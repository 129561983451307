import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Row, Col, Button, Label, FormGroup, Modal, ModalHeader, ModalFooter, ModalBody, FormText } from 'reactstrap';
import { Link } from 'react-router-dom';
import './MyAccount.css';
//import CreditCardInput from 'react-credit-card-input';
import axios from 'axios';
import { CONFIG, MatchingCountryCode } from '../../utils/config';
//import { MissingCompetitor} from '../MissingCompetitor';
import CreditCardInput from 'react-credit-card-input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { COMMON } from '../../utils/common';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import HubspotForm from 'react-hubspot-form';

export default class MyAccount extends React.Component {

  constructor() {
    super();
    this.state = {
      open: false,
      editAccountModel: false,
      editCredit: false,
      focusAfterClose: true,
      aModel: false,
      alertModel: false,
      aModel1: false,
      cardName: '',
      cardNumber: null,
      expiry: null,
      SecurityCode: null,
      focusAfterClose: true,
      isCh: false,
      tot: '',
      updateSubscripConfirmation: false,
      updatePopUp: false,
      updatePopUp1: false,
      countryCurrency: '',
      isLoading: false,
      snackOpen: false,
      snackMsg: '',
      errCard: '',
      phoneFieldBorder: '0',
      phoneErr: '',
      phone: '',
      userPhone: '',
      curSymb: CONFIG.Currency_Symbol,
      phoneMaxLength: CONFIG.PhoneMaxLength,
      StateCountryCount: 1,
      countryData: [],
      filteredState: [],
      showState: true,
      contactSupportModal: false
    };
    this.accountModal1 = this.accountModal1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.creditToggle = this.creditToggle.bind(this);
    this.accountModal = this.accountModal.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.toggleConfirmationAccount = this.toggleConfirmationAccount.bind(this);
    this.toggleConfirmationBilling = this.toggleConfirmationBilling.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.editSubAlert = this.editSubAlert.bind(this);
    //this.saveSubscription = this.saveSubscription.bind(this);
    this.ValidateCard = this.ValidateCard.bind(this);
    this.postalCodeValidation = this.postalCodeValidation.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
  }
  postalCodeValidation = (e) => {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code == 32) && // space
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)) { // lower alpha (a-z)
      e.preventDefault();
    }
  }
  editSubAlert() {
    // this.setState(prevState => ({ alertEditSubModel: !prevState.alertEditSubModel }));
    this.setState({ snackOpen: true, snackMsg: 'Please add billing details before edit the subscription' })
  }

  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };

  accountModal1() {

    this.setState(({ aModel1 }) => ({ aModel1: !aModel1 }));
  }

  handleChange(e) {
    if ((e.target.value !== '' && e.target.value !== undefined) && e.target.name === 'billingCountry') {
      //spliting the country code from state id
      var sc_code = e.target.value;
      let countryDetails = this.state.countryData.filter(function (data) {
        return data.countryCode === sc_code
      });
      // State's country code based country details
      let stateDetails = this.state.stateData.filter(function (data) {
        return data.countryCode === sc_code
      });
      this.state.infoData.info.userInfo.billingCountry = stateDetails.length > 0 ? countryDetails[0].countryID : '';
      // this.state.infoData.info.userInfo.billingState = '';
      this.state.infoData.info.userInfo.billingCountryCode = countryDetails !== undefined && countryDetails.length > 0 ? countryDetails[0].countryCode : '';
      this.setState({ filteredState: stateDetails, showState: countryDetails[0].regionId === COMMON.StorTrackRegion.UK ? false : true });
    } else {
      if (e.target.name === 'billingCountry') {
        this.state.infoData.info.userInfo.billingCountry = '';
        this.state.filteredState = [];
      }
    }
    this.setState({ [e.target.name]: e.target.value, errCard: '' }, () => { this.ValidateCard() });
  }

  toggle() {
    this.setState(({ open }) => ({ open: !open }));
  }

  toggleInfo() {
    this.setState({ alertModel: false });
  }

  toggleConfirmation() {
    this.setState(prevState => ({
      updateSubscripConfirmation: !prevState.updateSubscripConfirmation
    }));
  }

  toggleConfirmationBilling() {
    this.setState(prevState => ({
      updatePopUp: !prevState.updatePopUp
    }));
  }

  toggleConfirmationAccount() {
    this.setState(prevState => ({
      updatePopUp1: !prevState.updatePopUp1
    }));
  }

  accountModal() {
    this.setState(prevState => ({
      aModel: !prevState.aModel
    }));
    this.updateState();
  }

  handleSelectChange({ target: { value } }) {
    this.setState({ focusAfterClose: JSON.parse(value) });
  }

  onPhoneChanges = (value)=>{
    var string = value;
    let phoneErr = '';// 'Phone is invalid';
    let phoneFieldBorder = '0';// '1';


    if (string === "") {
      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
    }
    this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' }); 
}

  onPhoneChange = (event) => {
    var string = event.target.value;
    var replacedString = event.target.value;
    //var replacedString = event.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
    var numericRegex = /^[ 0-9*()+-]*$/;// /^([0-9]*)$/;
    var valid = numericRegex.exec(replacedString);
    var keyCode = this.state.keyCode;
    let phoneErr = '';// 'Phone is invalid';
    let phoneFieldBorder = '0';// '1';

    if (valid) {
      string = replacedString;
      // var regex = CONFIG.PhoneRegex;
      // var match = regex.exec(string);
      // if (match) {
      //   match.shift();
      //   match[0] = "(" + match[0] + ")";
      //   string = match.join("-");
        phoneErr = '';
        phoneFieldBorder = '0';
      // }
    } else {
      if (keyCode !== 8 && keyCode !== 46) {
        string = this.state.phone;
      }
    }

    if (string === "") {
      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
    }
    this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
  }

  phoneKeyPressed = (event) => {
    this.setState({ keyCode: event.keyCode });
  }

  creditToggle() {
    if (!this.state.infoData && !this.state.infoData.info.userInfo.billingAddress || !this.state.infoData && !this.state.infoData.info.userInfo.billingCity || !this.state.infoData && !this.state.infoData.info.userInfo.billingZipCode) {
      this.setState({ snackOpen: true, snackMsg: 'Please provide the billing details' })
    }
    else {
      this.setState(prevState => ({
        editCredit: !prevState.editCredit, errCard: '', cardName: '', cardNumber: '', expiry: '', SecurityCode: ''
      }));
    }

  }
  updateState() {
    const { infoData, stateData } = this.state;
    if (infoData && infoData.info !== undefined && stateData && stateData.length > 0) {
      if (infoData.info.userInfo.stateCode !== '' && infoData.info.userInfo.stateCode !== undefined && stateData.length > 0) {
        let stateDetails = infoData && infoData.info.userInfo.stateCode && infoData.info.userInfo.stateCode !== '' && stateData.length > 0 ? stateData.filter(function (data) {
          return data.countryCode === infoData.info.userInfo.stateCode.split(' ')[1]
        }) : [];
        this.setState({ filteredState: stateDetails });
      }
    }
  }
  toggleEditAccount() {
    this.setState({ phone: this.state.userPhone, phoneErr: '', phoneFieldBorder: '', regionCode: this.state.defaultRegionCode, dialCode: this.state.defaultRegionCode });

    this.setState(prevState => ({
      editAccountModel: !prevState.editAccountModel
    }));
  }

  sigOut(event, errors, values) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    const remarks = { "Remarks": values.Remarks, "RegionId": CONFIG.RegionId }
    axios.post(CONFIG.API_URL + 'account/cancelsubscription', remarks)
      .then(response => {
        if (response.status == 'success') {

          localStorage.removeItem('accessKey');
          // this.state.isNavigationBlocked = true;
          this.props.history.push({
            pathname: `/`
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        })
        if (err.response.StatusCode === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    localStorage.removeItem('accessKey');
    // this.state.isNavigationBlocked = true;
    this.props.history.push({
      pathname: `/`
    });
  }

  saveSubscription(event, errors, values) {
    let formvalid = false;

    formvalid = this.ValidateCard();
    if (formvalid && values.cardName !== '') {
      this.setState({ isLoading1: true, errCard: "" })

      //const subscriptionData = this.state.storeData.info.userInfo;
      var getPaymonthyear = this.state.expiry.toString().split('/');

      const data = {
        "CardType": "visa",
        "CardNumber": this.state.cardNumber,
        "ExpiryMonth": getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : null,
        "ExpiryYear": getPaymonthyear.length > 0 ? parseInt('20'.concat(getPaymonthyear[1].trim())) : null,
        "Token": '',
        "CardName": this.state.cardName !== '' ? this.state.cardName : values.cardName,
        "SecurityCode": this.state.SecurityCode,
        "IsDefault": true,
        "RegionId": CONFIG.RegionId
      }
      this.setState({
        curSymb: CONFIG.Currency_Symbol
      })
      // axios.get(CONFIG.API_URL + 'home/region')
      //   .then(response => {
      //       let curSymb = response.data.region.currencySymbol;
      //       this.setState({
      //           curSymb
      //       })
      //   });

      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }

      axios.put(CONFIG.API_URL + 'Valut/ChangeCard', data)
        .then(res => {
          const getTokvalues = res.data;
          this.setState({ getTokvalues });
          if (getTokvalues !== "Success") {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;

            localStorage.setItem('accessKey', getTokvalues.token);
            localStorage.setItem('getNames', getTokvalues.firstName);
            localStorage.setItem('isPaid', getTokvalues.isPaid);
            localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
            localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
            localStorage.setItem('userType', getTokvalues.userType);
            localStorage.setItem('userStatus', getTokvalues.status);
            localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
            localStorage.setItem('userManual', getTokvalues.isManual);
          }
          if (res.status === 200) {
            this.setState({ isLoading1: false, editCredit: false, snackOpen: true, snackMsg: 'Updated succesfully' });
            this.updateMenu();
          }
          // this.state.editaccountmodal = false;
          window.scrollTo(0, 0);
          this.componentDidMount();
          // this.setState({ isLoading: false, updateSubscripConfirmation: true });
          // window.location.reload();
        })
        .catch((err) => {
          this.setState({
            isLoading1: false
          })
          if (err.response.status === 401) {
            localStorage.removeItem('accessKey');
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
          }
          else if (err.response.status === 400) {
            let message = err.response.data.Message;
            this.setState({ snackOpen: true, snackMsg: message });
          }
          else {
            this.setState({ isLoading1: false, snackOpen: true, snackMsg: "error" });
          }
        });
    }
  }

  ValidateCard() {
    let formvalid = false;
    if (!this.state.cardNumber || !this.state.SecurityCode || !this.state.expiry) {
      this.setState({ errCard: "Please enter missing card details" })
    }
    else if (this.state.expiry) {
      //Expiry date check for Card
      var getPaymonthyear = this.state.expiry.toString().split('/');
      let ExpiryMonth = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
      let ExpiryYear = getPaymonthyear.length > 1 ? getPaymonthyear[1].trim() : '';
      var CurrentMonth = new Date().getMonth() + 1;
      var CurrentYear = new Date().getFullYear().toString().substr(-2);
      if (CurrentYear > ExpiryYear) {
        this.setState({ errCard: 'Please enter valid expiry year' });
      }
      else {
        if (CurrentYear === ExpiryYear) {
          if (CurrentMonth > ExpiryMonth || ExpiryMonth > 12) {
            this.setState({ errCard: 'Please enter valid expiry month' });
          } else {
            if (this.state.SecurityCode) {
              if (this.state.SecurityCode.length === 3 || this.state.SecurityCode.length === 4) {
                this.setState({ errCard: '' });
                formvalid = true;
              } else {
                this.setState({ errCard: 'Please enter valid CVV' });
              }
            }
          }
        } else {
          if (ExpiryMonth > 12) {
            this.setState({ errCard: 'Please enter valid expiry month' });
          } else {
            if (this.state.SecurityCode) {
              if (this.state.SecurityCode.length === 3 || this.state.SecurityCode.length === 4) {
                this.setState({ errCard: '' });
                formvalid = true;
              } else {
                this.setState({ errCard: 'Please enter valid CVV' });
              }
            }
          }
        }
      }

    }
    return formvalid;
  }

  billingSubmit(event, errors, values) {
    console.log(values.billingState);
    if (errors.length > 0) {
    }
    else {
      this.setState({ isLoading: true });
      let city = this.state.stateData;
      // let selState = values.billingState !=='' ? values.billingState : '';
      var billingState = city.filter(function (user) {
        return user.stateID == values.billingState
      });
      let billingEditInfo = this.state.infoData.info.userInfo;
      billingEditInfo.billingAddress = values.Address1 ? values.Address1 : null;
      billingEditInfo.billingAddress2 = values.Address2 ? values.Address2 : null;
      billingEditInfo.billingCity = values.City ? values.City : null;
      //  billingEditInfo.billingState = values.billingState ? values.billingState : null;
      billingEditInfo.billingState = billingState.length > 0 ? billingState[0].stateID : null;//CONFIG.Region_Id === Regions.UK ? selState : billingState[0].stateID ? billingState[0].stateID : null;
      billingEditInfo.billingZipCode = values.Zip ? values.Zip : null;
      billingEditInfo.billingCountry = this.state.infoData.info.userInfo.billingCountry;
      billingEditInfo.billingCountryCode = this.state.infoData.info.userInfo.billingCountryCode === 'England' ? 'GB' : this.state.infoData.info.userInfo.billingCountryCode;
      billingEditInfo.password = null;
      billingEditInfo.RegionId = CONFIG.RegionId;
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.put(CONFIG.API_URL + 'Account/updateaccount', billingEditInfo)
        .then(response => {
          if (response.status === 200) {
            // alert('Updated successfully');
            this.setState({ isLoading: false, snackOpen: true, snackMsg: 'Billing details updated successfully' }, () => {
              this.myAccountEdit();
            });
            let billingEditInfo = this.state.infoData.info.userInfo;
            billingEditInfo.billingState = values.billingState;
            // // this.state.aModel = false;
            // // this.componentDidMount();
            // let billingEditInfo = this.state.infoData.info.userInfo;
            // billingEditInfo.billingState = values.billingState ? values.billingState : '';
            // this.state.aModel = false;
            // this.setState({ isLoading: false, snackOpen: true, snackMsg: 'Billing details updated successfully' });
            // this.componentDidMount();
            setTimeout(() => {
              this.backToAccount();
            }, 1500);
          }
        })
        // .catch((err) => {
        //   this.setState({
        //     isLoading: false
        //   })
        //   if (err.response.status === 401) {
        //     localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        //   }
        //   else {
        //     this.setState({ snackOpen: true, snackMsg: err.response.data });
        //   }
        // });
        .catch((err) => {
          this.setState({
            isLoading: false
          })
          if (err.response.status === 401) {
            localStorage.removeItem('accessKey');
            const { from } = this.props.location.state || { from: { pathname: "/page_expired" } };
            this.props.history.push(from);
          }
          else if (err.response.status === 400) {
            let message = err.response.data.Message;
            this.setState({ snackOpen: true, snackMsg: message, snackClr: 'snack-error' });
          }
          else {
            this.setState({ isLoading: false, snackOpen: true, snackMsg: "error", snackClr: 'snack-error' });
          }
        });
    }
  }
  backToAccount() {
    this.setState({ snackOpen: false, isLoading: false, editAccountModel: false, aModel: false });
    const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
    this.props.history.push(from);
    this.componentDidMount();
  }
  myAccountEdit() {
    this.setState({
      isLoading: true
    });
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    // axios.post(CONFIG.API_URL + 'Account/getuserinfo')
    axios.post(CONFIG.API_URL + 'Account/getuserinfo/' + CONFIG.RegionId)
      .then(response => {

        let storeData = response.data;
        let infoData = response.data;
        let pricePerStoreState = response.data.info.subscription != null ? response.data.info.subscription.pricePerStore : 0;
        if (response.data.info.subscription) {
          if (pricePerStoreState != null) {
            this.setState({
              pricePerStoreState
            })
          } else {
            this.setState({
              pricePerStoreState: 0
            })
          }
        }
        infoData.info.userInfo.phone = this.formatPhoneNumber(infoData.info.userInfo.phone.replace(/[^\d]/g, ''));
        let phone = infoData ? infoData.info.userInfo.phone : '';
        if (localStorage.getItem('getNames') != infoData.info.userInfo.firstName) {
          localStorage.setItem('getNames', infoData.info.userInfo.firstName);
          document.getElementById('r1').click();
        }

        let isCh = false;
        let checBox = infoData.info.userInfo.isReceiveEmailAlerts.toString();
        if (checBox === "true") {
          isCh = true;
          this.setState({ isCh })
        } else {
          isCh = false;
          this.setState({ isCh })
        }
        let infoEditData = response.data.info.userInfo;

        let tot = response.data.info.subscription ? response.data.info.subscription.totalPrice : '';
        //Decimal Number Conversion 
        if (tot != '') {
          let checkDigit = tot.toString().split('.');
          let ret = "";
          let i = 0;
          if (tot.toString().indexOf(".") == -1) {
            tot = tot;
          } else {
            if (checkDigit == '00') {
              tot = checkDigit.trim();
            }
            else {
              if (checkDigit.length == 2) {
                ret += checkDigit[0] + ".";
                for (i = 0; i < checkDigit.length; i++) {
                  if (i >= checkDigit[1].length)
                    ret += '0';
                  else
                    ret += checkDigit[1][i];
                }
              }
              else if (checkDigit.length == 1) {
                ret += checkDigit[0] + ".";
                for (i = 0; i < checkDigit.length; i++) {
                  ret += '0'
                }
              }
              ret = ret.toString();
              tot = ret;
            }
          }
        }

        this.setState({
          isLoading: false,
          infoData,
          infoEditData, storeData, phone, tot, userPhone: phone
        }, () => {
          this.updateState();
        })

        if (this.state.storeData.info.storeDetails.length > 0) {
          const data1 = {
            "UserStoreID": this.state.storeData.info.storeDetails[0].userStoreID,
            "RegionId": CONFIG.RegionId
          }

          // axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data1)
          //   .then(res => {
          //     let countryCurrency = res.data.data.countryCurrency;
          //     this.setState({ countryCurrency });

          //   })
          //   .catch((err) => {
          //     this.setState({ isLoading: false });
          //     if (err.response.status === 401) {
          //       localStorage.removeItem('accessKey');
          //       const { from } = this.props.location.state || { from: { pathname: "/" } };
          //       this.props.history.push(from);
          //     }
          //     else {
          //       alert(err.response.data);
          //     }
          //   });

        }
      })
      .catch((err) => {

        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });

    // axios.get(CONFIG.API_URL + 'home/lookupdata')
    axios.get(CONFIG.API_URL + 'home/lookupdata/' + CONFIG.RegionId + '/' + 1)
      .then(response => {

        let stateData = response.data.lookups.statesList;
        //To know how many Countries in statesList data
        //   let StateCountryCount = stateData.filter(data => {
        //     return data.stateID === '';
        // }).length;
        let countryData = response.data.lookups.countryList;
        this.setState({
          stateData, countryData
        }, () => {
          this.updateState();
        })
        console.log(stateData)
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  componentDidMount() {

    this.myAccountEdit();

    let userType = localStorage.getItem('userType');
    let userStatus = localStorage.getItem('userStatus');
    // let countryCurrency = localStorage.getItem('curSymb');
    let countryCurrency = CONFIG.Currency_Symbol;
    let userManual = localStorage.getItem('userManual');
    let isPaid = localStorage.getItem('isPaid');
    this.setState({
      userType, userStatus, countryCurrency, userManual, isPaid
    });

    if (userType === "1" && userStatus === "2" || userType === "2" && userStatus === "2" || userType == "2" && isPaid == "0") {
      this.setState({
        alertModel: true
      });
    }
  }
  updateMenu() {

    localStorage.setItem('IsUpdatePaid', "1");
    var isElement = document.getElementById('r2');
    if (isElement == null) {
      document.getElementById('r1').click();
    }
    else {
      document.getElementById('r1').click();
      document.getElementById('r2').click();
    }
  }

  accountSubmit(event, errors, values) {
    let formValid = true;
    //Phone number validation
    let phoneErr = '';
    let phoneFieldBorder = '0';
    if (this.state.phone === "") {
      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
      formValid = false;
    } else if (this.state.phone.length < CONFIG.PhoneMinLength) {
      phoneErr = 'Phone is invalid';
      phoneFieldBorder = '1';
      formValid = false;
    }
    this.setState({ phoneErr, phoneFieldBorder });
    if (errors.length > 0) {
      formValid = false;
    }
    if (formValid) {
      let phoneNumber = this.state.phone;
      let userEditInfo = this.state.infoData.info.userInfo;
      userEditInfo.FirstName = values.fName;
      userEditInfo.LastName = '';
      userEditInfo.login = values.email;
      userEditInfo.company = values.company;
      userEditInfo.phone = phoneNumber.replace(/[^\d]/g, '');
      userEditInfo.billingPhone = phoneNumber.replace(/[^\d]/g, '');
      userEditInfo.password = values.password;
      userEditInfo.billingState = CONFIG.RegionId === COMMON.StorTrackRegion.UK ? '' : userEditInfo.stateCode;
      userEditInfo.isReceiveEmailAlerts = values.isReceiveEmailAlerts;
      userEditInfo.RegionId = CONFIG.RegionId;
      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.put(CONFIG.API_URL + 'Account/updateaccount', userEditInfo)
        .then(res => {
          const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
          this.props.history.push(from);
          this.state.editAccountModel = false;
          axios.put(CONFIG.API_URL + 'Account/updateaccount', userEditInfo)
            .then(res => {
              if (res.status === 200) {
                //alert('Updated successfully');  
                window.scrollTo(0, 0);
                this.setState({ isLoading: false, snackOpen: true, snackMsg: 'Updated succesfully' });
                this.componentDidMount();
              }
            })
          // if (err.response.status === 401) {
          //     localStorage.removeItem('accessKey');
          //     const { from } = this.props.location.state || { from: { pathname: "/" } };
          //     this.props.history.push(from);
          // }
          // else {
          //     alert(err.response.data);
          // }
        });
      // this.myAccountEdit();
    }
  }
  //format phone numbers
  formatPhoneNumber(phone) {
    if (CONFIG.RegionId !== MatchingCountryCode.UK_RID) {
      CONFIG.PhoneRegex.lastIndex = 0;
      var match = null;
      match = CONFIG.PhoneRegex.exec(phone);
      if (match) {
        match.shift();
        match[0] = "(" + match[0] + ")";
        phone = match.join("-");
      }
    }
    return phone;
  }

  contactSupport = () =>{
    this.setState(state => ({ contactSupportModal: !state.contactSupportModal }));
  }

  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    //const marketPrice = storeData && storeData.info.userInfo.tempBasicNoOfStores ? storeData.info.userInfo.tempBasicNoOfStores === 1 ? p_singleMarket :  : 0; 
    const { isLoading, isLoading1, cardNumber,
      expiry, snackMsg, snackOpen, StateCountryCount,
      cvv, infoData, userManual, stateData, isCh, storeData, pricePerStoreState, testData, tot, countryCurrency, userType, userStatus, isPaid, regionCode, onlyCountries, countryData, filteredState, showState } = this.state;
    let lName = infoData && infoData.info.userInfo.lastName ? infoData.info.userInfo.lastName : '';
    let billState = infoData && infoData.info.userInfo.billingState ? filteredState.filter((data) => { return data.state === infoData.info.userInfo.billingState }).map(data => data.state) : '';//infoData && infoData.info.userInfo.billingState : '';
    let billStateID = infoData && infoData.info.userInfo.billingState ? filteredState.filter((data) => { return data.state === infoData.info.userInfo.billingState }).map(data => data.stateID) : '';//infoData && infoData.info.userInfo.billingState : '';
    billStateID = billStateID.length > 0 ? billStateID : filteredState.filter((data) => { return data.stateID === infoData.info.userInfo.billingState }).map(data => data.stateID);
    let billingCountry = infoData && infoData.info.userInfo.billingCountry != null ? countryData.filter((data) => { return data.countryID === infoData.info.userInfo.billingCountry }).map(data => data.countryCode) : '';
    return (
      <div className="wrapper my-account-box height-vh">
        <div id="r2"></div>
        {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
        {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
        <Row>
          <Col md={12}>
            <Typography component="h2" variant="headline" className="breadcrumbs-top">
              My Account
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col md={12}>

            <div className="account-details">
              <h5 className="account-head">My Account</h5>
              <Row>
                <Col md={4} lg={4} className="brd-right"><Typography component="h2" variant="headline" className="account-sapce">
                  Account Details
                </Typography>
                  <div>
                    <p className="my-account-label">Full Name</p>
                    <p className="result-data inti-cap">{infoData && infoData.info.userInfo.firstName ? infoData.info.userInfo.firstName + ' ' + lName : isLoading === false ? 'N/A' : ''} </p>
                  </div>
                  <div>
                    <p className="my-account-label">Company </p>
                    <p className="result-data inti-cap">{infoData && infoData.info.userInfo.company ? infoData.info.userInfo.company : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Phone Number </p>
                    <p className="result-data">{infoData && infoData.info.userInfo.phone ? infoData.info.userInfo.phone : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Email Address </p>
                    <p className="result-data">{infoData && infoData.info.userInfo.login ? infoData.info.userInfo.login : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Password</p>
                    <input type="password" disabled className="result-data"
                      value={infoData && infoData.info.userInfo.password ? infoData.info.userInfo.password : isLoading === false ? 'N/A' : ''}
                    />
                  </div>
                  <div className="red-btn-underline">
                    <Link rel="noopener noreferrer" onClick={this.toggleEditAccount.bind(this)} id="fwd-id">Edit Account</Link>
                    <Modal backdrop="static" returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.editAccountModel} toggle={this.toggleEditAccount.bind(this)} className="custom-popup">
                      <ModalHeader
                      // toggle={this.toggleEditAccount.bind(this)}
                      >Account Details</ModalHeader>
                      <ModalBody>
                        <AvForm onSubmit={this.accountSubmit.bind(this)}>
                          <Row>
                            <Col md={12}>
                              <AvField name="fName" label="Full Name" type="text"
                                value={infoData && infoData.info.userInfo.firstName ? infoData.info.userInfo.firstName + ' ' + lName : ''}
                                validate={{
                                  required: { value: true, errorMessage: 'Full Name is required' }
                                }} />
                            </Col>
                            <Col md={12}>
                              <AvField name="company" label="Company" type="text" value={infoData && infoData.info.userInfo.company ? infoData.info.userInfo.company : ''}
                              />
                            </Col>
                            <Col md={12}>
                              <Label className={this.state.phoneFieldBorder === '1' ? 'text-danger' : ''}>Phone</Label> 
                              <PhoneInput typename="phone" id="phone"  value={this.state.phone} onChange={this.onPhoneChanges} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                              <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                            </Col>
                            <Col md={12} >
                              <AvField name="email" label="Email Address" readOnly value={infoData && infoData.info.userInfo.login ? infoData.info.userInfo.login : 'N/A'} type="email" validate={{
                                required: { value: true, errorMessage: 'Email Address is required' }
                              }} />
                            </Col>
                            <Col md={6}>
                              <AvField name="password" label="Password" type="password"
                                value={infoData && infoData.info.userInfo.password ? infoData.info.userInfo.password : ''}
                                validate={{
                                  required: { value: true, errorMessage: 'Password is required' },
                                  pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                  minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                  maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                }} />
                            </Col>
                            <Col md={6}>
                              <AvField name="c_password" label="Confirm Password" type="password"
                                value={infoData && infoData.info.userInfo.password ? infoData.info.userInfo.password : ''}
                                validate={{
                                  required: { value: true, errorMessage: 'Confirm Password is required' },
                                  match: { value: 'password', errorMessage: 'Mismatch Confirm Password' }
                                }} />
                            </Col>
                          </Row>
                          <AvGroup check className="custom-checkbox">
                            <AvInput type="checkbox" name="isReceiveEmailAlerts" defaultChecked={this.state.isCh} />
                            <Label check for="isReceiveEmailAlerts">Receive Email Alerts</Label>
                          </AvGroup>
                          {/* <FormControlLabel className="receive-email"
                          control={
                            <Checkbox name="isReceiveEmailAlerts" defaultChecked={this.state.isCh} />
                          }
                          label={<span className="font-sz-13">Receive Email Alerts</span>}
                        /> */}
                          <FormGroup className="login_inline">
                            <Button type="submit" className="success-btn">Save </Button>
                            <Button className="edit_cancel" color="secondary" onClick={this.toggleEditAccount.bind(this)}>Cancel</Button>
                            <div className="clear"></div>
                          </FormGroup>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </div>
                </Col>
                <Col md={4} lg={4} className="brd-right"><Typography component="h2" variant="headline" className="account-sapce">
                  Subscription Details
                </Typography>
                  <div>
                    <p className="my-account-label">Subscription Type </p>
                    <p className="result-data cap-txt">{infoData && infoData.info.userInfo.subscriptionType ? infoData.info.userInfo.subscriptionType : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div className="my-account-label">
                    <p className="result-data multi-data-show">
                      {infoData && infoData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                        <label>{storeData && storeData.info.userInfo.tempBasicNoOfStores ? storeData.info.userInfo.tempBasicNoOfStores : '0'} market(s) at <label>{infoData && infoData.info.userInfo.subscriptionType.toString() === 'StorTrack Optimize, Multi-market' ? this.state.pricePerStoreState ? countryCurrency + this.state.pricePerStoreState : 0 : this.state.pricePerStoreState ? countryCurrency + this.state.pricePerStoreState : 0}.00</label>/month {storeData.info.userInfo.tempBasicNoOfStores > 1 ? "ea." : ""}</label>
                        : ''}
                    </p>
                  </div>
                  <div>
                    {infoData && infoData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                      <p className="my-account-label" align="right">
                        Monthly Total: <label>{tot != '' ? countryCurrency + parseFloat(tot).toFixed(2) : 'N/A'}</label>
                        {/* <label>${infoData.info.subscription.totalPrice ? infoData.info.subscription.totalPrice : 'N/A'}</label>  */}
                      </p> : ''}
                  </div>
                  {userManual == 1 ? '' :
                    <div>
                      {userStatus === '2' ? '' :
                        <div className="red-btn-underline">
                          {infoData && infoData.info.userInfo.billingAddress != null ?
                            <Link to='/subscription-details/' onClick={this.accountModal} >
                              Edit Subscription
                            </Link>
                            :
                            <Link to='#' onClick={this.editSubAlert} >
                              Edit Subscription
                            </Link>
                          }
                        </div>
                      }
                      <div className="red-btn-underline">
                        {infoData && infoData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                          <Link to='#'
                            onClick={this.creditToggle}
                          >
                            Update credit card details
                          </Link> : ''}
                        {/* {userStatus == 2 ? '' : 
                    <div className="red-btn-underline">
                      <Link to="#" className="cancel-subs" onClick={this.accountModal1} >
                        Cancel Subscription
                      </Link>
                    </div>
                    } */}
                      </div>
                    </div>}
                    <>
                    <div className="red-btn-underline mt-3">
                      <p style={{ fontSize: '12px' }}>Need more help?&nbsp;
                        <a
                          href="javascript:void(0);"
                          onClick={()=> this.contactSupport()}
                          style={{ color: "#41a3f6" }}
                        >
                          Contact Support
                        </a>
                      </p>
                    </div>
                    <Modal
                      isOpen={this.state.contactSupportModal}
                      className="downgrae_delete-acc"
                    >
                      <ModalHeader className="content pb-0">Contact Support 
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className="closeModal contact-sup-btn"
                        onClick={()=> this.contactSupport()}>
                        <CloseIcon />
                      </IconButton>
                      </ModalHeader>
                      <ModalBody className="pt-0">
                        <p>
                          Submit questions or concerns about your account
                          or subscription to the support team.
                        </p>
                        <HubspotForm
                        region = "na1"
                        portalId = "4297816"
                        formId = "a83a7e57-db1d-41d2-8d9e-141c5c4586f6"
                        />
                      </ModalBody>
                    </Modal>
                  </>
                </Col>
                <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.aModel1} backdrop="static" className="exists-popup">
                  <AvForm onSubmit={this.sigOut.bind(this)}>
                    <p className="exist-para">Are you sure you need to exist?</p>
                    <div className="exists-confirms">
                      {/* <input type="text" name ="Remarks" placeholder="remarks" values=""/> */}
                      <AvField name="Remarks" label="Remarks" type="text"
                        value=''
                      // validate={{
                      //  required: { value: true, errorMessage: 'Remarks is required' }
                      // }} 
                      />
                      <div className="login_inline">
                        <Link to="#" onClick={this.accountModal1} className="edit_cancel btn btn-secondary">Cancel</Link>
                        <Button type="submit" className="success-btn">Confirm </Button>
                        <div className="clear"></div>
                      </div>
                      {/* <Link to="/" onClick={this.sigOut.bind(this)} className="confirm-btn" >
                  Confirm
              </Link> */}
                    </div>
                  </AvForm>
                </Modal>
                <Modal backdrop="static" returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.editCredit} toggle={this.creditToggle} className="custom-popup card-popup">
                  <ModalHeader
                  // toggle={this.creditToggle}
                  >Account Details</ModalHeader>
                  <AvForm
                    onSubmit={this.saveSubscription.bind(this)}
                  //onSubmit={this.handleValidSubmit.bind(this)}
                  >
                    <Col md="12" className="set-btm-mr">
                      <Label>Name on Card</Label>
                      <AvField name="cardName" id="Name" validate={{
                        required: { value: true, errorMessage: 'Please enter the card name' }
                      }}
                        value={this.state.cardName}
                      />
                    </Col>
                    <Col md="12" className="card-fields">
                      <Label>Card Credentials</Label>
                      <CreditCardInput
                        cardNumberInputProps={{
                          value: this.state.cardNumber,
                          name: "cardNumber",
                          placeholder: 'Card Number',
                          onChange: this.handleChange
                        }}
                        cardExpiryInputProps={{
                          value: this.state.expiry,
                          name: "expiry",
                          placeholder: 'MM/YY',
                          onChange: this.handleChange
                        }}
                        cardCVCInputProps={{
                          value: this.state.SecurityCode,
                          name: "SecurityCode", placeholder: 'CVV',
                          onChange: this.handleChange
                        }}
                        fieldClassName={this.state.errCard !== '' ? "input is-invalid" : "input"}
                      />
                      <FormText className='errorMsg' color="red">{this.state.errCard}</FormText>
                    </Col>
                    <div className="subscription-details no-border">
                      <div className="Subscription-btn">
                        <Button type="submit" className="success-btn">Confirm</Button>
                        <Button className="edit_cancel" onClick={this.creditToggle}>Cancel</Button>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </AvForm>

                </Modal>
                <div>
                  <Modal isOpen={this.state.updateSubscripConfirmation} className={this.props.className}>
                    <ModalHeader
                    // toggle={this.toggleConfirmation}
                    >Confirmation</ModalHeader>
                    <ModalBody className="text-center">
                      Your subscription has been updated successfully.
                    </ModalBody>

                  </Modal>
                </div>
                {/* <div>
                <Modal isOpen={this.state.updatePopUp} className={this.props.className}>
                  <ModalHeader toggle={this.toggleConfirmationBilling}>Confirmation</ModalHeader>
                  <ModalBody className="text-center">
                    Updated successfully.
            </ModalBody>
                    <ModalFooter>
                      <Link to="/my-account/" onClick={this.toggleConfirmationBilling} className="btn-save btn btn-secondary">Ok</Link>
                    </ModalFooter>
                  </Modal>
                </div>
                <div>
                  <Modal isOpen={this.state.updatePopUp1} className={this.props.className}>
                    <ModalHeader toggle={this.toggleConfirmationAccount}>Confirmation</ModalHeader>
                    <ModalBody className="text-center">
                      Updated successfully.
            </ModalBody>
            <ModalFooter>
              <Link to="/my-account/" onClick={this.toggleConfirmationAccount} className="btn-save btn btn-secondary">Ok</Link>
            </ModalFooter>
                </Modal>
              </div> */}
                <Col md={4} lg={4}><Typography component="h2" variant="headline" className="account-sapce">
                  Billing Details
                </Typography>
                  <div>
                    <p className="my-account-label">{CONFIG.Address1}</p>
                    <p className="result-data inti-cap">{infoData && infoData.info.userInfo.billingAddress ? infoData.info.userInfo.billingAddress : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div>
                    <p className="my-account-label">{CONFIG.Address2}</p>
                    <p className="result-data inti-cap">{infoData && infoData.info.userInfo.billingAddress2 ? infoData.info.userInfo.billingAddress2 : ''}</p>
                  </div>
                  <div>
                    <p className="my-account-label">{CONFIG.City}</p>
                    <p className="result-data inti-cap">{infoData && infoData.info.userInfo.billingCity ? infoData.info.userInfo.billingCity : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  {/* {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '' :
                    <div>
                      <p className="my-account-label">{CONFIG.State}</p>
                      {infoData && infoData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                        <p className="result-data">{infoData && infoData.info.userInfo.stateCode != null ? infoData.info.userInfo.stateCode.length > 3 ? billState : billState : ''}</p>
                        : <p className="result-data">{infoData && infoData.info.userInfo.stateCode ? billState : isLoading === false ? 'N/A' : ''}</p>}
                    </div>
                  }
                  
                   */}
                  {infoData && infoData.info.userInfo.stateCode != null ?
                    <div>
                      <p className="my-account-label">{CONFIG.State}</p>
                      {infoData && infoData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                        <p className="result-data">{infoData && infoData.info.userInfo.stateCode != null ? infoData.info.userInfo.stateCode.length > 3 ? billState : billState : ''}</p>
                        : <p className="result-data">{infoData && infoData.info.userInfo.stateCode ? billState : isLoading === false ? 'N/A' : ''}</p>}
                    </div> : ''}
                  <div>
                    <p className="my-account-label">{CONFIG.PostalCode}</p>
                    <p className="result-data">{infoData && infoData.info.userInfo.billingZipCode ? infoData.info.userInfo.billingZipCode : isLoading === false ? 'N/A' : ''}</p>
                  </div>
                  <div className="red-btn-underline">
                    <Link to="#" onClick={this.accountModal} >

                      Edit

                    </Link>
                    {userManual == 1 || userType == 2 && isPaid == 0 || userType == 2 ? '' :
                      <Link to='/billing-history' className="see-billing">
                        See billing history
                      </Link>}
                  </div>
                </Col>
                <div className="clear"></div>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.aModel} backdrop="static" className="custom-popup">
          <ModalHeader
          // toggle={this.accountModal.bind(this)}
          >Billing Details</ModalHeader>
          <AvForm onSubmit={this.billingSubmit.bind(this)}>
            <ModalBody>
              {/*<div className="Myacc-billing-valid">Billing Details </div>*/}
              {/* <AvField name="CardName" label="Card on Name" type="text"
              validate={{
                 required: { value: true, errorMessage: 'Please enter a card name' }
               }} 
              />
              <label>Credit Card Number</label>
              <CreditCardInput
                cardNumberInputProps={{ value: cardNumber, onChange: this.handleCardNumberChange }}
                cardExpiryInputProps={{ value: expiry, onChange: this.handleCardExpiryChange }}
                cardCVCInputProps={{ value: cvv, onChange: this.handleCardCVCChange }}
                fieldClassName="input"
              /> */}
              <AvField name="Address1" label={CONFIG.Address1} type="text" value={infoData && infoData.info.userInfo.billingAddress ? infoData.info.userInfo.billingAddress : ''} validate={{
                required: { value: true, errorMessage: CONFIG.Address1 + ' is required' }
              }} />
              <Row>
                <Col xs="12" sm="6">
                  <AvField name="Address2" label={CONFIG.Address2} type="text"
                    value={infoData && infoData.info.userInfo.billingAddress2 ? infoData.info.userInfo.billingAddress2 : ''} />
                </Col>
                <Col xs="12" sm="6"> <AvField name="City" label={CONFIG.City} value={infoData && infoData.info.userInfo.billingCity ? infoData.info.userInfo.billingCity : ''} type="text" validate={{
                  required: { value: true, errorMessage: CONFIG.City + ' is required' }
                }} /></Col></Row>
              <Row>
                <Col xs="12" sm="12">
                  <Label> Country </Label>
                  {/* <AvField name="BillingCountry" readOnly onChange={this.handleChange}
                                                                                        value={this.state.UserDetail.BillingState ? this.state.StateCountry : ''} /> */}
                  <AvField type="select" name="billingCountry" className="bs" validate={{
                    required: { value: true, errorMessage: 'Country is required' }
                  }} onChange={this.handleChange} value={billingCountry != null ? billingCountry : ''}>
                    <option value='' >--Select Country--</option>
                    {countryData && countryData.length > 0 ? countryData.map((state) => {
                      if (state.country !== "Scotland" && state.country !== "Wales" && state.country !== "Northern Ireland")
                        return (
                          //state.stateID !== '' ? 
                          <option key={state.country} value={state.countryCode}>{state.country === "England" ? 'United Kingdom' : state.country}</option>
                          // :''
                        )
                    }) : ''}
                  </AvField>
                </Col>
              </Row>
              <Row>
                {filteredState && filteredState.length > 0 && showState ?
                  <Col md="6" className="pr-0 billing-state-province">
                    <Label> {CONFIG.State}</Label>
                    <AvField type="select" name="billingState" className="bs" validate={{
                      required: { value: true, errorMessage: CONFIG.RegionId === COMMON.StorTrackRegion.US ? 'State/Province is required' : 'State is required' }
                    }} onChange={this.handleChange} value={infoData && infoData.info.userInfo.billingState != null ? billStateID[0] : ''}>
                      <option value='' >{CONFIG.RegionId === COMMON.StorTrackRegion.US ? "--Select State/Province--" : "--Select State--"}</option>
                      {filteredState ? filteredState.filter(state => state.stateID !== '').map((state) => {
                        return (
                          //state.stateID !== '' ? 
                          <option key={state.stateID} className={state.stateID === '' ? "optHead disabled-states" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                          // :''
                        )
                      }) : ''}
                    </AvField>
                  </Col>
                  : ''}
                <Col md={filteredState && filteredState.length > 0 && showState === true ? 6 : 12} className={CONFIG.RegionId === MatchingCountryCode.UK_RID ? "postal-code-uk left-padding-a-m" : "left-padding-a-m"}>
                  <AvField name="Zip" label={CONFIG.PostalCode} type="text"
                    value={infoData && infoData.info.userInfo.billingZipCode ? infoData.info.userInfo.billingZipCode : ''}
                    validate={{
                      required: { value: true, errorMessage: CONFIG.PostalCode + ' is required' },
                      pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.PostalCodevalidation },
                      maxLength: { value: 10, errorMessage: 'Your ' + CONFIG.PostalCode + ' must below 10 characters' }
                    }} onKeyPress={CONFIG.RegionId === 3 ? this.postalCodeValidation : ''} /></Col></Row>
              <FormGroup className="login_inline">
                <Button color="danger" className="success-btn">Save</Button>
                <Button onClick={this.accountModal} className="edit_cancel">Cancel</Button>
                <div className="clear"></div>
              </FormGroup>
            </ModalBody>
          </AvForm>
        </Modal>
        {/* Below the modal for status information */}
        <div>
          <Modal centered="true" returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.alertModel} backdrop="static" size="sm" className="trail-end">
            <ModalHeader toggle={this.toggleInfo} className="border-0 p-0 m-0 mr-2"></ModalHeader>
            <ModalBody className="text-center">
              {userType === "1" && userStatus === "2" ? 'Your Subscription has been cancelled, Please update your credit card details to activate your subscription.' : ''}
              {userType === "2" && userStatus === "2" || userType == "2" && isPaid == "0" ? 'Your Trial period has been expired, Please upgrade your subscription by edit subscription.' : ''}
            </ModalBody>
          </Modal>
          <Modal centered="true" returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.alertEditSubModel} backdrop="static" size="sm" className="trail-end">

            <ModalBody className="text-center">
              Please add billing details before edit the subscription
            </ModalBody>
            <Button color="secondary" onClick={this.editSubAlert} className="edit_cancel mb-3">Close</Button>
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackOpen}
            className="snackbar bg-color-primary custom-snackbar"
            maxsnack={3}
            autoHideDuration={30000}
            onClose={this.snackHandleclose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackMsg}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.snackHandleclose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
      </div>
    );
  }
}

