import React, { Component } from "react";
import "./CurrentPricing.css";
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Typography from "@material-ui/core/Typography";
import { Table as ReactTable } from "reactstrap";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import axios from "axios";
import { CONFIG } from "../../utils/config";
import addtomap from "./../../Assets/Images/plus.png";
import removefrommap from "./../../Assets/Images/trash.png";
import tableplus from "./../../Assets/Images/table-plus.png";
import tableminus from "./../../Assets/Images/table-minus.png";
import Tooltip from "@material-ui/core/Tooltip";
import ShowMoreText from "react-show-more-text";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chart from "react-apexcharts";
import duplicate from '../../Assets/Images/duplicate.png';
import resetPng from '../../Assets/Images/reset.png';

export default class CurrentPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {

      advertisedRatesHistoryOptions: {
        chart: {
          id: 'average-price-history-chart',
          type: "area",
         // stacked: true,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          tickPlacement: 'on',
          tickAmount: 8,
          labels: {
            show: false,
            datetimeUTC: false,

            style: {
              colors: '#8C9AA6',
              fontSize: '12px'
            },
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          categories : []
        },
        yaxis: {
          show: false,
          style: {
            colors: '#8C9AA6',
            fontSize: '12px'
          },
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: true,
          },
          style: {
            fontSize: '11px',
            fontFamily: "Roboto, Helvetica, Arial , sans-serif"
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '11px',
          fontWeight: 500,
          showForSingleSeries: true,
          showForNullSeries: false,
          showForZeroSeries: false,
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
      },
      advertisedRatesHistorySeries: [],

      options: {
        chart: {
          id: 'CurrentPricing',
          toolbar: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        xaxis: {
          tooltip: { enabled: false },
          categories: [],
          labels: {
            show: false,
            style: {
              colors: '#8C9AA6',
              fontSize: '12px'
            },
          }
        },
        markers: {
          discrete: [{
            seriesIndex: 1,
            dataPointIndex: 0,
            fillColor: "#F69E4F",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 1,
            dataPointIndex: 1,
            fillColor: "#1B2C42",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#5C95F6",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 1,
            dataPointIndex: 3,
            fillColor: "#F3695D",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 1,
            dataPointIndex: 4,
            fillColor: "#3FA7A1",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#83CE83",
            strokeColor: "#FFF",
            size: 6
          },
          {
            seriesIndex: 1,
            dataPointIndex: 6,
            fillColor: "#FAC05F",
            strokeColor: "#FFF",
            size: 6
          },
          {
            seriesIndex: 1,
            dataPointIndex: 7,
            fillColor: "#959595",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 0,
            fillColor: "#F69E4F",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 1,
            fillColor: "#1B2C42",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#5C95F6",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 3,
            fillColor: "#F3695D",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 4,
            fillColor: "#3FA7A1",
            strokeColor: "#FFF",
            size: 6
          }, {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#83CE83",
            strokeColor: "#FFF",
            size: 6
          },
          {
            seriesIndex: 0,
            dataPointIndex: 6,
            fillColor: "#FAC05F",
            strokeColor: "#FFF",
            size: 6
          },
          {
            seriesIndex: 0,
            dataPointIndex: 7,
            fillColor: "#959595",
            strokeColor: "#FFF",
            size: 6
          },
          ]
        },
        tooltip: {
          enabled: true,
          followCursor: false,
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [{
        name: 'STORE NAME',
        data: []
      }],

      activeTab: 1,
      isLoading1: false,
      order: "asc",
      orderBy: "storeName",
      snackOpen: false,
      snackMSg: "",
      unitDurations: [],
      hisactivemenu: "1",
      hisactivedur: "2",
      gridactivedur: 1,
      uniquestore: "",
      grapghCatcheck: "Premium",
      graphOpen: "Premium",
      // SinglelahMarkets: {},
      CoreMarketView: [],
      ChartTableData: [],
      GridTableValue: {},
      CurrentHistory: [],
      AllMarketdata: [],
      AllCurrentHistory: [],
      MarketViewpricingOptions: [],
      isLoading: false,
      AvailDataCheck: 0,
      stId: this.props.storeId,
      seId: this.props.searchId,
      defaultWidth: 0,
      priceLength: 0,
      pricecolLength: 0,
      Tabloading: false,
      promo_open: false,
      promo_open_id: 0,
      uniquestores: [],
      pdfunique: 0,
      snackClr: "",
      pageCount: 0,
      dropdownOpen: false,
      drpDownSelValue: 12,
      tenancyArray: [3, 6, 9, 12],
      storeAdvertising: [],
      yearRange : 2,
      duplicate_filterData : false,
      filterData : [],
      dupliacteColor:[],

    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
    this.onSelecCatGrapgh = this.onSelecCatGrapgh.bind(this);
    this.DurationsdropDown = this.DurationsdropDown.bind(this);
    this.AlignGraphTabData = this.AlignGraphTabData.bind(this);
    this.AlignHistoryGraphData = this.AlignHistoryGraphData.bind(this);
    //this.GetSummaryMarketData = this.GetSummaryMarketData.bind(this);
    this.GetGridtableData = this.GetGridtableData.bind(this);
    this.GetGraphTableData = this.GetGraphTableData.bind(this);
    this.onDurAndPriChange = this.onDurAndPriChange.bind(this);
    this.onDurAndDayChange = this.onDurAndDayChange.bind(this)
    localStorage.setItem("StId", this.props.storeId);
    this.toggle = this.toggle.bind(this);
  }

  //Duration and Price Type Change
  onDurAndPriChange(e) {
    const { name, value } = e.target;
    let fields = this.state;
    fields[name] = value;
    if (name === "gridactivedur") {
      this.setState({ fields, isLoading1: true , filterData:[] }, () =>
        this.GetGridtableData()
      );
    } else {
      this.setState({ fields, isLoading1: true, advertisedRatesHistorySeries: [] , filterData : []}, () => {
        this.AlignHistoryGraphData()
        this.apexChartNoData({
          textMessage: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          colorCode: "#5b95f6"
        });
      }
      );
    }
  }

onDurAndDayChange(e) {
  const { name, value } = e.target;
  let fields = this.state;
  fields[name] = value;
    this.setState({ fields, isLoading: true, advertisedRatesHistorySeries: [], yearRange : value , filterData : []}, () => {
      this.GetcurrentpricinganalysisHistory()
      this.apexChartNoData({
        textMessage: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
        colorCode: "#5b95f6"
      });
    }
    );
}
  //Tabs
  tabtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          Tabloading: false,
          filterData:[],
          advertisedRatesHistorySeries:[]
          // Tabloading: tab === 4 ? false : true,
        },
        () => {
          this.commonapiCall();
          this.apexChartNoData({
            textMessage: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
            colorCode: "#5b95f6"
          });
        }
      );
    }
  }

  //Table Sorting
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    let sort = isDesc ? "asc" : "desc";
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }

  //To open Particular Category's Graph
  onSelecCatGrapgh(openActGraph) {
    let activeRow =
      this.state.grapghCatcheck === openActGraph ? "" : openActGraph;
    activeRow === ""
      ? this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow })
      : this.setState(
        { grapghCatcheck: activeRow, graphOpen: activeRow },
        () => {
          this.TableViewGraphs();
        }
      );
  }

  //Grid Actions
  barhandleChange(CompId) {
    //loader
    this.setState({ isLoading: true, pdfunique: 1 });

    if (this.state.GridTableValue.dynamdata) {
      //getting the ids of to show or hide the graph data
      let GraphshowData = this.state.GridTableValue.dynamdata.filter(function (
        data
      ) {
        return data.isSelected === true && data.storeID !== CompId;
      });

      if (GraphshowData.length > 6) {
        this.setState({
          isLoading: false,
          snackOpen: true,
          snackMSg:
            "You can view up to 8 markets only, please remove any of the selected markets to add new ones.",
        });
      } else {
        //to change the selected value which clicked on table
        
        let uniquestore = [
          ...new Set(
            this.state.GridTableValue.dynamdata
              .filter(function (data) {
                return data.isSelected === true
                  ? data.storeID !== CompId
                  : data.storeID === CompId;
              })
              .map((item) => item.storeID)
          ),
        ].join(", ");

        let uniquestores = [
          ...new Set(
            this.state.GridTableValue.dynamdata
              .filter(function (data) {
                return data.isSelected === true
                  ? data.storeID !== CompId
                  : data.storeID === CompId;
              })
              .map((item) => item.storeID)
          ),
        ].join("/");

        for (var i = 0; i < this.state.GridTableValue.dynamdata.length; i++) {
          if (this.state.GridTableValue.dynamdata[i].storeID === CompId) {
            this.state.GridTableValue.dynamdata[i].isSelected =
              this.state.GridTableValue.dynamdata[i].isSelected === true
                ? false
                : true;
          }
        }
        let GridTableValue = this.state.GridTableValue;

        //If User Remove all Markets, then uniquestore should be 0
        uniquestore = uniquestore === "" ? 0 : uniquestore;
        uniquestores = uniquestores === "" ? 0 : uniquestores;
        this.setState({ uniquestores });
        this.setState({ GridTableValue, uniquestore }, () => {
          this.GetGraphTableData();
          this.GetcurrentpricinganalysisHistory();
        });
      }
    }
  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  //Formatted in as per competitor
  formData(data) {
    let uniquestore = [...new Set(data.map((item) => item.storeID))];
    let CurrentActivity = this.state.grapghCatcheck;
    let Colorbarcode = [
      "#f69e4f",
      "#85411b",
      "#5b95f6",
      "#f96a5d",
      "#3ea7a1",
      "#83ce83",
      "#fac05f",
      "#959595",
    ];
    var FData = [];

    for (var t = 0; t < uniquestore.length; t++) {
      var tFData = data.filter(
        (a) =>
          a.storeID == uniquestore[t] && a.priceOptionName === CurrentActivity
      );
      var mData = {};
      if (tFData.length > 0) {
        let splitpriceVal = tFData[0].price.split("-");
        let priceVal = splitpriceVal[1]
          ? parseInt(splitpriceVal[0])
          : splitpriceVal[0] !== "0"
            ? parseInt(splitpriceVal[0])
            : 0;
        let p1 =
          CurrentActivity === "Price Range"
            ? splitpriceVal[1]
              ? parseInt(splitpriceVal[1])
              : parseInt(splitpriceVal[0])
            : 0;

        mData = {
          StoreID: uniquestore[t],
          StoreName: tFData[0].storeName,
          price: parseInt(priceVal),
          priceOptionName: tFData[0].priceOptionName,
          price1: p1,
          labelPrice: splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0],
          labelPrice2:
            splitpriceVal[1] !== "0" && splitpriceVal[1]
              ? splitpriceVal[1]
              : splitpriceVal[0] === "0"
                ? "N/A"
                : splitpriceVal[0],
          Gcolor: Colorbarcode[t],
          IsSelected: tFData[0].isSelected,
          isMonthly: tFData[0].isMonthly,
        };
      }

      FData.push(mData);

    }
    return FData;

  }

  
  //Graph for Table
  TableViewGraphs() {
    
    let { CoreMarketView, grapghCatcheck, ChartTableData } = this.state;
    let dataPremium = [];
    let dataPremium_labelPrice2 = [];
    let dataPremiumName = [];
    let colorsCode = ['rgba(0, 0, 0, 0.25)'];
    var currency = this.props.currency ? this.props.currency : "";
    this.setState({series:[]});
    let filterMarkets = CoreMarketView.filter(fl => fl.storeID > 0);
    let uniquestore = [...new Set(filterMarkets.map((item) => item.storeID))];
    for (var t = 0; t < uniquestore.length; t++) {
      let tFData = filterMarkets.filter((a) => a.storeID == uniquestore[t] && a.priceOptionName === grapghCatcheck);
      let noValues = tFData.every(item => item.price === null || item.price === '' || item.price === undefined);
      if (!noValues) {
        let splitpriceVal = tFData[0].price.split("-");
        let labelPrice = splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0];
        let labelPrice2 = splitpriceVal[1] !== "0" && splitpriceVal[1] ? splitpriceVal[1] : splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0];
        let storeName = tFData[0].storeName ? tFData[0].storeName : null;
        dataPremium.push(isNaN(labelPrice) ? null : labelPrice);
        dataPremium_labelPrice2.push(isNaN(labelPrice2) ? null : labelPrice2);
        dataPremiumName.push(storeName);
      }
    }
    let allViewGrid;
    if(grapghCatcheck == "Price Range"){
      allViewGrid = [{ name: "", data: dataPremium }, { name: "", data: dataPremium_labelPrice2 }];
    }else{
      allViewGrid = [{ name: "", data: dataPremium }];
    }
   
    if (dataPremium.length > 0) {
      this.setState({
        options: {
          chart: {
            id: `CurrentPricing${Math.random()}`,
            events: {
              beforeZoom: function(chartContext, { xaxis,yaxis }) {
                if(yaxis !== undefined || xaxis.min > 0) return chartContext.t.reset =`<img src=${resetPng} alt="Reset Zoom" width="20">`;
              },
           zoomed: function(chartContext, { xaxis, yaxis }) {
         if(yaxis === undefined || xaxis.min <= 0) return chartContext.toolbar.t.reset = false;
            }
          },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin:false,
                  zoomout:false,
                  pan:false,
                  reset:false,
                },
              }
          },
          stroke: {
            width: 2,
            curve: 'straight'
          },
          colors: colorsCode,
          legend: { show: false },
          grid : {
            show : false,
            padding: {
              right: ChartTableData.TableHead.length == 2 ? 0 : 50,
              left: 60
          },  
          },
          markers: {
            discrete: [{
              seriesIndex: 1,
              dataPointIndex: 0,
              fillColor: "#F69E4F",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 1,
              fillColor: "#1B2C42",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 2,
              fillColor: "#5C95F6",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 3,
              fillColor: "#F3695D",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 4,
              fillColor: "#3FA7A1",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 5,
              fillColor: "#83CE83",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 1,
              dataPointIndex: 6,
              fillColor: "#FAC05F",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 1,
              dataPointIndex: 7,
              fillColor: "#959595",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 0,
              fillColor: "#F69E4F",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 1,
              fillColor: "#1B2C42",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 2,
              fillColor: "#5C95F6",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 3,
              fillColor: "#F3695D",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 4,
              fillColor: "#3FA7A1",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 5,
              fillColor: "#83CE83",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 0,
              dataPointIndex: 6,
              fillColor: "#FAC05F",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 0,
              dataPointIndex: 7,
              fillColor: "#959595",
              strokeColor: "#FFF",
              size: 6
            },
            ]
          },
          tooltip: {
            custom: undefined,
            marker : {show : false},
            inverseOrder: true,
            x: {
              show: false,
            },
            y: {
              formatter: function (val, opts) {
                if (typeof (opts) === "object") {
                  let store = dataPremiumName.length > 0 ? dataPremiumName[opts.dataPointIndex] : '';
                  let cUp = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex] !== null ? currency + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex] : 'N/A';
                  if (!(opts.seriesIndex && opts.w.config.series[0].data[opts.dataPointIndex] === opts.w.config.series[1].data[opts.dataPointIndex])) {
                    let add = `${store} : ${cUp}`
                    return add;
                  }                
                }
              },
              title: {
                formatter: function (val) {
                  if (val) return "";
                }
              }
            },
            enabled: true,
            showForNullSeries: false,
            onDatasetHover: {
              highlightDataSeries: false,
            },
            style: {
              fontSize: '11px',
              fontFamily: "Roboto, Helvetica, Arial , sans-serif"
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value === null ? "N/A" : currency + value.toFixed(2);
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
          },
          xaxis: {
            categories: dataPremiumName,
            tooltip: {
              enabled: false,
            },
            labels: {
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value.toUpperCase();
                return ChartTableData.TableHead.length === 2 && val.length > 16 ? val.substring(0, 16) + "..." : val ;
              },
              style: {
                fontSize: '10px',
              },
              hideOverlappingLabels: false,
              trim: ChartTableData.TableHead.length === 2 ? false : true,
              show: true,
              rotate: 0,
            },
          },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: allViewGrid
      })
    } else {
      this.setState({
        options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            labels: {
              show: false
            },
            categories: []
          },
        },
        series: []
      })
    }
  }


  dropDownData(tFData) {
    var { hisactivemenu } = this.state;
    let dataSeries1 = [];
    let dataSeries2 = [];
    let CurrentActivity = hisactivemenu === "2" ? "Price Range" : "";
    let priceRange1, priceRange2, datePrice;
    let noValues = tFData.every(item => item.price === null || item.price === '' || item.price === undefined);
    if (!noValues) {
      if (CurrentActivity === "Price Range") {
        dataSeries1 = tFData.map(val => {
          let splitpriceVal = val.price.split("-");
          priceRange1 = parseInt(splitpriceVal[0]);
          priceRange2 = splitpriceVal[1] ? parseInt(splitpriceVal[1]) : parseInt(splitpriceVal[0]);
          datePrice = moment(val.datePrice, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          return { '0': { x: datePrice, y: priceRange1 === 0 ? null : priceRange1, fullName: val.price }, '1': { x: datePrice, y: priceRange2 === 0 ? null : priceRange2, fullName: val.price } };
        });
      } else {
        dataSeries2 = tFData.map(val => {
          priceRange1 = val.price;
          priceRange2 = 0;
          datePrice = moment(val.datePrice, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          return { x: datePrice, y: priceRange1 === '0' ? null : priceRange1, fullName: val.price };
        })
      }
      return [dataSeries1, dataSeries2];
    }
  }
  apexChartNoData = ({ textMessage, colorCode }) => {
    this.setState({
      advertisedRatesHistorySeries: [],
      filterData:[],
      advertisedRatesHistoryOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        noData: {
          text: textMessage,
          style: { color: colorCode }
        }
      }
    });
  }

  //Graph for History
  HistoryGraphView() {
    var { CurrentHistory, hisactivemenu, yearRange , filterData } = this.state;
    let actualData = [];
    let filter_actualData = [];
    let markerSize = [];
    let xaxis_data = [];
    var currency = this.props.currency ? this.props.currency : "";
    let colors = ['#f69e4f', '#1b2c42', '#85411b', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
    this.setState({filterData:[], advertisedRatesHistorySeries:[]});
    if (CurrentHistory.length > 0) {
      let unique = [...new Set(CurrentHistory.map((item) => item.storeID))];
      let CurrentActivity = hisactivemenu == "2" ? "Price Range" : "";
      for (var i = 0; i < unique.length; i++) {
        var tFData = CurrentHistory.filter((a) => a.storeID === unique[i]);
        var tFMarketName = CurrentHistory.find((a) => a.storeID === unique[i]);
        let dataSeries = this.dropDownData(tFData);
        
        if (dataSeries[0].length > 0) {

          actualData.push({ name: tFMarketName["storeName"]+[i+1], data: dataSeries[0].map(val => { return val[0] }), color: colors[i] }, { name: tFMarketName["storeName"]+[i+1] + " data", data: dataSeries[0].map(val => { return val[1] }), color: colors[i] });
          filter_actualData.push({name: tFMarketName["storeName"], data: dataSeries[0].map(val => { return val[0] }), color: colors[i]} , { name: tFMarketName["storeName"] , data: dataSeries[0].map(val => { return val[1] }), color: colors[i] });
          markerSize.push(10, 0);
        } else {
          actualData.push({ name: tFMarketName["storeName"], data: dataSeries[1], color: colors[i] });
          filter_actualData.push({name: tFMarketName["storeName"], data: dataSeries[1], color: colors[i]});
          markerSize.push(10);
        }
      }

      xaxis_data = actualData.map(item => item.data.map(i => i.x));
     
     let newData = [];
    filter_actualData.map(dataItem => dataItem.data.forEach(i => {
      if(i.y !== null ){
      filter_actualData.map(userItem => userItem.data.forEach(j => {
        if(j.y !== null){
          if((dataItem.name !== userItem.name) && (dataItem.color !== userItem.color)){
            if(( (i.y === j.y) && (i.x === j.x) ) || ( (i.x === j.x) && (i.fullName === j.fullName)) ){
             newData.push({name:dataItem.name ,color:dataItem.color});
           let filterData = newData.reduce((elements, obj, index) => {
              let existingData = elements.find(element =>
                element.name === obj.name && element.color === obj.color
              );
              if (!existingData) {
                elements.push(obj);
              }
              return elements;
            }, []);
            this.setState({filterData:filterData});
            }
          }
          }
          }))
        }
        }));

      let actualData_nullValChk = actualData.map(i=>i.data);
      let nullValChk_bool =[]
      for(let i=0 ; i<actualData_nullValChk.length; i++){
        let nullValChk_data = actualData_nullValChk[i].map(i=>i.y)
        if(nullValChk_data.every(item=>item === null)){
          nullValChk_bool.push(true)
        }else{
          nullValChk_bool.push(false)
        }
      }

      let condition = false;
    if(nullValChk_bool.every(item => item == true)){
      condition = true
    }else{
      condition = false
    }

      if ( condition == false) {
        this.setState({
          advertisedRatesHistorySeries: actualData,
          advertisedRatesHistoryOptions: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin:false,
                  zoomout:false,
                  pan:false,
                  reset:false,
                },
              },
              events: {
                legendClick: function (chartContext, seriesIndex, config) {
                  if (CurrentActivity === "Price Range") {
                    let marketName = config.config.series[seriesIndex].name + ' data';
                    if (config.config.series[seriesIndex + 1].name === marketName) chartContext.toggleSeries(marketName);
                  }
                },
                beforeZoom: function(chartContext, { xaxis,yaxis }) {
                  if(yaxis !== undefined || xaxis.min > 0) return chartContext.t.reset =`<img src=${resetPng} alt="Reset Zoom" width="20">`;
                },
          zoomed: function(chartContext, { xaxis, yaxis }) {
           if(yaxis === undefined || xaxis.min <= 0) return chartContext.toolbar.t.reset = false;
          },
          mouseMove: function(event, chartContext, config) {
            var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
            let Value = tooltip !== null && tooltip.style.top ? parseInt(tooltip.style.top) : 0;
            var series = config.config.series.filter(item => item.data.length !== 0);
            if(CurrentActivity === "Price Range"){
              if ((series.length == 1 || series.length == 2) && Value > 218) {
                chartContext.el.classList.add('new_tooltip_height1')
              }else if((series.length == 3 || series.length == 4) && Value > 185){
                chartContext.el.classList.add('new_tooltip_height2')
              }else if((series.length == 5 || series.length == 6) && Value > 150){
                chartContext.el.classList.add('new_tooltip_height3')
              }else if((series.length == 7 || series.length == 8) && Value > 117){
                chartContext.el.classList.add('new_tooltip_height4')
              }else if((series.length == 9 || series.length == 10) && Value > 83){
                chartContext.el.classList.add('new_tooltip_height5')
              }else if((series.length == 11 || series.length == 12) && Value > 50){
                chartContext.el.classList.add('new_tooltip_height6')
              }else if((series.length == 13 || series.length == 14) && Value > 0){
                chartContext.el.classList.add('new_tooltip_height7')
              }else if((series.length == 15 || series.length == 16)&& Value > 0){
                chartContext.el.classList.add('new_tooltip_height8')
              }else{
                chartContext.el.classList.remove('new_tooltip_height1')
                chartContext.el.classList.remove('new_tooltip_height2')
                chartContext.el.classList.remove('new_tooltip_height3')
                chartContext.el.classList.remove('new_tooltip_height4')
                chartContext.el.classList.remove('new_tooltip_height5')
                chartContext.el.classList.remove('new_tooltip_height6')
                chartContext.el.classList.remove('new_tooltip_height7')
                chartContext.el.classList.remove('new_tooltip_height8')
              }
            }else{
              if (series.length == 1 && Value > 218) {
                chartContext.el.classList.add('new_tooltip_height1')
              }else if(series.length == 2 && Value > 185){
                chartContext.el.classList.add('new_tooltip_height2')
              }else if(series.length == 3 && Value > 150){
                chartContext.el.classList.add('new_tooltip_height3')
              }else if(series.length == 4 && Value > 117){
                chartContext.el.classList.add('new_tooltip_height4')
              }else if(series.length == 5 && Value > 83){
                chartContext.el.classList.add('new_tooltip_height5')
              }else if(series.length == 6 && Value > 50){
                chartContext.el.classList.add('new_tooltip_height6')
              }else if((series.length == 7 || series.length == 8) && Value > 0){
                chartContext.el.classList.add('new_tooltip_height7')
              }else if(series.length > 8 && Value > 0){
                chartContext.el.classList.add('new_tooltip_height8')
              }
              else{
                chartContext.el.classList.remove('new_tooltip_height1')
                chartContext.el.classList.remove('new_tooltip_height2')
                chartContext.el.classList.remove('new_tooltip_height3')
                chartContext.el.classList.remove('new_tooltip_height4')
                chartContext.el.classList.remove('new_tooltip_height5')
                chartContext.el.classList.remove('new_tooltip_height6')
                chartContext.el.classList.remove('new_tooltip_height7')
                chartContext.el.classList.remove('new_tooltip_height8')
              }
            }
        
        }
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value === null ? "N/A" : currency + value.toFixed(2);
                },
                style: {
                  colors: '#8C9AA6',
                  fontSize: '10px'
                },
              },
            },
            xaxis: {
              tooltip: {
                enabled: false
              },
              categories:xaxis_data,
              
              labels: {
                show: true,
                rotate: 0,
                
                formatter: function(value, timestamp, opts) {
                    let val = value === undefined || typeof (value) !== "string" ? '' : parseInt(yearRange) < 4 ? moment(new Date(value)).format('DD-MMM-YYYY'):moment(new Date(value)).format('MMM-YYYY');   
                    return val;
                  },

                style: {
                  fontSize: '10px',
                },
              },
              tickPlacement: 'on',
              tickAmount: yearRange == 1 ? 4 : yearRange == 2 ? 4 : yearRange == 3 ? 12 : yearRange == 4 ? 5 : 9, 

              axisBorder: {
                show: true
              },
              axisTicks: {
                show: true
              }
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center',
              fontSize: '11px',
              fontWeight: 500,
              showForNullSeries: false,
              showForZeroSeries: true,
              showForSingleSeries: true,
              formatter: (seriesName, opts) => {
                if (seriesName.endsWith('data')) return '';
                else return CurrentActivity === "Price Range" ? seriesName.substring(0, seriesName.length - 1) :seriesName;
              },
              markers: {
                width: markerSize,
                height: markerSize,
              },
              itemMargin: {
                horizontal: 10,
                vertical: 20,
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            grid: {
              show: false,
              row: {
                colors: ['#FFFFFF']
              },
              padding: {
                left: 20,
                right: 40 // Also you may want to increase this (based on the length of your labels)
              },
          
            },
            tooltip: {
              enabled: true,
              showForNullSeries: false,
              onDatasetHover: {
                highlightDataSeries: false,
              },
              style: {
                fontSize: '10px',
                fontFamily: "Roboto, Helvetica, Arial , sans-serif"
              },
              x: {
                show: true,
                format: 'dd MMM yyyy',
                formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                  let dateList = w.config.series.filter(item => item.data.length !== 0)[0]
                    let valueDate = moment(new Date(dateList.data[dataPointIndex].x)).format('DD-MMM-YYYY');
                 
                    return  valueDate;
                    
                }
            },

              y: {
                title: {
                  formatter: (seriesName) => CurrentActivity === "Price Range" ? seriesName.substring(0, seriesName.length - 1) :seriesName,
                },
                formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                  if (CurrentActivity === "Price Range") {
                    let marketName = w.config.series[seriesIndex].name;
                    let forDat = typeof (w.config.series[seriesIndex].data[dataPointIndex]) === "object" ? w.config.series[seriesIndex].data[dataPointIndex].fullName !== "0" ? currency + w.config.series[seriesIndex].data[dataPointIndex].fullName : " N/A" : '';
                    if (!marketName.endsWith('data')) return ": " + forDat;
                  } else return val === null ? ': N/A' : ": " + currency + val;
                },
              },
              marker: {
                show: true,
              },
            },
            noData: {
              text: 'Please enable any one of the below legend to view the graph.',
              style: {
                color: "#5b95f6",
              }
            },
          },
        })
      }
      else {
        this.setState({
          advertisedRatesHistorySeries: [],
          filterData:[],
          advertisedRatesHistoryOptions: {
            chart: {
              toolbar: {
                show: false
              }
            },
            xaxis: {
              labels: {
                show: false
              },
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              }
            },
            yaxis: {
              show: false
            },
            noData: {
              text: "No Records Found",
              style:{ color: "#F60000" }
            }
          }
        });
      }
    } else {
      this.setState({
        advertisedRatesHistorySeries: [],
        filterData:[],
        advertisedRatesHistoryOptions: {
          xaxis: {
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: {
            show: false
          },
          noData: {
            text: "No Records Found",
            style:{ color: "#F60000" }
          }
        }
      });
    }
    
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
        hisactivedur: "2",
        hisactivemenu: "1",
        uniquestore: "",
        uniquestores: [],
        pdfunique: 0,
      },
      () => {
        this.commonapiCall();
      }
    );
  }

  commonapiCall() {
    this.DurationsdropDown();
    this.GetGraphTableData();
   // this.TableViewGraphs();
    //this.GetSummaryMarketData();
    this.GetGridtableData();
    this.GetcurrentpricinganalysisHistory();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchId !== this.state.seId) {
      this.setState(
        {
          hisactivedur: "2",
          seId: nextProps.searchId,
          stId: nextProps.storeId,
        },
        () => {
          this.componentDidMount();
        }
      );
    }
  }

  //Duration For DropDown
  DurationsdropDown() {

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .get(CONFIG.API_URL + "unit/unitdurations")
      .then((response) => {
        let unitDurations = response.data.data.lookupData;
        this.setState({
          unitDurations,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });


  }

  // Grid Table data
  GetGridtableData() {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: 1,//this.state.activeTab,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName =
      this.state.activeTab === 4
        ? "effectiverateallmarketview"
        : "currentpricinganalysisallmarketviews";
    axios
      .post(CONFIG.API_URL + "dashboard/" + hostName, inputData)
      .then((res) => {
        let GridTableValue = this.state.GridTableValue;
        let AllMarketdata = res.data.current.currentPricingAnalysisAllMarket;
        let storeAdvertising = AllMarketdata.filter(x => x.isNotCurrentlyAdvertised === true)[0];
        //To get Only Your Store on All Market View
        GridTableValue["statdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore === 1;
        });
        //To get Stores Expect Your Store on All Market View
        GridTableValue["dynamdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore !== 1;
        });
        this.setState({ GridTableValue, AllMarketdata, isLoading1: false, storeAdvertising });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  //History Graph
  GetcurrentpricinganalysisHistory() {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    this.setState({
      AllCurrentHistory: [],
      isLoading: true,
    });

    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.hisactivedur),
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.hisactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName =
      this.state.activeTab === 4
        ? "effectiveratehistories"
        : "currentpricinganalysisHistories";

    axios
      .post(CONFIG.API_URL + "dashboard/" + hostName, inputData)
      .then((response) => {
        let AllCurrentHistory = response.data.current;
        this.setState({ AllCurrentHistory }, () => {
          this.AlignHistoryGraphData();
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Data Format HistoryGraph View
  AlignHistoryGraphData() {
    const { AllCurrentHistory, hisactivemenu } = this.state;

    //Based Dropdown above the History graph
    let CurrentActivity =
      hisactivemenu === "1"
        ? "Premium"
        : hisactivemenu === "2"
          ? "Price Range"
          : "Value";
    // Get Current priceOptionName
    let filterByMenu = AllCurrentHistory.filter(
      (a) => a.priceOptionName === CurrentActivity
    );

    //Spliting mystore and Non-mystore data to order
    let getMystore = filterByMenu.filter((a) => a.isMyMarket === 1);
    let getwithoutMystore = filterByMenu.filter((a) => a.isMyMarket === 0);

    let CurrentHistory = getMystore.concat(getwithoutMystore);
   
    setTimeout(
      function () {
        this.setState(
          { CurrentHistory, isLoading: false, isLoading1: false, advertisedRatesHistorySeries:[]},
          () => {
            this.HistoryGraphView();
          }
        );
      }.bind(this),
      3000
    );
  }

  //Graph Table data
  GetGraphTableData() {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors: this.state.uniquestore,
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      // Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName =
      this.state.activeTab === 4
        ? "effectiveratemarketview"
        : "currentpricinganalysiscoremarketview";
    axios
      .post(CONFIG.API_URL + "dashboard/" + hostName, inputData)
      .then((res) => {
        let CoreMarketView = res.data.current.currentPricingAnalysisMarket;
        let MarketViewpricingOptions = res.data.current.currentPricingAnalysis;


        this.setState(
          {
            CoreMarketView,
            MarketViewpricingOptions,
            isLoading1: false,
            Tabloading: false,
          },
          () => {
            //  if (this.state.activeTab !== 4) {
            this.AlignGraphTabData();
            //  }
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoading1: false, Tabloading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Data Format table View
  AlignGraphTabData() {
    const { CoreMarketView } = this.state;

    let ChartTableData = this.state.ChartTableData;

    //Checking for data availability
    let AvailDataCheck = [];

    AvailDataCheck = CoreMarketView.filter(function (data) {
      return data.storeID > 0 && data.price != 0;
    });

    //For table Row Height controll
    let priceLength = this.state.priceLength;
    let pricecolLength = this.state.pricecolLength;

    /* Spliting the data in the order of activityType */
    ChartTableData["lahMarkets"] = [];
    if (AvailDataCheck.length > 0) {
      if (this.state.activeTab === 3) {
        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0;
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Promotions",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0;
          }),
        });
        /* End of Barchart table's body data */
      } else {
        //For table Row Height controll

        //}), d => (d.price + (d.alternatePriceTag ? '(' + d.alternatePriceTag + ')' : '')).length);

        pricecolLength = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Price Range";
        }).length;
        // End of For table Row Height controll

        //For Premium data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Premium";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Premium"
            );
          }),
        });

        //For Price Range data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Price Range";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Price Range"
            );
          }),
        });

        //For Value data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Value";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return data.isMyStore === true && data.priceOptionName === "Value";
          }),
        });

        /* end of Spliting the data in the order of activityType */

        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Premium";
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Premium",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Premium";
          }),
        });

        //For Price Range data
        ChartTableData["TableBody"].push({
          td1: "Price Range",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Price Range";
          }),
        });

        //For Value data
        ChartTableData["TableBody"].push({
          td1: "Value",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Value";
          }),
        });
      }
    }
    if (
      this.state.graphOpen === "" ||
      this.state.activeTab === 3 ||
      AvailDataCheck.length === 0
    ) {
      
      this.setState({
        ChartTableData,
        AvailDataCheck: AvailDataCheck.length,
        pricecolLength,
        priceLength,
      });
    } else {
      this.setState(
        {
          ChartTableData,
          AvailDataCheck: AvailDataCheck.length,
          pricecolLength,
          priceLength,
          
        });
        this.TableViewGraphs();
    }
  }

  //Promotions Pop Up
  promohandleOpen(id) {
    this.setState({
      promo_open: true,
      promo_open_id: id,
    });
  }

  promohandleClose() {
    this.setState({
      promo_open: false,
      promo_open_id: 0,
    });
    // this.setState(prevState => ({
    //     promo_open: !prevState.promo_open,  modalPromos: []
    //   }));
  }
  //End OF PRomotions Pop Up
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = this.state.gridactivedur;
    let usId = this.props.storeId;
    let searchId = this.props.searchId;
    let accessKey = localStorage.getItem("SecretKey");
    let priceType = this.state.hisactivedur;
    let unitType = this.state.hisactivemenu;
    let orderBy = this.state.orderBy;
    let order = this.state.order;
    let unitTypes =
      this.state.uniquestores && this.state.pdfunique == 1
        ? this.state.uniquestores
        : this.state.pdfunique != 1
          ? "no"
          : "";
    let grapghCatcheck =
      this.state.grapghCatcheck == "Premium"
        ? 1
        : this.state.grapghCatcheck == "Value"
          ? 3
          : this.state.grapghCatcheck == "Price Range"
            ? 2
            : 4;
    let tDuration = this.state.drpDownSelValue;
    //    let activeTab = this.state.activeTab;
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "CurrentPricing" + "_" + timestamp + ".pdf";
    var stoName =
      this.props.storeDetails &&
        this.props.storeDetails.storeName &&
        this.props.storeDetails.storeName !== null &&
        this.props.storeDetails.storeName !== "" &&
        this.props.storeDetails.storeName !== undefined
        ? this.props.storeDetails.storeName
        : "Your Store";
    var fileHeading =
      this.state.activeTab === 4 ? "CurrentEffectiveRate" : "CurrentPricing";
    var fileNamePdf = stoName + "_" + fileHeading + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = this.state.activeTab === 4 ? 13 : 8;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/" +
        tDuration +
        "/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/" +
        grapghCatcheck +
        "/" +
        unitTypes,
      FileName: fileName,
    };
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  //pagination next page
  handleChangePage = (event, pageCount) => {
    this.setState({ pageCount });
  };

  //change next set of page in a row
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  //open/close dropdown
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  //handlechange for duration
  handleFilterChange = (e, selValue) => {
    this.setState(
      {
        drpDownSelValue: selValue,
      },
      () => this.commonapiCall()
    );
  };

  //price conversion
  priceConversion(tot) {
    if (tot !== "" && tot !== null) {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }

  render() {
    const {
      activeTab,
      promo_open,
      isLoading,
      pricecolLength,
      order,
      orderBy,
      grapghCatcheck,
      graphOpen,
      ChartTableData,
      /*SinglelahMarkets,*/ GridTableValue,
      unitDurations,
      MarketViewpricingOptions,
      snackOpen,
      snackMSg,
      AvailDataCheck,
      pageCount,
      dropdownOpen,
      drpDownSelValue,
      tenancyArray,
      storeAdvertising,
      dupliacteColor,
      duplicate_filterData,
      filterData,
    } = this.state;
    let ComActivityType = ["Premium", "Price Range", "Value"];
    let Colors = [
      "mystorebarclr",
      "barclr1",
      "barclr2",
      "barclr3",
      "barclr4",
      "barclr5",
      "barclr6",
      "barclr7",
    ];
    let moneySymbol = this.props.currency ? this.props.currency : "";
    var hlpcnt;
    if (activeTab === 1) {
      hlpcnt =
        "<h6>" +
        `${CONFIG.RegionId === 3
          ? "Current Advertised Weekly Rates"
          : "Current Advertised Rates"
        }` +
        "</h6> <p>In this section, the graph enables you to compare rates like-for-like, against your top competitors*.  The rates shown on the graph are based on the Online Advertised Rate on the store's website.</p><p>The Number of Pricing Options shows you if the selected store* offers more than one price type for this unit type.</p><ul><li>The highest price advertised by a store is shown as the Premium rate.</li><li>The lowest price advertised by a store is shown as the Value rate.</li><li>Any other rates in between are shown as a range</li><li>If there is only one price for a unit, it is shown as both the Premium and Value rate</li></ul><p>Market Low, Market Average and Market High</p><p>These rates are based on the online rates advertised by the competitor stores* and the price type chosen (Premium or Value).</p><p>*See the All Market View section below to learn how you can change the competitors you can compare against on this graph.</p><p><i>*Your store prices are included in the calculations.</i></p>";
    } else if (activeTab === 3) {
      hlpcnt =
        "<h6>Promotions</h6><p>These are unit-specific promotions advertised by the store for the above unit type.</p>";
    } else {
      hlpcnt =
        "<h6>" +
        `${CONFIG.RegionId === 3
          ? "Advertised Walk-in Weekly Rates"
          : "Advertised Walk-in Rates"
        }` +
        "</h6><p>These are the walk-in (or 'Standard') rates offered by the store for this unit type.</p>";
    }
    const defaultProps = {
      helpTitle: "Core Market View",
      helpContent:
        hlpcnt +
        "<p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/EhEUYS4SMAA' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps1 = {
      helpTitle: "Historical Pricing Analysis",
      helpContent:
        "<p>View historical rates for this unit type over the last 5 days, 30 days, 90 days, 6 months or 1 year, choose the time range from the drop down menu. You can also view rates by pricing type - Premium, Value or both. See the All Market View section below to learn how you can change the competitors you can compare against on this graph. These rates are based on the " +
        `${CONFIG.RegionId === 3
          ? "Advertised Online Weekly Rate."
          : "Advertised Online Rate."
        }` +
        "</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p> <iframe width='472' height='248' src='https://www.youtube.com/embed/ZBHn_uCFJSI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps2 = {
      helpTitle: "All Market View",
      helpContent:
        "<ul><li>These are all the stores in this Market offering this unit type.</li><li>Select up to a total of 8 competitor stores listed below to compare against in the Current Pricing analysis section or Historical pricing view. You can remove any stores from the graph at any time.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>",
    };

    return (
      <div className="currentprice">
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.isLoading1 ? (
          <div className="loader-wrap">
            <div className="loading loading-center"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.Tabloading ? (
          <div className="loader-wrap">
            <div className="loading loading-center"></div>
          </div>
        ) : (
          ""
        )}
        <div item="true" xs={12}>
          <Row>
            <Col md="6" id="cpanalysis">
              <div className="ms_charthead pt-3">
                <Row>
                  <Col>
                    <h6 className="core">
                      Core Market View <HelpTextPopup parm={defaultProps} />
                      <a href="#cpgrid" className="add_edit_link">
                        Go to All Market View to Add/Remove Stores{" "}
                      </a>
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <Button
                disabled={!isLoading && AvailDataCheck != 0 ? false : true}
                className={
                  AvailDataCheck != 0
                    ? "mt-3 apply-btn download-pdf "
                    : "download-pdf-disabled"
                }
                onClick={this.downLoadPDF.bind(this)}
              >
                <FontAwesomeIcon icon={faFilePdf} />
                PDF Download
              </Button>
              {activeTab === 4 ? (
                <div class="tenancy-cot">
                  <h5>Tenancy Length</h5>
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                      {drpDownSelValue === 0
                        ? "-Select-"
                        : drpDownSelValue + " months"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {tenancyArray &&
                        tenancyArray.map((drpDownValue) => {
                          return (
                            <DropdownItem
                              onClick={(e) =>
                                this.handleFilterChange(e, drpDownValue)
                              }
                            >
                              {drpDownValue + " months"}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              ) : (
                ""
              )}
            </Col>

            <Col
              md="12"
              className="sec-tabs-current"
              style={{ position: "relative" }}
            >
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 1 ? "active" : ""}
                    onClick={this.tabtoggle.bind(this, 1)}
                  >
                    {CONFIG.RegionId === 3
                      ? "Current Advertised Weekly Rates"
                      : "Current Advertised Rates"}
                  </NavLink>
                </NavItem>
                {CONFIG.RegionId === 3 ? '' : <>  <NavItem>
                  <NavLink
                    className={activeTab === 4 ? "active" : ""}
                    onClick={this.tabtoggle.bind(this, 4)}
                  >
                    Current Effective Rates
                  </NavLink>
                </NavItem></>}
                <NavItem>
                  <NavLink
                    className={activeTab === 3 ? "active" : ""}
                    onClick={this.tabtoggle.bind(this, 3)}
                  >
                    Promotions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 2 ? "active" : ""}
                    onClick={this.tabtoggle.bind(this, 2)}
                  >
                    {CONFIG.RegionId === 3
                      ? "Advertised Walk-in Weekly Rates"
                      : "Advertised Walk-in Rates"}
                  </NavLink>
                </NavItem>
                {CONFIG.RegionId === 3 ? (
                  <p
                    className="sqft-disclaimer mb-0 text-right"
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "5px",
                    }}
                  >
                    * This rate is calculated from an advertised monthly rate
                  </p>
                ) : (
                  ""
                )}
              </Nav>
            </Col>
            {AvailDataCheck > 0 ? (
              <React.Fragment>
                <Col
                  md="12"
                  lg={activeTab === 3 ? 12 : 9}
                  className={
                    activeTab === 3 ? "padding-right-1" : "padding-right-0"
                  }
                >
                  <div className="ms_charts p-3 padding-right-0 border-right-1 online-price-table">
                    <ReactTable className="msc activity-table" responsive>
                      <thead>
                        {ChartTableData.TableHead &&
                          ChartTableData.TableHead.length > 0 ? (
                          <tr>
                            <th id="getthwidth">Store Name</th>
                            {ChartTableData.TableHead.map((th_data, index) => {
                              let sAddress = th_data.address
                                ? ", \n" + th_data.address
                                : "";
                              const text = (
                                <div style={{ whiteSpace: "pre-line" }}>
                                  {th_data.storeName + sAddress}
                                </div>
                              );
                              let dotline;
                              let storName;
                              if (ChartTableData.TableHead.length > 5) {
                                dotline =
                                  th_data.storeName.length > 18 ? ".." : "";
                                storName =
                                  th_data.storeName.substring(0, 18) + dotline;
                              } else if (
                                ChartTableData.TableHead.length === 4 ||
                                ChartTableData.TableHead.length === 5
                              ) {
                                dotline =
                                  th_data.storeName.length > 40 ? ".." : "";
                                storName =
                                  th_data.storeName.substring(0, 40) + dotline;
                              } else {
                                storName = th_data.storeName;
                              }

                              return (
                                <Tooltip title={text} placement="top">
                                  <th
                                    className={Colors[index] + " thdatawidth"}
                                    key={th_data.storeID}
                                  >
                                    {storName}
                                  </th>
                                </Tooltip>
                              );
                            })}
                          </tr>
                        ) : (
                          <tr>
                            <th>Store Name</th>
                          </tr>
                        )}
                      </thead>
                      <tbody className="walk-in-rates">
                        {MarketViewpricingOptions &&
                          MarketViewpricingOptions.length > 0 ? (
                          <tr className="disabledrow">
                            <td>
                              <span className="markets-label">
                                No.of Pricing Options
                              </span>
                            </td>
                            {MarketViewpricingOptions.map((po_row) => {
                              return (
                                <td key={po_row.storeID}>
                                  {po_row.pricingOptions}
                                </td>
                              );
                            })}
                          </tr>
                        ) : (
                          <tr className="disabledrow disablegraphrow">
                            <td>
                              <span className="markets-label">
                                No.of Pricing Options
                              </span>
                            </td>
                          </tr>
                        )}
                        {
                          this.state.Tabloading ? (
                            ""
                          ) :
                            ChartTableData.TableBody &&
                              ChartTableData.TableBody.length > 0 ? (
                              ChartTableData.TableBody.map((row1, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td align="right" className="ms_tablebtn">
                                        <span className="markets-label">
                                          {row1.td1}
                                        </span>
                                        {(activeTab === 1 || activeTab === 4) ? (
                                          grapghCatcheck === row1.td1 ? (
                                            <Button
                                              color="danger"
                                              className="add-to-graph"
                                              onClick={() =>
                                                this.onSelecCatGrapgh(row1.td1)
                                              }
                                              size="sm"
                                            >
                                              <img src={tableminus} alt="" />
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              className="remove-to-graph"
                                              onClick={() =>
                                                this.onSelecCatGrapgh(row1.td1)
                                              }
                                              size="sm"
                                            >
                                              <img src={tableplus} alt="" />
                                            </Button>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      {row1.td2.map((row) => {
                                        // let alterLabel = row.alternatePriceTag ? '('+row.alternatePriceTag+')':'' ;
                                        var tdVal =
                                          row.price !== null && row.price !== "0"
                                            ? moneySymbol + row.price
                                            : "N/A";
                                        var monthlyValues =
                                          row.isMonthly &&
                                            row.isMonthly === 1 &&
                                            CONFIG.RegionId === 3
                                            ? "*"
                                            : "";
                                        if (activeTab === 3) {
                                          tdVal =
                                            row.promo &&
                                              row.promo !== null &&
                                              row.promo !== "0"
                                              ? row.promo.split("*~*").join("<hr/>")
                                              : "No specials";
                                        }
                                        return (
                                          <React.Fragment key={row.storeID}>
                                            <td
                                              dangerouslySetInnerHTML={
                                                activeTab === 3
                                                  ? { __html: tdVal }
                                                  : {
                                                    __html: tdVal + monthlyValues,
                                                  }
                                              }
                                            ></td>
                                          </React.Fragment>
                                        );
                                      })}
                                    </tr>
                                    {/* <tr className={graphOpen === row1.td1 ? "legend-table" : 'disablegraphrow legend-table'}><td colSpan="9">(O) - Online Price<span className="pl-3">(R) - Regular Price</span></td></tr> */}
                                    <tr
                                      className={
                                        graphOpen === row1.td1 &&
                                          (activeTab === 1 || activeTab === 4)
                                          ? "set-gray-background"
                                          : "disablegraphrow"
                                      }
                                    >
                                      <td colSpan={row1.td2.length + 1} className="centerAlign" style={{ padding: '0px' }}>
                                        <Chart options={this.state.options} series={this.state.series} type="line" className="label-align" style={{ width: "100%" }} height={220} />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <tr>0
                                <td>
                                  {isLoading === false
                                    ? "There are no competitors available in this market."
                                    : ""}
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </ReactTable>
                  </div>
                </Col>
                {activeTab !== 3 ? (
                  <Col md="12" lg="3">
                    <div className="ys_cont pt-3">
                      <ReactTable className="mss market-right-table" responsive>
                        <thead>
                          <tr>
                            <th className="ml">Market Low</th>
                            <th className="ma">Market Average</th>
                            <th className="mh">Market High</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.Tabloading ? (
                            ""
                          ) : ChartTableData.lahMarkets &&
                            ChartTableData.lahMarkets.length > 0 ? (
                            ChartTableData.lahMarkets.map((tr1data, index) => {
                              return (
                                <React.Fragment>
                                  <tr>
                                    {tr1data.tr1.map((td, index) => {
                                      let HclassName =
                                        (activeTab === 1 || activeTab === 4) ? "" : "other_price";
                                      if (
                                        td.priceOptionName === "Price Range"
                                      ) {
                                        // width lesser than 1367
                                        if (
                                          window.innerWidth < 1367 &&
                                          pricecolLength > 7 &&
                                          this.state.priceLength > 11
                                        ) {
                                          HclassName = "set-height";
                                        } else if (
                                          window.innerWidth < 1367 &&
                                          pricecolLength > 6 &&
                                          this.state.priceLength > 14
                                        ) {
                                          HclassName = "set-height";
                                        }

                                        // width equal to 1367
                                        if (
                                          window.innerWidth === 1367 &&
                                          pricecolLength > 7 &&
                                          this.state.priceLength > 12
                                        ) {
                                          HclassName = "set-height";
                                        } else if (
                                          window.innerWidth === 1367 &&
                                          pricecolLength > 6 &&
                                          this.state.priceLength > 14
                                        ) {
                                          HclassName = "set-height";
                                        }

                                        // width greater than 1367
                                        if (
                                          window.innerWidth > 1367 &&
                                          pricecolLength > 7 &&
                                          this.state.priceLength > 15
                                        ) {
                                          HclassName = "set-height";
                                        } else if (
                                          window.innerWidth > 1367 &&
                                          window.innerWidth < 1438 &&
                                          pricecolLength > 6 &&
                                          this.state.priceLength > 14
                                        ) {
                                          HclassName = "set-height";
                                        }
                                      }
                                      return (
                                        <React.Fragment key={index}>
                                          {td.storeName === "MarketLow" ? (
                                            <td className={HclassName + " ml "}>
                                              {td.priceOptionName ===
                                                "Price Range"
                                                ? " - "
                                                : td.price
                                                  ? moneySymbol + td.price
                                                  : "N/A"}
                                            </td>
                                          ) : td.storeName === "MarketAvg" ? (
                                            <td className={HclassName + " ma "}>
                                              {td.priceOptionName ===
                                                "Price Range"
                                                ? " - "
                                                : td.price
                                                  ? moneySymbol + td.price
                                                  : "N/A"}
                                            </td>
                                          ) : td.storeName === "MarketHigh" ? (
                                            <td className={HclassName + " mh "}>
                                              {td.priceOptionName ===
                                                "Price Range"
                                                ? " - "
                                                : td.price
                                                  ? moneySymbol + td.price
                                                  : "N/A"}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                        </React.Fragment>
                                      );
                                    })}
                                  </tr>

                                  {tr1data.tr2.length > 0 &&
                                    (activeTab === 1 || activeTab === 4) ? (
                                    tr1data.tr2.map((rowdata, index) => {
                                      const priceRangeFormatRegex = /^\d+(\.\d{1,2})?-\d+(\.\d{1,2})?$/;
                                      return (
                                        <tr
                                          key={index}
                                          className={
                                            graphOpen ===
                                              rowdata.priceOptionName
                                              ? ""
                                              : "disablegraphrow"
                                          }
                                        >
                                          <td colSpan="3">
                                            <div
                                              className="pb-4 pt-6 pl-0 pr-0 set-min-heights"
                                              align="center"
                                            >
                                              <Typography
                                                variant="caption"
                                                className="your-score"
                                              >
                                                {CONFIG.RegionId === 3
                                                  ? "Your Weekly Price"
                                                  : "Your Price"}
                                              </Typography>
                                              <Typography
                                                variant="h6"
                                                className="score-value"
                                              >
                                                {rowdata.price &&
                                                  rowdata.price !== "0"
                                                  ? priceRangeFormatRegex.test(rowdata?.price) ? moneySymbol + rowdata?.price : moneySymbol + this.priceConversion(rowdata?.price) 
                                                  : "N/A"}
                                              </Typography>
                                              {rowdata.myStorePriceRank ? (
                                                <h5>
                                                  {rowdata.myStorePriceRank}
                                                </h5>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr
                                      key={index}
                                      className={
                                        graphOpen === ComActivityType[index] &&
                                          (activeTab === 1 || activeTab === 4) &&
                                          graphOpen !== "Price Range"
                                          ? ""
                                          : "disablegraphrow"
                                      }
                                    >
                                      <td colSpan="3">
                                        <div
                                          className="p-4 pt-6 pb-6"
                                          align="center"
                                        >
                                          <Typography
                                            variant="caption"
                                            className="your-score"
                                          >
                                            {CONFIG.RegionId === 3
                                              ? "Your Weekly Price"
                                              : "Your Price"}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            className="score-value"
                                          >
                                            N/A
                                          </Typography>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="3" align="center">
                                N/A
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </ReactTable>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              <Col>
                {/* <Typography variant="body1" className="no-data p-4">{isLoading === false ? 'There are no competitors available in this market.' : ''}</Typography> */}
                <Typography variant="body1" className="no-data p-4">
                  {GridTableValue.dynamdata || GridTableValue.statdata
                    ? MarketViewpricingOptions.length === 1
                      ? "You have to select at least one competitor to show data here."
                      : "There are no competitors available in this market."
                    : ""}
                </Typography>
              </Col>
            )}
          </Row>
          <div>
            <Row>
              <Col md="12" id="cpanalysis" className="history-graph">
                <div className="ms_charthead pt-3">
                  <h6>
                    {activeTab === 4
                      ? "Current Effective Rates History"
                      : "Current Advertised Rates History"}
                    <HelpTextPopup parm={defaultProps1} />
                    <AvForm>
                      <AvField
                        type="select"
                        className="mr-3 ml-3"
                        onChange={this.onDurAndPriChange}
                        value={this.state.hisactivemenu}
                        name="hisactivemenu"
                        id="hisactivemenu"
                      >
                        <option value={"1"}>Premium</option>
                        <option value={"2"}>Price Range</option>
                        <option value={"3"}>Value</option>
                      </AvField>

                      <AvField
                        type="select"
                        className="mr-3 ml-3"
                        onChange={this.onDurAndDayChange}
                        value={this.state.hisactivedur}
                        name="hisactivedur"
                        id="hisactivedur"
                      >
                        {unitDurations && unitDurations.length > 0 ? (
                          unitDurations.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.timePeriod}
                            </option>
                          ))
                        ) : (
                          <option value={"2"}>0 Days</option>
                        )}
                      </AvField>

                    </AvForm>
                  
                  </h6>
                    {
                      filterData.length > 0 ?   <div className="duplicate-data-show">
                            <img src={duplicate} />
                     <div>
                          {
                            filterData.map((item)=>{
                              return(
                                <>
                                <div className="legend-box-color" style={{background: item.color }}></div>
                                <span>{item.name}</span>
                                </>
                              )
                            })
                          }
                          
                
                          <b> has same value in the series. Please hover your mouse to see detailed information.</b> 
                          </div>
                  </div> : ""

                    }
                
                  <div className="clear"></div>
                </div>
              </Col>

                   <div className="mainhisGraphdiv col-md-12">
                          <div className="hisGraph legend-space tooltip-height-changes">
                          <Chart options={this.state.advertisedRatesHistoryOptions} series={this.state.advertisedRatesHistorySeries} type="line" style={{ width: "100%" }} height={350} />
                          </div>
                   </div>       
             
            </Row>
            <Row>
              <Col md="12" id="cpgrid">
                <div className="ms_charthead pt-3 market-view-head">
                  <Row>
                    <Col>
                      <h6 className="mb-1" style={{ display: "block" }}>
                        All Market View <HelpTextPopup parm={defaultProps2} />
                        {/* {activeTab === 4 ? (
                          ""
                        ) : ( */}
                        <AvForm>
                          <AvField
                            type="select"
                            className="mr-3 ml-3"
                            onChange={this.onDurAndPriChange}
                            value={this.state.gridactivedur}
                            name="gridactivedur"
                            id="gridactivedur"
                          >
                            {unitDurations && unitDurations.length > 0 ? (
                              unitDurations.map((unit) => (
                                <option key={unit.id} value={unit.id}>
                                  {unit.timePeriod}
                                </option>
                              ))
                            ) : (
                              <option value={2}>0 Days</option>
                            )}
                          </AvField>
                        </AvForm>
                        {/* )} */}
                        <a href="#cpanalysis" className="add_edit_link">
                          Go to Core Market View{" "}
                        </a>

                      </h6>
                    </Col>
                    <Col>
                      {CONFIG.RegionId === 3 ? (
                        <div className="mb-1" >
                          {storeAdvertising ? <p
                            className="sqft-disclaimer mb-0 text-right"
                            style={{ marginTop: "12px" }}
                          >
                            <sup style={{ color: 'red', fontSize: '11px', top: '0em' }}>*</sup> This store is not currently advertising a rate for this unit.
                          </p> : ''}

                          <p
                            className="sqft-disclaimer mb-0 text-right"
                            style={{ marginTop: "2px" }}
                          >
                            * This rate is calculated from an advertised monthly
                            rate
                          </p>
                        </div>
                      ) : (<>
                        {storeAdvertising ? <p
                          className="sqft-disclaimer mb-0 text-right"
                          style={{ marginTop: "12px" }}
                        >
                          <sup style={{ color: 'red', fontSize: '11px', top: '0em' }}>*</sup> This store is not currently advertising a rate for this unit.
                        </p> : ''}
                      </>
                      )}
                    </Col>
                  </Row>
                  <div className="clear"></div>
                </div>
              </Col>
              <div className="col-lg-12 col-md-12">
                <div className="ms_table pd-3 display-grid">
                  <ReactTable
                    className="mst all-market-table activity-table"
                    responsive="true"
                  >
                    <EnhancedTableHead
                      activeTabs={activeTab}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={
                        GridTableValue.dynamdata
                          ? GridTableValue.dynamdata.length
                          : 0
                      }
                    />
                    {GridTableValue.dynamdata || GridTableValue.statdata ? (
                      <tbody>
                        {GridTableValue.statdata ? (
                          GridTableValue.statdata.map((row, index) => {
                            const labelId = index;
                            // let alterLabel = row.alternatePrice ? '('+row.alternatePrice+')':''
                            let ys_prom_tions =
                              row.promos && row.promos !== null
                                ? row.promos.replace(/~/g, "<hr/>")
                                : "No specials";
                            return (
                              <tr key={row.storeID} className="disabled_color">
                                <td
                                  className="S_captalize"
                                  align="center"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <span className="store-names">
                                    {row.storeName}
                                  </span>
                                  <span className="stores-address current-pr-store">
                                    {row.storeAddress}
                                  </span>
                                </td>
                                <td align="center">
                                  {row.currentPremium !== null &&
                                    row.currentPremium !== 0
                                    ? moneySymbol + row.currentPremium
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.currentPremium !== 0 && row.currentPremium !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row.currentValue !== null &&
                                    row.currentValue !== 0
                                    ? moneySymbol + row.currentValue
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.currentValue !== 0 && row.currentValue !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row.premium !== null && row.premium !== 0
                                    ? moneySymbol + row.premium
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.premium !== 0 && row.premium !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row.value !== null && row.value !== 0
                                    ? moneySymbol + row.value
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.value !== 0 && row.value !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  <ShowMoreText
                                    lines={2}
                                    more="more"
                                    less="less"
                                    expanded={false}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: ys_prom_tions,
                                      }}
                                    />
                                  </ShowMoreText>
                                </td>
                                {activeTab !== 4 ? <td align="center">
                                  {row.currentWalkInPremium !== null &&
                                    row.currentWalkInPremium !== 0
                                    ? moneySymbol + row.currentWalkInPremium
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.currentWalkInPremium !== 0 && row.currentWalkInPremium !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td> : ''}
                                {activeTab !== 4 ? <td align="center">
                                  {row.currentWalkInValue !== null &&
                                    row.currentWalkInValue !== 0
                                    ? moneySymbol + row.currentWalkInValue
                                    : "N/A"}
                                  {row.isMonthly &&
                                    row.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row.currentWalkInValue !== 0 && row.currentWalkInValue !== null &&
                                    row.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td> : ''}
                                <td align="center" className="ms_action"></td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="disabled_color na_row">
                            <td>N/A</td>
                          </tr>
                        )}
                        {GridTableValue.dynamdata ? (
                          this.stableSort(
                            GridTableValue.dynamdata,
                            this.getSorting(order, orderBy)
                          ).map((row1, index) => {
                            const labelId = index;
                            //let alterLabel = row1.alternatePrice ? '('+row1.alternatePrice+')':''
                            let prom_tions =
                              row1.promos && row1.promos !== null
                                ? row1.promos.replace(/~/g, "<hr/>")
                                : "No specials";
                            return (
                              <tr key={row1.storeID}>
                                <td
                                  className="S_captalize"
                                  align="center"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <span className="store-names">
                                    {row1.storeName}
                                  </span>
                                  <span className="stores-address current-pr-store">
                                    {row1.storeAddress}
                                  </span>
                                </td>
                                <td align="center">
                                  {row1.currentPremium !== null &&
                                    row1.currentPremium !== 0
                                    ? moneySymbol + row1.currentPremium
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.currentPremium !== 0 && row1.currentPremium !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row1.currentValue !== null &&
                                    row1.currentValue !== 0
                                    ? moneySymbol + row1.currentValue
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.currentValue !== 0 && row1.currentValue !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row1.premium !== null && row1.premium !== 0
                                    ? moneySymbol + row1.premium
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.premium !== 0 && row1.premium !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  {row1.value !== null && row1.value !== 0
                                    ? moneySymbol + row1.value
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.value !== 0 && row1.value !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td>
                                <td align="center">
                                  <ShowMoreText
                                    lines={2}
                                    more="more"
                                    less="less"
                                    expanded={false}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: prom_tions,
                                      }}
                                    />
                                  </ShowMoreText>
                                </td>
                                {activeTab !== 4 ? <td align="center">
                                  {row1.currentWalkInPremium !== null &&
                                    row1.currentWalkInPremium !== 0
                                    ? moneySymbol + row1.currentWalkInPremium
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.currentWalkInPremium !== 0 && row1.currentWalkInPremium !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td> : ''}
                                {activeTab !== 4 ? <td align="center">
                                  {row1.currentWalkInValue !== null &&
                                    row1.currentWalkInValue !== 0
                                    ? moneySymbol + row1.currentWalkInValue
                                    : "N/A"}
                                  {row1.isMonthly &&
                                    row1.isMonthly === 1 &&
                                    CONFIG.RegionId === 3 ? (
                                    <sup>*</sup>
                                  ) : (
                                    ""
                                  )}
                                  {row1.currentWalkInValue !== 0 && row1.currentWalkInValue !== null &&
                                    row1.isNotCurrentlyAdvertised === true ?
                                    <sup style={{ color: 'red' }}>*</sup> : ''}
                                </td> : ''}
                                <td align="center" className="ms_action">
                                  {row1.premium !== null &&
                                    row1.priceRange !== null &&
                                    row1.value !== null ? (
                                    row1.isSelected === true ? (
                                      <Button
                                        color="danger"
                                        padding="none"
                                        className="add-to-graph"
                                        onClick={this.barhandleChange.bind(
                                          this,
                                          row1.storeID
                                        )}
                                      >
                                        <Tooltip
                                          title="Remove from graph"
                                          placement="top"
                                        >
                                          <img
                                            src={removefrommap}
                                            alt="collapse"
                                          />
                                        </Tooltip>
                                      </Button>
                                    ) : (
                                      <Button
                                        color="info"
                                        className="remove-to-graph"
                                        onClick={this.barhandleChange.bind(
                                          this,
                                          row1.storeID
                                        )}
                                      >
                                        <Tooltip
                                          title="Show on graph"
                                          placement="top"
                                        >
                                          <img src={addtomap} alt="expand" />
                                        </Tooltip>
                                      </Button>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="na_row">
                            <td>N/A</td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="no-data" colSpan="9">
                            {isLoading === false
                              ? "There are no competitors available in this market."
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </ReactTable>
                </div>
              </div>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackOpen}
                className={
                  this.state.snackClr +
                  " snackbar bg-color-primary custom-snackbar"
                }
                maxsnack={3}
                autoHideDuration={30000}
                onClose={this.snackHandleclose.bind(this)}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{snackMSg}</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.snackHandleclose.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, activeTabs } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headRows = [
    { id: "storeName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "currentPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Premium",
    },
    {
      id: "currentValue",
      numeric: true,
      disablePadding: false,
      label: "Current Value",
    },
    {
      id: "premium",
      numeric: true,
      disablePadding: false,
      label: "Highest Premium Price Offered",
    },
    //{ id: 'priceRange', numeric: true, disablePadding: false, label: 'Standard' },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Lowest Value Price Offered",
    },
    { id: "promos", numeric: true, disablePadding: false, label: "Promotion" },
    {
      id: "currentWalkInPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Premium",
    },
    {
      id: "currentWalkInValue",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Value",
    },
    {
      id: "moreoptions",
      numeric: true,
      disablePadding: false,
      label: "More Options",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => {
          if (activeTabs === 4 ? (row.id !== 'currentWalkInValue' && row.id !== 'currentWalkInPremium') : true) {
            return (
              <TableCell
                key={row.id}
                align="center"
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                  disabled={row.id !== "moreoptions" ? false : true}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  );
}

// For Pagination
const actionsStyles = (theme) => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class EffectiveRate extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const EffectiveRateWrapped = withStyles(actionsStyles, { withTheme: true })(
  EffectiveRate
);
