import React, { Component, Fragment } from 'react';
import './MapViewPdf.css';
import ReactDOM from "react-dom";
import { Row, Col, Button } from 'reactstrap';
//import './MapViews.css';
//import { Gmaps, Marker, InfoWindow } from 'react-gmaps';
import axios from 'axios';
import { MarketProfile } from './../../MarketProfile';
import { CONFIG } from './../../../utils/config';
import orangepin from './../../../Assets/Images/orange-pin.png';
import blackpin from './../../../Assets/Images/black-pin.png';
import bluepin from './../../../Assets/Images/blue-pin.png';
import orangehome from './../../../Assets/Images/orange-home.png';
import { HelpTextPopup } from './../../HelpTextPopup/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../Assets/Images/logo.jpg';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps";
//const params = { v: '3.exp', key: 'AIzaSyDaYQZ0es5K-kLgDuZuTqRHKiTDENEMxxQ' };

export default class MapViewsGmapPdf extends React.Component {
    static defaultProps = {
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.GoogleMapsApiKey + '',
    }
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            mapData: [],
            showingInfoWindow: false,
            storeId: '',
            searchId: '',
            test: '',
            storeName: '',
            storeid: 0,
            isLoading: false,
            accessKey: '',
            locationInfoData: {},
            analysisSummary: [],
            zoomValue: 6,
            centerPoint: [],

        };
        this.getParams = this.getParams.bind(this);
        this.mapRef = React.createRef();
    }

    CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            ref={this.mapRef}
            defaultZoom={this.state.zoomValue}
            defaultCenter={{ lat: parseFloat(this.state.centerPoint && (this.state.centerPoint.length > 0 && this.state.centerPoint[0].latitude != null) ? this.state.centerPoint[0].latitude : this.props.lat), lng: parseFloat(this.state.centerPoint && (this.state.centerPoint.length > 0 && this.state.centerPoint[0].longitude != null) ? this.state.centerPoint[0].longitude : this.props.lng) }}
            //  defaultCenter = {{lat: this.state.locationInfoData ? parseFloat(this.state.locationInfoData.latitude) : 33.64566 , lng: this.state.locationInfoData ? parseFloat(this.state.locationInfoData.longitude) : -86.6836}}
            defaultOptions={{ zoomControl: true, fullscreenControl: false, streetViewControl: false, disableDefaultUI: false, mapTypeControl: true, }}
        >
            {props.children}
        </GoogleMap>
    ));

    getParams() {
        let strName = this.state.storeName;
        let strID = this.state.storeid;
        if (strName && strID) {
            this.child.current.toggleModal(strName, strID);
        }
    }

    onMarkerClick = (name, address, id, lat, lng, size) => {
        let sam = [], uniqsize = [];
        this.state.mapData.map(test => {
            if (test.storeId === id) {
                sam.push(test.size)

            }
        })
        const uniqueNames = Array.from(new Set(sam));
        console.log(uniqueNames, "uniqsize");
        var units = uniqueNames.join(", ");
        var add = address;
        var last = add.lastIndexOf(",");
        var s1 = add.substring(0, last);
        var s2 = add.substring(last + 1);
        var storedata = this.state.FData;
        var tFData = storedata.filter(a => a.StoreID == id);
        var StandardOnlinePrice = tFData[0].StandardOnlinePrice;
        var StandardRegPrice = tFData[0].StandardRegPrice;
        //setTimeout(() => {
        this.setState({
            storeName: name,
            lat: lat,
            lng: lng,
            address: address ? s1 : '',
            address1: address ? s2 : '',
            regPrice: StandardRegPrice != this.props.curr + 0 ? StandardRegPrice : 'N/A',
            onlinePrice: StandardOnlinePrice != this.props.curr + 0 ? StandardOnlinePrice : 'N/A',
            size: size ? units : 'N/A',
            storeid: id,
            showingInfoWindow: true
        });
        // },500)
    }
    async UNSAFE_componentWillMount() {
        const data = {
            "SecretKey": this.props.accessKey,
            "RegionId": CONFIG.RegionId
        }

        const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
        if (response.status === 200) {
            this.setState({ accessKey: response.data.token })
            this.state.accessKey = response.data.token;
            console.log(this.state.accessKey, 'acceskry');
            this.initialLoad()
            this.loadSummary()
        } else {
            alert(response.status.Message);
        }
    }
    loadSummary() {
        let token = this.state.accessKey;
        const data = {
            "UserStoreID": parseInt(this.props.usId),
            "SearchID": parseInt(this.props.pta),
            "RegionID": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'dashboard/currentpricinganalysissummary', data)
            .then(response => {

                // this.state.isLoading=true;
                const analysisSummary = response.data.current;
                this.setState({
                    analysisSummary: analysisSummary,
                    isLoading: false,
                })
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });


    }

    //    componentWillReceiveProps(nextProps) {
    //     if(nextProps){
    //       this.state.mapData=[];
    //         this.setState({
    //             isLoading: true,
    //             showingInfoWindow: false,
    //             bca:nextProps.bca, 
    //             abc:nextProps.abc}, 
    //             ()=> {this.componentDidMount()
    //         });
    //     }

    // }

    setMapFitBounds = (mapData) => {
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < mapData.length; i++) {
            let loc = new window.google.maps.LatLng(mapData[i].latitude, mapData[i].longitude);
            bounds.extend(loc);
        }
        let homeLoc = new window.google.maps.LatLng(this.state.locationInfoData.latitude, this.state.locationInfoData.longitude);
        bounds.extend(homeLoc);
        this.mapRef.current.fitBounds(bounds);
    }

    mapView() {
        // const data=
        // {
        //   "UserStoreID":129229,
        //   "SearchID":965256
        // }
        localStorage.setItem('StId', this.props.usId);
        const data = {
            "UserStoreID": parseInt(this.props.usId),
            "SearchID": parseInt(this.props.pta),
            "RegionID": CONFIG.RegionId,
            "IsMapBox": CONFIG.IsMapbox
        }
        // const data = {
        // UserStoreID: parseInt(this.props.usId),
        // SearchID: this.props.pta,
        // "RegionID" :CONFIG.RegionId
        // }
        let token = this.state.accessKey;
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'unit/unitlevelmapview', data)
            .then(res => {
                let mapData = res.data.data.unitLevelDatas;
                if (mapData.length) this.setMapFitBounds(mapData);
                let uniquestore = [...new Set(mapData.map(item => item.storeId))];
                var FData = [];
                for (var t = 0; t < uniquestore.length; t++) {
                    var tFData = mapData.filter(a => a.storeId == uniquestore[t]);
                    var mData = {
                        StoreID: uniquestore[t],
                        StoreAddress: tFData[0].address,
                        PremiumRegPrice: Math.max.apply(Math, tFData.map(function (o) { return o.regPrice; })),
                        // StandardRegPrice:(Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
                        StandardRegPrice: Math.max.apply(Math, tFData.map(function (o) { return o.regPrice; })) === Math.min.apply(Math, tFData.map(function (o) { return o.regPrice; })) ? this.props.curr + Math.min.apply(Math, tFData.map(function (o) { return o.regPrice; })) : this.props.curr + (Math.min.apply(Math, tFData.map(function (o) { return o.regPrice; })) + ' - ' + Math.max.apply(Math, tFData.map(function (o) { return o.regPrice; }))),
                        //old
                        // StandardRegPrice : Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) ? this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) : this.props.curr + (Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+ this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
                        ValueRegPRice: Math.min.apply(Math, tFData.map(function (o) { return o.regPrice; })),
                        PremiumOnlinePrice: Math.max.apply(Math, tFData.map(function (o) { return o.onlinePrice; })),
                        // StandardOnlinePrice:(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
                        StandardOnlinePrice: Math.max.apply(Math, tFData.map(function (o) { return o.onlinePrice; })) === Math.min.apply(Math, tFData.map(function (o) { return o.onlinePrice; })) ? this.props.curr + Math.min.apply(Math, tFData.map(function (o) { return o.onlinePrice; })) : this.props.curr + (Math.min.apply(Math, tFData.map(function (o) { return o.onlinePrice; })) + ' - ' + Math.max.apply(Math, tFData.map(function (o) { return o.onlinePrice; }))),
                        //old
                        // StandardOnlinePrice: Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) ? this.props.curr+ Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) : this.props.curr +(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
                        ValueOnlinePRice: Math.min.apply(Math, tFData.map(function (o) { return o.onlinePrice; }))
                    }
                    FData.push(mData);
                }
                //let lat= mapData != '' ? mapData[0].latitude:0;
                //let lng= mapData != '' ? mapData[0].longitude:0;
                this.setState({ mapData, FData, isLoading: false });
            })

            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    onMarkerClose = () => {
        this.setState({ showingInfoWindow: false });
    }
    loadDashBoard() {
        let token = this.state.accessKey;
        const data = {
            "UserStoreID": parseInt(this.props.usId),
            "SearchID": parseInt(this.props.pta),
            "RegionID": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'unit/dashboardSearchlocation', data)
            .then(response => {
                // this.state.isLoading=true;
                let zoomValue;
                const locationInfoData = response.data.data.unitDashboard;
                const centerPoint = response.data.data.centerPoints;
                if (locationInfoData.searchType === 3) {
                    zoomValue = 9;
                } else if (locationInfoData.searchType === 2) {
                    let zCnt = locationInfoData.storeZoneCoverage != "" && locationInfoData.storeZoneCoverage != null ? (locationInfoData.storeZoneCoverage.split(",").length - 1) : 1;
                    zoomValue = (zCnt > 1) ? 9 : (zCnt > 0) ? 10 : 11;
                } else if (locationInfoData.searchType === 1) {
                    let storeZoneCoverage = locationInfoData.storeZoneCoverage.split(' ');
                    zoomValue = (storeZoneCoverage[0] === '1' || storeZoneCoverage[0] === '2' || storeZoneCoverage[0] === '3') ? 12 : (storeZoneCoverage[0] === '4' || storeZoneCoverage[0] === '5' || storeZoneCoverage[0] === '6') ? 11 : (storeZoneCoverage[0] === '7' || storeZoneCoverage[0] === '8' || storeZoneCoverage[0] === '9' || storeZoneCoverage[0] === '10') ? 10 : 6;
                }
                this.setState({
                    locationInfoData: locationInfoData, zoomValue, centerPoint
                })
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    initialLoad() {
        this.setState({ isLoading: true });
        this.loadDashBoard();
        this.mapView();

    }

    render() {
        const { analysisSummary, mapData, locationInfoData, FData, isLoading, centerPoint, zoomValue } = this.state;
        const defaultProps = {
            helpTitle: "Map View",
            helpContent: "<p>The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store. </p><p><i>*Your store prices are included in the calculations.</i></p>"
        }
        let cutCur = locationInfoData.countryCurrency ? locationInfoData.countryCurrency : 'N/A';
        let clp = analysisSummary.lowestPrice;
        let cap = analysisSummary.averagePrice;
        let chp = analysisSummary.highestPrice;
        if (clp) {
            var splt_clp = clp.toString().split('.');
            let splt_val_clp = splt_clp[1] ? splt_clp[1].length === 1 ? '.' + splt_clp[1] + '0' : splt_clp[1].length === 2 ? '.' + splt_clp[1] : '' : '';
            clp = cutCur + splt_clp[0] + splt_val_clp;
        }
        if (cap) {
            var splt_cap = cap.toString().split('.');
            let splt_val_cap = splt_cap[1] ? splt_cap[1].length === 1 ? '.' + splt_cap[1] + "0" : splt_cap[1].length === 2 ? '.' + splt_cap[1] : '' : '';
            cap = cutCur + splt_cap[0] + splt_val_cap;
        }
        if (chp) {
            var splt_chp = chp.toString().split('.');
            let splt_val_chp = splt_chp[1] ? splt_chp[1].length === 1 ? '.' + splt_chp[1] + '0' : splt_chp[1].length === 2 ? '.' + splt_chp[1] : '' : '';
            chp = cutCur + splt_chp[0] + splt_val_chp;
        }
        const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
        return (
            <div className="mapview-pdf">
                <div className="header mapview-header"> <img src={Logo} /><div className="float-right-name">
                    {/* <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ': ''}{locationInfoData.storeState + ' ' + locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p> */}
                    <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0 address-font">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''}{locationInfoData.storeState ? locationInfoData.storeState + ' ' : ''}{locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p>
                    </div>
                </div>


                <div className="wrapper my-account-box mapview-body">
                    <h4 className="mb-3">{'(' + locationInfoData.searchName + ')'} Map View</h4>
                    <Row className="height-full">
                        <div className="sep-box">
                            <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Lowest Weekly Price" : "Current Lowest Price"}</h4>
                            <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.lowestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
                            <div className="align-stores-btm"><h5>{clp ? clp : isLoading === false ? 'N/A' : ''} </h5></div>
                        </div>
                        <div className="sep-box current-avg-height">
                            <h4 className="text-left" style={!cap && isLoading === false ? { height: 'auto' } : { position: 'relative' }}>{CONFIG.RegionId === 3 ? "Current Average Weekly Price" : "Current Average Price"}</h4>
                            {/* <div className="pd-btm-20"><p></p></div> */}
                            <div className="align-stores-btm"><h5>{cap ? cap : isLoading === false ? 'N/A' : ''} </h5></div>
                        </div>
                        <div className="sep-box right-none">
                            <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Highest Weekly Price" : "Current Highest Price"} </h4>
                            <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.highestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
                            <div className="align-stores-btm"><h5>{chp ? chp : isLoading === false ? 'N/A' : ''} </h5></div>
                        </div>
                        <div className="clear"></div>
                    </Row>
                    {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
                    <div className="mt-3 is-it-store">
                        <p>
                            The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store.
                        </p><p className="mb-3">*Your store prices are included in the calculations.
                        </p>
                        <Col md="12" className="p-v-15 border-box shadow-sm rounded">
                            <Row className="mt-2">
                                <Col md="12">
                                    <Fragment>
                                        <this.CMap
                                            googleMapURL={this.props.googleMapURL}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `600px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                        >
                                            <Marker
                                                position={{ lat: parseFloat(locationInfoData.latitude), lng: parseFloat(locationInfoData.longitude) }}
                                                icon={orangehome}
                                            />
                                            {mapData ? mapData.map((marker) => {
                                                return (
                                                    <Marker
                                                        position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                                                        icon={marker.isUserStore == 1 ? orangepin : marker.isUserStore == 0 && marker.storeStatus == 1 ? bluepin : blackpin}
                                                        onMouseOver={() => this.onMarkerClick(marker.storeName, marker.address, marker.storeId, marker.latitude, marker.longitude, marker.size)}
                                                    >
                                                        {this.state.showingInfoWindow && this.state.storeid === marker.storeId ?
                                                            <InfoWindow
                                                                onCloseClick={() => this.onMarkerClose()}
                                                                visible={this.state.showingInfoWindow}>
                                                                <div className="gmap-tooltip" >
                                                                    <table className="map-table"><tr><td className="address-map-top"><b><a onClick={this.getParams}>{this.state.storeName}</a></b><p>{this.state.address},<br />{this.state.address1}</p></td></tr></table><table><tr><td className="label-list">Walk-in Rate:</td><td>{this.state.regPrice}</td></tr><tr><td className="label-list">Online Rate:</td><td>{this.state.onlinePrice}</td></tr><tr><td className="label-list">Unit Type:</td><td className="state-size">{this.state.size}</td></tr><tr><td></td></tr></table>
                                                                </div>
                                                            </InfoWindow>
                                                            : ''}
                                                    </Marker>
                                                )

                                            }) : ''
                                            }

                                        </this.CMap>
                                    </Fragment>
                                </Col>
                            </Row>
                            <Col md="12" className="margin-2 map-btm-legend">
                                <Row>
                                    <div className="legend-block"><div className="maps-legend-orange"></div>Your Store</div>
                                    <div className="legend-block"><div className="maps-legend-blue"></div>Competitor</div>
                                    <div className="legend-block"><div className="maps-legend-black"></div>Rate Data Unavailable</div>
                                </Row>
                            </Col>
                        </Col>
                    </div>
                </div>
                <div className="footer">
                    <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
                </div>
            </div>
        );
    }

}