import React from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormLabel from '@material-ui/core/FormLabel';
import classnames from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, FormGroup, Modal, ModalHeader, ModalFooter, ModalBody, Label } from 'reactstrap';
import { Table as ReactTable } from 'reactstrap';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import { COMMON } from '../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { TableBody } from '@material-ui/core';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { HashLink as Link } from 'react-router-hash-link';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { HelpTextPopup } from './../HelpTextPopup/index';

function getSteps() {
    return ['Alert Type', 'Unit Type', 'Change Type', 'Alert Name'];
}
export default class Alert extends React.Component {
    constructor(props) {
        const steps = getSteps();
        super(props);
        this.state = {
            isLoading: false,
            activeTab: '1',
            order: 'desc',
            orderBy: 'createdDate',
            alertModal: false,
            activeStep: 0,
            dialogcnt: '',
            isTab: 0,
            alertTypeValue: '',
            alertArray: [],
            detailModal: false,
            snackOpen: false,
            snackMSg: '',
            homeData: [],
            selectedIds: [],
            lengthOfIds: [],
            alertIds: [],
            manageAlertList: [],
            userStoreId: 0,
            pageCountMDT: 0,
            pageCount: 0,
            pageCountMA: 0,
            Page: 0,
            alertPopupValues: [],
            helpModal: false,
            storeAddress: [],
            isActiveValue: false,
            userStoreId: parseInt(this.props.match.params.userstoreid),
            viewAlertsList: [],
            matchedComp: [],
            newDate: new Date(),
            alertdeleteModal: false,
            alertViewModal: false,
            alertDeleteId: 0,
            alertViewId: 0
        }
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.stableSort = this.stableSort.bind(this);
        this.getSorting = this.getSorting.bind(this);
        this.selectCheckBox = this.selectCheckBox.bind(this);
    }
    // html formatting
    alertHtmlChange(data) {
        return { __html: data };
    }
    // html formatting
    alertTextChange(data) {
        return { __html: data };
    }
    //modal for showing alert details
    openAlertDetails(values) {
        this.setState({ isLoading: true });
        let alertPopupValues = []
        this.setState({ detailModal: true, });
        let token = localStorage.getItem('accessKey');
        let alertOpenIP = {
            "AlertID": values.alertID,
            "RegionId": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/getalert', alertOpenIP)
            .then(response => {
                alertPopupValues = response.data.data.viewAlertsInformation;
                this.setState({ alertPopupValues, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    deleteModalClose(){
        this.setState({ alertdeleteModal: false });
    }
     //popup for delete alert  
     deleteModal(id){
        this.setState({ alertdeleteModal: true, alertDeleteId: id });
    }
    //delete alert 
    deleteAlert() {
        this.setState({ isLoading: true });
        let token = localStorage.getItem('accessKey');
        let deleteIP = {
            "AlertID": this.state.alertDeleteId,
            "AlertIDList": 0,
            "RegionId": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/deletealerts', deleteIP)
            .then(response => {
                if (response.status === 200)
                {
                    let pageCountMA = this.state.pageCountMA;
                    this.setState({ pageCountMA, alertdeleteModal: false, isLoading: false, snackOpen: true, snackMSg: "Alert deleted successfully" }, () => {
                        this.manageAlertsLoad();
                    });
                    }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
 
    deleteViewClose(){
        this.setState({ alertViewModal: false});
    }
    //popup for deleteView alert  
    deleteViewModal(id){
        this.setState({ alertViewModal: true, alertViewId: id });
    }
    //delete view alert
    deleteViewAlert() {
        this.setState({ isLoading: true });
        let token = localStorage.getItem('accessKey');
        let deleteIP = {
            "Id": this.state.alertViewId,
            "RegionId": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/deleteviewalerts', deleteIP)
            .then(response => {
                if (response.status === 200){
                    let pageCountMDT = this.state.pageCountMDT;
                    this.setState({ pageCountMDT, alertViewModal: false, isLoading: false, snackOpen: true, snackMSg: "Alert deleted successfully" }, () => {
                        this.viewAlertsLoad();
                    });}
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    //modal close
    alertUpdateClose() {
        this.setState({ alertUpdateModal: false });
    }
    //alert modal popup open
    alertUpdateModal(event, id, Value) {
        this.setState({ alertUpdateModal: true, alertIds: id, isActiveValue: Value });
    }
    //alert update
    alertUpdate() {
        this.setState({ isLoading: true });
        let token = localStorage.getItem('accessKey');
        let alertUpdateIp = {
            "AlertID": this.state.alertIds,
            "IsActive": this.state.isActiveValue ? 0 : 1,
            "RegionId": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/updatealert', alertUpdateIp) //this.state.userStoreId/CONFIG.RegionId)
            .then(response => {
                let pageCountMA = this.state.pageCountMA;
                this.setState({ alertUpdateModal: false, isLoading: false }, () => {
                    this.manageAlertsLoad();
                })
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    //select all checkbox
    selectCheckBox(event, id) {
        let checkBoxLength  = this.state.homeData.userStoreAlert;
        let selectedIds = this.state.selectedIds;
        let lengthOfIds = this.state.lengthOfIds;
        let index;
        let valueChange;
        if (event.target.checked) {
            index = selectedIds.indexOf(+id + '|0');
            if (index >= 0) {
                selectedIds.splice(index, 1)
            }
            selectedIds.push(+id + '|1');
            lengthOfIds.push(+id)
        }
        else if (!event.target.checked) {
            index = selectedIds.indexOf(+id + '|1');
            if (index >= 0) {
                selectedIds.splice(index, 1)
            }
            selectedIds.push(+id + '|0');
            var matchedId = lengthOfIds.indexOf(+id);
            if (matchedId > -1) {
                lengthOfIds.splice(index, 1)
            }
        }
        valueChange = this.state.lengthOfIds.length === checkBoxLength.length ? "1" : "0"
        this.state.alertArray.applyRule = valueChange;
        this.setState({
            selectedIds, lengthOfIds
        });
    }
    //snackbar close
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };
    //modal for alert details open/close 
    detailModalClose() {
        this.setState(prevState => ({
            detailModal: !prevState.detailModal,
        }));
    }
    //Tabs 
    tabtoggle(tab) {
        if (this.state.activeTab !== tab) {

            this.setState({ activeTab: tab });
        }
    }
    handleChangePage = (event, pageCount) => {
        this.setState({ pageCount });
    };
    handleChangePageMTD = (event, pageCountMDT) => {
        this.setState({ pageCountMDT });
    };
    handleChangePageMA = (event, pageCountMA) => {
        this.setState({ pageCountMA });
    };
    handleChangeRowsPerPageMA = event => {
        this.setState({ pageCountMA: 0, 10: event.target.value });
    };
    handleChangeRowsPerPageMTD = event => {
        this.setState({ pageCountMDT: 0, 10: event.target.value });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };
    //save alert details
    alertSubmit(event, errors, values) {
        var datainput = this.state.alertArray;
        if (errors.length === 0) {
            if (this.state.activeStep === 0) {
                if (this.state.alertArray.alertType != undefined) {
                    this.setState({ activeStep: this.state.activeStep + 1 });
                } else {
                    this.setState({ snackOpen: true, snackMSg: 'Please select either one option ' });
                }
            } else if (this.state.activeStep === 1) {
                this.setState({ activeStep: this.state.activeStep + 1 });
            } 
            else if (this.state.activeStep === 2) {
                if (this.state.selectedIds.length > 0 && this.state.alertArray.applyRule != undefined && this.state.lengthOfIds.length > 0 && (this.state.alertArray.dollarValue != "0" && this.state.alertArray.percentageValue != "0")) {
                    let matchedComp = [];
                    let alertArray = this.state.alertArray;
                    let unitType = this.state.alertArray.unitTypeName;
                    let alertType = this.state.alertArray.alertType === '1' ? 'Pricing' : 'Inventory';
                    let date = moment(this.state.newDate).format('DD-MMM-YYYY');
                    alertArray['alertName'] = unitType + ', ' + alertType+' Change, ' + date;
                    for (var i = 0; i < this.state.homeData.userStoreAlert.length; i++) {
                        for (var j = 0; j < this.state.lengthOfIds.length; j++) {
                            if (this.state.lengthOfIds[j] === this.state.homeData.userStoreAlert[i].storeID) {
                                matchedComp.push(this.state.homeData.userStoreAlert[i])
                            }
                        }
                    }
                    this.setState({ activeStep: this.state.activeStep + 1, matchedComp });
                }
                else if (this.state.alertArray.applyRule === undefined) {

                    this.setState({ snackMSg: "Please select either one rule", snackOpen: true });
                } else if (this.state.lengthOfIds.length === 0) {

                    this.setState({ snackMSg: "Please select atleast one competitor", snackOpen: true });
                } else if (this.state.alertArray.dollarValue === '0') {
                    this.setState({ snackMSg: "Please enter valid price", snackOpen: true });
                } else if (this.state.alertArray.percentageValue === '0') {
                    this.setState({ snackMSg: "Please enter valid price", snackOpen: true });
                }
                let goTo = document.getElementById("myDiv");
                if(goTo != null)
                {
                goTo.scrollIntoView();
                }
            }
            else if (this.state.activeStep === 3) {
                this.setState({ isLoading: true });
                let token = localStorage.getItem('accessKey');
                let alertIp = {
                    "RegionId": CONFIG.RegionId,
                    "AlertName": this.state.alertArray.alertName,//"Nameo f Alerts",
                    "AlertType": parseInt(this.state.alertArray.alertType),//Price or Inventory
                    "AlertFor": this.state.alertArray.alertType === "1" ? parseInt(this.state.alertArray.priceOption) : 4,//Average, Premium or Value
                    "AlertRange": this.state.alertArray.alertType === "2" ? parseInt(this.state.alertArray.inventoryChange) : this.state.alertArray.priceOptionV1 ? parseInt(this.state.alertArray.priceOptionV1) : parseInt(this.state.alertArray.priceOptionV2),//Greter, Less or Equal to | InCre. Decr By | Appears or Dis.Appears
                    "UserSearchID": parseInt(this.state.alertArray.unitTypeSel),// "Unit type Value i.e. value of 5X5"
                    "AllComps": parseInt(this.state.alertArray.applyRule),//1 - All or 0-Specific Comps
                    "AlertValue": this.state.alertArray.dollarValue && this.state.alertArray.alertType === "1" ? parseFloat(this.state.alertArray.dollarValue) : this.state.alertArray.percentageValue && this.state.alertArray.alertType === "1" ? parseFloat(this.state.alertArray.percentageValue) : 0,//Value ranges
                    "CompsList": this.state.alertArray.applyRule === '1' ? "" : this.state.selectedIds.toString()//List Comp List or 1234|0,1235|1
                }
                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }
                axios.post(CONFIG.API_URL + 'alert/addnewalert', alertIp)
                    .then(res => {
                        this.setState({ pageCountMA: 0, pageCountMDT: 0, isLoading: false, alertModal: false, snackMSg: "Alert created successfully", snackOpen: true, activeStep: 0, alertArray: [] }, () => {
                            this.manageAlertsLoad();
                        });
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        if (err.response.status === 401) {
                            localStorage.removeItem('accessKey');
                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                            this.props.history.push(from);
                        }
                        else if (err.response.status === 400) {
                            this.setState({ snackOpen: true, snackMSg: err.response.data.Message });
                        }
                        else {
                            alert(err.response.data);
                        }
                    });
            }
        } else {
            let goTop = document.getElementById("myDiv");
            if(goTop != null)
            {
            goTop.scrollIntoView();
            }
        }
    }
    //modal back
    handleBack() {
        this.setState({ activeStep: this.state.activeStep - 1 });
    }
    //open alert modal
    alertOpen() {
        this.setState({ isLoading: true });
        this.setState(prevState => ({
            alertModal: !prevState.alertModal
        }));
        let userStoreId = parseInt(this.props.match.params.userstoreid);
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'Alert/competitors/' + userStoreId + '/' + CONFIG.RegionId) //this.state.userStoreId/CONFIG.RegionId)
            .then(response => {
                var homeData = response.data.competitorslist;
                let alertArray = this.state.alertArray;
                let selectedIds = [];
                let lengthOfIds = [];
                alertArray['applyRule'] = "1";
                alertArray['alertType'] = "1";
                let data = homeData.fetchAlertPerc.filter(it => it.rangeFor === 'Price');
                let dropDownValues = homeData.userStoreAlert.map(storeAlert => {
                    selectedIds.push(storeAlert.storeID + '|1');
                    lengthOfIds.push(storeAlert.storeID);
                })
                this.setState({ homeData, isLoading: false, alertArray: alertArray, selectedIds, lengthOfIds, isTab: 1 }, () => {
                    this.manageAlertsLoad();
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    //closing the alert modal
    alertClose() {
        this.setState({ alertModal: false, activeStep: 0, alertArray: [], selectedIds: [] });
    }
    //Table Sorting 
    handleRequestSort(event, property) {
        const { order, orderBy } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        let sort = isDesc ? 'asc' : 'desc';
        this.setState({ order: sort, orderBy: property });
    }
    //Table Sorting order
    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    //Table Sorting the selected Column
    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }
    //Table Sorting Order Request
    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }
    //all on change events handled here
    onChangeValues(e) {
        let alertArray = this.state.alertArray;
        if (e.target.name != '' && e.target.name != undefined) {
            if (e.target.name == 'alertType') {
                alertArray['alertType'] = e.target.value;
                this.setState({ isTab: parseInt(e.target.value) });
            } else if (e.target.name === 'unitTypeSel') {
                alertArray['unitTypeSel'] = e.target.value;
                alertArray['unitTypeName'] = this.state.homeData.fetchUnits.filter(it => it.searchID === parseInt(e.target.value)).map((x) => x.searchName)
            } else if (e.target.name === 'inventoryChange') {
                alertArray['inventoryChange'] = e.target.value;
                alertArray['inventoryChangeName'] = this.state.homeData.fetchAlertPerc.filter(it => it.id === parseInt(e.target.value)).map((x) => x.alertRange)
            }
            else if (e.target.name === 'priceOption') {
                alertArray['priceOption'] = e.target.value;
                alertArray['priceOptionName'] = this.state.homeData.fetchAlertFor.filter(it => it.id === parseInt(e.target.value)).map((x) => x.alertFor)
            }
            else if (e.target.name === 'priceOptionV1') {
                alertArray['priceOptionV1'] = e.target.value;
                alertArray['priceOptionV1Name'] = this.state.homeData.fetchAlertPerc.filter(it => it.id === parseInt(e.target.value)).map((x) => x.alertRange)
                alertArray.priceOptionV2 = '';
                alertArray.percentageValue = '';
                alertArray.priceOptionV2Name = ''

            }
            else if (e.target.name === 'dollarValue') {

                alertArray['dollarValue'] = e.target.value;
                alertArray.priceOptionV2 = '';
                alertArray.percentageValue = '';
                alertArray.priceOptionV2Name = ''

            }
            else if (e.target.name === 'priceOptionV2') {
                alertArray['priceOptionV2'] = e.target.value;
                alertArray['priceOptionV2Name'] = this.state.homeData.fetchAlertPerc.filter(it => it.id === parseInt(e.target.value)).map((x) => x.alertRange)
                alertArray.priceOptionV1 = '';
                alertArray.dollarValue = '';
                alertArray.priceOptionV1Name = ''
            }
            else if (e.target.name === 'percentageValue') {
                alertArray['percentageValue'] = e.target.value;
                alertArray.priceOptionV1 = '';
                alertArray.dollarValue = '';
                alertArray.priceOptionV1Name = ''
            }
            else if (e.target.name === 'inventoryChange') {
                alertArray['inventoryChange'] = e.target.value;
            }
            else if (e.target.name === 'alertName') {
                alertArray['alertName'] = e.target.value;
            }
            else if (e.target.name === 'applyRule') {
                alertArray['applyRule'] = e.target.value;
                if (e.target.value === '1') {
                    let selectedIds = [];
                    let lengthOfIds = [];
                    if (e.target.checked) {
                        this.state.homeData.userStoreAlert.map(storeAlert => {
                            selectedIds.push(storeAlert.storeID + '|1');
                            lengthOfIds.push(storeAlert.storeID);
                        })
                    }
                    this.setState({ selectedIds, lengthOfIds });
                } else {
                    this.setState({ selectedIds: [], lengthOfIds: [] });
                }
            }
            this.setState({ alertTypeValue: e.target.value, alertArray });
        }
    }
    //load manage alert grid list
    manageAlertsLoad() {
        this.setState({ isLoading: true });
        let alertIds = [];
        let token = localStorage.getItem('accessKey');
        let userStoreId = parseInt(this.props.match.params.userstoreid);
        let manageAlertIp = {
            "userStoreID": userStoreId,
            "RegionId": CONFIG.RegionId,
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/managealerts', manageAlertIp)
            .then(response => {
                let pageNav = this.state.pageCountMA;
                let manageAlertList = response.data.data.manageAlertsInfo;
                manageAlertList.map(data => {
                    if (data.isActive === true) {
                        alertIds.push(data.alertID + '|1')
                    } else {
                        alertIds.push(data.alertID + '|0')
                    }
                })
                if(pageNav === 1){
                    let checkMaPg = manageAlertList.length < 21 ? 0 : manageAlertList.length < 31 ? 1 : 2 ;
                    this.setState({pageCountMA : checkMaPg});
                }
                this.setState({ manageAlertList, userStoreId, alertIds, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    //load view alert grid list
    viewAlertsLoad() {
        this.setState({ isLoading: true });
        let alertIds = [];
        let token = localStorage.getItem('accessKey');
        let userStoreId = parseInt(this.props.match.params.userstoreid);
        let manageAlertIp = {
            "userStoreID": userStoreId,
            "RegionId": CONFIG.RegionId,
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Alert/viewalerts', manageAlertIp)
            .then(response => {
                let pageNav = this.state.pageCountMDT;
                let viewAlertsList = response.data.data.viewAlertsInformation
                if(pageNav === 1){
                    let checkPg = viewAlertsList.length < 21 ? 0 : viewAlertsList.length < 31 ? 1 : 2 ;
                    this.setState({pageCountMDT : checkPg});
                }
                this.setState({
                    viewAlertsList, isLoading: false, 
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    //load home data 
    dashboardLocation() {
        this.setState({ isLoading: true });
        let alertIds = this.state.alertIds;
        let token = localStorage.getItem('accessKey');
        let userStoreId = parseInt(this.props.match.params.userstoreid);
        let dashboardIp = {
            "userStoreID": userStoreId,
            "RegionId": CONFIG.RegionId,
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', dashboardIp)
            .then(response => {
                let storeAddress = response.data.data.storeDashboard;
                this.setState({ storeAddress, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    componentDidMount() {
        this.viewAlertsLoad();
        this.manageAlertsLoad();
        this.dashboardLocation();
    }
    render() {
        document.title = 'StorTrack: Self-storage market pricing data';
        const defaultProps = {
            helpTitle: '',
            helpContent: "<p style='padding-top: 15px;'><b style='font-weight: 500;color: #000;font-size: 14px;'>Create an alert for price or inventory changes in your market.</b></p><p>View alerts on this page each time you log in – they are also emailed to you if you have opted to receive alerts from Stortrack.</p><p>* Alerts are set up by unit type (the units saved to your dashboard).</p><p><b style='font-weight: 500;font-size: 14px;color: #000;'>Price Changes</b></p><p>Select Price Change and select from the alert criteria.</p><p>You can choose to get alerts each time a price is higher, lower or equal to a certain amount.</p><p>You can also get alerts every time the price decreases or increases by a specified percentage.</p><p><b style='font-weight: 500;font-size: 14px;color: #000;'>Inventory Changes</b></p><p>Select Inventory Change to create an alert for when availability of a unit type increases or decreases.</p><p>Stortrack alerts you when prices for a unit type have been removed from or added to a location’s website.</p><p>All of these rules can be applied to only specified competitors or across your market.</p>"
        }
        const { isLoading, order, orderBy, dialogcnt, snackOpen, snackMSg, tableGrid, homeData, pageCountMDT, pageCount,pageCountMA, selectedIds, manageAlertList, Page, alertPopupValues, storeAddress, viewAlertsList } = this.state;
        const steps = getSteps();
        return (
            <div className="market-alerts">
                {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
                <Breadcrumbs separator="›" aria-label="Breadcrumb" className="breadcrumb-float-left breadcrumb-top">
                   
                    
                   {localStorage.getItem('isMVP_User') === "false"  ? <Link to="/my-market/" color="inherit">My Markets</Link> : ""}
                    
                   <Link to={{ pathname: `/market-dashboard/${this.props.match.params.userstoreid}` }} color="inherit" >Go to Market Dashboard</Link>
                    
                    <Typography component="h6" variant="headline" className="link-tag">Market Alerts</Typography>
                </Breadcrumbs>

                <div className="clearfix"> </div>
                <div className="align-container-center" style={{ marginBottom: '15px', marginTop: '5px' }}>
                    <Paper>
                        <Row>
                            <Col md='2'>
                                <h3>Market Alerts <HelpTextPopup parm={defaultProps} /></h3>

                            </Col>
                            <Col md='8' className="market-address-tp">
                                <h5>{storeAddress.storeName && storeAddress.storeName !== null && storeAddress.storeName !== '' ? storeAddress.storeName : isLoading === false ? 'Your Store' : ''}</h5>
                                <p>{storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ',' : isLoading === false ? '' : ''} {storeAddress.storeAddress2 ? storeAddress.storeAddress2 + ',' : ''} {storeAddress.storeCity ? storeAddress.storeCity+', '  : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ':  isLoading===false ? '':''}{storeAddress.storeState ? storeAddress.storeState + '' : isLoading === false ? '' : ''} {storeAddress.storeZipCode ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''}</p>
                                <p className="mb-0"><label className="mr-2">Coverage: </label><span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading === false ? 'N/A' : ''}</span></p>
                            </Col>
                            <Col md='2' >
                                <div className="step_btn">
                                    <Button className="next-btn" onClick={() => this.alertOpen()}>Create Alert</Button>
                                </div>
                            </Col>
                        </Row>
                    </Paper>
                </div>
                <div className="align-container-center">
                    <Paper>
                        <Nav tabs className="d-inline-block">
                            <NavItem className="grouped-btn d-inline-block">
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.tabtoggle('1'); }}>
                                    Current Alerts
                            </NavLink>
                            </NavItem>
                            <NavItem className="grouped-btn d-inline-block">
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.tabtoggle('2'); }}>
                                    Manage Alerts
                            </NavLink>
                            </NavItem>
                        </Nav>
                        <div>
                            <Dialog className={dialogcnt === '0' ? 'FreeTrial landing-stepper landing-popup' : dialogcnt === '4' ? ' SingleMarket landing-stepper landing-popup' : 'MultiMarket landing-stepper landing-popup alert-landing-popup'} open={this.state.alertModal} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle align="center">
                                    <div>
                                        <b className="h-pop-head">Set up an Alert</b>
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            className="closeModal"
                                            onClick={() => this.alertClose()}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Stepper className="stepper-align" activeStep={this.state.activeStep} alternativeLabel >
                                            {steps.map((label, index) => (
                                                <Step key={label}>
                                                    <StepLabel
                                                        className={this.state.activeStep > index ? 'MuiStepIcon-completed-cx' : ''}
                                                        completed={false}
                                                    >{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                </DialogTitle>
                                <DialogContent >
                                    {this.state.activeStep === 0 ?
                                        <div className='user-details alert-selection'>
                                            <Container>
                                                <AvForm onSubmit={this.alertSubmit.bind(this)} >
                                                    <Row>
                                                        <Col>

                                                            <FormControl component="fieldset" >
                                                                <p style={{ fontSize: '14px' }}>React faster – be alerted of changes occurring in your market. Know when there’s a change in inventory or pricing, by competitor or market.</p>
                                                                <h5>Set up an Alert to track changes in</h5>
                                                                <RadioGroup
                                                                    aria-label="alertType"
                                                                    name="alertType"
                                                                    value={this.state.alertArray.alertType ? this.state.alertArray.alertType : "1"}
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                >
                                                                    {homeData && homeData.fetchAlertType ? homeData.fetchAlertType.map(course =>

                                                                        <FormControlLabel value={course.id.toString()} control={<Radio />} label={course.alertType}
                                                                        />

                                                                    ) : ''}
                                                                </RadioGroup>
                                                            </FormControl>
                                                            <Row>
                                                                <div className="step_btn">
                                                                    <Button className="next-btn" >
                                                                        Save and Go to Next
                                                                    </Button>
                                                                    <Button className="go-back-btn" onClick={() => this.alertClose()}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Container>
                                        </div>
                                        :
                                        this.state.activeStep === 1 ?
                                            <div className='billing-content cmn-cnt'>
                                                <Container>
                                                    <AvForm onSubmit={this.alertSubmit.bind(this)}
                                                    >
                                                        <Row>
                                                            <Col >
                                                                <div>
                                                                    <p style={{ fontSize: '14px' }}>React faster – be alerted of changes occurring in your market. Know when there’s a change in inventory or pricing, by competitor or market.</p>
                                                                    <AvField type="select" name="unitTypeSel"
                                                                        label={this.state.alertArray.alertType === '1' ? "Track Pricing changes for the following unit type:" : "Track Inventory changes for the following unit type:"}
                                                                        validate={{
                                                                            required: { value: true, errorMessage: 'Unit type is required' },
                                                                        }}
                                                                        onChange={(e) => this.onChangeValues(e)}
                                                                        value={this.state.alertArray.unitTypeSel}
                                                                    >
                                                                        <option value=''>--Select--</option>
                                                                        {homeData && homeData.fetchUnits ? homeData.fetchUnits.map(units =>

                                                                            <option value={units.searchID}>{units.searchName}</option>
                                                                        ) : ''}
                                                                    </AvField>
                                                                    <Row>
                                                                        <div className="step_btn">
                                                                            <Button className="next-btn"
                                                                                disabled={this.state.dblclkrest2 || this.state.dblclkrest2 ? true : false}
                                                                            > Save and Go to Next</Button>
                                                                            <Button
                                                                                onClick={this.handleBack.bind(this)}
                                                                                color="secondary"
                                                                                variant="contained"
                                                                                className="go-back-btn">
                                                                                Go Back
                                                                </Button>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                </Container>
                                            </div> :
                                            this.state.activeStep === 2 ? this.state.isTab === 1 ?
                                                <Container>
                                                    <AvForm onSubmit={this.alertSubmit.bind(this)}
                                                    >
                                                        <div id="myDiv">
                                                            <Row className="alert-me-txt">
                                                                <Label >Alert me when the </Label>
                                                                <AvField type="select" name="priceOption"
                                                                    value={this.state.alertArray.priceOption}
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    validate={{
                                                                        required: { value: true, errorMessage: 'Choose a price option' },
                                                                    }}>
                                                                    <option value=''>--Select--</option>
                                                                    {homeData && homeData.fetchAlertFor ? homeData.fetchAlertFor.map(alert =>

                                                                        <option value={alert.id}>{alert.alertFor}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                                <Label>rate for this unit type is</Label>
                                                            </Row>
                                                            <Row className="alert-me-txt">
                                                                <AvField type="select" name="priceOptionV1"
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    value={this.state.alertArray.priceOptionV1}

                                                                    validate={{
                                                                        required: { value: this.state.alertArray.priceOptionV2 || this.state.alertArray.percentageValue ? false : true, errorMessage: 'Choose an option here' },
                                                                    }}
                                                                    className="ml-0">
                                                                    <option value=''>--Select--</option>
                                                                    {homeData && homeData.fetchAlertPerc ? homeData.fetchAlertPerc.filter(it => it.rangeFor === 'Price').map(alert =>

                                                                        <option value={alert.id}>{alert.alertRange}</option>
                                                                    ) : ''}
                                                                </AvField><Label>{storeAddress.countryCurrency}</Label>
                                                                <AvField name="dollarValue" type="text"
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    pattern="^\d+(\.\d{1,2})?$"
                                                                    errorMessage='Please enter a positive number'
                                                                    value={this.state.alertArray.dollarValue != undefined && this.state.alertArray.dollarValue != '' ? this.state.alertArray.dollarValue : ''}
                                                                    //disabled={this.state.alertArray.priceOptionV1 === undefined ? true : false}
                                                                    validate={{
                                                                        required: { value: this.state.alertArray.priceOptionV2 || this.state.alertArray.percentageValue ? false : true, errorMessage: 'Price value is required' },
                                                                    }} /><Label> OR</Label>
                                                                <AvField type="select" name="priceOptionV2"
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    value={this.state.alertArray.priceOptionV2}
                                                                    validate={{
                                                                        required: { value: this.state.alertArray.priceOptionV1 || this.state.alertArray.dollarValue ? false : true, errorMessage: 'Choose an option here' },
                                                                    }}>
                                                                    <option value=''>--Select--</option>
                                                                    {homeData && homeData.fetchAlertPerc ? homeData.fetchAlertPerc.filter(it => it.rangeFor === "Percent").map(alert =>

                                                                        <option value={alert.id}>{alert.alertRange}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                                <AvField name="percentageValue" type="text"
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    value={this.state.alertArray.percentageValue !== undefined ? this.state.alertArray.percentageValue : ''}
                                                                    pattern="^\d+(\.\d{1,2})?$"
                                                                    errorMessage='Please enter a positive number'
                                                                    validate={{
                                                                        required: { value: this.state.alertArray.priceOptionV1 || this.state.alertArray.dollarValue ? false : true, errorMessage: 'Please enter a value here' },

                                                                    }} />
                                                                <Label>%</Label>
                                                            </Row>
                                                            <Row className="apply-this-rule">
                                                                <FormControl component="fieldset" >
                                                                    <FormLabel component="legend">Apply this rule for:</FormLabel>
                                                                    <RadioGroup
                                                                        aria-label="applyRule"
                                                                        name="applyRule"
                                                                        onChange={(e) => this.onChangeValues(e)}
                                                                        value={this.state.alertArray.applyRule}
                                                                    >
                                                                        <FormControlLabel value="1" control={<Radio />} label="All stores in this market" />
                                                                        <FormControlLabel value="0" control={<Radio />} label="Some stores in this market" />
                                                                    </RadioGroup>
                                                                </FormControl>

                                                            </Row>
                                                            <Row>
                                                                <Table className="mst all-market-table activity-table table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                Include
                                                                    </TableCell>
                                                                            <TableCell>
                                                                                Store Name
                                                                    </TableCell>
                                                                            <TableCell>
                                                                                Address
                                                                    </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {homeData && homeData.userStoreAlert ? homeData.userStoreAlert.slice(pageCount * 10, pageCount * 10 + 10).map(storeAlert => {
                                                                            return (
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    //disabled={this.state.alertArray.applyRule === '1'}
                                                                                                    checked={(storeAlert.storeID + '|1') === selectedIds.find((e) => e === (storeAlert.storeID + '|1'))}
                                                                                                    onChange={event => this.selectCheckBox(event, storeAlert.storeID)}
                                                                                                    value={storeAlert.storeID}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {storeAlert.compName}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {storeAlert.address}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }) : ''}
                                                                    </TableBody>
                                                                    <TableFooter>
                                                                        <TableRow>
                                                                            <TablePagination
                                                                                rowsPerPageOptions={[1]}
                                                                                colSpan={3}
                                                                                count={homeData ? homeData.userStoreAlert.length : 0}
                                                                                rowsPerPage={10}
                                                                                // page={pageCountMDT}
                                                                                // onChangePage={this.handleChangePageMTD}
                                                                                // onChangeRowsPerPage={this.handleChangeRowsPerPageMTD}
                                                                                page={pageCount}
                                                                                onChangePage={this.handleChangePage}
                                                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                                ActionsComponent={MyMarketWrapped}
                                                                            />
                                                                        </TableRow>
                                                                    </TableFooter>
                                                                </Table>

                                                            </Row>
                                                            <Row>
                                                                <div className="step_btn" style={{ marginLeft: '0px' }}>
                                                                    <Button className="next-btn">Save and Go to Next</Button>
                                                                    <Button
                                                                        onClick={this.handleBack.bind(this)}
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        className="go-back-btn">
                                                                        Go Back
                                                            </Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </AvForm>
                                                </Container>
                                                :
                                                <Container>
                                                    <AvForm onSubmit={this.alertSubmit.bind(this)}
                                                    >
                                                         <div id="myDiv">
                                                            <Row className="alert-me-txt"><Label>Alert me when stores in this market  </Label>
                                                                <AvField type="select" name="inventoryChange"
                                                                    onChange={(e) => this.onChangeValues(e)}
                                                                    value={this.state.alertArray.inventoryChange}
                                                                    validate={{
                                                                        required: { value: true, errorMessage: 'Inventory type is required' },
                                                                    }}
                                                                >
                                                                    <option value=''>--Select--</option>
                                                                    {homeData && homeData.fetchAlertPerc ? homeData.fetchAlertPerc.filter(it => it.rangeFor === "Inventory").map(alert =>

                                                                        <option value={alert.id}>{alert.alertRange}</option>
                                                                    ) : ''}
                                                                </AvField><Label>this unit type from/on their website</Label>
                                                            </Row>
                                                            <Row className="apply-this-rule">

                                                                <FormControl component="fieldset" >
                                                                    <FormLabel component="legend">Apply this rule for:</FormLabel>
                                                                    <RadioGroup
                                                                        aria-label="applyRule"
                                                                        name="applyRule"
                                                                        onChange={(e) => this.onChangeValues(e)}
                                                                        value={this.state.alertArray.applyRule}
                                                                    >
                                                                        <FormControlLabel value="1" control={<Radio />} label="All stores in this market" />
                                                                        <FormControlLabel value="0" control={<Radio />} label="Some stores in this market" />
                                                                    </RadioGroup>
                                                                </FormControl>

                                                            </Row>
                                                            <Row>
                                                                <Table className="mst all-market-table activity-table table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                Include
                                                                    </TableCell>
                                                                            <TableCell>
                                                                                Store Name
                                                                    </TableCell>
                                                                            <TableCell>
                                                                                Address
                                                                    </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {homeData && homeData.userStoreAlert ? homeData.userStoreAlert.slice(pageCount * 10, pageCount * 10 + 10).map(storeAlert => {
                                                                            return (
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    //disabled={this.state.alertArray.applyRule === '1'}
                                                                                                    checked={(storeAlert.storeID + '|1') === selectedIds.find((e) => e === (storeAlert.storeID + '|1'))}
                                                                                                    onChange={event => this.selectCheckBox(event, storeAlert.storeID)}
                                                                                                    value={storeAlert.storeID}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {storeAlert.compName}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {storeAlert.address}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }) : ''}
                                                                    </TableBody>
                                                                    <TableFooter>
                                                                        <TableRow>
                                                                            <TablePagination
                                                                                rowsPerPageOptions={[1]}
                                                                                colSpan={3}
                                                                                count={homeData ? homeData.userStoreAlert.length : 0}
                                                                                rowsPerPage={10}
                                                                                // page={pageCountMDT}
                                                                                // onChangePage={this.handleChangePageMTD}
                                                                                // onChangeRowsPerPage={this.handleChangeRowsPerPageMTD}
                                                                                page={pageCount}
                                                                                onChangePage={this.handleChangePage}
                                                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                                ActionsComponent={MyMarketWrapped}
                                                                            />
                                                                        </TableRow>
                                                                    </TableFooter>
                                                                </Table>
                                                            </Row>
                                                            <Row>
                                                                <div className="step_btn" style={{ marginLeft: '0px' }}>
                                                                    <Button className="next-btn">Save and Go to Next</Button>
                                                                    <Button
                                                                        onClick={this.handleBack.bind(this)}
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        className="go-back-btn">
                                                                        Go Back
                                                            </Button>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </AvForm>
                                                </Container>
                                                :
                                                this.state.activeStep === 3 ?
                                                    <div className="last-step alert-last-step">
                                                        <Container>
                                                            <AvForm onSubmit={this.alertSubmit.bind(this)}
                                                            >
                                                                <Row>
                                                                    <Col md="12">
                                                                        <div className="sec-msg">
                                                                            <AvField name="alertName" type="text"
                                                                                onChange={(e) => this.onChangeValues(e)}
                                                                                value={this.state.alertArray.alertName}
                                                                                label="Alert Name"
                                                                               
                                                                                validate={{
                                                                                    maxLength:{value:250, errorMessage:'Alert Name should be below 250 words' },
                                                                                    required: { value: true, errorMessage: 'Alert Name is required' },

                                                                                }} />
                                                                            <h5>Alert Summary:</h5>
                                                                            <p><label>Track changes in:</label>{this.state.alertArray.alertType === '1' ? 'Pricing' : 'Inventory'}</p>
                                                                            <p><label>Unit Type:</label>{this.state.alertArray.unitTypeName}</p>
                                                                            <p><label>Alert Details:</label>{this.state.alertArray.alertType === "1" ? this.state.alertArray.priceOptionName : 'Stores ' + this.state.alertArray.inventoryChangeName + ' unit type'} {this.state.alertArray.alertType === "1" && this.state.alertArray.dollarValue ? 'rate is ' + this.state.alertArray.priceOptionV1Name + ' ' + storeAddress.countryCurrency + parseFloat(this.state.alertArray.dollarValue).toFixed(2) : ''} {this.state.alertArray.alertType === "1" && this.state.alertArray.percentageValue ? 'rate ' + this.state.alertArray.priceOptionV2Name + ' ' + parseFloat(this.state.alertArray.percentageValue).toFixed(2) + '%' : ''}
                                                                            </p>
                                                                            <p><label>Stores included:</label>{this.state.alertArray.applyRule === '1' ? 'All' :
                                                                                this.state.matchedComp.map(data => {
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            <div className="data-list">
                                                                                                <p><b> {data.compName}</b></p>
                                                                                                <p> {data.address}</p>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}</p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <div className="step_btn">
                                                                        <Button className="next-btn">Save Alert</Button>
                                                                        <Button
                                                                            onClick={this.handleBack.bind(this)}
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            className="go-back-btn">
                                                                            Go Back
                                                            </Button>
                                                                    </div>
                                                                </Row>
                                                            </AvForm>
                                                        </Container>
                                                    </div>
                                                    :
                                                    ''}
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div className="alert-tab-contents">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="">
                                        <div className="ms_table pd-3 display-grid" >
                                            <ReactTable className="mst all-market-table activity-table" responsive="true">
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                    tabValue={this.state.activeTab}
                                                    rowCount={viewAlertsList ? viewAlertsList.length : 0}
                                                />
                                                <TableBody>
                                                    {viewAlertsList && viewAlertsList.length > 0 ? this.stableSort(viewAlertsList, this.getSorting(order, orderBy)).slice(pageCountMDT * 20, pageCountMDT * 20 + 20).map((data, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {moment(data.createdDate).format('DD-MMM-YYYY')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Link to="#" onClick={() => this.openAlertDetails(data)} > {data.alertName} </Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.alertType}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.unitType}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.alertOverview}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.alertType === "Inventory Availability" ?
                                                                        <Link to={"/market-dashboard/" + this.state.userStoreId + "/view-details/" + data.userSearchId + "/2?"} > Unit Details</Link> :
                                                                        <Link to={"/market-dashboard/" + this.state.userStoreId + "/view-details/" + data.userSearchId+ "/3?"} > Unit Details</Link>}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FontAwesomeIcon className="handcurser" icon={faTrash} 
                                                                   // onClick={() => this.deleteViewAlert(data.id)}
                                                                    onClick={() => this.deleteViewModal(data.id)} 
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }) :
                                                        <TableRow><TableCell className="text-center no-data" colspan="7"> No Records Found</TableCell> </TableRow>}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[1]}
                                                            colSpan={7}
                                                            count={viewAlertsList ? viewAlertsList.length : 0}
                                                            rowsPerPage={20}
                                                            page={pageCountMDT}
                                                            onChangePage={this.handleChangePageMTD}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPageMTD}
                                                            ActionsComponent={MyMarketWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </ReactTable>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" >
                                    <div className="">
                                        <div className="ms_table pd-3 display-grid" >
                                            <ReactTable className="mst all-market-table activity-table" responsive="true">
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                    rowCount={manageAlertList ? manageAlertList.length : 0}
                                                />
                                                <TableBody>
                                                    {manageAlertList && manageAlertList.length > 0 ? this.stableSort(manageAlertList, this.getSorting(order, orderBy)).slice(pageCountMA * 20, pageCountMA * 20 + 20).map((data, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {moment(data.createdDate).format('DD-MMM-YYYY')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Link to="#" onClick={() => this.openAlertDetails(data)} > {data.alertName} </Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.alertType}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.unitType}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.alertOverview}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.allComps === 0 ? data.noOfCompetitors : "All"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={data.isActive}
                                                                                onChange={event => this.alertUpdateModal(event, data.alertID, data.isActive)}
                                                                                value={data.alertID}
                                                                                className="track-check-gl" />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FontAwesomeIcon className="handcurser" icon={faTrash} 
                                                                    //  onClick={() => this.deleteAlert(data.alertID)} 
                                                                    onClick={() => this.deleteModal(data.alertID)}
                                                                     />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }) : <TableRow><TableCell className="text-center no-data" colspan="8"> No Records Found</TableCell> </TableRow>}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[1]}
                                                            colSpan={8}
                                                            count={manageAlertList ? manageAlertList.length : 0}
                                                            rowsPerPage={20}
                                                            page={pageCountMA}
                                                            onChangePage={this.handleChangePageMA}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPageMA}
                                                            ActionsComponent={MyMarketWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </ReactTable>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Paper>
                    <div>
                        <Dialog open={this.state.detailModal} maxWidth="md" className="alert-details-popup" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle align="center">Alert Details
                            <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className="closeModal"
                                    onClick={() => this.detailModalClose()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                {alertPopupValues.map(data => {
                                    return (
                                        <React.Fragment>
                                            <p><label>Alert Name:</label> {data.alertName}</p>
                                            <p><label>Alert Type:</label> {data.alertType}</p>
                                            <p><label>Unit Type:</label> {data.unitType}</p>
                                            <p><label style={{ float: 'left' }}>Alert Details:</label> <span className="set-left-padding" dangerouslySetInnerHTML={this.alertHtmlChange(data.alertsWhen)} style={{ float: 'left' }} /><div className="clear"></div></p>
                                            <p><label style={{ float: 'left' }}>Stores to monitor for changes:</label> <span className="set-left-padding" dangerouslySetInnerHTML={this.alertTextChange(data.comps)} style={{ float: 'left' }} /><div className="clear"></div></p>
                                        </React.Fragment>);
                                })}
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog open={this.state.alertUpdateModal} maxWidth="md" className="alert-details-popup" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle>Alert Update
                            <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className="closeModal"
                                    onClick={() => this.alertUpdateClose()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to {this.state.isActiveValue ? 'deactivate this alert?' : 'activate this alert?'}</p>
                                <div className="step_btn" style={{ marginLeft: '0px' }}>
                                    <Button onClick={() => this.alertUpdate()} className="next-btn" style={{ marginRight: '10px' }}>Ok</Button>
                                    <Button onClick={() => this.alertUpdateClose()} className="go-back-btn">Cancel</Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog open={this.state.alertViewModal} maxWidth="md" className="alert-details-popup" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle>Alert Delete
                            <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className="closeModal"
                                    onClick={() => this.deleteViewClose()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to delete this alert?</p>
                                <div className="step_btn" style={{ marginLeft: '0px' }}>
                                    <Button onClick={() => this.deleteViewAlert()} className="next-btn" style={{ marginRight: '10px' }}>Ok</Button>
                                    <Button onClick={() => this.deleteViewClose()} className="go-back-btn">Cancel</Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog open={this.state.alertdeleteModal} maxWidth="md" className="alert-details-popup" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle>Alert Delete
                            <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className="closeModal"
                                    onClick={() => this.deleteModalClose()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to delete this alert?</p>
                                <div className="step_btn" style={{ marginLeft: '0px' }}>
                                    <Button onClick={() => this.deleteAlert()} className="next-btn" style={{ marginRight: '10px' }}>Ok</Button>
                                    <Button onClick={() => this.deleteModalClose()} className="go-back-btn">Cancel</Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackOpen}
                        className="snackbar bg-color-primary"
                        maxsnack={3}
                        autoHideDuration={30000}
                        onClose={this.snackHandleclose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{snackMSg}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.snackHandleclose}
                            >
                                <CloseIcon />
                            </IconButton>
                        ]}
                    />
                </div>
            </div>
        );
    }
}

//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, tabValue } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    let tableHeadings = [];
    if (tabValue === '1') {
        tableHeadings = [
            { id: 'createdDate', numeric: false, disablePadding: true, label: 'Alert Date' },
            { id: 'alertName', numeric: false, disablePadding: true, label: 'Alert Name' },
            { id: 'alertType', numeric: false, disablePadding: true, label: 'Alert Type' },
            { id: 'unitType', numeric: false, disablePadding: true, label: 'Unit Type' },
            { id: 'alertOverview', numeric: false, disablePadding: true, label: 'Alert Overview' },
            { id: 'viewDetails', numeric: false, disablePadding: true, label: 'View Details' },
            { id: 'dismiss', numeric: false, disablePadding: true, label: 'Dismiss' },
        ];
    } else {
        tableHeadings = [
            { id: 'createdDate', numeric: false, disablePadding: true, label: 'Date Created' },
            { id: 'alertName', numeric: false, disablePadding: true, label: 'Alert Name' },
            { id: 'alertType', numeric: false, disablePadding: true, label: 'Alert Type' },
            { id: 'unitType', numeric: false, disablePadding: true, label: 'Unit Type' },
            { id: 'alertOverview', numeric: false, disablePadding: true, label: 'Alert Overview' },
            { id: 'competitors', numeric: false, disablePadding: true, label: 'Competitors' },
            { id: 'active', numeric: false, disablePadding: true, label: 'Active' },
            { id: 'delete', numeric: false, disablePadding: true, label: 'Delete' },
        ];
    }
    return (
        <TableHead>
            <TableRow>
                {tableHeadings.map(row => (
                    <TableCell
                        key={row.id}
                        align='center'
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                            disabled={row.id === 'alertName' || row.id === 'createdDate' || row.id === 'alertType' || row.id === 'unitType' || row.id === 'alertOverview' ? false : true}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// For Pagination
const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

class MyMarket extends React.Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };


    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.tablepaggination}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
    MyMarket,
);