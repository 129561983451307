import React, { Component } from "react";
import "./EffectiveRates.css";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import * as d3 from "d3";
import axios from "axios";
import { CONFIG } from "../../utils/config";
import { faFilePdf, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import { TableBody } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import { withStyles } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { EffectiveRateCal } from "../EffectiveRateCal";
class EffectiveRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 4,
      isLoading1: false,
      order: "asc",
      orderBy: "storeName",
      snackOpen: false,
      snackMSg: "",
      unitDurations: [],
      hisactivemenu: "1",
      hisactivedur: "2",
      gridactivedur: 1,
      uniquestore: "",
      grapghCatcheck: "Premium",
      graphOpen: "Premium",
      // SinglelahMarkets: {},
      CoreMarketView: [],
      ChartTableData: [],
      GridTableValue: {},
      CurrentHistory: [],
      AllMarketdata: [],
      AllCurrentHistory: [],
      MarketViewpricingOptions: [],
      isLoading: false,
      AvailDataCheck: 0,
      stId: this.props.storeId,
      seId: this.props.searchId,
      defaultWidth: 0,
      priceLength: 0,
      pricecolLength: 0,
      Tabloading: false,
      promo_open: false,
      promo_open_id: 0,
      uniquestores: [],
      pdfunique: 0,
      snackClr: "",
      pageCount: 0,
      effectiveRateData: [],
      dropdownOpen: false,
      drpDownSelValue: 12,
      tenancyArray: [3, 6, 9, 12],
      moneySymbol:this.props.currency !== undefined ? this.props.currency: this.props.currency,
    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
    this.onSelecCatGrapgh = this.onSelecCatGrapgh.bind(this);
    this.DurationsdropDown = this.DurationsdropDown.bind(this);
    this.AlignGraphTabData = this.AlignGraphTabData.bind(this);
    this.AlignHistoryGraphData = this.AlignHistoryGraphData.bind(this);
    //this.GetSummaryMarketData = this.GetSummaryMarketData.bind(this);
    this.GetGridtableData = this.GetGridtableData.bind(this);
    this.GetGraphTableData = this.GetGraphTableData.bind(this);
    this.onDurAndPriChange = this.onDurAndPriChange.bind(this);
    localStorage.setItem("StId", this.props.storeId);
    this.toggle = this.toggle.bind(this);
    this.child = React.createRef();
  }

  //Duration and Price Type Change
  onDurAndPriChange(e) {
    const { name, value } = e.target;
    let fields = this.state;
    fields[name] = value;
    if (name === "gridactivedur") {
      this.setState({ fields, isLoading1: true }, () =>
        this.GetGridtableData()
      );
    } else if (name === "hisactivedur") {
      this.setState({ fields, isLoading: true });
    } else {
      this.setState({ fields, isLoading1: true }, () =>
        this.AlignHistoryGraphData()
      );
    }
  }

  //Tabs
  tabtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          Tabloading: true,
          // Tabloading: tab === 4 ? false : true,
        },
        () => {
          //  if (tab !== 4) {
          this.GetGraphTableData();
          //  }
        }
      );
    }
  }

  //Table Sorting
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    let sort = isDesc ? "asc" : "desc";
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }

  //To open Particular Category's Graph
  onSelecCatGrapgh(openActGraph) {
    let activeRow =
      this.state.grapghCatcheck === openActGraph ? "" : openActGraph;
    activeRow === ""
      ? this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow })
      : this.setState(
          { grapghCatcheck: activeRow, graphOpen: activeRow },
          () => {
            //  this.TableViewGraphs();
          }
        );
  }

  //Grid Actions
  barhandleChange(CompId) {
    //loader
    this.setState({ isLoading: true, pdfunique: 1 });
    if (this.state.GridTableValue.dynamdata) {
      //getting the ids of to show or hide the graph data
      let GraphshowData = this.state.GridTableValue.dynamdata.filter(function (
        data
      ) {
        return data.isSelected === true && data.storeID !== CompId;
      });

      if (GraphshowData.length > 6) {
        this.setState({
          isLoading: false,
          snackOpen: true,
          snackMSg:
            "You can view up to 8 markets only, please remove any of the selected markets to add new ones.",
        });
      } else {
        //to change the selected value which clicked on table
        let uniquestore = [
          ...new Set(
            this.state.GridTableValue.dynamdata
              .filter(function (data) {
                return data.isSelected === true
                  ? data.storeID !== CompId
                  : data.storeID === CompId;
              })
              .map((item) => item.storeID)
          ),
        ].join(", ");

        let uniquestores = [
          ...new Set(
            this.state.GridTableValue.dynamdata
              .filter(function (data) {
                return data.isSelected === true
                  ? data.storeID !== CompId
                  : data.storeID === CompId;
              })
              .map((item) => item.storeID)
          ),
        ].join("/");

        for (var i = 0; i < this.state.GridTableValue.dynamdata.length; i++) {
          if (this.state.GridTableValue.dynamdata[i].storeID === CompId) {
            this.state.GridTableValue.dynamdata[i].isSelected =
              this.state.GridTableValue.dynamdata[i].isSelected === true
                ? false
                : true;
          }
        }
        let GridTableValue = this.state.GridTableValue;

        //If User Remove all Markets, then uniquestore should be 0
        uniquestore = uniquestore === "" ? 0 : uniquestore;
        uniquestores = uniquestores === "" ? 0 : uniquestores;
        this.setState({ uniquestores });

        //console.log(GridTableValue);
        this.setState({ GridTableValue, uniquestore }, () => {
          this.GetGraphTableData();
         // this.GetcurrentpricinganalysisHistory();
        });
      }
    }
  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  //Formatted in as per competitor
  formData(data) {
    let uniquestore = [...new Set(data.map((item) => item.storeID))];
    let CurrentActivity = this.state.grapghCatcheck;
    let Colorbarcode = [
      "#f69e4f",
      "#85411b",
      "#5b95f6",
      "#f96a5d",
      "#3ea7a1",
      "#83ce83",
      "#fac05f",
      "#959595",
    ];
    var FData = [];
    for (var t = 0; t < uniquestore.length; t++) {
      var tFData = data.filter(
        (a) =>
          a.storeID == uniquestore[t] && a.priceOptionName === CurrentActivity
      );
      var mData = {};
      if (tFData.length > 0) {
        let splitpriceVal = tFData[0].price.split("-");
        let priceVal = splitpriceVal[1]
          ? parseInt(splitpriceVal[0])
          : splitpriceVal[0] !== "0"
          ? parseInt(splitpriceVal[0])
          : 0;
        let p1 =
          CurrentActivity === "Price Range"
            ? splitpriceVal[1]
              ? parseInt(splitpriceVal[1])
              : parseInt(splitpriceVal[0])
            : 0;

        mData = {
          StoreID: uniquestore[t],
          StoreName: tFData[0].storeName,
          price: parseInt(priceVal),
          priceOptionName: tFData[0].priceOptionName,
          price1: p1,
          labelPrice: splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0],
          labelPrice2:
            splitpriceVal[1] !== "0" && splitpriceVal[1]
              ? splitpriceVal[1]
              : splitpriceVal[0] === "0"
              ? "N/A"
              : splitpriceVal[0],
          Gcolor: Colorbarcode[t],
          IsSelected: tFData[0].isSelected,
          isMonthly: tFData[0].isMonthly,
        };
      }

      FData.push(mData);
    }
    return FData;
  }

  // Text Wrap For Graph X-axis label
  wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
    });
  }

  //Formatted in as per competitor
  HisformData(data, uid) {
    var tFData = data.filter((a) => a.storeID === uid);
    var tData = [];
    let CurrentActivity = this.state.hisactivemenu === "2" ? "Price Range" : "";
    for (var i = 0; i < tFData.length; i++) {
      // for price range calculation
      let calPrice, price2;
      if (CurrentActivity === "Price Range") {
        let splitpriceVal = tFData[i].price.split("-");
        //calPrice = splitpriceVal[1] ? (parseInt(splitpriceVal[1]) + parseInt(splitpriceVal[0])) / 2 : parseInt(tFData[i].price);
        calPrice = parseInt(splitpriceVal[0]);
        price2 = splitpriceVal[1]
          ? parseInt(splitpriceVal[1])
          : parseInt(splitpriceVal[0]);
      } else {
        calPrice = tFData[i].price;
        price2 = 0;
      }
      // for display label date
      let caldate;
      // if(this.state.hisactivedur === '2' || this.state.hisactivedur === '3'){
      //     caldate = moment(tFData[i].datePrice.substring(8)).format('DD/MM/YY');
      // }
      // else if(this.state.hisactivedur === '1'){
      //     caldate = moment(tFData[i].datePrice.substring(4)).format('DD/MM/YY');
      // }
      // else{
      //     caldate = tFData[i].datePrice;
      // }
      caldate = moment(tFData[i].datePrice).toDate();
      var CData = {
        date: moment(tFData[i].datePrice).toDate(),
        StoreName: tFData[i].storeName,
        price: parseInt(calPrice),
        price2: parseInt(price2),
        storeID: tFData[i].storeID,
        labeldate: caldate,
        labelPrice: tFData[i].price === "0" ? "N/A" : tFData[i].price,
        orderNo: tFData[i].orderNo,
        priceOptionName: tFData[i].priceOptionName,
      };
      tData.push(CData);
    }
    return tData;
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
        hisactivedur: "2",
        hisactivemenu: "1",
        uniquestore: "",
        uniquestores: [],
        pdfunique: 0,
      },
      () => {
        this.commonapiCall();
      }
    );
    console.log("santhosh");
    //console.log(window.innerWidth, 'innerWidth');\
  }

  commonapiCall() {
    this.DurationsdropDown();
    this.GetGraphTableData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchId !== this.state.seId) {
      this.setState(
        {
          hisactivedur: "2",
          seId: nextProps.searchId,
          stId: nextProps.storeId,
        },
        () => {
          this.componentDidMount();
        }
      );
    }
  }

  //Duration For DropDown
  DurationsdropDown() {
    this.setState({isLoading1: true});
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .get(CONFIG.API_URL + "unit/unitdurations")
      .then((response) => {
        let unitDurations = response.data.data.lookupData;
        this.setState({
          unitDurations,isLoading1: false, isLoading: false
        });
      })
      .catch((err) => {
        this.setState({isLoading1: false, isLoading: false});
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Grid Table data
  GetGridtableData() {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(
        CONFIG.API_URL + "dashboard/currentpricinganalysisallmarketviews",
        data
      )
      .then((res) => {
        let GridTableValue = this.state.GridTableValue;
        let AllMarketdata = res.data.current.currentPricingAnalysisAllMarket;
        //To get Only Your Store on All Market View
        GridTableValue["statdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore === 1;
        });
        //To get Stores Expect Your Store on All Market View
        GridTableValue["dynamdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore !== 1;
        });
        this.setState({ GridTableValue, AllMarketdata, isLoading1: false });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  //History Graph
  GetcurrentpricinganalysisHistory() {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    this.setState({
      AllCurrentHistory: [],
      isLoading: true,
    });

    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.hisactivedur),
      RegionId: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "dashboard/currentpricinganalysisHistories", data)
      .then((response) => {
        let AllCurrentHistory = response.data.current;
        this.setState({ AllCurrentHistory }, () => {
          this.AlignHistoryGraphData();
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Data Format HistoryGraph View
  AlignHistoryGraphData() {
    const { AllCurrentHistory, hisactivemenu } = this.state;

    //Based Dropdown above the History graph
    let CurrentActivity =
      hisactivemenu === "1"
        ? "Premium"
        : hisactivemenu === "2"
        ? "Price Range"
        : "Value";
    // Get Current priceOptionName
    let filterByMenu = AllCurrentHistory.filter(
      (a) => a.priceOptionName === CurrentActivity
    );

    //Spliting mystore and Non-mystore data to order
    let getMystore = filterByMenu.filter((a) => a.isMyMarket === 1);
    let getwithoutMystore = filterByMenu.filter((a) => a.isMyMarket === 0);

    let CurrentHistory = getMystore.concat(getwithoutMystore);
    //console.log(CurrentHistory, 'CurrentHistory');
    setTimeout(
      function () {
        this.setState(
          { CurrentHistory, isLoading: false, isLoading1: false },
          () => {
            //  this.HistoryGraphView();
          }
        );
      }.bind(this),
      3000
    );
  }

  //Graph Table data
  GetGraphTableData() {
    this.setState({isLoading1: true, isLoading: true, Tabloading: true});
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Duration: parseInt(this.state.gridactivedur),
      Competitors: this.state.uniquestore,
      RegionId: CONFIG.RegionId,
    }; 
    axios
      .post(
        CONFIG.API_URL + "dashboard/currentpricinganalysiscoremarketview",
        data
      )
      .then((res) => {
        let CoreMarketView = res.data.current.currentPricingAnalysisMarket;
        let MarketViewpricingOptions = res.data.current.currentPricingAnalysis;
        let effectiveRateData = [];
        CoreMarketView.map((x) => {
          const found = effectiveRateData.some(el => el.storeID === x.storeID);
       
          return(
            effectiveRateData.push({storeID: x.storeID,
              address: found ? '' : x.address,
              storeName: found ? '' : x.storeName,
              priceOptionName: x.priceOptionName,
              price: x.price,
              isMyStore: x.isMyStore,
              alternatePriceTag: x.alternatePriceTag,
              proximity: x.proximity,
              myStorePriceRank: x.myStorePriceRank,
              promo: x.promo,
              isMonthly: x.isMonthly,
              floorType: x.floorType,
              advertisedPrice: x.advertisedPrice,
              promotion: x.promotion,
              m1: x.m1,
             m2: x.m2,
              m3: x.m3,
              m4: x.m4,
              m5: x.m5,
              m6: x.m6,
              m7: x.m7,
              m8: x.m8,
              m9: x.m9,
              m10: x.m10,
              m11: x.m11,
              m12: x.m12,})
          )
        })
        this.setState({
          CoreMarketView,
          MarketViewpricingOptions,
          effectiveRateData: effectiveRateData,
          isLoading1: false, isLoading: false,
          Tabloading: false,
        });
      })
      .catch((err) => {
        this.setState({isLoading1: false, isLoading: false, Tabloading: false});
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Data Format table View
  AlignGraphTabData() {
    const { CoreMarketView } = this.state;

    let ChartTableData = this.state.ChartTableData;

    //Checking for data availability
    let AvailDataCheck = [];

    AvailDataCheck = CoreMarketView.filter(function (data) {
      return data.storeID > 0 && data.price != 0;
    });

    //For table Row Height controll
    let priceLength = this.state.priceLength;
    let pricecolLength = this.state.pricecolLength;

    /* Spliting the data in the order of activityType */
    ChartTableData["lahMarkets"] = [];
    if (AvailDataCheck.length > 0) {
      if (this.state.activeTab === 3) {
        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0;
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Promotions",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0;
          }),
        });
        /* End of Barchart table's body data */
      } else {
        //For table Row Height controll
        priceLength = d3.max(
          AvailDataCheck.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Price Range";
          }),
          (d) => d.price.length
        );
        //}), d => (d.price + (d.alternatePriceTag ? '(' + d.alternatePriceTag + ')' : '')).length);

        pricecolLength = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Price Range";
        }).length;
        // End of For table Row Height controll

        //For Premium data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Premium";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Premium"
            );
          }),
        });

        //For Price Range data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Price Range";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Price Range"
            );
          }),
        });

        //For Value data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Value";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return data.isMyStore === true && data.priceOptionName === "Value";
          }),
        });

        /* end of Spliting the data in the order of activityType */

        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Premium";
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Premium",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Premium";
          }),
        });

        //For Price Range data
        ChartTableData["TableBody"].push({
          td1: "Price Range",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Price Range";
          }),
        });

        //For Value data
        ChartTableData["TableBody"].push({
          td1: "Value",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Value";
          }),
        });
      }
    }
    if (
      this.state.graphOpen === "" ||
      this.state.activeTab === 3 ||
      AvailDataCheck.length === 0
    ) {
      this.setState({
        ChartTableData,
        AvailDataCheck: AvailDataCheck.length,
        pricecolLength,
        priceLength,
      });
    } else {
      this.setState(
        {
          ChartTableData,
          AvailDataCheck: AvailDataCheck.length,
          pricecolLength,
          priceLength,
        },
        () => {
          // this.TableViewGraphs();
        }
      );
    }

    /* end of Barchart table's body data */
  }

  //Promotions Pop Up
  promohandleOpen(id) {
    this.setState({
      promo_open: true,
      promo_open_id: id,
    });
  }

  promohandleClose() {
    this.setState({
      promo_open: false,
      promo_open_id: 0,
    });
  }
  //End OF PRomotions Pop Up
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = this.state.gridactivedur;
    let usId = this.props.storeId;
    let searchId = this.props.searchId;
    let accessKey = localStorage.getItem("SecretKey");
    let priceType = this.state.hisactivedur;
    let unitType = this.state.hisactivemenu;
    let orderBy = this.state.orderBy;
    let order = this.state.order;
    let unitTypes =
      this.state.uniquestores && this.state.pdfunique == 1
        ? this.state.uniquestores
        : this.state.pdfunique != 1
        ? "no"
        : "";
    let grapghCatcheck =
      this.state.grapghCatcheck == "Premium"
        ? 1
        : this.state.grapghCatcheck == "Value"
        ? 3
        : this.state.grapghCatcheck == "Price Range"
        ? 2
        : 4;
    let tDuration = this.state.drpDownSelValue;
    //    let activeTab = this.state.activeTab;
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "EffectiveRate" + "_" + timestamp + ".pdf";
    var stoName =
      this.props.storeDetails &&
      this.props.storeDetails.storeName &&
      this.props.storeDetails.storeName !== null &&
      this.props.storeDetails.storeName !== "" &&
      this.props.storeDetails.storeName !== undefined
        ? this.props.storeDetails.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "EffectiveRates" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 12;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/" +
        tDuration +
        "/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/" +
        grapghCatcheck +
        "/" +
        unitTypes,
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });               
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);       
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );          
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);             
        this.setState({isLoading: false });  
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  //download excel for effective rate
  downLoadExcel = ()=> {
    this.setState({isLoading: true})
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    var stoName =
      this.props.storeDetails &&
      this.props.storeDetails.storeName &&
      this.props.storeDetails.storeName !== null &&
      this.props.storeDetails.storeName !== "" &&
      this.props.storeDetails.storeName !== undefined
        ? this.props.storeDetails.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "EffectiveRates_" + currentDate + ".xlsx";
    
    const Input={
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors: this.state.uniquestore,
      Currency: this.state.moneySymbol,
      RegionId: CONFIG.RegionId,
      EffectiveTimePeriod: this.state.drpDownSelValue
    }
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios({
      url: CONFIG.API_URL + "dashboard/downloadeffectiverate",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;        
        link.click();   
        this.setState({ isLoading: false }); 
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  //pagination next page
  handleChangePage = (event, pageCount) => {
    this.setState({ pageCount });
  };

  //change next set of page in a row
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  //open/close dropdown
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  //handlechange for duration
  handleFilterChange = (e, selValue) => {
    this.setState(
      {
        drpDownSelValue: selValue,
      },
      () => this.child.current._handleColumnHide(selValue)
    );
  };

  //price conversion
  priceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }

  render() {
    const {
      activeTab,
      promo_open,
      isLoading,
      pricecolLength,
      order,
      orderBy,
      grapghCatcheck,
      graphOpen,
      ChartTableData,
      /*SinglelahMarkets,*/ GridTableValue,
      unitDurations,
      MarketViewpricingOptions,
      snackOpen,
      snackMSg,
      AvailDataCheck,
      effectiveRateData,
      pageCount,
      dropdownOpen,
      drpDownSelValue,
      tenancyArray,
      moneySymbol
    } = this.state;
    let ComActivityType = ["Premium", "Price Range", "Value"];
    let Colors = [
      "mystorebarclr",
      "barclr1",
      "barclr2",
      "barclr3",
      "barclr4",
      "barclr5",
      "barclr6",
      "barclr7",
    ];
    // let moneySymbol = this.props.currency ? this.props.currency : "";
    var hlpcnt;
    if (activeTab === 1) {
      hlpcnt =
        "<h6>" +
        `${
          CONFIG.RegionId === 3
            ? "Current Advertised Weekly Rates"
            : "Current Advertised Rates"
        }` +
        "</h6> <p>In this section, the graph enables you to compare rates like-for-like, against your top competitors*.  The rates shown on the graph are based on the Online Advertised Rate on the store's website.</p><p>The Number of Pricing Options shows you if the selected store* offers more than one price type for this unit type.</p><ul><li>The highest price advertised by a store is shown as the Premium rate.</li><li>The lowest price advertised by a store is shown as the Value rate.</li><li>Any other rates in between are shown as a range</li><li>If there is only one price for a unit, it is shown as both the Premium and Value rate</li></ul><p>Market Low, Market Average and Market High</p><p>These rates are based on the online rates advertised by the competitor stores* and the price type chosen (Premium or Value).</p><p>*See the All Market View section below to learn how you can change the competitors you can compare against on this graph.</p><p><i>*Your store prices are included in the calculations.</i></p>";
    } else if (activeTab === 3) {
      hlpcnt =
        "<h6>Promotions</h6><p>These are unit-specific promotions advertised by the store for the above unit type.</p>";
    } else {
      hlpcnt =
        "<h6>" +
        `${
          CONFIG.RegionId === 3
            ? "Advertised Walk-in Weekly Rates"
            : "Advertised Walk-in Rates"
        }` +
        "</h6><p>These are the walk-in (or 'Standard') rates offered by the store for this unit type.</p>";
    }
    const defaultProps = {
      helpTitle: "Core Market View",
      helpContent:
        hlpcnt +
        "<p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/EhEUYS4SMAA' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    return (
      <div className="currentprice">
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.isLoading1 ? (
          <div className="loader-wrap">
            <div className="loading loading-center"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.Tabloading ? (
          <div className="loader-wrap">
            <div className="loading loading-center"></div>
          </div>
        ) : (
          ""
        )}
        <div item="true" xs={12}>
         <Row>
            <Col md="12">
              <div className="rate-clac-text">
              The Effective Rate is the price the consumer pays after factoring in the promotion. The calculation is performed over a specific tenancy length, which can be selected on the right.
                </div>
              <Button
                disabled={
                  effectiveRateData && effectiveRateData.length !== 0
                    ? false
                    : true
                }
                className={
                  effectiveRateData && effectiveRateData.length !== 0
                    ? "mt-3 apply-btn download-pdf "
                    : "download-pdf-disabled"
                }
                onClick={this.downLoadPDF.bind(this)}
              >
                <FontAwesomeIcon icon={faFilePdf} />
                PDF Download
              </Button>
              <Button
                disabled={
                  effectiveRateData && effectiveRateData.length !== 0
                    ? false
                    : true
                }
                className={
                  effectiveRateData && effectiveRateData.length !== 0
                    ? "mt-3 apply-btn download-pdf "
                    : "download-pdf-disabled"
                }
                onClick={this.downLoadExcel.bind(this)}
              >
                <FontAwesomeIcon icon={faFileExcel} />
                Excel Download
              </Button>
              {effectiveRateData && effectiveRateData.length > 0 ? <div class="tenancy-cot">
                <h5>Tenancy Length</h5>
                <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle caret>
                    {drpDownSelValue === 0
                      ? "-Select-"
                      : drpDownSelValue + " months"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {tenancyArray &&
                      tenancyArray.map((drpDownValue) => {
                        return (
                          <DropdownItem
                            onClick={(e) =>
                              this.handleFilterChange(e, drpDownValue)
                            }
                          >
                            {drpDownValue + " months"}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              : ''}</Col>
          </Row>
          <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackOpen}
                className={
                  this.state.snackClr +
                  " snackbar bg-color-primary custom-snackbar"
                }
                maxsnack={3}
                autoHideDuration={30000}
                onClose={this.snackHandleclose.bind(this)}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{snackMSg}</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.snackHandleclose.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
        </div>
        {effectiveRateData && effectiveRateData.length > 0 ? (
          <EffectiveRateCal
            drpDownSel={drpDownSelValue}
            effData={effectiveRateData}
            data={effectiveRateData}
            ref={this.child}
            currency={moneySymbol}
          />
        ) : !this.state.Tabloading ? (
          <div style={{textAlign: "center"}}><p style={{color: '#f15d47', fontWeight: '500'}}>No data available</p> </div>
        ) : ''}
      </div>
    );
  }
}

export default React.memo(EffectiveRates);
//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headRows = [
    { id: "storeName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "currentPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Premium",
    },
    {
      id: "currentValue",
      numeric: true,
      disablePadding: false,
      label: "Current Value",
    },
    {
      id: "premium",
      numeric: true,
      disablePadding: false,
      label: "Highest Premium Price Offered",
    },
    //{ id: 'priceRange', numeric: true, disablePadding: false, label: 'Standard' },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Lowest Value Price Offered",
    },
    { id: "promos", numeric: true, disablePadding: false, label: "Promotion" },
    {
      id: "currentWalkInPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Premium",
    },
    {
      id: "currentWalkInValue",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Value",
    },
    {
      id: "moreoptions",
      numeric: true,
      disablePadding: false,
      label: "More Options",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="center"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              disabled={row.id !== "moreoptions" ? false : true}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// For Pagination
const actionsStyles = (theme) => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class EffectiveRate extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const EffectiveRateWrapped = withStyles(actionsStyles, { withTheme: true })(
  EffectiveRate
);
