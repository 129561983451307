import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LiveHelp from '@material-ui/icons/LiveHelp';
import './HelpTextPopup.css';
class HelpTextPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }

    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }
  handleClose() {
    this.setState({
      open: false
    })
  }
  handleClickOpen() {
    this.setState({
      open: true
    })
  }



  render() {
    const { open } = this.state;

    return (
      <div className="help-compo" title="What am I looking at?">

        {/* <IconButton size="small" color="primary" onClick={this.handleClickOpen}  > */}
        <span className="pop-up-link" onClick={this.handleClickOpen}><LiveHelp aria-label="Help" fontSize="small" /></span>
        {/* </IconButton> */}
        <Dialog
          open={open}
          // onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="helpTextbox"
        >

          <DialogTitle id="alert-dialog-title">{this.props.parm.helpTitle} <IconButton className="help-close-btn" aria-label="close" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton></DialogTitle>
          <DialogContent>
            <DialogContentText dangerouslySetInnerHTML={{ __html: this.props.parm.helpContent }}>

            </DialogContentText>
          </DialogContent>

        </Dialog>
      </div>
    );
  }
}
export default HelpTextPopup;