import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { HashLink as Link } from 'react-router-hash-link';
import { CONFIG } from '../../utils/config';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, Collapse, CardBody, Card } from 'reactstrap';
import './Login.css';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sighinerror: '', modal: false, modal1: false, collapse: false, collapse1: true,curSymb:''
        };
        this.toggleforgetpwd = this.toggleforgetpwd.bind(this);
        this.togglealert = this.togglealert.bind(this);
    }

    valueChanged() {
        console.log('hi');
        this.setState({ sighinerror: '' });
    }
    componentDidMount() { 
        localStorage.clear();
        window.scrollTo(0, 0);
        this.setState({
            curSymb: CONFIG.Currency_Symbol
        })

        //Region
        // axios.get(CONFIG.API_URL + 'home/region')
        // .then(response => {
        //     let curSymb = response.data.region.currencySymbol;
        //     this.setState({
        //         curSymb
        //     })
        // });
        
      }
    handleSubmit(event, errors, values) {
        // console.log(values);
        this.setState({ sighinerror: '' });
        if (errors.length > 0) {

            console.log(errors);
        }
        else {
            this.setState({ isLoading: true });
            axios.post(CONFIG.API_URL + 'account/securelogin', values)

                .then(res => {

                    if (res.status === 200) {
                        const getTokvalues = res.data;
                        this.myFormRef && this.myFormRef.reset();
                        //console.log(getTokvalues);
                        this.setState({ getTokvalues });
                         axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
                        localStorage.setItem('SecretKey', getTokvalues.secureID);
                        localStorage.setItem('accessKey', getTokvalues.token);
                        localStorage.setItem('getNames', getTokvalues.firstName);
                        localStorage.setItem('isPaid', getTokvalues.isPaid);
                        localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
                        localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
                        localStorage.setItem('userType', getTokvalues.userType);
                        localStorage.setItem('userStatus', getTokvalues.status);
                        localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
                        localStorage.setItem('userManual', getTokvalues.isManual);
                        localStorage.setItem('userCdate', getTokvalues.createdDate);
                        localStorage.setItem('displayMessage', getTokvalues.displayMessage);

                        if (getTokvalues.isPaid === 0) {
                            console.log(window.location.pathname);
                            console.log(window.location.href);
                            const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
                            // this.props.history.push(from);
                            // window.location.reload('http://localhost:3000/my-account/')
                            window.top.location.href = CONFIG.OPTZ_URL+"my-market/";
                        }
                        else {
                            const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
                            // this.props.history.push(from);
                            window.top.location.href = CONFIG.OPTZ_URL+"my-market/";
                        }

                    }

                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.data.StatusCode === 400) {
                        this.myFormRef && this.myFormRef.reset();
                        const sighinerror = err.response.data.Message;
                        this.setState({ sighinerror });
                    }

                });

        }
        this.setState({ errors, values });

    }

    handleforgetSubmit(event, errors, values) {
        //   const sighinsucess ='';
        if (errors.length > 0) {

            console.log(errors);
        }
        else {
            this.setState({ isLoading: true });
            const data = {
                Username: values.Username,
                "RegionId": CONFIG.RegionId
            }
            axios.post(CONFIG.API_URL + 'account/ForgotPassword', data)
                .then(res => {

                    const issuccss = res.data;
                    this.setState({ isLoading: false });
                    if (issuccss === "success") {
                        this.setState({ sighinsucess: ["The password reset link has been sent to your email."] });
                        this.togglealert();
                        this.form && this.form.reset();
                        this.setState({collapse1: true, collapse: false });
                    }

                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.form && this.form.reset();
                    const sighinsucess = err.response.data;
                    this.setState({ sighinsucess });

                });

        }

    }
    togglealert() {
        this.setState(prevState => ({
            modal1: !prevState.modal1
        }));
    }
    toggleforgetpwd() {

        this.form && this.form.reset();
        const sighinsucess = '';
        this.setState({ sighinsucess });

        this.setState({collapse1: false, collapse: true });
    }
    toggleforgetcancel() {
        //this.setState(state => ({ modal: !state.modal }));
        this.setState({collapse1: true, collapse: false });
    }
    resetAllValues() {

        this.myFormRef && this.myFormRef.reset();
        this.setState({ sighinerror: '' });

    }
    render() {
        const { sighinerror, sighinsucess } = this.state;
        return (

            <div className="body-form">
                <div className="opti-form">
                <Collapse isOpen={this.state.collapse1}>
                        
                    <h5 className="size-18 mrg-btm-10">Optimize SignIn </h5>
                    <div className="sign-in-fields">
                        <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.myFormRef = c)}>
                            <AvField name="Username" placeholder="Username" onChange={this.valueChanged.bind(this)} type="email" validate={{
                                required: { value: true, errorMessage: 'Username is required' },
                                pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Username is Invalid' }
                            }} />
                            <AvField name="Password" placeholder="Password" onChange={this.valueChanged.bind(this)} type="password" validate={{
                                required: { value: true, errorMessage: 'Password is required' }
                            }} />
                            <span className="sign-error">{sighinerror}</span>
                            <div className="sign-in-btn">

                                <Button id="btn" className="submit-btn btn-design">Sign In</Button>
                                <Link to="#" onClick={this.resetAllValues.bind(this)} className="reset-pwd">Reset</Link>
                                <Link to="#" rel="noopener noreferrer" onClick={this.toggleforgetpwd.bind(this)} className="fwd-link" id="fwd-id">Forgot Password?</Link>
                            </div>
                            <AvField type="hidden" name="RegionId" value={CONFIG.RegionId} />

                        </AvForm>
                    </div>
                   
                    </Collapse>
                </div>

                <div className="opti-form forgot-password-design">
                    <Collapse isOpen={this.state.collapse}>
                        <h4>Forgot Password</h4>
                                <p>Provide your email address and we will send you a link to update your password.</p>
                                <div className="sign-in-fields">
                                    <AvForm onSubmit={this.handleforgetSubmit.bind(this)} ref={cl => (this.form = cl)} >
                                        <AvField name="Username" placeholder="Enter Your Email id" type="email" validate={{
                                            required: { value: true, errorMessage: 'Email id is required' }
                                        }} />
                                        <span>{sighinsucess}</span>
                                        <div className="sign-in-btn">
                                            
                                            <Button id="btn" className="submit-btn btn-design">Send</Button>
                                            <Link to="#" onClick={this.toggleforgetcancel.bind(this)} className="reset-pwd">Cancel</Link>
                                        </div>
                                    </AvForm>

                                </div>
                           
                    </Collapse>
                    <Modal isOpen={this.state.modal1} toggle={this.togglealert} >
                        <ModalHeader toggle={this.togglealert}> {sighinsucess}</ModalHeader>

                    </Modal>
                </div>
            </div>
        );
    }
}

