import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
//import  {MarketDashboardPdfHeader}  from './../MarketDashboardPdfHeader/index';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { HashLink as Link } from 'react-router-hash-link';
import './MarketDashboardPdf.css';
import Card from './Card';
import { CONFIG } from './../../../utils/config';
import axios from 'axios';
import { HelpTextPopup } from '../../HelpTextPopup/index';
import Logo from '../../../Assets/Images/logo.jpg';
import { arrayOf } from 'prop-types';
//import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
class MarketDashboardPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeid: this.props.storeid,
      cards: [],
      storedata: [],
      isLoading: false,
      storeInformation: [],
      countryCode: '',
      storeAddress: '',
      priceTrends: this.props.priceTrend.toString(),
      priceTrendsAvg: this.props.priceTrend.toString(),
      accessKey: this.props.accessKey
    }
    this.handler = this.handler.bind(this);
    this.refreshMenu = this.refreshMenu.bind(this);
    localStorage.setItem('StId', this.props.storeid);
  }
  refreshMenu() {
    localStorage.setItem('refreshMenu', "show-menu");
    var isElement = document.getElementById('r2');
    if (isElement == null) {
      document.getElementById('r1').click();
    }
    else {
      document.getElementById('r1').click();
      document.getElementById('r2').click();
    }
  }
  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.MarketDashboard();
    } else {
      alert(response.status.Message);
    }
  }
  MarketDashboard(priceTrendss) {

    this.setState({
      isLoading: true
    })
    // priceTrendss = this.props.priceTrend ;
    const userstoreid = this.state.storeid ? this.state.storeid : this.props.storeid;
    let priceTrends1 = '';
    if (priceTrendss) {
      priceTrends1 = priceTrendss;
    }
    else {
      priceTrends1 = this.state.priceTrends;

    }
    let token = this.state.accessKey;
    // console.log(token);

    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    const data1 = {
      "UserStoreID": parseInt(this.state.storeid),
      "RegionId": CONFIG.RegionId
    }
    axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data1)
      .then(res => {
        let storeAddress = res.data.data.storeDashboard;
        this.setState({ storeAddress });

      })
      .catch((err) => {


        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          // localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    if (userstoreid) {
      let data = {
        "UserStoreId": userstoreid,
        "SortId": priceTrends1,
        "RegionId": CONFIG.RegionId
      }
      // axios.post(CONFIG.API_URL + 'market/dashboard/' + userstoreid + '/' + priceTrends1)
      axios.post(CONFIG.API_URL + 'market/dashboard/', data)
        .then(res => {

          // if(res.data.board.storeInformation !=null){
          this.state.countryCode = res.data.board.storeInformation.countryCode;
          this.state.storeInformation = res.data.board.storeInformation.countryCurrency;
          this.state.cards = res.data.board.units;
          const storedata = res.data.board.storeInformation;
         // this.state.cards.unshift('Add New')
          this.setState({ isLoading: false, storedata });

          /*
           }
           else
           {
            alert("No store units available");
            const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
            this.props.history.push(from);
           }*/
        })
        .catch((err) => {

          this.setState({ isLoading: false });
          if (err.response.status === 401) {
            // localStorage.removeItem('accessKey');
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
          }
          else if (err.response.status === 403) {
            const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
            this.props.history.push(from);
          }
          else {
            alert(err.response.data);
          }
        });
    }
    else {
      alert("User store id is empty");
    }
  }
  showPriceTrends = (event) => {
    let priceTrends = event.target.value;

    this.setState({ priceTrendsAvg: priceTrends, priceTrends: priceTrends }, () => { this.componentDidMount(priceTrends); });


  }

  moveCard = (dragIndex, hoverIndex) => {
    const dragCard = this.state.cards[dragIndex];
    this.state.cards.splice(dragIndex, 1);
    this.state.cards.splice(hoverIndex, 0, dragCard);

    this.setState(
      { cards: this.state.cards });
    const userstoreid = this.state.storeid;
    let removeCard = this.state.cards;
    removeCard.shift();
   // this.state.cards.unshift('Add New')
    let reorderid = removeCard;
    let arr = [];

    reorderid.forEach(function (e) { arr.push(e.searchid) });

    let searchorder = arr.toString();
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    let data = {
      "UserStoreId": userstoreid,
      "OrderList": searchorder,
      "RegionId": CONFIG.RegionId
    }
    // axios.post(CONFIG.API_URL + 'Market/unitorder/' + userstoreid + '/' + searchorder)
    axios.post(CONFIG.API_URL + 'Market/unitorder/', data)
      .then(res => {
        //  console.log(res)
      })
      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          //localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          // alert(err.response.data);
        }
      });

  }
  handler() {
    this.componentDidMount();
  }
  render() {
    const { cards, storeInformation, isLoading, countryCode, storeid, storedata, storeAddress, priceTrendsAvg } = this.state;
    const defaultProps = {
      helpTitle: 'Market Overview',
      helpContent: "<p>By default, this dashboard shows 12 of the most common unit types. Each 'box' gives you a quick snapshot of the rates for this Market. Below is a breakdown of what's in each snapshot:</p><h6>Offered by x stores</h6><p>This is the number of stores advertising this unit type in this Market</p><h6>Average Market Price</h6><p>This is the market average rate for this unit type. The average rates shown here are calculated using the Advertised Online rates</p><h6>Trend</h6><p>The upward/downward arrow shows the direction of the trend for this unit type's rates over the period selected (5 days, 30 days, 90 days)</p><h6>Your Price</h6><p>If you have set up your store as your Market, your store's rate(s) will appear here for the unit type. If we have not found a rate for your store, it will be shown as 'N/A'</p><h6>View Details</h6><p>To view the detailed pricing insights for the unit type, click on View Details</p><h6>+Add New</h6><p>You are not limited to the 12 unit types you see here. Click +Add New to configure your own unit size and features</p><h6>Edit</h6><p>Click the pencil icon to make any changes to the features for that unit type</p><h6>Delete</h6><p>Click the x to delete this unit type from the dashboard</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/4mN1tA5gjtk' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    }
    const tr = [...Array(Math.ceil(cards.length / 15))];
    const trproductRows = tr.map((row, idx) => cards.slice(idx * 15, idx * 15 + 15));

    var preIndex = 0;
    const nameCount = storeAddress.storeName ? storeAddress.storeName.length : 0;
    return (
      <div className="my-markets-dashboard mrk-dashboard-pdf">
        <div id="r2"></div>
        {/* <Breadcrumbs separator="›" aria-label="Breadcrumb" className="breadcrumb-float-left breadcrumb-top">
          <Typography color="textPrimary" className="link-tag">Overview</Typography>
          <Link to="/my-market/" onClick={() => this.refreshMenu()} color="inherit" className="link-tag">My Market</Link>
          <Typography component="h6" className="link-tag">{storeAddress.storeName?storeAddress.storeName:''}</Typography>
          <Typography component="h6" className="link-tag">Dashboard</Typography>
        </Breadcrumbs>
        
        <div className="clearfix"> </div>
        <div className="market-Dashboard-Heading">

          <Row className="market-Heading">
            <Col xs={12} md={3}>
              <h3 className="pl-0">Market Dashboard <HelpTextPopup parm={defaultProps} /></h3>
            </Col>
            {isLoading===false ?
            <Col xs={12} md={6} className="address-list">
              <h5>{storeAddress.storeName ? storeAddress.storeName : isLoading===false ? 'N/A':''}</h5>
              <p>{storeAddress.storeAddress1 ? storeAddress.storeAddress1 + ',' : isLoading===false ? 'N/A':''} {storeAddress.storeAddress2 ? storeAddress.storeAddress2 + ','  : ''} {storeAddress.storeCity ? storeAddress.storeCity  : isLoading===false ? 'N/A':''}{storeAddress.storeState ?  ', ' + storeAddress.storeState + '' : isLoading===false ? '':''} {storeAddress.storeZipCode ? storeAddress.storeZipCode + '' : isLoading===false ? 'N/A':''}</p>
              <label>Coverage: </label><span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading===false ? 'N/A':''}</span>
            </Col>:''}

            <Col md={3} className="padding-0">
              <Row>
                <Col xs={12} md={7} className="show-price">
                  <label className="show">Show Price Trend for</label>
                </Col>
                <Col xs={12} md={5} className="show-price padding-left-0">
                  <AvForm>
                    <AvField value={this.state.priceTrends} type="select" onChange={this.showPriceTrends.bind(this)} name="showPrice">
                      <option value="1"> 5 days </option>
                      <option value="2"> 30 days </option>
                      <option value="3"> 90 days </option>
                     
                    </AvField>
                  </AvForm>
                  
                </Col>
              </Row>
            </Col>

          </Row>

        </div> */}

        {/* <MarketDashboardHeader  parentMethod={this.handler}  />  */}

        <div className="mrket-Dashboard-pdf">
          {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
          <Row className="custom-margin">
            <div className="market-Card-pdf">
              <div className="header"> 
                  <img src={Logo} />
                  <div className="float-right-name"> 
                    <h5>{storeAddress.storeName && storeAddress.storeName !== null && storeAddress.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? storeAddress.storeName.substring(0,CONFIG.storeNameMaxLimit-3) + '...' : storeAddress.storeName : isLoading === false ? 'Your Store' : ''}</h5>
                  </div> 
                  <div className="float-right"><p className="m-0 address-font">
                    {/* {storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ',' : isLoading === false ? '' : ''} {storeAddress.storeCity ? storeAddress.storeCity + ', ' : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ' : isLoading === false ? '' : ''} {storeAddress.storeState ? storeAddress.storeState + '' : isLoading === false ? 'N/A' : ''} {storeAddress.storeZipCode && storeAddress.storeZipCode !== '' && storeAddress.storeZipCode !== null ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''} | <span className="text-right"><label>Coverage: </label> <span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading===false ? 'N/A':''}</span></span> */}
                    {storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ',' : isLoading === false ? '' : ''} {storeAddress.storeCity ? storeAddress.storeCity + ', ' : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ' : isLoading === false ? '' : ''} {storeAddress.storeState ? storeAddress.storeState + '' : isLoading === false ? '' : ''} {storeAddress.storeZipCode && storeAddress.storeZipCode !== '' && storeAddress.storeZipCode !== null ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''} | <span className="text-right"><label>Coverage: </label> <span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading===false ? 'N/A':''}</span></span>
                 </p> </div>
                </div>
              <div className="note-txt"><i><b>*N/A:</b> Trend currently unavailable due to insufficient data</i></div>
              <div className="footer">
                <a href={CONFIG.Website_URL_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:'+CONFIG.Mail_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
              </div>
              <Row className="custom-fix-row">
                {
                  trproductRows ? trproductRows.map((rows, i) => (
                    <div class="page">
                      {i === 0 ?
                        <div className="help-text-pdf text-left" style={{marginLeft: '10px'}}>
                          <h3 className="market-Printview" style={{marginLeft: '0px'}}>Market Dashboard</h3>
                          <p>By default, this dashboard shows 12 of the most common unit types. Each 'box' gives you a quick snapshot of the rates for this Market. Below is a breakdown of what's in each snapshot:</p>
                          <h6>Offered by x stores</h6>
                          <p>This is the number of stores advertising this unit type in this Market</p>
                          <h6>{CONFIG.RegionId === 3 ? "Average Weekly Market Price" : "Average Market Price"}</h6>
                          <p>This is the market average rate for this unit type. The average rates shown here are calculated using the Advertised Online rates</p>
                          <h6>Trend</h6>
                          <p>The upward/downward arrow shows the direction of the trend for this unit type's rates over the period selected (5 days, 30 days, 90 days)</p>
                          <h6>{CONFIG.RegionId === 3 ? "Your Weekly Price" : "Your Price" }</h6>
                          <p>If you have set up your store as your Market, your store's rate(s) will appear here for the unit type. If we have not found a rate for your store, it will be shown as 'N/A'</p>
                          <p><i>*Your store prices are included in the calculations.</i></p>
                        </div>
                      : ''}
                      <div className="dashboard-grids">
                      {rows.map((card, index) => (
                        <div className="col-lg-3 col-md-4" 
                        // id={index === 0 ? 'dis-none' : ''}
                        >
                          {/* {preIndex = preIndex+1} */}

                          {/* {preIndex = preIndex+1} -
                  {i+index} */}
                          {/* {preIndex === 0 ? 0 : preIndex + 1} */}
                          {/* {i+index===0? 0 : preIndex + 1} */}
                          <Card
                            key={card.searchid}
                            index={i + index === 0 ? 0 : preIndex = preIndex + 1}
                            id={card.searchid ? card.searchid : 'N/A'}
                            storeid={storeid}
                            name={card.name ? card.name : 'N/A'}
                            storeCount={card.storeCount ? card.storeCount : 'No'}
                            currency={storeInformation}
                            countryCode={countryCode}
                            avgPrice={card.avgPrice ? card.avgPrice : 'N/A'}
                            priceTrend={card.priceTrend}
                            myStorePrice={card.myStorePrice ? card.myStorePrice : 'N/A'}
                            moveCard={this.moveCard}
                            parentMethod={this.handler}
                            StoreName={storedata.storeName}
                            StoreAddress={storedata.storeAddress1}
                            StoreCity={storedata.storeCity}
                            StoreState={storedata.storeState}
                            StoreZipcode={storedata.storeZipCode}
                            StoreRadius={storedata.storeZoneCoverage}
                            noOfStores={storedata.noOfStores}
                            StoreSearchId={card.searchid}
                            StoreId={this.props.storeid}
                            priceTrends1={priceTrendsAvg ? priceTrendsAvg : '2'}
                          />
                        </div>


                      ))}
                      </div>
                      <div class="printclearfix"></div>
                    </div>
                  ))
                    : ''}
              </Row>
            </div>

          </Row>
        </div>

      </div>

    );
  }
}
export default DragDropContext(HTML5Backend)(MarketDashboardPdf);
