import React, { Component } from "react";
import "./App.css";
import RouteComp from "./Router/Router";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./Modal/IdleModal";
import { CONFIG } from "./utils/config";
import PropTypes from "prop-types";
import Fav_Icon from './Modal/favicon_old.ico';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * CONFIG.TimeOut_Sec * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
// this.setState({ isTimedOut: false });
    this.setState(this.state.showModal === true ? { isTimedOut: true } : {isTimedOut: false });
  }

  _onActive(e) {
    //this.setState({ isTimedOut: false });
    this.setState(this.state.showModal === true ? { isTimedOut: true } : {isTimedOut: false });
  }

  _onIdle(e) {
    // if (
    //   !localStorage.getItem("accessKey") ||
    //   document.location.origin + "/" === document.location.href
    // ) {
    // } else {
    //   if(localStorage.getItem("accessKey")){
    //   this.idleTimer.reset();
    //   this.setState({ isTimedOut: true, showModal: true });
    // }
    // }
    if (localStorage.getItem('accessKey') && localStorage.getItem('isLayoutFrom') == 'ADMIN_LAYOUT'){
      this.idleTimer.reset();
      this.setState({ isTimedOut: true, showModal: true });
    }
  }

  handleClose() {
    document.getElementById("favicon").href = Fav_Icon;
    this.setState({ showModal: false });
  }

  handleLogout() {
    localStorage.clear();
    this.setState({ showModal: false });
    window.top.location.href ="/page_expired";
   // window.top.location.href = CONFIG.STK_SIGNURL;
  }
  render() {
    return (
      <div id="app">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <RouteComp />
        {this.state.isTimedOut ? (
          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            isFrom={"2"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

App.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired,
};

export default App;
