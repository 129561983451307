import { DataCell } from "fixed-data-table-2";
import React from "react";
import { CONFIG } from "../../utils/config";
import ReactTooltip from "react-tooltip";

class CollapseCell extends React.PureComponent {
  render() {
    const {
      data,
      rowIndex,
      columnKey,
      collapsedRows,
      callback,
      ...props
    } = this.props;
    return (
      <DataCell {...props}>
        <a onClick={() => callback(rowIndex)}>
          {collapsedRows.has(rowIndex) ? "\u25BC" : "\u25BA"}
        </a>
      </DataCell>
    );
  }
}

class TextCell extends React.PureComponent {
  render() {
    const { data, rowIndex, columnKey, ...props } = this.props;
    return (
      <DataCell {...props}>{data.getObjectAt(rowIndex)[columnKey]}</DataCell>
    );
  }
}

class LinkCell extends React.PureComponent {
  priceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }
  render() {
    const { data, rowIndex, columnKey, ...props } = this.props;
    let price =
      data[rowIndex][columnKey] !== "" && data[rowIndex][columnKey] !== null
        ? parseFloat(data[rowIndex][columnKey]).toFixed(2) === "0.00"
          ? "free"
          : this.props.currency + this.priceConversion(data[rowIndex][columnKey])
        : "";
    return <DataCell {...props} className={data[rowIndex].isMyStore ? "disabled_color" : ''}>{price}</DataCell>;
  }
}

class CalEffPrice extends React.PureComponent {
  //price conversion
  priceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }
  render() {
    const { data, rowIndex, drpDownSelValue, ...props } = this.props;
    let m1 =
      parseFloat(data[rowIndex].m1).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m1)
        : 0;
    let m2 =
      parseFloat(data[rowIndex].m2).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m2)
        : 0;
    let m3 =
      parseFloat(data[rowIndex].m3).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m3)
        : 0;
    let m4 =
      parseFloat(data[rowIndex].m4).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m4)
        : 0;
    let m5 =
      parseFloat(data[rowIndex].m5).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m5)
        : 0;
    let m6 =
      parseFloat(data[rowIndex].m6).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m6)
        : 0;
    let m7 =
      parseFloat(data[rowIndex].m7).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m7)
        : 0;
    let m8 =
      parseFloat(data[rowIndex].m8).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m8)
        : 0;
    let m9 =
      parseFloat(data[rowIndex].m9).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m9)
        : 0;
    let m10 =
      parseFloat(data[rowIndex].m10).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m10)
        : 0;
    let m11 =
      parseFloat(data[rowIndex].m11).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m11)
        : 0;
    let m12 =
      parseFloat(data[rowIndex].m12).toFixed(2) !== "0.00"
        ? parseFloat(data[rowIndex].m12)
        : 0;
    let avgM3 = (m1 + m2 + m3) / 3;
    let avgM6 = (m1 + m2 + m3 + m4 + m5 + m6) / 6;
    let avgM9 = (m1 + m2 + m3 + m4 + m5 + m6 + m7 + m8 + m9) / 9;
    let avgM12 =
      (m1 + m2 + m3 + m4 + m5 + m6 + m7 + m8 + m9 + m10 + m11 + m12) / 12;
    let priceCal =
      drpDownSelValue === 3
        ? avgM3
        : drpDownSelValue === 6
        ? avgM6
        : drpDownSelValue === 9
        ? avgM9
        : avgM12;
    let averagePrice =
      parseFloat(priceCal).toFixed(2) === "0.00"
        ? priceCal
        : this.priceConversion(priceCal);
    return (
      <DataCell {...props} className={data[rowIndex].isMyStore ? "disabled_color" : ''}>
        {averagePrice !== "" && averagePrice !== null
          ? parseFloat(averagePrice).toFixed(2) === "0.00"
            ? "free"
            : this.props.currency + averagePrice
          : ""}
      </DataCell>
    );
  }
}

class CalAdvPrice extends React.PureComponent {
  //price conversion
  advPriceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }
  render() {
    const { data, rowIndex, ...props } = this.props;
    let advPrice = data[rowIndex].advertisedPrice;
      let advertisedPrice = advPrice !== "" && advPrice !== null
            ? parseFloat(advPrice).toFixed(2) === "0.00"
              ? "0.00"
              : this.props.currency + this.advPriceConversion(advPrice)
            : "";
    return (
      <DataCell {...props} className={data[rowIndex].isMyStore ? "disabled_color" : ''}>
       {advertisedPrice}
      </DataCell>
    );
  }
}

class PendingCell extends React.PureComponent {
  render() {
    const { data, rowIndex, columnKey, dataVersion, ...props } = this.props;
    const rowObject = data.getObjectAt(rowIndex);
    return (
      <DataCell {...props}>
        {rowObject ? rowObject[columnKey] : "pending"}
      </DataCell>
    );
  }
}
const PagedCell = ({ data, ...props }) => {
  const dataVersion = data.getDataVersion();
  return (
    <PendingCell data={data} dataVersion={dataVersion} {...props}></PendingCell>
  );
};

class RemovableHeaderCell extends React.PureComponent {
  render() {
    const {
      data,
      rowIndex,
      columnKey,
      callback,
      children,
      ...props
    } = this.props;
    return <DataCell {...props}>{children}</DataCell>;
  }
}

class TooltipCell extends React.PureComponent {
  render() {
    const { data, rowIndex, columnKey, ...props } = this.props;
    const value =
      data[rowIndex][columnKey] !== null && data[rowIndex][columnKey] !== ""
        ? data[rowIndex][columnKey].replace(/~/g, "<hr/>")
        : "No Special";
    return (
      <DataCell
        {...props}
        onMouseEnter={() => {
          ReactTooltip.show();
        }}
        onMouseLeave={() => {
          ReactTooltip.hide();
        }}
      >
        <div data-tip={value}>{value}</div>
      </DataCell>
    );
  }
}

export {
  CollapseCell,
  LinkCell,
  PagedCell,
  RemovableHeaderCell,
  TextCell,
  TooltipCell,
  CalEffPrice,
  CalAdvPrice
};
