import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import axios from "axios";
import { CONFIG } from "../../utils/config";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import moment from "moment";
import { faCheck, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alarm from "./../../Assets/Images/unit-type-images/Alarm.png";
import ClimateControlled from "./../../Assets/Images/unit-type-images/ClimateControlled.png";
import StorageUnit from "./../../Assets/Images/unit-type-images/StorageUnit.png";
import apartment from "./../../Assets/Images/unit-type-images/apartment.png";
import artstudio from "./../../Assets/Images/unit-type-images/artstudio.png";
import box from "./../../Assets/Images/unit-type-images/box.png";
import celltower from "./../../Assets/Images/unit-type-images/celltower.png";
import container from "./../../Assets/Images/unit-type-images/container.png";
import filestorage from "./../../Assets/Images/unit-type-images/filestorage.png";
import office from "./../../Assets/Images/unit-type-images/office.png";
import mailbox from "./../../Assets/Images/unit-type-images/mailbox.png";
import parking from "./../../Assets/Images/unit-type-images/parking.png";
import unit from "./../../Assets/Images/unit-type-images/unit.png";
import warehouse from "./../../Assets/Images/unit-type-images/warehouse.png";
import winestorage from "./../../Assets/Images/unit-type-images/winestorage.png";
import workshop from "./../../Assets/Images/unit-type-images/workshop.png";
import DoorType from "./../../Assets/Images/unit-type-images/DoorType.png";
import DriveUp from "./../../Assets/Images/unit-type-images/DriveUp.png";
import Elevator from "./../../Assets/Images/unit-type-images/Elevator.png";
import HumidityControlled from "./../../Assets/Images/unit-type-images/HumidityControlled.png";
import OutdoorAccess from "./../../Assets/Images/unit-type-images/OutdoorAccess.png";
import power from "./../../Assets/Images/unit-type-images/power.png";
import covered from "./../../Assets/Images/unit-type-images/Covered.png";
import vehicle from "./../../Assets/Images/unit-type-images/Vehicle.png";
import locker from "./../../Assets/Images/unit-type-images/locker.png";
import { MarketProfile } from "../MarketProfile";
import { Link } from "react-router-dom";
import { faMapPin, faStar } from "@fortawesome/free-solid-svg-icons";
import "./DetailedStoresView.css";
import exportFromJSON from "export-from-json";
import { Row, Col } from "reactstrap";
import Workbook from "react-excel-workbook";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
//import exportFromJSON from 'export-from-json'
//const exportType = 'xls';
const exportType = "xls";
const actionsStyles = (theme) => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class DetailedStoresView extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const DetailedStoresViewWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(DetailedStoresView);

let headRows = [];
if (CONFIG.RegionId === 3) {
  headRows = [
    {
      id: "storeName",
      numeric: false,
      disablePadding: false,
      label: "Store Name / Address",
    },
    {
      id: "proximity",
      numeric: true,
      disablePadding: false,
      label: "Proximity",
    },
    { id: "size", numeric: true, disablePadding: false, label: "Unit Size" },
    { id: "floor", numeric: false, disablePadding: false, label: "Floor" },
    { id: "features", numeric: true, disablePadding: false, label: "Features" },
    {
      id: "onlinePrice",
      numeric: true,
      disablePadding: false,
      label: "Advertised Online Rate",
    },
    //  { id: 'onlinePriceVat', numeric: true, disablePadding: false, label: 'VAT' },
    {
      id: "dtOnlinePriceChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
    {
      id: "regPrice",
      numeric: true,
      disablePadding: false,
      label: "Advertised Walk-in Rate",
    },
    // { id: 'regularPriceVat', numeric: true, disablePadding: false, label: 'VAT' },
    {
      id: "dtPriceChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
    {
      id: "promo",
      numeric: false,
      disablePadding: false,
      label: "Advertised Promotion",
    },
    {
      id: "dtPromoChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
  ];
} else {
  headRows = [
    {
      id: "storeName",
      numeric: false,
      disablePadding: false,
      label: "Store Name / Address",
    },
    {
      id: "proximity",
      numeric: true,
      disablePadding: false,
      label: "Proximity",
    },
    { id: "size", numeric: true, disablePadding: false, label: "Unit Size" },
    { id: "floor", numeric: false, disablePadding: false, label: "Floor" },
    { id: "features", numeric: true, disablePadding: false, label: "Features" },
    {
      id: "onlinePrice",
      numeric: true,
      disablePadding: false,
      label: "Advertised Online Rate",
    },
    {
      id: "dtOnlinePriceChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
    {
      id: "regPrice",
      numeric: true,
      disablePadding: false,
      label: "Advertised Walk-in Rate",
    },
    {
      id: "dtPriceChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
    {
      id: "promo",
      numeric: false,
      disablePadding: false,
      label: "Advertised Promotion",
    },
    {
      id: "dtPromoChanged",
      numeric: true,
      disablePadding: false,
      label: "Last Changed",
    },
  ];
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    order1,
    orderBy1,
    order2,
    orderBy2,
    numSelected,
    rowCount,
    onRequestSort,
    onProximitysort,
    onStoreSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const creatProximitysort = (property) => (event) => {
    onProximitysort(event, property);
  };
  const creatstoreSort = (property) => (event) => {
    onStoreSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell> */}
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={
              orderBy === row.id
                ? order
                : orderBy1 === row.id
                ? order1
                : orderBy2 === row.id
                ? order2
                : false
            }
          >
            <TableSortLabel
              active={
                orderBy === row.id || orderBy1 === row.id || orderBy2 === row.id
              }
              direction={order || order1 || order2}
              disabled={row.id === "features" ? true : false}
              onClick={
                row.id === "storeName"
                  ? creatstoreSort(row.id)
                  : row.id === "proximity"
                  ? creatProximitysort(row.id)
                  : createSortHandler(row.id)
              }
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onProximitysort: PropTypes.func.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  order1: PropTypes.string.isRequired,
  orderBy1: PropTypes.string.isRequired,
  order2: PropTypes.string.isRequired,
  orderBy2: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
class CustomDetailedStoresView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageCount: 0,
      rowsPerPage: 10,
      detailStoreData: [],
      detailViewData: [],
      detailStoreDatafilter: [],
      order: "asc",
      orderBy: "name",
      order1: "asc",
      order2: "desc",
      orderBy1: "proximity",
      // orderBy2: 'asc',
      orderBy2: "storeName",
      isLoading: false,
      isPopUp: false,
      currentDate: new Date(),
      storeAddress: [],
      downloadInput: [],
      fileName: "",
      snackOpen: false,
      snackMSg: "",
      snackClr: "",
    };
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleProximitysort = this.handleProximitysort.bind(this);
    this.handleStoreSort = this.handleStoreSort.bind(this);
    // this.getParams = this.getParams.bind(this);
    this.child = React.createRef();
  }
  getParams = (name, sid) => {
    this.state.isPopUp = true;
    if (name && sid) {
      this.child.current.toggleModal(name, sid);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.isPopUp === false) {
      if (nextProps.searchId != this.props.searchId) {
        this.setState(
          {
            isLoading: true,
            searchId: nextProps.searchId,
            storeId: nextProps.storeId,
          },
          () => {
            this.componentDidMount();
          }
        );
      }
    } else {
      this.setState({
        isPopUp: false,
      });
    }
  }
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ isLoading: true });
    const detaildata = {
      UserStoreID: this.props.storeId,
      SearchID: this.props.searchId,
      RegionID: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "unit/unitlevelgridview", detaildata)
      .then((res) => {
        let detailStoreData = res.data.data;
        var sortData = detailStoreData.sort((a, b) => b.storeId - a.storeId);

        let uniquestore = [...new Set(sortData.map((item) => item.storeId))];
        var FData = [];
        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortData.filter((a) => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              rowcount: 1,
              competitorName: tempData[s].competitorName,
              address: tempData[s].address,
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: tempData[s].proximity,
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: tempData[s].storeName,
              vehicle: tempData[s].vehicle,
              regularPriceVat: tempData[s].regularPriceVat,
              onlinePriceVat: tempData[s].onlinePriceVat,
              isMonthly: tempData[s].isMonthly,
            };

            FData.push(inlineData);
          }
        }
        this.setState({
          detailStoreData: FData,
          detailViewData: sortData,
          isLoading: false,
          order: isDesc ? "asc" : "desc",
          orderBy: property,
          order1: "",
          orderBy1: "",
          order2: "",
          orderBy2: "",
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  handleProximitysort(event, property) {
    this.setState({ isLoading: true });
    const { order1, orderBy1 } = this.state;
    const isDesc = orderBy1 === property && order1 === "desc";
    const detaildata = {
      UserStoreID: this.props.storeId,
      SearchID: this.props.searchId,
      RegionID: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "unit/unitlevelgridview", detaildata)
      .then((res) => {
        //let detailStoreData = [];
        let tempdetailStoreData = res.data.data;
        // var sortData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        if (order1 === "desc") {
          var sortByStoreData = tempdetailStoreData.sort(
            (a, b) => a.proximity - b.proximity
          );
        } else {
          var sortByStoreData = tempdetailStoreData.sort(
            (a, b) => b.proximity - a.proximity
          );
        }
        // let term = order;
        //     let option = orderBy;
        // var sortByStoreData = tempdetailStoreData.sort((a, b) => {
        //   return (option == 'asc' ? (a[term] >= b[term] ? -1 : 1) :
        //       (a[term] <= b[term] ? -1 : 1))
        //   });

        let uniquestore = [
          ...new Set(sortByStoreData.map((item) => item.storeId)),
        ];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(
            (a) => a.storeId == uniquestore[t]
          );
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : "",
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : "empty",
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : "",
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat: tempData[s].regularPriceVat,
              onlinePriceVat: tempData[s].onlinePriceVat,
              isMonthly: tempData[s].isMonthly,
            };

            detailStoreDatafilter.push(inlineData);
          }
        }
        this.setState({
          detailStoreDatafilter,
          detailViewData: sortByStoreData,
          detailStoreData: [],
          tempdetailStoreData,
          isLoading: false,
          order1: isDesc ? "asc" : "desc",
          orderBy1: property,
          order: "",
          orderBy: "",
          order2: "",
          orderBy2: "",
          detailStoreDatafilterLength: uniquestore.length,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  handleStoreSort(event, property) {
    this.setState({ isLoading: true });
    const { order2, orderBy2 } = this.state;
    const isDesc = orderBy2 === property && order2 === "desc";
    const detaildata = {
      UserStoreID: this.props.storeId,
      SearchID: this.props.searchId,
      RegionID: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "unit/unitlevelgridview", detaildata)
      .then((res) => {
        let tempdetailStoreData = res.data.data;
        if (order2 === "desc") {
          var sortByStoreData = tempdetailStoreData.sort((a, b) =>
            a.storeName.localeCompare(b.storeName)
          );
        } else {
          var sortByStoreData = tempdetailStoreData.sort((a, b) =>
            b.storeName.localeCompare(a.storeName)
          );
        }
        let uniquestore = [
          ...new Set(sortByStoreData.map((item) => item.storeId)),
        ];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(
            (a) => a.storeId == uniquestore[t]
          );
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : "",
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : "empty",
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : "",
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat: tempData[s].regularPriceVat,
              onlinePriceVat: tempData[s].onlinePriceVat,
              isMonthly: tempData[s].isMonthly,
            };

            detailStoreDatafilter.push(inlineData);
          }
        }
        this.setState(
          {
            detailStoreDatafilter,
            detailViewData: sortByStoreData,
            detailStoreData: [],
            tempdetailStoreData,
            isLoading: false,
            order2: isDesc ? "asc" : "desc",
            orderBy2: property,
            order: "",
            orderBy: "",
            order1: "",
            orderBy1: "",
            detailStoreDatafilterLength: uniquestore.length,
          },
          () => {
            this.downloadExcel();
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  componentDidMount() {
    // this.setState({ isLoading: true });
    // const detaildata = {
    //   "UserStoreID":this.props.storeId,
    //   "SearchID": this.props.searchId
    // }

    // axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
    //   .then(res => {
    //     let detailStoreData = res.data.data.unitLevelDatas;
    //     var sortData = detailStoreData.sort((a, b) => (b.storeId - a.storeId));

    // let uniquestore = [...new Set(sortData.map(item => item.storeId))];
    // var FData = [];
    // for (var t = 0; t < uniquestore.length; t++) {
    //   var tempData = sortData.filter(a => a.storeId == uniquestore[t]);
    //   for (var s = 0; s < tempData.length; s++) {
    //     var inlineData = {
    //       rowcount: 1,
    //       competitorName: tempData[s].competitorName,
    //       address: tempData[s].address,
    //       alarm: tempData[s].alarm,
    //       climateControlled: tempData[s].climateControlled,
    //       covered: tempData[s].covered,
    //       doorType: tempData[s].doorType,
    //       driveUp: tempData[s].driveUp,
    //       dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
    //       dtPriceChanged: tempData[s].dtPriceChanged,
    //       dtPromoChanged: tempData[s].dtPromoChanged,
    //       elevator: tempData[s].elevator,
    //       floor: tempData[s].floor,
    //       humidityControlled: tempData[s].humidityControlled,
    //       isUserStore: tempData[s].isUserStore,
    //       lastCheckedDate: tempData[s].lastCheckedDate,
    //       latitude: tempData[s].latitude,
    //       longitude: tempData[s].longitude,
    //       onlinePrice: tempData[s].onlinePrice,
    //       onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
    //       outdoorAccess: tempData[s].outdoorAccess,
    //       power: tempData[s].power,
    //       promo: tempData[s].promo,
    //       promoLastChanged: tempData[s].promoLastChanged,
    //       proximity: tempData[s].proximity,
    //       rank: tempData[s].rank,
    //       regPrice: tempData[s].regPrice,
    //       regularPrice: tempData[s].regularPrice,
    //       regularPriceLastChanged: tempData[s].regularPriceLastChanged,
    //       size: tempData[s].size,
    //       spaceId: tempData[s].spaceId,
    //       spaceType: tempData[s].spaceType,
    //       spacetype: tempData[s].spacetype,
    //       state: tempData[s].state,
    //       storeId: tempData[s].storeId,
    //       storeName: tempData[s].storeName,
    //       vehicle: tempData[s].vehicle
    //     }

    //     FData.push(inlineData);
    //   }
    // }
    //     this.setState({ detailStoreData: FData, isLoading: false });
    //   })
    //   .catch((err) => {
    //     this.setState({ isLoading: false });
    //     if (err.response.status === 401) {
    //       localStorage.removeItem('accessKey');
    //       const { from } = this.props.location.state || { from: { pathname: "/" } };
    //       this.props.history.push(from);
    //     }
    //     else {
    //       alert(err.response.data);
    //     }
    //   });
    this.setState({ isLoading: true });
    // const { order2, orderBy2 } = this.state;
    // const property = 'storeName';
    // const isDesc = orderBy2 === property && order2 === 'desc';
    // localStorage.setItem('StId', this.props.storeId);
    // const detaildata = {
    //   "UserStoreID": this.props.storeId,
    //   "SearchID": this.props.searchId
    // }

    // axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
    //   .then(res => {
    //     let tempdetailStoreData = res.data.data.unitLevelDatas;
    //     if (order2 === 'desc') {
    //       var sortByStoreData = tempdetailStoreData.sort((a, b) => a.storeName.localeCompare(b.storeName));
    //     }
    //     else {
    //       var sortByStoreData = tempdetailStoreData.sort((a, b) => b.storeName.localeCompare(a.storeName));
    //     }
    //     let uniquestore = [...new Set(sortByStoreData.map(item => item.storeId))];
    //     var detailStoreDatafilter = [];
    //     var detailStoreDatafilterLength = 0;

    //     for (var t = 0; t < uniquestore.length; t++) {
    //       var tempData = sortByStoreData.filter(a => a.storeId == uniquestore[t]);
    //       for (var s = 0; s < tempData.length; s++) {
    //         var inlineData = {
    //           competitorName: tempData[s].competitorName,
    //           address: s === 0 ? tempData[s].address : '',
    //           alarm: tempData[s].alarm,
    //           climateControlled: tempData[s].climateControlled,
    //           covered: tempData[s].covered,
    //           doorType: tempData[s].doorType,
    //           driveUp: tempData[s].driveUp,
    //           dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
    //           dtPriceChanged: tempData[s].dtPriceChanged,
    //           dtPromoChanged: tempData[s].dtPromoChanged,
    //           elevator: tempData[s].elevator,
    //           floor: tempData[s].floor,
    //           humidityControlled: tempData[s].humidityControlled,
    //           isUserStore: tempData[s].isUserStore,
    //           lastCheckedDate: tempData[s].lastCheckedDate,
    //           latitude: tempData[s].latitude,
    //           longitude: tempData[s].longitude,
    //           onlinePrice: tempData[s].onlinePrice,
    //           onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
    //           outdoorAccess: tempData[s].outdoorAccess,
    //           power: tempData[s].power,
    //           promo: tempData[s].promo,
    //           promoLastChanged: tempData[s].promoLastChanged,
    //           proximity: s === 0 ? tempData[s].proximity : 'empty',
    //           rank: tempData[s].rank,
    //           regPrice: tempData[s].regPrice,
    //           regularPrice: tempData[s].regularPrice,
    //           regularPriceLastChanged: tempData[s].regularPriceLastChanged,
    //           size: tempData[s].size,
    //           spaceId: tempData[s].spaceId,
    //           spaceType: tempData[s].spaceType,
    //           spacetype: tempData[s].spacetype,
    //           state: tempData[s].state,
    //           storeId: tempData[s].storeId,
    //           storeName: s === 0 ? tempData[s].storeName : '',
    //           vehicle: tempData[s].vehicle,
    //           rowcount: s === 0 ? tempData.length : 0,

    //         }

    //         detailStoreDatafilter.push(inlineData);
    //       }
    //     }
    //     this.setState({
    //       detailStoreDatafilter, detailStoreData: [], tempdetailStoreData, isLoading: false,
    //       order2: isDesc ? 'asc' : 'desc', orderBy2: property,
    //       order: '', orderBy: '', order1: '', orderBy1: '',
    //       detailStoreDatafilterLength: uniquestore.length
    //     })

    //   })
    //   .catch((err) => {
    //     this.setState({ isLoading: false });
    //     if (err.response.status === 401) {
    //       localStorage.removeItem('accessKey');
    //       const { from } = this.props.location.state || { from: { pathname: "/" } };
    //       this.props.history.push(from);
    //     }
    //     else {
    //       alert(err.response.data);
    //     }
    //   });
    let token = localStorage.getItem("accessKey");

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    const data1 = {
      UserStoreID: this.props.storeId,
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(CONFIG.API_URL + "dashboard/dashboardlocation/", data1)
      .then((res) => {
        let storeAddress = res.data.data.storeDashboard;
        this.setState({ storeAddress });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });

    const { order1, orderBy1 } = this.state;
    const property = "proximity";
    const isDesc = orderBy1 === property && order1 === "asc";
    const detaildata = {
      UserStoreID: this.props.storeId,
      SearchID: this.props.searchId,
      RegionID: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "unit/unitlevelgridview", detaildata)
      .then((res) => {
        let tempdetailStoreData = res.data.data;
        // var sortData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        if (order1 === "asc") {
          var sortByStoreData = tempdetailStoreData.sort(
            (a, b) => a.proximity - b.proximity
          );
        } else {
          var sortByStoreData = tempdetailStoreData.sort(
            (a, b) => b.proximity - a.proximity
          );
        }
        // let term = order;
        //     let option = orderBy;
        // var sortByStoreData = tempdetailStoreData.sort((a, b) => {
        //   return (option == 'asc' ? (a[term] >= b[term] ? -1 : 1) :
        //       (a[term] <= b[term] ? -1 : 1))
        //   });

        let uniquestore = [
          ...new Set(sortByStoreData.map((item) => item.storeId)),
        ];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(
            (a) => a.storeId == uniquestore[t]
          );
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : "",
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : "empty",
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : "",
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat: tempData[s].regularPriceVat,
              onlinePriceVat: tempData[s].onlinePriceVat,
              isMonthly: tempData[s].isMonthly,
            };

            detailStoreDatafilter.push(inlineData);
          }
        }
        this.setState(
          {
            detailStoreDatafilter,
            detailViewData: sortByStoreData,
            detailStoreData: [],
            tempdetailStoreData,
            isLoading: false,
            order1: isDesc ? "asc" : "desc",
            orderBy1: property,
            order: "",
            orderBy: "",
            order2: "",
            orderBy2: "",
            detailStoreDatafilterLength: uniquestore.length,
          },
          () => {
            this.downloadExcel();
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
          if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  downloadExcel() {
    let data = "";
    let date = this.state.currentDate;
    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();
    let size,
      fileName,
      covered,
      vehicle,
      alarm,
      climate,
      doorType,
      driveUp,
      elevator,
      storename,
      storeAddress,
      humidity,
      outdoorAccess,
      proximity,
      power,
      adPromoDate,
      adOnlineDate,
      adWalkinDate;
    let downloadInput = [];
    let downloadInputMerge = [];
    let inputArray = [];
    // let currency  = CONFIG.RegionId === 3 ?'£': CONFIG.Currency_Symbol;
    let currency = this.props.curr;
    let units =
      CONFIG.RegionId == 2 || CONFIG.RegionId == 4 ? " " + "km" : " " + "mi";
    inputArray =
      this.state.detailViewData && this.state.detailViewData.length > 0
        ? this.state.detailViewData
        : this.state.detailViewData;
    inputArray.map((data) => {
      storename = data.storeName ? data.storeName : "N/A";
      storeAddress = data.address ? data.address : "N/A";
      size = data.size ? data.size.toString() : "N/A";
      alarm = data.alarm === 1 ? "Alarm, " : "";
      climate = data.climateControlled === 1 ? "ClimateControlled, " : "";
      covered = data.covered === 1 ? "Covered, " : "";
      doorType =
        data.doorType > 0
          ? data.doorType === 1
            ? "Roll-Up Door, "
            : data.doorType === 2
            ? "Swing Door, "
            : data.doorType === 3
            ? "Double Door, "
            : data.doorType === 4
            ? "Chain-Link Door, "
            : data.doorType === 5
            ? "Other Door, "
            : ""
          : "";
      driveUp = data.driveUp === 1 ? "DriveUp, " : "";
      elevator = data.elevator === 1 ? "Elevator, " : "";
      vehicle = data.vehicle === 1 ? "Vehicle, " : "";
      humidity = data.humidityControlled === 1 ? "HumidityControlled, " : "";
      outdoorAccess = data.outdoorAccess === 1 ? "OutdoorAccess, " : "";
      power = data.power === 1 ? "Power, " : "";
      adOnlineDate = data.dtOnlinePriceChanged
        ? moment(data.dtOnlinePriceChanged).format("DD-MMM-YYYY")
        : "N/A";
      adWalkinDate = data.dtPriceChanged
        ? moment(data.dtPriceChanged).format("DD-MMM-YYYY")
        : "N/A";
      adPromoDate = data.dtPromoChanged
        ? moment(data.dtPromoChanged).format("DD-MMM-YYYY")
        : "N/A";
      proximity =
        data.proximity !== "empty" ? data.proximity.toFixed(2) + units : "";
      let str =
        alarm +
        climate +
        vehicle +
        doorType +
        driveUp +
        elevator +
        humidity +
        outdoorAccess +
        power +
        covered;
      var features = str.replace(/,\s*$/, "");
      downloadInputMerge.push({
        STORENAME: storename,
        ADDRESS: storeAddress,
        PROXIMITY: proximity,
        "UNIT SIZE": size,
        FLOOR: data.floor ? data.floor : "N/A",
        FEATURES: features, //alarm + climate + doorType + driveUp + elevator + humidity + outdoorAccess + power,
        "ADVERTISED ONLINE RATE": data.onlinePrice && data.onlinePrice != 0 && data.onlinePrice != null
        ? data.isMonthly === 1
          ? currency + data.onlinePrice + "*"
          : currency + data.onlinePrice
        : "N/A",
        "ADVERTISED ONLINE WEEKLY RATE":
          data.onlinePrice && data.onlinePrice != 0 && data.onlinePrice != null
            ? data.isMonthly === 1
              ? currency + data.onlinePrice + "*"
              : currency + data.onlinePrice
            : "N/A",
        "ONLINE RATE LAST CHANGED": adOnlineDate,
        "ADVERTISED WALK-IN RATE":
          data.regPrice && data.regPrice != 0 && data.regPrice != null
            ? data.isMonthly === 1
              ? currency + data.regPrice + "*"
              : currency + data.regPrice
            : "N/A",
            "ADVERTISED WALK-IN WEEKLY RATE":
            data.regPrice && data.regPrice != 0 && data.regPrice != null
              ? data.isMonthly === 1
                ? currency + data.regPrice + "*"
                : currency + data.regPrice
              : "N/A",
        "WALK-IN RATE LAST CHANGED": adWalkinDate,
        "ADVERTISED PROMOTION": data.promo ? data.promo : "N/A",
        "PROMOTION LAST CHANGED": adPromoDate,
      });
    });
    var merge = [
      {
        STORENAME: "* This rate is calculated from an advertised monthly rate",
      },
    ];
    downloadInput =
      CONFIG.RegionId === 3
        ? downloadInputMerge.concat(merge)
        : downloadInputMerge;
    var stoName =
      this.state.storeAddress.storeName &&
      this.state.storeAddress.storeName !== null &&
      this.state.storeAddress.storeName !== "" &&
      this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    fileName =
      stoName +
      "_" +
      this.props.unitTypesName +
      "_" +
      moment(this.state.currentDate).format("DD-MMM-YYYY");
    //var result = exportFromJSON({ data, fileName, exportType, formateDate: 'yyyy/mm/dd' });
    this.setState({ downloadInput, fileName });
  }
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = "1";
    let usId = this.props.storeId ? this.props.storeId : "0";
    let searchId = this.props.searchId ? this.props.searchId : "0";
    let accessKey = localStorage.getItem("SecretKey");
    let unitType = this.state.order ? this.state.order : "0";
    let priceType = this.state.orderBy ? this.state.orderBy : "0";
    let orderBy = this.state.order1 ? this.state.order1 : "0";
    let order = this.state.order2 ? this.state.order2 : "0";
    let isShowGraph = this.state.orderBy1 ? this.state.orderBy1 : "0";
    let isRemoveGraph = this.state.orderBy2 ? this.state.orderBy2 : "0";

    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "DetailedStoreView" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.storeAddress.storeName &&
      this.state.storeAddress.storeName !== null &&
      this.state.storeAddress.storeName !== "" &&
      this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "DetailedStoreView" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 10;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/" +
        isShowGraph +
        "/" +
        isRemoveGraph +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });               
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);       
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );          
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);             
        this.setState({isLoading: false });        
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  render() {
    document.title = "StorTrack: Self-storage market pricing data";
    const {
      detailStoreData,
      detailViewData,
      isLoading,
      detailStoreDatafilter,
      detailStoreDatafilterLength,
      rowsPerPage,
      page,
      pageCount,
      order,
      orderBy,
      order1,
      orderBy1,
      order2,
      orderBy2,
      snackOpen,
      snackMSg,
    } = this.state;
    //'<a href="#" className="download-xcel">HideCompetitor</a>'
    const defaultProps = {
      helpTitle: "Detailed View of Stores",
      helpContent:
        "<p>This is a spreadsheet style view of the stores advertising this unit currently. It shows the store, the proximity to your address, the unit size, the floor in which the unit is located (Ground or Upper floor) and any features advertised for this unit type by the store. Additionally, the rates (both Advertised Online Rate and Advertised Walk-in Rate), unit-specific promotions and the last time these were changed on the store's website.</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/DOjmEH0-SOI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    return (
      <div>
        <MarketProfile ref={this.child} />
        <Row>
          <Col>
            <h5>
              Detailed view of Stores <HelpTextPopup parm={defaultProps} />
            </h5>
          </Col>
          <Col className="pull-right">
            <div style={{ overflow: "hidden" }}>
              {/* <Button onClick={()=> this.downloadExcel()} className="download-excel">
                <FontAwesomeIcon icon={faFileExcel} />Download Excel
                  </Button> */}
              <Workbook
                filename={this.state.fileName + ".xlsx"}
                element={
                  this.state.detailStoreDatafilter.length > 0 ? (
                    <button
                      className="download-excel download-prices"
                      style={{ border: "1px solid #596e80" }}
                    >
                      <FontAwesomeIcon icon={faFileExcel} />
                      Download Prices
                    </button>
                  ) : (
                    <button
                      disabled="true"
                      className="no-download-excel"
                      style={{ border: "1px solid #F15D47" }}
                    >
                      <FontAwesomeIcon icon={faFileExcel} />
                      Download Prices
                    </button>
                  )
                }
              >
                <Workbook.Sheet data={this.state.downloadInput} name="sheet1">
                  <Workbook.Column label="STORE NAME" value="STORENAME" />
                  <Workbook.Column label="ADDRESS" value="ADDRESS" />
                  <Workbook.Column label="PROXIMITY" value="PROXIMITY" />
                  <Workbook.Column label="UNIT SIZE" value="UNIT SIZE" />
                  <Workbook.Column label="FLOOR" value="FLOOR" />
                  <Workbook.Column label="FEATURES" value="FEATURES" />
                  <Workbook.Column
                    label={
                      CONFIG.RegionId === 3
                        ? "ADVERTISED ONLINE WEEKLY RATE"
                        : "ADVERTISED ONLINE RATE"
                    }
                    value={
                      CONFIG.RegionId === 3
                        ? "ADVERTISED ONLINE WEEKLY RATE"
                        : "ADVERTISED ONLINE RATE"
                    }
                  />
                  <Workbook.Column
                    label="LAST CHANGED"
                    value="ONLINE RATE LAST CHANGED"
                  />
                  <Workbook.Column
                    label={
                      CONFIG.RegionId === 3
                        ? "ADVERTISED WALK-IN WEEKLY RATE"
                        : "ADVERTISED WALK-IN RATE"
                    }
                    value={
                      CONFIG.RegionId === 3
                        ? "ADVERTISED WALK-IN WEEKLY RATE"
                        : "ADVERTISED WALK-IN RATE"
                    }
                  />
                  <Workbook.Column
                    label="LAST CHANGED"
                    value="WALK-IN RATE LAST CHANGED"
                  />
                  <Workbook.Column
                    label="ADVERTISED PROMOTION"
                    value="ADVERTISED PROMOTION"
                  />
                  <Workbook.Column
                    label="LAST CHANGED"
                    value="PROMOTION LAST CHANGED"
                  />
                </Workbook.Sheet>
              </Workbook>
              <Button
                disabled={
                  !isLoading &&
                  (detailStoreData.length > 0 ||
                    detailStoreDatafilter.length > 0)
                    ? false
                    : true
                }
                className={
                  detailStoreData.length > 0 || detailStoreDatafilter.length > 0
                    ? "mt-0 mb-2 mr-2 apply-btn download-pdf "
                    : "mt-0 mb-2 mr-2 apply-btn download-pdf-disabled"
                }
                onClick={this.downLoadPDF.bind(this)}
              >
                <FontAwesomeIcon icon={faFilePdf} />
                PDF Download
              </Button>
            </div>
            {CONFIG.RegionId === 3 ? (
              <p className="sqft-disclaimer mb-1 text-right">
                * This rate is calculated from an advertised monthly rate
              </p>
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* <div className="clear"></div> */}
        {/* <h5>Detailed view of Stores <HelpTextPopup parm={defaultProps} /></h5>
        
        <div className="clear"></div> */}
        {/* {CONFIG.RegionId === 3 ?
        <p className = "sqft-disclaimer mb-1">* This rate is calculated from an advertised monthly rate</p>
        :''} */}
        <div className="clear"></div>
        {/* <span className="setup-stores">You have setup {detailStoreData && detailStoreData.length > 0 ? detailStoreData.length : '0'}.</span> */}
        <div className="detailed-view-table">
          {isLoading ? (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
          <Paper>
            <Table className="table table-bordered custom-table">
              <EnhancedTableHead
                // numSelected={selected.length}
                order={order || order1 || order2}
                orderBy={orderBy || orderBy1 || orderBy2}
                // onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                onProximitysort={this.handleProximitysort}
                onStoreSort={this.handleStoreSort}
                rowCount={detailStoreData.length}
              />
              {/* <TableHead>
                                <TableRow className="table-header">
                                    <TableCell>storeName</TableCell>
                                    
                                </TableRow>
                            </TableHead> */}
              <TableBody>
                {detailStoreData && detailStoreData.length > 0 ? (
                  this.stableSort(
                    detailStoreData,
                    this.getSorting(order, orderBy)
                  ).map((category, index) => (
                    <TableRow
                      key={category.userStoreID}
                      title={
                        "Last Checked: " +
                        moment(category.lastCheckedDate).format("DD-MMM-YYYY")
                      }
                      className="table-body"
                    >
                      <TableCell>
                        <Link
                          onClick={() =>
                            this.getParams(category.storeName, category.storeId)
                          }
                          className="storename"
                        >
                          {category.storeName}
                        </Link>
                        <br />
                        <span className="store-names-view">
                          {category.address}
                        </span>
                      </TableCell>
                      <TableCell>
                        {category.proximity.toFixed(2)}{" "}
                        {CONFIG.RegionId == 2 || CONFIG.RegionId == 4
                          ? " " + "km"
                          : " " + "mi"}
                      </TableCell>
                      <TableCell>
                        {category.size}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "storageunit" ? (
                          <img
                            src={StorageUnit}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "apartment" ? (
                          <img
                            src={apartment}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "artstudio" ? (
                          <img
                            src={artstudio}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "box" ? (
                          <img
                            src={box}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "celltower" ? (
                          <img
                            src={celltower}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "container" ? (
                          <img
                            src={container}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "filestorage" ? (
                          <img
                            src={filestorage}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "office" ? (
                          <img
                            src={office}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "mailbox" ? (
                          <img
                            src={mailbox}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "parking" ? (
                          <img
                            src={parking}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "unit" ? (
                          <img
                            src={unit}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "warehouse" ? (
                          <img
                            src={warehouse}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "winestorage" ? (
                          <img
                            src={winestorage}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "workshop" ? (
                          <img
                            src={workshop}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "locker" ? (
                          <img
                            src={locker}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{category.floor}</TableCell>
                      <TableCell className="feature-images">
                        {category.alarm === 1 ? (
                          <img src={Alarm} title="Alarm" />
                        ) : (
                          ""
                        )}
                        {category.climateControlled === 1 ? (
                          <img src={ClimateControlled} title="Climate" />
                        ) : (
                          ""
                        )}
                        {category.doorType > 0 ? (
                          <img
                            src={DoorType}
                            title={
                              category.doorType === 1
                                ? "Roll-Up Door"
                                : category.doorType === 2
                                ? "Swing Door"
                                : category.doorType === 3
                                ? "Double Door"
                                : category.doorType === 4
                                ? "Chain Link Door"
                                : category.doorType === 5
                                ? "Other Door"
                                : ""
                            }
                          />
                        ) : (
                          ""
                        )}
                        {category.driveUp === 1 ? (
                          <img src={DriveUp} title="Drive Up" />
                        ) : (
                          ""
                        )}
                        {category.elevator === 1 ? (
                          <img src={Elevator} title="Elevator" />
                        ) : (
                          ""
                        )}
                        {category.humidityControlled === 1 ? (
                          <img src={HumidityControlled} title="Humidity" />
                        ) : (
                          ""
                        )}
                        {category.outdoorAccess === 1 ? (
                          <img src={OutdoorAccess} title="Outdoor Access" />
                        ) : (
                          ""
                        )}
                        {category.power === 1 ? (
                          <img src={power} title="Power" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {category.onlinePrice >= 1 && this.props.curr ? (
                          <span>{this.props.curr + category.onlinePrice}</span>
                        ) : (
                          "N/A"
                        )}
                        {category.isMonthly &&
                        category.isMonthly === 1 &&
                        CONFIG.RegionId === 3 ? (
                          <sup>*</sup>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      {/* {CONFIG.RegionId ===3?<TableCell>{category.onlinePriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.onlinePriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                      <TableCell>
                        {moment(category.dtOnlinePriceChanged).format(
                          "DD-MMM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {category.regPrice >= 1 && this.props.curr ? (
                          <span>{this.props.curr + category.regPrice}</span>
                        ) : (
                          "N/A"
                        )}
                        {category.isMonthly &&
                        category.isMonthly === 1 &&
                        CONFIG.RegionId === 3 ? (
                          <sup>*</sup>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      {/* {CONFIG.RegionId ===3?<TableCell>{category.regularPriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.regularPriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                      <TableCell>
                        {moment(category.dtPriceChanged).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell>{category.promo}</TableCell>
                      <TableCell>
                        {moment(category.dtPromoChanged).format("DD-MMM-YYYY")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : detailStoreDatafilter &&
                  detailStoreDatafilter.length > 0 ? (
                  detailStoreDatafilter.map((category, index) => (
                    <TableRow
                      key={category.userStoreID}
                      title={
                        "Last Checked: " +
                        moment(category.lastCheckedDate).format("DD-MMM-YYYY")
                      }
                      className={
                        category.rowcount > 0
                          ? "table-body gropup-data border-tr"
                          : "no-border"
                      }
                    >
                      {category.storeName !== "" ? (
                        <TableCell
                          className="top-align-cell"
                          rowSpan={
                            category.rowcount > 0 ? category.rowcount : 0
                          }
                        >
                          <Link
                            onClick={() =>
                              this.getParams(
                                category.storeName,
                                category.storeId
                              )
                            }
                            className="storename"
                          >
                            {category.storeName}
                          </Link>
                          <br />
                          <span className="store-names-view">
                            {category.address}
                          </span>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {category.proximity !== "empty" ? (
                        <TableCell
                          rowSpan={
                            category.rowcount > 0 ? category.rowcount : 0
                          }
                          className={
                            category.rowcount > 0 ? "border-right-cell" : ""
                          }
                        >
                          {category.proximity.toFixed(2)}
                          {CONFIG.RegionId == 2 || CONFIG.RegionId == 4
                            ? " " + "km"
                            : " " + "mi"}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell>
                        {category.size}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "storageunit" ? (
                          <img
                            src={StorageUnit}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "apartment" ? (
                          <img
                            src={apartment}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "artstudio" ? (
                          <img
                            src={artstudio}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "box" ? (
                          <img
                            src={box}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "celltower" ? (
                          <img
                            src={celltower}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "container" ? (
                          <img
                            src={container}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "filestorage" ? (
                          <img
                            src={filestorage}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "office" ? (
                          <img
                            src={office}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "mailbox" ? (
                          <img
                            src={mailbox}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "parking" ? (
                          <img
                            src={parking}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "unit" ? (
                          <img
                            src={unit}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "warehouse" ? (
                          <img
                            src={warehouse}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "winestorage" ? (
                          <img
                            src={winestorage}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "workshop" ? (
                          <img
                            src={workshop}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                        {category.spaceType.toLowerCase().replace(" ", "") ===
                        "locker" ? (
                          <img
                            src={locker}
                            class="space-type"
                            title={category.spaceType}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{category.floor}</TableCell>
                      <TableCell className="feature-images">
                        {category.covered === 1 ? (
                          <img src={covered} title="Covered" />
                        ) : (
                          ""
                        )}
                        {category.vehicle === 1 ? (
                          <img src={vehicle} title="Vehicle" />
                        ) : (
                          ""
                        )}
                        {category.alarm === 1 ? (
                          <img src={Alarm} title="Alarm" />
                        ) : (
                          ""
                        )}
                        {category.climateControlled === 1 ? (
                          <img src={ClimateControlled} title="Climate" />
                        ) : (
                          ""
                        )}
                        {category.doorType > 0 ? (
                          <img
                            src={DoorType}
                            title={
                              category.doorType === 1
                                ? "Roll-Up Door"
                                : category.doorType === 2
                                ? "Swing Door"
                                : category.doorType === 3
                                ? "Double Door"
                                : category.doorType === 4
                                ? "Chain Link Door"
                                : category.doorType === 5
                                ? "Other Door"
                                : ""
                            }
                          />
                        ) : (
                          ""
                        )}
                        {category.driveUp === 1 ? (
                          <img src={DriveUp} title="Drive Up" />
                        ) : (
                          ""
                        )}
                        {category.elevator === 1 ? (
                          <img src={Elevator} title="Elevator" />
                        ) : (
                          ""
                        )}
                        {category.humidityControlled === 1 ? (
                          <img src={HumidityControlled} title="Humidity" />
                        ) : (
                          ""
                        )}
                        {category.outdoorAccess === 1 ? (
                          <img src={OutdoorAccess} title="Outdoor Access" />
                        ) : (
                          ""
                        )}
                        {category.power === 1 ? (
                          <img src={power} title="Power" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {category.onlinePrice >= 1 && this.props.curr ? (
                          <span>{this.props.curr + category.onlinePrice}</span>
                        ) : (
                          "N/A"
                        )}
                        {category.isMonthly &&
                        category.isMonthly === 1 &&
                        CONFIG.RegionId === 3 ? (
                          <sup>*</sup>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      {/* {CONFIG.RegionId ===3?<TableCell>{category.onlinePriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.onlinePriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                      <TableCell>
                        {moment(category.dtOnlinePriceChanged).format(
                          "DD-MMM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {category.regPrice >= 1 && this.props.curr ? (
                          <span>{this.props.curr + category.regPrice}</span>
                        ) : (
                          "N/A"
                        )}
                        {category.isMonthly &&
                        category.isMonthly === 1 &&
                        CONFIG.RegionId === 3 ? (
                          <sup>*</sup>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      {/* {CONFIG.RegionId ===3?<TableCell>{category.regularPriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.regularPriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                      <TableCell>
                        {moment(category.dtPriceChanged).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell>{category.promo}</TableCell>
                      <TableCell>
                        {moment(category.dtPromoChanged).format("DD-MMM-YYYY")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="10" className="no-data">
                      {isLoading === false ? "No data Available." : ""}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {/* {
              detailStoreData && detailStoreData.length > 0 ?
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[2]}
                colSpan={10}
                count={detailStoreData ? detailStoreData.length : detailStoreDatafilter ? detailStoreDatafilterLength : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={DetailedStoresViewWrapped}
              />
            </TableRow>
          </TableFooter>
          :''} */}
            </Table>
          </Paper>
          {/* {CONFIG.RegionId ===3?
          <div className="new-legend legend-box">
                   
                       <b>Legend:</b>
                        <FontAwesomeIcon icon={faCheck} className="green-clr" /> Price includes VAT
                        <FontAwesomeIcon icon={faTimes} className="red-clr" /> Price excludes VAT 
                        <FontAwesomeIcon  className="red-clr" /> <span>--</span>Unknown if price includes VAT
                        
                     </div>
                     :""
          } */}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose.bind(this)}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose.bind(this)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackMSg}</span>}
        />
      </div>
    );
  }
}

CustomDetailedStoresView.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default CustomDetailedStoresView;
