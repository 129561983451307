import React from 'react';
import './PricingVolatilityPdf.css';
import * as d3 from "d3";
import { CONFIG, MatchingCountryCode } from '../../../utils/config';
import axios from 'axios';
import { HelpTextPopup } from '../../HelpTextPopup/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table as TableBoots } from 'reactstrap';
import shows from '../../../Assets/Images/show.png';
import hiddens from '../../../Assets/Images/unshow.png';
import downarrows from '../../../Assets/Images/down-arrow.png';
import { CompetitorStore } from '../../CompetitorStore';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Logo from '../../../Assets/Images/logo.jpg';
import Chart from "react-apexcharts";
import duplicate from "../../../Assets/Images/duplicate.png"
export default class PricingVolatilityPdf extends React.Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      options: {
        chart: {
          id: 'apexchart-example',
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 3,
        },
        markers: {
          size: 4,
        },
        grid: { show: false },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          }
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [],

      snackOpen: false,
      snackMSg: '',
      marketVolatility: '',
      marketStorePrice: '',
      isLoading: false,
      unitType: '',
      priceOption: '',
      dashboardBasicInfo: '',
      volatilityGrpahData: '',
      isDefaultUnit: this.props.unit,
      isDefaultPrice: this.props.price,
      showGrpahData: '',
      isSelectIdPush: [],
      isShowGraph: this.props.isShowGraph,
      isYourStore: [],
      isRemoveGrapg: this.props.isRemoveGraph,
      isSelectCount: [],
      locationInfoData: [],
      isdefaultSelected: this.props.unit,
      isdefaultSelectedprice: this.props.price,
      isNoGraphData: false,
      isCompsName: this.props.isCompsName,
      order: this.props.order ? this.props.order : 'asc',
      orderBy: this.props.orderby ? this.props.orderby : 'storeName',
      isNoData: false,
      accessKey: this.props.accessKey,
      showRemoveId: this.props.showRemoveId,
      showRemoveType: this.props.showRemoveType,
      new_filterData : []
    };
    this.getParams = this.getParams.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
  }

  getParams(storeAddress) {
    let storeId = parseInt(this.props.userstoreid);
    this.child.current.toggleStoreModal(storeAddress, storeId);
  }

  //Table Sorting 
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    let sort = isDesc ? 'asc' : 'desc';
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }
  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.pricingVoltality();
    } else {
      alert(response.status.Message);
    }
  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": parseInt(this.props.userstoreid),
      "RegionId": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation', data)
      .then(response => {
        const locationInfoData = response.data.data.storeDashboard;
        var countrycode = response.data.data.storeDashboard.countryCode;
        var data1 = {
          "CountryCode": countrycode,
          "RegionId": CONFIG.RegionId
        }
        axios.post(CONFIG.API_URL + 'dashboard/lookupunit', data1)
          .then(response => {
            let unitType = response.data.prices;
            //this.state.isDefaultUnit = unitType[0].unitType;
            let isDefaultUnit = unitType.filter((e => e.id == this.props.unit));
            this.state.isDefaultUnit = isDefaultUnit[0].unitType
            //this.state.isDefaultUnit = this.props.unit;
            this.setState({
              unitType,
            })
          });
        this.setState({
          locationInfoData: locationInfoData
        })
        const inputParam = {
          UserStoreID: parseInt(this.props.userstoreid),
          RegionId: CONFIG.RegionId,
          Competitors: '',
        }
        this.PricingVolatility(inputParam)
   
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  pricingVoltality() {

    this.setState({
      isLoading: true
    })


    this.loadDashBoard();


    axios.post(CONFIG.API_URL + 'dashboard/priceoptions')
      .then(response => {

        let priceOption = response.data.price;
        this.setState({
          priceOption
        })
      });

  }
  PricingVolatility(inputParam) {
    let isShowGraph = [];
    let isRemoveGrapg = [];
    let isCompsName = [];
    let isYourStore = [];
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/priceoverviewvolatility', inputParam)
      .then(response => {
        const dashboardBasicInfo = response.data.data.dashboardBasicInfo;
        const volatilityGrpahData = response.data.data.volatilityGrpahData;
        const marketVolatility = response.data.data.unitVolatilityData;
        // Below I have using the filter function to finding the null values of all unit types. If any case all the units return the null values we have define the one boolean value to the state for showing the "No data found" in the pricing volatality grid.
        var checkNull = marketVolatility.filter((filterData => filterData.volatility !== null));
        if (checkNull.length === 0) {
          this.setState({ isNoData: true });
        }
        // End of the filter function code.
        const marketStorePrice = response.data.data.allVolatilityData;
        const isUnitType = marketVolatility.filter((e => e.volatility === 0));

        const isUnitTypeNotNull = marketVolatility.filter((e => e.volatility));
        const isYourStCount = marketStorePrice.filter((e => e.isMyStore));
        // const isUnitTypeNull = marketVolatility.filter((e => e.volatility===null));
        let marketStorePriceSort = [];
        marketStorePrice.filter((e) => e.isMyStore === true).forEach(function (e) { isYourStore.push(e) });
        marketStorePrice.filter((e) => e.isMyStore === false).forEach(function (e) { marketStorePriceSort.push(e) });
        if (!this.state.showRemoveId && !this.state.showRemoveType) {
          marketStorePrice.filter((e) => e.isSelected === 1).forEach(function (e) { isShowGraph.push(e.storeId) });
          marketStorePrice.filter((e) => e.isSelected === 1 && e.isMyStore != 1).forEach(function (e) { isCompsName.push(e.storeName) });

        }
        marketStorePrice.filter((e) => e.isSelected === 0).forEach(function (e) { isRemoveGrapg.push(e.storeId) });
        if (isShowGraph === undefined) {
          isShowGraph = [];
        }
        if (isRemoveGrapg === undefined) {
          isRemoveGrapg = [];
        }
        if (isYourStore === undefined) {
          isYourStore = [];
        }

        if (this.props.orderby == "storeName") {
          if (this.props.order === 'asc') {
            marketStorePriceSort = marketStorePrice.sort((a, b) => a.storeName.localeCompare(b.storeName));
          }
          else {
            marketStorePriceSort = marketStorePrice.sort((a, b) => b.storeName.localeCompare(a.storeName));
          }
        } else if (this.props.orderby == "allPrice") {
          if (this.props.order === 'asc') {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (a.allPrice - b.allPrice));
          }
          else {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (b.allPrice - a.allPrice));
          }
        }
        else if (this.props.orderby == "premium") {
          if (this.props.order === 'asc') {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (a.premium - b.premium));
          }
          else {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (b.premium - a.premium));
          }
        } else if (this.props.orderby == "value") {
          if (this.props.order === 'asc') {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (a.value - b.value));
          }
          else {
            marketStorePriceSort = marketStorePrice.sort((a, b) => (b.value - a.value));
          }
        }

        this.setState({
          marketVolatility, isYourStCount, isUnitType, isYourStore, isUnitTypeNotNull, volatilityGrpahData, isCompsName, marketStorePrice: marketStorePriceSort, dashboardBasicInfo, isShowGraph, isRemoveGrapg, isLoading: false
        })

        if (this.state.volatilityGrpahData.length > 0) {
          this.setState({
            isNoGraphData: false
          })
          // if((this.props.isShowGraph == "null") || (this.props.isRemoveGraph =="null" )){

          //this.initialLoad(volatilityGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice));


          if (this.state.showRemoveId && this.state.showRemoveType) {
            let name = marketStorePrice.filter(a => a.storeId == this.state.showRemoveId)
            // volatilityGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice))
            this.showGraph(this.state.showRemoveId, this.state.showRemoveType, name[0]['storeName'])
          }
          else {
            this.initialLoad(volatilityGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice));

          }
          // }
          //     else{
          //   const inputParam = {
          //     UserStoreID: parseInt(this.props.userstoreid),
          //     Competitors: this.props.Competitors,
          //     RegionId: CONFIG.RegionId
          //   }

          //   this.onlyShowGraph(inputParam);
          // }

        }
        else {
          this.setState({
            isNoGraphData: true
          })
        }

      }).catch((err) => {
        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  apexChartNoData = ({ textMessage, colorCode }) => {
    this.setState({
      series: [],
      options: {
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        noData: {
          text: textMessage,
          style: { color: colorCode }
        }
      }
    });
  }

  initialLoad(res) {
    const { isCompsName } = this.state;
    var data = res;
    var przvoldality = res.filter((a) => a.volatility !== null);
    let isCompString = typeof (isCompsName) !== 'object' ? isCompsName : isCompsName.toString();
    this.setState({new_filterData : []});
    if (przvoldality.length > 0) {
      this.setState({
        isNoGraphData: false,
      });

      var tempdata = [];
      let marketSeries = [];
      let widthArrayData = [];
      let dashArrayData = [];
      let marketNameList = [];
      let colorsCodeList = [];
      let markerSize = [];
      let newFilter_tempdata = [];
      let unique = [...new Set(data.map((item) => item.storeId))];
      let MarketName = ["Your Store", "Market Average", isCompString];
      let MarketName2 = ["Market Average", isCompString];
      let colorsCode = ['#F15D47', '#1B2C42', '#41A3F6'];
      let colorsCode2 = ['#1B2C42', '#41A3F6'];
      for (var i = 0; i < unique.length; i++) {
        var datum = {
          name: unique[i],
          count: i,
          isMyMarket: this.popMyStore(data, unique[i]),
          values: this.popData(data, unique[i]),
        };
        tempdata.push(datum);
      }
      const toTop = (key, value) => (a, b) => (b[key] === value) - (a[key] === value);
      tempdata.sort(toTop('isMyMarket', 1));
      tempdata.every(val => {
        marketNameList = val.isMyMarket === 1 ? MarketName : MarketName2;
        colorsCodeList = val.isMyMarket === 1 ? colorsCode : colorsCode2;
      })
      for (var k = 0; k < tempdata.length; k++) {
        let noValues = tempdata[k].values.every(item => item.totalChangeCount === '' || item.totalChangeCount === undefined);
        if (!noValues) {
          let dataSeries = tempdata[k].values.map(val => { return { x: val.monthYear, y: val.totalChangeCount, originData: val.totalChangeCount }; });
          let dataSeries1 = tempdata[k].values.map(val => { return { x: val.monthYear, y: val.totalChangeCount !== null && val.totalChangeCount !== "0.00" ? val.totalChangeCount : 0, originData: val.totalChangeCount !== null && val.totalChangeCount !== "0.00" ? val.totalChangeCount : 0 }; });
          let containsNull = dataSeries.every(val => val.y !== null);
          newFilter_tempdata.push({name :marketNameList[k] , data:dataSeries1 , color:colorsCodeList[k]});
          if (tempdata[k].isMyMarket === 1) {
            if (!containsNull) {
              let noZeroData1 = dataSeries.map(yData => { return { x: yData.x, y: yData.y === null ? 0 : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: (marketNameList[k] + " data"), data: noZeroData1, color: colorsCodeList[k] });
              let noZeroData = dataSeries.map(yData => { return { x: yData.x, y: yData.y === 0 ? null : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: marketNameList[k], data: noZeroData, color: colorsCodeList[k] });
              markerSize.push(0, 10);
              widthArrayData.push(1, 3);
              dashArrayData.push(6, 0);
            } else {
              marketSeries.push({ name: marketNameList[k], data: dataSeries, color: colorsCodeList[k] });
              markerSize.push(10);
              widthArrayData.push(3);
              dashArrayData.push(0);
            }
          } else {
            if (!containsNull) {
              let noZeroData1 = dataSeries.map(yData => { return { x: yData.x, y: yData.y === null ? 0 : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: (marketNameList[k] + " data"), data: noZeroData1, color: colorsCodeList[k] });
              let noZeroData = dataSeries.map(yData => { return { x: yData.x, y: yData.y === 0 ? null : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: marketNameList[k], data: noZeroData, color: colorsCodeList[k] });
              markerSize.push(0, 10);
              widthArrayData.push(1, 3);
              dashArrayData.push(6, 0);
            } else {
              marketSeries.push({ name: marketNameList[k], data: dataSeries, color: colorsCodeList[k] });
              markerSize.push(10);
              widthArrayData.push(3);
              dashArrayData.push(0);
            }
          }
        }
      }
      
       //Duplicate Data
     let newData = [];
     newFilter_tempdata.map(dataItem => dataItem.data.forEach(i => {
      if(i.y !== "" && i.y !== undefined){
        newFilter_tempdata.map(userItem => userItem.data.forEach(j => {
          if(j.y !== "" && j.y !== undefined){
            if((dataItem.name !== userItem.name) && (dataItem.color !== userItem.color)){
              if((i.y === j.y) && (i.x === j.x)){
                newData.push({name:dataItem.name ,color:dataItem.color});
               let new_filterData = newData.reduce((elements , obj , index)=>{  
                  let existingData = elements.find(element => 
                    element.name === obj.name  && element.color === obj.color
                  );
                  if(!existingData){
                    elements.push(obj);
                  }
                  return elements;
                }, []);
                this.setState({new_filterData : new_filterData});   
              }
            }
          }
        }));
      }
     })); 

      if (marketSeries.length > 0) {
        this.setState({
          options: {
            chart:{
              animations: {
                enabled: false,
              },
            },
            tooltip: {
              enabled: false
            },
            stroke: {
              width: widthArrayData,
              dashArray: dashArrayData,
            },
            markers: {
              size: 4,
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              position: 'bottom',
              horizontalAlign: 'center',
              fontSize: '11px',
              fontWeight: 500,
              showForNullSeries: true,
              showForZeroSeries: true,
              itemMargin: {
                horizontal: 10,
                vertical: 20,
              },
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: false
              },
              formatter: function (seriesName, opts) {
                if (!seriesName.endsWith('data')) return seriesName;
                return null;
              },
              markers: {
                width: markerSize,
                height: markerSize,
              }
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  let val = value === null || value === undefined ? "N/A" : value.toFixed(0) + "%";
                  return val;
                },
                style: {
                  colors: '#8C9AA6',
                  fontSize: '10px'
                },
              },
              axisBorder:{
                show: false,
              },
              axisTicks: {
                show: false,
              }
            },
            xaxis: {
              title: {
                text: "--- No Data Available",
                offsetX: 400,
                offsetY: 20,
                style: {
                  fontSize: '11px',
                  fontWeight: 400,
                },
              },
              tooltip: { enabled: false },
              labels: {
                formatter: function (value) {
                  let val = value === undefined || typeof (value) !== "string" ? '' : value;
                  return val;
                },
                show: true,
                style: {
                  colors: '#8C9AA6',
                  fontSize: '10px'
                },
              },
              axisBorder: {
                show: true
              },
              axisTicks: {
                show: true
              }
            },
          },
          series: marketSeries
        })
      };

    }
    else {
      this.apexChartNoData({

        textMessage: "No Data Found",
        colorCode: "#F60000"
      });
    }
  }

  popData(data, uid) {
    var tFData = data.filter(a => a.storeId == uid);
    var tData = [];
    for (var i = 0; i < tFData.length; i++) {
      var CData = {
        date: new Date(tFData[i].year, tFData[i].month - 1, 1),
        volatility: tFData[i].volatility,
        month: tFData[i].month,
        isMyMarket: tFData[i].isMyMarket,
        totalChangeCount: tFData[i].totalChangeCount,
        totalCount: tFData[i].totalCount,
        monthYear: tFData[i].monthYear,
      }
      tData.push(CData);
    }

    return tData;
  }

  popMyStore(data, uid) {

    var tFData = data.filter(a => (a.storeId == uid && a.isMyMarket == 1));
    return (tFData.length > 0 ? 1 : 0);
  }

  showGraph(sId, type, name) {
    this.setState({
      isLoading: true
    })

    const isShowGraph = this.state.isShowGraph
    const isCount = this.state.isYourStCount;
    var isVal = 2;
    if (isCount.length === 0) {
      isVal = 1;
    }
    else {
      isVal = 2;
    }
    if (isShowGraph.length <= isVal) {
      var isSelectStoreId = [];
      var id;
      // var isShowData = this.state.marketStorePrice;
      //var isShowG = isShowData.filter(a => a.isSelected == "1");
      // for (const item of isShowG) {
      //   var id = item.storeId;
      // }
      // const isShowGraph = this.state.isShowGraph
      const isRemoveGrapg = this.state.isRemoveGrapg;
      let index;
      if (isShowGraph.length <= isVal) {
        if (type == "1") {
          this.state.isCompsName = name;
          id = sId;
          id = 0;
          isSelectStoreId.push(id);
          index = isShowGraph.indexOf(+sId)
          isShowGraph.splice(index, 1)
          isRemoveGrapg.push(+sId)
          const inputParam = {
            UserStoreID: parseInt(this.props.userstoreid),
            Competitors: isSelectStoreId.toString(),
            RegionId: CONFIG.RegionId
          }

          this.onlyShowGraph(inputParam);

        }
      }
      if (isShowGraph.length < isVal) {
        this.state.isCompsName = [];
        if (type == "0") {
          this.state.isCompsName = name;
          if (id == undefined) {
            id = 0;
          }
          isSelectStoreId.push(id);
          isSelectStoreId = isSelectStoreId.concat(sId);
          index = isRemoveGrapg.indexOf(+sId)
          isRemoveGrapg.splice(index, 1)
          isShowGraph.push(+sId)
          const inputParam = {
            UserStoreID: parseInt(this.props.userstoreid),
            Competitors: isSelectStoreId.toString(),
            RegionId: CONFIG.RegionId
          }
          this.onlyShowGraph(inputParam);
        }
      }
      else {
        this.setState({
          isLoading: false
        })
        this.state.isSelectCount.pop();
        this.setState({ snackOpen: true, snackMSg: 'Please remove any of the selected markets to add new ones.' });
      }
      this.setState({
        isRemoveGrapg,
        isShowGraph
      })


    }

  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };


  onlyShowGraph(inputParam) {

    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/priceoverviewvolatilitygraph', inputParam)
      .then(response => {


        const showGrpahData = response.data.data;
        this.state.volatilityGrpahData = response.data.data;
        this.setState({
          showGrpahData, isLoading: false
        })
        if (this.state.showGrpahData.length > 0) {
          this.initialLoad(showGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice));
        }
      }).catch((err) => {

        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }


  onUnitChange(e) {

    this.state.isDefaultUnit = e.target.value;
    var res = '';
    res = this.state.volatilityGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice);
    this.initialLoad(res);
  }
  onPriceChange(e) {

    this.state.isDefaultPrice = e.target.value;
    var res = '';
    res = this.state.volatilityGrpahData.filter(a => a.unitType == this.state.isDefaultUnit && a.priceType == this.state.isDefaultPrice);
    this.initialLoad(res);
  }

  render() {
    const { marketVolatility, isYourStore, isUnitType, order, orderBy, isUnitTypeNotNull, marketStorePrice, isCompsName, isNoGraphData, locationInfoData, isRemoveGrapg, isShowGraph, dashboardBasicInfo, isLoading, unitType, priceOption, isNoData, new_filterData } = this.state;
    const defaultProps = {
      helpTitle: "Price Volatility",
      helpContent: "<h6>How aggressive is this store/competitor?</h6><ul><li>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing.</li><li> A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility.</li><li> Here you are shown the pricing volatility for this Market, Your Store (if available), the REITs in this Market, Multi-store operators and Single-store operators over the last 12 months.</li><li>Pricing Volatility is calculated by taking the number of times a pricing change occurred over the last 12 months for each store type.</li><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p></ul><iframe width='472' height='248' src='https://www.youtube.com/embed/EQQ7dSl3xNE' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    }
    const defaultProps1 = {
      helpTitle: "Last 12 Months",
      helpContent: "<h6>How aggressive is this store/competitor?</h6><p>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing. A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility. Here you are shown the average monthly pricing volatility for Your Store (if available), against the Market average and any of the stores/competitors you select for comparison*, for the unit type and price type selected from the drop down menu.</p><p>Pricing Volatility is calculated by taking the number of times a pricing change occurred each month over the last 12 months.</p><p>*See Pricing Volatility by Store & Price Option to select a store you want to view on this graph</p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    const defaultProps2 = {
      helpTitle: "Market Pricing Volatility Over Last 12 Months",
      helpContent: "<p>This shows the pricing volatility over the last 12 months for each of the 11 common unit types (across the entire Market). This helps you understand which unit types in the Market are priced more aggressively.</p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    const defaultProps3 = {
      helpTitle: "Pricing Volatility by Store & Price Option",
      helpContent: "<p>This shows you all the stores in this Market along with their overall pricing volatility across all the unit types they advertise for the last 12 months. For comparison, we also show you the pricing volatility of the Premium unit rates vs. their Value unit rates.</p><p>Click on the link &#34;Show on graph&#34; to view this store's pricing volatility graph above over the last 12 months.</p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    const tr = [...Array(Math.ceil(marketStorePrice.length / 18))];
    const trproductRows = tr.map((row, idx) => marketStorePrice.slice(idx * 18, idx * 18 + 18));
    // console.log(trproductRows, 'array', marketStorePrice);
    const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
    return (
      <div className="pricing-volatility pricing-volatility-pdf">
        <div className="header"> <img src={Logo} />
          <div className="float-right-name"><h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div>
          <div className="float-right">
            <p className="m-0 address-font">{locationInfoData && locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ',' : ''} {locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''} {locationInfoData ? locationInfoData.storeState : 'N/A'} {locationInfoData ? locationInfoData.storeZipCode : 'N/A'} | Coverage: {locationInfoData ? locationInfoData.storeZoneCoverage : 'N/A'}</p>
          </div>
        </div>
        <div className="footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>


        <div className="clearfix"> </div>

        <div className="top-content-body help-text-pdf mb-3">
          <h3>Price Volatility</h3>
          <h6 className="mb-2">How aggressive is this store/competitor?</h6>
          <ul>
            <li>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing.</li>
            <li> A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility.</li>
            <li> Here you are shown the pricing volatility for this Market, Your Store (if available), the REITs in this Market, Multi-store operators and Single-store operators over the last 12 months.</li>
            <li>Pricing Volatility is calculated by taking the number of times a pricing change occurred over the last 12 months for each store type.</li>

          </ul>
          <p><i>*Your store prices are included in the calculations.</i></p>
        </div>
        <div className="clearfix"> </div>
        <div className="banner-header mb-4">
          <Row className="height-full row-wid-flex">
            <Col> <div className="market-label">Market</div><div className="market-value">{dashboardBasicInfo.market != null ? dashboardBasicInfo.market + '%' : isLoading === false ? 'N/A' : ''}</div>{dashboardBasicInfo.market != null && dashboardBasicInfo.market ? <div className="btm-label">Last 12 months</div> : ''}</Col>
            <Col className="common-col height-full"><div className="middle-div"><div className="other-labels">Your Store</div><div className="other-values">{dashboardBasicInfo.myStore != null ? dashboardBasicInfo.myStore + '%' : isLoading === false ? 'N/A' : ''}</div></div></Col>
            {locationInfoData.countryCode == "US" ?
              <Col className="common-col height-full"><div className="middle-div"><div className="other-labels">REIT's</div><div className="other-values">{dashboardBasicInfo.reits != null ? dashboardBasicInfo.reits + '%' : isLoading === false ? 'N/A' : ''}</div></div></Col>
              : ''}
            <Col className="common-col height-full"><div className="middle-div"><div className="other-labels">Multi-Ops</div><div className="other-values">{dashboardBasicInfo.multiOps != null ? dashboardBasicInfo.multiOps + '%' : isLoading === false ? 'N/A' : ''}</div></div></Col>
            <Col className="common-col height-full remove-right"><div className="middle-div"><div className="other-labels">Single-Ops</div><div className="other-values">{dashboardBasicInfo.singleOps != null ? dashboardBasicInfo.singleOps + '%' : isLoading === false ? 'N/A' : ''}</div></div></Col>
          </Row>
        </div>
        <div className="dashboard-layout last-months mt-2">

          <div className="App">

            <div style={{ overflow: 'hidden' }}>
              <h4>Last 12 months
                {/* <HelpTextPopup parm={defaultProps1} /> */}
              </h4>
              <AvForm className="select-unit-type">
                <AvField className="custom-select-opt" type="select" name="unit" value={this.state.isDefaultUnit} label="" helpMessage="" validate={{
                }} onChange={e => this.onUnitChange(e)}>
                  {/* <option value='' >Unit Type</option> */}
                  {unitType ? unitType.map((unit) =>
                    <option key={unit.id} selected={this.state.isdefaultSelected} value={unit.unitType}>{unit.unitType}</option>
                  ) : ''}
                </AvField>
                <AvField type="select" className="custom-select-opt" name="price" label="" value={this.state.isdefaultSelectedprice} helpMessage="" validate={{
                }} onChange={e => this.onPriceChange(e)}>
                  {/* <option value='' >Price Option</option> */}
                  {priceOption ? priceOption.map((price) =>
                    <option key={price.id} selected={this.state.isdefaultSelectedprice} value={price.name}>{price.name}</option>
                  ) : ''}
                </AvField>
              </AvForm>
            </div>
            <div className="help-text-pdf mb-4 text-left">
              <h6 className="mb-2">How aggressive is this store/competitor?</h6>
              <p>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing. A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility. Here you are shown the average monthly pricing volatility for Your Store (if available), against the Market average and any of the stores/competitors you select for comparison*, for the unit type and price type selected from the drop down menu.</p>
              <p>Pricing Volatility is calculated by taking the number of times a pricing change occurred each month over the last 12 months.</p><p>*See Pricing Volatility by Store & Price Option to select a store you want to view on this graph</p>
              <p><i>*Your store prices are included in the calculations.</i></p>
            </div>

            <div className="clear"></div>

            {
              new_filterData.length > 0 ? <div className="duplicate-data-show">
              <img src={duplicate} />
              <div>
                          {
                            new_filterData.map((item)=>{
                              return(
                                <>
                                <div className="legend-box-color" style={{background: item.color }}></div>
                                <span>{item.name}</span>
                                </>
                              )
                            })
                          }
                          <b> has same value in the series.</b> 
                  </div> </div> : ""
            } 

            <div className={isNoGraphData ? 'fix-map price-map' : 'price-map'}>
              <div id="Price-Volatility"></div>

              <div className="space_xaxis">
              {isNoGraphData ? <div className="no-data">No Data Found</div> :
              
                <Chart options={this.state.options} series={this.state.series} type="line" width={960} height={320} />}
            </div>
            </div>
          </div>
          <div className="request-free-trial">
            {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
            <Col>
              <h5 className="bill-head">Current Market Volatility(last 12 months)
                {/* <HelpTextPopup parm={defaultProps2} /> */}
              </h5>
              <div className="help-text-pdf mb-4 text-left">
                <p>This shows the pricing volatility over the last 12 months for each of the 11 common unit types (across the entire Market). This helps you understand which unit types in the Market are priced more aggressively.</p>
                <p><i>*Your store prices are included in the calculations.</i></p>
              </div>
              <div className="clear"></div>

              <div className="market-volatility">
                <div className="Table hole-table" >
                  {marketVolatility.length > 0 ?
                    <Table className="table table-bordered custom-table">
                      <TableHead className="TH cur-mar-vol-tab-head">
                        <TableRow className="table-header">
                          {marketVolatility.length > 0 ? marketVolatility.map(data => {
                            return (
                              <TableCell>{data.unitType}</TableCell>
                            );
                          }) : ''}
                        </TableRow>
                      </TableHead>
                      <TableBody className="cur-mar-vol-tab-body">
                        <TableRow className="table-body">
                          {
                            (isUnitType.length === 0 || isUnitType.length > 0) && isNoData === false ? marketVolatility.map(data => {
                              return (
                                <TableCell>
                                  {data.volatility === null ? 'N/A' : data.volatility + '%'}
                                </TableCell>
                              )
                            }) : isNoData === true ? '' : <td colspan={marketVolatility.length} className="no-data">{isLoading === false ? 'No Data Found' : ''}</td>
                          }
                          {/* Below I write code for showing "No data FOund" if all the units have return the null values */}
                          {
                            isNoData === true ?
                              <TableCell className="no-data" colSpan={marketVolatility.length}>
                                {isLoading === false ? 'No Data Found' : ''}
                              </TableCell> : ''
                          }
                          {/* Above I write code for showing "No data FOund" if all the units have return the null values */}
                        </TableRow>
                      </TableBody>
                    </Table> : <p className="no-data">{isLoading === false ? 'No Data Found' : ''}</p>}
                </div>
              </div>
            </Col>
          </div>
          <div className="request-free-trial price-option">
            {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
            <Col>
              {/* <h5 className="bill-head">Current Market Volatility by Store & Price Option(last 12 months) <HelpTextPopup parm={defaultProps3} /></h5> */}
              <div className="clear"></div>

              <div className="mobile-view-market-volatility print-view-pdf ">
                <div className="Table table-max-height print-view" >
                  {
                    trproductRows ? trproductRows.map((rows, i) => (
                      <div class="page fix-top page first-child-page">
                        {i === 0 ?
                          <div>
                            <h5 className="bill-head">Current Market Volatility by Store & Price Option(last 12 months)</h5>
                            <div className="help-text-pdf mb-4 text-left">
                              <h6 className="mb-2">Pricing Volatility by Store & Price Option</h6>
                              <p>This shows you all the stores in this Market along with their overall pricing volatility across all the unit types they advertise for the last 12 months. For comparison, we also show you the pricing volatility of the Premium unit rates vs. their Value unit rates.</p>
                              <p>Click on the link &#34;Show on graph&#34; to view this store's pricing volatility graph above over the last 12 months.</p>
                              <p><i>*Your store prices are included in the calculations.</i></p>
                            </div>
                          </div>
                          : ''}


                        <Table className="table pr-vol table-bordered custom-table set-width-current-market pv_table" scrollY>
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={marketStorePrice.length}
                          />
                          <TableBody>


                            {i === 0 && isYourStore.length > 0 ? isYourStore.map(data => {
                              return (
                                <TableRow className="table-body store-price-opt">
                                  {/* <TableCell className="store-name">{data.storeName} <div className="store-address">{data.address}, {data.city}, {data.state}, {data.zipCode}</div></TableCell> */}
                                  {data.storeName === 'Your Store' ?
                                    <TableCell className="your-store store-name">{data.storeName === null ? 'N/A' : data.storeName} <div className="store-address">{data.address != null ? data.address : ''} {data.city != null ? ', ' + data.city : ''} {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '' : data.state != null ? ', ' + data.state : ''} {data.zipCode != null ? data.zipCode : ''}</div></TableCell> : <TableCell className="store-name">{data.storeName === null ? 'N/A' : data.storeName} <div className="store-address">{data.address != null ? data.address : ''} {data.city != null ? ', ' + data.city : ''} {data.state != null && CONFIG.RegionId !== MatchingCountryCode.UK_RID ? ', ' + data.state : ''} {data.zipCode != null ? data.zipCode : ''}</div></TableCell>}
                                  {data.storeName === 'Your Store' ?
                                    <TableCell className="your-store">{data.allPrice === null ? 'N/A' : data.allPrice + '%'}</TableCell> : <TableCell className="">{data.allPrice === null ? 'N/A' : data.allPrice + '%'}</TableCell>}
                                  {data.storeName === 'Your Store' ?
                                    <TableCell className="your-store">{data.premium === null ? 'N/A' : data.premium + '%'}</TableCell> : <TableCell className="">{data.premium === null ? 'N/A' : data.premium + '%'}</TableCell>}
                                  {data.storeName === 'Your Store' ?
                                    <TableCell className="your-store">{data.value === null ? 'N/A' : data.value + '%'}</TableCell> : <TableCell className="">{data.value === null ? 'N/A' : data.value + '%'}</TableCell>}
                                  {data.storeName === 'Your Store' ?
                                    <TableCell className="your-store">
                                      {(data.storeId === isShowGraph.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 1, data.storeName)} className="hides-graph"><img src={hiddens} className="remove-graph" alt="Show Graph" />Remove from graph</a> : ''}
                                      {(data.storeId === isRemoveGrapg.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 0, data.storeName)} className="shows-graph"><img src={shows} className="show-graph" alt="Remove Graph" />Show on graph</a> : ''}
                                    </TableCell> : <TableCell className="">
                                      {(data.storeId === isShowGraph.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 1, data.storeName)} className="hides-graph"><img src={hiddens} className="remove-graph" alt="Show Graph" />Remove from graph</a> : ''}
                                      {(data.storeId === isRemoveGrapg.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 0, data.storeName)} className="shows-graph"><img src={shows} className="show-graph" alt="Remove Graph" />Show on graph</a> : ''}
                                    </TableCell>}
                                </TableRow>
                              );
                            })
                              : ''}
                            {marketStorePrice.length > 0 ? this.stableSort(rows, this.getSorting(order, orderBy)).map(data => {
                              if (data.isMyStore !== true) {
                                return (
                                  <TableRow className="table-body">
                                    {/* <TableCell className="store-name">{data.storeName} <div className="store-address">{data.address}, {data.city}, {data.state}, {data.zipCode}</div></TableCell> */}
                                    {data.storeName === 'Your Store' ?
                                      <TableCell className="your-store store-name">{data.storeName === null ? 'N/A' : data.storeName} <div className="store-address">{data.address != null ? data.address : ''} {data.city != null ? ', ' + data.city : ''} {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '' : data.state != null ? ', ' + data.state : ''} {data.zipCode != null ? data.zipCode : ''}</div></TableCell> : <TableCell className="store-name">{data.storeName === null ? 'N/A' : data.storeName} <div className="store-address">{data.address != null ? data.address : ''} {data.city != null ? ', ' + data.city : ''} {data.state != null && CONFIG.RegionId !== MatchingCountryCode.UK_RID ? ', ' + data.state : ''} {data.zipCode != null ? data.zipCode : ''}</div></TableCell>}
                                    {data.storeName === 'Your Store' ?
                                      <TableCell className="your-store">{data.allPrice === null ? 'N/A' : data.allPrice + '%'}</TableCell> : <TableCell className="">{data.allPrice === null ? 'N/A' : data.allPrice + '%'}</TableCell>}
                                    {data.storeName === 'Your Store' ?
                                      <TableCell className="your-store">{data.premium === null ? 'N/A' : data.premium + '%'}</TableCell> : <TableCell className="">{data.premium === null ? 'N/A' : data.premium + '%'}</TableCell>}
                                    {data.storeName === 'Your Store' ?
                                      <TableCell className="your-store">{data.value === null ? 'N/A' : data.value + '%'}</TableCell> : <TableCell className="">{data.value === null ? 'N/A' : data.value + '%'}</TableCell>}
                                    {data.storeName === 'Your Store' ?
                                      <TableCell className="your-store">
                                        {(data.storeId === isShowGraph.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 1, data.storeName)} className="hides-graph"><img src={hiddens} className="remove-graph" alt="Show Graph" />Remove from graph</a> : ''}
                                        {(data.storeId === isRemoveGrapg.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 0, data.storeName)} className="shows-graph"><img src={shows} className="show-graph" alt="Remove Graph" />Show on graph</a> : ''}
                                      </TableCell> : <TableCell className="">
                                        {(data.storeId === isShowGraph.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 1, data.storeName)} className="hides-graph"><img src={hiddens} className="remove-graph" alt="Show Graph" />Remove from graph</a> : ''}
                                        {(data.storeId === isRemoveGrapg.find((e) => e === data.storeId) && data.isMyStore != 1 && data.allPrice != null) ? <a onClick={() => this.showGraph(data.storeId, 0, data.storeName)} className="shows-graph"><img src={shows} className="show-graph" alt="Remove Graph" />Show on graph</a> : ''}
                                      </TableCell>}
                                  </TableRow>
                                );
                              }
                            })
                              : marketStorePrice.length <= 0 && isYourStore.length <= 0 ? <tr><td colspan="5" className="no-data">{isLoading === false ? 'No Data Found' : ''}</td></tr>
                                : marketStorePrice.length <= 0 ? <tr><td colspan="5" className="no-data">{isLoading === false ? 'No Competitor Data Found' : ''}</td></tr> : ''}
                          </TableBody>
                        </Table>

                        <div class="printclearfix"></div>
                      </div>
                    ))
                      : ''}
                </div>
              </div>
            </Col>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.snackOpen}
            className="snackbar bg-color-primary custom-snackbar"
            maxsnack={3}
            autoHideDuration={30000}
            onClose={this.snackHandleclose.bind(this)}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackMSg}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.snackHandleclose.bind(this)}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />


        </div>
      </div>
    );
  }
}


//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headRows = [
    { id: 'storeName', numeric: false, disablePadding: true, label: 'STORE NAME' },
    { id: 'allPrice', numeric: true, disablePadding: false, label: 'ALL PRICE VOLATILITY' },
    { id: 'premium', numeric: true, disablePadding: false, label: 'PREMIUM PRICE VOLATILITY' },
    { id: 'value', numeric: true, disablePadding: false, label: 'VALUE PRICE VOLATILITY' },
    { id: 'moreoptions', numeric: true, disablePadding: false, label: 'MORE OPTIONS' }
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align='center'
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              disabled={row.id !== 'moreoptions' ? false : true}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
} 