import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import "./InventoryAvailability.css";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import { Table as ReactTable } from "reactstrap";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chart from "react-apexcharts";


import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";
import { CONFIG } from "../../utils/config";
import axios from "axios";
import downarrows from "./../../Assets/Images/down-arrow.png";
import moment from "moment";
const actionsStyles = (theme) => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket
);

export default class InventoryAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overViewInfo: "",
      inventoryAvailablityList: [],
      storeAddress: "",
      isLoading: false,
      pdfLoading: true,
      modal: false,
      trackModalData: "",
      pageCount: 0,
      pageCountMDT: 0,
      max: 0,
      defaultWidth: 0,
      storeNeverOff: [],
      order: "asc",
      orderBy: "storeName",
      uniqueUnitType: [],
      invStore: [],
      uniqueSID: [],
      invStores: [],
      sname: [],
      address: [],
      invStoreStatus: false,
      snackOpen: false,
      snackMSg: "",
      snackClr: "",
      options: {
        chart: {
          id: 'apexchart-inventory-availablity',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            columnWidth: '10%',
            barHeight: '50%',
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
        },
        axisTicks: {
          show: false
        }
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [{
        name: '',
        data: [],
      }],
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  toggle() {
    let storeId = parseInt(this.props.match.params.userstoreid);
    axios
      .post(
        CONFIG.API_URL + "market/competitors/" + storeId + "/" + CONFIG.RegionId
      )
      .then((response) => {
        // this.state.isLoading=true;
        const trackModalData = response.data.competitor.competitorsList;
        var trackedFilterResult = trackModalData.filter(
          (result) => result.isTracked === true
        );
        // let strOptions = [];
        // trackModalData.forEach(function (e) { strOptions.push(e.storeID + (e.isTracked ? '|1' : '|0')) });
        this.setState({
          trackModalData: trackedFilterResult,
        });
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //     localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        // }
        // else {
        //     alert(err.response.data);
        // }
      });
  }
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const data = {
      UserStoreID: parseInt(this.props.match.params.userstoreid),
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(CONFIG.API_URL + "dashboard/dashboardlocation/", data)
      .then((res) => {
        const storeAddress = res.data.data.storeDashboard;
        this.setState({ storeAddress : storeAddress});
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });

    axios
      .post(CONFIG.API_URL + "dashboard/inventoryavailablity", data)
      .then((res) => {
        let overViewInfo = res.data.data.invAvaildashboard;
        let inventoryAvailablityList = res.data.data.inventoryAvailablityList;
        var storeNeverOff = inventoryAvailablityList.filter(function (m1) {
          return m1.storesWithUnitType > 0 && m1.storesWithUnitTypePercent > 0;
        });
        this.setState({ overViewInfo, storeNeverOff });
        this.setState({ inventoryAvailablityList });
        this.stackedgraph(inventoryAvailablityList);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
    axios
      .post(CONFIG.API_URL + "dashboard/inventorybystore", data)
      .then((res) => {
        let invStore = res.data.data;
        let uniqueSID = [...new Set(invStore.map((item) => item.storeID))];
        let uniqueUnitType = [
          ...new Set(invStore.map((item) => item.unitType)),
        ];
        var invStores = [];

        for (let i = 0; i < uniqueSID.length; i++) {
          var fD = invStore.filter((item) => item.storeID == uniqueSID[i]);
          let sname = [...new Set(fD.map((item) => item.storeName))];
          let address = [...new Set(fD.map((item) => item.storeAddress))];
          var key = "storeName";
          var obj = {};
          obj["storeName"] = sname[0];
          obj["storeAddress"] = address[0];
          obj["storeID"] = fD[0].storeID;
          for (let u = 0; u < uniqueUnitType.length; u++) {
            var uData = fD.filter((item) => item.unitType == uniqueUnitType[u]);
            obj[uniqueUnitType[u]] = uData[0].value;
          }
          invStores.push(obj);
        }

        this.setState({
          uniqueUnitType,
          invStore,
          uniqueSID,
          invStoreStatus: true,
          invStores,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  stackedgraph(datavalue) {
    let { inventoryAvailablityList } = this.state;
    let marketSeries = [];
    var resultedRange = this.state.inventoryAvailablityList.map(
      (data) => data.storesWithUnitType + data.storesNeverOffered
    );

    let onMarketData = [
      ...new Set(
        this.state.inventoryAvailablityList
          .filter(function (obj) {
            return obj.onMarket > 0;
          })
          .map((item) => item.onMarket)
      ),
    ];
    let offMarketData = [
      ...new Set(
        this.state.inventoryAvailablityList
          .filter(function (obj) {
            return obj.offMarket > 0;
          })
          .map((item) => item.offMarket)
      ),
    ];

    var max = resultedRange.reduce(function (a, b) {
      return Math.max(a, b);
    });
    max = onMarketData.length > 0 || offMarketData.length > 0 ? max : 0;
    this.setState({ max });
    let dataSeries = inventoryAvailablityList.map(val => { return { x: val.unitType, y: val.onMarket, perc: val.onMarketPercent }; });
    let dataSeries2 = inventoryAvailablityList.map(val => { return { x: val.unitType, y: val.offMarket, perc: val.offMarketPercent }; });
    marketSeries.push({ name: 'On Market', data: dataSeries }, { name: 'Off Market', data: dataSeries2 });
  
// YAXIS VALUES AS DYNAMIC
  let max_dataSeries = Math.max.apply(null, dataSeries.map(item => item.y));
  let max_dataSeries2 = Math.max.apply(null, dataSeries2.map(item => item.y));

  let max_marketSeries = 0
  if(max_dataSeries > max_dataSeries2){
    max_marketSeries = max_dataSeries
  }else{
    max_marketSeries = max_dataSeries2
  }

    if (marketSeries.length > 0 && max !== 0) {
      this.setState({
        options: {
          chart: {
            id: `apexchart-inventory-availablity${Math.random()}`,
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin:false,
                zoomout:false,
                pan:false,
                reset: true,
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (value, opts) {
                if (typeof (opts) === "object") {
                  let iAF = `${opts.series[opts.seriesIndex][opts.dataPointIndex]} Stores (${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].perc})% currently have <br> this inventory <span class="dot" style="color:${opts.w.globals.colors[opts.seriesIndex]};">${opts.w.config.series[opts.seriesIndex].name}</span>`;
                  return iAF;
                }
              },
              title: {
                formatter: function (val) {
                  if (val) return '';
                }
              }
            },
            marker: {
              show: false,
            },
            enabled: true,
            showForNullSeries: false,
            onDatasetHover: {
              highlightDataSeries: false,
            },
            style: {
              fontSize: '11px',
              fontFamily: "Roboto, Helvetica, Arial , sans-serif"
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: 4,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            },
            formatter: function (val, opts) {
              return val === 0 ? '' : val;
            },
          },
          annotations: {
            yaxis: [
              {
                y: max_marketSeries*2,
                borderColor: '#68E497',
              }
            ]
          },
          plotOptions: {
            bar: {
             // borderRadius: 0,
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              },
              columnWidth: '25%',
              barHeight: '50%',
            },
          },
          grid: {
            show: false
          },
          yaxis: {
            max:  max_marketSeries*2,
            labels: {
              formatter: function (value) {
                let val = value === null || value === undefined ? "N/A" : value.toFixed(0);
                return val;
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            showForNullSeries: false,
            showForZeroSeries: true,
            markers: {
              radius: 13,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 20,
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          xaxis: {
            show: true,
            labels: {
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value;
                return val;
              },
              show: true,
              hideOverlappingLabels: false,
              trim: true,
              rotate: 0,
              style: {
                colors: '#010101',
                fontSize: '10px'
              },
              
            },
            axisBorder: {
              show: true
          },
          axisTicks: {
            show: true
          }
          },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: marketSeries
      })
    } else {
      this.setState({
        options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            show: false,
            labels: {
              show: false
            },
            axisBorder: {
              show: false
          },
          axisTicks: {
            show: false
          },
            categories: []
          },
        },
        series: []
      })
    }
  }

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };
  handleChangeRowsPerPageTrack = (event) => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  //Table Sorting
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    let sort = isDesc ? "asc" : "desc";
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (orderBy == "storeName") {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }
  downLoadPDF() {
    this.setState({
      // isLoading: true,
      pdfLoading: false,
    });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = "1";
    let usId = parseInt(this.props.match.params.userstoreid);
    let accessKey = localStorage.getItem("SecretKey");
    let orderBy =
      this.state.orderBy == "storeName"
        ? "storeName"
        : this.state.uniqueUnitType.indexOf(this.state.orderBy);
    // let orderBy = this.state.orderBy;
    let order = this.state.order;
    let searchId = "1";
    let unitType = "1";
    let priceType = "1";
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "InventoryAvailability" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.storeAddress &&
        this.state.storeAddress.storeName &&
        this.state.storeAddress.storeName !== null &&
        this.state.storeAddress.storeName !== "" &&
        this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "InventoryAvailability" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 4;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  render() {
    const {
      overViewInfo,
      inventoryAvailablityList,
      storeNeverOff,
      page,
      pageCount,
      pageCountMDT,
      storeAddress,
      isLoading,
      pdfLoading,
      trackModalData,
      max,
      order,
      orderBy,
      uniqueUnitType,
      invStore,
      uniqueSID,
      invStores,
      snackOpen,
      snackMSg,
    } = this.state;
    const defaultProps = {
      helpTitle: "Inventory Availability",
      helpContent:
        "<p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, their inventory availability for that unit type goes down. If a store adds a unit type to their website, their inventory availability goes up.</p><p>Here you can see the current Inventory Availability across the entire Market, as well as a breakdown of current Inventory Availability by store type - Your Store, REITs, Multi-store and Single-store operators.</p><h6>Inventory Availability for the Market</h6><p>The aggregate number of On-Market/Stores Offering any unit type in this Market</p><h6>Inventory Availability for Your Store</h6><p>How many Unit Types Your Store has offered  in the past vs. how many of them are currently being offered (or are 'On-Market'). For example, let's say Your Store has offered/advertised 8 different Unit Types but currently has only 6 of them on-market (and by definition 2 are off-market), then 'Your Store' Inventory Availability is 75%, i.e. 6÷8)</p><h6>Inventory Availability for REITs, Mid-Ops, Single-Ops</h6><p>This is the same calculation as explained in 'Your Store' above, but calculated for each store group (REIT, Multi and Single operators). For example, if there are 3 REITs in the Market and two are offering 6 of 8 unit types and one of them is offering 5 of 8 unit types, then the aggregate Inventory Availability for REITs is (((6÷8)+(6÷8)+(5÷8))/3) x 100 = 70.8%</p><p><i>*Your store inventory is included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/1yTtSFctpcE' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps1 = {
      helpTitle: "Inventory Supply",
      helpContent:
        "<p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, the inventory availability for that unit type goes down. If a store adds a unit type to their website, the inventory availability for that unit type goes up. Here you will see a breakdown of the number of stores offering vs. the number of stores which have never offered each of the most common unit types.</p><p><i>*Your store inventory is included in the calculations.</i></p>",
    };
    const defaultProps2 = {
      helpTitle: "Inventory Availability by Unit Type",
      helpContent:
        "<p>This graph is a deeper analysis of the Inventory Supply you see above. It further analyzes for each unit type, how many out of all the stores in the Market which have advertised this unit type at some point, the number of stores <i>currently</i> offering/advertising this unit type vs. the number of stores (which have advertised it in the past) are <i>currently not</i> offering this unit type.</p><p><i>*Your store inventory is included in the calculations.</i></p>",
    };
    const defaultProps3 = {
      helpTitle: "Inventory Status",
      helpContent:
        "<p>This table is a continuation of the Inventory Supply graph you see above. It shows, for each unit type, how many out of all the stores in the Market which have advertised this unit type at some point, the number of stores currently offering/advertising this unit type vs. the number of stores (which have advertised it in the past) are currently not offering this unit type.</p><p><i>*Your store inventory is included in the calculations.</i></p>",
    };
    let uTypeLen = uniqueUnitType.length;
    return (
      <div className="InventoryAvailability">
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <Breadcrumbs
          separator="›"
          aria-label="Breadcrumb"
          className="breadcrumb-float-left breadcrumb-top"
        >


          {localStorage.getItem("isMVP_User") === "false" ? (
            <Link to="/my-market/" color="inherit">
              My Markets
            </Link>
          ) : (
            ""
          )}

          <Link to={`/market-dashboard/${this.props.match.params.userstoreid}`} color="inherit" >Go to Market Dashboard</Link>

          <Typography component="h6" className="link-tag">
            Inventory Availability
          </Typography>
        </Breadcrumbs>
        <Button
          disabled={!isLoading && invStores.length > 0 ? false : true}
          className={
            invStores.length > 0
              ? "apply-btn download-pdf "
              : "download-pdf-disabled"
          }
          onClick={this.downLoadPDF.bind(this)}
        >
          <FontAwesomeIcon icon={faFilePdf} />
          PDF Download
        </Button>

        <div className="clearfix"> </div>
        <Row className="overview-header mt-2">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row className="ht-100">
                <Col sm="6" className="padding-right-8">
                  <h2 className="only-iventory-head">
                    Inventory Availability <HelpTextPopup parm={defaultProps} />
                  </h2>
                  {isLoading ? (
                    ""
                  ) : storeAddress.compsCount === 0 ||
                    storeAddress.compsCount === null ? (
                    <div className="align-stores-btm">
                      <p>Analyzing N/A</p>
                    </div>
                  ) : (
                    <div className="align-stores-btm">
                      <p>
                        Analyzing{" "}
                        <Link to="#" onClick={() => this.toggle()}>
                          {storeAddress.compsCount
                            ? parseInt(storeAddress.compsCount) > 1
                              ? storeAddress.compsCount + " stores"
                              : storeAddress.compsCount + " store"
                            : "N/A"}
                        </Link>
                      </p>
                    </div>
                  )}
                </Col>
                {isLoading === false || pdfLoading === false ? (
                  <Col sm="6" className="pl-0">
                    <h3>
                      {storeAddress.storeName
                        ? storeAddress.storeName
                        : isLoading === false
                          ? "Your Store"
                          : ""}
                    </h3>
                    <div
                      className={
                        storeAddress && storeAddress.storeZoneCoverage
                          ? storeAddress.storeZoneCoverage.length > 24
                            ? "pd-btm-35"
                            : "pd-btm-20"
                          : "pd-btm-20"
                      }
                    >
                      <p className="m-0">
                        {storeAddress.storeAddress1 &&
                          storeAddress.storeAddress1 !== null &&
                          storeAddress.storeAddress1 !== ""
                          ? storeAddress.storeAddress1 + ", "
                          : ""}{" "}
                        {storeAddress.storeAddress2
                          ? storeAddress.storeAddress2 + ", "
                          : ""}{" "}
                        {storeAddress && storeAddress.storeCity
                          ? storeAddress.storeCity + ", "
                          : storeAddress.searchType === 1 &&
                            (storeAddress.storeCity === null ||
                              storeAddress.storeCity === "") &&
                            (storeAddress.storeZipCode === null ||
                              storeAddress.storeZipCode === "")
                            ? storeAddress.latitude +
                            ", " +
                            storeAddress.longitude +
                            ", "
                            : ""}
                        {storeAddress.storeState ? storeAddress.storeState : ""}{" "}
                        {storeAddress.storeZipCode}
                      </p>
                    </div>
                    <div className="align-stores-btm">
                      <p className="m-0 pt-1">
                        {"Coverage: " + storeAddress.storeZoneCoverage}
                      </p>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Col>
          <Col md="7" className="padding-left-0">
            <Col
              md="12"
              className="price-top-right border-box shadow-sm rounded"
            >
              <Row className="height-full">
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="market-label">Market</div>
                    <div className="market-value">
                      {overViewInfo.market
                        ? overViewInfo.market + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                    <div className="btm-label">Current</div>
                  </div>
                </Col>
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Your Store</div>
                    <div className="other-values">
                      {overViewInfo.myStore
                        ? overViewInfo.myStore + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
                {storeAddress.countryCode == "US" ? (
                  <Col className="common-col height-full">
                    <div className="middle-div">
                      <div className="other-labels">REIT's</div>
                      <div className="other-values">
                        {overViewInfo.reits
                          ? overViewInfo.reits + "%"
                          : isLoading === false
                            ? "N/A"
                            : ""}
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Multi-Ops</div>
                    <div className="other-values">
                      {overViewInfo.multiOps
                        ? overViewInfo.multiOps + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Single-Ops</div>
                    <div className="other-values">
                      {overViewInfo.singleOps
                        ? overViewInfo.singleOps + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>

        <Paper className="dashboard-layout last-months">
          <div className="request-free-trial inventory-tables">
            <Col>
              <h5 className="bill-head pt-2">
                Inventory Supply <HelpTextPopup parm={defaultProps1} />
              </h5>

              <div className="clear"></div>
              <div className="overflow-scroll-table br-0">
                <Paper className="bx-sh-0">
                  {storeNeverOff.length > 0 ? (
                    <Table
                      className={
                        "table custom-table table-bordered inventory-table inventory-status"
                      }
                    >
                      <TableHead>
                        <TableRow className="table-header">
                          <TableCell className="getthwidth"></TableCell>
                          {inventoryAvailablityList.length > 0
                            ? inventoryAvailablityList.map((data) => {
                              return (
                                <TableCell className="secondthwidth">
                                  {data.unitType}
                                </TableCell>
                              );
                            })
                            : ""}
                        </TableRow>
                      </TableHead>
                      {inventoryAvailablityList.length > 0 ? (
                        <TableBody>
                          <TableRow className="table-body">
                            <TableCell>
                              {"Stores With this Unit Type "}
                            </TableCell>
                            {inventoryAvailablityList.length > 0
                              ? inventoryAvailablityList.map((data) => {
                                return (
                                  <TableCell>
                                    {data.storesWithUnitType}
                                    <span className="gray-color">
                                      ({data.storesWithUnitTypePercent}%)
                                    </span>
                                  </TableCell>
                                );
                              })
                              : ""}
                          </TableRow>
                          <TableRow className="table-body">
                            <TableCell>{"Stores Never Offered "}</TableCell>
                            {inventoryAvailablityList.length > 0
                              ? inventoryAvailablityList.map((data) => {
                                return (
                                  <TableCell>
                                    {data.storesNeverOffered}
                                    <span className="gray-color">
                                      ({data.storesNeverOfferedPercent}%)
                                    </span>
                                  </TableCell>
                                );
                              })
                              : ""}
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell className="no-data">
                              {isLoading === false ? "No Records Found" : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    <div className="no-data inventory-nodata">
                      {isLoading === false ? "No Data Available" : ""}
                    </div>
                  )}
                </Paper>
              </div>
            </Col>
          </div>
          <div className="App pb-2">
            <h4>
              Inventory Availability by Unit Type{" "}
              <HelpTextPopup parm={defaultProps2} />
            </h4>

            <div className="clear"></div>
            <div className={max === 0 ? "fix-map price-map" : "price-map"}>
              {
                // max === 0 ? (
                //   <div className="no-data">
                //     {" "}
                //     {isLoading === false ? "No Data Available" : ""}
                //   </div>
                // ) : 
                (
                  <>
                    <div
                      id="printDiv"
                      className="viz-portfolio-delinquent-status inventory-graph"
                    >
                      <Chart className="invAvaApexChart" options={this.state.options} series={this.state.series} type="bar" style={{ width: "100%", overflow: 'hidden' }} height={320} />
                    </div>
                  </>
                )}
            </div>
          </div>
          <div className="request-free-trial inventory-tables">
            <Col>
              <h5 className="bill-head">Inventory Status </h5>

              <div className="clear"></div>
              <div className="overflow-scroll-table br-0">
                <Paper className="bx-sh-0">
                  {storeNeverOff.length > 0 ? (
                    <Table
                      className={
                        "table custom-table table-bordered inventory-table inventory-status"
                      }
                    >
                      <TableHead>
                        <TableRow className="table-header">
                          <TableCell></TableCell>
                          {inventoryAvailablityList.length > 0
                            ? inventoryAvailablityList.map((data) => {
                              return <TableCell>{data.unitType}</TableCell>;
                            })
                            : ""}
                        </TableRow>
                      </TableHead>
                      {inventoryAvailablityList.length > 0 ? (
                        <TableBody>
                          <TableRow className="table-body">
                            <TableCell>{"Off Market "}</TableCell>
                            {inventoryAvailablityList.length > 0
                              ? inventoryAvailablityList.map((data) => {
                                return (
                                  <TableCell>
                                    <b className="inventory-Bold">
                                      {data.offMarket}
                                    </b>
                                    <span className="red-color">
                                      ({data.offMarketPercent}%)
                                    </span>
                                  </TableCell>
                                );
                              })
                              : ""}
                          </TableRow>
                          <TableRow className="table-body">
                            <TableCell>{"On Market "}</TableCell>
                            {inventoryAvailablityList.length > 0
                              ? inventoryAvailablityList.map((data) => {
                                return (
                                  <TableCell>
                                    {data.onMarket}
                                    <span className="green-color">
                                      ({data.onMarketPercent}%)
                                    </span>
                                  </TableCell>
                                );
                              })
                              : ""}
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell className="no-data">
                              {isLoading === false ? "No Records Found" : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    <div className="no-data inventory-nodata">
                      {isLoading === false ? "No Data Available" : ""}
                    </div>
                  )}
                </Paper>
              </div>
            </Col>
          </div>
          <div className="request-free-trial inventory-tables">
            <Col>
              <h5 className="bill-head">Inventory by Store </h5>
              {invStores.length > 0 ? (
                <div className="inventory-legend">
                  <span>
                    <FontAwesomeIcon icon={faCheck} className="green-clr" />{" "}
                    Currently Advertising this Unit Type
                  </span>
                  <span className="close-ic">
                    {" "}
                    <FontAwesomeIcon icon={faTimes} className="red-clr" /> Not
                    Currently Advertising this Unit Type
                  </span>
                  {/* <span className="close-ic">   <FontAwesomeIcon icon={faEyeSlash}  /> Never offered in this unit type</span> */}
                </div>
              ) : (
                ""
              )}
              <div className="clear"></div>

              <div className="ms_table pd-3 display-grid">
                <ReactTable
                  className={
                    "table custom-table table-bordered inventory-table inventory-status tableinventorystore"
                  }
                  responsive="true"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={uniqueSID ? uniqueSID.length : 0}
                    rowHeader={uniqueUnitType}
                  />
                  {invStores && invStores.length > 0 ? (
                    <tbody>
                      {invStores.length > 0 ? (
                        this.stableSort(
                          invStores,
                          this.getSorting(order, orderBy)
                        ).map((row, i) => {
                          return (
                            <tr id={i} key={i} className="disabled_color">
                              <td
                                align="center"
                                className="ms_action tablestorename"
                              >
                                {" "}
                                <div className="tablestorenamein">
                                  {row.storeName}
                                </div>{" "}
                                <span> {row.storeAddress}</span>
                              </td>

                              {Object.values(row)
                                .splice(3, uTypeLen)
                                .map((val) => (
                                  <td align="center" className="ms_action">
                                    {" "}
                                    {val == 1 ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="green-clr"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        className="red-clr"
                                      />
                                    )}
                                  </td>
                                ))}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="disabled_color na_row">
                          <td>{isLoading === false ? "" : "N/A"}</td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="no-data" colSpan="9">
                          {isLoading === false && this.state.invStoreStatus ? (
                            "No Data Available"
                          ) : (
                            <div className="loader-wrap">
                              <div className="loading"></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </ReactTable>
              </div>
            </Col>
          </div>
        </Paper>
        <div>
          <Modal
            isOpen={this.state.modal}
            backdrop="static"
            className={"store-mainpopup" + this.props.className}

          >
            <ModalHeader toggle={this.toggle}>
              {storeAddress.storeName}
            </ModalHeader>
            <ModalBody>
              <Table className="table custom-table table-bordered store-count-popup">
                <TableHead>
                  <TableRow>
                    <TableCell>COMPETITOR NAME</TableCell>
                    <TableCell>ADDRESS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trackModalData ? (
                    trackModalData
                      .slice(pageCount * 10, pageCount * 10 + 10)
                      .map((trackData) => (
                        <TableRow key={trackData.rec_num}>
                          <TableCell>{trackData.storeName}</TableCell>
                          <TableCell>{trackData.storeAddress}</TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow></TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1]}
                      colSpan={3}
                      count={trackModalData ? trackModalData.length : 0}
                      rowsPerPage={10}
                      page={pageCount}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={this.handleChangePageTrack}
                      onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                      ActionsComponent={MyMarketWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </ModalBody>
          </Modal>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose.bind(this)}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose.bind(this)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackMSg}</span>}
        />
      </div>
    );
  }
}
InventoryAvailability.propTypes = {
  // classes: PropTypes.object.isRequired,
};

//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, rowHeader } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  let headRows =
    rowHeader.length > 0
      ? rowHeader.map((item, i) => {
        return {
          // id: item.replace(/\s/g, ''),
          id: item,
          numeric: false,
          disablePadding: false,
          label: item,
        };
      })
      : [];

  if (rowHeader.length > 0) {
    headRows.unshift({
      id: "storeName",
      numeric: false,
      disablePadding: true,
      label: "storeName",
    });
  }

  return (
    <TableHead
      className={
        "table custom-table table-bordered inventory-table inventory-status"
      }
    >
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="center"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            // disabled={row.id !== 'moreoptions' ? false : true}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
