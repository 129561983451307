import { LinkCell, CalEffPrice, CalAdvPrice} from "./cells";
import { Table, Column, DataCell, Cell } from "fixed-data-table-2";
import React from "react";
import "./EffectiveRateCal.css";
import "fixed-data-table-2/dist/fixed-data-table.css";
import { CONFIG } from "../../utils/config";
let columnTitles = {
  storeName: "Store Name",
  priceOptionName: "Price Option ",
  floorType: "Floor",
  advertisedPrice: "Advertised Price",
  promotion: "Promotion",
  m1: "1st month",
  m2: "2nd month",
  m3: "3rd month",
  m4: "4th month",
  m5: "5th month",
  m6: "6th month",
  m7: "7th month",
  m8: "8th month",
  m9: "9th month",
  m10: "10th month",
  m11: "11th month",
  m12: "12th month",
  effectiveRate: "Effective Rate",
};


class EffectiveRateCal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataList: [],
      columnOrder: Object.keys(columnTitles),
      columnOrder1: Object.keys(columnTitles),
      drpDownSel: this.props.drpDownSel,
      collapsedRows: new Set(),
      scrollToRow: null,
      gridWidth: window.innerWidth - 290,
      currency:this.props.currency,
    };
    this._handleColumnHide = this._handleColumnHide.bind(this);
    this.rowHeightGetter = this.rowHeightGetter.bind(this);
  }

  rowHeightGetter(index) {
    var nHeight = 50,
      oRow = this.state.dataList[index];
    nHeight += (oRow.items.length - 1) * 15;

    return nHeight;
  }

  _handleColumnHide(columnKey) {
    let newColumnOrder = this.state.columnOrder1.filter((x) => {
      return columnKey === 3
        ? x !== "m4" &&
            x !== "m5" &&
            x !== "m6" &&
            x !== "m7" &&
            x !== "m8" &&
            x !== "m9" &&
            x !== "m10" &&
            x !== "m11" &&
            x !== "m12"
        : columnKey === 6
        ? x !== "m7" &&
          x !== "m8" &&
          x !== "m9" &&
          x !== "m10" &&
          x !== "m11" &&
          x !== "m12"
        : columnKey === 9
        ? x !== "m10" && x !== "m11" && x !== "m12"
        : x;
    });
    this.setState({
      columnOrder: newColumnOrder,drpDownSel: columnKey,
    });
  }


  //price conversion
  priceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }

  updateDimensions = () => {
    this.setState({ gridWidth: window.innerWidth - 290});
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var { drpDownSel, gridWidth, currency } = this.state;
    let dataList =
      this.props.effData && this.props.effData.length > 0
        ? this.props.effData
        : [];
    return (
      gridWidth !== null ? 
      <div className="detailed-view-table">
        <Table
          className="table table-bordered custom-table"
          rowHeight={60}
          headerHeight={40}
          rowsCount={dataList.length}
          // rowHeightGetter={(rowIndex) => {
          //   if (dataList[rowIndex].address || dataList[rowIndex].promotion) {
          //     let cmprValue =
          //       dataList[rowIndex].address.length >
          //       dataList[rowIndex].promotion.length
          //         ? dataList[rowIndex].address.length * 3
          //         : dataList[rowIndex].promotion.length * 3;

          //     return Math.max(66, cmprValue);
          //   }
          // }}
          width={gridWidth}
          maxHeight={500}
          {...this.props}
        >
          {dataList &&
            dataList.length > 0 &&
            this.state.columnOrder.map(function (columnKeys, i) {
              return (
                <Column
                  columnKey={columnKeys}
                  key={i}
                  header={columnTitles[columnKeys]}
                  cell={
                    columnKeys === "m1" ||
                    columnKeys === "m2" ||
                    columnKeys === "m3" ||
                    columnKeys === "m4" ||
                    columnKeys === "m5" ||
                    columnKeys === "m6" ||
                    columnKeys === "m7" ||
                    columnKeys === "m8" ||
                    columnKeys === "m9" ||
                    columnKeys === "m10" ||
                    columnKeys === "m11" ||
                    columnKeys === "m12" ? (
                      <LinkCell data={dataList} currency={currency}/>
                    ) : columnKeys === "advertisedPrice" ? ( <CalAdvPrice currency={currency}
                        data={dataList}
                      />
                      
                    ) : columnKeys === "promotion" ? (
                      ({ rowIndex, columnKey, ...props }) => {
                        let ys_prom_tions =
                          dataList[rowIndex][columnKey] &&
                          dataList[rowIndex][columnKey] !== null
                            ? dataList[rowIndex][columnKey].replace(
                                /~/g,
                                "<hr/>"
                              )
                            : "No specials";

                        return (
                          <DataCell {...props} className={dataList[rowIndex].isMyStore ? "disabled_color" : ''}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ys_prom_tions,
                              }}
                            />
                          </DataCell>
                        );
                      }
                    ) : columnKeys === "effectiveRate" ? (
                      <CalEffPrice
                        data={dataList}
                        drpDownSelValue={drpDownSel}
                        currency={currency}
                      />
                    ) : (
                      ({ rowIndex, columnKey, ...props }) => {
                        return (
                          columnKey === "storeName" ? dataList[rowIndex].storeName !== '' ?
                          <Cell {...props} className={dataList[rowIndex].isMyStore ? "disabled_color" : ''} >
                            <h5>{dataList[rowIndex].storeName}</h5>
                                <p class="stores-address current-pr-store mb-0">
                                  {dataList[rowIndex].address}
                                </p>
                                </Cell> : <Cell className={dataList[rowIndex].isMyStore ? "disabled_color" : ''} style={{width:'100%', height:'100%'}}>
                                </Cell>
                            :  <Cell {...props} className={dataList[rowIndex].isMyStore ? "disabled_color" : ''}>
                             { dataList[rowIndex][columnKey]}
                            
                          </Cell>
                        );
                      }
                    )
                  }
                  fixed={i < 5}
                  width={
                    columnKeys === "storeName"
                      ? 220
                      : columnKeys === "promotion"
                      ? 200
                      : drpDownSel === 9 && window.innerWidth > 1700 ? 140 : drpDownSel === 6 && (window.innerWidth > 1600 && window.innerWidth < 1700) ? 130 : drpDownSel === 3 && window.innerWidth > 1700 ? 200 : drpDownSel === 6 && window.innerWidth > 1700 ? 170 : drpDownSel === 3 && window.innerWidth > 1600 ? 180 : (drpDownSel === 3 || drpDownSel === 6) && window.innerWidth > 1200 && (drpDownSel === 3 || drpDownSel === 6) && window.innerWidth <= 1600 ? 120 : 93
                  }
                />
              );
            })}
        </Table>
        </div>
    : ''
    );
  }
}

export default React.memo(EffectiveRateCal);
