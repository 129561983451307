import React, { Fragment } from 'react';
import './MapViewPdf.css';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { CONFIG } from './../../../utils/config';
import homeIcon from './../../../Assets/Images/home-icon-circle.png';
import Logo from '../../../Assets/Images/logo.jpg';
import MapGL, { Layer, Source, Marker, FlyToInterpolator, WebMercatorViewport } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": ["get", "colorCode"],
  },
};

export default class MapViewsPdf extends React.Component {
  static defaultProps = {
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.GoogleMapsApiKey + '',
  }
  constructor(props) {
    super(props);
    this.state = {
      mapData: [],
      isLoading: false,
      accessKey: '',
      locationInfoData: {},
      analysisSummary: [],
      viewport: {
        // width: "100%",
        // height: "100vh",
        longitude: 0,
        latitude: 0,
        zoom: 10,
      },
      homeLatLng: { longitude: 0, latitude: 0 }
    };
    this.mapRef = React.createRef();
  }

  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.setState({ accessKey: response.data.token })
      this.state.accessKey = response.data.token;
      this.initialLoad()
      this.loadSummary()
    } else {
      alert(response.status.Message);
    }
  }
  loadSummary() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": parseInt(this.props.usId),
      "SearchID": parseInt(this.props.pta),
      "RegionID": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/currentpricinganalysissummary', data)
      .then(response => {
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  getBoundingValues = (points) => {
    if (points.length) {
      const paddingLength = window.innerHeight / 3;
      const applyToArray = (func, array) => func.apply(Math, array);
      const pointsLong = points.map((point) => point.geometry.coordinates[0]);
      const pointsLat = points.map((point) => point.geometry.coordinates[1]);
      if (!pointsLong.includes(this.state.homeLatLng.longitude)) pointsLong.push(this.state.homeLatLng.longitude);
      if (!pointsLat.includes(this.state.homeLatLng.latitude)) pointsLat.push(this.state.homeLatLng.latitude);
      const cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ];
      const viewport = new WebMercatorViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      }).fitBounds(cornersLongLat, {
        padding: { top: paddingLength, bottom: paddingLength, left: paddingLength, right: paddingLength },
      });
      const { longitude, latitude, zoom } = viewport;
      this.setState(prevState => {
        return {
          viewport: {
            ...prevState.viewport,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionDuration: 100,
            transitionInterpolator: new FlyToInterpolator(),
          }
        }
      });
    }
  };

  mapView() {
    localStorage.setItem('StId', this.props.usId);
    const data = {
      "UserStoreID": parseInt(this.props.usId),
      "SearchID": parseInt(this.props.pta),
      "RegionID": CONFIG.RegionId,
      "IsMapBox": CONFIG.IsMapbox
    }
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelmapview', data)
      .then(res => {
        let mapData = res.data.data.unitLevelDatas;
        this.setState({ mapData, isLoading: false }, () => this.getBoundingValues(mapData));
      })

      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": parseInt(this.props.usId),
      "SearchID": parseInt(this.props.pta),
      "RegionID": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'unit/dashboardSearchlocation', data)
      .then(response => {
        // let zoomValue;
        const locationInfoData = response.data.data.unitDashboard;
        // const centerPoint = response.data.data.centerPoints;
        // if (locationInfoData.searchType === 3) {
        //   zoomValue = 8;
        // } else if (locationInfoData.searchType === 2) {
        //   let zCnt = locationInfoData.storeZoneCoverage != "" && locationInfoData.storeZoneCoverage != null ? (locationInfoData.storeZoneCoverage.split(",").length - 1) : 1;
        //   zoomValue = (zCnt > 1) ? 9 : (zCnt > 0) ? 10 : 11;
        // } else if (locationInfoData.searchType === 1) {
        //   let storeZoneCoverage = locationInfoData.storeZoneCoverage.split(' ');
        //   zoomValue = (storeZoneCoverage[0] === '1' || storeZoneCoverage[0] === '2' || storeZoneCoverage[0] === '3') ? 13 : (storeZoneCoverage[0] === '4' || storeZoneCoverage[0] === '5' || storeZoneCoverage[0] === '6') ? 12 : (storeZoneCoverage[0] === '7' || storeZoneCoverage[0] === '8' || storeZoneCoverage[0] === '9' || storeZoneCoverage[0] === '10') ? 10 : 6;
        // }
        let lat = parseFloat(this.props.lat);
        let lng = parseFloat(this.props.lng);
        // let lat = parseFloat(centerPoint && (centerPoint.length > 0 && centerPoint[0].latitude != null) ? centerPoint[0].latitude : this.props.lat);
        // let lng = parseFloat(centerPoint && (centerPoint.length > 0 && centerPoint[0].longitude != null) ? centerPoint[0].longitude : this.props.lng);

        this.setState(prevState => ({
          locationInfoData: locationInfoData,
          viewport: {
            ...prevState.viewport,
            longitude: lng,
            latitude: lat,
            // zoom: zoomValue
          },
          homeLatLng: { longitude: Number(lng), latitude: Number(lat) }
        }), () => this.mapView());
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }
  initialLoad() {
    this.setState({ isLoading: true });
    this.loadDashBoard();
  }

  handleViewportChange = (viewport) => {
    this.setState({ viewport });
  }

  render() {
    const { analysisSummary, mapData, locationInfoData, isLoading, viewport, homeLatLng } = this.state;
    const defaultProps = {
      helpTitle: "Map View",
      helpContent: "<p>The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store. </p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    let cutCur = locationInfoData.countryCurrency ? locationInfoData.countryCurrency : 'N/A';
    let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if (clp) {
      var splt_clp = clp.toString().split('.');
      let splt_val_clp = splt_clp[1] ? splt_clp[1].length === 1 ? '.' + splt_clp[1] + '0' : splt_clp[1].length === 2 ? '.' + splt_clp[1] : '' : '';
      clp = cutCur + splt_clp[0] + splt_val_clp;
    }
    if (cap) {
      var splt_cap = cap.toString().split('.');
      let splt_val_cap = splt_cap[1] ? splt_cap[1].length === 1 ? '.' + splt_cap[1] + "0" : splt_cap[1].length === 2 ? '.' + splt_cap[1] : '' : '';
      cap = cutCur + splt_cap[0] + splt_val_cap;
    }
    if (chp) {
      var splt_chp = chp.toString().split('.');
      let splt_val_chp = splt_chp[1] ? splt_chp[1].length === 1 ? '.' + splt_chp[1] + '0' : splt_chp[1].length === 2 ? '.' + splt_chp[1] : '' : '';
      chp = cutCur + splt_chp[0] + splt_val_chp;
    }
    const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
    return (
      <div className="mapview-pdf">
        <div className="header mapview-header"> <img src={Logo} /><div className="float-right-name">
          {/* <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ': ''}{locationInfoData.storeState + ' ' + locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p> */}
          <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0 address-font">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''}{locationInfoData.storeState ? locationInfoData.storeState + ' ' : ''}{locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p>
          </div>
        </div>


        <div className="wrapper my-account-box mapview-body">
          <h4 className="mb-3">{'(' + locationInfoData.searchName + ')'} Map View</h4>
          <Row className="height-full">
            <div className="sep-box">
              <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Lowest Weekly Price" : "Current Lowest Price"}</h4>
              <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.lowestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
              <div className="align-stores-btm"><h5>{clp ? clp : isLoading === false ? 'N/A' : ''} </h5></div>
            </div>
            <div className="sep-box current-avg-height">
              <h4 className="text-left" style={!cap && isLoading === false ? { height: 'auto' } : { position: 'relative' }}>{CONFIG.RegionId === 3 ? "Current Average Weekly Price" : "Current Average Price"}</h4>
              {/* <div className="pd-btm-20"><p></p></div> */}
              <div className="align-stores-btm"><h5>{cap ? cap : isLoading === false ? 'N/A' : ''} </h5></div>
            </div>
            <div className="sep-box right-none">
              <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Highest Weekly Price" : "Current Highest Price"} </h4>
              <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.highestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
              <div className="align-stores-btm"><h5>{chp ? chp : isLoading === false ? 'N/A' : ''} </h5></div>
            </div>
            <div className="clear"></div>
          </Row>
          {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
          <div className="mt-3 is-it-store">
            <p>
              The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store.
            </p><p className="mb-3">*Your store prices are included in the calculations.
            </p>
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row className="mt-2">
                <Col md="12">
                  <Fragment>
                    <div className="map-view-height">
                      <MapGL
                        {...viewport}
                        ref={this.mapRef}
                        onViewportChange={this.handleViewportChange}
                        mapboxApiAccessToken={CONFIG.REACT_APP_MAPBOX_TOKEN}
                        mapStyle={"mapbox://styles/mapbox/streets-v11"}
                        attributionControl={false}
                        preserveDrawingBuffer={true}
                        onLoad={() => {
                          let mapbox = this.mapRef.current.getMap();
                          mapbox.loadImage(homeIcon, (error, image) => {
                            if (error) throw error;
                            mapbox.addImage('homeMarker', image);
                          });
                        }}
                      >
                        {/* {homeLatLng.latitude !== 0 && homeLatLng.longitude !== 0 &&
                          (<Marker {...homeLatLng} offsetLeft={-10} offsetTop={-12}>
                            <img src={homeIcon} alt="icon" />
                          </Marker>)} */}
                        {/* {homeLatLng.latitude !== 0 && homeLatLng.longitude !== 0 && */}
                        <Source
                          type="geojson"
                          data={{
                            'type': 'FeatureCollection',
                            'features': [{
                              'type': 'Feature',
                              'geometry': {
                                'type': 'Point',
                                'coordinates': [homeLatLng.longitude, homeLatLng.latitude]
                              }
                            }]
                          }}
                        >
                          <Layer
                            id="home-marker"
                            type="symbol"
                            layout={{
                              'icon-image': 'homeMarker',
                            }}
                          />
                        </Source>
                        <Source id="map-data" type='geojson' data={{ type: 'FeatureCollection', features: mapData }}>
                          {/* <Layer id='point' type='circle' paint={{ "circle-radius": 5, "circle-color": marker.properties.isUserStore == 1 ? "#f15d47" : marker.properties.isUserStore == 0 && marker.properties.storeStatus == 1 ? "#017ACC" : "#000" }} /> */}
                          <Layer {...layerStyle} />
                        </Source>
                      </MapGL>
                    </div>
                  </Fragment>
                </Col>
              </Row>
              <Col md="12" className="margin-2 map-btm-legend">
                <Row>
                  <div className="legend-block"><div className="maps-legend-orange rounded-circle"></div>Your Store</div>
                  <div className="legend-block"><div className="maps-legend-blue rounded-circle"></div>Competitor</div>
                  <div className="legend-block"><div className="maps-legend-black rounded-circle"></div>Rate Data Unavailable</div>
                </Row>
              </Col>
            </Col>
          </div>
        </div>
        <div className="footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>
      </div>
    );
  }
}
