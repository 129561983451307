import React from 'react';
import './ActiveReports.css'
import { Col, Row, Modal, ModalHeader, ModalBody, Button, FormText, Label } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import NumberFormat from 'react-number-format';
import EmailShare from 'react-email-share-link';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CONFIG } from '../../utils/config';
import { COMMON } from '../../utils/common';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Radio from '@material-ui/core/Radio';

export default class ActiveReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            isModalCheck: false,
            snackOpen: false,
            snackMsg: '',
            snackClr: 'snack-error',
            activityData: [],
            isEnabled: this.props.isGreyOut,
            isCompStatus: this.props.isCompStatus,
            reciepients: [],
            isLoading: false,
            isFreqAlert: false,
            isReportAlert: false,
            pdfName: '',
            activeId: null,
            selFrequency: 0,
            subChoice: "1",
            daysOut: 1,
            isDayAlert: false,
            activeStatus: null,
            formValid: true,
            dayOfMonth: "",
            showDays: "1",
            frequencyMonth: [],
            dayOutMonth: [],
            selectedDaysOut: [],
            isMyStore: null
        };
        this.activeReportSubmit = this.activeReportSubmit.bind(this);
        // this.snackOpen = this.snackOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFreChange = this.handleFreChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
    }

    activeModalOpen = () => {
        this.setState(state => ({ modal: !state.modal }));
        this.loadActiveData();
    }

    cancelActiveReports = () => this.setState(state => ({ modal: !state.modal }))

    componentDidMount() {
        if (typeof window.dataLayer != "undefined" && this.props.isActiveOpen) {
            window.dataLayer.push({
                "event": "show popup",
                'eventCategory': 'popup',
                'eventAction': 'show',
                'eventLabel': this.state.popupName,
            });
        }
    }

    // below the function for activate and deactivate report
    updateStatus = (active, comp, id) => {
        let data = {
            UserStoreId: id,
            ActivityReport: active == null || active == 4 ? 0 : 1,
            CompReport: comp == null || comp == 0 ? 0 : 1
        }
        this.setState({ activeId: data.ActivityReport });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'market/updateactivitycompreport', data)
            .then(response => {
                //this.setState({ reciepients: []});
                this.state.reciepients = [];
                if (this.props.isFrom == "LEFT_NAV") {
                    this.props.parentMethod(this.props.storeId);
                } else {
                    this.props.compRefresh();
                }
                // if (this.state.activeId == 1) {
                //     document.getElementById('btn-submit-form-active-comp').click();
                // } else {
                //     if (this.props.isFrom == "LEFT_NAV") {
                //         this.props.parentMethod(this.props.storeId);
                //     } else {
                //         this.props.compRefresh();
                //     }
                // }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    activeReportSubmit(event, errors, values) {
        let formValid = this.state.formValid;
        formValid = this.state.showDays == 3 && this.state.subChoice == 2 ? this.state.activityData.daysOutMonth.dayOfMonth != "" && this.state.activityData.daysOutMonth.dayOfMonth != null ? true : false : true;
        if (errors.length == 0 && formValid) {
            this.setState({ activeStatus: values.daysOut && values.daysOut != '' ? values.daysOut : 0 });
            const data = this.state.activityData;
            // validate data's
            let isFreqLength = this.state.showDays == 2 ? data.frequency.filter(d => d.value == 1) : [0];
            let isActivityLength = data.activity.filter(d => d.value == 1);
            let isInventoryLength = data.inventoryTrack.filter(d => d.value == 1);
            let israteTrendsLength = data.rateTrends.filter(d => d.value == 1);
            if ((isFreqLength.length > 0) && (isActivityLength.length > 0 || isInventoryLength.length > 0 || israteTrendsLength.length > 0)) {
                this.setState({ isLoading: true });
                let reciepients = [values.Recipient1, values.Recipient2, values.Recipient3, values.Recipient4, values.Recipient5];
                reciepients = reciepients.filter(item => item);
                data["info"] = {
                    "UserStoreId": this.props.storeId,
                    "RegionId": CONFIG.RegionId,
                    "Reciepients": reciepients.toString(),
                    "IsMyStore": this.state.isMyStore == true ? 1 : 0
                    // "Reciepients": [values.Recipient1,values.Recipient2,values.Recipient3,values.Recipient4,values.Recipient5].toString(),// "opt2@test.com",
                }
                let token = localStorage.getItem('accessKey');
                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }
                axios.post(CONFIG.API_URL + 'dashboard/updateActivityReport', data)
                    .then(response => {
                        this.setState({ modal: false, isLoading: false }, () => {
                            this.updateStatus(this.state.activeStatus, this.props.isCompStatus, this.props.storeId)
                            // if (this.props.isFrom == "LEFT_NAV") {
                            //     this.props.parentMethod(this.props.storeId);
                            // } else {
                            //     this.props.compRefresh();
                            // }
                        });
                        this.setState({ snackOpen: true, snackMsg: 'The changes have been saved Successfully', snackClr: 'snack-success' })
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        if (err.response.status === 401) {
                            localStorage.removeItem('accessKey');
                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                            this.props.history.push(from);
                        }
                        else {
                            alert(err.response.data);
                        }
                    });
            } else {
                if (isFreqLength.length == 0 && isActivityLength.length == 0 && isInventoryLength.length == 0 && israteTrendsLength.length == 0) {
                    this.setState({ isFreqAlert: true, isReportAlert: true });
                } else if (isFreqLength.length > 0 && isActivityLength.length == 0 && isInventoryLength.length == 0 && israteTrendsLength.length == 0) {
                    this.setState({ isFreqAlert: false, isReportAlert: true });
                } else if ((isFreqLength.length == 0) && (isActivityLength.length > 0 || isInventoryLength.length > 0 || israteTrendsLength.length > 0)) {
                    this.setState({ isFreqAlert: true, isReportAlert: false });
                } else {
                    this.setState({ isFreqAlert: false, isReportAlert: false });
                }
                document.getElementById("days").focus({ preventScroll: false });
            }
        } else {
            if (this.state.subChoice == 2 && this.state.dayOfMonth == "") {
                this.setState({ formValid: false });
            }
        }
    }

    //load activity report data
    loadActiveData() {
        this.setState({ isLoading: true });
        const data = {
            UserStoreId: this.props.storeId,
            RegionId: CONFIG.RegionId
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'dashboard/checkactivityreport', data)
            .then(response => {
                const activityData = response.data.data;
                let radioCheck = (activityData.daysOutMonth.dayOfMonth == 0 || activityData.daysOutMonth.dayOfMonth == '' || activityData.daysOutMonth.dayOfMonth == null) ? false : true;
                let selectedDaysOut = activityData.daysOut.filter(days => days.value == 1);
                let showDays = selectedDaysOut.length > 0 ? selectedDaysOut[0].id : 0;
                let dayOutMonth = activityData.daysOutMonth.daysOut.filter(days => days.value == 1);
                let frequencyMonth = activityData.daysOutMonth.frequency.filter(days => days.value == 1);
                let reciepients = response.data.data.info.reciepients != null ? response.data.data.info.reciepients.split(',') : [];
                let pdfName = response.data.data.info.activityReportSample;
                let myStore = response.data.data.info.isMyStore;
                this.setState({ activityData, reciepients, pdfName, isLoading: false, frequencyMonth, dayOutMonth, selectedDaysOut, showDays, subChoice: radioCheck ? '2' : '1', isMyStore: myStore == 1 ? true : false });
            }).catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

    }

    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    // snackOpen() {
    //     this.setState({ snackOpen: true, snackMsg: 'Activate by checking on My Markets Page' });
    // }
    handleChange(e, value) {
        let index;
        let activityData = this.state.activityData;
        if (e.target.checked) {
            index = activityData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                // if(e.target.name == "frequency"){
                //     activityData.daysOutMonth[e.target.name][index].value = 1;
                // }else{
                activityData[e.target.name][index].value = 1;
                // }

            }
        }
        else if (!e.target.checked) {
            index = activityData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                // if(e.target.name == "frequency"){
                //     activityData.daysOutMonth[e.target.name][index].value = 0;
                // }else{
                activityData[e.target.name][index].value = 0;
                // }
            }
        }
        this.setState({ activityData });
        let data = this.state.activityData;
        // validate data's
        let isFreqLength = data.frequency.filter(d => d.value == 1);
        let isActivityLength = data.activity.filter(d => d.value == 1);
        let isInventoryLength = data.inventoryTrack.filter(d => d.value == 1);
        let israteTrendsLength = data.rateTrends.filter(d => d.value == 1);
        if (isFreqLength.length == 0 && isActivityLength.length == 0 && isInventoryLength.length == 0 && israteTrendsLength.length == 0) {
            this.setState({ isFreqAlert: true, isReportAlert: true });
        } else if (isFreqLength.length > 0 && isActivityLength.length == 0 && isInventoryLength.length == 0 && israteTrendsLength.length == 0) {
            this.setState({ isFreqAlert: false, isReportAlert: true });
        } else if ((isFreqLength.length == 0) && (isActivityLength.length > 0 || isInventoryLength.length > 0 || israteTrendsLength.length > 0)) {
            this.setState({ isFreqAlert: true, isReportAlert: false });
        } else {
            this.setState({ isFreqAlert: false, isReportAlert: false });
        }
    }

    handleDayChange(e, value) {
        let index;
        let activityData = this.state.activityData;
        if (e.target.value != '') {
            index = activityData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                activityData[e.target.name][index].value = 1;
                activityData[e.target.name].forEach((val, i) => {
                    if (val.id != parseInt(e.target.value))
                        activityData[e.target.name][i].value = 0;
                });
            }
            this.setState({ showDays: e.target.value, isFreqAlert: false, isDayAlert: false });
        }
    }
    //selectedFrequency onChange event
    handleFreChange(e, value) {
        let index;
        let activityData = this.state.activityData;
        let isDayAlert = this.state.isDayAlert;
        //Check for No of days below 31
        if (e.target.name === "dayOfMonth") {
          e.target.value = e.target.value.replace(/[^\w\s]/gi, "");
          if (e.target.value.length > 2) {
            e.target.value = e.target.value.substring(
              0,
              Math.min(e.target.value.length, 2)
            );
          }
          if (parseInt(e.target.value) > 31 || e.target.value == "0") {
            e.target.value = "";
            isDayAlert = true;
          } else {
            isDayAlert = false;
          }
          // this.setState({isDayAlert});
          activityData.daysOutMonth[e.target.name] = e.target.value;
        } else {
          if (e.target.name === "dayOutMonth") {
            index = activityData.daysOutMonth["daysOut"].findIndex(
              (obj) => obj.id == parseInt(e.target.value)
            );
            if (index >= 0) {
              activityData.daysOutMonth["daysOut"][index].value = 1;
              activityData.daysOutMonth["daysOut"].forEach((val, i) => {
                if (val.id != parseInt(e.target.value))
                  activityData.daysOutMonth["daysOut"][i].value = 0;
              });
            }
          } else {
            index = activityData.daysOutMonth[e.target.name].findIndex(
              (obj) => obj.id == parseInt(e.target.value)
            );
            if (index >= 0) {
              activityData.daysOutMonth[e.target.name][index].value = 1;
              activityData.daysOutMonth[e.target.name].forEach((val, i) => {
                if (val.id != parseInt(e.target.value))
                  activityData.daysOutMonth[e.target.name][i].value = 0;
              });
            }
          }
        }
        this.setState({ formValid: true, isDayAlert });

    }

    //Daysout selection handlechange
    selectChoice(choice) {
        let selChoice;
        let activityData = this.state.activityData;
        if (choice == 1) {
            selChoice = 1;
            activityData.daysOutMonth.dayOfMonth = "";
        } else {
            selChoice = 2;
        }
        this.setState({ subChoice: selChoice, formValid: true });
    }

    render() {
        const { modal, isEnabled, snackOpen, activityData, reciepients, snackMsg, isFreqAlert, isReportAlert, snackClr, pdfName, selFrequency, subChoice, daysOut, isDayAlert, showDays, frequencyMonth, dayOutMonth, selectedDaysOut, isMyStore } = this.state;
        return (
            <div className="missing-Competitor">
                {/* <Link to="#" onClick={this.props.isGreyOut != 1 ? () => this.snackOpen() : this.activeModalOpen.bind(this)} className="go-back-btn list-item-text man-act-repo-menu"><span>Manage Activity Reports</span></Link> */}
                {this.props.isFrom == "LEFT_NAV" ?
                    <Link to="#" onClick={this.activeModalOpen.bind(this)} className="go-back-btn list-item-text man-act-repo-menu"><span>Manage Activity Reports</span></Link>
                    : this.props.isGreyOut == 1 ? <p onClick={this.activeModalOpen.bind(this)} className="setup-text sch"> Scheduled</p> : <p onClick={this.activeModalOpen.bind(this)} className="setup-text not-sch"> Set up </p>}
                <Modal backdrop="static" isOpen={modal} toggle={this.cancelActiveReports.bind(this)} id="missing-Competitor-modal" className={this.props.className} size="lg">
                    <ModalHeader Header toggle={this.cancelActiveReports.bind(this)} className="pb-1">Manage Activity Reports</ModalHeader >
                    <ModalBody className="misCompmodalBody">
                        <AvForm onSubmit={this.activeReportSubmit.bind(this)} className="enter-acc-details">
                            <Row className="margin-right-0 mb-0 activity-report-pop">
                                <Col lg={6} md={6} className="list-item-text p-t3-b5">
                                    <Row >
                                        <Col>
                                            {/* <h3 className="report-pop-title">When do you want to receive this report?<br /><span><i>(uncheck to exclude any day)</i></span></h3> */}
                                            {/* {isFreqAlert ? <p style={{ color: '#F15D47', opacity: '1' }}>Please select at least one day from the list below.</p> : ''} */}
                                            {/* <Row style={{margin: '0px', marginTop: '10px'}} className="weekly-list">
                                                {activityData.frequency && activityData.frequency.length > 0 ? activityData.frequency.map(data => {
                                                    return (
                                                        <Col xs={6} sm={4}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                checked={data.value == 1}
                                                                onChange={this.handleChange} 
                                                                name="frequency"
                                                                value={data.id}
                                                                id="frequency"
                                                                className="move-ch-top track-check-gl"
                                                                />}
                                                                 label={data.name}
                                                            /></Col>)
                                                }) : ''}
                                            </Row> */}
                                            <div className="weekly-list align-items-center">
                                                When do you want to receive this report?
                                                <Row style={{ marginTop: '5px' }} className="weekly-refresh">
                                                    <Col xs={6} sm={3} className="pr-0" style={{ marginTop: '5px' }}>
                                                        <RefreshIcon /> Repeat
                                                    </Col>
                                                    <Col xs={6} sm={4} className="pl-0">
                                                        <AvField type="select" name="daysOut" id="days"
                                                            value={activityData && selectedDaysOut.length > 0 ? selectedDaysOut[0].id : ''}
                                                            onChange={this.handleDayChange} >
                                                            {/* <option value='' >--Select DaysOut--</option> */}
                                                            {activityData.daysOut && activityData.daysOut.length > 0 ? activityData.daysOut.map((daysOut) =>
                                                                <option key={daysOut.id} selected={selectedDaysOut.length > 0 && selectedDaysOut[0].id == daysOut.id ? true : false} value={daysOut.id}>{daysOut.name}
                                                                </option>
                                                            ) : ''}
                                                        </AvField>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <p className="report-pop-note">Please note that Activity Reports are typically emailed between <br/>8-9am your local time</p> */}
                                        </Col>
                                    </Row>
                                    {showDays == 2 ?
                                        <Row style={{ margin: '0px', marginTop: '10px' }} className="weekly-list">Please select which day(s) to receive this report
                                            {activityData && activityData.frequency && activityData.frequency.length > 0 ? activityData.frequency.map(data => {
                                                return (
                                                    <Col xs={6} sm={4}>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={data.value == 1}
                                                                onChange={this.handleChange}
                                                                name="frequency"
                                                                value={data.id}
                                                                className="move-ch-top track-check-gl"
                                                            />}
                                                            label={data.name}
                                                        /></Col>)
                                            }) : ''}
                                            {isFreqAlert ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px', marginTop: '0px' }}>Please select at least one day from the list below.</p> : ''}
                                        </Row> : showDays == 3 ?
                                            <Col className="p-0 mt-2">
                                                <Row>
                                                    <Col md={12}>
                                                        Please select which day to receive this report
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Row className="align-items-center">
                                                            <Col xs={1} className="pr-0">
                                                                <FormControlLabel className="radio_cls" control={<Radio onChange={() => this.selectChoice('1')} checked={subChoice == '1' ? 'checked' : ''} />} />
                                                            </Col>
                                                            <Col xs={5} className="">
                                                                <AvField type="select" name="dayOutMonth" disabled={subChoice == 2} onChange={this.handleFreChange}
                                                                    value={activityData && dayOutMonth.length > 0 ? dayOutMonth[0].id : ''}>
                                                                    {activityData && activityData.daysOutMonth.daysOut && activityData.daysOutMonth.daysOut.length > 0 ? activityData.daysOutMonth.daysOut.map((state) =>
                                                                        <option key={state.id} selected={dayOutMonth.length > 0 && dayOutMonth[0].id == state.id ? true : false} value={state.id}>{state.name}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                            </Col>
                                                            <Col xs={5} className="pl-0">
                                                                <AvField type="select" name="frequency" disabled={subChoice == 2}
                                                                    value={activityData && frequencyMonth.length > 0 ? frequencyMonth[0].id : ''} onChange={this.handleFreChange} >
                                                                    {activityData && activityData.daysOutMonth.frequency && activityData.daysOutMonth.frequency.length > 0 ? activityData.daysOutMonth.frequency.map((state) =>
                                                                        <option key={state.id} selected={frequencyMonth.length > 0 && frequencyMonth[0].id == state.id ? true : false} value={state.id}>{state.name}</option>
                                                                    ) : ''}
                                                                </AvField>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col xs={1}>
                                                        <FormControlLabel className="radio_cls"
                                                            // className="select-choice-report-control"
                                                            control={<Radio
                                                                checked={subChoice == '2' ? 'checked' : ''}
                                                                onChange={() => this.selectChoice('2')} />}
                                                        />
                                                    </Col>
                                                    <Col xs={10}>
                                                        <Row className="align-items-center">
                                                            <Col xs={1} className="pr-0">Day</Col>
                                                            <Col xs={2} className="pr-0">
                                                                <input type="number" value={activityData && activityData.daysOutMonth.dayOfMonth != null && activityData.daysOutMonth.dayOfMonth != 0 ? activityData.daysOutMonth.dayOfMonth : ''} maxLength="2" disabled={subChoice == 1 ? true : false} name="dayOfMonth" onChange={this.handleFreChange} className="count-type" />
                                                            </Col>
                                                            <Col className="pl-2">of every Month</Col>
                                                        </Row>

                                                    </Col>
                                                    <Col xs={12}>{this.state.formValid ? isDayAlert ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px', marginTop: '0px' }}>Please Enter Valid Day Count.</p> : '' : this.state.subChoice == 2 ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px', marginTop: '0px' }}>Day is required</p> : ''}</Col>
                                                </Row>
                                            </Col> : ''}<p className="report-pop-note">Please note that Activity Reports are typically emailed between <br />8-9am your local time</p>
                                    <Row>
                                        <Col>
                                            <h3 className="report-pop-title">Report Features:<span><i>(uncheck to exclude any unit size)</i></span></h3>
                                            {isReportAlert ? <p style={{ color: '#F15D47', opacity: '1' }}>Please select at least one day from the list below.</p> : ''}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="remove-left-margin">
                                            <FormControlLabel
                                                control={<Checkbox checked={isMyStore} 
                                                onChange={e => this.setState({ isMyStore: e.target.checked })} 
                                                className="track-check-gl"
                                                name="info" 
                                                />}
                                                label="Include My Store Pricing"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4 className="report-pop-title">Changes in Unit Rates</h4>
                                            <Row style={{ margin: '0px' }} className="market-activity-values">
                                                {activityData.activity && activityData.activity.length > 0 ? activityData.activity.map(data => {
                                                    return (
                                                        <Col xs={6} sm={4}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    checked={data.value == 1}
                                                                    onChange={this.handleChange}
                                                                    name="activity"
                                                                    value={data.id}
                                                                    className="move-ch-top track-check-gl"
                                                                />}
                                                                label={data.name}
                                                            // className="move-ch-top track-check-gl"
                                                            /></Col>)
                                                }) : ''}
                                            </Row>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4 className="report-pop-title">Changes in Unit Inventory<span><i>(added/removed from market)</i></span></h4>
                                            <Row style={{ margin: '0px' }} className="market-activity-values">
                                                {activityData.inventoryTrack && activityData.inventoryTrack.length > 0 ? activityData.inventoryTrack.map(data => {
                                                    return (
                                                        <Col xs={6} sm={4}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    checked={data.value == 1}
                                                                    onChange={this.handleChange}
                                                                    name="inventoryTrack"
                                                                    value={data.id}
                                                                    className="move-ch-top track-check-gl"
                                                                />}
                                                                label={data.name}
                                                            //  className="move-ch-top track-check-gl"
                                                            /></Col>)
                                                }) : ''}
                                            </Row>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4 className="report-pop-title">Changes in Unit Rate Trends Over Time</h4>
                                            <Row style={{ margin: '0px' }} className="market-activity-values">
                                                {activityData.rateTrends && activityData.rateTrends.length > 0 ? activityData.rateTrends.map(data => {
                                                    return (
                                                        <Col xs={6} sm={4}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    checked={data.value == 1}
                                                                    onChange={this.handleChange}
                                                                    name="rateTrends"
                                                                    value={data.id}
                                                                    className="move-ch-top track-check-gl"
                                                                />}
                                                                label={data.name}
                                                            // className="move-ch-top track-check-gl"
                                                            /></Col>)
                                                }) : ''}
                                            </Row>

                                        </Col>

                                    </Row>

                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="active-comp-report-info-box">
                                        <h6 className="miss-sub">Never miss a beat!</h6>
                                        <p>Activity Reports are designed to let you easily and quickly check your competitive market each morning and identify changes that may require some action on your part.</p>
                                        <p>You can change your settings or frequency any time.</p>
                                        <p>If you have suggestions, questions or comments, let us know..</p>
                                        <Row>
                                            <Col xs={8} className="pr-0">
                                                <div className="phone">
                                                    <span>Call: </span>
                                                    <a href={"tel:" + CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free}> <NumberFormat value={CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free} displayType={'text'} format="#-###-###-####" /></a>

                                                </div>
                                                <div className="email">
                                                    <span>Email: </span>
                                                    <EmailShare email={CONFIG.Mail_Support} subject="" body="">
                                                        {link => (
                                                            <a href={link} data-rel="external">{CONFIG.Mail_Support}</a>
                                                        )}
                                                    </EmailShare>
                                                </div>
                                            </Col>
                                            <Col xs={4} className="p-0">
                                                <Button
                                                    size="sm"
                                                    style={{ fontSize: '10px' }}
                                                    className="submit-btn next-btn btn-design activate-deactivate-btn"
                                                    href={
                                                        pdfName && pdfName != undefined ?
                                                            require(`./active-report/${pdfName}`) :
                                                            require("./active-report/sample-activity-report-us.pdf")
                                                    }
                                                    target="_blank">
                                                    View Sample
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="active-comp-report-mail-box">
                                        <h3 className="report-pop-title">Recipients <span className="pl-3"><i> Add up to 5 recipients </i></span></h3>
                                        {/* <AvForm className="enter-acc-details"> */}
                                        <AvField name="Recipient1" placeholder="Required" label="Send to:" type="text"
                                            value={reciepients && reciepients[0] != "" ? reciepients[0] : ''}
                                            validate={{
                                                required: { value: true, errorMessage: 'Email Address is required' },
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient2" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[1] != "" ? reciepients[1] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient3" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[2] != "" ? reciepients[2] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient4" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[3] != "" ? reciepients[3] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient5" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[4] != "" ? reciepients[4] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <div className="form-submits">
                                            <Col className="changes-btn step_btn text-right p-0">
                                                <Button id="btn" onClick={this.cancelActiveReports.bind(this)} className="submit-btn btn-design go-back-btn">Cancel</Button>
                                                {/* <Button className="submit-btn next-btn btn-design mr-2 activate-deactivate-btn" onClick={() => { this.updateStatus(this.props.isGreyOut, this.props.isCompStatus, this.props.storeId) }}>{this.props.isGreyOut != 1 ? 'Schedule' : 'Deactivate'}</Button> */}
                                                <Button id="btn" className="submit-btn next-btn btn-design mr-0"  >Save</Button>
                                                {/* <Button id="btn-submit-form-active-comp" className="submit-btn next-btn btn-design mr-0" style={{ display: 'none' }} ></Button> */}
                                            </Col>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackOpen}
                    id={snackClr}
                    className="snackbar bg-color-primary"
                    maxsnack={5}
                    autoHideDuration={3000}
                    onClose={this.snackHandleclose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}