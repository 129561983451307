import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import './InventoryAvailabilityPdf.css';
import { makeStyles } from '@material-ui/core/styles';
import { HelpTextPopup } from '../../HelpTextPopup/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TableRow from '@material-ui/core/TableRow';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
//import TableRowColumn from '@material-ui/core/TableRowColumn'; 
import { Table as ReactTable } from 'reactstrap';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Logo from '../../../Assets/Images/logo.jpg';
import * as d3 from "d3";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { CONFIG } from '../../../utils/config';
import axios from 'axios';
import downarrows from '../../../Assets/Images/down-arrow.png';
import Chart from "react-apexcharts";

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };


  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket,
);





export default class InventoryAvailabilityPdf extends Component {

  constructor(props) {
    super(props);
    this.state = {


      overViewInfo: '',
      inventoryAvailablityList: [],
      storeAddress: '',
      isLoading: false,
      modal: false,
      trackModalData: '',
      pageCount: 0,
      pageCountMDT: 0,
      max: 0,
      defaultWidth: 0,
      storeNeverOff: [],
      order: this.props.order ? this.props.order : 'asc',
      orderBy: this.props.orderby,
      uniqueUnitType: [],
      invStore: [],
      uniqueSID: [],
      invStores: [],
      sname: [],
      address: [],
      invStoreStatus: false,
      accessKey: this.props.accessKey,

      options: {
        chart: {
          id: 'apexchart-example',
          stacked: true,
          toolbar: {
            show: false
          },
          animations: {
            enabled: false,
          },
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            columnWidth: '10%',
            barHeight: '50%',
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [{
        name: '',
        data: [],
      }],
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);

  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  toggle() {

    let storeId = parseInt(this.props.userstoreid);
    axios.post(CONFIG.API_URL + 'market/competitors/' + storeId + '/' + CONFIG.RegionId)
      .then(response => {
        const trackModalData = response.data.competitor.competitorsList;
        var trackedFilterResult = trackModalData.filter((result) => result.isTracked === true);
        // let strOptions = [];
        // trackModalData.forEach(function (e) { strOptions.push(e.storeID + (e.isTracked ? '|1' : '|0')) });
        this.setState({
          trackModalData: trackedFilterResult
        });
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }).catch((err) => {
        // if (err.response.status === 401) {
        //     localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        // }
        // else {
        //     alert(err.response.data);
        // }
      });



  }
  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.inventoryAvailability();
    } else {
      alert(response.status.Message);
    }
  }

  inventoryAvailability() {
    this.setState({
      isLoading: true
    })
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    const data = {
      "UserStoreID": parseInt(this.props.userstoreid),
      "RegionId": CONFIG.RegionId
    }
    axios.post(CONFIG.API_URL + 'dashboard/inventorybystore', data)
      .then(res => {
        let invStore = res.data.data;
        let uniqueSID = [...new Set(invStore.map(item => item.storeID))];
        let uniqueUnitType = [...new Set(invStore.map(item => item.unitType))];
        var invStores = [];

        // let  orderBy = this.state.orderBy =='storeName'? 'storeName': uniqueUnitType.slice(this.props.orderby, 1+this.props.orderby);
        let orderBy = this.state.orderBy == 'storeName' ? 'storeName' : uniqueUnitType[this.props.orderby];

        for (let i = 0; i < uniqueSID.length; i++) {
          var fD = invStore.filter(item => item.storeID == uniqueSID[i]);
          let sname = [...new Set(fD.map(item => item.storeName))];
          let address = [...new Set(fD.map(item => item.storeAddress))];
          var key = "storeName";
          var obj = {};
          obj["storeName"] = sname[0];
          obj["storeAddress"] = address[0];
          obj["storeID"] = fD[0].storeID;
          for (let u = 0; u < uniqueUnitType.length; u++) {
            var uData = fD.filter(item => item.unitType == uniqueUnitType[u]);
            obj[uniqueUnitType[u]] = uData[0].value;
          }
          invStores.push(obj);
        }
        if (orderBy == "storeName") {
          if (this.state.order === 'asc') {
            invStores = invStores.sort((a, b) => a.storeName.localeCompare(b.storeName));
          }
          else {
            invStores = invStores.sort((a, b) => b.storeName.localeCompare(a.storeName));
          }
        } else {

          if (this.state.order === 'asc') {
            invStores = invStores.sort((a, b) => (a[orderBy] - b[orderBy]));
          }
          else {
            invStores = invStores.sort((a, b) => (b[orderBy] - a[orderBy]));
          }
        }


        this.setState({ uniqueUnitType, orderBy, invStore, uniqueSID, invStoreStatus: true, invStores, isLoading: false });

      })
      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data)
      .then(res => {
        let storeAddress = res.data.data.storeDashboard;
        this.setState({ storeAddress });

      })
      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });


    axios.post(CONFIG.API_URL + 'dashboard/inventoryavailablity', data)
      .then(res => {
        let overViewInfo = res.data.data.invAvaildashboard;
        let inventoryAvailablityList = res.data.data.inventoryAvailablityList;
        var storeNeverOff = inventoryAvailablityList.filter(function (m1) {
          return m1.storesWithUnitType > 0 && m1.storesWithUnitTypePercent > 0;
        });
        this.setState({ overViewInfo, storeNeverOff });
        this.setState({ inventoryAvailablityList });
        this.stackedgraphNew(inventoryAvailablityList);
        // if(CONFIG.RegionId === 2){
        //   this.stackedgraphAus(inventoryAvailablityList);
        // }else{
        //   this.stackedgraph(inventoryAvailablityList);
        // }
        this.setState({ isLoading: false });
      })
      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });


  }

  stackedgraphNew(datavalue) {
    let { inventoryAvailablityList } = this.state;
    let marketSeries = [];
    var resultedRange = inventoryAvailablityList.map(data => (data.storesWithUnitType + data.storesNeverOffered));
    let onMarketData = [...new Set(inventoryAvailablityList.filter(function (obj) {
      return obj.onMarket > 0;
    }).map(item => item.onMarket))];
    let offMarketData = [...new Set(inventoryAvailablityList.filter(function (obj) {
      return obj.offMarket > 0;
    }).map(item => item.offMarket))];
    var max = resultedRange.reduce(function (a, b) { return Math.max(a, b); });
    max = (onMarketData.length > 0 || offMarketData.length > 0) ? max : 0;

    this.setState({ max });
    let dataSeries = inventoryAvailablityList.map(val => { return { x: val.unitType, y: val.onMarket, perc: val.onMarketPercent }; });
    let dataSeries2 = inventoryAvailablityList.map(val => { return { x: val.unitType, y: val.offMarket, perc: val.offMarketPercent }; });
    marketSeries.push({ name: 'On Market', data: dataSeries }, { name: 'Off Market', data: dataSeries2 });

    // YAXIS VALUES AS DYNAMIC
  let max_dataSeries = Math.max.apply(null, dataSeries.map(item => item.y));
  let max_dataSeries2 = Math.max.apply(null, dataSeries2.map(item => item.y));

  let max_marketSeries = 0
  if(max_dataSeries > max_dataSeries2){
    max_marketSeries = max_dataSeries
  }else{
    max_marketSeries = max_dataSeries2
  }


    if (marketSeries.length > 0 && max !== 0) {
      this.setState({
        options: {
          chart:{
            animations: {
              enabled: false,
            },
          },
          grid: {
            show: false
          },
          tooltip: { enabled: false },
          dataLabels: {
            enabled: true,
            offsetY:4,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            },
            formatter: function (val, opts) {
              return val === 0 ? '' : val;
            },
          },
          annotations: {
            yaxis: [
              {
                y: max_marketSeries*2,
                borderColor: '#68E497',
              }
            ]
          },
          plotOptions: {
            bar: {
             // borderRadius: barBorderRadius,
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              },
              columnWidth: '25%',
              barHeight: '50%',
            },
          },
          yaxis: {
            max:  max_marketSeries*2,
            labels: {
              formatter: function (value) {
                let val = value === null || value === undefined ? "N/A" : value.toFixed(0);
                return val;
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder:{
              show: false,
            },
            axisTicks: {
              show: false,
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            showForNullSeries: false,
            showForZeroSeries: true,
            markers: {
              radius: 13,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 20,
            },
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: false
            },
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value;
                return val;
              },
              show: true,
              hideOverlappingLabels: false,
              trim: true,
              rotate: 0,
              style: {
                colors: '#010101',
                fontSize: '10px'
              },
            },
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            }
          },
        },
        series: marketSeries
      })
    } else {
      this.setState({
        options: {
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            labels: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
        },
        series: []
      })
    }
  }


  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };
  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  //Table Sorting 
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    let sort = isDesc ? 'asc' : 'desc';
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  render() {
    const { overViewInfo, inventoryAvailablityList, storeNeverOff, page, pageCount, pageCountMDT, storeAddress, isLoading, trackModalData, max, order, orderBy, uniqueUnitType, invStore, uniqueSID, invStores } = this.state;
    const defaultProps = {
      helpTitle: "Inventory Availability",
      helpContent: "<p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, their inventory availability for that unit type goes down. If a store adds a unit type to their website, their inventory availability goes up.</p><p>Here you can see the current Inventory Availability across the entire Market, as well as a breakdown of current Inventory Availability by store type - Your Store, REITs, Multi-store and Single-store operators.</p><h6>Inventory Availability for the Market</h6><p>The aggregate number of On-Market/Stores Offering any unit type in this Market</p><h6>Inventory Availability for Your Store</h6><p>How many Unit Types Your Store has offered  in the past vs. how many of them are currently being offered (or are 'On-Market'). For example, let's say Your Store has offered/advertised 8 different Unit Types but currently has only 6 of them on-market (and by definition 2 are off-market), then 'Your Store' Inventory Availability is 75%, i.e. 6÷8)</p><h6>Inventory Availability for REITs, Mid-Ops, Single-Ops</h6><p>This is the same calculation as explained in 'Your Store' above, but calculated for each store group (REIT, Multi and Single operators). For example, if there are 3 REITs in the Market and two are offering 6 of 8 unit types and one of them is offering 5 of 8 unit types, then the aggregate Inventory Availability for REITs is (((6÷8)+(6÷8)+(5÷8))/3) x 100 = 70.8%</p><p><i>*Your store inventory is included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/1yTtSFctpcE' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    }
    const defaultProps1 = {
      helpTitle: "Inventory Supply",
      helpContent: "<p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, the inventory availability for that unit type goes down. If a store adds a unit type to their website, the inventory availability for that unit type goes up. Here you will see a breakdown of the number of stores offering vs. the number of stores which have never offered each of the most common unit types.</p><p><i>*Your store inventory is included in the calculations.</i></p>"
    }
    const defaultProps2 = {
      helpTitle: "Inventory Availability by Unit Type",
      helpContent: "<p>This is graph is a deeper analysis of the Inventory Supply you see above. It further analyzes for each unit type, how many out of all the stores in the Market which have advertised this unit type at some point, the number of stores <i>currently</i> offering/advertising this unit type vs. the number of stores (which have advertised it in the past) are <i>currently not</i> offering this unit type.</p><p><i>*Your store inventory is included in the calculations.</i></p>"
    }
    const defaultProps3 = {
      helpTitle: "Inventory Status",
      helpContent: "<p>This table is a continuation of the Inventory Supply graph you see above. It shows, for each unit type, how many out of all the stores in the Market which have advertised this unit type at some point, the number of stores currently offering/advertising this unit type vs. the number of stores (which have advertised it in the past) are currently not offering this unit type.</p><p><i>*Your store inventory is included in the calculations.</i></p>"
    }
    let uTypeLen = uniqueUnitType.length;
    const tr = [...Array(Math.ceil(invStores.length / 17))];
    const trproductRows = tr.map((row, idx) => invStores.slice(idx * 17, idx * 17 + 17));
    const nameCount = storeAddress.storeName ? storeAddress.storeName.length : 0;
    return (

      <div className="InventoryAvailabilityPdf" id="invent-container-pdf">
        <div class="header">
          <img src={Logo} /><div className="float-right-name">
            <h5>{storeAddress.storeName && storeAddress.storeName !== null && storeAddress.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? storeAddress.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : storeAddress.storeName : isLoading === false ? 'Your Store' : ''}</h5>
          </div>
          <div className="float-right">
            {/* {storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ',' : isLoading === false ? '' : ''} {storeAddress.storeCity ? storeAddress.storeCity + ', ' : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ' : isLoading === false ? '' : ''} {storeAddress.storeState && storeAddress.storeState !== '' && storeAddress.storeState !== null ? storeAddress.storeState + '' : isLoading === false ? ' ' : ''} {storeAddress.storeZipCode && storeAddress.storeZipCode !== '' && storeAddress.storeZipCode !== null ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''} | <span className="text-right"><label>Coverage: </label> <span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading===false ? 'N/A':''}</span></span> */}
            {/* {storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ", " : '' + storeAddress && storeAddress.storeCity ? storeAddress.storeCity + ", " : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ': ''}{storeAddress.storeState + ' ' + storeAddress.storeZipCode} {storeAddress.storeZoneCoverage ? "| Coverage: " + storeAddress.storeZoneCoverage : 'N/A'} */}
            {storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' ? storeAddress.storeAddress1 + ',' : isLoading === false ? '' : ''} {storeAddress.storeCity ? storeAddress.storeCity + ', ' : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude + ', ' + storeAddress.longitude + ', ' : isLoading === false ? '' : ''} {storeAddress.storeState && storeAddress.storeState !== '' && storeAddress.storeState !== null ? storeAddress.storeState + '' : isLoading === false ? ' ' : ''} {storeAddress.storeZipCode && storeAddress.storeZipCode !== '' && storeAddress.storeZipCode !== null ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''} | <span className="text-right"><label>Coverage: </label> <span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading === false ? 'N/A' : ''}</span></span>
          </div>
        </div>
        <div className="top-content-body help-text-pdf">
          <h5 className="bill-head pt-2 m-0">Inventory Availability <span className="text-right" style={{ fontSize: '12px', color: '#5a6e82', float: 'right' }}><i>*Your store inventory is included in the calculations.</i></span></h5>
          <p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, their inventory availability for that unit type goes down. If a store adds a unit type to their website, their inventory availability goes up.</p>
          <p>Here you can see the current Inventory Availability across the entire Market, as well as a breakdown of current Inventory Availability by store type - Your Store, REITs, Multi-store and Single-store operators.</p>
          <h6>Inventory Availability for the Market</h6>
          <p>The aggregate number of On-Market/Stores Offering any unit type in this Market</p>
          <h6>Inventory Availability for Your Store</h6>
          <p>How many Unit Types Your Store has offered  in the past vs. how many of them are currently being offered (or are 'On-Market'). For example, let's say Your Store has offered/advertised 8 different Unit Types but currently has only 6 of them on-market (and by definition 2 are off-market), then 'Your Store' Inventory Availability is 75%, i.e. 6÷8)</p>
          <h6>Inventory Availability for REITs, Mid-Ops, Single-Ops</h6>
          <p>This is the same calculation as explained in 'Your Store' above, but calculated for each store group (REIT, Multi and Single operators). For example, if there are 3 REITs in the Market and two are offering 6 of 8 unit types and one of them is offering 5 of 8 unit types, then the aggregate Inventory Availability for REITs is (((6÷8)+(6÷8)+(5÷8))/3) x 100 = 70.8%</p>
        </div>
        <div className="banner-header">
          <Row className="height-full">
            <Col className="common-col height-full">
              <div className="middle-div">
                <div className="market-label">Market</div>
                <div className="market-value">{overViewInfo.market ? overViewInfo.market + "%" : isLoading === false ? 'N/A' : ''}</div>
                <div className="btm-label">Current</div>
              </div>
            </Col>
            <Col className="common-col height-full">
              <div className="middle-div">
                <div className="other-labels">Your Store</div>
                <div className="other-values">{overViewInfo.myStore ? overViewInfo.myStore + "%" : isLoading === false ? 'N/A' : ''}</div>
              </div>
            </Col>
            {storeAddress.countryCode == "US" ?
              <Col className="common-col height-full">
                <div className="middle-div">
                  <div className="other-labels">REIT's</div>
                  <div className="other-values">{overViewInfo.reits ? overViewInfo.reits + "%" : isLoading === false ? 'N/A' : ''}</div>
                </div>
              </Col>
              : ''}
            <Col className="common-col height-full">
              <div className="middle-div">
                <div className="other-labels">Multi-Ops</div>
                <div className="other-values">{overViewInfo.multiOps ? overViewInfo.multiOps + "%" : isLoading === false ? 'N/A' : ''}</div>
              </div>
            </Col>
            <Col className="common-col height-full remove-right">
              <div className="middle-div">
                <div className="other-labels">Single-Ops</div>
                <div className="other-values">{overViewInfo.singleOps ? overViewInfo.singleOps + "%" : isLoading === false ? 'N/A' : ''}</div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="clearfix"> </div>
        <Paper className="dashboard-layout last-months" >

          <div className="request-free-trial inventory-tables">

            <Col>
              <h5 className="bill-head pt-3 m-0">Inventory Supply</h5>
              <div className="help-text-pdf text-left">
                <p>Inventory Availability refers to the number of stores in the Market offering a particular unit type. When a store takes a unit off their website, the inventory availability for that unit type goes down. If a store adds a unit type to their website, the inventory availability for that unit type goes up. Here you will see a breakdown of the number of stores offering vs. the number of stores which have never offered each of the most common unit types.</p>
              </div>
              <div className="clear"></div>
              <div className="overflow-scroll-table br-0">

                <Paper className="bx-sh-0">
                  {storeNeverOff.length > 0 ?
                    <Table className={"table custom-table table-bordered inventory-table inventory-status"}>
                      <TableHead>
                        <TableRow className="table-header">
                          <TableCell className="getthwidth"></TableCell>
                          {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                            return (

                              <TableCell className="secondthwidth">{data.unitType}</TableCell>

                            );
                          }) : ''}
                        </TableRow >
                      </TableHead>
                      {inventoryAvailablityList.length > 0 ?
                        <TableBody>
                          <TableRow className="table-body">
                            <TableCell>{"Stores With this Unit Type "}</TableCell>
                            {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                              return (

                                <TableCell>{data.storesWithUnitType}<span className="gray-color">({data.storesWithUnitTypePercent}%)</span></TableCell>
                              );
                            }) : ''}
                          </TableRow>
                          <TableRow className="table-body">
                            <TableCell>{"Stores Never Offered "}</TableCell>
                            {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                              return (

                                <TableCell>{data.storesNeverOffered}<span className="gray-color">({data.storesNeverOfferedPercent}%)</span></TableCell>
                              );
                            }) : ''}
                          </TableRow>

                        </TableBody>
                        : <TableBody><TableRow><TableCell className="no-data">{isLoading === false ? 'No Records Found' : ''}</TableCell></TableRow></TableBody>}
                    </Table>
                    : <div className="no-data inventory-nodata">{isLoading === false ? 'No Data Available' : ''}</div>}
                </Paper>

              </div>
            </Col>
          </div>

          <div className="App pb-2">
            <h4 className="m-0" style={{ float: 'none' }}>Inventory Availability by Unit Type</h4>
            <div className="help-text-pdf text-left">
              <p>This is graph is a deeper analysis of the Inventory Supply you see above. It further analyzes for each unit type, how many out of all the stores in the Market which have advertised this unit type at some point, the number of stores <i>currently</i> offering/advertising this unit type vs. the number of stores (which have advertised it in the past) are <i>currently not</i> offering this unit type.</p>
            </div>
            <Paper style={{ height: '', width: '100%' }} className="custom-paper">
              <div className="clear"></div>
              <div className={max === 0 ? 'fix-map price-map' : 'price-map'}>
                {max === 0 ? <div className="no-data"> {isLoading === false ? 'No Data Available' : ''}</div> :
                  <div id="printDiv" className={this.state.storeAddress.countryCode === 'US' ? 'viz-portfolio-delinquent-status inventory-graph graph-us' : 'viz-portfolio-delinquent-status inventory-graph'}>
                    <Chart options={this.state.options} series={this.state.series} type="bar" width={1270} height={350} />
                  </div>

                }
              </div>
            </Paper>
            <div className="request-free-trial inventory-tables">
              <div className="inventory-status">
                <h5 className="bill-head text-left" style={{ display: 'block' }}>Inventory Status </h5>
                <div className="clear"></div>
                <div className="overflow-scroll-table br-0">
                  <Paper className="bx-sh-0">
                    {storeNeverOff.length > 0 ?
                      <Table className={"table custom-table table-bordered inventory-table inventory-status"}>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell></TableCell>
                            {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                              return (

                                <TableCell>{data.unitType}</TableCell>

                              );
                            }) : ''}
                          </TableRow >
                        </TableHead>
                        {inventoryAvailablityList.length > 0 ?
                          <TableBody>
                            <TableRow className="table-body">
                              <TableCell>{"Off Market "}</TableCell>
                              {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                                return (

                                  <TableCell><b className="inventory-Bold">{data.offMarket}</b><span className="red-color">({data.offMarketPercent}%)</span></TableCell>
                                );
                              }) : ''}
                            </TableRow>
                            <TableRow className="table-body">
                              <TableCell>{"On Market "}</TableCell>
                              {inventoryAvailablityList.length > 0 ? inventoryAvailablityList.map(data => {
                                return (

                                  <TableCell>{data.onMarket}<span className="green-color">({data.onMarketPercent}%)</span></TableCell>
                                );
                              }) : ''}
                            </TableRow>


                          </TableBody>
                          : <TableBody><TableRow><TableCell className="no-data">{isLoading === false ? 'No Records Found' : ''}</TableCell></TableRow></TableBody>}
                      </Table>
                      : <div className="no-data inventory-nodata">{isLoading === false ? 'No Data Available' : ''}</div>}
                  </Paper>
                </div>
              </div>
            </div>
          </div>

          <div className="request-free-trial inventory-tables inventory-by-stores">
            {trproductRows ? trproductRows.map((rows, i) => (
              <div class="page fix-top">
                {i === 0 ? <h5 className="bill-head">Inventory by Store</h5> : ''}
                <Paper className="custom-paper" style={{ border: 'none' }}>
                  <Col>


                    {invStores.length > 0 ?
                      <div className="inventory-legend"><span><FontAwesomeIcon icon={faCheck} className="green-clr" /> Currently Advertising this Unit Type</span>
                        <span className="close-ic">   <FontAwesomeIcon icon={faTimes} className="red-clr" /> Not Currently Advertising this Unit Type</span>
                      </div>
                      : ''}
                    <div className="clear"></div>

                    <div className="ms_table pd-3 display-grid" >
                      <ReactTable className={"table custom-table table-bordered inventory-table inventory-status tableinventorystore"} responsive="true">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={uniqueSID ? uniqueSID.length : 0}
                          rowHeader={uniqueUnitType}
                        />
                        {invStores && invStores.length > 0 ?
                          <tbody>
                            {invStores.length > 0 ? this.stableSort(rows, this.getSorting(order, orderBy)).map((row, i) => {

                              return (

                                <tr id={i} key={i} className="disabled_color">
                                  <td align='center' className="ms_action tablestorename"> <div className="tablestorenamein">{row.storeName}</div> <span> {row.storeAddress}</span></td>

                                  {

                                    Object.values(row).splice(3, uTypeLen).map((val =>
                                      <td align='center' className="ms_action"> {val == 1 ? <FontAwesomeIcon icon={faCheck} className="green-clr" /> : <FontAwesomeIcon icon={faTimes} className="red-clr" />}</td>
                                    ))
                                  }

                                </tr>
                              )
                            })
                              :
                              <tr className="disabled_color na_row"><td>{isLoading === false ? '' : 'N/A'}</td></tr>
                            }

                          </tbody>
                          :
                          <tbody>
                            <tr>
                              <td className="no-data" colSpan="9">{isLoading === false && this.state.invStoreStatus ? 'No Data Available' : <div className="loader-wrap"><div className="loading"></div></div>}</td>
                            </tr>
                          </tbody>
                        }
                      </ReactTable>
                    </div>


                  </Col>
                </Paper>
                <div class="printclearfix"></div>
              </div>
            ))
              : ''}
          </div>
          <div className="footer">
            <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
          </div>
        </Paper>
        <div>
          <Modal isOpen={this.state.modal} backdrop="static" className={"store-mainpopup" + this.props.className}>
            {/* <Modal isOpen={this.state.modal} backdrop="static" className={this.props.className} className="store-mainpopup"> */}
            <ModalHeader toggle={this.toggle}>{storeAddress.storeName}</ModalHeader>
            <ModalBody>
              <Table className="table custom-table table-bordered store-count-popup">
                <TableHead>
                  <TableRow>
                    <TableCell>COMPETITOR NAME</TableCell>
                    <TableCell >ADDRESS</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {trackModalData ? trackModalData.slice(pageCount * 10, pageCount * 10 + 10).map((trackData) =>
                    <TableRow key={trackData.rec_num}>
                      <TableCell>{trackData.storeName}</TableCell>
                      <TableCell>{trackData.storeAddress}</TableCell>

                    </TableRow>
                  ) :
                    <TableRow></TableRow>
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1]}
                      colSpan={3}
                      count={trackModalData ? trackModalData.length : 0}
                      rowsPerPage={10}
                      page={pageCount}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={this.handleChangePageTrack}
                      onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                      ActionsComponent={MyMarketWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </ModalBody>

          </Modal>
        </div>
      </div>
    );
  }
}
InventoryAvailabilityPdf.propTypes = {
  // classes: PropTypes.object.isRequired,
};

//Listing and Sorting Table's Head
function EnhancedTableHead(props) {

  const { order, orderBy, onRequestSort, rowHeader } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };


  let headRows = rowHeader.length > 0 ? rowHeader.map((item, i) => {
    return {
      // id: item.replace(/\s/g, ''),
      id: item,
      numeric: false,
      disablePadding: true,
      label: item,
    };
  }) : [];

  if (rowHeader.length > 0) {
    headRows.unshift({
      id: "storeName",
      numeric: false,
      disablePadding: true,
      label: "storeName"
    })
  }


  return (

    <TableHead className={"table custom-table table-bordered inventory-table inventory-status"}>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align='center'
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            // disabled={row.id !== 'moreoptions' ? false : true}
            >
              {row.label}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}