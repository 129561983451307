import React, { Component } from 'react';
import { Header, Footer } from './../../Core/index';
class LoginLayout extends Component {
    render() {
        return (
            <div>
              
                {this.props.children}
               
            </div>
        );
    }
}

export default LoginLayout;