import React from 'react';
import ReactDOM from "react-dom";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, ButtonToolbar, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faEye } from '@fortawesome/free-solid-svg-icons';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AvatarImage from './../../Assets/Images/oval1.png';
import './developmentListing.css';
import { Gmaps, Marker, InfoWindow } from 'react-gmaps';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
const params = { v: '3.exp', key: 'AIzaSyDaYQZ0es5K-kLgDuZuTqRHKiTDENEMxxQ' };
export class InfoWindowEx extends React.Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.onInfoWindowOpen = this.onInfoWindowOpen.bind(this);
    if (!this.containerElement) {
      this.containerElement = document.createElement(`div`);
    }
  }

  onInfoWindowOpen() {
    ReactDOM.render(
      React.Children.only(this.props.children),
      this.containerElement
    );
    this.infoWindowRef.current.infowindow.setContent(this.containerElement);
  }
  render() {
    return (
      <InfoWindow
        onOpen={this.onInfoWindowOpen}
        ref={this.infoWindowRef}
        {...this.props}
      />
    );
  }
}


export default class developmentListing extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    //this.tabsChange = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      showingInfoWindow: false,
      listingData: [],
      storeData: [],
      activeMarker: {},
      locationInfoData: [],
      activeTab: '2'
    };
  }
  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: true
    });
  }

  tabsChange(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onMarkerClick = (storeId, name, address, foot, open, own, operated, lat, lng, price) => {
    this.setState({
      storeId: storeId,
      activeMarker: name,
      storeName: name,
      lat: lat,
      lng: lng,
      address: address,
      squareFootage: foot,
      opened: open,
      ownedBy: own,
      operatedBy: operated,
      priceChangesPercent: price,
      showingInfoWindow: true
    });

  };
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  developListingMap() {
    this.setState({
      isLoading: true
    })
    const data = {
     "UserStoreID": parseInt(this.props.match.params.userstoreid)
    }
    // const data = {"UserStoreID":"20498","WebRegion":"1"};
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/developmentlisting', data)
      .then(res => {
        const listingData = res.data.data;
        console.log('test comp');
        console.log(listingData);
        this.setState({ listingData: listingData});
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }
  
  componentDidMount() 
  {
      this.developListingMap();
  }
  
  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    const { locationInfoData } = this.state;
    console.log('propstest');
    const { listingData } = this.state;
    const infoWindowContent = '<table>' + '<tr><td width="80%">' + this.state.storeName + '<p> ' + this.state.address + '</p></td><td width="20%"><img src=/></td></tr></table><table><tr>' + '<td className="tbHead-td">'+CONFIG.MeasurementLabel+'</td>' + '<td .tbdata-td>' + this.state.squareFootage + '</td></tr>' + '' + '<tr>' + '<td className="tbHead-td">Opened</td>' + '<td .tbdata-td>' + this.state.opened + '</td></tr>' + '<tr>' + '<td className="tbHead-td">Owned by</td>' + '<td .tbdata-td>' + this.state.ownedBy + '</td></tr>' + '<tr>' + '<td className="tbHead-td">Operated by</td>' + '<td .tbdata-td>' + this.state.operatedBy + '</td></tr>' + '<tr>' + '<td className="tbHead-td">Price Volitality</td>' + '<td .tbdata-td>' + this.state.priceChangesPercent + '</td>' + '</tr>' + '<table>';
    return (
      <div className="wrapper my-account-box">
        <Row className="overview-header mt-2">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row>
                <Col sm="6" className="padding-right-8">
                  <h2>Competitor Overview</h2>
                  <p>Analyzing <Link to="#">Stores</Link></p>
                </Col>
                <Col sm="6" className="pl-0">
                  <h3></h3>
                  <p className="m-0"></p>
                  <p className="m-0 pt-1">Coverage:</p>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col md="7" className="">
            <Col md="12" className="p-v-15 p-37-15 border-box shadow-sm rounded">
              <Row className="height-full">
                <Col xs="6" sm="6" md="3" >
                  <h4>No. of Stores</h4>
                  <h5></h5>
                </Col>
                <Col xs="6" sm="6" md="3">
                  <h4>No. of REIT's</h4>
                  <h5></h5>
                </Col>
                <Col xs="6" sm="6" md="3">
                  <h4>No. of Multi-Ops</h4>
                  <h5></h5>
                </Col>
                <Col xs="6" sm="6" md="3">
                  <h4>No. of Single-Ops</h4>
                  <h5></h5>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <div className="mt-3">
          <Col md="12" className="p-v-15 border-box shadow-sm rounded">
            <Row>
              <Col md="8">
                <Link to="#">Are there any stores missing?</Link>
              </Col>
              <Col md="4" className="float-right text-right">
                <Nav tabs className="d-inline-block">
                  <NavItem className="grouped-btn d-inline-block">
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.tabsChange('1'); }}>
                      <FontAwesomeIcon icon={faList} />
                    </NavLink>
                  </NavItem>
                  <NavItem className="grouped-btn d-inline-block">
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.tabsChange('2'); }}
                    >
                      <FontAwesomeIcon icon={faMapPin} />
                    </NavLink>
                  </NavItem>
                </Nav>
                <Dropdown className="d-inline-block" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle caret>
                    Sort by
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Foo Action</DropdownItem>
                    <DropdownItem>Bar Action</DropdownItem>
                    <DropdownItem>Quo Action</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            {/* List view grid */}
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>

                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row className="mt-3">
                  <Col md="12">
                    <Gmaps
                      width={'100%'}
                      height={'600px'}
                      //lat={}
                      //lng={}
                        lat={39.2075}
                        lng={-75.5686}
                      zoom={10}
                      params={params}
                      onMapCreated={this.onMapCreated}>
                      {/* {listingData ? storeData.map((map) => */}
                        <Marker
                          //lat={map.latitude}
                          //lng={map.longitude}
                          lat={39.2075}
                          lng={-75.5686}
                          //onClick={() => this.onMarkerClick(map.storeID, map.storeName, map.storeAddress, map.squareFootage, map.opened, map.ownedBy, map.operatedBy, map.latitude, map.longitude, map.priceChangesPercent)}
                        />
                      {/* ) : ''} */}
                      {/* {this.state.showingInfoWindow && this.state.activeMarker != '' ?
                        <InfoWindow
                          marker={this.state.activeMarker === undefined ? '' : this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          //visible={false}
                          lat={this.state.lat}
                          lng={this.state.lng}
                          content={infoWindowContent}
                        //onCloseClick={this.onCloseClick}
                        />
                        : ''} */}
                    </Gmaps>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </div>
      </div>
    );
  }

}