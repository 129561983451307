import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Select from "react-select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import List from "@material-ui/icons/List";
import LocationOn from "@material-ui/icons/LocationOn";
import LastPageIcon from "@material-ui/icons/LastPage";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { CONFIG, MatchingCountryCode } from "../../utils/config";
import { HashLink as Link } from "react-router-hash-link";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Label } from "reactstrap";
import "react-dropdown-tree-select/dist/styles.css";
import { MissingCompetitor } from "./../../Views";
import "./AddMarketcmpopup.css";
import graypin from "./../../Assets/Images/gray-pin.png";
import blackpin from "./../../Assets/Images/black-pin.png";
import bluepin from "./../../Assets/Images/blue-pin.png";
import orangepin from "./../../Assets/Images/orange-pin.png";
import orangehome from "./../../Assets/Images/orange-home.png";
import Snackbar from "@material-ui/core/Snackbar";
// import { compose, withProps, withStateHandlers } from "recompose";
// import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Circle,
  Polygon,
} from "react-google-maps";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import Slider from "@material-ui/core/Slider";
export default class AddMarketcmpopup extends Component {
  static defaultProps = {
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" +
      CONFIG.GoogleMapsApiKey +
      "",
  };
  constructor(props) {
    super(props);
    this.state = {
      myMarketData: "",
      page: 0,
      pageCount: 0,
      pageCountMDT: 0,
      rowsPerPage: 10,
      tooltipOpen: false,
      isLoading: false,
      isLoading1: false,
      open: false,
      open1: false,
      open2: false,
      setOpen: false,
      isSelactCity: false,
      StateCountry: "",
      activeStep: 0,
      isDefaultValue: 1,
      radiusValue: 50,
      coverageTrackCount: null,
      scale: 10,
      showComponent: "radius",
      lookupZipcodeData: [],
      filterlookupZipcodeData: [],
      lookupCityData: [],
      ZipcodeFilter: "",
      selectedCityOption: "",
      selectedZipOption: null,
      zipcodeOptions: [],
      unzipcodeOptions: [],
      marketStoreName: "",
      userStorId: null,
      userStoreID: 0,
      trackStorId: null,
      storeModal: false,
      trackModalData: [],
      trackOptions: [],
      strOptions: [],
      strOptions1: [],
      trackedFields: [],
      trackedFields1: [],
      unTrackedCount: [],
      serachTypeval: null,
      competitorsData: [],
      tabvalue: 0,
      coordinate: [],
      EditStoredata: {},
      showModal: false,
      marketName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      coverageTrackCount: 0,
      exestineStore: "true",
      title: "Add Market",
      title1: "Add Address",
      tracked: "",
      userStr: "",
      isTracks: "",
      snackOpen: false,
      snackMSg: "",
      errCard: "",
      oldAddress: {},
      newAddress: {},
      isOpen: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      coordinate: [],
      zipCoordinates1: [],
      zipCoordinates2: [],
      zipCoordinates3: [],
      cityCoordinates: [],
      searchData: [],
      merged: [],
      uniqzipcode: [],
      zipError: "",
      coordinates_one: [],
      coordinates_one2: [],
      StateCountryCount: 1,
      centerLat: 0,
      centerLng: 0,
      zoomValue: 6,
    };
    this.trackModal = this.trackModal.bind(this);
    this.trackModalClose = this.trackModalClose.bind(this);
    this.ModalClickOpen = this.ModalClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.snackHandleclose = this.snackHandleclose.bind(this);
    this.handleBack = this.handleBack.bind(this);
    // this.sendTrackFields = this.sendTrackFields.bind(this);
    this.trackhandleClick = this.trackhandleClick.bind(this);
    //this.trackhandle = this.trackhandle.bind(this);
    this.upgradeBtn = this.upgradeBtn.bind(this);
    //this.degradeBtn = this.degradeBtn.bind(this);
    this.deleteComp = this.deleteComp.bind(this);
    //this.raduisChange = this.raduisChange.bind(this);
    // this.handleReset = this.handleReset.bind(this);
    // this.addMarket = this.addMarket.bind(this);
    this.dummyFn = this.dummyFn.bind(this);

    this.onToggleOpen = this.onToggleOpen.bind(this);
    this.postalCodeValidation = this.postalCodeValidation.bind(this);
    this.radiusOnChange = this.radiusOnChange.bind(this);
    this.mapRef = React.createRef();
    this.getbounds = true;
  }
 
  postalCodeValidation = (e) => {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (
      !(code == 32) && // space
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  };
  onToggleOpen = (isOpen) => {
    this.setState({
      isOpen: !isOpen,
    });
  };

  CMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        //defaultZoom={9}
        ref={this.mapRef}
        onTilesLoaded={this.setMapFitBounds}
        defaultZoom={
          this.state.showComponent === "radius"
            ? this.state.zoomValue
            : this.state.showComponent === "city"
            ? 10
            : this.state.showComponent === "zipcode"
            ? this.state.zoomValue
            : ""
        }
        defaultCenter={{
          lat: parseFloat(
            this.state.competitorsData.data.centerPointDetail &&
              this.state.competitorsData.data.centerPointDetail.length > 0 &&
              this.state.competitorsData.data.centerPointDetail[0].latitude !=
                null
              ? this.state.competitorsData.data.centerPointDetail[0].latitude
              : this.state.competitorsData.data.currentStoreDetail.lat
          ),
          lng: parseFloat(
            this.state.competitorsData.data.centerPointDetail &&
              this.state.competitorsData.data.centerPointDetail.length > 0 &&
              this.state.competitorsData.data.centerPointDetail[0].longitude !=
                null
              ? this.state.competitorsData.data.centerPointDetail[0].longitude
              : this.state.competitorsData.data.currentStoreDetail.lng
          ),
        }}
        defaultOptions={{
          zoomControl: true,
          fullscreenControl: false,
          streetViewControl: false,
          disableDefaultUI: true,
          mapTypeControl: false,
        }}
      >
        {props.children}
      </GoogleMap>
    ))
  );

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true });
    }, 3000);
  };

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false });
    this.delayedShowMarker();
  };
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
    // const x = document.getElementById('Subid');
    // x.focus();
  };
  zipFilterHandleChange = (e) => {
    let ZipcodeFilter = e.target.value;
    let zipError = this.state.zipError;
    // this.setState({
    //     ZipcodeFilter: e.target.value
    // })
    let filterlookupZipcodeData = this.state.lookupZipcodeData;
    filterlookupZipcodeData = filterlookupZipcodeData.filter((lookup) => {
      let lookupName = lookup.value.toLowerCase();
      return lookupName.indexOf(ZipcodeFilter.toLowerCase()) !== -1;
    });
    this.setState({
      filterlookupZipcodeData,
      ZipcodeFilter,
      zipError:
        filterlookupZipcodeData.length === 0
          ? "The given Zip or Postal Code(s) is not available"
          : "",
    });
  };
  // zipFilterHandleChange = (e) => {
  //     this.setState({
  //         ZipcodeFilter: e.target.value
  //     })
  //     //this.props.onChange(event.target.value)
  //   }
  onShowModal = () => {
    this.setState({ showModal: true });
  };

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: false,
    });
  }

  zipcodeClick(event, zipcodeValue) {
    let zipcodeOptions = this.state.zipcodeOptions;
    let unzipcodeOptions = this.state.unzipcodeOptions;
    let index;

    if (event.target.checked) {
      if (zipcodeOptions.length <= 2) {
        zipcodeOptions.push(zipcodeValue);
        index = unzipcodeOptions.indexOf(zipcodeValue);
        unzipcodeOptions.splice(index, 1);
      } else {
        this.setState({
          snackOpen: true,
          snackMSg:
            "You cannot select more than 3 zipcodes! Please remove a selection and try again",
        });
      }
    } else if (!event.target.checked) {
      if (zipcodeOptions.length <= 3) {
        index = zipcodeOptions.indexOf(zipcodeValue);
        zipcodeOptions.splice(index, 1);
        unzipcodeOptions.push(zipcodeValue);
      } else {
        this.setState({
          snackOpen: true,
          snackMSg:
            "You cannot select more than 3 zipcodes! Please remove a selection and try again.",
        });
      }
    }

    this.setState({
      zipcodeOptions,
      unzipcodeOptions,
      errortext1: "",
      coverageType: "zipcode",
    });
    this.setState({
      isLoading1: true,
    });
    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: "",
      SearchType: 2,
      Zone: {
        Radius: "",
        Zipcode1:
          this.state.zipcodeOptions.length > 1 ||
          this.state.zipcodeOptions.length > 2 ||
          this.state.zipcodeOptions.length > 3 ||
          this.state.zipcodeOptions.length === 1
            ? this.state.zipcodeOptions[0]
            : "",
        Zipcode2:
          this.state.zipcodeOptions.length > 2 ||
          this.state.zipcodeOptions.length === 2
            ? this.state.zipcodeOptions[1]
            : "",
        Zipcode3:
          this.state.zipcodeOptions.length === 3
            ? this.state.zipcodeOptions[2]
            : "",
        CityName: "",
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/competitorbycoverage", data)
      .then((response) => {
        let coverageTrackCount = response.data.data.count;
        this.setState({
          coverageTrackCount,
          isLoading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  }
  ModalClickOpen(UserStoreID) {
    this.lookupZipcode(UserStoreID);
    this.lookupCity(UserStoreID);
    this.setState({
      isLoading1: true,
    });
    let showComponent = this.state.showComponent;

    let userStoreID = this.state.userStoreID;
    userStoreID = UserStoreID;
    const selectedCity = {
      label: "",
      value: "",
    };
    this.setState({
      open: true,
      userStoreID,
      title: "Add Market",
      title1: "Add Address",
      EditStoredata: {},
      isDefaultValue: 1,
      isLoading1: true,
    });

    if (userStoreID !== 0) {
      this.setState({
        title: "Edit Market",
        isLoading1: true,
        title1: "Update Address",
      });

      axios
        .post(
          CONFIG.API_URL +
            "market/storedetails/" +
            userStoreID +
            "/" +
            CONFIG.RegionId
        )
        .then((response) => {
          let EditStoredata = response.data.userstore;
          const isDefaultValue = [];
          const stateData = this.state.stateData;
          const countryData = this.state.countryData;
          let zipcodeOptions = response.data.zipcode;
          let coverSearchType = response.data.userstore.searchType;
          let oldAddress = this.state.oldAddress;
          oldAddress = {
            Address: EditStoredata.address,
            Address2: EditStoredata.address2,
            City: EditStoredata.city,
            State: EditStoredata.state,
            Zipcode: EditStoredata.zipcode,
          };
          if (coverSearchType === 1) {
            showComponent = "radius";
          } else if (coverSearchType === 2) {
            showComponent = "zipcode";
          } else if (coverSearchType === 3) {
            showComponent = "city";
            selectedCity.label = zipcodeOptions
              ? zipcodeOptions[0] + ", " + zipcodeOptions[1]
              : "";
            selectedCity.value = zipcodeOptions
              ? zipcodeOptions[0] + "," + zipcodeOptions[1]
              : "";
          }
          stateData
            .filter((con) => con.stateID === EditStoredata.state)
            .forEach(function (con) {
              isDefaultValue.unshift(con.countryCode);
            });
          //stateData.filter((con) => con.stateID === e.target.value).find(con.countryCode);
          countryData
            .filter((con) => con.countryCode === isDefaultValue.toString())
            .forEach(function (con) {
              isDefaultValue.unshift(con.country);
            });
          isDefaultValue.toString();

          let scale =
            EditStoredata.zoneCoverage !== null && coverSearchType === 1
              ? EditStoredata.zoneCoverage.indexOf("10") > -1
                ? 10
                : EditStoredata.zoneCoverage.charAt(0)
              : 10;
          this.setState({
            coverageType:
              coverSearchType === 1
                ? "radius"
                : coverSearchType === 2
                ? "zipcode"
                : coverSearchType === 3
                ? "city"
                : "radius",
            EditStoredata,
            oldAddress,
            scale,
            StateCountry: isDefaultValue[0],
            isDefaultValue,
            isSelactCity: coverSearchType === 3 ? true : false,
            selectedCityOption: coverSearchType === 3 ? selectedCity : [],
            showComponent,
            zipcodeOptions,
            isLoading1: false,
          });
          this.trackCoverageCount();
        })
        .catch((err) => {
          this.setState({
            isLoading1: false,
          });
          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.message);
          }
        });
    }
  }
  ModalClickOpenCR(UserStoreID) {
    let filterlookupZipcodeData = this.state.filterlookupZipcodeData;
    filterlookupZipcodeData = [];
    let userStoreID = this.state.userStoreID;
    userStoreID = UserStoreID;
    this.setState({
      isLoading1: true,
      filterlookupZipcodeData,
      userStoreID,
    });
    this.lookupZipcode1(UserStoreID);
  }

  dummyFn(UserStoreID) {
    let userStoreID = UserStoreID;
    let zipcodeOptions = [];
    let showComponent = this.state.showComponent;
    const selectedCity = {
      label: "",
      value: "",
    };

    this.setState({
      userStoreID,
      title: "Add Market",
      title1: "Add Address",
      EditStoredata: {},
      isDefaultValue: 1,
      isLoading1: true,
    });
    if (userStoreID !== 0) {
      this.setState({
        title: "Edit Market",
        isLoading1: true,
        title1: "Update Address",
        activeStep: 1,
      });

      axios
        .post(
          CONFIG.API_URL +
            "market/storedetails/" +
            userStoreID +
            "/" +
            CONFIG.RegionId
        )
        .then((response) => {
          let EditStoredata = response.data.userstore;
          let oldAddress = this.state.oldAddress;
          oldAddress = {
            Address: EditStoredata.address,
            Address2: EditStoredata.address2,
            City: EditStoredata.city,
            State: EditStoredata.state,
            Zipcode: EditStoredata.zipcode,
          };
          const isDefaultValue = [];
          const stateData = this.state.stateData;
          const countryData = this.state.countryData;
          zipcodeOptions = response.data.zipcode;
          let coverSearchType = response.data.userstore.searchType;
          if (coverSearchType === 1) {
            showComponent = "radius";
          } else if (coverSearchType === 2) {
            showComponent = "zipcode";
          } else if (coverSearchType === 3) {
            showComponent = "city";
            if (CONFIG.RegionId === MatchingCountryCode.UK_RID) {
              selectedCity.label = zipcodeOptions ? zipcodeOptions[0] : "";
              selectedCity.value = zipcodeOptions ? zipcodeOptions[0] : "";
            } else {
              selectedCity.label = zipcodeOptions
                ? zipcodeOptions[0] + ", " + zipcodeOptions[1]
                : "";
              selectedCity.value = zipcodeOptions
                ? zipcodeOptions[0] + "," + zipcodeOptions[1]
                : "";
            }
          }

          stateData
            .filter((con) => con.stateID === EditStoredata.state)
            .forEach(function (con) {
              isDefaultValue.unshift(con.countryCode);
            });
          //stateData.filter((con) => con.stateID === e.target.value).find(con.countryCode);
          countryData
            .filter((con) => con.countryCode === isDefaultValue.toString())
            .forEach(function (con) {
              isDefaultValue.unshift(con.country);
            });
          isDefaultValue.toString();

          let scale =
            EditStoredata.zoneCoverage !== null && coverSearchType === 1
              ? EditStoredata.zoneCoverage.indexOf("10") > -1
                ? 10
                : EditStoredata.zoneCoverage.charAt(0)
              : 10;
          this.setState({
            coverageType:
              coverSearchType === 1
                ? "radius"
                : coverSearchType === 2
                ? "zipcode"
                : coverSearchType === 3
                ? "city"
                : "radius",
            EditStoredata,
            oldAddress,
            StateCountry: isDefaultValue[0],
            isSelactCity: coverSearchType === 3 ? true : false,
            selectedCityOption: coverSearchType === 3 ? selectedCity : [],
            scale,
            isDefaultValue,
            showComponent,
            zipcodeOptions,
            open: true,
            isLoading1: false,
          });
          this.trackCoverageCount();
        })
        .catch((err) => {
          this.setState({
            isLoading1: false,
          });
          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.message);
          }
        });
    }
  }

  cityHandleChange = (selectedCityOption) => {
    this.setState({
      selectedCityOption,
      errortext1: "",
      coverageType: "city",
      isSelactCity: true,
    });
    this.setState({
      isLoading1: true,
    });
    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: "",
      SearchType: 3,
      Zone: {
        Radius: "",
        Zipcode1: "",
        Zipcode2: "",
        Zipcode3: "",
        CityName: selectedCityOption.value.replace(",", ", "),
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/competitorbycoverage", data)
      .then((response) => {
        let coverageTrackCount = response.data.data.count;
        this.setState({
          coverageTrackCount,
          isLoading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  };

  zipHandleChange = (selectedZipOption) => {
    if (selectedZipOption.length <= 3) {
      this.setState({ selectedZipOption, coverageType: "zipcode" });
      // if (selectedZipOption[0].value != '') {

      // }
      this.trackCoverageCount();
    } else {
      alert(
        "You cannot select more than 3 zipcodes! Please remove a selection and try again."
      );
    }
  };

  onDragEnd(e) {}

  tabhandleChange(event, newValue) {
    if (newValue === 0) this.getbounds = true;
    this.setState({ tabvalue: newValue, showingInfoWindow: false }, () => {
      if (
        this.state.coverageType == "city" &&
        newValue === 1 &&
        CONFIG.RegionId === 1
      ) {
        var city = this.state.selectedCityOption.value;
        this.getMapQuestResultCity(city);
      } else {
        if (
          this.state.coverageType == "zipcode" &&
          newValue === 1 &&
          CONFIG.RegionId === 1
        ) {
          var zipInput = this.state.zipcodeOptions;
          this.getMapQuestResultZip(zipInput);
        }
      }
    });
  }

  coverageType = (e) => {
    let showComponent = this.state.showComponent;
    let zipcodeOptions = this.state.zipcodeOptions;
    let selectedCityOption = this.state.selectedCityOption;
    let scale = this.state.scale;
    let isSelactCity = this.state.isSelactCity;
    let zipcodeOptionsLength;
    let errortext1;
    let ZipcodeFilter = this.state.ZipcodeFilter;
    let trackedFields = [];
    let trackedFields1 = [];
    let unTrackedCount = [];

    if (e.target.value === "radius") {
      selectedCityOption = [];
      zipcodeOptions = [];
      errortext1 = "";
      zipcodeOptionsLength = null;
      ZipcodeFilter = "";
      scale = 10;
      this.trackCoverageCountRadius(scale);
    } else if (e.target.value === "zipcode") {
      // this.lookupZipcode();
      // this.state.isLoading = true;
      selectedCityOption = [];
      scale = 10;
      errortext1 = "";
      zipcodeOptionsLength = null;
      ZipcodeFilter = "";
      zipcodeOptions = [];
      let filterlookupZipcodeData = this.state.lookupZipcodeData;
      filterlookupZipcodeData = filterlookupZipcodeData.filter((lookup) => {
        let lookupName = lookup.value.toLowerCase();
        return lookupName.indexOf(ZipcodeFilter.toLowerCase()) !== -1;
      });
      this.setState({
        filterlookupZipcodeData,
        ZipcodeFilter,
        coverageTrackCount: 0,
      });
    } else if (e.target.value === "city") {
      // this.lookupCity();
      zipcodeOptions = [];
      scale = 10;
      errortext1 = "";
      zipcodeOptionsLength = null;
      ZipcodeFilter = "";
      this.setState({
        coverageTrackCount: 0,
      });
    }

    if (e.target.checked) {
      showComponent = e.target.value;
    }
    this.setState({
      trackedFields,
      trackedFields1,
      isSelactCity: false,
      unTrackedCount,
      showComponent,
      ZipcodeFilter,
      selectedCityOption,
      zipcodeOptions,
      scale,
      errortext1,
      zipcodeOptionsLength,
    });
    if (e.target.value === "radius") {
      this.trackCoverageCountRadius(this.state.scale);
    }
  };
  trackCoverageCountRadius(scale) {
    this.setState({
      isLoading1: true,
    });
    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: "",
      SearchType: 1,
      Zone: {
        Radius: scale,
        Zipcode1: "",
        Zipcode2: "",
        Zipcode3: "",
        CityName: "",
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/competitorbycoverage", data)
      .then((response) => {
        let coverageTrackCount = response.data.data.count;
        this.setState({
          coverageTrackCount,
          isLoading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  }

  // raduisChange = (event, value) => {
  //     this.setState({ value });
  // };
  trackModal(storeId, marketName) {
    this.setState({
      marketStoreName: marketName,
    });
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(
        CONFIG.API_URL + "market/competitors/" + storeId + "/" + CONFIG.RegionId
      )
      .then((response) => {
        // this.state.isLoading=true;
        const trackModalData = response.data.competitor.competitorsList;
        let strOptions = [];
        trackModalData.forEach(function (e) {
          strOptions.push(e.storeID + (e.isTracked ? "|1" : "|0"));
        });
        this.setState({
          trackModalData,
          storeModal: true,
          strOptions,
        });
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //     localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        // }
        // else {
        //     alert(err.response.data);
        // }
      });
    // this.setState(prevState => ({
    //     storeModal: !prevState.storeModal
    // }));
  }

  trackModalClose() {
    this.setState({
      storeModal: false,
    });
  }

  onMarkerClose = () => {
    this.setState({ showingInfoWindow: false });
  };

  upgradeBtn(plus) {
    this.setState({
      isLoading1: true,
    });
    let errortext = "";
    let data = this.state.data;
    data.Plus = plus;
    data.RegionId = CONFIG.RegionId;
    this.setState({
      data,
    });
    axios
      .post(CONFIG.API_URL + "market/add", data)
      .then((response) => {
        let userStoreID = response.data.userStoreID;
        this.setState({
          isLoading1: false,
          userStoreID,
        });
        if (response.status === 200) {
          const already = response.data.result
            ? response.data.result.indexOf("Store name already exists!")
            : -1;
          const storenotExist = response.data.result
            ? response.data.result.indexOf("Store does not exists!")
            : -1;
          const addressNotFound = response.data.result
            ? response.data.result.indexOf("Address not found!")
            : -1;

          if (already > -1) {
            errortext =
              "Market with same name is available in your account. Please rename the market name to proceed further!";
            this.setState({
              errortext,
            });
          }
          //else if (response.data.result === 'Store does not exists!') {
          else if (storenotExist > -1) {
            this.errorHandleOpen();
            let addInputData = response.data.data;
            this.setState({
              addInputData,
            });
          } else if (addressNotFound > -1) {
            let addInputData = response.data.data;
            this.setState({
              addInputData,
              open2: true,
            });
          } else if (userStoreID >= 1 && this.state.serachTypeval !== 1) {
            let addInputData = response.data.data;
            this.setState({
              addInputData,
            });
            this.lookupZipcode(userStoreID);
            this.lookupCity(userStoreID);
            this.setState({ activeStep: this.state.activeStep + 1 });
          } else if (userStoreID >= 1 && this.state.serachTypeval === 1) {
            let addInputData = response.data.data;
            this.setState({
              addInputData,
            });
            this.setState({ activeStep: this.state.activeStep + 1 });
            this.lookupZipcode(userStoreID);
            this.lookupCity(userStoreID);
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 400) {
          alert(err.message);
        }

        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  setMapFitBounds = () => {
    let { competitorsData } = this.state;
    if (
      this.getbounds &&
      this.state.showComponent !== "radius" &&
      competitorsData?.data?.competitors?.length
    ) {
      this.getbounds = false;
      let bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < competitorsData.data.competitors.length; i++) {
        let loc = new window.google.maps.LatLng(
          competitorsData.data.competitors[i].lat,
          competitorsData.data.competitors[i].lng
        );
        bounds.extend(loc);
      }
      let homeLoc = new window.google.maps.LatLng(
        competitorsData.data.currentStoreDetail.lat,
        competitorsData.data.currentStoreDetail.lng
      );
      bounds.extend(homeLoc);
      this.mapRef.current.fitBounds(bounds);
    }
  };

  addMarket(event, errors, values) {
    if (errors.length <= 0) {
      this.setState({
        isLoading1: true,
      });
      this.btn0.setAttribute("disabled", "disabled");

      // if (this.state.serachTypeval === 1) {
      //     const data = {
      //         "StoreName": values.marketName,
      //         "Address": values.addressLine1,
      //         "Address2": values.addressLine2,
      //         "City": values.city,
      //         "State": values.state,
      //         "Zipcode": values.zipcode,
      //         "UserStoreID": this.state.userStoreID,
      //         "Longitude": "",
      //         "Latitude": "",
      //         "Status": 1,
      //         "Plus": this.state.serachTypeval === 2 ? false : this.state.serachTypeval === 4 ? true : false,
      //         "IsExisting": values.exestineStore,
      //         "IsConfirm": false,
      //         "Website": ''
      //     }
      //     this.setState({ data, isLoading1: false });
      //     // this.lookupZipcode();
      //     // this.setState({ activeStep: this.state.activeStep + 1 });
      // }
      // else {
      this.setState({
        isLoading1: true,
      });
      //this.setState({ activeStep: this.state.activeStep + 1 });
      let newAddress = this.state.newAddress;
      let oldAddress = this.state.oldAddress;
      let errortext = "";
      newAddress = {
        Address: values.addressLine1,
        Address2: values.addressLine2,
        City: values.city,
        State: values.state,
        Zipcode: values.zipcode,
      };
      this.setState({
        newAddress,
        oldAddress,
      });
      if (
        newAddress.Address === oldAddress.Address &&
        newAddress.Address2 === oldAddress.Address2 &&
        newAddress.City === oldAddress.City &&
        newAddress.State === oldAddress.State &&
        newAddress.Zipcode === oldAddress.Zipcode
      ) {
      } else {
        let showComponent = this.state.showComponent;
        let selectedCityOption = this.state.selectedCityOption;
        let zipcodeOptions = this.state.zipcodeOptions;
        let scale = this.state.scale;
        showComponent = "radius";
        zipcodeOptions = [];
        selectedCityOption = [];
        scale = 10;
        this.setState({
          showComponent,
          selectedCityOption,
          zipcodeOptions,
          scale,
          isSelactCity: false,
        });
      }

      // if(newAddress != oldAddress){
      //     let showComponent = this.state.showComponent;
      //     let selectedCityOption = this.state.selectedCityOption;
      //     let zipcodeOptions = this.state.zipcodeOptions;
      //     let scale = this.state.scale;
      //     showComponent = 'radius';
      //     zipcodeOptions = [];
      //     selectedCityOption = [];
      //     scale = 10;
      //     this.setState({
      //         showComponent, selectedCityOption, zipcodeOptions, scale
      //     });
      // }
      const data = {
        StoreName: values.marketName,
        Address: values.addressLine1,
        Address2: values.addressLine2,
        City: values.city,
        State: values.state ? values.state : "",
        Zipcode: values.zipcode,
        UserStoreID: this.state.userStoreID,
        Longitude: "",
        Latitude: "",
        Status: 1,
        Plus:
          this.state.serachTypeval === 2
            ? false
            : this.state.serachTypeval === 4
            ? true
            : false,
        IsExisting: values.exestineStore,
        IsConfirm: false,
        Website: "",
        RegionId: CONFIG.RegionId,
      };
      //localStorage.setItem(data);
      axios
        .post(CONFIG.API_URL + "market/add", data)
        .then((response) => {
          let userStoreID = this.state.userStoreID;
          userStoreID = response.data.userStoreID
            ? response.data.userStoreID
            : this.state.userStoreID;
          //     if(userStoreID > 0 && userStoreID !== undefined) {
          //     this.setState({
          //        userStoreID
          //     })

          // }
          // else if(userStoreID === undefined){
          //     let userStoreID = this.state.userStoreID
          //     this.setState({
          //         userStoreID
          //      })
          // }
          this.setState({
            isLoading1: false,
            userStoreID,
          });
          if (response.status === 200) {
            const already = response.data.result
              ? response.data.result.indexOf("Store name already exists!")
              : -1;
            const storenotExist = response.data.result
              ? response.data.result.indexOf("Store does not exists!")
              : -1;
            const addressNotFound = response.data.result
              ? response.data.result.indexOf("Address not found!")
              : -1;

            if (already > -1) {
              let addInputData = response.data.data;
              errortext =
                "Market with same name is available in your account. Please rename the market name to proceed further!";
              this.setState({
                errortext,
                addInputData,
                marketName: values.marketName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state,
                zipcode: values.zipcode,
                exestineStore: values.exestineStore,
                userStoreID: this.state.userStoreID,
                oldAddress: newAddress,
              });
              this.btn0.removeAttribute("disabled");
            } else if (storenotExist > -1) {
              this.errorHandleOpen();
              let addInputData = response.data.data;
              this.setState({
                errortext: "",
                addInputData,
                marketName: values.marketName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state ? values.state : "",
                zipcode: values.zipcode,
                exestineStore: values.exestineStore,
                userStoreID: this.state.userStoreID,
                oldAddress: newAddress,
              });
              this.btn0.removeAttribute("disabled");
            } else if (addressNotFound > -1) {
              let addInputData = response.data.data;
              this.setState({
                errortext: "",
                addInputData,
                open2: true,
                marketName: values.marketName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state ? values.state : "",
                zipcode: values.zipcode,
                exestineStore: values.exestineStore,
                userStoreID: this.state.userStoreID,
                oldAddress: newAddress,
              });
              this.btn0.removeAttribute("disabled");
            } else if (userStoreID >= 1 && this.state.serachTypeval !== 1) {
              let addInputData = response.data.data;
              this.setState({
                errortext: "",
                addInputData,
                marketName: values.marketName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state,
                zipcode: values.zipcode,
                exestineStore: values.exestineStore,
                userStoreID: this.state.userStoreID,
                oldAddress: newAddress,
              });
              this.lookupZipcode(this.state.userStoreID);
              this.lookupCity(this.state.userStoreID);
              if (this.state.showComponent === "radius") {
                this.trackCoverageCountRadius(this.state.scale);
              }
              this.setState({ activeStep: this.state.activeStep + 1 });
              localStorage.setItem("displayMessage", 1);
            } else if (userStoreID >= 1 && this.state.serachTypeval === 1) {
              let addInputData = response.data.data;
              this.setState({
                errortext: "",
                addInputData,
                zipcode: values.zipcode,
                marketName: values.marketName,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                city: values.city,
                state: values.state,
                zipcode: values.zipcode,
                exestineStore: values.exestineStore,
                userStoreID: this.state.userStoreID,
                oldAddress: newAddress,
              });

              if (this.state.showComponent === "radius") {
                this.trackCoverageCountRadius(this.state.scale);
              }
              this.setState({ activeStep: this.state.activeStep + 1 });
              localStorage.setItem("displayMessage", 1);
              this.lookupZipcode(this.state.userStoreID);
              this.lookupCity(this.state.userStoreID);
            }
          }
        })
        .catch((err) => {
          this.btn0.removeAttribute("disabled");
          this.setState({
            isLoading1: false,
            marketName: values.marketName,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            exestineStore: values.exestineStore,
            userStoreID: this.state.userStoreID,
          });
          if (err.response.status === 400) {
            alert(err.message);
          }

          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.message);
          }
        });

      //}
    }
  }
  lookupZipcode1(userStoreID) {
    this.setState({
      isLoading1: true,
    });
    const data = {
      userStoreID: userStoreID,
      RegionId: CONFIG.RegionId,
    };
    let unzipcodeOptions = [];
    axios
      .post(CONFIG.API_URL + "coverage/zipcode", data)
      .then((response) => {
        let lookupZipcodeData = response.data.zipcode;
        lookupZipcodeData.forEach(function (e) {
          unzipcodeOptions.push(e.value);
        });

        this.setState({
          lookupZipcodeData,
          filterlookupZipcodeData: lookupZipcodeData,
          unzipcodeOptions,
          isLoading1: false,
        });
        this.lookupCity1(userStoreID);
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  lookupCity1(userStoreID) {
    this.setState({
      isLoading1: true,
    });
    const data = {
      userStoreID: userStoreID,
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(CONFIG.API_URL + "coverage/city", data)
      .then((response) => {
        let lookupCityData = response.data.city;
        this.setState({
          lookupCityData,
          isLoading1: false,
        });
        this.dummyFn(userStoreID);
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  lookupZipcode(userStoreID) {
    this.setState({
      isLoading1: true,
    });
    const data = {
      userStoreID: userStoreID,
      RegionId: CONFIG.RegionId,
    };
    let unzipcodeOptions = [];
    axios
      .post(CONFIG.API_URL + "coverage/zipcode", data)
      .then((response) => {
        let lookupZipcodeData = response.data.zipcode;
        lookupZipcodeData.forEach(function (e) {
          unzipcodeOptions.push(e.value);
        });

        this.setState({
          lookupZipcodeData,
          filterlookupZipcodeData: lookupZipcodeData,
          unzipcodeOptions,
          isLoading1: false,
        });
        return true;
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
        return false;
      });
  }
  lookupCity(userStoreID) {
    this.setState({
      isLoading1: true,
    });
    const data = {
      userStoreID: userStoreID,
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(CONFIG.API_URL + "coverage/city", data)
      .then((response) => {
        let lookupCityData = response.data.city;
        this.setState({
          lookupCityData,
          isLoading1: false,
        });
        return true;
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
    return false;
  }

  radiusOnChange(scalevalue, value) {
    let scale = value;
    // this.setState({ scale: scalevalue.target.value, coverageType: 'radius' });
    this.setState({ scale: scale, coverageType: "radius" });
    this.setState({
      isLoading1: true,
    });
    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: "",
      SearchType: 1,
      Zone: {
        // "Radius": scalevalue.target.value,
        Radius: scale,
        Zipcode1: "",
        Zipcode2: "",
        Zipcode3: "",
        CityName: "",
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/competitorbycoverage", data)
      .then((response) => {
        let coverageTrackCount = response.data.data.count;
        this.setState({
          coverageTrackCount,
          isLoading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  }

  trackCoverageCount() {
    this.setState({
      isLoading1: true,
    });
    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: this.state.trackedFields.toString(),
      SearchType:
        this.state.coverageType === "radius"
          ? 1
          : this.state.coverageType === "zipcode"
          ? 2
          : this.state.coverageType === "city"
          ? 3
          : "",
      Zone: {
        Radius: this.state.coverageType === "radius" ? this.state.scale : null,
        Zipcode1:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 1 ||
            this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length > 3 ||
            this.state.zipcodeOptions.length === 1)
            ? this.state.zipcodeOptions[0]
            : "",
        Zipcode2:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length === 2)
            ? this.state.zipcodeOptions[1]
            : "",
        Zipcode3:
          this.state.coverageType === "zipcode" &&
          this.state.zipcodeOptions.length === 3
            ? this.state.zipcodeOptions[2]
            : "",
        CityName:
          this.state.coverageType === "city"
            ? this.state.selectedCityOption.value.replace(",", ", ")
            : null,
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/competitorbycoverage", data)
      .then((response) => {
        let coverageTrackCount = response.data.data.count;
        this.setState({
          coverageTrackCount,
          isLoading1: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  }

  addCoverage(event, errors, values) {
    let zipcodeOptionsLength = this.state.zipcodeOptions
      ? this.state.zipcodeOptions.length
      : 0;
    if (this.state.showComponent === "radius" && this.state.scale === 0) {
      let errortext1 = "Please Choose Radius Value";
      this.setState({
        errortext1,
      });
    }
    if (this.state.showComponent === "zipcode" && zipcodeOptionsLength === 0) {
      let errortext1 =
        this.state.zipError === "" ? "Please Choose Zip or Postal Code(s)" : "";
      this.setState({
        errortext1,
      });
    }
    if (
      this.state.showComponent === "city" &&
      this.state.selectedCityOption.value === undefined
    ) {
      let errortext1 = "Please Choose City Name";
      this.setState({
        errortext1,
      });
    } else if (
      (this.state.showComponent === "radius" && this.state.scale > 0) ||
      (this.state.showComponent === "zipcode" && zipcodeOptionsLength > 0) ||
      (this.state.showComponent === "city" &&
        this.state.selectedCityOption.value !== "")
    ) {
      this.btn1.setAttribute("disabled", "disabled");
      this.setState({
        isLoading1: true,
        errortext1: "",
      });
      let scale = this.state.scale;

      const data = {
        userStoreID: this.state.userStoreID,
        SearchType:
          this.state.showComponent === "radius"
            ? 1
            : this.state.showComponent === "zipcode"
            ? 2
            : this.state.showComponent === "city"
            ? 3
            : "",
        Zone: {
          Radius: this.state.showComponent === "radius" ? this.state.scale : "",
          Zipcode1:
            this.state.showComponent === "zipcode" &&
            (this.state.zipcodeOptions.length > 1 ||
              this.state.zipcodeOptions.length > 2 ||
              this.state.zipcodeOptions.length > 3 ||
              this.state.zipcodeOptions.length === 1)
              ? this.state.zipcodeOptions[0]
              : "",
          Zipcode2:
            this.state.showComponent === "zipcode" &&
            (this.state.zipcodeOptions.length > 2 ||
              this.state.zipcodeOptions.length === 2)
              ? this.state.zipcodeOptions[1]
              : "",
          Zipcode3:
            this.state.showComponent === "zipcode" &&
            this.state.zipcodeOptions.length === 3
              ? this.state.zipcodeOptions[2]
              : "",
          CityName:
            this.state.showComponent === "city"
              ? this.state.selectedCityOption.value.replace(",", ", ")
              : "",
        },
        isCoverageZone: 1,
        isFirstDisplay: 1,
        RegionId: CONFIG.RegionId,
      };
      let coverageType = this.state.showComponent;
      let zipcodevalues = values.zipcodevalues;
      let cityvalue = values.cityvalue;
      if (coverageType == "zipcode") {
        let zipcodevalues = this.state.zipcodeOptions[0];
        let zipcodevalues1 = this.state.zipcodeOptions[1];
        let zipcodevalues2 = this.state.zipcodeOptions[2];
        const zoomValue =
          coverageType == "zipcode" &&
          zipcodevalues !== undefined &&
          zipcodevalues1 !== undefined &&
          zipcodevalues !== undefined
            ? 9
            : coverageType == "zipcode" &&
              zipcodevalues !== undefined &&
              zipcodevalues1 !== undefined
            ? 11
            : coverageType == "zipcode" && zipcodevalues !== undefined
            ? 12
            : "";
        this.setState({ zoomValue: zoomValue });
        // if (zipcodevalues !== undefined) {
        //     this.zip1Polygon(zipcodevalues);
        // }
        // if (zipcodevalues1 !== undefined) {
        //     this.zip2Polygon(zipcodevalues1);
        // }
        // if (zipcodevalues2 !== undefined) {
        //     this.zip3Polygon(zipcodevalues2);
        // }
      }
      axios
        .post(CONFIG.API_URL + "coverage/searchcompetitors", data)
        .then((response) => {
          let competitorsData = response.data;
          if (response.data.data.currentStoreDetail.coverage == "Radius") {
            let zoomLevel = response.data.data.currentStoreDetail.radius;
            let zoomValue =
              zoomLevel === 1 || zoomLevel === 2
                ? 11
                : zoomLevel === 3 || zoomLevel === 4
                ? 10
                : zoomLevel === 5 || zoomLevel === 6
                ? 10
                : zoomLevel === 7 ||
                  zoomLevel === 8 ||
                  zoomLevel === 9 ||
                  zoomLevel === 10
                ? 10
                : 6;
            this.setState({ zoomValue });
          }
          let trackedFields = [];
          let unTrackedCount = [];
          let trackedFields1 = [];
          let uniqzipcode = [
            ...new Set(
              competitorsData.data.competitors.map((item) => item.zipCode)
            ),
          ];
          competitorsData.data.competitors.forEach(function (e) {
            trackedFields.push(e.storeId + (e.isTrack === 1 ? "|1" : "|0"));
          });
          competitorsData.data.competitors
            .filter((e) => e.isTrack === 0)
            .forEach(function (e) {
              unTrackedCount.push(e.storeId);
            });
          // competitorsData.data.competitors.filter((e) => e.isTrack === 0).forEach(function (e) { trackedFields1.push(e.storeId + '|0') });
          competitorsData.data.competitors.forEach(function (e) {
            trackedFields1.push(e.storeId + (e.isTrack === 1 ? "|1" : "|0"));
          });
          if (unTrackedCount === undefined) {
            unTrackedCount = [];
          }
          if (trackedFields1 === undefined) {
            trackedFields1 = [];
          }

          if (trackedFields === undefined) {
            trackedFields = [];
          }

          this.setState({
            isLoading1: false,
            competitorsData,
            activeStep: this.state.activeStep + 1,
            scale,
            trackedFields,
            trackedFields1,
            unTrackedCount,
            coverageType,
            zipcodevalues,
            cityvalue,
            uniqzipcode,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading1: false,
          });
          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.response.data);
          }
          this.btn1.removeAttribute("disabled");
        });
      this.setState({
        coverageType,
        zipcodevalues,
        cityvalue,
      });
    }
  }

  closeModal() {
    let path = `/my-market/`;
    var isElement = document.getElementById("r2");
    if (isElement == null) {
      document.getElementById("r1").click();
    } else {
      document.getElementById("r1").click();
      document.getElementById("r2").click();
    }

    this.setState({
      isLoading1: true,
    });
    let showComponent = this.state.showComponent;
    let selectedCityOption = this.state.selectedCityOption;
    let zipcodeOptions = this.state.zipcodeOptions;
    let scale = this.state.scale;
    let unTrackedCount = this.state.unTrackedCount;
    let trackedFields = this.state.trackedFields;
    let trackedFields1 = this.state.trackedFields1;
    let userStoreID = this.state.userStoreID;
    let newAddress = this.state.newAddress;
    let oldAddress = this.state.oldAddress;
    let tabvalue = this.state.tabvalue;
    tabvalue = 0;
    newAddress = {};
    oldAddress = {};
    userStoreID = 0;
    showComponent = "radius";
    zipcodeOptions = [];
    selectedCityOption = [];
    unTrackedCount = [];
    trackedFields = [];
    trackedFields1 = [];
    scale = 10;
    this.setState({
      tabvalue,
      isLoading1: false,
      isSelactCity: false,
      unTrackedCount,
      trackedFields,
      trackedFields1,
      open: false,
      activeStep: 0,
      showComponent,
      selectedCityOption,
      zipcodeOptions,
      scale,
      errortext: "",
      errortext1: "",
      pageCountMDT: 0,
      marketName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      exestineStore: "true",
      addInputData: "",
      StateCountry: "",
      userStoreID,
      coverageTrackCount: 0,
      oldAddress,
      newAddress,
    });
  }
  closeModalAddress() {
    this.setState({ open2: false });
  }
  closeModalWebsite() {
    this.setState({ open1: false });
  }
  onCountryChange(e) {
    // const isDefaultValue = [];
    // const stateData = this.state.stateData;
    // const countryData = this.state.countryData;
    // stateData.filter((con) => con.stateID === e.target.value).forEach(function (con) { isDefaultValue.unshift(con.countryCode) });
    // //stateData.filter((con) => con.stateID === e.target.value).find(con.countryCode);
    // countryData.filter((con) => con.countryCode === isDefaultValue.toString()).forEach(function (con) { isDefaultValue.unshift(con.country) });
    // isDefaultValue.toString();

    // this.setState({ isDefaultValue });
    if (e.target.value === "") {
      this.setState({ StateCountry: "" });
    } else {
      var state = e.target.value.toString().split(",");
      var sc_code = state.length > 0 ? state[1].trim() : "";
      const countryData = this.state.countryData;
      // State's country code based country details
      let countryDetails = countryData.filter(function (data) {
        return data.countryCode === sc_code;
      });

      //Adding Country name
      // fields['country'] = countryDetails[0].countryID;
      this.setState({ StateCountry: countryDetails[0].country });
    }
  }
  handleNext() {
    this.setState({ activeStep: this.state.activeStep + 1 });
  }

  handleBack() {
    // let showComponent = this.state.showComponent;
    // let selectedCityOption = this.state.selectedCityOption;
    // let selectedZipOption = this.state.selectedZipOption;
    // let scale = this.state.scale;
    // if (this.state.activeStep === 2) {
    //     showComponent = 'radius';
    //     selectedZipOption = [];
    //     selectedCityOption = [];
    //     scale = this.state.EditStoredata ? this.state.scale : 10;
    // }
    if (this.state.activeStep === 2) {
      this.setState({ pageCountMDT: 0 });
    }
    this.setState({
      activeStep: this.state.activeStep - 1,
      // showComponent, selectedCityOption, selectedZipOption, scale,
      open1: false,
      open2: false,
      showingInfoWindow: false,
      showingInfoWindowCity: false,
      showingInfoWindowZip: false,
      coordinates_one: [],
      coordinates_one2: [],
      tabvalue: 0,
    });
    this.getbounds = true;
  }

  handleClose() {
    this.setState({
      open: false,
      EditStoredata: {},
      errortext: "",
      marketName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      StateCountry: "",
      exestineStore: "true",
    });
  }
  errorHandleOpen() {
    this.setState({ open1: true });
  }

  addwebsite(event, errors, values) {
    let errortext = "";
    if (errors.length <= 0) {
      this.btn3.setAttribute("disabled", "disabled");
      let data = this.state.addInputData;
      let addInputData = this.state.addInputData;
      data.website = values.website;
      data.userStoreID = this.state.userStoreID;
      data.isConfirm = true;
      data.RegionId = CONFIG.RegionId;
      let exestineStore = this.state.exestineStore;
      exestineStore = data.isExisting;
      let EditStoredata = this.state.EditStoredata;
      if (EditStoredata === !{}) {
        EditStoredata.marketName = data.storeName;
        EditStoredata.addressLine1 = data.address;
        EditStoredata.addressLine2 = data.address2;
        EditStoredata.city = data.city;
        EditStoredata.state = data.state;
        EditStoredata.zipcode = data.zipcode;
        EditStoredata.exestineStore = data.isExisting;
      }
      this.setState({
        data,
        isLoading1: true,
        EditStoredata,
        exestineStore,
      });
      axios
        .post(CONFIG.API_URL + "market/add", data)
        .then((response) => {
          let userStoreID = this.state.userStoreID;
          userStoreID = response.data.userStoreID
            ? response.data.userStoreID
            : this.state.userStoreID;

          this.setState({
            isLoading1: false,
            userStoreID,
          });
          // let userStoreID = response.data.userStoreID;
          // this.setState({
          //     isLoading1: false,
          //     userStoreID
          // })
          const already = response.data.result
            ? response.data.result.indexOf("Store name already exists!")
            : -1;
          const storenotExist = response.data.result
            ? response.data.result.indexOf("Store does not exists!")
            : -1;
          const addressNotFound = response.data.result
            ? response.data.result.indexOf("Address not found!")
            : -1;
          if (response.status === 200) {
            if (already > -1) {
              errortext =
                "Market with same name is available in your account. Please rename the market name to proceed further!";
              this.setState({
                open1: false,
                errortext,
                marketName: addInputData.storeName,
                addressLine1: addInputData.address,
                addressLine2: addInputData.address2,
                city: addInputData.city,
                state: addInputData.state,
                zipcode: addInputData.zipcode,
                exestineStore: addInputData.isExisting,
                userStoreID: this.state.userStoreID,
              });
              this.btn0.removeAttribute("disabled");
            }
            //else if (response.data.result === 'Store does not exists!') {
            else if (storenotExist > -1) {
              this.errorHandleOpen();
              let addInputData = response.data.data;
              this.setState({
                open1: false,
                errortext: "",
                addInputData,
                marketName: addInputData.storeName,
                addressLine1: addInputData.address,
                addressLine2: addInputData.address2,
                city: addInputData.city,
                state: addInputData.state,
                zipcode: addInputData.zipcode,
                exestineStore: addInputData.isExisting,
                userStoreID: this.state.userStoreID,
              });
              this.btn0.removeAttribute("disabled");
            } else if (addressNotFound > -1) {
              let addInputData = response.data.data;
              this.setState({
                open1: false,
                errortext: "",
                addInputData,
                open2: true,
                marketName: addInputData.storeName,
                addressLine1: addInputData.address,
                addressLine2: addInputData.address2,
                city: addInputData.city,
                state: addInputData.state,
                zipcode: addInputData.zipcode,
                exestineStore: addInputData.isExisting,
                userStoreID: this.state.userStoreID,
              });
              this.btn0.removeAttribute("disabled");
            } else if (userStoreID >= 1 && this.state.serachTypeval !== 1) {
              let addInputData = response.data.data;
              this.setState({
                open1: false,
                errortext: "",
                addInputData,
                marketName: addInputData.storeName,
                addressLine1: addInputData.address,
                addressLine2: addInputData.address2,
                city: addInputData.city,
                state: addInputData.state,
                zipcode: addInputData.zipcode,
                exestineStore: addInputData.isExisting,
                userStoreID: this.state.userStoreID,
              });
              this.lookupZipcode(this.state.userStoreID);
              this.lookupCity(this.state.userStoreID);
              if (this.state.showComponent === "radius") {
                this.trackCoverageCountRadius(this.state.scale);
              }
              this.setState({ activeStep: this.state.activeStep + 1 });
              localStorage.setItem("displayMessage", 1);
            } else if (userStoreID >= 1 && this.state.serachTypeval === 1) {
              let addInputData = response.data.data;
              this.setState({
                open1: false,
                errortext: "",
                addInputData,
                marketName: addInputData.storeName,
                addressLine1: addInputData.address,
                addressLine2: addInputData.address2,
                city: addInputData.city,
                state: addInputData.state,
                zipcode: addInputData.zipcode,
                exestineStore: addInputData.isExisting,
                userStoreID: this.state.userStoreID,
              });

              if (this.state.showComponent === "radius") {
                this.trackCoverageCountRadius(this.state.scale);
              }
              this.setState({
                activeStep: this.state.activeStep + 1,
                already: "",
              });
              localStorage.setItem("displayMessage", 1);
              this.lookupZipcode(this.state.userStoreID);
              this.lookupCity(this.state.userStoreID);
            }
          }
        })
        .catch((err) => {
          this.setState({
            isLoading1: false,
          });
          if (err.response.status === 400) {
            alert(err.message);
          }

          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.response.data.Message);
          }
          this.btn0.removeAttribute("disabled");
        });

      this.setState({ open1: false });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };

  handleChangePageMTD = (event, pageCountMDT) => {
    this.setState({ pageCountMDT });
  };

  handleChangeRowsPerPageMTD = (event) => {
    this.setState({ pageCountMDT: 0, 10: event.target.value });
  };

  handleChangeRowsPerPageTrack = (event) => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  saveCovergae() {
    this.btn2.setAttribute("disabled", "disabled");
    this.setState({
      isLoading1: true,
    });

    const data = {
      UserStoreID: this.state.userStoreID,
      Competitorslist: this.state.trackedFields.toString(),
      SearchType:
        this.state.coverageType === "radius"
          ? 1
          : this.state.coverageType === "zipcode"
          ? 2
          : this.state.coverageType === "city"
          ? 3
          : "",
      Zone: {
        Radius: this.state.coverageType === "radius" ? this.state.scale : null,
        Zipcode1:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 1 ||
            this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length > 3 ||
            this.state.zipcodeOptions.length === 1)
            ? this.state.zipcodeOptions[0]
            : "",
        Zipcode2:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length === 2)
            ? this.state.zipcodeOptions[1]
            : "",
        Zipcode3:
          this.state.coverageType === "zipcode" &&
          this.state.zipcodeOptions.length === 3
            ? this.state.zipcodeOptions[2]
            : "",
        CityName:
          this.state.coverageType === "city"
            ? this.state.selectedCityOption.value.replace(",", ", ")
            : null,
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "coverage/trackcompetitor", data)
      .then((response) => {
        // this.state.isLoading=true;
        const trackResponse = response.data;
        this.setState({
          isLoading1: true,
        });
        const data = {
          UserStoreID: this.state.userStoreID,
          SearchType:
            this.state.coverageType === "radius"
              ? 1
              : this.state.coverageType === "zipcode"
              ? 2
              : this.state.coverageType === "city"
              ? 3
              : "",
          Zone: {
            Radius:
              this.state.coverageType === "radius" ? this.state.scale : null,
            Zipcode1:
              this.state.coverageType === "zipcode" &&
              (this.state.zipcodeOptions.length > 1 ||
                this.state.zipcodeOptions.length > 2 ||
                this.state.zipcodeOptions.length > 3 ||
                this.state.zipcodeOptions.length === 1)
                ? this.state.zipcodeOptions[0]
                : null,
            Zipcode2:
              this.state.coverageType === "zipcode" &&
              (this.state.zipcodeOptions.length > 2 ||
                this.state.zipcodeOptions.length === 2)
                ? this.state.zipcodeOptions[1]
                : null,
            Zipcode3:
              this.state.coverageType === "zipcode" &&
              this.state.zipcodeOptions.length === 3
                ? this.state.zipcodeOptions[2]
                : null,
            CityName:
              this.state.coverageType === "city"
                ? this.state.selectedCityOption.value.replace(",", ", ")
                : null,
          },
          RegionId: CONFIG.RegionId,
        };

        if (response.status === 200) {
          let token = localStorage.getItem("accessKey");
          if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          }
          axios
            .post(CONFIG.API_URL + "coverage/savecoverage", data)
            .then((response) => {
              // this.state.isLoading=true;
              const trackResponse = response.data;
              // console.log(trackResponse)
              if (response.status === 200) {
                this.setState({
                  //storeModal: false,
                  isLoading1: false,
                  activeStep: this.state.activeStep + 1,
                  trackResponse,
                });
                // let strOptions1 = this.state.strOptions1;
                // strOptions1= [];
                // let strOptions = this.state.strOptions;
                // strOptions= [];
              }
            })
            .catch((err) => {
              this.setState({
                isLoading1: false,
              });
              if (err.response.status === 401) {
                localStorage.removeItem("accessKey");
                const { from } = this.props.location.state || {
                  from: { pathname: "/" },
                };
                this.props.history.push(from);
              } else {
                alert(err.message);
              }
            });

          this.setState({
            storeModal: false,
          });
          // let strOptions1 = this.state.strOptions1;
          // strOptions1= [];
          // let strOptions = this.state.strOptions;
          // strOptions= [];
          //this.componentDidMount();
        }
      })
      .catch((err) => {
        this.setState({
          isLoading1: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.message);
        }
      });
  }

  // sendTrackFields() {
  //     const data = {
  //         "UserStoreID": this.state.trackStorId,
  //         "Competitorslist": this.state.trackedFields1.toString(),
  //         "SearchType": this.state.coverageType === 'radius' ? 1 : this.state.coverageType === 'zipcode' ? 2 : this.state.coverageType === 'city' ? 3 : '',
  //         "Zone": {
  //             "Radius": this.state.scale ? this.state.scale : 10,
  //             "Zipcode1": this.state.zipcodevalues ? this.state.zipcodevalues[0] : null,
  //             "Zipcode2": this.state.zipcodevalues ? this.state.zipcodevalues[1] : null,
  //             "Zipcode3": this.state.zipcodevalues ? this.state.zipcodevalues[2] : null,
  //             "CityName": this.state.cityvalue ? this.state.cityvalue[0] : null
  //         },
  //         "isCoverageZone": 1
  //     }
  //     let token = localStorage.getItem('accessKey');
  //     if (token) {
  //         axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  //     }
  //     axios.post(CONFIG.API_URL + 'coverage/trackcompetitor', data)
  //         .then(response => {
  //             // this.state.isLoading=true;
  //             const trackResponse = response.data;
  //             this.setState({
  //                 isLoading: true
  //             })
  //             if (response.status === 200) {
  //                 this.setState({
  //                     storeModal: false
  //                 })
  //                 // let strOptions1 = this.state.strOptions1;
  //                 // strOptions1= [];
  //                 // let strOptions = this.state.strOptions;
  //                 // strOptions= [];
  //                 this.componentDidMount();
  //             }
  //         }).catch((err) => {
  //             // if (err.response.status === 401) {
  //             //     localStorage.removeItem('accessKey');
  //             //     const { from } = this.props.location.state || { from: { pathname: "/" } };
  //             //     this.props.history.push(from);
  //             // }
  //             // else {
  //             //     alert(err.response.data);
  //             // }
  //         });
  //     this.setState({
  //         isLoading: false
  //     })
  // }
  //     trackhandle (store,userstore)
  //     {
  //         let trackStorId = userstore;
  //         let trackedFields = this.state.trackedFields;
  //         let trackedFields1 = this.state.trackedFields1;
  //         let index;
  //         let index1;
  // if(this.state.track == true)
  // {
  //     index = trackedFields.indexOf(+store + '|0');
  //             index1 = trackedFields1.indexOf(+store + '|0');
  //             if (index >= 0) {
  //                 trackedFields.splice(index, 1)
  //             }
  //             if (index1 >= 0) {
  //                 trackedFields1.splice(index1, 1)
  //             }
  //             //trackOptions.push(+storeID);
  //             trackedFields.push(+store + '|1');
  //             trackedFields1.push(+store + '|1');
  // }
  // else if(this.state.track == false)
  // {
  //     index = trackedFields.indexOf(+store + '|1');
  //             index1 = trackedFields1.indexOf(+store + '|1');
  //             if (index >= 0) {
  //                 trackedFields.splice(index, 1)
  //             }
  //             if (index1 >= 0) {
  //                 trackedFields1.splice(index1, 1)
  //             }
  //             trackedFields.push(+store + '|0');
  //             trackedFields1.push(+store + '|0');
  // }
  // this.setState({
  //     trackedFields, trackStorId, trackedFields1
  // })

  //     }

  trackhandleClick(event, storeID, userStId) {
    let trackStorId = userStId;
    let storesid = storeID;
    let tra = this.state.isTracks;
    if (this.state.selectedPlace === storesid) {
      this.setState({ isTracks: tra === 1 ? 0 : 1 });
    }
    // let trackOptions = this.state.trackOptions;
    let trackedFields = this.state.trackedFields;
    let trackedFields1 = this.state.trackedFields1;
    let index;
    let index1;

    if (event.target.checked) {
      // this.state.disablecheckbox = true;
      index = trackedFields.indexOf(+storeID + "|0");
      index1 = trackedFields1.indexOf(+storeID + "|0");
      if (index >= 0) {
        trackedFields.splice(index, 1);
      }
      if (index1 >= 0) {
        trackedFields1.splice(index1, 1);
      }
      //trackOptions.push(+storeID);
      trackedFields.push(+storeID + "|1");
      trackedFields1.push(+storeID + "|1");
    } else if (!event.target.checked) {
      index = trackedFields.indexOf(+storeID + "|1");
      index1 = trackedFields1.indexOf(+storeID + "|1");
      if (index >= 0) {
        trackedFields.splice(index, 1);
      }
      if (index1 >= 0) {
        trackedFields1.splice(index1, 1);
      }
      trackedFields.push(+storeID + "|0");
      trackedFields1.push(+storeID + "|0");
    }
    this.setState({
      trackedFields,
      trackStorId,
      trackedFields1,
    });
  }

  upgradehandleClick(event, storeID, userStId) {
    let userStorId = userStId;
    let trackOptions = this.state.trackOptions;
    let strOptions = this.state.strOptions;
    let strOptions1 = this.state.strOptions1;
    let index;
    let index1;

    if (event.target.checked) {
      // this.state.disablecheckbox = true;
      index = strOptions.indexOf(+storeID + "|0");
      index1 = strOptions1.indexOf(+storeID + "|0");
      if (index >= 0) {
        strOptions.splice(index, 1);
      }
      if (index1 >= 0) {
        strOptions1.splice(index1, 1);
      }
      trackOptions.push(+storeID);
      strOptions.push(+storeID + "|1");
      strOptions1.push(+storeID + "|1");
    } else if (!event.target.checked) {
      index = strOptions.indexOf(+storeID + "|1");
      index1 = strOptions1.indexOf(+storeID + "|1");
      if (index >= 0) {
        strOptions.splice(index, 1);
      }
      if (index1 >= 0) {
        strOptions1.splice(index1, 1);
      }
      strOptions.push(+storeID + "|0");
      strOptions1.push(+storeID + "|0");
    }
    this.setState({
      strOptions,
      userStorId,
      strOptions1,
    });
  }

  getMapQuestResultZip(query) {
    let lengthCheck = query.length;
    let centerLat, centerLng;
    let checkZip = 0;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      query.map((zipCode) => {
        const url =
          CONFIG.MapQuestKey_URL +
          CONFIG.MapQuestKey +
          "&hostedData=mqap.uspostalcodes&origin=" +
          zipCode +
          "&ambiguities=ignore";
        fetch(url)
          .then((response) => response.json())
          .then((response) => {
            let data = [];
            let coordinates = [];
            let coordinates_one = [];
            for (var val = 0; val < response.resultsCount; val++) {
              if (
                response.searchResults[val].fields.POSTCODE ===
                zipCode.toString()
              ) {
                try {
                  data = response.searchResults[val].shapePoints.exterior;
                  let Arr2 = [],
                    Arr3 = [];
                  // coordinates = [];
                  let datas = [];
                  let test = [];
                  //let check = response.searchResults[2].shapePoints.exterior;
                  data.map((detailss) => {
                    test.push(detailss);
                  });
                  for (var i = 0; i < test.length; i++) {
                    if ((i + 2) % 2 == 0) {
                      Arr3.push(test[i]);
                    } else {
                      Arr2.push(test[i]);
                    }
                  }
                  var merged = [];
                  for (var i = 0; i < Arr2.length; i++) {
                    merged.push(appendArrays(Arr2[i], Arr3[i]));
                  }
                  function appendArrays() {
                    var temp = [];
                    for (var i = 0; i < arguments.length; i++) {
                      temp.push(arguments[i]);
                    }
                    return temp;
                  }
                  let position = 0;
                  merged.map((location) => {
                    coordinates.push({ lat: location[1], lng: location[0] });
                    //  bounds.extend({"lat": location[1], "lng": location[0]});
                  });
                  this.state.coordinates = coordinates;
                  this.setState({
                    centerLat: coordinates[0].lat,
                    centerLng: coordinates[0].lng,
                  });
                  coordinates_one.push([coordinates]);
                  this.state.coordinates_one = [...coordinates_one];
                  coordinates = [];
                } catch (e) {
                  for (
                    var n = 0;
                    n < response.searchResults[val].shapePoints.length;
                    n++
                  ) {
                    data = response.searchResults[val].shapePoints[n].exterior;
                    let Arr2 = [],
                      Arr3 = [];
                    // coordinates = [];
                    let datas = [];
                    let test = [];
                    //let check = response.searchResults[2].shapePoints.exterior;
                    data.map((detailss) => {
                      test.push(detailss);
                    });
                    for (var i = 0; i < test.length; i++) {
                      if ((i + 2) % 2 == 0) {
                        Arr3.push(test[i]);
                      } else {
                        Arr2.push(test[i]);
                      }
                    }
                    var merged = [];
                    for (var i = 0; i < Arr2.length; i++) {
                      merged.push(appendArrays(Arr2[i], Arr3[i]));
                    }
                    function appendArrays() {
                      var temp = [];
                      for (var i = 0; i < arguments.length; i++) {
                        temp.push(arguments[i]);
                      }
                      return temp;
                    }
                    let position = 0;
                    merged.map((location) => {
                      coordinates.push({ lat: location[1], lng: location[0] });
                      //  bounds.extend({"lat": location[1], "lng": location[0]});
                    });
                    this.state.coordinates = coordinates;
                    coordinates_one.push([coordinates]);
                    this.state.coordinates_one = [...coordinates_one];
                    coordinates = [];
                  }
                }
              }
            }
            var mergedCoordinates = this.state.coordinates_one;
            if (checkZip === 0) {
              this.setState({ coordinates_one2: mergedCoordinates });
              checkZip++;
            } else {
              var concatCoord =
                this.state.coordinates_one2.concat(mergedCoordinates);
              this.setState({ coordinates_one2: concatCoord });
            }
          })
          .catch((error) => this.setState({ isLoading: false }));
      });
    }, 1000);
  }

  getMapQuestResultCity(query) {
    var citySplit = query.split(",");
    var city = citySplit[0].toUpperCase();
    if (!query) {
      return;
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const url =
        CONFIG.MapQuestKey_URL +
        CONFIG.MapQuestKey +
        "&hostedData=mqap.uspostalcodes&origin=" +
        query +
        "&ambiguities=ignore";
      fetch(url)
        .then((response) => response.json())
        .then((response) => {
          let data = [];
          let coordinates = [];
          let coordinates_one = [];
          for (var val = 0; val < response.resultsCount; val++) {
            if (response.searchResults[val].fields.ADMIN4 === city) {
              try {
                data = response.searchResults[val].shapePoints.exterior;
                let Arr2 = [],
                  Arr3 = [];
                // coordinates = [];
                let datas = [];
                let test = [];
                //let check = response.searchResults[2].shapePoints.exterior;
                data.map((detailss) => {
                  test.push(detailss);
                });
                for (var i = 0; i < test.length; i++) {
                  if ((i + 2) % 2 == 0) {
                    Arr3.push(test[i]);
                  } else {
                    Arr2.push(test[i]);
                  }
                }
                var merged = [];
                for (var i = 0; i < Arr2.length; i++) {
                  merged.push(appendArrays(Arr2[i], Arr3[i]));
                }
                function appendArrays() {
                  var temp = [];
                  for (var i = 0; i < arguments.length; i++) {
                    temp.push(arguments[i]);
                  }
                  return temp;
                }
                let position = 0;
                merged.map((location) => {
                  coordinates.push({ lat: location[1], lng: location[0] });
                  //  bounds.extend({"lat": location[1], "lng": location[0]});
                });
                this.state.coordinates = coordinates;
                coordinates_one.push([coordinates]);
                this.state.coordinates_one = [...coordinates_one];
                coordinates = [];
              } catch (e) {
                for (
                  var n = 0;
                  n < response.searchResults[val].shapePoints.length;
                  n++
                ) {
                  data = response.searchResults[val].shapePoints[n].exterior;
                  let Arr2 = [],
                    Arr3 = [];
                  // coordinates = [];
                  let datas = [];
                  let test = [];
                  //let check = response.searchResults[2].shapePoints.exterior;
                  data.map((detailss) => {
                    test.push(detailss);
                  });
                  for (var i = 0; i < test.length; i++) {
                    if ((i + 2) % 2 == 0) {
                      Arr3.push(test[i]);
                    } else {
                      Arr2.push(test[i]);
                    }
                  }
                  var merged = [];
                  for (var i = 0; i < Arr2.length; i++) {
                    merged.push(appendArrays(Arr2[i], Arr3[i]));
                  }
                  function appendArrays() {
                    var temp = [];
                    for (var i = 0; i < arguments.length; i++) {
                      temp.push(arguments[i]);
                    }
                    return temp;
                  }
                  let position = 0;
                  merged.map((location) => {
                    coordinates.push({ lat: location[1], lng: location[0] });
                    //  bounds.extend({"lat": location[1], "lng": location[0]});
                  });
                  this.state.coordinates = coordinates;
                  coordinates_one.push([coordinates]);
                  this.state.coordinates_one = [...coordinates_one];
                  coordinates = [];
                }
              }
            }
          }
          var mergedCoordinates = this.state.coordinates_one;
          this.setState({ coordinates_one: mergedCoordinates });
        })

        .catch((error) => this.setState({ isLoading: false }));
    }, 1000);
  }

  deleteComp() {
    const data = {
      UserStoreID: this.state.userStoreId,
      CompetitorID: this.state.selectedPlace,
      SearchType:
        this.state.coverageType === "radius"
          ? 1
          : this.state.coverageType === "zipcode"
          ? 2
          : this.state.coverageType === "city"
          ? 3
          : "",
      Zone: {
        Radius: this.state.coverageType === "radius" ? this.state.scale : null,
        Zipcode1:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 1 ||
            this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length > 3 ||
            this.state.zipcodeOptions.length === 1)
            ? this.state.zipcodeOptions[0]
            : "",
        Zipcode2:
          this.state.coverageType === "zipcode" &&
          (this.state.zipcodeOptions.length > 2 ||
            this.state.zipcodeOptions.length === 2)
            ? this.state.zipcodeOptions[1]
            : "",
        Zipcode3:
          this.state.coverageType === "zipcode" &&
          this.state.zipcodeOptions.length === 3
            ? this.state.zipcodeOptions[2]
            : "",
        CityName:
          this.state.coverageType === "city"
            ? this.state.selectedCityOption.value.replace(",", ", ")
            : null,
      },
      isCoverageZone: 1,
      RegionId: CONFIG.RegionId,
    };

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    axios
      .post(CONFIG.API_URL + "coverage/hidecompetitor", data)
      .then((response) => {
        // this.state.isLoading=true;
        const success = response.data;

        if (response.status === 200) {
          //alert('Hide ScuessFully');
          //this.componentDidMount();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  onMarkerClick = (track, storeid, userstid, marker, lat, lng, address) => {
    var add = address;
    var last = add.lastIndexOf(",");
    var s1 = add.substring(0, last);
    var s2 = add.substring(last + 1);
    //setTimeout(() => {
    this.setState({
      selectedPlace: storeid,
      userStr: userstid,
      activeMarker: marker ? marker : "",
      lat: lat,
      lng: lng,
      track: track === 1 ? true : false,
      isTracks: track,
      tracked: track === 1 ? "Click to Untrack" : "Click to Track",
      address: address ? s1 : "",
      address1: address ? s2 : "",
      showingInfoWindow: true,
    });
    //},500)
  };

  goToMymarket() {
    let showComponent = this.state.showComponent;
    let selectedCityOption = this.state.selectedCityOption;
    let zipcodeOptions = this.state.zipcodeOptions;
    let scale = this.state.scale;
    let unTrackedCount = this.state.unTrackedCount;
    let trackedFields = this.state.trackedFields;
    let trackedFields1 = this.state.trackedFields1;
    let userStoreID = this.state.userStoreID;
    let addInputData = this.state.addInputData;
    let newAddress = this.state.newAddress;
    let oldAddress = this.state.oldAddress;
    let tabvalue = this.state.tabvalue;
    tabvalue = 0;
    newAddress = {};
    oldAddress = {};
    userStoreID = 0;
    showComponent = "radius";
    zipcodeOptions = [];
    selectedCityOption = [];
    scale = 10;
    unTrackedCount = [];
    trackedFields = [];
    trackedFields1 = [];
    addInputData = "";
    this.setState({
      open: false,
      tabvalue,
      isSelactCity: false,
      unTrackedCount,
      trackedFields,
      trackedFields1,
      activeStep: 0,
      showComponent,
      zipcodeOptions,
      selectedCityOption,
      scale,
      errortext: "",
      errortext1: "",
      pageCountMDT: 0,
      marketName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      exestineStore: "true",
      StateCountry: "",
      coverageTrackCount: 0,
      userStoreID,
      addInputData,
      newAddress,
      oldAddress,
    });

    let path = `/my-market/`;
    this.props.parm.children === undefined
      ? this.props.parentMethod()
      : this.props.parm.children.props.history.push(path);

    var isElement = document.getElementById("r2");
    if (isElement == null) {
      document.getElementById("r1").click();
    } else {
      document.getElementById("r1").click();
      document.getElementById("r2").click();
    }
  }

  componentDidMount() {
    // this.setState({
    //     isLoading: true
    // })
    this.delayedShowMarker();
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    // axios.post(CONFIG.API_URL + 'Account/reputationstore')
    axios
      .post(CONFIG.API_URL + "Account/reputationstore/" + CONFIG.RegionId)
      .then((res) => {
        const myMarketData = res.data;
        let serachTypeval;
        if (myMarketData.result1.length > 0) {
          serachTypeval = myMarketData.result1.find(function (searchType) {
            return searchType;
          });
          serachTypeval = serachTypeval.searchType;
        }
        this.setState({
          isLoading: false,
          myMarketData,
          serachTypeval,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });

    // axios.get(CONFIG.API_URL + 'home/lookupdata')
    axios
      .get(CONFIG.API_URL + "home/lookupdata/" + CONFIG.RegionId + "/" + 0)
      .then((response) => {
        let stateData = response.data.lookups.statesList;
        let countryData = response.data.lookups.countryList;
        //To know how many Countries in statesList data
        let StateCountryCount = stateData.filter((data) => {
          return data.stateID === "";
        }).length;

        this.setState({
          stateData,
          countryData,
          StateCountryCount,
        });
      });
  }

  render() {
    const steps = [
      this.state.title1,
      "Choose Coverage Type",
      "Review Coverage",
      "Confirm Market",
    ];
    const params = {
      v: "3.exp",
      key: "AIzaSyDaYQZ0es5K-kLgDuZuTqRHKiTDENEMxxQ",
    };
    let storeid = this.state.selectedPlace;
    const {
      ZipcodeFilter,
      zipcodeOptions,
      isSelactCity,
      unzipcodeOptions,
      EditStoredata,
      selectedCityOption,
      selectedZipOption,
      trackedFields,
      trackedFields1,
      unTrackedCount,
      addInputData,
      errortext,
      errortext1,
      tabvalue,
      filterlookupZipcodeData,
      zipError,
      lookupZipcodeData,
      lookupCityData,
      showComponent,
      addMarketData,
      zipcode,
      stateData,
      countryData,
      rowsPerPage,
      page,
      pageCount,
      pageCountMDT,
      myMarketData,
      isLoading,
      isLoading1,
      isDefaultValue,
      trackModalData,
      strOptions,
      selectedPlace,
      activeMarker,
      showingInfoWindow,
      address,
    } = this.state;
    const {
      open,
      open1,
      open2,
      snackOpen,
      snackMSg,
      activeStep,
      radiusValue,
      competitorsData,
      trackResponse,
    } = this.state;
    const {
      coverageTrackCount,
      zipCoordinates1,
      zipCoordinates2,
      zipCoordinates3,
      cityCoordinates,
      StateCountryCount,
    } = this.state;
    const defaultValues = {
      coverageType: this.state.showComponent,
      exestineStore: this.state.exestineStore
        ? this.state.exestineStore.toString()
        : EditStoredata && EditStoredata.exestineStore
        ? EditStoredata.exestineStore.toString()
        : "true",
    };
    const defaultProps = {
      helpTitle:
        this.state.activeStep && this.state.activeStep === 1
          ? "Let's define your coverage"
          : this.state.activeStep && this.state.activeStep === 2
          ? "Review your coverage area"
          : "",

      helpContent:
        (this.state.activeStep &&
          this.state.activeStep === 1 &&
          CONFIG.RegionId == 2) ||
        CONFIG.RegionId == 4
          ? "<ul><li>The coverage for this Market/address is what you consider your immediate competitor area.</li><li>Select a kilometre radius, a city or specific zip code(s) to set as the coverage area for this Market.</li><li>The market insights shown throughout the site, including competitor stores, will be based on the coverage area you select here.</li></ul><p>Sometimes what you consider your competitor area can change, that's fine! Come back to this section any time you want to change the coverage area.</p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/9TF7yJnQrLg' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
          : this.state.activeStep &&
            this.state.activeStep === 1 &&
            CONFIG.RegionId != (2 || 4)
          ? "<ul><li>The coverage for this Market/address is what you consider your immediate competitor area.</li><li>Select a mile radius, a city or specific zip code(s) to set as the coverage area for this Market.</li><li>The market insights shown throughout the site, including competitor stores, will be based on the coverage area you select here.</li></ul><p>Sometimes what you consider your competitor area can change, that's fine! Come back to this section any time you want to change the coverage area.</p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/9TF7yJnQrLg' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
          : this.state.activeStep && this.state.activeStep === 2
          ? "<ul><li>These are the stores found within the coverage area you selected in the previous step.</li><li>Not your competition? We get it, maybe the guys across the street are no match to the value you bring to your customers. To remove any of stores/competitors from the market insights you see throughout the site, unselect the box under Track next to the store.</li></ul><h6>Lay of the land</h6><p>Click on the Map icon to view the stores on a map and see which ones are advertising rates (blue) and which ones are not (black).</p><h6>I can't find a store</h6><p>If you find there's a store missing on the list, click the Are there any stores missing? link and fill out the form.</p>"
          : "",
    };
    //  let errMsg = CONFIG.RegionId === 3 ? CONFIG.PostalCodevalidationUK:CONFIG.PostalCodevalidation;

    return (
      <div>
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}

        {isLoading1 ? (
          <div className="loader-wrap">
            <div className="loading loading-center"></div>
          </div>
        ) : (
          ""
        )}
        <Dialog
          className="landing-stepper add-market-popup"
          open={open}
          //onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 1000,
          }}
        >
          <DialogTitle align="center" id="alert-dialog-title">
            <div>
              {this.state.activeStep === 0 ? (
                <b className="add-mar-title">{this.state.title}</b>
              ) : this.state.activeStep === 1 ? (
                <b className="add-mar-title"> {this.state.title}</b>
              ) : this.state.activeStep === 2 ? (
                <b className="add-mar-title">{this.state.title}</b>
              ) : this.state.activeStep === 3 ? (
                <b className="add-mar-title">{this.state.title}</b>
              ) : (
                ""
              )}
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className="closeModal"
                onClick={this.closeModal.bind(this)}
              >
                <CloseIcon />
              </IconButton>

              <Stepper
                className="stepper-block"
                activeStep={this.state.activeStep}
                alternativeLabel
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      className={
                        this.state.activeStep > index
                          ? "MuiStepIcon-completed-cx"
                          : ""
                      }
                      //  optional={index+1}
                      completed={false}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <div>
                  {this.state.activeStep === 0 ? (
                    <div className="avform-bx">
                      <AvForm
                        onSubmit={this.addMarket.bind(this)}
                        model={defaultValues}
                      >
                        <div className="scrool-popup-view">
                          <Label className="main-lable">
                            Please enter an address to identify the Market
                            centerpoint. If this is your Store address, this
                            will be identified as 'Your Store' in the graphs and
                            charts in the Market data. If it is not a Store
                            address, 'Your Store' will appear as 'N/A'.
                          </Label>
                          <AvRadioGroup
                            inline
                            name="exestineStore"
                            label="Is this a Store address?"
                            required
                            className="is-existing-store"
                          >
                            <AvRadio
                              className="opa-0-8"
                              customInput
                              label="Yes"
                              value="true"
                            />
                            <AvRadio
                              className="opa-0-8"
                              customInput
                              label="No"
                              value="false"
                            />
                          </AvRadioGroup>
                          <Row>
                            <Col md="6" className="market-form-6">
                              <div className="form-group-market">
                                <AvField
                                  name="marketName"
                                  label="Market Name"
                                  type="text"
                                  errorMessage="Market Name is required"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={
                                    this.state.marketName
                                      ? this.state.marketName
                                      : EditStoredata.storeName
                                      ? EditStoredata.storeName
                                      : ""
                                  }
                                />
                                <div className="add-market-error">
                                  {errortext ? errortext : ""}
                                </div>

                                <AvField
                                  name="addressLine1"
                                  label={CONFIG.Address1}
                                  type="text"
                                  errorMessage={
                                    CONFIG.Address1 + " is required"
                                  }
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={
                                    this.state.addressLine1
                                      ? this.state.addressLine1
                                      : EditStoredata.address
                                      ? EditStoredata.address
                                      : ""
                                  }
                                />

                                <AvField
                                  name="addressLine2"
                                  label={CONFIG.Address2}
                                  type="text"
                                  validate={{
                                    required: { value: false },
                                  }}
                                  value={
                                    this.state.addressLine2
                                      ? this.state.addressLine2
                                      : EditStoredata.address2
                                      ? EditStoredata.address2
                                      : ""
                                  }
                                />

                                <AvField
                                  name="city"
                                  label={CONFIG.City}
                                  type="text"
                                  errorMessage={CONFIG.City + " is required"}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={
                                    this.state.city
                                      ? this.state.city
                                      : EditStoredata.city
                                      ? EditStoredata.city
                                      : ""
                                  }
                                />
                                <Row>
                                  {CONFIG.RegionId === 3 ? (
                                    ""
                                  ) : (
                                    <Col
                                      md="6"
                                      className="right-padding-a-m state-province"
                                    >
                                      <AvField
                                        type="select"
                                        name="state"
                                        label={CONFIG.State}
                                        helpMessage=""
                                        validate={{
                                          required: {
                                            value: true,
                                            errorMessage:
                                              CONFIG.State + " is required",
                                          },
                                        }}
                                        onChange={(e) =>
                                          this.onCountryChange(e)
                                        }
                                        value={
                                          this.state.state
                                            ? this.state.state
                                            : EditStoredata.state
                                            ? EditStoredata.state
                                            : ""
                                        }
                                      >
                                        <option value="">
                                          --Select State--
                                        </option>
                                        {stateData
                                          ? stateData.map((state) =>
                                              state.stateID === "" &&
                                              StateCountryCount <= 1 ? (
                                                ""
                                              ) : (
                                                <option
                                                  key={state.stateID}
                                                  disabled={
                                                    state.stateID === ""
                                                      ? true
                                                      : false
                                                  }
                                                  selected={
                                                    EditStoredata.state ===
                                                    state.stateID
                                                      ? true
                                                      : this.state.state ===
                                                        state.stateID
                                                      ? true
                                                      : false
                                                  }
                                                  value={state.stateID}
                                                >
                                                  {state.state}
                                                </option>
                                              )
                                            )
                                          : ""}
                                      </AvField>
                                    </Col>
                                  )}
                                  <Col
                                    md="6"
                                    className={
                                      CONFIG.RegionId ===
                                      MatchingCountryCode.UK_RID
                                        ? "postal-code-uk left-padding-a-m"
                                        : "left-padding-a-m"
                                    }
                                  >
                                    <AvField
                                      name="zipcode"
                                      label={CONFIG.PostalCode}
                                      type="text"
                                      errorMessage={
                                        CONFIG.PostalCode + " is required"
                                      }
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "^[A-Za-z0-9]+$",
                                          errorMessage:
                                            CONFIG.PostalCodevalidation,
                                        },
                                        maxLength: {
                                          value: 10,
                                          errorMessage:
                                            CONFIG.PostalCode +
                                            " must below 10 characters",
                                        },
                                      }}
                                      value={
                                        this.state.zipcode
                                          ? this.state.zipcode
                                          : EditStoredata.zipcode
                                          ? EditStoredata.zipcode
                                          : ""
                                      }
                                      onKeyPress={
                                        CONFIG.RegionId === 3
                                          ? this.postalCodeValidation
                                          : ""
                                      }
                                    />
                                  </Col>
                                </Row>
                                {CONFIG.RegionId === 3 ? (
                                  ""
                                ) : (
                                  <AvField
                                    type="select"
                                    name="country"
                                    label={CONFIG.Country}
                                    helpMessage=""
                                    disabled
                                    validate={
                                      {
                                        // required: { value: true, errorMessage: 'Country is Required' }
                                      }
                                    }
                                  >
                                    {/* <option value='' >--Select Country--</option> */}
                                    <option
                                      value={this.state.isDefaultValue[0]}
                                    >
                                      {this.state.StateCountry}
                                    </option>
                                    {/* {countryData ? countryData.map((country) =>
                                                                        <option key={country.countryID} value={country.countryID}>{this.state.defaultValue}</option>

                                                                    ) : ''} */}
                                  </AvField>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {/* <AvField type="select" name="country" label="Country" readOnly validate={{
                                                                    required: { value: true, errorMessage: 'Country is Required' }
                                                                }}
                                                                value={this.state.StateCountry}/> */}

                        {/* <Button type="submit">Send</Button> */}

                        <button
                          ref={(btn0) => {
                            this.btn0 = btn0;
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="next-btn save-t"
                        >
                          Save and Go to Next
                        </button>
                        <Button
                          type="button"
                          //disabled={this.state.activeStep === 1}
                          onClick={this.handleClose}
                          className="go-btn close-step-first"
                        >
                          Go Back
                        </Button>
                        {/* <Button
                                                    type="button"
                                                                    //disabled={this.state.activeStep === 0}
                                                                    onClick={this.handleBack}

                                                                >
                                                                    Back
              </Button> */}

                        {/* <Button type="submit" variant="contained" color="primary"
                                                            //onClick={this.handleNext}
                                                            >
                                                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                            </Button> */}

                        {/* <FormGroup>
                                                                    <Button type="submit">Submit</Button>
                                                                </FormGroup> */}
                      </AvForm>
                      <Dialog
                        className="landing-stepper market-not-found"
                        open={open1}
                        //onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <div className="n-f-b">
                          <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="closeModal"
                            onClick={this.closeModalWebsite.bind(this)}
                          >
                            <CloseIcon />
                          </IconButton>
                          <DialogTitle id="alert-dialog-title">
                            Store not found
                          </DialogTitle>
                          <AvForm
                            class="websiteform"
                            onSubmit={this.addwebsite.bind(this)}
                          >
                            <DialogContent>
                              <DialogContentText
                                id="alert-dialog-description"
                                className="cls-txt"
                              >
                                <Col md="12">
                                  <p>
                                    This store is not yet part of our database.
                                    Let us know if this store needs to be added
                                    to our system. Our research team will review
                                    your inquiry and add the store to our
                                    database within 72 hours.
                                  </p>
                                </Col>
                              </DialogContentText>

                              <Col md="9" className="store-not-found">
                                <AvField
                                  name="website"
                                  label="If this store has a website, please provide it below:"
                                  // type="text"
                                  // pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                                  // validate={{ required: { value: true, errorMessage: 'Website URL is Invalid' } }}
                                  validate={{
                                    //required: { value: true, errorMessage: 'Please enter the website url' },
                                    pattern: {
                                      value:
                                        "^(https?:\\/\\/)?" + // protocol
                                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                                        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                                        "(\\#[-a-z\\d_]*)?$",
                                      errorMessage: "Website url is invalid",
                                    },
                                  }}
                                  //  type="text"
                                  // validate={{
                                  //     required: {value: true, errorMessage: 'Please enter the website url'},
                                  //     pattern: {value: '^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&"\(\)\*\+,;=.]+$',
                                  //     errorMessage: 'Website url is invalid'}
                                  //   }}
                                />
                              </Col>
                            </DialogContent>
                            <DialogActions className="store-submit-btn">
                              <Button
                                ref={(btn3) => {
                                  this.btn3 = btn3;
                                }}
                                type="submit"
                                color="primary"
                                autoFocus
                              >
                                Submit & Continue with this address
                              </Button>
                              <Button
                                ref={(btn3) => {
                                  this.btn3 = btn3;
                                }}
                                onClick={this.closeModalWebsite.bind(this)}
                                color="primary"
                                className="cancel-web-btn"
                                type="button"
                              >
                                Update address
                              </Button>
                            </DialogActions>
                          </AvForm>
                        </div>
                      </Dialog>
                      <Dialog
                        className="landing-stepper market-not-found"
                        open={open2}
                        //onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          className="closeModal"
                          onClick={this.closeModalAddress.bind(this)}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogTitle id="alert-dialog-title">
                          Address not found
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText
                            id="alert-dialog-description"
                            className="cls-txt"
                          >
                            <Col md="12" className="opacity-7">
                              <p>
                                Please make sure you have entered a valid
                                address.
                              </p>
                              <p>
                                If you believe you have received this message in
                                error, please contact our support team at{" "}
                                {CONFIG.Mail_Support} or{" "}
                                {CONFIG.RegionId === 3
                                  ? CONFIG.Sales_Support
                                  : CONFIG.Toll_Free}
                                .
                              </p>
                            </Col>
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                    </div>
                  ) : this.state.activeStep === 1 ? (
                    <div className="avform-bx radio-bx-blk">
                      <AvForm
                        onSubmit={this.addCoverage.bind(this)}
                        model={defaultValues}
                      >
                        <Row className="city-min-height">
                          <Col md="6">
                            <AvField
                              className="mrg-btm-15"
                              name="marketName1"
                              disabled
                              label="Market Name"
                              value={
                                addInputData
                                  ? addInputData.storeName
                                  : EditStoredata.storeName
                                  ? EditStoredata.storeName
                                  : ""
                              }
                              type="text"
                              errorMessage="Invalid Market Name"
                              validate={{
                                required: { value: false },
                              }}
                            />
                          </Col>
                          <Col md="12">
                            {/* <AvRadioGroup inline name="coverageType" label="Define Your Coverage type" required>
                                                            <AvRadio className="opa-0-8" checked={this.state.showComponent === 'radius'} onChange={this.coverageType.bind(this)} customInput label="Radius" value="radius" />
                                                            <AvRadio className="opa-0-8" checked={this.state.showComponent === 'zipcode'} onChange={this.coverageType.bind(this)} customInput label="Zip or Postal Code(s)" value="zipcode" />
                                                            <AvRadio className="opa-0-8" checked={this.state.showComponent === 'city'} onChange={this.coverageType.bind(this)} customInput label="City Name" value="city" />

                                                        </AvRadioGroup> */}
                            <div className="radio-mui-group">
                              <div className="ch-ra-zip">
                                Define your coverage type{" "}
                                <HelpTextPopup parm={defaultProps} />
                              </div>
                              <div className="clear"></div>
                              <FormControlLabel
                                value="radius"
                                control={
                                  <Radio
                                    checked={
                                      this.state.showComponent === "radius"
                                    }
                                    onChange={this.coverageType.bind(this)}
                                    value="radius"
                                    name="radius"
                                  />
                                }
                                label="Radius"
                              />
                              <FormControlLabel
                                value="zipcode"
                                control={
                                  <Radio
                                    checked={
                                      this.state.showComponent === "zipcode"
                                    }
                                    onChange={this.coverageType.bind(this)}
                                    value="zipcode"
                                    name="zipcode"
                                  />
                                }
                                label="Zip or Postal Code(s) *"
                              />
                              <FormControlLabel
                                value="city"
                                control={
                                  <Radio
                                    checked={
                                      this.state.showComponent === "city"
                                    }
                                    onChange={this.coverageType.bind(this)}
                                    value="city"
                                    name="city"
                                  />
                                }
                                label="City Name *"
                              />
                            </div>
                          </Col>
                          <Col md="12">
                            {this.state.showComponent === "radius" ? (
                              <div className="radio-bx coverages-markets">
                                <h4>Radius based market coverage</h4>
                                <p
                                  className="bx"
                                  style={{ marginBottom: "0px" }}
                                >
                                  {CONFIG.RegionId == 2 || CONFIG.RegionId == 4
                                    ? "How many kilometres would you like to choose as your Radius?"
                                    : "How many miles would you like to choose as your Radius?"}
                                </p>
                                {/* <input className="r-slider" type="range" step={1} min={1} max={10} value={this.state.scale} name="scale" onChange={this.radiusOnChange.bind(this)} />  
                                                                <span className="miles-calculate">{(CONFIG.RegionId == 2) ||(CONFIG.RegionId == 4) ?'10 kilometres':'10 miles'}</span>*/}
                                <Slider
                                  className="r-slider"
                                  defaultValue={this.state.scale}
                                  valueLabelDisplay="on"
                                  // valueLabelFormat={this.getcustomLabel}
                                  onChangeCommitted={this.radiusOnChange}
                                  aria-labelledby="discrete-slider-always"
                                  step={1}
                                  marks={[
                                    {
                                      value: 1,
                                      label: "1",
                                    },
                                    {
                                      value: 2,
                                      label: "2",
                                    },
                                    {
                                      value: 3,
                                      label: "3",
                                    },
                                    {
                                      value: 4,
                                      label: "4",
                                    },
                                    {
                                      value: 5,
                                      label: "5",
                                    },
                                    {
                                      value: 6,
                                      label: "6",
                                    },
                                    {
                                      value: 7,
                                      label: "7",
                                    },
                                    {
                                      value: 8,
                                      label: "8",
                                    },
                                    {
                                      value: 9,
                                      label: "9",
                                    },
                                    {
                                      value: 10,
                                      label: "10",
                                    },
                                  ]}
                                  min={1}
                                  max={10}
                                />
                                <div className="clear"></div>
                                <div className="sel-mi-txt">
                                  You selected {this.state.scale}{" "}
                                  {CONFIG.RegionId == 2 || CONFIG.RegionId == 4
                                    ? "kilometre"
                                    : "mile"}
                                  {this.state.scale === "1" ? "" : "s"}
                                </div>
                                <div className="add-market-error">
                                  {errortext1 ? errortext1 : ""}
                                </div>
                                <div className="ld-blk">
                                  {isLoading1 === false ? (
                                    // <p class="new-track-txt">{this.state.coverageTrackCount > 0 ? 'Expect about ' + this.state.coverageTrackCount : 'There are no'} competitor{this.state.coverageTrackCount === 1 || this.state.coverageTrackCount === 0 ? '' : 's'} in this radius.</p>
                                    <p class="new-track-txt">
                                      {this.state.coverageTrackCount > 0 ? (
                                        <span>
                                          There{" "}
                                          {this.state.coverageTrackCount === 1
                                            ? "is"
                                            : "are"}{" "}
                                          <span>
                                            <b
                                              style={{
                                                color: "#f15d47",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {this.state.coverageTrackCount}
                                            </b>{" "}
                                            <b
                                              style={{
                                                color: "#f15d47",
                                                fontWeight: "500",
                                              }}
                                            >
                                              competitor
                                              {this.state.coverageTrackCount ===
                                              1
                                                ? ""
                                                : "s"}
                                            </b>
                                          </span>{" "}
                                        </span>
                                      ) : (
                                        "There are no competitors"
                                      )}{" "}
                                      within this radius.
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : this.state.showComponent === "zipcode" ? (
                              <div className="radio-bx">
                                <Row>
                                  <Col lg={12}>
                                    <p style={{ textAlign: "right", fontWeight: '500' }}>
                                      * Zip or Postal Code(s) (25 miles around
                                      market center)
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <Row>
                                      <Col md={6}>
                                        <h4 className="mt-1">
                                          Zip or Postal Code(s)
                                        </h4>
                                      </Col>
                                      <Col md={6}>
                                        <div>
                                          <input
                                            type="text"
                                            id="ZipcodeFilter"
                                            placeholder="Type in to Search..."
                                            value={this.state.ZipcodeFilter}
                                            onChange={
                                              this.zipFilterHandleChange
                                            }
                                            className="zip-code-filter"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-1">
                                      <Col md={6}>
                                        <p className="zipcode-desc">
                                          Pick up to three Zip or Postal Code(s)
                                        </p>
                                      </Col>
                                      <Col md={6}>
                                        <p class="selected-zip-values">
                                          {zipcodeOptions && zipcodeOptions[0]
                                            ? zipcodeOptions[0]
                                            : ""}
                                          {zipcodeOptions &&
                                          zipcodeOptions[1] &&
                                          !zipcodeOptions[2]
                                            ? ", " + zipcodeOptions[1]
                                            : ""}
                                          {zipcodeOptions &&
                                          zipcodeOptions[1] &&
                                          zipcodeOptions[2]
                                            ? ", " + zipcodeOptions[1]
                                            : ""}
                                          {zipcodeOptions && zipcodeOptions[2]
                                            ? ", " + zipcodeOptions[2]
                                            : ""}
                                        </p>
                                      </Col>
                                    </Row>
                                    <FormGroup row className="form-zipcode">
                                      {filterlookupZipcodeData &&
                                      filterlookupZipcodeData.length > 0 ? (
                                        filterlookupZipcodeData.map((zip) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className="track-check"
                                                key={zip.value}
                                                id={zip.value}
                                                disabled={this.state.isChecked}
                                                checked={
                                                  zip.value ===
                                                  zipcodeOptions.find(
                                                    (e) => e === zip.value
                                                  )
                                                }
                                                name="updateCheck"
                                                value={zip.value}
                                                onClick={(event) =>
                                                  this.zipcodeClick(
                                                    event,
                                                    zip.value
                                                  )
                                                }
                                                style={{ fontSize: 12 }}
                                              ></Checkbox>
                                            }
                                            label={zip.value}
                                            className="col-md-3"
                                          />
                                        ))
                                      ) : (
                                        // ''
                                        <div className="zip-error">
                                          {zipError}
                                        </div>
                                      )}
                                    </FormGroup>
                                    <div className="ld-blk">
                                      {/* {zipcodeOptions && zipcodeOptions.length > 0 && isLoading1 === false ? <p class="new-track-txt"> {this.state.coverageTrackCount > 0 ? 'Expect about ' + this.state.coverageTrackCount : 'There are no'} competitor{this.state.coverageTrackCount === 1 ? '' : 's'} in this zip code(s).</p> : ''} */}
                                      {zipcodeOptions &&
                                      zipcodeOptions.length > 0 &&
                                      isLoading1 === false ? (
                                        <p class="new-track-txt">
                                          {this.state.coverageTrackCount > 0 ? (
                                            <span>
                                              There{" "}
                                              {this.state.coverageTrackCount ===
                                              1
                                                ? "is"
                                                : "are"}{" "}
                                              <span>
                                                <b
                                                  style={{
                                                    color: "#f15d47",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {
                                                    this.state
                                                      .coverageTrackCount
                                                  }
                                                </b>{" "}
                                                <b
                                                  style={{
                                                    color: "#f15d47",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  competitor
                                                  {this.state
                                                    .coverageTrackCount === 1
                                                    ? ""
                                                    : "s"}
                                                </b>
                                              </span>{" "}
                                            </span>
                                          ) : (
                                            "There are no competitors"
                                          )}{" "}
                                          in this{" "}
                                          {CONFIG.RegionId == "1"
                                            ? "Zip Code(s)"
                                            : "Postal Code(s)"}
                                          .
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="add-market-error">
                                      {errortext1 ? errortext1 : ""}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : this.state.showComponent === "city" ? (
                              <div className="city-select radio-bx">
                                <Row>
                                  <Col>
                                    <p style={{ textAlign: "right", fontWeight: '500', marginBottom: '5px' }}>
                                      * City Name (25 miles around market
                                      center)
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6" className="market-form-6">
                                    <h4>City Name</h4>

                                    <Select
                                      value={selectedCityOption}
                                      onChange={this.cityHandleChange}
                                      options={lookupCityData}
                                      isSearchable={true}
                                      defaultMenuIsOpen={false}
                                      placeholder="-Select-"
                                    />

                                    <div className="add-market-error">
                                      {errortext1 ? errortext1 : ""}
                                    </div>
                                  </Col>
                                </Row>
                                <div className="ld-blk">
                                  {/* {this.state.isSelactCity && isLoading1 === false ? <p class="new-track-txt">{this.state.coverageTrackCount > 0 ? 'Expect about ' + this.state.coverageTrackCount : 'There are no'} competitor{this.state.coverageTrackCount === 1 ? '' : 's'} in this city.</p> : ''} */}
                                  {this.state.isSelactCity &&
                                  isLoading1 === false ? (
                                    <p class="new-track-txt">
                                      {this.state.coverageTrackCount > 0 ? (
                                        <span>
                                          There{" "}
                                          {this.state.coverageTrackCount === 1
                                            ? "is"
                                            : "are"}{" "}
                                          <span>
                                            <b
                                              style={{
                                                color: "#f15d47",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {this.state.coverageTrackCount}
                                            </b>{" "}
                                            <b
                                              style={{
                                                color: "#f15d47",
                                                fontWeight: "500",
                                              }}
                                            >
                                              competitor
                                              {this.state.coverageTrackCount ===
                                              1
                                                ? ""
                                                : "s"}
                                            </b>
                                          </span>{" "}
                                        </span>
                                      ) : (
                                        "There are no competitors"
                                      )}{" "}
                                      in this city.
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <div className="clearfix"></div>
                        <Button
                          type="submit"
                          ref={(btn1) => {
                            this.btn1 = btn1;
                          }}
                          variant="contained"
                          color="primary"
                          className="next-btn"
                        >
                          Save and Go to Next
                        </Button>
                        {localStorage.getItem("userType") === "2" ? (
                          <Button
                            type="button"
                            //disabled={this.state.activeStep === 1}
                            onClick={this.handleClose}
                            className="go-btn"
                          >
                            Go Back
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            //disabled={this.state.activeStep === 1}
                            onClick={this.handleBack}
                            className="go-btn"
                          >
                            Go Back
                          </Button>
                        )}
                      </AvForm>
                    </div>
                  ) : this.state.activeStep === 2 ? (
                    <div className="tab-bx">
                      {this.state.showComponent === "radius" ? (
                        <div>
                          <div className="left-de--view radius-market-coverage">
                            <h4>Radius based market coverage</h4>
                            <p className="you-are-tracking">
                              There{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 2
                                ? "are"
                                : competitorsData.data.currentStoreDetail
                                    .trackedCount === 0
                                ? "are"
                                : "is"}{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 1
                                ? competitorsData.data.currentStoreDetail
                                    .trackedCount
                                : "no"}{" "}
                              competitor
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount > 1
                                ? "s"
                                : ""}{" "}
                              within a{" "}
                              {competitorsData
                                ? competitorsData.data.currentStoreDetail.radius
                                : ""}{" "}
                              {CONFIG.RegionId == 2 || CONFIG.RegionId == 4
                                ? "kilometre"
                                : "mile"}{" "}
                              radius. <HelpTextPopup parm={defaultProps} />
                            </p>
                            {/* <p className="you-are-tracking">There are {competitorsData && competitorsData.data.currentStoreDetail.trackedCount >= 1 ? competitorsData.data.currentStoreDetail.trackedCount - unTrackedCount.length : '0'} store{competitorsData && competitorsData.data.currentStoreDetail.trackedCount > 1 ? 's' : ''} within a {competitorsData ? competitorsData.data.currentStoreDetail.radius : ''} mile radius.</p> */}
                          </div>
                          <div className="missed-competitor">
                            <MissingCompetitor
                              className="list-item-text add-mar-comp"
                              isShowModal={this.state.showModal}
                            />
                          </div>
                          <div className="clear"></div>
                        </div>
                      ) : this.state.showComponent === "zipcode" ? (
                        <div className="row">
                          <div className="col-lg-8">
                            <h4>Zip or Postal Code(s) based market coverage</h4>
                            <p className="you-are-tracking">
                              There{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 2
                                ? "are"
                                : competitorsData.data.currentStoreDetail
                                    .trackedCount === 0
                                ? "are"
                                : "is"}{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 1
                                ? competitorsData.data.currentStoreDetail
                                    .trackedCount
                                : "no"}{" "}
                              competitor
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount > 1
                                ? "s"
                                : ""}{" "}
                              within zip code(s){" "}
                              {/* {competitorsData ? competitorsData.data.currentStoreDetail.zipCodes : ''} */}
                              {zipcodeOptions && zipcodeOptions[0]
                                ? zipcodeOptions[0]
                                : ""}
                              {zipcodeOptions &&
                              zipcodeOptions[1] &&
                              !zipcodeOptions[2]
                                ? " and " + zipcodeOptions[1]
                                : ""}
                              {zipcodeOptions &&
                              zipcodeOptions[1] &&
                              zipcodeOptions[2]
                                ? ", " + zipcodeOptions[1]
                                : ""}
                              {zipcodeOptions && zipcodeOptions[2]
                                ? " and " + zipcodeOptions[2]
                                : ""}
                              . <HelpTextPopup parm={defaultProps} />
                            </p>
                            {/* <p className="you-are-tracking">There are {competitorsData && competitorsData.data.currentStoreDetail.trackedCount >= 1 ? competitorsData.data.currentStoreDetail.trackedCount - unTrackedCount.length : '0'} store{competitorsData && competitorsData.data.currentStoreDetail.trackedCount > 1 ? 's' : ''} within zip code(s) {zipcodeOptions && zipcodeOptions[0] ? zipcodeOptions[0] : ''}{zipcodeOptions && zipcodeOptions[1] && !zipcodeOptions[2] ? ' and ' + zipcodeOptions[1] : ''}{zipcodeOptions && zipcodeOptions[1] && zipcodeOptions[2] ? ', ' + zipcodeOptions[1] : ''}{zipcodeOptions && zipcodeOptions[2] ? ' and ' + zipcodeOptions[2] : ''}.</p> */}
                          </div>
                          <div className="col-lg-4 pl-0">
                            <p className="right-link">
                              <MissingCompetitor
                                className="list-item-text add-mar-comp"
                                isShowModal={this.state.showModal}
                              />
                            </p>
                          </div>
                        </div>
                      ) : this.state.showComponent === "city" ? (
                        <div className="row">
                          <div className="col-lg-8">
                            <h4>City based market coverage</h4>
                            <p className="you-are-tracking">
                              There{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 2
                                ? "are"
                                : competitorsData.data.currentStoreDetail
                                    .trackedCount === 0
                                ? "are"
                                : "is"}{" "}
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount >= 1
                                ? competitorsData.data.currentStoreDetail
                                    .trackedCount
                                : "no"}{" "}
                              competitor
                              {competitorsData &&
                              competitorsData.data.currentStoreDetail
                                .trackedCount > 1
                                ? "s"
                                : ""}{" "}
                              within the city of{" "}
                              {competitorsData
                                ? competitorsData.data.currentStoreDetail.city.replace(
                                    ",",
                                    ", "
                                  )
                                : ""}
                              . <HelpTextPopup parm={defaultProps} />
                            </p>
                            {/* <p className="you-are-tracking">There are {competitorsData && competitorsData.data.currentStoreDetail.trackedCount >= 1 ? competitorsData.data.currentStoreDetail.trackedCount - unTrackedCount.length : '0'} store{competitorsData && competitorsData.data.currentStoreDetail.trackedCount > 1 ? 's' : ''} within the city of {competitorsData ? competitorsData.data.currentStoreDetail.city.replace(',', ', ') : ''} city.</p> */}
                          </div>
                          <div className="col-lg-4 pl-0">
                            <p className="right-link">
                              <MissingCompetitor
                                className="list-item-text add-mar-comp"
                                isShowModal={this.state.showModal}
                              />
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <AppBar position="static" className="tabs-pick-market">
                        <Tabs
                          value={tabvalue}
                          onChange={this.tabhandleChange.bind(this)}
                        >
                          <Tab icon={<List />} />
                          <Tab icon={<LocationOn />} />
                        </Tabs>
                      </AppBar>

                      {tabvalue === 0 && (
                        <TabContainer>
                          <Table className="table table-bordered custom-table">
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell>Market Name</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Distance</TableCell>
                                <TableCell
                                  align="left"
                                  className="left-zero-padding"
                                >
                                  Track
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {competitorsData &&
                              competitorsData.data.competitors.length > 0 ? (
                                competitorsData.data.competitors
                                  .slice(
                                    pageCountMDT * 10,
                                    pageCountMDT * 10 + 10
                                  )
                                  .map((compdata) => (
                                    <TableRow key={compdata.recNum}>
                                      <TableCell className="st-name">
                                        {compdata.storeName}
                                      </TableCell>
                                      <TableCell>
                                        {compdata.storeAddress}
                                      </TableCell>
                                      <TableCell>
                                        {compdata.proximity
                                          ? compdata.proximity
                                          : ""}{" "}
                                        {compdata.proximity > 0 &&
                                        (CONFIG.RegionId == 2 ||
                                          CONFIG.RegionId == 4)
                                          ? " " + "km"
                                          : compdata.proximity > 0 &&
                                            CONFIG.RegionId != (2 || 4)
                                          ? " " + "mi"
                                          : ""}
                                      </TableCell>
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          key={compdata.recNum}
                                          name="deleteCheck"
                                          // checked={trackData.isTracked === true}

                                          // checked={(trackData.storeID + '|1') === strOptions.find((e) => e === (trackData.storeID + '|1'))}
                                          // onClick={event => this.upgradehandleClick(event, trackData.storeID, trackData.userStoreID)} />
                                          className="track-check"
                                          checked={
                                            compdata.storeId + "|1" ===
                                            trackedFields.find(
                                              (e) =>
                                                e === compdata.storeId + "|1"
                                            )
                                          }
                                          onClick={(event) =>
                                            this.trackhandleClick(
                                              event,
                                              compdata.storeId,
                                              compdata.userStoreId
                                            )
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan="4"
                                    align="center"
                                    className="no-data"
                                  >
                                    Markets not available.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                            <TableFooter>
                              {/* {this.state.trackedFields1 && this.state.trackedFields1.length > 0 ?
                                                                        <TableRow>
                                                                            <TableCell colSpan={4} className="text-right">
                                                                                <Button variant="contained" onClick={() => this.sendTrackFields()} color="primary" className="save-btn-grid">Save</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        : ''} */}
                              <TableRow>
                                <TablePagination
                                  rowsPerPageOptions={[1]}
                                  colSpan={5}
                                  count={
                                    competitorsData
                                      ? competitorsData.data.competitors.length
                                      : 0
                                  }
                                  rowsPerPage={10}
                                  page={pageCountMDT}
                                  // SelectProps={{
                                  //     native: true,
                                  // }}
                                  // backIconButtonProps={{
                                  //     'aria-label': 'Previous',
                                  // }}
                                  // nextIconButtonProps={{
                                  //     'aria-label': 'Next',
                                  // }}
                                  // labelDisplayedRows={({ from, to, count }) => `Displaying pages ${from}-${to} of total ${count} pages`}
                                  onChangePage={this.handleChangePageMTD}
                                  onChangeRowsPerPage={
                                    this.handleChangeRowsPerPageMTD
                                  }
                                  ActionsComponent={MyMarketWrapped}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TabContainer>
                      )}
                      {tabvalue === 1 && (
                        <TabContainer>
                          {this.state.showComponent === "radius" ? (
                            <Fragment>
                              <this.CMap
                                googleMapURL={this.props.googleMapURL}
                                loadingElement={
                                  <div style={{ height: `100%` }} />
                                }
                                containerElement={
                                  <div style={{ height: `300px` }} />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lat
                                    ),
                                    lng: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lng
                                    ),
                                  }}
                                  icon={orangehome}
                                />

                                {competitorsData.data.competitors.map(
                                  (marker, index) => {
                                    return (
                                      <Marker
                                        position={{
                                          lat: parseFloat(marker.lat),
                                          lng: parseFloat(marker.lng),
                                        }}
                                        icon={
                                          marker.isMyStore == 1
                                            ? orangepin
                                            : marker.storeStatus < 1
                                            ? blackpin
                                            : bluepin
                                        }
                                        onMouseOver={() =>
                                          this.onMarkerClick(
                                            marker.isTrack,
                                            marker.storeId,
                                            marker.userStoreId,
                                            marker.storeName,
                                            marker.lat,
                                            marker.lng,
                                            marker.storeAddress
                                          )
                                        }
                                      >
                                        {this.state.showingInfoWindow &&
                                        this.state.selectedPlace ===
                                          marker.storeId ? (
                                          <InfoWindow
                                            onCloseClick={() =>
                                              this.onMarkerClose()
                                            }
                                            visible={
                                              this.state.showingInfoWindow
                                            }
                                          >
                                            <div className="gmap-tooltip color-white">
                                              <h3>{this.state.activeMarker}</h3>
                                              <p>
                                                {this.state.address},<br />
                                                {this.state.address1}
                                              </p>
                                              <p>
                                                <input
                                                  type="checkbox"
                                                  key={marker.recNum}
                                                  name="deleteCheck"
                                                  checked={
                                                    marker.storeId + "|1" ===
                                                    trackedFields.find(
                                                      (e) =>
                                                        e ===
                                                        marker.storeId + "|1"
                                                    )
                                                  }
                                                  onClick={(event) =>
                                                    this.trackhandleClick(
                                                      event,
                                                      marker.storeId,
                                                      marker.userStoreId
                                                    )
                                                  }
                                                />{" "}
                                                {this.state.isTracks &&
                                                this.state.isTracks === 1
                                                  ? "Click to Untrack"
                                                  : "Click to Track"}
                                              </p>
                                            </div>
                                          </InfoWindow>
                                        ) : (
                                          ""
                                        )}
                                      </Marker>
                                    );
                                  }
                                )}
                                <Circle
                                  center={{
                                    lat: parseFloat(
                                      competitorsData.data.currentStoreDetail
                                        .lat
                                    ),
                                    lng: parseFloat(
                                      competitorsData.data.currentStoreDetail
                                        .lng
                                    ),
                                  }}
                                  radius={
                                    this.state.scale
                                      ? this.state.scale * 1609.34
                                      : 0
                                  }
                                  options={{
                                    fillColor: "#000",
                                    fillOpacity: 0,
                                    // strokeColor: "#000",
                                    // strokeOpacity: 1,
                                    // strokeWeight: 1
                                  }}
                                />
                              </this.CMap>
                            </Fragment>
                          ) : this.state.showComponent === "zipcode" ? (
                            <Fragment>
                              <this.CMap
                                googleMapURL={this.props.googleMapURL}
                                loadingElement={
                                  <div style={{ height: `100%` }} />
                                }
                                containerElement={
                                  <div style={{ height: `300px` }} />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lat
                                    ),
                                    lng: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lng
                                    ),
                                  }}
                                  icon={orangehome}
                                />
                                {competitorsData.data.competitors.map(
                                  (marker, index) => {
                                    return (
                                      <Marker
                                        position={{
                                          lat: parseFloat(marker.lat),
                                          lng: parseFloat(marker.lng),
                                        }}
                                        icon={
                                          marker.isMyStore == 1
                                            ? orangepin
                                            : marker.storeStatus < 1
                                            ? blackpin
                                            : bluepin
                                        }
                                        onMouseOver={() =>
                                          this.onMarkerClick(
                                            marker.isTrack,
                                            marker.storeId,
                                            marker.userStoreId,
                                            marker.storeName,
                                            marker.lat,
                                            marker.lng,
                                            marker.storeAddress
                                          )
                                        }
                                      >
                                        {this.state.showingInfoWindow &&
                                        this.state.selectedPlace ===
                                          marker.storeId ? (
                                          <InfoWindow
                                            onCloseClick={() =>
                                              this.onMarkerClose()
                                            }
                                            visible={
                                              this.state.showingInfoWindow
                                            }
                                          >
                                            <div className="gmap-tooltip color-white">
                                              <h3>{this.state.activeMarker}</h3>
                                              <p>
                                                {this.state.address},<br />
                                                {this.state.address1}
                                              </p>
                                              <p>
                                                <input
                                                  type="checkbox"
                                                  key={marker.recNum}
                                                  name="deleteCheck"
                                                  checked={
                                                    marker.storeId + "|1" ===
                                                    trackedFields.find(
                                                      (e) =>
                                                        e ===
                                                        marker.storeId + "|1"
                                                    )
                                                  }
                                                  onClick={(event) =>
                                                    this.trackhandleClick(
                                                      event,
                                                      marker.storeId,
                                                      marker.userStoreId
                                                    )
                                                  }
                                                />{" "}
                                                {this.state.isTracks &&
                                                this.state.isTracks === 1
                                                  ? "Click to Untrack"
                                                  : "Click to Track"}
                                              </p>
                                            </div>
                                          </InfoWindow>
                                        ) : (
                                          ""
                                        )}
                                      </Marker>
                                    );
                                  }
                                )}

                                {this.state.coordinates_one2.map((lat_lng) => {
                                  return (
                                    <Polygon
                                      path={lat_lng[0]}
                                      options={{
                                        strokeColor: "#a393b3",
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                        fillColor: "Transparent",
                                        fillOpacity: 0.2,
                                      }}
                                    />
                                  );
                                })}
                              </this.CMap>
                            </Fragment>
                          ) : this.state.showComponent === "city" ? (
                            <Fragment>
                              <this.CMap
                                googleMapURL={this.props.googleMapURL}
                                loadingElement={
                                  <div style={{ height: `100%` }} />
                                }
                                containerElement={
                                  <div style={{ height: `300px` }} />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lat
                                    ),
                                    lng: parseFloat(
                                      this.state.competitorsData.data
                                        .currentStoreDetail.lng
                                    ),
                                  }}
                                  icon={orangehome}
                                />
                                {competitorsData.data.competitors.map(
                                  (marker, index) => {
                                    return (
                                      <Marker
                                        position={{
                                          lat: parseFloat(marker.lat),
                                          lng: parseFloat(marker.lng),
                                        }}
                                        icon={
                                          marker.isMyStore == 1
                                            ? orangepin
                                            : marker.storeStatus < 1
                                            ? blackpin
                                            : bluepin
                                        }
                                        onMouseOver={() =>
                                          this.onMarkerClick(
                                            marker.isTrack,
                                            marker.storeId,
                                            marker.userStoreId,
                                            marker.storeName,
                                            marker.lat,
                                            marker.lng,
                                            marker.storeAddress
                                          )
                                        }
                                      >
                                        {this.state.showingInfoWindow &&
                                        this.state.selectedPlace ===
                                          marker.storeId ? (
                                          <InfoWindow
                                            onCloseClick={() =>
                                              this.onMarkerClose()
                                            }
                                            visible={
                                              this.state.showingInfoWindow
                                            }
                                          >
                                            <div className="gmap-tooltip color-white">
                                              <h3>{this.state.activeMarker}</h3>
                                              <p>
                                                {this.state.address},<br />
                                                {this.state.address1}
                                              </p>
                                              <p>
                                                <input
                                                  type="checkbox"
                                                  key={marker.recNum}
                                                  name="deleteCheck"
                                                  checked={
                                                    marker.storeId + "|1" ===
                                                    trackedFields.find(
                                                      (e) =>
                                                        e ===
                                                        marker.storeId + "|1"
                                                    )
                                                  }
                                                  onClick={(event) =>
                                                    this.trackhandleClick(
                                                      event,
                                                      marker.storeId,
                                                      marker.userStoreId
                                                    )
                                                  }
                                                />{" "}
                                                {this.state.isTracks &&
                                                this.state.isTracks === 1
                                                  ? "Click to Untrack"
                                                  : "Click to Track"}
                                              </p>
                                            </div>
                                          </InfoWindow>
                                        ) : (
                                          ""
                                        )}
                                      </Marker>
                                    );
                                  }
                                )}

                                {this.state.coordinates_one.map((lat_lng) => {
                                  return (
                                    <Polygon
                                      path={lat_lng[0]}
                                      options={{
                                        strokeColor: "#a393b3",
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                        fillColor: "Transparent",
                                        fillOpacity: 0.2,
                                      }}
                                    />
                                  );
                                })}
                              </this.CMap>
                            </Fragment>
                          ) : (
                            ""
                          )}
                          <Col md="12" className="margin-2 map-btm-legend">
                            <Row>
                              <div className="legend-block">
                                <div className="maps-legend-orange"></div>Your
                                Store
                              </div>
                              <div className="legend-block">
                                <div className="maps-legend-blue"></div>Store
                                with pricing
                              </div>
                              <div className="legend-block">
                                <div className="maps-legend-black"></div>Store
                                without pricing
                              </div>
                            </Row>
                          </Col>
                        </TabContainer>
                      )}
                      <Button
                        ref={(btn2) => {
                          this.btn2 = btn2;
                        }}
                        onClick={this.saveCovergae.bind(this)}
                        variant="contained"
                        color="primary"
                        className="next-btn "
                      >
                        Save and Go to Next
                      </Button>
                      {localStorage.getItem("userType") === "2" ? (
                        <Button
                          type="button"
                          //disabled={this.state.activeStep === 1}
                          onClick={this.handleClose}
                          className="go-btn"
                        >
                          Go Back
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          //disabled={this.state.activeStep === 1}
                          onClick={this.handleBack}
                          className="go-btn"
                        >
                          Go Back
                        </Button>
                      )}
                    </div>
                  ) : this.state.activeStep === 3 &&
                    this.state.activeStep > steps.length ? (
                    <div>
                      <h3 className="added-txt">
                        Market{" "}
                        {this.state.title === "Edit Market" ? "Updat" : "Add"}ed
                        Successfully!
                      </h3>
                      <div className="market-success">
                        <p>
                          You are tracking{" "}
                          {this.state.trackResponse.trackCount > 0
                            ? this.state.trackResponse.trackCount
                            : "no"}{" "}
                          competitor
                          {this.state.trackResponse.trackCount > 1
                            ? "s"
                            : this.state.trackResponse.trackCount === 0
                            ? "s"
                            : ""}{" "}
                          in this Market.{" "}
                          <Link
                            to={{
                              pathname: `/market-dashboard/${this.state.userStoreID}`,
                            }}
                          >
                            Go to the Market Dashboard
                          </Link>{" "}
                          or click below to go back to My Markets.
                        </p>
                        {/* <p>{this.state.trackResponse.trackCount} Store{this.state.trackResponse.trackCount > 1 ? 's' : ''} are tracked in this Market. You can go to the <Link to={{ pathname: `/market-dashboard/${this.state.userStoreID}` }}>Market Dashboard</Link> or <span className="bold-txt">go back to My Markets</span> by clicking the button below.</p> */}
                        <p className="go-to-top">
                          <Button onClick={this.goToMymarket.bind(this)}>
                            Go to My Markets
                          </Button>
                        </p>
                      </div>
                    </div>
                  ) : (
                    // : this.state.activeStep > steps.length ?
                    //     <div className="market-success">
                    //     <h3 className="added-txt">Market Added Successfully!</h3>
                    //     <p>{this.state.trackResponse.trackCount} Store{this.state.trackResponse.trackCount > 1 ? 's' : ''} are tracked in this Market. You can immediately view this <Link to={{ pathname: `/market-dashboard/${this.state.userStoreID}` }}>Market Dashboard</Link> or <span className='cursur' onClick={this.goToMymarket.bind(this)}>go back to My Markets</span> screen by clicking the button below</p>
                    //     <p className='go-to-top'><Button onClick={this.goToMymarket.bind(this)}>Go to My Markets</Button></p>
                    // </div>
                    <div>
                      <h3 className="added-txt">
                        Market{" "}
                        {this.state.title === "Edit Market" ? "Updat" : "Add"}ed
                        Successfully!
                      </h3>
                      <div className="market-success">
                        <p>
                          You are tracking{" "}
                          {this.state.trackResponse.trackCount > 0
                            ? this.state.trackResponse.trackCount
                            : "no"}{" "}
                          competitor
                          {this.state.trackResponse.trackCount > 1
                            ? "s"
                            : this.state.trackResponse.trackCount === 0
                            ? "s"
                            : ""}{" "}
                          in this Market.{" "}
                          <Link
                            to={{
                              pathname: `/market-dashboard/${this.state.userStoreID}`,
                            }}
                          >
                            Go to the Market Dashboard
                          </Link>{" "}
                          or click below to go back to My Markets.
                        </p>
                        {/* <p>{this.state.trackResponse.trackCount} Store{this.state.trackResponse.trackCount > 1 ? 's' : ''} are tracked in this Market. You can go to the <Link to={{ pathname: `/market-dashboard/${this.state.userStoreID}` }}>Market Dashboard</Link> or <span className="bold-txt">go back to My Markets</span> by clicking the button below.</p> */}
                        <p className="go-to-top">
                          <Button onClick={this.goToMymarket.bind(this)}>
                            Go to My Markets
                          </Button>
                        </p>
                      </div>
                    </div>
                  )}

                  {/* <div>
                                                            <Button
                                                                disabled={this.state.activeStep === 0}
                                                                onClick={this.handleBack}

                                                            >
                                                                Back
              </Button>
                                                            <Button variant="contained" color="primary" onClick={this.handleNext}>
                                                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                            </Button>
                                                        </div> */}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className="snackbar bg-color-primary"
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackMSg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

// For Pagination
const actionsStyles = (theme) => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket
);

//For Tab
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
