export const COMMON = {
    priceOption:{
        Average: 1, 
        Premium: 2,
        Value: 3
    },
    priceOptValue1:{
        lessThan:1,
        moreThan:2
    },
    priceOptValue2:{
        increasesBy:1,
        decreasesBy:2
    },
    StorTrackRegion:{
        US:1,
        AUS:2,
        UK:3,
        NZ:4
    },
    MVPUSER: [4,5,10]
}