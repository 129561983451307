import React from 'react';
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import * as d3 from "d3";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import {
  Container, Col, Row, Button, Label, Input, FormText,
  CardDeck, Card, CardTitle, CardSubtitle, CardFooter, CardBody
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './AveragePrice.css';
import freeoval from './../../Assets/Images/oval1.png';
import singleimg from './../../Assets/Images/oval2.png';
import multiimg from './../../Assets/Images/oval3.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Divider from '@material-ui/core/Divider';
import CreditCardInput from 'react-credit-card-input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { CONFIG, MatchingCountryCode } from '../../utils/config';
import Checkbox from '@material-ui/core/Checkbox';
import { COMMON } from '../../utils/common';


function getSteps() {
  return ['Create Your Account', 'Billing Address', 'Payment Details', 'You are Confirmed!'];
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      hisactivedur: 1,
      unitDurations: [],
      unitTypes: [],
      CurrentHistory: [],
      AllCurrentHistory: [],
      isLoading: false,
      defaultWidth: 0,
      // isDefaultUnit: 1,
      isDefaultUnit: "All Units",
      isDefaultDateRange: 1,
      widgetId: 10, //This is widget id in DB
      dataStatus: false,
      stateLookup: [],
      cityLookup: [],
      storeName: '',
      coverage: '',
      palettecomparision: '',
      showLoader: false,
      is_onchange: false,
      selectedState: '',
      selectedCountry: ''
    };
    // this.emailInput = React.createRef();
    this.DurationsdropDown = this.DurationsdropDown.bind(this);
    this.onDurAndPriChange = this.onDurAndPriChange.bind(this);
  }


  componentDidMount() {
    this.DurationsdropDown();
    this.loadStateValues();
    this.initialLoad();
  }



  async onDurAndPriChange(e) {
    const { name, value } = e.target;
    const AllCurrentHistory = this.state.AllCurrentHistory;
    var datas = []
    if (name === 'unitTypeChange') {
      this.state.isDefaultUnit = value;
      datas = AllCurrentHistory.filter(a => a.UnitType === value);
    }

    this.HistoryGraphView(datas);

  }

  // Text Wrap For Graph X-axis label
  wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
      while (word = words.pop()) {
        line.push(word)
        tspan.text(line.join(" "))
        if (tspan.node().getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(" "))
          line = [word]
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
        }
      }
    })
  }

  //Formatted in as per competitor
  HisformData(data, uid) {
    var tFData = data.filter(a => a.MarketID === uid);
    var tData = [];
    for (var i = 0; i < tFData.length; i++) {
      // for price range calculation    
      var CData = {
        date: tFData[i].Date,
        Date: moment(tFData[i].Date).toDate(),
        MarketName: tFData[i].MarketName,
        price: parseFloat(tFData[i].Value),
        labeldate: tFData[i].Date,
        labelPrice: tFData[i].Value === '0' ? 'N/A' : tFData[i].Value,
      }
      tData.push(CData);
    }
    return tData;
  }
  getYaxismin(minVal) {
    var modVal = minVal % 10;
    minVal = minVal - modVal;
    return minVal;
  }
  getYaxisMax(maxVal) {
    var modVal = maxVal % 10;
    var maxVal = (maxVal - modVal) + (modVal + (10 - modVal));
    if (maxVal > 200) {
      var modVal1 = maxVal % 50;
      maxVal = (maxVal - modVal1) + (modVal1 + (50 - modVal1));
    }
    return maxVal;
  }
  calcMAxVal(minVal, maxVal) {
    var diffVal = maxVal - minVal;
    var incRatio = Math.round(diffVal / 5);
    var maxMinVal = 0;
    var maxCalVal = [];
    maxCalVal.push(minVal);
    for (let i = 1; maxMinVal < maxVal; i++) {
      if (i * incRatio < maxVal) {
        var maxRVal = minVal + (i * incRatio);
        maxMinVal = maxRVal;
        maxCalVal.push(maxRVal);
      }
      else {
        break;
      }
    }
    return maxCalVal;
  }
  //Graph for History
  HistoryGraphView(Hisdata) {
    // var Hisdata = graphDatas;
    var decimalFormat = d3.format("0.2f");
    d3.selectAll(".mainhisGraphdivs > *").remove();
    d3.select(".av-wid-legend").remove();
    let MaxPrice;
    if (Hisdata.length > 0) {
      MaxPrice = d3.max(Hisdata.map(function (d) {
        return d.Value != "" && d.Value != null ? d.Value : 0;
      }));
    };

    var height = 200;
    if (MaxPrice) {

      Hisdata.map(record => {
        record.Value = record.Value !== "" || record.Value !== null ? record.Value : ''
      });

      // d3.selectAll(".mainhisGraphdivs > *").remove();
      // d3.select(".av-wid-legend").remove();

      var margin = 50;
      var width = Hisdata.length > 0 ? (d3.select('.mainhisGraphdivs').node().offsetWidth) - margin : 0;


      var lineOpacity = "1";

      var currency = CONFIG.Currency_Symbol;
      const tParser = d3.timeParse("%m/%d/%Y")

      /*Data Formation*/
      var tempdata = [];
      //To get the Unique store ids
      let unique = [...new Set(Hisdata.map(item => item.MarketID))];
      let MarketName = [...new Set(Hisdata.map(item => item.MarketName))];
      for (var i = 0; i < unique.length; i++) {
        if (MarketName[i]) {
          var tFData = Hisdata.filter(a => a.MarketID == unique[i]);
          var datum = {
            name: tFData.length > 0 ? tFData[0].MarketName : '',
            storeID: tFData.length > 0 ? tFData[0].MarketID : '',
            values: this.HisformData(tFData, unique[i])
          }
          tempdata.push(datum);
        }
      }
      var tmin = d3.min(Hisdata.map(function (d) {
        if (d.Value != 0) {
          return Number(d.Value);
        }
      }));
      var tmax = d3.max(Hisdata.map(function (d) { return d.Value ? parseInt(d.Value) : 0 }));
      tmin = this.getYaxismin(tmin);
      tmax = this.getYaxisMax(tmax);
      var arrMax = this.calcMAxVal(tmin, tmax);
      d3.select('.mainhisGraphdivs').append('div').attr('class', 'hisGraph1');

      /* Scale */
      var xScale = d3.scaleTime()
        .domain(d3.extent(tempdata[0].values, function (d) { return tParser(d.date); }))
        .range([0, width - 100]);

      var yScale = d3.scaleLinear()
        .domain([tmin, tmax])
        .range([height - margin, 0]);

      //Colors
      var filterColors = ['#4D4D4D', '#5DB975', '#5B95F6'];

      var color = d3.scaleOrdinal(filterColors);

      /* Add SVG */
      var svg = d3.selectAll(".hisGraph1").append("svg")
        .attr("width", (width) + "px")
        .attr("height", (height + margin) + "px")
        .append('g')
        .attr("transform", `translate(${margin + 20}, ${margin})`);


      // var line = d3.line()
      //     .x(d => xScale(tParser(d.date)))
      //     .y(d => yScale(d.price));
      var line = d3.line()
        .x(d => xScale(tParser(d.date)))
        .y(d => yScale(d.price))
        .defined(function (d) { return !isNaN(d.price != null && d.price != 0 ? d.price : "N/A"); });

      let lines = svg.append('g')
        .attr('class', 'lines');
      lines.selectAll('.line-group')
        .data(tempdata).enter()
        .append('g')
        .attr('class', 'line-group')
        .append('path')
        .attr('class', 'line')
        .attr('d', d => line(d.values))
        .style('stroke', (d, i) => color(i))
        .attr("stroke-width", 1)
        .style('opacity', "1");


      /*End of Price Range Graph*/
      var focus = svg.append("g")
        .attr("class", "focus")
        .style("display", "none");

      focus.append("line")
        .attr("class", "x-hover-line hover-line")
        .attr("y1", 10)
        .attr("y2", (height - 24));

      focus.append("line")
        .attr("class", "y-hover-line hover-line")
        .attr("x1", width)
        .attr("x2", width);

      focus.append("text")
        .attr("x", 15)
        .attr("dy", ".31em");

      svg.append("rect")
        .attr("transform", "translate( 0  , 0 )")
        .attr("class", "overlay")
        .style('fill', 'none')
        .attr("width", width - 100)
        .attr("height", height - 30)
        .on("mouseover", function () { focus.style("display", null); })
        .on("mouseout", function () {
          focus.style("display", "none");
          //Legend value change
          d3.selectAll('.his_price').text('[---]');
          d3.selectAll('.ld_span').text('- - -');
        })
        .on("mousemove", mousemove);

      const bisectDate = d3.bisector(d => d.Date).left;
      function mousemove() {

        var x0 = xScale.invert(d3.mouse(this)[0]),
          i = bisectDate(tempdata[0].values, x0, 1),
          d0 = tempdata[0].values[i - 1],
          d1 = tempdata[0].values[i],
          d2 = x0 - d0.Date > d1.Date - x0 ? d1 : d0,
          d = x0 - d0.Date > d1.Date - x0 ? d1.Date : d0.date;


        //Hover Line 
        focus.attr("transform", "translate(" + xScale(d2.Date) + ", -12 )");
        //ToolTip
        var Tooltip_date = moment(d).format('DD-MMM-YYYY');
        // div.transition()
        //     .duration(200)
        //     .style("opacity", .9);

        //Get Current date stores Values
        let Storecount = [];
        for (var c = 0; c < tempdata.length; c++) {
          let getSCData = tempdata[c].values.filter(data => {

            var Filter_date = moment(data.Date).format('DD-MMM-YYYY');
            return Filter_date === Tooltip_date;
          });
          Storecount.push(getSCData.length > 0 ? getSCData[0] ? getSCData[0] : "N/A" : 'N/A');
        }

        //Legend value Change
        d3.select('.av-wid-legend').selectAll('.key').remove();
        d3.select('.av-wid-legend').selectAll('.legend_date').remove();

        //For Date
        d3.select('.av-wid-legend').append('div')
          .attr('class', 'col-md-12 legend_date')
          .style('padding-left', '0')
          .style('padding-bottom', '2px')
          .style('font-size', '15px')
          .style('display', 'inline-block')
          .html('<b>Date : </b><span class="ld_span">' + Tooltip_date + '</span>');

        //For Store Details
        //var div_width = ( width / 3) - 50 ;
        let keys = d3.select('.av-wid-legend').selectAll('.key')
          .data(Storecount).enter()
          .append('div')
          .attr('class', 'key col-md-3')
          .style('display', 'inline-block')
          .style('align-items', 'center');

        keys.append('div')
          .attr('class', 'symbol')
          .style('display', 'inline-block')
          .style('height', '10px')
          .style('width', '10px')
          .style('margin-right', '5px')
          .style('background-color', (d, i) => color(i));

        keys.append('div')
          .attr('class', 'name')
          .style('display', 'inline-block')
          .style('font-size', '11px')
          .style('font-weight', '500')
          .text(d => `${d.MarketName} `);

        keys.append('div')
          .attr('class', 'his_price')
          .style('display', 'inline-block')
          .style('color', (d, i) => color(i))
          .style('margin-left', '10px')
          .text(d => !isNaN(d.price) ? '[' + currency + d.price.toFixed(2) + ']' : '[N/A]');

        keys.exit().remove();

      }

      /* Add X Axis into SVG */
      var info = tempdata[0].values.map(function (d) { return d.date })
      if ((this.state.hisactivedur == 1 && this.props.isLoading === false) || (this.state.hisactivedur == 1 && this.state.isLoading === false)) {
        var rDate = new Date(info[0]);
        var rday = rDate.getDay();
        var dayv = rday == 0 ? d3.timeSunday : rday == 1 ? d3.timeMonday : rday == 2 ? d3.timeTuesday : rday == 3 ? d3.timeWednesday : rday == 4 ? d3.timeThursday : rday == 5 ? d3.timeFriday : rday == 6 ? d3.timeSaturday : d3.timeDay;
        // if (this.state.selectedState != '') {
        svg.append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0, ${height - margin + 20})`)
          //.call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d-%b-%Y")).tickPadding(1).ticks(d3.timeWeek));
          //Day wise
          .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d-%b-%Y")).ticks(d3.timeDay.every(1)));
        // } else {
        //   svg.append('g')
        //     .attr('class', 'x-axis')
        //     .attr('transform', `translate(0, ${height - margin + 20})`)
        //     .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d-%b-%Y")).tickPadding(1).ticks(d3.timeWeek));
        // }
        // svg.append('g')
        //     .attr('class', 'x-axis')
        //     .attr('transform', `translate(0, ${height - margin + 20})`)
        //.call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d-%b-%Y")).tickPadding(1).ticks(d3.timeWeek));
        //Day wise
        //  .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%d-%b-%Y")).ticks(d3.timeDay.every(1)));
      }
      else if (this.state.hisactivedur == 6) {
        svg.append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0, ${height - margin + 20})`)
          .call(d3.axisBottom(xScale).ticks(d3.timeMonth.utc, 1).tickFormat(d3.timeFormat("%b-%y")));
      }
      else {
        svg.append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0, ${height - margin + 20})`)
          .call(d3.axisBottom(xScale).ticks(d3.timeMonth).tickFormat(d3.timeFormat("%b-%y")));
      }

      var yAxis = d3.axisLeft(yScale).tickValues(arrMax).tickFormat(function (d) {
        let split_d = d.toString().split('.');
        let d1 = split_d[1] ? split_d[1].length > 1 ? d : d + '0' : d + '.00';
        return currency + d1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      svg.append("g")
        .attr("class", "y-axis")
        .call(yAxis)
        .append('text')
        .attr("transform", "rotate(-90)")
        .attr("x", - "70")
        .attr("y", - "55")
        .attr("fill", "#6c757d")
        .attr("font-weight", "500")
        .attr("text-anchor", "middle")
        .text("Average Price");

      //add horizantal line
      svg.selectAll(".y-axis")
        .selectAll(".tick line")
        .call(yAxis)
        .attr("x2", width - margin);


      let linesm = svg.append('g')
        .attr('class', 'x_lines');

      linesm.append('path')
        .attr('class', 'x_line')
        .attr('d', 'M0.5,6V0.5H' + (width - margin) + '.5V6')
        .attr("transform", `translate(0, ${height - margin + 20} )`)
        .style('stroke', '#cbd1d5');

      // To identify the Line belongs to which company and Date

      let legend = d3.select(".av-market-legend").append('div')
        .attr('class', 'legend av-wid-legend')
        .style('margin-top', '10px');

      //For Date
      legend.append('div')
        .attr('class', 'col-md-12 legend_date')
        .style('padding-left', '0')
        .style('padding-bottom', '2px')
        .style('font-size', '15px')
        .style('display', 'inline-block')
        .html('<b>Date : </b><span class="ld_span">- - -</span>');

      //For Store Details
      //var div_width =( width / 3) - 50 ;
      let keys = legend.selectAll('.key')
        .data(tempdata).enter()
        .append('div')
        .attr('class', 'key col-md-3')//(d, i) => d.name === "This Market" ? 'key col-md-3' : 'key col-md-4')
        .style('display', 'inline-block')
        .style('align-items', 'center');

      keys.append('div')
        .attr('class', 'symbol')
        .style('display', 'inline-block')
        .style('height', '10px')
        .style('width', '10px')
        .style('margin-right', '5px')
        .style('background-color', (d, i) => color(i));

      keys.append('div')
        .attr('class', 'name')
        .style('display', 'inline-block')
        .style('font-size', '11px')
        .style('font-weight', '500')
        .text(d => `${d.name + ' '} `);

      keys.append('div')
        .attr('class', 'his_price')
        .style('display', 'inline-block')
        .style('margin-left', '10px')
        .style('color', (d, i) => color(i))
        .text('[---]');

      keys.exit().remove();
      //  this.props.showLoading({isLoading: false, widgetId: this.state.widgetId});

    } else {
      //dispatch the action once the data recived from reducer to hide loader 

      if (this.state.dataStatus && (MaxPrice === 0 || Hisdata.length > 0)) {
        //  this.props.showLoading({isLoading: false, widgetId: this.state.widgetId});
        //No data Div
        d3.select('.mainhisGraphdivs').append('div')
          .style('height', (height) + 'px')
          .append('p')
          .attr('class', 'loading-text no-data')
          .style('padding', '70px')
          .style('text-align', 'center')
          .text('No data Found');

      }
      else {
        d3.select('.mainhisGraphdivs').append('div')
          .style('height', (height) + 'px')
          .append('p')
          .attr('class', 'loading-text')
          .style('text-align', 'center')
          .style('padding', '20px')
          .text(' Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.');
      }

    }
    this.setState({ isLoading: false });

  }

  //load initial graph 
  initialLoad() {
    this.setState({ isLoading: true });
    // let token = localStorage.getItem('accessKey');
    // if (token) {
    //   axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    // }
    let inputData = {
      "RegionID": CONFIG.RegionId,
      "State": this.state.selectedState != '' ? this.state.selectedState : '',
      "City": ""
    }
    axios.post(CONFIG.API_URL + 'home/weeklypricedata', inputData)
      .then(response => {
        let data = response.data;
        let datas = response.data.filter(data => data.UnitType === this.state.isDefaultUnit);
        this.GetcurrentpricinganalysisHistory(data);
        this.HistoryGraphView(datas);
        this.setState({ isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }
  //load citybased graph
  cityBasedData(e) {
    this.setState({ isLoading: true });
    let citySelected = e.currentTarget.value;
    // let token = localStorage.getItem('accessKey');
    // if (token) {
    //   axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    // }
    let inputData = {
      "RegionID": CONFIG.RegionId,
      "State": this.state.selectedState,
      "City": citySelected
    }
    axios.post(CONFIG.API_URL + 'Home/weeklypricedata', inputData)
      .then(response => {
        let data = response.data;
        let datas = response.data.filter(data => data.UnitType === this.state.isDefaultUnit);
        this.GetcurrentpricinganalysisHistory(data);
        this.setState({ isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }
  NumberFormat(number) {
    return new Intl.NumberFormat().format(number);
  }

  //city selection based on state values
  citySelection(e) {
    this.setState({ isLoading: true });
    let selCity = e.currentTarget.value;
    let citySelected = selCity.split(",");
    // let token = localStorage.getItem('accessKey');
    let selectedState = citySelected[0];
    let selectedCou = citySelected[1];
    let selectedCountry = selCity != '' ? selectedCou.replace(/\s+/g, '') : '';
    let data = selCity != '' ? citySelected[0].replace(/\"/g, "") : '';
    if (selectedCountry != '') {
      this.setState({ selectedCountry, isDefaultUnit: "All Units" }, () => {
        this.DurationsdropDown();
      });

    } else {
      this.setState({ selectedCountry: '', isDefaultUnit: "All Units" }, () => {
        this.DurationsdropDown();
      });
    }
    // if (token) {
    //   axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    // }
    if (selCity != '') {
      axios.get(CONFIG.API_URL + 'home/citydatabystate/' + data + '/' + CONFIG.RegionId)
        .then(response => {
          console.log(response.data, "res data");
          let cityLookup = response.data.cityData;
          this.setState({ cityLookup, isLoading: false, selectedState }, () => {
            this.initialLoad();
          });
        })
        .catch(err => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ cityLookup: [], selectedState, isLoading: false }, () => {
        this.initialLoad();
      });
    }
  }

  //load state data values
  loadStateValues() {
    this.setState({ isLoading: true });
    axios.get(CONFIG.API_URL + 'Home/lookupdata/' + CONFIG.RegionId +'/'+ 0)
      .then(response => {
        var statedata = response.data.lookups.statesList;
        let stateLookup = statedata.filter(a => a.isterritory != 1);
      
        this.setState({ stateLookup, isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }
  //Duration For DropDown
  DurationsdropDown() {
    let countryCode = this.state.selectedCountry != '' ? this.state.selectedCountry : CONFIG.Country_Code;
    this.setState({ isLoading: true });
    console.log(countryCode, "countryCode");
    console.log(this.state.selectedCountry, "selcountryCode");
    axios.get(CONFIG.API_URL + 'home/lookupdefaultunitsizes/' + countryCode + '/' + CONFIG.RegionId)
      .then(response => {
        this.setState({
          unitTypes: response.data.units,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/page_expired" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 400) {
          if (err.response.data.Message === "Authentication failed") {
            const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
            this.props.history.push(from);
          }
        }
        else {
          alert(err.response.data);
        }
      });
  }


  //History Graph
  GetcurrentpricinganalysisHistory(graphData) {
    if (graphData) {
      let MarketName = [...new Set(graphData.map(data => data.MarketName))];
      let CurrentHistory = graphData.filter(data => data.UnitType === this.state.isDefaultUnit);
      this.setState({ AllCurrentHistory: graphData, MarketName, isLoading: false }, () => { this.HistoryGraphView(CurrentHistory) });
    }
  }

  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    var { isLoading1, showLoader, storeName, coverage, unitDurations, unitTypes, hisactivedur, overviewdetails, isDefaultUnit, isLoading, stateLookup, cityLookup, marketoverview } = this.state
    //For Widget desc text duration change
    let CurTxtDur = hisactivedur === 1 ? 'past 3 months' : hisactivedur === 2 ? 'past 6 months' : hisactivedur === 3 ? 'past 9 months' : hisactivedur === 4 ? 'past 1 Year' : hisactivedur === 5 ? 'past 2 Years' : hisactivedur === 6 ? 'past 3 Years' : '';

    const data = this.state.AllCurrentHistory;
    //Removing the existing svg when there is no data

    return (
      <div className="landing-page avgprice-track" style={{ paddingTop: '0px' }}>
        {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
        <div>

          <div className="clear"></div>
          <div className="tracker-banner">
            <div className="overlay-track">
              <Container>
                <h1>Daily Rate Tracker</h1>
                <p>See how rates in your city have changed over the last 7 days. <br />StorTrack provides detailed market rate insights to help owners<br />and operators react to changes faster.</p>
              </Container>
            </div>
          </div>
          <Container>
            <Row>
              <Col className="pt-4 rate-tracker">
                <div className="currentprice">
                  {isLoading ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                  {/* {this.state.isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''} */}
                  <div item="true" xs={12} >
                    <Row>
                      <Col md="12" id="cpanalysis" className="mb-3">
                        <div className="ms_charthead mb-2">
                          <h4 style={{ fontSize: '20px' }}>Select a State and City to view average rates over the last 7 days</h4>
                          <AvForm>
                            {this.props.isLoading ? '' :
                              <React.Fragment>
                                <Row className="select-top-type">
                                  <Col md="4">
                                    <label>State:</label>
                                    <AvField type="select" name='stateChange' onChange={(e) => this.citySelection(e)}>
                                      <option value='' >--Select State--</option>
                                      {stateLookup ? stateLookup.map((state) =>
                                        <option className={state.stateID === '' ? "optHead" : ''} key={state.stateID} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                      ) : ''}
                                    </AvField>
                                  </Col>
                                  <Col md="4">
                                    <label>City:</label>
                                    <AvField type="select" name='cityChange' onChange={(e) => this.cityBasedData(e)}>
                                      <option value='' >--Select City--</option>
                                      {cityLookup ? cityLookup.map((city) =>
                                        <option key={city.cityID} disabled={city.cityID === '' ? true : false} value={city.cityID}>{city.cityName}</option>
                                      ) : ''}
                                    </AvField>
                                  </Col>
                                  <Col md="4" className="last-child-only pl-0">
                                    <label>Unit Type:</label>
                                    <AvField type="select" onChange={this.onDurAndPriChange} value={this.state.isDefaultUnit} name='unitTypeChange' id='hisactivedur'>

                                      {unitTypes && unitTypes.length > 0 ? unitTypes.map((unit) =>
                                        <option value={unit.unitType}>{unit.unitType}</option>
                                      ) :
                                        <option value={'0'}>All Units</option>
                                      }
                                      {/* {unitTypes ? unitTypes.map(n => {
                                        return (
                                          <option value={n.unit}>

                                            {ReactHtmlParser(n.unitID)}
                                          </option>
                                        );
                                      }) : ''} */}
                                    </AvField>
                                  </Col>
                                </Row>
                              </React.Fragment>}
                          </AvForm>
                          {/* <p className="history-para">Below are historical graphs showing the average price over the {CurTxtDur} for each of the most common unit types.</p> */}

                          <div className="clear"></div>
                        </div>
                      </Col>
                      <Col sm="9">
                        {/* {data && data.length  >0  ? <Col md='12' className={"mainhisGraphdivs"}></Col>: <Col md='12'><p className = "loading-text" style= {{paddingTop: '20px', border: '1px solid rgba(224, 224, 224, 1)'}}><Col md='12' className={"mainhisGraphdivs"}></Col>Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.</p></Col>} */}
                        <div className="set-graph-border">
                          <div className="mainhisGraphdivs">
                            <p className="loading-text" style={{ paddingTop: '20px' }}>Please wait for the data to be loaded.</p>
                          </div>
                          {data && data.length > 0 ?
                            <div className="av-market-legend">
                              {/* {this.state.MarketName ? this.state.MarketName.map((market) => <span className="mrk-names">{market}</span>) : ''} */}
                            </div> : <div className={"mainhisGraphdivs"}></div>}
                        </div>
                        <div>
                          <p style={{ fontSize: '14px', marginTop: '20px' }}>This graph shows the average rate for the unit type selected. Move the slider to view the average rates for a specific day. National averages are displayed for comparison. </p>
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="right-side-txt-banner">
                          <p><a href={CONFIG.OPTZ_URL} className="opt-link">OPTIMIZE</a> offers detailed market and competitor rate information for any market. </p>
                          <a href={CONFIG.OPTZ_URL + "requesttrial"} className="req-trial">Request a Trial</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withMobileDialog()(Home); 