import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { HelpTextPopup } from './../HelpTextPopup/index';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';
import moment from 'moment';
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, ModalHeader, Modal as TrackModalPop, ModalFooter, ModalBody } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../utils/config';
import editicon from './../../Assets/Images/edit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowDown, faArrowUp, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-dropdown-tree-select/dist/styles.css'
import './MyMarket.css';
import AddMarketcmpopup from '../AddMarketcmpopup/AddMarketcmpopup.js';
import AddMarketcmpopupGmap from '../AddMarketcmpopup/AddMarketcmpopupGmap.js';
import {ActiveReports, CompReports} from '../../Views';
import { MarketProfile } from '../MarketProfile';
import { COMMON } from '../../utils/common';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

class MyMarket extends React.Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        window.scrollTo(0, 0);
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        window.scrollTo(0, 0);
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };


    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.tablepaggination}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
    MyMarket,
);

class CustomMyMarket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myMarketData: '',
            myMarketData1: [],
            page: 0,
            pageCount: 0,
            pageCountMDT: 0,
            rowsPerPage: 50,
            tooltipOpen: false,
            isLoading: false,
            isLoading1: false,
            open: false,
            open1: false,
            open2: false,
            setOpen: false,
            activeStep: 0,
            isDefaultValue: 1,
            radiusValue: 50,
            scale: 10,
            showComponent: 'radius',
            lookupZipcodeData: [],
            lookupCityData: [],
            selectedCityOption: null,
            selectedZipOption: null,
            modalMarketStoreId: '',
            marketStoreName: '',
            userStorId: null,
            trackStorId: null,
            storeModal: false,
            trackModalData: [],
            trackOptions: [],
            strOptions: [],
            strOptions1: [],
            trackedFields: [],
            trackedFields1: [],
            serachTypeval: null,
            competitorsData: [],
            tabvalue: 0,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            countlen: null,
            ch: null,
            modal: false,
            delUserStoreId: null,
            deleteConfirmModal: false,
            editConfirmModal: false,
            userType: '',
            infoData: '',
            snackOpen: false,
            snackMsg: '',
            downLoadReportModal: false,
            downloadStoreID: 0,
            excelProgress: '',
            editMarketName: '',
            marketnameeditmodal: false,
            isButtonDisabled: false,
            sortUpdate: false,
            sortUpdateDate: false,
            ShowMarketNameSort: false,
            ShowAddressSort: false,
            sortUpdateComp: true,
            sortUpdateAddress: true,
            sortUpdateProximity: true,
            ShowProxSort: true,
            ShowCompSort: false,
            data: false,
            addmarketpopup:false,
            enableSave: false,
        };
        this.child = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.trackModal = this.trackModal.bind(this);
        this.trackModalClose = this.trackModalClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClickOpenCR = this.handleClickOpenCR.bind(this);
        this.sendTrackApi = this.sendTrackApi.bind(this);
        this.upgradehandleClick = this.upgradehandleClick.bind(this);
        this.refreshHandler = this.refreshHandler.bind(this);
        this.deleteStore = this.deleteStore.bind(this);
        this.UserStrIdData = this.UserStrIdData.bind(this);
        this.delConModal = this.delConModal.bind(this);
        this.editConModal = this.editConModal.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.closeEditConfirmModal = this.closeEditConfirmModal.bind(this);
        this.editSubAlert = this.editSubAlert.bind(this);
        this.downLoadExcel = this.downLoadExcel.bind(this);
        this.submitRabitQueue = this.submitRabitQueue.bind(this);
        this.editMarketNameModal = this.editMarketNameModal.bind(this);
        this.cancelmarketEdit = this.cancelmarketEdit.bind(this);
        this.sortFeature = this.sortFeature.bind(this);
        this.sortPopUp = this.sortPopUp.bind(this);
    }
    // Below the function for sorting the market name and date
    sortFeature(getName){
        if(getName === "marketDateAsc" || getName === "marketDateDesc"){
            this.setState({ ShowMarketNameSort: false })
        }else if(getName === "marketNameAsc" || getName === "marketNameDesc"){
            this.setState({ ShowMarketNameSort: true })
        }
        var dataSet = this.state.myMarketData.result1;
        dataSet.sort(function (a, b) {
            var textA = (getName === "marketNameAsc" || getName === "marketNameDesc") ? a.storeName.toUpperCase() : (getName === "marketDateAsc" || getName === "marketDateDesc") ? a.createdDate.toUpperCase() : '';
            var textB = (getName === "marketNameAsc" || getName === "marketNameDesc") ? b.storeName.toUpperCase() : (getName === "marketDateAsc" || getName === "marketDateDesc") ? b.createdDate.toUpperCase() : '';
            if(getName === "marketNameAsc" || getName === "marketDateAsc"){
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0 ;
            }else if (getName === "marketNameDesc" || getName === "marketDateDesc") {
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0 ;
            }
        });
        this.setState({ sortUpdate: getName === "marketNameAsc" ? true : false, sortUpdateDate: getName === "marketDateAsc" ? true : false })
        // this.setState({ sortUpdate: getName === "marketNameAsc" ? true : false, sortUpdateDate: getName === "marketDateAsc" ? true : false })
        this.state.myMarketData.result1 = dataSet;
        this.forceUpdate();
    }


    UserStrIdData(strId) {

        localStorage.removeItem('StId');
        localStorage.setItem('StId', strId);
        var isElement = document.getElementById('r2');
        if (isElement == null) {
            document.getElementById('r1').click();
        }
        else {
            document.getElementById('r1').click();
            document.getElementById('r2').click();
        }
    }

    // onMapCreated(map) {
    //     map.setOptions({
    //         disableDefaultUI: true
    //     });
    // }

    // firstPolygon()
    // {
    //    let a=0;
    //     let zipcode = a!=0 ?'': '90001';  
    //   axios.get('http://www.mapquestapi.com/search/v2/radius?key=L05QPQHwpWvvHBogQnG1X56gjYLvLy01&hostedData=mqap.uspostalcodes&origin='+zipcode+'&ambiguities=ignore')
    //     .then(response => {
    //         console.log(response,"response");
    //     let centerpt=response.data.origin.latLng;
    //     console.log(centerpt);
    //     debugger
    //     let data = response.data.searchResults[0].shapePoints.exterior;
    //      let Arr2 = [],
    //      Arr3 = [],
    //      coordinates = [];
    //     for (var i = 0; i < data.length; i++) {
    //      if ((i + 2) % 2 == 0) {
    //       //Arr3.push({ "lat": data[i] });
    //       Arr3.push(data[i]);
    //      }
    //      else {
    //       //Arr2.push({ "lng": data[i] });
    //       Arr2.push(data[i]);
    //      }
    //     }
    //     var merged = [];
    //     for (i = 0; i < Arr2.length; i++) {
    //      merged.push(appendArrays(Arr2[i],Arr3[i]));
    //     }
    //     function appendArrays() {
    //      var temp = []
    //      for (var i = 0; i < arguments.length; i++) {
    //       temp.push(arguments[i]);
    //      }
    //      return temp;
    //     }//renderCoordinate(merge) {
    //      coordinates = [];
    //      let position = 0;
    //      merged.map((location) => {

    //       if (position % 10 === 0) {
    //        coordinates.push({"lat": location[1], "lng": location[0]});
    //       }
    //       position++
    //      });
    //     this.setState({coordinates1:coordinates,centerpt1:centerpt});
    //    })
    //    .catch((err) => {
    //         alert(err);

    // });

    //   }





    refreshHandler() {
        this.componentDidMount();
    }
    deleteStore(id) {
       
        this.setState({
            isButtonDisabled: true,isLoading : true
          });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'market/deletestore/' + id)

            .then(response => {
                if (response.status === 200) {
                    this.componentDidMount();
                    this.closeConfirmModal();
                    this.closeEditConfirmModal();
                    this.forceUpdate();
                    var isElement = document.getElementById('r2');
                    if (isElement == null) {
                        document.getElementById('r1').click();
                    }
                    else {
                        document.getElementById('r1').click();
                        document.getElementById('r2').click();
                    }
                    // alert('your store was deleted successfully');
                   
                    this.setState({
                        isButtonDisabled: false,isLoading : false
                      });
                }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if(err.response.status === 400){

                }
                else {
                    alert(err.response.data);
                }
            });
    }
    delConModal(e, id) {
        this.state.delUserStoreId = id;
        this.state.deleteConfirmModal = true;
    }
    editConModal(e, id) {
        this.state.delUserStoreId = id;
        this.state.editConfirmModal = true;
    }
    closeConfirmModal() {
        this.setState({
            deleteConfirmModal: false,
            // ShowMarketNameSort: false
        })
    }
    closeEditConfirmModal() {
        this.setState({
            editConfirmModal: false,
            // ShowMarketNameSort: false
        })
    }
    editMarketNameModal(e, id, name) {

        this.state.delUserStoreId = id;
        this.state.editMarketName = name;
        this.state.marketnameeditmodal = true;
        //document.getElementById("MarketName").focus();
    }
    
    cancelmarketEdit() {
        this.setState({ marketnameeditmodal: false, errortext: "" });
    }

    marketNameEditSubmit(event, errors, values) {
        if (errors.length === 0) {
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            let errortext = '';
            let data = {
                UserStoreId: this.state.delUserStoreId,
                MarketName: values.MarketName,
                RegionId: CONFIG.RegionId
            }
            axios.post(CONFIG.API_URL + 'market/edit', data)
                .then(response => {
                    if (response.status === 200) {
                        const already = response.data.result ? response.data.result.indexOf("Store name already exists!") : -1;
                        if (already > -1) {
                            errortext = 'This market name already exists! Please assign a different name.';
                            this.setState({
                                errortext
                            })
                        } else {

                            this.cancelmarketEdit();
                            this.setState({ ShowMarketNameSort: false })
                            this.componentDidMount();
                            var isElement = document.getElementById('r2');
                            if (isElement == null) {
                                document.getElementById('r1').click();
                            }
                            else {
                                document.getElementById('r1').click();
                                document.getElementById('r2').click();
                            }
                        }

                    }
                }).catch((err) => {
                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else if (err.response.status === 400) {
                        this.setState({ errortext : err.response.data.Message });
                    }
                    else {
                        alert(err.response.data);
                    }
                });
        }

    }
    trackModal(storeId, marketName) {
        this.setState({
            modalMarketStoreId: storeId,
            marketStoreName: marketName
        })
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'market/competitors/' + storeId + '/' + CONFIG.RegionId)
            .then(response => {
                // this.state.isLoading=true;
                const trackModalData = response.data.competitor.competitorsList;
                let strOptions = [];
                trackModalData.forEach(function (e) { strOptions.push(e.storeID + (e.isTracked ? '|1' : '|0')) });
                if(trackModalData.length > 0){
                trackModalData.sort(function (a, b) {
                    var textA =  a.proximity;
                    var textB =  b.proximity;
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0 ;
                    
                });}
                //console.log(strOptions);
                //console.log(trackModalData, "trackModalData");
                this.setState({
                    trackModalData, storeModal: true, strOptions
                })
            }).catch((err) => {
                // if (err.response.status === 401) {
                //     localStorage.removeItem('accessKey');
                //     const { from } = this.props.location.state || { from: { pathname: "/" } };
                //     this.props.history.push(from);
                // }
                // else {
                //     alert(err.response.data);
                // }
            });
        // this.setState(prevState => ({
        //     storeModal: !prevState.storeModal
        // }));
    }

    //   handleReset() {
    //     setActiveStep(0);
    //   }
    handleClickOpen(e, id) {
        this.setState({
            editConfirmModal: false,
            addmarketpopup:true
        })
        //console.log(id);
        this.child.current.ModalClickOpen(id);
    }
    handleClickOpenCR(e, id) {
        //console.log(id);
        this.child.current.ModalClickOpenCR(id);
    }

    toggle = targetName => {
        if (!this.state[targetName]) {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                ...this.state,
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };
    isToolTipOpen = targetName => {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };
    // toggle() {
    //     this.setState(prevState => ({
    //         dropdownOpen: !prevState.dropdownOpen
    //     }));
    // }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount });
    };
    handleChangePageMTD = (event, pageCountMDT) => {
        this.setState({ pageCountMDT });
    };
    handleChangeRowsPerPageMTD = event => {
        this.setState({ pageCountMDT: 0, 10: event.target.value });
    };
    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    upgradehandleClick(event, storeID, userStId) {
        let userStorId = userStId;
        let trackOptions = this.state.trackOptions;
        let strOptions = this.state.strOptions;
        let strOptions1 = this.state.strOptions1;
        let index;
        let index1;

        //console.log(event.target.checked)
        if (event.target.checked) {

            // this.state.disablecheckbox = true;
            index = strOptions.indexOf(+storeID + '|0');
            index1 = strOptions1.indexOf(+storeID + '|0');
            //console.log(upgradeOptions.indexOf(+storeID))
            if (index >= 0) {
                strOptions.splice(index, 1)
            }
            if (index1 >= 0) {
                strOptions1.splice(index1, 1)
            }
            trackOptions.push(+storeID);
            strOptions.push(+storeID + '|1');
            strOptions1.push(+storeID + '|1');
        }
        else if (!event.target.checked) {

            index = strOptions.indexOf(+storeID + '|1');
            index1 = strOptions1.indexOf(+storeID + '|1');
            //console.log(upgradeOptions.indexOf(+storeID))
            if (index >= 0) {
                strOptions.splice(index, 1)
            }
            if (index1 >= 0) {
                strOptions1.splice(index1, 1)
            }
            strOptions.push(+storeID + '|0');
            strOptions1.push(+storeID + '|0');
        }
        this.setState({
            strOptions, userStorId, strOptions1, enableSave: true,
        })
        //console.log(strOptions1);
    }

    componentDidMount() {
       
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else {
                Notification.requestPermission();
            }

            if (!localStorage.getItem('accessKey') || ((document.location.origin + '/') === document.location.href)) {
                var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
                (function () {
                    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                    s1.async = true;
                    s1.src = '//js.hs-scripts.com/4297816.js';//'https://embed.tawk.to/5bbe622408387933e5bacf9e/default';
                    s1.charset = 'UTF-8';
                    s1.setAttribute('crossorigin', '*');
                    s0.parentNode.insertBefore(s1, s0);
                })();
            }
       
        var element = document.getElementsByTagName("iframe")[1];
        if (element !== undefined) {
            element.parentNode.removeChild(element)
        } this.setState({
            isLoading: true,
            ShowMarketNameSort: false
        })
        let token = localStorage.getItem('accessKey');
        let instructionMsg = localStorage.getItem('instructionMsg');
        let displayMessage = localStorage.getItem('displayMessage');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        // axios.post(CONFIG.API_URL + 'Account/reputationstore')
        axios.post(CONFIG.API_URL + 'Account/reputationstore/' + CONFIG.RegionId)
            .then(res => {
                const myMarketData = res.data;
                let countlen = myMarketData.result1.length;
                let ch = myMarketData.result2.tempNoOfStores;
                let serachTypeval;
                let userType = localStorage.getItem('userType');
                if (myMarketData.result1.length > 0) {
                    serachTypeval = myMarketData.result1.find(function (searchType) {
                        return searchType;
                    });
                    serachTypeval = serachTypeval.searchType;
                }

                // To identify the Status report of Excel report is progress
                let excelProgress = myMarketData.result1.filter(function (data) {
                    return data.reportStatus === 1;
                });

                excelProgress = excelProgress.length > 0 ? excelProgress.length : '';
                // End of To identify the Status report of Excel report is progress

                this.setState({
                    isLoading: false, myMarketData, excelProgress
                    , serachTypeval, countlen, ch, instructionMsg, userType, displayMessage
                });
                // console.log(serachTypeval);
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
        // axios.post(CONFIG.API_URL + 'Account/getuserinfo')
        axios.post(CONFIG.API_URL + 'Account/getuserinfo/' + CONFIG.RegionId)
            .then(response => {
                let infoData = response.data;
                this.setState({ infoData });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

        // To Call ExcelReport status Api
        this.CallgetStatusReportApi = setInterval(
            () =>
            this.statusReport(),
            30000
        );
    }

    //To call the Function
    componentWillUnmount() {
        clearInterval(this.CallgetStatusReportApi);
    }

    // To Get Status of ExcelReport
    statusReport() {
        if (this.state.excelProgress) {
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            // axios.post(CONFIG.API_URL + 'Account/reputationstore')

            axios.post(CONFIG.API_URL + 'Account/reputationstore/' + CONFIG.RegionId)
                .then(res => {
                    const myMarketData = res.data;
                    let countlen = myMarketData.result1.length;
                    let ch = myMarketData.result2.tempNoOfStores;
                    let serachTypeval;
                    let userType = localStorage.getItem('userType');
                    if (myMarketData.result1.length > 0) {
                        serachTypeval = myMarketData.result1.find(function (searchType) {
                            return searchType;
                        });
                        serachTypeval = serachTypeval.searchType;
                    }

                    // To identify the Status report of Excel report is progress
                    let excelProgress = myMarketData.result1.filter(function (data) {
                        return data.reportStatus === 1;
                    });

                    excelProgress = excelProgress.length > 0 ? excelProgress.length : '';
                    // End of To identify the Status report of Excel report is progress

                    this.setState({
                        isLoading: false, myMarketData, excelProgress
                        , serachTypeval, countlen, ch, userType
                    });
                    // console.log(serachTypeval);
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else {
                        alert(err.response.data);
                    }
                });
        }

        // DONT FORGET TO IMPLEMENT

        //  // To identify the Status report of Excel report is progress
        //  let excelProgress = myMarketData.result1.filter(function(data){
        //     return data.reportStatus === 1;
        // });

        // excelProgress = excelProgress.length > 0 ? excelProgress.length : '';
        // // End of To identify the Status report of Excel report is progress

    }

    // Get Status Report Excel

    trackModalClose() {
        this.setState({
            storeModal: false,
            pageCount: 0,
            enableSave: false,
        })
    }

    sendTrackApi() {
        const data = {
            userStoreID: this.state.userStorId,
            id_list: this.state.strOptions1.toString(),
            RegionId: CONFIG.RegionId
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'market/track', data)
            .then(response => {
                // this.state.isLoading=true;
                const trackResponse = response.data;
                if (response.status === 200) {
                    this.setState({
                        storeModal: false,
                        pageCount: 0,
                        enableSave: false,
                    })
                    // let strOptions1 = this.state.strOptions1;
                    // strOptions1= [];
                    // let strOptions = this.state.strOptions;
                    // strOptions= [];
                    this.componentDidMount();
                }
                //console.log(trackResponse)
            }).catch((err) => {
                // if (err.response.status === 401) {
                //     localStorage.removeItem('accessKey');
                //     const { from } = this.props.location.state || { from: { pathname: "/" } };
                //     this.props.history.push(from);
                // }
                // else {
                //     alert(err.response.data);
                // }
            });
    }
    marketDashboard() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        //alert("Before you can view your competitors pricing and promotions, you must define the geographical coverage zone for this market")
    }

    editSubAlert() {
        this.setState({ snackOpen: true, snackMsg: 'Please add billing details before edit the subscription' })
    }
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    downLoadExcelPrompt(id) {
        this.state.downloadStoreID = id;
        this.state.downLoadReportModal = true;
    }

    // Call RabitQueue
    submitRabitQueue(storeId) {
        //this.setState({ isLoading: true, downLoadReportModal: false });
        axios.post(CONFIG.API_URL + 'market/reportqueue/' + storeId)
            .then((response) => {
                if (response.data === 'success') {
                    this.setState({ snackOpen: true, snackMsg: 'Your Competitor Report is being generated. This might take a couple minutes. When the report is ready it will download automatically when you click the report icon again.' }, () => { this.componentDidMount() });
                }
            }).catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    this.setState({ snackOpen: true, snackMsg: "Report generation is in progress, please try after sometime." });
                }
            });
    }

    //Excel file Download
    downLoadExcel(rep_id, filename) {
        this.setState({ isLoading: true, downLoadReportModal: false });
        axios({
            url: CONFIG.API_URL + 'market/downloadreport/' + rep_id + '/' + CONFIG.RegionId,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            //console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename.replace("<br />","") + ".xlsx");
            document.body.appendChild(link);
            link.click();
            this.setState({ isLoading: false });
        }).catch((err) => {
            this.setState({ isLoading: false });
            if (err.response.status === 401) {
                localStorage.removeItem('accessKey');
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
            }
            else {
                this.setState({ snackOpen: true, snackMsg: "Report generation is in progress, please try after sometime." });
            }
        });
    }

    // Activity and Comp report change
    // compAndActivityReportChange = (e, type) => {
    //     let data = this.state.myMarketData.result1;
    //     if (e.target.checked) {
    //         data.forEach(d => {
    //             if(type == 'ACTIVITY_REPORT' && d.userStoreID == e.target.value){
    //                 d.isActivityReport = 1
    //             } else if(type == 'COMP_REPORT' && d.userStoreID == e.target.value){
    //                 d.isCompReport = 1
    //             }
    //         })
    //         this.state.myMarketData.result1 = data;
    //     } else if (!e.target.checked) {
    //         data.forEach(d => {
    //             if(type == 'ACTIVITY_REPORT' && d.userStoreID == e.target.value){
    //                 d.isActivityReport = 0
    //             } else if(type == 'COMP_REPORT' && d.userStoreID == e.target.value){
    //                 d.isCompReport = 0
    //             }
    //         })
    //         this.state.myMarketData.result1 = data;
    //     }
    //     let reportStatus = data && data.filter((x) => x.userStoreID == e.target.value);
    //     reportStatus = reportStatus[0];
    //     let finalData = {
    //         storeId: e.target.value,
    //         isActivityReport: reportStatus.isActivityReport == null || reportStatus.isActivityReport == 0 ? 0 : 1,
    //         isCompReport: reportStatus.isCompReport == null || reportStatus.isCompReport == 0 ? 0 : 1
    //     }
    //     this.updateActiveComp(finalData);
    //     // this.forceUpdate();
    // }

    // //update activity comp report 
    // updateActiveComp(values){
    //     let data = {
    //          UserStoreId: values.storeId, 
    //          ActivityReport: values.isActivityReport, 
    //          CompReport: values.isCompReport,
    //     }
    //     let token = localStorage.getItem('accessKey');
    //     if (token) {
    //         axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    //     }
    //     axios.post(CONFIG.API_URL + 'market/updateactivitycompreport', data)
    //         .then(response => {
    //             this.forceUpdate();
    //         }).catch((err) => {
    //             if (err.response.status === 401) {
    //                 localStorage.removeItem('accessKey');
    //                 const { from } = this.props.location.state || { from: { pathname: "/" } };
    //                 this.props.history.push(from);
    //             }
    //             else {
    //                 alert(err.response.data);
    //             }
    //         });
    // }

    // Sorting competitor and address in modal popup
    sortPopUp(getName){
        if(getName === "compeAsc" || getName === "compDesc"){
            this.setState({ ShowCompSort: true, ShowAddressSort: false, ShowProxSort: false });
        }else if(getName === "addressAsc" || getName === "addressDesc"){
            this.setState({ ShowAddressSort: true, ShowProxSort: false, ShowCompSort: false });
        }else if(getName === "proximityAsc" || getName === "proximityDesc"){
            this.setState({ ShowAddressSort: false, ShowProxSort: true,  ShowCompSort: false });
        }
        var popUpData = this.state.trackModalData;        
        popUpData.sort(function (a, b) {
            var textA = (getName === "addressAsc" || getName === "addressDesc") ? a.storeAddress.toUpperCase() : (getName === "compAsc" || getName === "compDesc") ? a.storeName.toUpperCase() : (getName === "proximityAsc" || getName === "proximityDesc") ? a.proximity : '';
            var textB = (getName === "addressAsc" || getName === "addressDesc") ? b.storeAddress.toUpperCase() : (getName === "compAsc" || getName === "compDesc") ? b.storeName.toUpperCase() : (getName === "proximityAsc" || getName === "proximityDesc") ? b.proximity : '';
            if(getName === "addressAsc" || getName === "compAsc" || getName === "proximityAsc"){
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0 ;
            }else if (getName === "addressDesc" || getName === "compDesc" || getName === "proximityDesc") {
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0 ;
            }
        });
        this.setState({ sortUpdateAddress: getName === "addressAsc" ? true : false, sortUpdateComp: getName === "compAsc" ? true : false, sortUpdateProximity: getName === "proximityAsc" ? true : false });
         this.state.trackModalData = popUpData;
        this.forceUpdate();
    }

    render() {
        document.title = 'StorTrack: Self-storage market pricing data';
        const { selectedCityOption, selectedZipOption, trackedFields, addInputData, errortext, tabvalue, lookupZipcodeData, lookupCityData, showComponent, addMarketData, stateData, countryData, rowsPerPage, page, pageCount, pageCountMDT, myMarketData, isLoading, isLoading1, isDefaultValue, trackModalData, strOptions, selectedPlace, activeMarker, showingInfoWindow, address, sortUpdate, sortUpdateDate, ShowMarketNameSort, sortUpdateProximity, ShowProxSort, ShowCompSort } = this.state;
        const { open, open1, open2, activeStep, radiusValue, competitorsData, userType, marketnameeditmodal, editMarketName } = this.state;
        const { coordinates1, coordinates2, coordinates3, coordinates4, countlen, displayMessage, ch, instructionMsg, infoData, snackMsg, snackOpen, sortUpdateAddress, sortUpdateComp, ShowAddressSort, enableSave } = this.state;
        const defaultProps = {
            helpTitle: this.state.storeModal === true ? 'Stores in the Market' : 'My Markets',
            helpContent: this.state.storeModal === true ? '<p>These are the stores found within the coverage area you selected.</p><p>Not your competition? We get it, maybe the guys across the street are no match to the value you bring to your customers. To remove any of stores/competitors from the market insights you see throughout the site, unselect the box under Track next to the store and click Save.</p>' : "<p>This page shows all the Markets you have set up, if any. If you haven't yet (what are you waiting for?!), click on the +Add Market icon and follow the steps to set up your first Market.</p><h6>Date Added</h6><p>The date the Market you set up this Market</p><h6>Market Name</h6><p>Click on any Market name to view that Market's detailed insights</p><h6>Address</h6><p>The address for this Market</p><h6>Coverage</h6><p>This is the coverage/competitor area you selected for this Market. Click on this to change the coverage at any time</p><h6>Stores</h6><p>To view all the competing stores for this Market, click on this</p><h6>Go to Dashboard</h6><p>Click on the icon to go to the detailed Market insights</p><h6>Download Competitor Report</h6><p>Click on the icon to download an Excel spreadsheet with a detailed report comparing your store's rates against the rates of the competitor stores in this market. You will see the rates for all your unit types and how they compare against the market average and matching units at competitor stores.</p><h6>Edit Market Info</h6><p>Edit the address for this Market or delete it (however, keep in mind making changes to the address for a Market can result in additional fees to your subscription - contact us for additional information)</p>"
        }

        //'<a href="#" className="download-xcel">HideCompetitor</a>'

        return (
            <div className="my-markets-page">
                <div id="r2" onClick={this.refreshHandler}></div>
                {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}

                {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                {/* <Paper className="market-name-block" item xs={12}>
                    <div className="market-div">
                        <h4 className="market-name">Market name </h4>
                        <p className="market-address">1811, Adrin, CA, 2000</p>
                        <p className="market-coverage">Coverage: 5 Mile Radius</p>
                    </div>
                </Paper> */}
                <Breadcrumbs separator="›" aria-label="Breadcrumb" className="breadcrumb-float-left breadcrumb-top">
                   
                    
                </Breadcrumbs>

                <div className="clearfix"> </div>
                {/* {localStorage.getItem('accessKey') && localStorage.getItem('userType') === 2 ? 
                    <MarketProfile className="list-item-text" isShowModal={this.state.showModal} />
                : ''} */}
                <Paper item xs={12}>
                    <div className="market-div">
                        {countlen > 0 || displayMessage == 1 ?
                            <Row>
                                <Col xs="8"><h2 className="mb-2">My Markets {countlen > 0 || this.state.ch > 0 ? <span>You have set up {myMarketData.result1.length} out of {this.state.ch} markets.</span> : ''} <span style={{ marginLeft: '0px', opacity: '1' }} className="only-market-page"> <HelpTextPopup parm={defaultProps} /></span>  {countlen == ch ? <span class="pb-2 pt-1 please-upgrade">Please upgrade your subscription to add more markets.</span> : ''}</h2> </Col>

                                <Col xs="4" className="right-align">
                                    {countlen >= 0 && countlen !== ch ?

                                        countlen == 0 ? '' :
                                            <Button disabled={countlen >= ch ? true : false} onClick={(e) => this.handleClickOpen(e, 0)} className="add-market-btn"> <FontAwesomeIcon icon={faPlus} /> Add Market</Button>
                                        :
                                        countlen > 0 && countlen == ch && infoData && infoData.info.userInfo.billingAddress != null && localStorage.getItem('userManual') !== '1' ? <Link to="/subscription-details/" className=" btn btn-secondary btn-save btn-save-mark update-markets"> <FontAwesomeIcon icon={faPlus} /> Update Subscription</Link> : localStorage.getItem('userManual') !== '1' ? <Link to="#" onClick={this.editSubAlert} className=" btn btn-secondary btn-save btn-save-mark update-markets"> <FontAwesomeIcon icon={faPlus} /> Update Subscription</Link> : ''}
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col xs="12">
                                    <h2>My Markets</h2>
                                </Col>
                                <Col md="12">
                                    <span className="lgs_addmark">Let's get started! Set up your first market by clicking +Add Market below. </span>
                                </Col>
                                <Col md="12">
                                    {this.state.ch > 1 && displayMessage !== null && countlen >= 1 ? <span className="lgs_addmark">You have set up {myMarketData.result1.length} out of {this.state.ch} markets</span> : ''}
                                </Col>
                            </Row>
                        }
                        {/* <Grid xs={7}> */}

                        {/* </Grid> */}
                        {/* <Grid xs={5}>
                                <Button>Add Market</Button>
                                <Button>SiteFinder</Button>
                            </Grid> */}
                        <div className="overflow-scroll-table br-0">
                            <Paper className="bx-sh-0">
                                <Table className="table table-bordered custom-table">
                                    <TableHead>
                                        <TableRow className="table-header">
                                            <TableCell className={ShowMarketNameSort === false ? 'sort-active' : ''}><span className="hover-hand" onClick={sortUpdateDate === true ? ()=> this.sortFeature('marketDateDesc') : ()=> this.sortFeature('marketDateAsc') }>Date Added {sortUpdateDate ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span></TableCell>
                                            <TableCell className={ShowMarketNameSort === true ? 'sort-active' : ''}><span className="hover-hand" onClick={sortUpdate === true ? ()=> this.sortFeature('marketNameDesc') : ()=> this.sortFeature('marketNameAsc') }>Market Name {sortUpdate ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span></TableCell>
                                            {/* <TableCell><span className="hover-hand" onClick={sortUpdate === true ? ()=> this.sortFeature('marketNameDesc') : ()=> this.sortFeature('marketNameAsc') }>Market Name {ShowMarketNameSort ? <span>{sortUpdate ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span> : ''}</span></TableCell> */}
                                            <TableCell>Address</TableCell>
                                            <TableCell>Coverage</TableCell>
                                            <TableCell>Stores</TableCell>
                                            <Tooltip title="Schedule Market Activity Reports to be emailed for each of your markets.Click on the link to edit the report's schedule or recipients." placement="top" arrow>
                                            <TableCell className="text-left" id="acti">ACTIVITY REPORTS</TableCell>
                                            </Tooltip>
                                            <Tooltip title="Schedule Competitor Reports for each of your markets.Click on the link to edit the report's schedule or recipients." placement="top" arrow>
                                            <TableCell className="text-left" id="comp">COMPETITOR REPORTS</TableCell>
                                            </Tooltip>
                                            {/* <TableCell align="center">Go To Dashboard</TableCell>
                                            <TableCell align="center">Download Comp Report</TableCell> */}
                                            <TableCell className="text-right">Edit Market Info</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {countlen > 0 ? myMarketData.result1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) =>
                                            <TableRow key={category.userStoreID} className="table-body">
                                                <TableCell scope="row">
                                                    {/* {moment(category.createdDate).format('L')} */}
                                                    {moment(category.createdDate).format('DD-MMM-YYYY')}
                                                </TableCell>
                                                <TableCell className="market-name-color market-name-m-w">{category.zoneCoverage !== null && category.zoneCoverage ? <Link onClick={() => this.UserStrIdData(category.userStoreID)} to={{ pathname: `/market-dashboard/${category.userStoreID}` }}>{category.storeName}</Link> : <Link onClick={this.marketDashboard.bind(this)} >{category.storeName}</Link>}</TableCell>
                                                <TableCell dangerouslySetInnerHTML={{ __html: category.address }}></TableCell>
                                                <TableCell className="opacity-one"><span className="hyper-ref" title="Change coverage zone" onClick={(e) => this.handleClickOpenCR(e, category.userStoreID)}>{category.zoneCoverage !== null && category.zoneCoverage ? category.zoneCoverage.replace(/,/g, ', ') : 'Define Coverage'}</span></TableCell>
                                                {category.zoneCoverage !== null && category.zoneCoverage ?
                                                    <TableCell className="opacity-one"><span className="hyper-ref" onClick={() => this.trackModal(category.userStoreID, category.storeName)}><span className="store-count-list">{category.compsCount}</span><img src={editicon} className="edit-stores" title="View Competitors" alt="" /></span></TableCell>
                                                    :
                                                    <TableCell >N/A</TableCell>
                                                }
                                                <TableCell className="middle-align opacity-one">
                                                    {category.zoneCoverage !== null && category.zoneCoverage ?
                                                        <React.Fragment>
                                                            <ActiveReports isCompStatus={category.isCompReport} isGreyOut={category.isActivityReport} isActiveOpen={this.state.showModal} storeId={category.userStoreID} compRefresh={() => this.componentDidMount()} isFrom="MY_MARKET" />
                                                        </React.Fragment> :
                                                        <p class="setup-text not-sch" onClick={this.marketDashboard.bind(this)}> Set up</p>
                                                    }
                                                    {/* {category.isActivityReport == 1 ? <Button size="sm" className="setup-btn btn-enb"><FontAwesomeIcon icon={faCheckCircle} /> Set-up</Button> : <Button className="setup-btn btn-dis" size="sm"><FontAwesomeIcon icon={faTimesCircle} /> Setup </Button>} */}
                                                    {/* <FormControlLabel
                                                        control={
                                                            <Checkbox  className="move-ch-top track-check-gl"
                                                                checked={category.isActivityReport == 1}
                                                                onClick={(e) => this.compAndActivityReportChange(e, 'ACTIVITY_REPORT')}
                                                                value={category.userStoreID}
                                                                name={} 
                                                            />
                                                        }
                                                    /> */}
                                                {/* {category.zoneCoverage !== null && category.zoneCoverage ? <Link onClick={() => this.UserStrIdData(category.userStoreID)} to={{ pathname: `/market-dashboard/${category.userStoreID}` }} className="go-to-dash"></Link> : <Link onClick={this.marketDashboard.bind(this)} className="go-to-dash"></Link>} */}
                                                </TableCell>
                                                <TableCell className="middle-align opacity-one">
                                                    {category.zoneCoverage !== null && category.zoneCoverage ?
                                                        <React.Fragment>
                                                            <CompReports isActiveStatus={category.isActivityReport} isGreyOut={category.isCompReport} isActiveOpen={this.state.showModal} storeId={category.userStoreID} compRefresh={() => this.componentDidMount()} isFrom="MY_MARKET" />
                                                            {/* {category.competitorStoreID != null && category.competitorStoreID != 0 ?
                                                                <CompReports isActiveStatus={category.isActivityReport} isGreyOut={category.isCompReport} isActiveOpen={this.state.showModal} storeId={category.userStoreID} compRefresh={() => this.componentDidMount()} isFrom="MY_MARKET" />
                                                                : '-'} */}
                                                        </React.Fragment> :
                                                        <p class="setup-text not-sch" onClick={this.marketDashboard.bind(this)}> Set up</p>
                                                    }
                                                {/* {category.isCompReport == 1 ? <Button size="sm" className="setup-btn btn-enb"><FontAwesomeIcon icon={faCheckCircle} /> Set-up</Button> : <Button className="setup-btn btn-dis" size="sm"><FontAwesomeIcon icon={faTimesCircle} /> Setup</Button>} */}
                                                    {/* <FormControlLabel
                                                        control={
                                                            <Checkbox className="move-ch-top track-check-gl"
                                                                checked={category.isCompReport == 1}
                                                                onClick={(e) => this.compAndActivityReportChange(e, 'COMP_REPORT')}
                                                                value={category.userStoreID}
                                                                name={} 
                                                            />
                                                        }
                                                    /> */}
                                                {/* {category.zoneCoverage !== null && category.zoneCoverage ? <Link onClick={() => this.UserStrIdData(category.userStoreID)} to={{ pathname: `/market-dashboard/${category.userStoreID}` }} className="go-to-dash"></Link> : <Link onClick={this.marketDashboard.bind(this)} className="go-to-dash"></Link>} */}
                                                </TableCell>
                                                {/* <TableCell className="middle-align"><Link className="go-to-dash"></Link></TableCell> */}
                                                {/* <TableCell className="middle-align">
                                                    {
                                                        // Checking Competitor Available (category.competitorStoreID) and Competitor belongs to "US"(category.displayReport)
                                                        category.competitorStoreID !== null && category.competitorStoreID !== 0 && category.compsCount !== null && category.compsCount > 0
                                                            ?
                                                            category.zoneCoverage === null // Request to download CompReport if category.zoneCoverage is available
                                                                ?
                                                                <span title='Request For Report' onClick={this.marketDashboard.bind(this)} className="chk-aval-xcel"></span>
                                                                :
                                                                category.reportStatus === null || category.reportStatus === 0 // Request to download CompReport
                                                                    ?
                                                                    <span title='Request For Report' onClick={() => this.submitRabitQueue(category.userStoreID)} className="chk-aval-xcel"></span>
                                                                    :
                                                                    category.reportStatus === 1 // Compreport in progress
                                                                        ?
                                                                        <span title='Report generation is in progress.' className="loader-xcel"></span>
                                                                        :
                                                                        category.reportStatus === 3 // Able to download Compreport
                                                                            ?
                                                                            <span title='Download Here' onClick={() => this.downLoadExcel(category.reportID, category.address.replace("<br />",""))} className="available-xcel"></span>
                                                                            :
                                                                            category.reportStatus === 4 // Unable to Download Compreport
                                                                                ?
                                                                                <span title='Unable to generate report for this market.' className="unavailable-xcel"></span>
                                                                                :
                                                                                category.reportStatus === 5 // Un Available price
                                                                                    ?
                                                                                    <span title='Pricing data is not available for your store and comp report cannot be generated for this market.' className="unavailable-xcel"></span>
                                                                                    :
                                                                                    ''
                                                            :
                                                            ''}
                                                </TableCell> */}
                                                {/* onClick={()=>this.downLoadExcel(category.userStoreID)} */}
                                                <TableCell className="middle-align pl-5">

                                                    <Dropdown
                                                        isOpen={this.isToolTipOpen(`btn-${category.userStoreID}`)}
                                                        target={`btn-${category.userStoreID}`}
                                                        //isOpen={this.state.dropdownOpen}
                                                        toggle={() => this.toggle(`btn-${category.userStoreID}`)}>
                                                        <DropdownToggle>
                                                            ...
                                                        </DropdownToggle>
                                                        {userType === "2" ? '' :
                                                            <DropdownMenu right>
                                                                <DropdownItem onClick={(e) => this.editMarketNameModal(e, category.userStoreID, category.storeName)}>Edit Market Name</DropdownItem>
                                                                <DropdownItem onClick={(e) => this.editConModal(e, category.userStoreID)}>Edit Address</DropdownItem>
                                                                <DropdownItem disabled={this.state.isButtonDisabled} onClick={(e) => this.delConModal(e, category.userStoreID)}>Delete</DropdownItem>                                                                
                                                                {/* <DropdownItem onClick={(e) => this.deleteStore(e, category.userStoreID)}>Delete</DropdownItem> */}
                                                            </DropdownMenu>
                                                        }
                                                    </Dropdown>
                                                </TableCell>
                                            </TableRow>
                                        ) :
                                            <TableRow>
                                                <TableCell colSpan="5">To view a market's detailed insights, first click +Add Market to enter the market's address.</TableCell>
                                                <TableCell colSpan="3" className="text-right">
                                                    <Button disabled={countlen >= ch ? true : false} onClick={(e) => this.handleClickOpen(e, 0)} className="add-market-btn top-bot-spc"> <FontAwesomeIcon icon={faPlus} /> Add Market</Button>
                                                </TableCell>
                                            </TableRow>
                                        }

                                    </TableBody>
                                    {countlen > 0 ?
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[2]}
                                                    colSpan={8}
                                                    count={myMarketData ? myMarketData.result1.length : 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={MyMarketWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter> : ''}
                                </Table>
                            </Paper>
                        </div>
                        {CONFIG.IsMapbox ?
                        <AddMarketcmpopup parm={this.props} parentMethod={this.refreshHandler} ref={this.child} addmarket={this.state.addmarketpopup} />
                        :
                        <AddMarketcmpopupGmap parm={this.props} parentMethod={this.refreshHandler} ref={this.child} />}

                    </div>


                    {/* <Button onClick={this.errorHandleOpen.bind(this)}>Open Modal</Button>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={open1}
                        onClose={this.errorHandleOpen}
                    >
                        <div>
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className="closeModal"
                                onClick={this.errorHandleOpen.bind(this)}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography variant="h6" id="modal-title">
                                Address Not Fount
          </Typography>
                            <Typography variant="subtitle1" id="simple-modal-description">
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>

                        </div>
                    </Modal> */}
                </Paper>
                {/* Modal for track untrack stores */}
                <div>
                    <TrackModalPop isOpen={this.state.storeModal} toggle={() => this.trackModal(this.state.modalMarketStoreId, this.state.marketStoreName)} className="my-markets-popup">
                        <ModalHeader toggle={this.trackModalClose}>{this.state.marketStoreName} <HelpTextPopup parm={defaultProps} /></ModalHeader>
                        <ModalBody>
                            <Table className="table table-bordered custom-table">
                                <TableHead>
                                    <TableRow className="table-header">
                                    <TableCell className={ShowCompSort === true ? 'sort-active' : ''}><span className="hover-hand" onClick={sortUpdateComp === true ? ()=> this.sortPopUp('compDesc') : ()=> this.sortPopUp('compAsc') }>Competitor Name {sortUpdateComp ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span></TableCell>
                                            <TableCell className={ShowAddressSort === true ? 'sort-active' : ''}><span className="hover-hand" onClick={sortUpdateAddress === true ? ()=> this.sortPopUp('addressDesc') : ()=> this.sortPopUp('addressAsc') }>Address {sortUpdateAddress ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span></TableCell>
                                            <TableCell className={ShowProxSort === true ? 'sort-active' : ''}><span className="hover-hand" onClick={sortUpdateProximity === true ? ()=> this.sortPopUp('proximityDesc') : ()=> this.sortPopUp('proximityAsc') }>Proximity {sortUpdateProximity ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span></TableCell>
                                        {/* <TableCell>Competitor Name</TableCell>
                                        <TableCell>Address</TableCell> */}
                                        <TableCell>Track</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trackModalData ? trackModalData.slice(pageCount * 10, pageCount * 10 + 10).map((trackData) =>
                                        <TableRow key={trackData.rec_num}>
                                            <TableCell>{trackData.storeName}</TableCell>
                                            <TableCell>{trackData.storeAddress}</TableCell>
                                            <TableCell>{trackData.proximity == null ? "--" : trackData.proximity == 0 ? "" : trackData.proximity + ((CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? " km" : " mi")}</TableCell>                                            
                                            <TableCell padding="checkbox" className="remove-padding">
                                                <Checkbox key={trackData.rec_num} name="deleteCheck"
                                                    className="track-check-gl"
                                                    // checked={trackData.isTracked === true}
                                                    checked={(trackData.storeID + '|1') === strOptions.find((e) => e === (trackData.storeID + '|1'))}
                                                    onClick={event => this.upgradehandleClick(event, trackData.storeID, trackData.userStoreID)} />
                                            </TableCell>
                                        </TableRow>
                                    ) : ''}
                                    {trackModalData.length == 0 ?
                                        <TableRow>
                                            <TableCell colSpan="3" className="text-center no-data">There are no competitors available in this market</TableCell>
                                        </TableRow>
                                        : ''}
                                </TableBody>
                                {trackModalData.length !== 0 ?
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={4} className="text-right">
                                                <Button disabled={!enableSave} onClick={() => this.sendTrackApi()} variant="contained" color="primary" className="save-btn-grid">Save</Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[1]}
                                                colSpan={4}
                                                count={trackModalData ? trackModalData.length : 0}
                                                rowsPerPage={10}
                                                page={pageCount}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                onChangePage={this.handleChangePageTrack}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                                                ActionsComponent={MyMarketWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                    : ''}
                            </Table>
                        </ModalBody>
                    </TrackModalPop>
                </div>
                <div>

                    <TrackModalPop isOpen={this.state.deleteConfirmModal} size="sm" centered={true} className="del-this-market">
                        <ModalBody>
                            Are you sure you want to delete this market?
                    </ModalBody>
                        <ModalFooter className="want-to-delete">
                            <Button className="edit_cancel" onClick={() => this.closeConfirmModal()}>No</Button>
                            <Button className="success-btn" onClick={() => this.deleteStore(this.state.delUserStoreId)}>Yes</Button>
                        </ModalFooter>
                    </TrackModalPop>
                    <TrackModalPop isOpen={this.state.editConfirmModal} size="sm" centered={true} className="del-this-market">
                        <ModalBody>
                            Changing the address for this market may result in additional fees to your subscription. Click Continue to edit the address.
                    </ModalBody>
                        <ModalFooter className="want-to-delete">
                            <Button className="edit_cancel" onClick={() => this.closeEditConfirmModal()}>No</Button>
                            <Button className="success-btn" onClick={(e) => this.handleClickOpen(e, this.state.delUserStoreId)}>Continue</Button>
                        </ModalFooter>
                    </TrackModalPop>
                    <TrackModalPop isOpen={this.state.downLoadReportModal} size="sm" centered={true} className="del-this-market">
                        <ModalBody>
                            Your competitor report is being processed and will be automatically downloaded when ready.
                    </ModalBody>
                        <ModalFooter className="want-to-delete">
                            <Button className="edit_cancel" onClick={() => this.setState({ downLoadReportModal: false })}>Cancel</Button>
                            <Button className="success-btn" onClick={() => this.downLoadExcel(this.state.downloadStoreID, this.state.address)}>OK</Button>
                        </ModalFooter>
                    </TrackModalPop>
                </div>
                <div>
                    <TrackModalPop isOpen={this.state.modal} >

                        <ModalBody>
                            Before you can view your competitors pricing and promotions, you must define the geographical coverage zone for this market.          </ModalBody>
                        <ModalFooter className="close-main-btn">
                            <Button color="secondary" onClick={this.marketDashboard.bind(this)}>Close</Button>
                        </ModalFooter>
                    </TrackModalPop>
                </div>
                <div>

                    <TrackModalPop isOpen={this.state.marketnameeditmodal}  >
                        <ModalHeader className='customized-modal-header'>
                        Update Market Name
                        </ModalHeader>
                        <ModalBody className="name-of-mrks">
                            <AvForm onSubmit={this.marketNameEditSubmit.bind(this)}>
                                <Col md="10" className="name-f-market pl-0">
                                    <AvField name="MarketName" type="text" maxLength={100} errorMessage="Market Name is required" validate={{
                                        required: { value: true }
                                    }} value={editMarketName || ''} id="MarketName" />
                                    <div className="add-market-error">{errortext ? errortext : ''}</div>
                                </Col>
                                <button type="submit" variant="contained" color="primary" className="next-btn save-t">Save</button>
                                <Button type="button" onClick={this.cancelmarketEdit} className="go-btn close-step-first">
                                    Cancel
                                </Button>
                            </AvForm>
                        </ModalBody>
                    </TrackModalPop>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackOpen}
                    className="snackbar bg-color-primary custom-snackbar"
                    maxsnack={3}
                    autoHideDuration={30000}
                    onClose={this.snackHandleclose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

CustomMyMarket.propTypes = {
    // classes: PropTypes.object.isRequired,
};

export default CustomMyMarket;