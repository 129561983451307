import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import './CommonSqFtInfoPopup.css';
class CommonSqFtInfoPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        infoModal: false
    }

    this.handleToggleDialog = this.handleToggleDialog.bind(this);
  }

  handleToggleDialog() {
    this.setState({
        infoModal: !this.state.infoModal
    })
  }


  render() {
    const { infoModal } = this.state;

    return (
      <React.Fragment>

        {this.props.infoFrom == 'ALL_STORES_LIST' ?
          <p className="sqft-info-link-modal-open" onClick={this.handleToggleDialog}>Learn about how Rentable Square Footage is calculated.</p>
        :
          this.props.infoFrom == 'AM_POP_TSFT' || this.props.infoFrom == 'AM_POP_RSFT' ?
            <span className="info-icon" onClick={this.handleToggleDialog}><InfoRoundedIcon aria-label="Help" fontSize="small" /></span>
        :
          ''
        }
       
        {/* </IconButton> */}
        <Dialog
            open={infoModal}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
            className="sqft-info-modal-wrapper"
            maxWidth="xs"
        >
            <DialogTitle className="close">
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleToggleDialog}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className="sqft-content">
                {this.props.infoFrom == 'ALL_STORES_LIST' || this.props.infoFrom == 'AM_POP_RSFT' ?
                <div>
                  <h2>How is Rentable Square Feet (RSF) calculated for each property?</h2>
                  <ul>
                    <li>RSF is 95% of TSF if the property has 1 floor</li>
                    <li>RSF is 85% of TSF if the property has 2 floors</li>
                    <li>RSF is 75% of TSF if the property has 3 or more floors</li>
                    <li>RSF is 95% of TSF if the property has an unknown number of floors</li>
                  </ul>
                </div> : ''}
                {this.props.infoFrom == 'AM_POP_TSFT' ?
                <div>
                  <p><b>Total Square Feet</b> Values are obtained from public sources, including city and county property records. When this information is unavailable, StorTrack uses mapping tools or internal models to estimate the size of the property.</p>
                </div> : ''}
                {this.props.infoFrom == 'AM_POP_TSFT' || this.props.infoFrom == 'AM_POP_RSFT' ?
                <div>
                  <p>Property Square Footage is available in the All Stores List page.</p>
                </div> : ''}
            </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default CommonSqFtInfoPopup;