import React from "react";
import "./MarketInventoryAnalysis.css";
import Chart from "react-apexcharts";
import * as d3 from "d3";
import { CONFIG } from "../../utils/config";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";
import { MarketProfile } from "../MarketProfile";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table as TableBoots,
} from "reactstrap";
import greenimage from "./../../Assets/Images/green.png";
import redimages from "./../../Assets/Images/red.png";
import downarrows from "./../../Assets/Images/down-arrow.png";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import resetPng from '../../Assets/Images/reset.png';

export default class MarketInventoryAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      options: {
        chart: {
          id: 'apexchart-example',
          toolbar: {
            show: false
          }
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
        },
        axisTicks: {
          show: false
        }
        },
        grid: { show: false },
        yaxis: { show: false },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [{
        name: 'Store offered this Unit Type on',
        data: []
      }],
      unitInventoryAvailablity: "",
      isLoading: false,
      unitDurations: "",
      unitLevelInventory: "",
      isDefaultUnit: "3",
      storeId: "",
      defaultSelected: 3,
      isModel: false,
      date: "",
      searchId: "",
      isAddedCount: "",
      isRemovedCount: "",
      isNoGraphData: false,
      snackOpen: false,
      snackMSg: "",
      snackClr: "",
      date_value:[],
      yearRange : 3
    };

    // this.props;
    this.state.storeId = this.props.storeId;
    this.state.searchId = this.props.searchId;
    this.openPopup = this.openPopup.bind(this);
    this.getParams = this.getParams.bind(this);
  }

  getParams(name, sid) {
    this.child.current.toggleModal(name, sid);
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      defaultSelected: 3,
      isDefaultUnit: "3",
    });
    axios.get(CONFIG.API_URL + "unit/unitdurations").then((response) => {
      let unitDurations = response.data.data.lookupData;

      this.setState({
        unitDurations,
      });
    });
    localStorage.setItem("StId", this.state.storeId);
    const inputParam = {
      UserStoreID: this.state.storeId,
      SearchID: this.state.searchId,
      Duration: this.state.isDefaultUnit,
      RegionID: CONFIG.RegionId,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.commonInitLoad(inputParam);
  }

  componentWillReceiveProps(nextProps) {
    // this.myFormRef && this.myFormRef.reset();

    if (nextProps.searchId != this.props.searchId) {
      this.setState(
        {
          isLoading: true,
          defaultSelected: "",
          searchId: nextProps.searchId,
          storeId: nextProps.storeId,
        },
        () => {
          this.componentDidMount();
        }
      );
      // window.location.reload();
      //  const { from } = { from: { pathname: "/market-dashboard/${nextProps.storeId}/view-details/${nextProps.searchId}/${nextProps.storeCount}" } };
      // this.props.history.push(from);
    }
  }

  commonInitLoad(inputParam) {
    this.setState({
      isLoading: true,
    });

    axios
      .post(CONFIG.API_URL + "unit/unitdatahistoryinventory", inputParam)
      .then((response) => {
        const unitLevelInventory = response.data.data.unitLevelInventory;
        const unitInventoryAvailablity =
          response.data.data.unitInventoryAvailablity;
        const isAddedCount = unitInventoryAvailablity.filter(
          (e) => e.isAdded === 1
        );
        const isRemovedCount = unitInventoryAvailablity.filter(
          (e) => e.isAdded === 0
        );
        // unitInventoryAvailablity.filter((e) => e.isAdded === 1).forEach(function (e) { isAddedCount.push(e.isAdded) });
        // unitInventoryAvailablity.filter((e) => e.isAdded === 0).forEach(function (e) { isRemovedCount.push(e.isAdded) });
        const date = response.data.data.datePrice.dateDetail;

        this.setState({
          unitInventoryAvailablity,
          unitLevelInventory,
          date,
          isAddedCount,
          isRemovedCount,
          isLoading: false,
        });
        if (this.state.unitLevelInventory.length > 0) {
          this.setState({
            isNoGraphData: false,
          });
          this.initialLoad(unitLevelInventory);
        } else {
          this.setState({
            isNoGraphData: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  initialLoad(res) {
    var data = res;
    let yearRange_bool;
    const {yearRange} = this.state
    
    if(this.state.yearRange < 4){
      yearRange_bool = true
    }else{
      yearRange_bool = false
    }

  

   
    const {date_value} = this.state;
   
    this.setState(prev => { return { ...prev , date_value } });
    let dataSeries = data.map(val => { return { x: val.datePrice, y: isNaN(val.availableCount) ? null : val.availableCount }; });
    let uniqueDataSet = [...new Set(data.map(val => { return  isNaN(val.availableCount) ? null : val.availableCount  }))];
    let marketSeries = [({ name: 'Store offered this Unit Type', data: dataSeries })];

    if (marketSeries.length > 0) {
      this.setState({
        isNoGraphData: false,
        options: {
          chart: {
            events:{
              beforeZoom: function(chartContext, { xaxis,yaxis }) {
                if(yaxis !== undefined || xaxis.min > 0) return chartContext.t.reset =`<img src=${resetPng} alt="Reset Zoom" width="20">`;
              },
           zoomed: function(chartContext, { xaxis, yaxis }) {
            if(yaxis === undefined || xaxis.min <= 0) return chartContext.toolbar.t.reset = false;
            },
          },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: false,
                zoomin:false,
                zoomout:false,
                pan:false,
                reset:false,
              },
            },
          },
          stroke: {
            width: 2,
          },
          colors: ["#1D1E1F"],
          yaxis: {
            title: {
              text: "Number of Stores offering this Unit Type"
            },
            min:0,
            tickAmount: uniqueDataSet.length === 1 || uniqueDataSet.length === 2 ? 1 : 2,
            
            labels: {
              formatter: function (value) {
                return value === null ? "N/A" : value.toFixed(0);
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            style: {
              color: undefined,
              fontSize: '10px',
              fontFamily: 'Arial',
              fontWeight: 500,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          tooltip: {
            enabled: true,
            showForNullSeries: false,
            onDatasetHover: {
              highlightDataSeries: false,
            },
            style: {
              fontSize: '11px',
              fontFamily: "Roboto, Helvetica, Arial , sans-serif"
            },
            x: {
              show: true,
              formatter: function (value, opts) {
                if (typeof (opts) === "object") {
                  let dtd = moment(opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x, "YYYY-MM-DD").format('DD-MMM-YYYY');
                  return dtd;
                }
              },
            },
            y: {
              formatter: function (value, opts) {
                if (typeof (opts) === "object") {
                  let iAF = `${opts.series[opts.seriesIndex][opts.dataPointIndex]} Stores offered this Unit Type`;
                  return iAF;
                }
              },
              title: {
                formatter: function (val) {
                  if (val) return null;
                }
              }
            },
          },
          xaxis: {
            show: true,
            type: "datetime",
            tooltip: { enabled: false },
            labels: {
              formatter: function(value, timestamp, opts) {
                       
                let val = value === undefined || typeof (value) !== "string" ? '' : yearRange_bool ? moment(new Date(value)).format('DD-MMM-YYYY') : moment(new Date(value)).format('MMM-YYYY');
                return val;
              },
              showDuplicates: false,
              show: true,
              rotate: 0,
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder: {
              show: true
          },
          axisTicks: {
            show: true
          },
           tickAmount: yearRange == 1 ? 4 : yearRange == 2 ? 4 : yearRange == 3 ? 12 : yearRange == 4 ? 5 : 9, 
        },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: marketSeries
      });
      var tempdata = [];
      var datum = {
        name: "Graph",
        values: this.popData(data),
      };
      tempdata.push(datum);
    } else {
      this.setState({
        isNoGraphData: true,
        options: {
          chart: {
            toolbar: {
              show: false
            }
          },
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            show: false,
            labels: {
              show: false
            },
            axisBorder: {
              show: false
          },
          axisTicks: {
            show: false
          },
            categories: []
          },
        },
        series: []
      })
    }
  }

  popData(data) {
    var tFData = data;
    var tData = [];
    for (var i = 0; i < tFData.length; i++) {
      var CData = {
        date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          tFData[i].orderNo
        ),
        totalStores: tFData[i].totalStores,
        availableCount: tFData[i].availableCount,
        datePrice: new Date(tFData[i].datePrice),
        orderNo: tFData[i].orderNo,
      };
      tData.push(CData);
    }
    return tData;
  }

  unitInventory(e) {
    this.state.isDefaultUnit = e.target.value;
    this.setState({yearRange : e.target.value});
    const inputParam = {
      UserStoreID: this.state.storeId,
      SearchID: this.state.searchId,
      Duration: this.state.isDefaultUnit,
    };

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.commonInitLoad(inputParam);
    // this.initialLoad(res);
  }
  openPopup() {
    this.setState((prevState) => ({
      isModel: !prevState.isModel,
    }));
  }
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = this.state.isDefaultUnit;
    let usId = this.props.storeId;
    let searchId = this.props.searchId;
    let accessKey = localStorage.getItem("SecretKey");
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "MarketInventoryAnalysis" + "_" + timestamp + ".pdf";
    var stoName =
      this.props.storeDetails &&
        this.props.storeDetails.storeName &&
        this.props.storeDetails.storeName !== "" &&
        this.props.storeDetails.storeName !== undefined &&
        this.props.storeDetails.storeName !== null
        ? this.props.storeDetails.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "MarketInventoryAnalysis" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 9;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  render() {
    const {
      isLoading,
      unitDurations,
      date,
      isRemovedCount,
      isNoGraphData,
      isAddedCount,
      unitInventoryAvailablity,
      snackOpen,
      snackMSg,
    } = this.state;

    const defaultProps = {
      helpTitle: "Historical Inventory Analytics",
      helpContent:
        "<p>In Optimize, we define inventory as the number of stores in this Market which advertise a particular unit type. On this graph you will see the number of stores over time (5 days, 30 days, 90 days, 6 months or 1 year) which have advertised this unit type.</p><p>Below the graph, under Added in Last 24 Hours, you will see the stores in this Market which have either added this unit type to their website or removed it from their website in the last 24 hours.</p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/hl1BepLwnnI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    return (
      <div>
        <div className="App history-tab">
          <h4>
            History <HelpTextPopup parm={defaultProps} />
            <AvForm
              id="frmDurDdl"
              ref={(c) => (this.myFormRef = c)}
              className="days-count"
            >
              <AvField
                type="select"
                name="price"
                value={this.state.defaultSelected}
                onChange={(e) => this.unitInventory(e)}
              >
                {unitDurations
                  ? unitDurations.map((unit) => (
                    <option
                      key={unit.id}
                      selected={this.state.defaultSelected}
                      value={unit.id}
                    >
                      {unit.timePeriod}
                    </option>
                  ))
                  : ""}
              </AvField>
            </AvForm>
          </h4>
          <Button
            disabled={!isLoading && !isNoGraphData ? false : true}
            style={{ marginTop: "12px", marginRight: "0px" }}
            className={
              !isNoGraphData
                ? "apply-btn download-pdf "
                : "download-pdf-disabled"
            }
            onClick={this.downLoadPDF.bind(this)}
          >
            <FontAwesomeIcon icon={faFilePdf} />
            PDF Download
          </Button>
          <div className="clear"></div>

          {isNoGraphData ? (
            <div className="no-data mb-3">
              No data available for this graph!
            </div>
          ) : (
            <div className="display-grid">
              <div id="Inventory-Analysis">
              <Chart options={this.state.options} series={this.state.series} type="line" style={{ width: "100%", overflow: 'hidden' }} height={320} />
              </div>
             
            </div>
          )}
          <div className="both-list">
            <Row>
              <div className="added-on col-lg-6 col-md-12 padding-right-7">
                {/* <h5>ADDED ON DATE: {moment(date).format('L')}</h5> */}
                <h5>Added in last 24 hours</h5>
                <div className="body-add-on">
                  <Row>
                    {isAddedCount.length > 0 ? (
                      isAddedCount.map((unit) =>
                        unit.isAdded == 1 ? (
                          <div className="col-lg-6">
                            <div className="address-listings">
                              <MarketProfile ref={this.child} />
                              <a
                                onClick={() =>
                                  this.getParams(unit.storeName, unit.storeID)
                                }
                              >
                                <img src={greenimage} alt="" />
                                {unit.storeName}
                              </a>
                              <div className="address-name">{unit.address}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : (
                      <div className="col-lg-12 no-records pt-2">
                        {isLoading === false
                          ? "No Stores have Added this Unit Type"
                          : ""}
                      </div>
                    )}
                  </Row>
                </div>
              </div>

              <div className="added-on col-lg-6 col-md-12 low-range">
                {/* <h5>WITHDRAWN ON DATE: {moment(date).format('L')}</h5> */}
                <h5>Removed in last 24 hours</h5>
                <div className="body-add-on">
                  <Row>
                    {isRemovedCount.length > 0 ? (
                      isRemovedCount.map((unit) =>
                        unit.isAdded == 0 ? (
                          <div className="col-lg-6">
                            <div className="address-listings red-list">
                              <MarketProfile ref={this.child} />
                              <a
                                onClick={() =>
                                  this.getParams(unit.storeName, unit.storeID)
                                }
                              >
                                <img src={redimages} alt="" />
                                {unit.storeName}
                              </a>
                              <div className="address-name">{unit.address}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : (
                      <div className="col-lg-12 no-records pt-2">
                        {isLoading === false
                          ? "No Stores have Removed this Unit Type"
                          : ""}
                      </div>
                    )}
                  </Row>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div className="">
          {isLoading ? (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose.bind(this)}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose.bind(this)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackMSg}</span>}
        />
      </div>
    );
  }
}
