import React from 'react';
import HubspotForm from 'react-hubspot-form';
import './Requesttrial.css'
//import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
// import axios from 'axios';
import './Requesttrial.css';

export default class Requesttrial extends React.Component {
    
    render() {

        return (
            <div className="request-free-trial">
                <Container>

                    <div className="hubspot-form">
                        <h1>Thank you for your interest in our FREE 7-day trial</h1>
                        <Row>
                            <Col md="6">


                                <div className="align-txt-center">
                                    <p>The StorTrack free 7-day trial is based on approval. Please fill in the form below and one of our representatives will contact you to set-up your FREE 7-day access. This will give you access, free of charge, to all functionality of a Basic subscription for one market.</p>
                                </div>
                            </Col>
                            <Col md="6" className="hubspot-form-fields">
                                <h6>Your Information</h6>
                                <HubspotForm
                                    portalId='4297816'
                                    formId='36e1104a-e244-422a-9495-86d5797d2410'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}