import React from 'react';
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {
    Container, Col, Row, Button, Label, Input, FormText,
    CardDeck, Card, CardTitle, CardSubtitle, CardFooter, CardBody
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './Home.css';
import freeoval from './../../Assets/Images/oval1.png';
import singleimg from './../../Assets/Images/oval2.png';
import multiimg from './../../Assets/Images/oval3.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Divider from '@material-ui/core/Divider';
import CreditCardInput from 'react-credit-card-input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { CONFIG, MatchingCountryCode } from '../../utils/config';
import Checkbox from '@material-ui/core/Checkbox';
import { COMMON } from '../../utils/common';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'
import btnarrow from './../../Assets/Images/btn-arrow.png'
import btnarrowwhite from './../../Assets/Images/btn-arrow-white.png'

function getSteps() {
    return ['Create Your Account', 'Billing Address', 'Payment Details', 'You are Confirmed!'];
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            snackOpen: false,
            activeStep: 0,
            UserDetail: {},
            PaymentDetail: {},
            errCard: '',
            phoneErr: '',
            emailErr: '',
            dialogcnt: '',
            StateCountry: '',
            stateAbb: '',
            stateData: [],
            phoneFieldBorder: '0',
            countryData: [],
            total_value: null,
            checkItOut: false,
            terms: false,
            focusValue: '',
            isLoading1: false,
            checkErr: '',
            snackMSg: '',
            phoneFieldBorder: '0',
            curSymb: CONFIG.Currency_Symbol,
            phone: '',
            noofstores: '',
            dialogbox2: false,
            phoneMaxLength: CONFIG.PhoneMaxLength,
            StateCountryCount: 1,
            isFullDiscount: false,
            filteredState: [],
            showState: true,
        };
        // this.emailInput = React.createRef();
        this.modal_toggle = this.modal_toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
        this.formsub_modal_toggle = this.formsub_modal_toggle.bind(this);
        this.ValidateCard = this.ValidateCard.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.postalCodeValidation = this.postalCodeValidation.bind(this);
    }

    phoneKeyPressed = (event) => {
        this.setState({ keyCode: event.keyCode });
    }


    onPhoneChangeML = (event) => {
        var string = event.target.value;
        var replacedString = event.target.value;
        // var replacedString = event.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
        var numericRegex = /^[ 0-9*()+-]*$/;// /^([0-9]*)$/;
        var valid = numericRegex.exec(replacedString);
        var keyCode = this.state.keyCode;
        let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';

        if (valid) {
            string = replacedString;
            // var regex = CONFIG.PhoneRegex;
            // var match = regex.exec(string);
            // if (match) {
            //     match.shift();
            //     match[0] = "(" + match[0] + ")";
            //     string = match.join("-");
            phoneErr = '';
            phoneFieldBorder = '0';
            // }
        } else {
            if (keyCode !== 8 && keyCode !== 46) {
                string = this.state.phone;
            }
        }

        if (string === "") {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
        }
        this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
    }
    onPhoneChangesML = (event) => {
        var string = event;
        let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';
        if (string === "" || string === undefined) {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
        }
        this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
    }
    onPhoneChanges = (value) => {
        let UserDetail = this.state.UserDetail;
        var string = value;
        let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';
        if (string === "" || string === undefined) {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
        }
        UserDetail['Phone'] = string;
        this.setState({ UserDetail, phoneErr, phoneFieldBorder, keyCode: '' });
    }

    onPhoneChange = (event) => {
        let UserDetail = this.state.UserDetail;
        var string = event.target.value;
        var replacedString = string;// event.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
        var numericRegex = /^[ 0-9*()+-]*$/;///^([0-9]*)$/;
        var valid = numericRegex.exec(replacedString);
        var keyCode = this.state.keyCode;
        let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';

        if (valid) {
            string = replacedString;
            // var regex = CONFIG.PhoneRegex;
            // var match = regex.exec(string);
            // if (match) {
            //     match.shift();
            //     match[0] = "(" + match[0] + ")";
            //     string = match.join("-");
            phoneErr = '';
            phoneFieldBorder = '0';
            // }
        } else {
            if (keyCode !== 8 && keyCode !== 46) {
                string = this.state.UserDetail.Phone;
            }
        }

        if (string === "") {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
        }
        UserDetail['Phone'] = string;
        this.setState({ UserDetail, phoneErr, phoneFieldBorder, keyCode: '' });
    }

    //Payment Checkbox
    checkPayTerms() {
        this.setState({ checkItOut: !this.state.checkItOut });
    }

    //Snack Close
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    // Terms and Conditions Dialog
    handleTermsOpen = () => {
        this.setState({
            terms: true,
        });
    };

    handleTermsClose = () => {
        this.setState({ terms: false });
    };

    //Stepper Dialog  
    modal_toggle(dialog_typ) {
        let total_value = this.state.total_value;
        let modalValid = true;
        let UserDetail = this.state.UserDetail;
        if (dialog_typ === '1') {
            total_value = this.state.p_singleMarket[0].regularPrice;
        }
        else if (dialog_typ === '0') {
            modalValid = true;
        }
        else {

            let nos = UserDetail.TempNoOfStores ? parseInt(UserDetail.TempNoOfStores) : '';
            if (nos !== '' && nos > 0) {
                if (nos > 99) {
                    dialog_typ = '4';
                }
                else {
                    let multiMarket_price = this.state.p_multiMarket.filter(function (data) {
                        return nos <= data.noOfStores && data.userType === 1
                    });
                    total_value = multiMarket_price[0].regularPrice * nos;
                    modalValid = true;
                    this.setState({ multiMarket_price });
                }

            }
            else {
                modalValid = false;
                let msg = nos === 0 ?
                    'Please Enter Valid Market Count' : 'Please Enter Your Market Count';
                this.setState({ snackOpen: true, snackMSg: msg });
            }
        }

        if (modalValid) {
            if (dialog_typ === '4') {
                this.setState(
                    prevState => ({
                        dialogbox2: !prevState.dialogbox2, dialogcnt: dialog_typ, total_value, UserDetail
                    }));
            } else {
                this.setState(
                    prevState => ({
                        modal: !prevState.modal, dialogcnt: dialog_typ, total_value, UserDetail
                    }));
            }

        }

    }

    //while form submit in multimarket home card
    formsub_modal_toggle(e) {
        e.preventDefault();
        let total_value = this.state.total_value;
        let modalValid = true;
        let UserDetail = this.state.UserDetail;
        let dialog_typ = '2';
        let nos = UserDetail.TempNoOfStores ? parseInt(UserDetail.TempNoOfStores) : '';
        if (nos !== '' && nos > 0) {
            if (nos > 99) {
                dialog_typ = '4';
            }
            else {
                let multiMarket_price = this.state.p_multiMarket.filter(function (data) {
                    return nos <= data.noOfStores && data.userType === 1
                });
                total_value = multiMarket_price[0].regularPrice * nos;
                modalValid = true;
                this.setState({ multiMarket_price });
            }

        }
        else {
            modalValid = false;
            let msg = nos === 0 ?
                'Please Enter Valid Market Count' : 'Please Enter Your Market Count';
            this.setState({ snackOpen: true, snackMSg: msg });
        }

        if (modalValid) {
            if (dialog_typ === '4') {
                this.setState(
                    prevState => ({
                        dialogbox2: !prevState.dialogbox2, dialogcnt: dialog_typ, total_value, UserDetail
                    }));
            } else {
                this.setState(
                    prevState => ({
                        modal: !prevState.modal, dialogcnt: dialog_typ, total_value, UserDetail
                    }));
            }
        }
    }

    handleBlur() {
        this.ValidateCard();
    }

    ValidateCard() {
        let formvalid = false;
        if (!this.state.PaymentDetail.cardNumber || !this.state.PaymentDetail.SecurityCode || !this.state.PaymentDetail.expiry) {
            this.setState({ errCard: "Please enter missing card details" })
        }
        else if (this.state.PaymentDetail.expiry) {
            //Expiry date check for Card
            var getPaymonthyear = this.state.PaymentDetail.expiry.toString().split('/');
            let ExpiryMonth = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
            let ExpiryYear = getPaymonthyear.length > 1 ? getPaymonthyear[1].trim() : '';
            var CurrentMonth = new Date().getMonth() + 1;
            var CurrentYear = new Date().getFullYear().toString().substr(-2);
            if (CurrentYear > ExpiryYear) {
                this.setState({ errCard: 'Please enter valid expiry year' });
                formvalid = false;
            }
            else {
                if (CurrentYear === ExpiryYear) {
                    if (CurrentMonth > ExpiryMonth || ExpiryMonth > 12) {
                        this.setState({ errCard: 'Please enter valid expiry month' });
                        formvalid = false;
                    } else {
                        if (this.state.PaymentDetail.SecurityCode) {
                            if (this.state.PaymentDetail.SecurityCode.length === 3 || this.state.PaymentDetail.SecurityCode.length === 4) {
                                this.setState({ errCard: '' });
                                formvalid = true;
                            } else {
                                this.setState({ errCard: 'Please enter valid CVV' });
                                formvalid = false;
                            }
                        }
                    }
                }
                else {
                    if (ExpiryMonth > 12) {
                        this.setState({ errCard: 'Please enter valid expiry month' });
                    }
                    else {
                        if (this.state.PaymentDetail.SecurityCode) {
                            if (this.state.PaymentDetail.SecurityCode.length === 3 || this.state.PaymentDetail.SecurityCode.length === 4) {
                                this.setState({ errCard: '' });
                                formvalid = true;
                            } else {
                                this.setState({ errCard: 'Please enter valid CVV' });
                                formvalid = false;
                            }
                        }
                    }
                }
            }
        }
        return formvalid;
    }

    handleValidSubmit(event, errors, values) {

        let formValid = true;
        if (this.state.activeStep === 2) {

            //credit card validation
            formValid = this.state.isFullDiscount ? true : this.ValidateCard();

            let checkErr = '';
            if (this.state.checkItOut === false) {
                formValid = false;
                checkErr = 'Please accept terms and conditions';
            }
            this.setState({ checkErr });
        }
        //Phone number validation starts
        let phoneErr = '';
        let phoneFieldBorder = '0';
        if (this.state.UserDetail.Phone === undefined || this.state.UserDetail.Phone === "") {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
            formValid = false;
        } else if (this.state.UserDetail.Phone.length < CONFIG.PhoneMinLength) {
            //this.state.UserDetail.Phone.length != CONFIG.PhoneMaxLength) {
            phoneErr = 'Phone is invalid';
            phoneFieldBorder = '1';
            formValid = false;
        }
        this.setState({ phoneErr, phoneFieldBorder });
        //Phone number validation end

        //next stepper
        if (errors.length === 0) {
            // Based on radio button validation
            if (this.state.activeStep === 0) {
                if (this.state.dialogcnt === '2') {

                    // TempNoofMarket Field Validation
                    if (this.state.UserDetail.TempNoOfStores === undefined || this.state.UserDetail.TempNoOfStores === "") {
                        formValid = false;
                        this.setState({ snackOpen: true, snackMSg: 'Please Enter Market Count' });
                    }
                    else if (parseInt(this.state.UserDetail.TempNoOfStores) === 0) {
                        formValid = false;
                        this.setState({ snackOpen: true, snackMSg: 'Please Enter Valid Market Count' });
                    }
                }
            }
            if (formValid) {
                this.setState({ isLoading1: true });
                //for next and  pay button double click restrict
                if (this.state.activeStep === 0) {
                    this.setState({ dblclkrest1: true });
                } else if (this.state.activeStep === 1) {
                    this.setState({ dblclkrest2: true });
                } else if (this.state.activeStep === 2) {
                    this.setState({ dblclkrest: true });
                }

                //For account create restriction
                if (this.state.activeStep !== 2) {
                    if (this.state.activeStep === 0) {
                        const data = {
                            UserEmail: this.state.UserDetail.Login,
                            RegionID: CONFIG.RegionId
                        };
                        axios.post(CONFIG.API_URL + 'Home/signinvalidation', data)
                            .then(response => {
                                let Err = '';
                                if (response.data.message === 'No data') {
                                    this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', emailErr: '', checkErr: '' }));
                                } else {
                                    this.setState({ emailErr: 'Email Address already exists', dblclkrest1: false, focusValue: 'focused' });
                                    const x = document.getElementById('focused');
                                    x.focus();
                                }
                            });
                    }
                    else {
                        this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', emailErr: '', checkErr: '' }));
                    }
                    setTimeout(
                        function () {
                            this.setState({ isLoading1: false });
                        }
                            .bind(this), 1000
                    );

                }
                else {

                    let UserDetail = this.state.UserDetail;
                    let PaymentDetail = this.state.PaymentDetail;
                    let phoneNumber = UserDetail.Phone;
                    /* Temp No of Stores */
                    let countryDetails = this.state.countryData.filter(function (data) {
                        return data.countryCode === UserDetail['BillingCountry']
                    });
                    UserDetail['BillingCountry'] = countryDetails[0].countryID;
                    if (this.state.dialogcnt === '1') {
                        UserDetail['TempNoOfStores'] = 1;
                    }

                    UserDetail['TempNoOfStores'] = parseInt(UserDetail['TempNoOfStores']);
                    /* End of Temp No of Stores */

                    /* Temp Basic No of Stores */
                    UserDetail['TempBasicNoOfStores'] = UserDetail['TempNoOfStores'];
                    /* End of Temp Basic No of Stores */

                    /* Temp Plus No of Stores */
                    UserDetail['TempPlusNoOfStores'] = 0;
                    /* End of Temp Plus No of Stores */

                    UserDetail['BillingPhone'] = phoneNumber.replace(/[^\d]/g, '');
                    UserDetail['Phone'] = phoneNumber.replace(/[^\d]/g, '');
                    UserDetail['BillingAddress2'] = UserDetail['BillingAddress2'] ? UserDetail['BillingAddress2'] : '';
                    UserDetail['UserType'] = 1;
                    UserDetail['Status'] = 1;
                    UserDetail["IsReceiveEmailAlerts"] = true;
                    UserDetail["PromoID"] = 0;
                    UserDetail["LastName"] = '';
                    UserDetail["BillingState"] = this.state.UserDetail.BillingState ? this.state.UserDetail.BillingState : '';
                    var getPaymonthyear = this.state.isFullDiscount ? '' : PaymentDetail.expiry.toString().split('/');
                    PaymentDetail['ExpiryMonth'] = this.state.isFullDiscount ? '' : getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
                    PaymentDetail['ExpiryYear'] = this.state.isFullDiscount ? '' : getPaymonthyear.length > 1 ? parseInt('20'.concat(getPaymonthyear[1].trim())) : '';
                    UserDetail['CouponCode'] = this.state.validCoupon ? UserDetail['CouponCode'] : '';
                    PaymentDetail['CardType'] = this.state.isFullDiscount ? '' : 'visa';
                    PaymentDetail['IsDefault'] = true;

                    const data = {
                        UserDetail,
                        PaymentDetail,
                        "RegionID": CONFIG.RegionId
                    };
                    //console.log(data); 

                    axios.post(CONFIG.API_URL + 'subscription/add', data) //'http://52.37.198.165:1213/api/subscription/add
                        .then(response => {
                            this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', sucUser: response.data, checkItOut: false, isLoading1: false, checkErr: '' }));
                        })
                        .catch(err => {

                            if (err.response.data.Message !== 'Payment Failed') {
                                if (err.response.data.Message == 'User with this email address already exists.') {
                                    this.setState({ snackOpen: true, snackMSg: err.response.data.Message, errCard: '', dblclkrest: false, checkErr: '' });
                                }
                                else {
                                    this.setState({ errCard: err.response.data.Message, dblclkrest: false, checkErr: '' });
                                }
                            }
                            else {
                                this.setState({ snackOpen: true, snackMSg: 'Payment Failed', dblclkrest: false, errCard: '', checkErr: '' });
                            }
                            this.setState({ isLoading1: false });
                        });
                }
            }
        }
    }

    closeModal() {
        this.setState({ modal: false, activeStep: 0, UserDetail: {}, PaymentDetail: {}, errCard: '', couponErr: '', emailErr: '', total_value: null, checkItOut: false, validCoupon: '', discountTotal: '', dblclkrest1: false, dblclkrest2: false, dblclkrest: false, checkErr: '', phoneErr: '', phoneFieldBorder: '', phone: '' });
        this.todoTextElem.form[0].value = '';
    }

    handleBack() {
        this.setState({ phoneErr: '', phoneFieldBorder: '', phone: '' });
        if (this.state.activeStep === 3 || this.state.activeStep === 0) {
            this.setState({ modal: false, activeStep: 0, UserDetail: {}, PaymentDetail: {}, errCard: '', checkErr: '', phoneErr: '', couponErr: '', emailErr: '', phoneFieldBorder: '0', total_value: null, checkItOut: false, validCoupon: '', discountTotal: '', dblclkrest1: false, dblclkrest2: false, dblclkrest: false });
            if (this.state.activeStep === 3) {

                let token = this.state.sucUser ? this.state.sucUser.token : ''; console.log(this.state.sucUser);
                if (token) {
                    //console.log(this.state.sucUser);
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                    localStorage.setItem('accessKey', token);
                    localStorage.setItem('getNames', this.state.sucUser.firstName);
                    localStorage.setItem('isPaid', this.state.sucUser.isPaid);
                    localStorage.setItem('tempNoStoreCount', this.state.sucUser.tempNoOfStores);
                    localStorage.setItem('instructionMsg', this.state.sucUser.instructionMsg);
                    localStorage.setItem('userType', this.state.sucUser.userType);
                    localStorage.setItem('userStatus', this.state.sucUser.status);
                    localStorage.setItem('userCdate', this.state.createdDate);
                    if (this.state.sucUser.isPaid === 0) {
                        const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
                        this.props.history.push(from);
                    }
                    else {
                        const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
                        this.props.history.push(from);
                    }
                }
            }
        }
        else {
            if (this.state.activeStep === 1) {
                let UserDetail = this.state.UserDetail;
                UserDetail['CouponCode'] = '';
                this.setState({ validCoupon: '', discountTotal: '', UserDetail });
            }
            this.setState({ activeStep: this.state.activeStep - 1, errCard: '', checkErr: '', couponErr: '', emailErr: '', phoneFieldBorder: '0', dblclkrest1: false, dblclkrest2: false, dblclkrest: false });
        }
        this.todoTextElem.form[0].value = '';
    }

    handleChange(e) {

        let fields = this.state.activeStep === 2 ? this.state.PaymentDetail : this.state.UserDetail;

        let inputValid = true;



        if (e.target.name === 'Login') {
            this.setState({ focusValue: '', emailErr: '' });
        }

        //Check for No of stores below 100
        if (e.target.name === 'TempNoOfStores') {
            e.target.value = e.target.value.replace(/[^\w\s]/gi, "");
            if (e.target.value.length > 3) {
                e.target.value = e.target.value.substring(0, Math.min(e.target.value.length, 3));
            }

            // if (this.state.modal === true) {
            //     if (e.target.value.length > 2) {
            //         inputValid = false;
            //         this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100' });
            //     }
            // }
            // else {
            //     //For market count restrict below 1000
            //     if (e.target.name === 'TempNoOfStores') {
            //         if (e.target.value.length > 3) {
            //             e.target.value = e.target.value.substring(0, Math.min(e.target.value.length, 3));
            //         }
            //     }
            // }
        }

        if (inputValid === true) {

            fields[e.target.name] = e.target.value;
            if (e.target.name === "cardNumber" || e.target.name === "SecurityCode" || e.target.name === "expiry") {
                this.setState({ fields, errCard: '' });
            } else {
                this.setState({
                    fields
                });
            }

        }
        if (this.state.UserDetail.BillingState !== undefined && this.state.UserDetail.BillingState !== '' && e.target.name === 'BillingState') {
            //spliting the country code from state id
            var Billing_state = e.target.value.toString().split(',');
            var sc_code = Billing_state.length > 0 ? Billing_state[1].trim() : '';
            var s_code = Billing_state.length > 0 ? Billing_state[0].trim() : '';

            // State's country code based country details
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.countryCode === sc_code
            });

            //Adding Country name
            fields['BillingCountry'] = countryDetails[0].countryCode;
            this.setState({ fields, stateAbb: s_code === "England" ? '' : s_code });
        }
        // Country based state;
        if (this.state.UserDetail.BillingCountry !== undefined && this.state.UserDetail.BillingCountry !== '' && e.target.name === 'BillingCountry') {
            //spliting the country code from state id
            var sc_code = e.target.value;

            let countryDetails = this.state.countryData.filter(function (data) {
                return data.countryCode === sc_code
            });
            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.countryCode === sc_code
            });
            //Adding Country name
            fields['BillingCountry'] = stateDetails.length > 0 ? countryDetails[0].countryCode : '';
            fields['BillingState'] = '';
            fields['BillingCountryCode'] = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].countryCode : '';
            this.state.UserDetail.BillingState = '';

            this.state.UserDetail.BillingCountryCode = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].countryCode === "England" ? "GB" : countryDetails[0].countryCode : '';
            this.setState({ fields, stateAbb: sc_code === "England" ? '' : sc_code, filteredState: stateDetails, showState: countryDetails[0].regionId === COMMON.StorTrackRegion.UK ? false : true });
        }
        //Multi Market Store Based on TempNoOfStores Calculation
        let nos = 0;
        if (this.state.dialogcnt === '2' && e.target.name === 'TempNoOfStores') {

            nos = this.state.UserDetail.TempNoOfStores && this.state.UserDetail.TempNoOfStores !== '0' ? parseInt(this.state.UserDetail.TempNoOfStores) : 0;
            let multiMarket_price = this.state.p_multiMarket.filter(function (data) {
                return nos <= data.noOfStores && data.userType === 1
            });

            let base_total1 = multiMarket_price[0].regularPrice * nos;
            if (this.state.UserDetail.TempPlusNoOfStores !== undefined && this.state.UserDetail.TempPlusNoOfStores !== '') {
                base_total1 = base_total1 + (this.state.UserDetail.TempPlusNoOfStores * 20);
            }

            this.setState({ total_value: base_total1, multiMarket_price });
        }
    }

    componentDidMount() {
        localStorage.clear();
        if (!localStorage.getItem('accessKey') || ((document.location.origin + '/') === document.location.href)) {
            var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = '//js.hs-scripts.com/4297816.js';//'https://embed.tawk.to/5bbe622408387933e5bacf9e/default';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        }
        this.setState({
            curSymb: CONFIG.Currency_Symbol
        })
        //Region
        // axios.get(CONFIG.API_URL + 'home/region')
        //     .then(response => {
        //         let curSymb = response.data.region.currencySymbol;
        //         this.setState({
        //             curSymb
        //         })
        //     })
        //     .catch(err => {
        //         this.setState({ isLoading1: false });
        //     });

        //Price Lisiting
        // axios.get(CONFIG.API_URL + 'Home/getprice')
        axios.get(CONFIG.API_URL + 'Home/getprice/' + CONFIG.RegionId)
            .then(response => {
                let priceData = response.data.prices.priceInformation;

                let singleMarket = priceData.filter(function (data) {
                    return data.name === "Single market"
                });

                let multiMarket = priceData.filter(function (data) {
                    return data.name === "Multi-Market"
                });

                this.setState({
                    singleMarket, multiMarket
                });
            })
            .catch(err => {
                this.setState({ isLoading1: false });
            });

        //Price Calculation Lisiting 
        // axios.get(CONFIG.API_URL + 'Home/getpricecalculation')
        axios.get(CONFIG.API_URL + 'Home/getpricecalculation/' + CONFIG.RegionId)
            .then(response => {
                let priceData = response.data.prices.priceList;

                let p_singleMarket = priceData.filter(function (data) {
                    return data.userType === 1 && data.noOfStores === 1
                });

                let p_multiMarket = priceData.filter(function (data) {
                    return data.userType === 1
                })

                this.setState({
                    p_singleMarket, p_multiMarket
                });
            })
            .catch(err => {
                this.setState({ isLoading1: false });
            });

        //State and Country
        // axios.get(CONFIG.API_URL + 'home/lookupdata')
        axios.get(CONFIG.API_URL + 'home/lookupdata/' + CONFIG.RegionId + '/' + 1)
            .then(response => {
                let stateData = response.data.lookups.statesList;
                let countryData = response.data.lookups.countryList;
                //To know how many Countries in statesList data
                // let StateCountryCount = stateData.filter(data => {
                //     return data.stateID === '';
                // }).length;

                this.setState({
                    stateData, countryData
                });
            })
            .catch(err => {
                this.setState({ isLoading1: false });
            });

    }

    freetrialhandleClick() {
        this.props.history.push({ pathname: "/requesttrial" });
    }

    CouponApply() {

        let CouponCode = this.state.UserDetail.CouponCode;
        let couponValid = true;
        let validCoupon = this.state.validCoupon ? this.state.validCoupon : '';
        let userType = this.state.dialogcnt === '1' ? 1 : this.state.dialogcnt === '2' ? 1 : 4;
        let storeCount = this.state.dialogcnt === '1' ? 1 : this.state.dialogcnt === '2' ? this.state.UserDetail.TempNoOfStores : 999;
        if (CouponCode === undefined || CouponCode === '') {
            couponValid = false;
            this.setState({ couponErr: 'PromoCode is required', validCoupon: '', discountTotal: '' });
        }

        if (couponValid) {
            const data = {
                CouponCode: CouponCode,
                'UserType': userType,
                'StoreCount': storeCount,
                'RegionID': CONFIG.RegionId
            };
            axios.post(CONFIG.API_URL + 'subscription/couponcode', data)
                .then(response => {
                    let discountTotal = response.data.isFixedPrice == 0 ? this.state.total_value - ((this.state.total_value / 100) * response.data.discountPercentage) : response.data.fixedPrice && response.data.fixedPrice != null ? response.data.fixedPrice * response.data.storeCount : 'N/A';
                    // let discountTotal = this.state.total_value - ((this.state.total_value / 100) * response.data.discountPercentage);
                    discountTotal = discountTotal.toFixed(2);
                    let decimalCount = discountTotal.toString().split('.');
                    discountTotal = !decimalCount[1] ? discountTotal + '.00' : decimalCount[1].length === 1 ? discountTotal + '0' : discountTotal;
                    let isDiscount = discountTotal === "0.00" ? true : false;
                    // //Decimal Number Conversion 
                    // let checkDigit = discountTotal.toString().split('.');
                    // let ret = "";
                    // let i = 0;
                    // if (discountTotal.toString().indexOf(".") == -1) {
                    //     discountTotal = discountTotal;
                    // } else {
                    //     if (checkDigit == '00') {
                    //         discountTotal = checkDigit.trim();
                    //     }
                    //     else {
                    //         if (checkDigit.length == 2) {
                    //             ret += checkDigit[0] + ".";
                    //             for (i = 0; i < checkDigit.length; i++) {
                    //                 if (i >= checkDigit[1].length)
                    //                     ret += '0';
                    //                 else
                    //                     ret += checkDigit[1][i];
                    //             }
                    //         }
                    //         else if (checkDigit.length == 1) {
                    //             ret += checkDigit[0] + ".";
                    //             for (i = 0; i < checkDigit.length; i++) {
                    //                 ret += '0'
                    //             }
                    //         }
                    //         ret = ret.toString();
                    //         discountTotal = ret;
                    //     }
                    // }

                    this.setState({ isFullDiscount: isDiscount, discountTotal, couponErr: '', validCoupon: CouponCode });
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.Message === "The coupon code you've entered has already expired!") {
                        this.setState({ couponErr: 'Expired PromoCode', validCoupon: '', discountTotal: '' });
                    }
                    else {
                        this.setState({ couponErr: 'Invalid Promo Code', validCoupon: '', discountTotal: '' });
                    }
                });
        }


    }

    multpleLocationSubmit(event, errors, values) {
        let formValid = true;

        //Phone number validation starts
        let phoneErr = '';
        let phoneFieldBorder = '0';
        if (this.state.phone === "") {

            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
            formValid = false;
        } else if (this.state.phone.length < CONFIG.PhoneMinLength) {
            //(this.state.phone.length != CONFIG.PhoneMaxLength) {
            phoneErr = 'Phone is invalid';
            phoneFieldBorder = '1';
            formValid = false;
        }
        let noofstores = values.noofstores;

        this.setState({ phoneErr, phoneFieldBorder, noofstores });

        //Phone number validation end


        if (errors.length > 0) {
            formValid = false;
        }
        if (formValid) {
            if (errors.length > 0) {
                console.log(errors);
            }
            else {
                let data = {
                    "fName": values.fName.trim(),
                    "lName": values.lName.trim(),
                    "company": values.company.trim(),
                    "phone": this.state.phone.replace(/[^\d]/g, ''),
                    "email": values.email.trim(),
                    "noofstores": values.noofstores ? values.noofstores : this.state.noofstores,
                    "RegionID": CONFIG.RegionId
                }
                this.setState({ isLoading: true });
                axios.post(CONFIG.API_URL + 'subscription/getquote', data)
                    .then(res => {

                        this.myFormRef && this.myFormRef.reset();
                        let msg = "Thank you for submitting your request. We will contact you shortly regarding your 100+ subscription request.";
                        if (this.state.modal === true) {
                            this.setState(prevState => ({ dialogbox2: !prevState.dialogbox2, snackOpen: true, dialogcnt: '2', snackMSg: msg, phoneErr: '', phoneFieldBorder: '', phone: '' }));
                        }
                        else {
                            this.setState(prevState => ({ dialogbox2: !prevState.dialogbox2, snackOpen: true, snackMSg: msg, phoneErr: '', phoneFieldBorder: '', phone: '' }));
                        }

                        this.todoTextElem.form[0].value = '';
                        // this.modal_toggle('5');
                        //this.setState( prevState =>({ modal :!prevState.modal}));
                        //    alert(res.data);
                    })
                    .catch(err => {
                        //  this.myFormRef && this.myFormRef.reset();
                        // alert(err.response.data.Message);
                        let msg = err.response.data.Message;
                        this.setState({ snackOpen: true, snackMSg: msg });
                    });
            }
        }

    }

    handleClosedb2() {
        if (this.state.modal === true) {
            this.setState({ dialogbox2: false, phoneErr: '', phoneFieldBorder: '', phone: '', dialogcnt: '2' });
        }
        else {
            this.setState({ dialogbox2: false, phoneErr: '', phoneFieldBorder: '', phone: '' });
        }
    }

    resetAllValues() {
        this.myFormRef && this.myFormRef.reset();
        this.setState({ phoneErr: '', phoneFieldBorder: '', phone: '' });
    }
    postalCodeValidation = (e) => {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code == 32) && // space
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            e.preventDefault();
        }
    }
    render() {
        document.title = 'StorTrack: Self-storage market pricing data';
        const steps = getSteps();
        const { isFullDiscount, dialogcnt, isLoading1, stateData, StateCountryCount, singleMarket, multiMarket, p_singleMarket, multiMarket_price, p_multiMarket, total_value, snackMSg, snackOpen, stateAbb, curSymb, countryData, filteredState, showState } = this.state;
        const price_title = dialogcnt === '1' ? p_singleMarket[0].regularPrice : multiMarket_price !== undefined ? this.state.UserDetail.TempNoOfStores ? multiMarket_price[0].regularPrice : 0 : 0;
        const marketName = dialogcnt === '1' ? 'Single Market' : 'Multi Market';

        //for decimal values restriction
        /* for market price*/
        var Pricedet = this.state.dialogcnt === '1' ? p_singleMarket[0].regularPrice : this.state.dialogcnt === '2' ? multiMarket_price[0].regularPrice : '';
        var decPricedet = Pricedet.toString().split('.');
        let subPrice = !decPricedet[1] ? Pricedet + '.00' : decPricedet[1].length === 1 ? Pricedet + '0' : Pricedet;

        /* for total value */
        var dectotVal = total_value && total_value !== null ? total_value.toString().split('.') : '';
        let dtotal_value = !dectotVal[1] ? total_value + '.00' : dectotVal[1].length === 1 ? total_value + '0' : total_value;

        return (
            <div className="landing-page">
                {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                <section className="landing-body">
                    <Container className="landing-background">
                        <h1 className="whetheryouareasi">
                            Whether you are a single market operator, a multi-market <br />operator or a developer/investor, we have the right solution for you.
                        </h1>
                        <div className="signupforthemost">
                            Sign up for the most powerful market analysis tool in self-storage
                        </div>
                        <Row>
                            <Col className='pricing-col'>

                                <CardDeck>
                                    {CONFIG.RegionId !== COMMON.StorTrackRegion.UK ?
                                        <Card >
                                            <div className='card-boder-top'></div>
                                            <CardBody >

                                                <CardTitle >
                                                    <div className='heading-text-box'>
                                                        <h1 className="freetrial"> 7-Day Free Trial</h1>
                                                    </div>
                                                    <div className='sub-heading'>Get market insights for free</div>
                                                </CardTitle>
                                                <CardSubtitle>
                                                    <p className='pricing-content'> 7-day access to a market with a 10-mile radius. Explore all pricing, inventory, and market data without obligation. Dive into valuable insights and make informed decisions with ease.</p>
                                                </CardSubtitle>
                                            </CardBody>
                                            <CardFooter className='pricing-col-btn ' >

                                                <button onClick={() => this.modal_toggle('0')} className="get-started">Get Started  <img src={btnarrow} className='mouse-out' /> <img src={btnarrowwhite} className="mouse-in" />
                                                </button>
                                            </CardFooter>
                                        </Card>
                                        : ''}
                                    <Card>
                                        <div className='card-boder-top'></div>
                                        <CardBody>
                                            <CardTitle>
                                                <div className='heading-text-box'>
                                                    <h1 className="freetrial"> Single Market  </h1>
                                                    <div className='rate-month'>  {singleMarket ? <NumberFormat value={singleMarket[0].price} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}/month</div>
                                                </div>
                                                <div className='sub-heading'>For single-store operators</div>
                                                {/* <div className="amount-height">
                                                    <div className="amount">
                                                        {singleMarket ? <NumberFormat value={singleMarket[0].price} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}
                                                    </div>
                                                    <div className="labels">
                                                        per month
                                                    </div>
                                                </div> */}
                                            </CardTitle>
                                            <CardSubtitle>
                                                <p className='pricing-content'> Full access to all Optimize features and functions tailored to your market. Stay ahead with up-to-date competitive data on pricing and inventory. Enjoy hassle-free monthly billing with easy cancellation.</p>
                                            </CardSubtitle>
                                        </CardBody>
                                        <CardFooter className='pricing-col-btn' >
                                            <button onClick={() => this.modal_toggle('1')} className="get-started">Get Started
                                                <img src={btnarrow} className='mouse-out' /> <img src={btnarrowwhite} className="mouse-in" />
                                            </button>
                                        </CardFooter>
                                    </Card>
                                    <Card>
                                        <div className='card-boder-top'></div>
                                        <CardBody>
                                            <CardTitle>
                                                <div className='heading-text-box'>
                                                    <h1 className="freetrial"> Multiple Markets</h1>
                                                    <div className='rate-month'>As low as {multiMarket ? <NumberFormat value={multiMarket[0].price} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'} per market/month</div>
                                                </div>
                                                <div className='sub-heading'>For multi-store operators</div>
                                                {/* <div className="amount-height">
                                                    <div className="amount">
                                                        as low as {multiMarket ? <NumberFormat value={multiMarket[0].price} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}
                                                    </div>
                                                    <div className="labels">
                                                        per market/per month
                                                    </div>
                                                </div> */}
                                            </CardTitle>
                                            <CardSubtitle>
                                                <p className='pricing-content'> Perfect for operators managing multiple markets. Enjoy access to all features and functions per market. Stay ahead with real-time competitive data on pricing and inventory. Monthly billing with hassle-free cancellation.</p>
                                                <div className="count-list">
                                                    <form onSubmit={this.formsub_modal_toggle}>
                                                        I have <input ref={el => this.todoTextElem = el} type="number" maxLength="3" name="TempNoOfStores" values={this.state.UserDetail.TempNoOfStores || null} onChange={this.handleChange} className="count-type" /> markets
                                                    </form>
                                                </div>
                                            </CardSubtitle>
                                        </CardBody>
                                        <CardFooter className='pricing-col-btn' >
                                            <button onClick={() => this.modal_toggle('2')} className="get-started">Get Quote
                                                <img src={btnarrow} className='mouse-out' /> <img src={btnarrowwhite} className="mouse-in" />
                                            </button>
                                        </CardFooter>
                                    </Card>
                                </CardDeck>
                            </Col>
                        </Row>
                        <Dialog className={dialogcnt === '0' ? 'FreeTrial landing-stepper landing-popup' : dialogcnt === '4' ? ' SingleMarket landing-stepper landing-popup' : 'MultiMarket landing-stepper landing-popup'} open={this.state.modal} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle align="center">
                                {dialogcnt === '0' ?
                                    <div>
                                        <h3 className="free-trial-h3">Request a Free Trial</h3>
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            className="closeModal"
                                            onClick={this.closeModal.bind(this)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    :
                                    <div>
                                        <b className="h-pop-head">Set-up Your Optimize Account</b>
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            className="closeModal"
                                            onClick={this.closeModal.bind(this)}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Stepper className="stepper-align" activeStep={this.state.activeStep} alternativeLabel >
                                            {steps.map((label, index) => (
                                                <Step key={label}>
                                                    <StepLabel
                                                        className={this.state.activeStep > index ? 'MuiStepIcon-completed-cx' : ''}
                                                        completed={false}
                                                    >{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                }
                            </DialogTitle>
                            <DialogContent>
                                {dialogcnt === '0' ?
                                    <div className="free-trial">
                                        <Typography gutterBottom> Get 7 days of access to StorTrack OPTIMIZE for one market.</Typography>
                                        <Typography gutterBottom> Upgrade to a single or multiple market subscription at any time.</Typography>
                                        <Typography gutterBottom> Please note, if at the end of your trial you decide not to continue with a paid subscription, you could lose any saved searches. </Typography>
                                        <Typography gutterBottom> Click here to complete a request form, and upon approval we will activate your free trial access. No credit card required. </Typography>
                                        <Row align="center">
                                            <Col sm="12" className="" >
                                                <Button onClick={this.freetrialhandleClick.bind(this)} className="continue-botton-modal sign-in-btn" >Sign Up Today</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    this.state.activeStep === steps.length ? (
                                        <div>
                                            <Typography >All steps completed</Typography>
                                            <Button>Reset</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            {this.state.activeStep === 0 ?
                                                <div className='user-details'>
                                                    <Container>
                                                        <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                            <Row>
                                                                <Col md="6" className="pr-0">
                                                                    <Row className="scr-1">
                                                                        <Col>
                                                                            <div className="subcontent-1">
                                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription st-sub-change">
                                                                                    Your StorTrack Optimize Subscription {/*is {<NumberFormat value={price_title +'.00'} displayType={'text'} thousandSeparator={true} prefix={curSymb} />} */}
                                                                                </Typography>
                                                                                <FormGroup className="upgrade-option">
                                                                                    <div>
                                                                                        {dialogcnt === '2' ?
                                                                                            <Typography component="p" className="quote-for">
                                                                                                Quote for <input type="number" min="1" maxLength="2" name="TempNoOfStores" value={this.state.UserDetail.TempNoOfStores || ''} onChange={this.handleChange} className="count-type" /> market(s), {this.state.UserDetail.TempNoOfStores ? <NumberFormat value={multiMarket_price[0].regularPrice + '.00'} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'} monthly/market
                                                                                            </Typography>
                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                    </div>
                                                                                </FormGroup>
                                                                                <Typography component="p" align="right" className="hom-text-bd">
                                                                                    Monthly Subscription total: <span>{total_value !== null ? <NumberFormat value={total_value + '.00'} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}</span>
                                                                                </Typography>
                                                                                <Typography component="p" align="right" className="hom-text-bd">
                                                                                    <b>Total due today: <span>{total_value !== null ? <NumberFormat value={total_value + '.00'} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}</span></b>
                                                                                </Typography>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="scr-2">
                                                                        <Col className="background-left">
                                                                            <div className="subcontent-2">
                                                                                <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                    Here's what you will get, everything you need to Optimize your pricing and performance!
                                                                                </Typography>
                                                                                <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Define your competitive territory using {(CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? 'kilometre' : 'miles'} radius, postal codes or city name
                                                                                </Typography>
                                                                                <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Direct like-for-like market pricing comparison charts and graphs at the unit type level
                                                                                </Typography>
                                                                                <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Up-to-date pricing across the whole competitive market
                                                                                </Typography>
                                                                                <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Inventory change analysis - see when units are taken off market
                                                                                </Typography>
                                                                                <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Historical pricing and inventory graphs for in-depth analysis
                                                                                </Typography>
                                                                                {CONFIG.RegionId === 1 ? <Typography className="bulleting" variant="caption" gutterBottom>
                                                                                    Also includes the new Marketing Score analysis - see how your competitors are marketing themselves
                                                                                </Typography> : ''}
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                                <Col md="6">
                                                                    <Row className="enter-acc-details">
                                                                        <Col md="12" className="pr-0">
                                                                            <Typography variant="h5" component="h5" gutterBottom>
                                                                                Enter Account Details
                                                                            </Typography>
                                                                        </Col>
                                                                        <Col md="12" className="pr-0">
                                                                            <Label> Email Address</Label>
                                                                            <AvField name="Login" type="text" validate={{
                                                                                required: { value: true, errorMessage: 'Email Address is required' },
                                                                                pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                                                            }} onChange={this.handleChange} value={this.state.UserDetail.Login || ''}
                                                                                id={this.state.focusValue == 'focused' ? 'focused' : ''}
                                                                            // ref={(input) => { this.nameInput = input; }}
                                                                            />
                                                                            <FormText className='errorMsg' color="red">{this.state.emailErr}</FormText>
                                                                        </Col>
                                                                        <Col md="12" className="pr-0">
                                                                            <Label> Password</Label>
                                                                            <AvField name="Password" type="password" validate={{
                                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                                            }}
                                                                                onChange={this.handleChange} />

                                                                        </Col>
                                                                        <Col md="12" className="pr-0">
                                                                            <Label>Confirm Password</Label>
                                                                            <AvField name="ConfirmPassword" type="password" validate={{
                                                                                required: { value: true, errorMessage: 'Confirm Password is required' },
                                                                                match: { value: 'Password', errorMessage: 'Mismatch Confirm Password' },
                                                                                maxLength: { value: 20, errorMessage: 'Your Password must be 20 characters' }
                                                                            }} />
                                                                        </Col>

                                                                        <Col md="12" className="pr-0">
                                                                            <Typography variant="h5" component="h3" gutterBottom>
                                                                                Enter Company and Contact Details
                                                                            </Typography>
                                                                        </Col>
                                                                        <Col md="12" className="pr-0">
                                                                            <Label>Full Name</Label>
                                                                            <AvField name="FirstName" id="FirstName" validate={{
                                                                                required: { value: true, errorMessage: 'Full Name is required' }
                                                                            }}
                                                                                onChange={this.handleChange} value={this.state.UserDetail.FirstName || ''} />
                                                                        </Col>
                                                                        <Col md="12" className="pr-0">
                                                                            <Label> Company </Label>
                                                                            <AvField name="Company"
                                                                                onChange={this.handleChange} value={this.state.UserDetail.Company || ''} />

                                                                        </Col>
                                                                        {/* <Col md="12" className="pr-0">
                                                                            <Label for="m_company">Phone</Label> <input type="text" name="phone" id="phone" value={this.state.UserDetail.Phone ? this.state.UserDetail.Phone : ''} onKeyDown={this.phoneKeyPressed} onChange={this.onPhoneChange} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                                                            <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                                                                        </Col> */}
                                                                        <Col md="12" className="pr-0">
                                                                            <Label for="m_company">Phone</Label>
                                                                            <PhoneInput name="phone" id="phone" value={this.state.UserDetail.Phone ? this.state.UserDetail.Phone : ''} onChange={this.onPhoneChanges} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                                                            <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col>
                                                                    <Row>
                                                                        <div className="step_btn">
                                                                            <Col className="pr-0">
                                                                                <Button
                                                                                    onClick={this.handleBack.bind(this)}
                                                                                    color="secondary"
                                                                                    variant="contained"
                                                                                    className="go-back-btn">
                                                                                    Go Back
                                                                                </Button>
                                                                                {this.state.UserDetail.TempNoOfStores > 99 ?
                                                                                    <Button type="button" onClick={() => this.modal_toggle('4')} className="next-btn" >
                                                                                        Next
                                                                                    </Button>
                                                                                    :
                                                                                    <Button className="next-btn" disabled={this.state.dblclkrest1 || this.state.dblclkrest1 ? true : false}>
                                                                                        Next
                                                                                    </Button>
                                                                                }

                                                                            </Col>
                                                                        </div>
                                                                    </Row>
                                                                </Col>

                                                            </Row>
                                                        </AvForm>
                                                    </Container>
                                                </div>
                                                :
                                                this.state.activeStep === 1 ?
                                                    <div className='billing-content cmn-cnt'>
                                                        <Container>
                                                            <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                                <Row className="enter-acc-details">
                                                                    <Col md="12">
                                                                        <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                            Company and Contact Details
                                                                        </Typography>
                                                                    </Col>
                                                                    <Col md="6" className="pr-0">
                                                                        <Label> Email Address (username)</Label>
                                                                        <AvField name="dEmail" type="email" disabled value={this.state.UserDetail.Login || ''} />
                                                                    </Col>
                                                                    <Col md="6" className="pr-0">
                                                                        <Label>Full Name</Label>
                                                                        <AvField name="dName" disabled value={this.state.UserDetail.FirstName || ''} />
                                                                    </Col>
                                                                    {this.state.UserDetail.Company ?
                                                                        <Col md="6" className="pr-0">
                                                                            <Label> Company </Label>
                                                                            <AvField name="dCompany" disabled value={this.state.UserDetail.Company || ''} />
                                                                        </Col>
                                                                        : ''}
                                                                    <Col md="6" className="pr-0">
                                                                        <Label>Phone</Label>
                                                                        <AvField name="dPhone" disabled value={this.state.UserDetail.Phone || ''} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="">
                                                                    <Col md="6" className="pr-0">
                                                                        <Row className="enter-acc-details">
                                                                            <Col md="12">
                                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                                    Add Billing Address
                                                                                </Typography>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <Label>{CONFIG.Address1}</Label>
                                                                                <AvField name="BillingAddress" validate={{
                                                                                    required: { value: true, errorMessage: CONFIG.Address1 + ' is required' },
                                                                                    maxLength: { value: 150, errorMessage: 'Your' + CONFIG.Address1 + ' must be 150 characters' }
                                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingAddress || ''} />
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <Label> {CONFIG.Address2}</Label>
                                                                                <AvField name="BillingAddress2" onChange={this.handleChange} value={this.state.UserDetail.BillingAddress2 || ''}
                                                                                    validate={{ maxLength: { value: 150, errorMessage: 'Your ' + CONFIG.Address2 + ' must be 150 characters' } }} />
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <Label> {CONFIG.City}</Label>
                                                                                <AvField name="BillingCity" validate={{
                                                                                    required: { value: true, errorMessage: CONFIG.City + ' is required' },
                                                                                    maxLength: { value: 100, errorMessage: 'Your ' + CONFIG.City + ' must be 100 characters' }
                                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingCity || ''} />
                                                                            </Col>
                                                                            {/* {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '':
                                                                                <Col md="6" className="pr-0 billing-state-province">
                                                                                    <Label> {CONFIG.State}</Label>
                                                                                    <AvField type="select" name="BillingState" className="bs" validate={{
                                                                                        required: { value: true, errorMessage: CONFIG.State+ ' is required' }
                                                                                    }} onChange={this.handleChange} value={this.state.UserDetail.BillingState}>
                                                                                        <option value='' >--Select State--</option>
                                                                                        {stateData ? stateData.map((state) =>
                                                                                            state.stateID === '' && StateCountryCount <= 1 ? '' :
                                                                                            <option key={state.stateID} className={state.stateID === '' ? "optHead" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                                                        ) : ''}
                                                                                    </AvField>
                                                                                </Col>
                                                                                } */}
                                                                            <Col md="12">
                                                                                <Label> {CONFIG.Country}</Label>
                                                                                <AvField type="select" name="BillingCountry" className="bs" validate={{
                                                                                    required: { value: true, errorMessage: 'Country is required' }
                                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingCountry}>
                                                                                    <option value='' >--Select Country--</option>
                                                                                    {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                                                        if (state.country !== "Scotland" && state.country !== "Wales" && state.country !== "Northern Ireland")
                                                                                            return (
                                                                                                //state.stateID !== '' ? 
                                                                                                <option key={state.country} value={state.countryCode}>{state.country === "England" ? 'United Kingdom' : state.country}</option>
                                                                                                // :''
                                                                                            )
                                                                                    }) : ''}
                                                                                </AvField>
                                                                            </Col>
                                                                            {filteredState && filteredState.length > 0 && showState ?
                                                                                <Col md="6" className="pr-0 billing-state-province">
                                                                                    <Label> {CONFIG.State}</Label>
                                                                                    <AvField type="select" name="BillingState" className="bs" validate={{
                                                                                        required: { value: true, errorMessage: CONFIG.RegionId === COMMON.StorTrackRegion.US ? 'State/Province is required' : 'State is required' }
                                                                                    }} onChange={this.handleChange} value={this.state.UserDetail.BillingState}>
                                                                                        <option value='' >{CONFIG.RegionId === COMMON.StorTrackRegion.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                                                        {filteredState ? filteredState.filter(state => state.stateID !== '').map((state) => {
                                                                                            return (
                                                                                                //state.stateID !== '' ? 
                                                                                                <option key={state.stateID} className={state.stateID === '' ? "optHead disabled-states" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                                                                // :''
                                                                                            )
                                                                                        }) : ''}
                                                                                    </AvField>
                                                                                </Col>
                                                                                : ''}
                                                                            <Col md={filteredState && filteredState.length > 0 && showState === true ? 6 : 12} className={CONFIG.RegionId === MatchingCountryCode.UK_RID ? "postal-code-uk left-padding-a-m" : "left-padding-a-m"}>
                                                                                <Label> {CONFIG.PostalCode}</Label>
                                                                                <AvField name="BillingZipCode" validate={{
                                                                                    required: { value: true, errorMessage: CONFIG.PostalCode + ' is required' },
                                                                                    pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.PostalCodevalidation },
                                                                                    maxLength: { value: 10, errorMessage: 'Your ' + CONFIG.PostalCode + ' must below 10 characters' }
                                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingZipCode || ''} onKeyPress={CONFIG.RegionId === 3 ? this.postalCodeValidation : ''} />
                                                                            </Col>
                                                                            {/* {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '':
                                                                                <Col md="12">
                                                                                    <Label> {CONFIG.Country}</Label>
                                                                                    <AvField name="BillingCountry" readOnly validate={{
                                                                                        required: { value: true, errorMessage: CONFIG.Country+ ' is required' }
                                                                                    }} onChange={this.handleChange}
                                                                                        value={this.state.UserDetail.BillingState ? this.state.StateCountry : ''} />
                                                                                </Col>
                                                                            } */}

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="6" className="pr-0">
                                                                        <Row className="height-94">
                                                                            <Col>
                                                                                <div className="bill-promo height-94 pr-2">
                                                                                    <Paper className="p-2 pt-4 pb-4 elevation0 apply-promo height-94" component="div">
                                                                                        <Typography variant="h5" component="h5" align="right" className="st-subscription" >
                                                                                            Your Subscription Details
                                                                                        </Typography>

                                                                                        <Typography component="p" align="right" className=''>
                                                                                            <b> {dialogcnt === '1' ? '1' : this.state.UserDetail.TempNoOfStores}</b> market(s) at <b><NumberFormat value={subPrice} displayType={'text'} thousandSeparator={true} prefix={curSymb} /></b> monthly
                                                                                        </Typography>

                                                                                        <Typography component="p" align="right" gutterBottom>
                                                                                            Monthly Subscription total: <b>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}</b>
                                                                                        </Typography>

                                                                                        <Col md="pt-4 padding-top-10">
                                                                                            <Typography variant="title" align="right" component="h6">
                                                                                                Promo code:
                                                                                            </Typography>
                                                                                        </Col>
                                                                                        <div className="apply-code">
                                                                                            <Input type="text" className="promoCode" name="CouponCode" onChange={this.handleChange} value={this.state.UserDetail.CouponCode || ''} />
                                                                                            <Button className="apply-btn" onClick={this.CouponApply.bind(this)}>Apply</Button>
                                                                                            <div className="clear"></div>
                                                                                            <FormText color='red'>{this.state.couponErr}</FormText>
                                                                                        </div>
                                                                                        <Typography align="right" gutterBottom variant="h5" component="h5" className="st-subscription">
                                                                                            Total due today: <b className={this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? 'discount-strike' : ''}>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b> <b>{this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? <NumberFormat value={this.state.discountTotal} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                                        </Typography>
                                                                                    </Paper>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="pr-0">
                                                                        <div className="step_btn">

                                                                            <Button
                                                                                onClick={this.handleBack.bind(this)}
                                                                                color="secondary"
                                                                                variant="contained"
                                                                                className="go-back-btn">
                                                                                Go Back
                                                                            </Button>
                                                                            <Button className="next-btn" disabled={this.state.dblclkrest2 || this.state.dblclkrest2 ? true : false}> Next</Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </Container>
                                                    </div> :
                                                    this.state.activeStep === 2 ?
                                                        <div className='payment-details'>
                                                            <Container>
                                                                <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                                    <Row className="">
                                                                        <Col md="6" className="pr-0">
                                                                            <div className="subcontent-3 pb-3">
                                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                                    Billing Address
                                                                                </Typography>
                                                                                <Typography component="p" >
                                                                                    {this.state.UserDetail.BillingAddress || ''},
                                                                                </Typography>
                                                                                {this.state.UserDetail.BillingAddress2 !== undefined && this.state.UserDetail.BillingAddress2 !== '' ?
                                                                                    <Typography component="p" >
                                                                                        {this.state.UserDetail.BillingAddress2 + ',' || ''}
                                                                                    </Typography>
                                                                                    : ''}
                                                                                <Typography component="p" gutterBottom>
                                                                                    {this.state.UserDetail.BillingCity || ''}{stateAbb ? ', ' + stateAbb : ''}  {this.state.UserDetail.BillingZipCode || ''}
                                                                                </Typography>
                                                                            </div>
                                                                            <div className="paycontent">
                                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                                    Add Payment Details
                                                                                </Typography>
                                                                                <Row className="enter-acc-details mar-min-top ">
                                                                                    <Col md="12">
                                                                                        <Label>Name on Card</Label>
                                                                                        <AvField name="cardName" id="Name" validate={{
                                                                                            required: { value: isFullDiscount === true ? false : true, errorMessage: 'Please enter the card Name' }
                                                                                        }} onChange={this.handleChange} value={this.state.PaymentDetail.cardName || ''}
                                                                                            disabled={isFullDiscount === true ? true : false} />
                                                                                    </Col>
                                                                                    <Col md="12" className={isFullDiscount === true ? "disabled-card" : ""}>
                                                                                        <Label>Card Credentials</Label>
                                                                                        <CreditCardInput
                                                                                            cardNumberInputProps={{ value: this.state.PaymentDetail.cardNumber || '', name: "cardNumber", placeholder: 'Card Number', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                                            cardExpiryInputProps={{ value: this.state.PaymentDetail.expiry || '', name: "expiry", placeholder: 'MM/YY', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                                            cardCVCInputProps={{ value: this.state.PaymentDetail.SecurityCode || '', name: "SecurityCode", placeholder: 'CVV', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                                            fieldClassName={this.state.errCard !== '' ? "input is-invalid" : "input"}
                                                                                        />
                                                                                        <FormText className='errorMsg' color="red">{this.state.errCard}</FormText>
                                                                                    </Col>
                                                                                    <Col md="12" className="pt-1">
                                                                                        <div className="terms_cond">
                                                                                            {/* <AvField type="checkbox" label={<span>I Agree to StorTrack's <Link to={'/'} onClick={this.handleTermsOpen}>Terms</Link></span>} name="checkItOut" onClick={this.checkPayTerms.bind(this)} validate={{
                                                                                                            required: { value: true, errorMessage: 'Field is required' }
                                                                                                        }} value={this.state.checkItOut} /> */}
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox className="track-check-gl" name="checkItOut" onClick={this.checkPayTerms.bind(this)} checked={this.state.checkItOut} />
                                                                                                }
                                                                                                label={<span className="font-sz-13">I Agree to StorTrack's <Link to={'/'} onClick={this.handleTermsOpen}>Terms</Link></span>}
                                                                                            />
                                                                                            <FormText className='errorMsg' color="red">{this.state.checkItOut === false ? this.state.checkErr : ''}</FormText>
                                                                                            <div className="clear"></div>
                                                                                        </div>
                                                                                        <div className="termsandconditions">
                                                                                            <Dialog onClose={this.handleTermsClose}
                                                                                                aria-labelledby="customized-dialog-title"
                                                                                                open={this.state.terms} className="tc_dialog"
                                                                                            >
                                                                                                <DialogTitle id="customized-dialog-title" onClose={this.handleTermsClose}>
                                                                                                    <b>Terms of Service</b>
                                                                                                    <IconButton
                                                                                                        key="close"
                                                                                                        aria-label="Close"
                                                                                                        color="inherit"
                                                                                                        className="closeModal"
                                                                                                        onClick={this.handleTermsClose.bind(this)}
                                                                                                    >
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent dividers='true'>
                                                                                                    <Typography gutterBottom style={{ fontSize: '13px', fontWeight: '600' }}>Effective and Last Updated: April 1, 2024</Typography>

                                                                                                    <Typography gutterBottom>This website is owned and operated by Aggregate Intelligence, Inc. (the producer of the StorTrack services) referred hereto as ‘StorTrack’. This webpage contains the Terms of Service governing your access to and use of StorTrack (the “Site”). When used in this Agreement, "Service" means those premium content areas of the Site which you may have been authorized to access. </Typography>
                                                                                                    <Typography gutterBottom>By submitting your completed Registration Form, and using any part of the service, you are representing that you are 18 years old or older, and that you agree to be bound by all of the terms in this Subscription Agreement. You may print and keep a copy of this Agreement. Aggregate Intelligence, Inc. may change the terms in this Subscription Agreement. When the terms are changed, we will notify you by e-mail and/or online postings. The changes will also appear in this document, which you can access at any time by selecting "Terms of Service" in the footer section of the site’s home page. After notification that a change in this Subscription Agreement has been posted, you agree to be bound by all of the changes.</Typography>
                                                                                                    <Typography gutterBottom>The “Effective Date” of this Agreement is the date that the person placing the order accepts the Terms by clicking ‘Accept Terms of Subscription’ during the subscription account set-up process on the Site.</Typography>
                                                                                                    <Typography gutterBottom>If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to this Agreement in which case the term “Client” shall refer to such entity. If you do not have such authority, or if you do not agree to the terms of this Agreement, do not accept this Agreement or use the Services. If you receive free access or a trial account for evaluation you are deemed a “Client” under this Agreement and are subject to its terms</Typography>
                                                                                                    <Typography gutterBottom>You may not use or access the Services if you are a direct competitor of StorTrack or if you are accessing or using the Services for the benefit of a direct competitor of StorTrack.</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        PRIVACY OF INFORMATION
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom> By clicking the "I Accept" button, you also are agreeing that Aggregate Intelligence, Inc. may use information you provide as part of the subscription process and usage information, and provide aggregate statistical information about subscribers to advertisers, potential advertisers, and content licensors. Aggregate Intelligence, Inc. also may use the information to inform you about other products and services, unless you notify Aggregate Intelligence, Inc. that you do not wish to receive this information. Aggregate Intelligence, Inc. also may deliver additional messages about the Service to you by e-mail.</Typography>
                                                                                                    <Typography gutterBottom> As a general policy, Aggregate Intelligence, Inc. does not disclose to third parties any specific personal identifying information about you, such as your name, address, phone number, or e-mail address ("Personal Identifying Information"). Aggregate Intelligence, Inc. will not disclose any of your Personal Identifying Information to a third party without first notifying you and giving you an opportunity to elect not to have your Personal Identifying Information disclosed. Also, please consult our Privacy Policy .</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        ENTERPRISE CUSTOMERS
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom>If Aggregate Intelligence, Inc. and you or your employer have manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, the terms in that manually signed, paper document or Enterprise Subscription Agreement are not amended by, and supersede, terms in this Subscription Agreement.</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        FEES AND PAYMENTS
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom>You can always find the current Subscription Fees and charges posted with the product information. Your subscription will continue for the term specified and will renew automatically until terminated by Aggregate Intelligence, Inc. or until you notify Aggregate Intelligence, Inc. by electronic mail of your decision to terminate your subscription or cancel the subscription from the “My Account” section after Signing In. Your default payment method will be charged for the total amount for any subscription package selected during the Checkout process. All fees and charges are non-refundable.</Typography>
                                                                                                    <Typography gutterBottom>All fees and charges incurred in connection with your username and/or password will be billed to the default payment method you designate during the registration process for the Service or the electronic information service, which provides you access to the Service. If there are any annual, or similar periodic fees for your Service subscription, these fees will be billed automatically to your default payment method at the start of the period, and at the start of each renewal period, unless you terminate your subscription before the relevant period begins. If there is a problem charging your selected payment method, we may charge any other valid payment method associated with your account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account, and you agree to accept responsibility for all activities that occur under your account or password. If you want to update your payment method, or if you believe someone has accessed the Service using your user name or password without your authorization, you must contact Aggregate Intelligence, Inc. Customer Service at the telephone numbers posted on the website or fill out the form available on the “Contact Us” page. You may also update your payment method from the “My Account” section after Signing In. You also are responsible for any fees or charges incurred to access the Service through an Internet access provider or other third-party service. YOU, AND NOT AGGREGATE INTELLIGENCE, INC., ARE RESPONSIBLE FOR PAYING ANY AMOUNTS BILLED TO YOUR PAYMENT METHOD BY A THIRD PARTY, WHICH WERE NOT AUTHORIZED BY YOU.</Typography>
                                                                                                    <Typography gutterBottom>If Aggregate Intelligence, Inc. and you or your employer has manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, some or all of these "Fees and Payments" terms may not apply to you. Please contact your employer for details.</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        COPYRIGHT AND LIMITATIONS ON USE
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom>The content available as part of the Service, other than content accessible through the Product area, is defined as "Licensed Information". The Licensed Information is the property of Aggregate Intelligence, Inc. or its licensors and is protected by copyright and other intellectual property laws. Licensed Information received through the Service may be displayed, reformatted and printed for your personal, non-commercial use only. You agree not to reproduce, transmit, distribute, display, sell, location, archive or publish any Licensed Information to anyone, including but not limited to others in the same company or organization, without the express prior consent of Aggregate Intelligence, Inc. with these two exceptions:</Typography>
                                                                                                    <Typography gutterBottom><b>1)</b> You may, on an occasional and irregular basis, disseminate an insubstantial portion of content retrieved from the Service, for a non-commercial purpose, without charge, and in non-electronic form, to a limited number of individuals, provided you include all copyright and other proprietary notices with such portion of the content in the same form in which the notices appear in the Service, and the phrase "Used with permission from Aggregate Intelligence, Inc. " To request consent for other matters, please send an e-mail to support@stortrack.com</Typography>
                                                                                                    <Typography gutterBottom><b>2)</b> Except where indicated otherwise you may e-mail your Service search results to a single e-mail address and a single individual within your company or organization The content accessible through hyperlinks within company capsules in the database is not made available to you through any contractual arrangement between Aggregate Intelligence, Inc. and third parties, but instead is made available from publicly available sources on the World Wide Web. FOR THIS REASON, AGGREGATE INTELLIGENCE, INC. DOES NOT GRANT YOU ANY RIGHT OR LICENSE WHATSOEVER WITH RESPECT TO CONTENT ACCESSIBLE THROUGH THE GUIDE. You are solely responsible for determining the extent to which the content accessible through the application may be used or distributed. Aggregate Intelligence, Inc. reserves the right to monitor the Subscriber's use of all premium content, services, and tools to ensure the Subscriber's compliance with the Terms and Conditions. If it is determined that the Subscriber is not in compliance with these Terms and Conditions, Aggregate Intelligence, Inc. reserves the right to take such action as is deemed necessary, including, but not limited to, suspension or termination of the Subscriber's account. The Subscriber acknowledges that such monitoring of use may include determining whether or not the Service is accessed under the account from IP addresses, monitoring the log files to determine if any computer programs are being run in order to access data from the site, as well as noting excessive use from Subscription accounts. Only one individual may access the Service at the same time using the same user name or password, unless agreed otherwise by Aggregate Intelligence, Inc.</Typography>
                                                                                                    <Typography gutterBottom>Aggregate Intelligence, Inc. and its content licensors do not give investment advice, or advocate the purchase or sale of any security or investment.</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        DISCLAIMER OF WARRANTIES AND LIABILITY
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom>DUE TO THE NUMBER OF SOURCES FROM WHICH CONTENT ON THE SERVICE IS OBTAINED, AND THE INHERENT HAZARDS OF ELECTRONIC DISTRIBUTION, THERE MAY BE DELAYS, OMISSIONS OR INACCURACIES IN SUCH CONTENT AND THE SERVICE. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE CONTENT AVAILABLE THROUGH THE SERVICE OR THE SERVICE ITSELF. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS OR LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGE, OTHER THAN DEATH OR PERSONAL INJURY RESULTING DIRECTLY FROM USE OF THE SERVICE, CAUSED IN WHOLE OR PART BY CONTINGENCIES BEYOND THEIR CONTROL OR NEGLIGENCE IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE SERVICE AND ANY CONTENT THROUGH THE SERVICE. IN NO EVENT WILL AGGREGATE INTELLIGENCE, INC., ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES OTHER THAN DIRECT DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF AGGREGATE INTELLIGENCE, INC., ITS AFFILIATES, AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR OTHERWISE) IN ANY WAY CONNECTED WITH THE SERVICE OR THE CONTENT IN THE SERVICE SHALL NOT EXCEED THE AMOUNT YOU PAID TO AGGREGATE INTELLIGENCE, INC. FOR THE USE OF THE SERVICE IN THE 12 MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM.</Typography>
                                                                                                    <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                        ADDITIONAL LEGAL TERMS AND CONDITIONS
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom>You are responsible for all statements made and acts or omissions that occur while your password is being used. You may not use the Service for any unlawful purpose. Aggregate Intelligence, Inc. may refuse to grant you a user name that impersonates someone else, is protected by trademark or other proprietary right law, or is vulgar or otherwise offensive, as determined by Aggregate Intelligence, Inc.</Typography>
                                                                                                    <Typography gutterBottom>Aggregate Intelligence, Inc. may discontinue or change the Service, certain Licensed Information, or certain content within the Service or its availability to you, at any time, and you may always terminate your subscription at any time. This Agreement is personal to you, and you may not assign your rights or obligations to anyone. If any provision in this Agreement is invalid or unenforceable under applicable law, the remaining provisions will continue in full force and effect. This Agreement, your rights and obligations, and all actions contemplated by this Agreement shall be governed by the laws of the State of Michigan USA, as if the Agreement was a contract wholly entered into and wholly performed within the State of Michigan. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.</Typography>
                                                                                                </DialogContent>
                                                                                                {/* <DialogActions>
                                                                                                                 <div className="terms_cond">
                                                                                        <AvField type="checkbox"  label={<span>I Agree to StorTrack Terms</span>} defaultChecked={ this.state.checkItOut } name="checkItOut" onClick={this.checkPayTerms.bind(this)} validate={{
                                                                                            required: { value: true, errorMessage: 'Field is Required' }
                                                                                            }} value={this.state.PaymentDetail.checkItOut}/>
                                                                                            <div className="clear"></div>
                                                                                    </div>  
                                                                                                            </DialogActions>*/}
                                                                                            </Dialog>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <Row className="height-94">
                                                                                <Col>
                                                                                    <div className="bill-promo height-94 pr-2">
                                                                                        <Paper className="p-2 pt-4 pb-4 elevation0 apply-promo height-94" component="div" >
                                                                                            <Typography variant="h5" component="h5" align="right" className="st-subscription" >
                                                                                                Your Subscription Details
                                                                                            </Typography>

                                                                                            <Typography component="p" align="right" >
                                                                                                <b> {dialogcnt === '1' ? '1' : this.state.UserDetail.TempNoOfStores}</b> market(s) at <b><NumberFormat value={subPrice} displayType={'text'} thousandSeparator={true} prefix={curSymb} /></b> monthly
                                                                                            </Typography>

                                                                                            <Typography component="p" align="right" gutterBottom >
                                                                                                Monthly Subscription total: <b>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                                            </Typography>

                                                                                            <Col className="padding-top-10 padding-right-0 margin-btm-10">
                                                                                                <Typography variant="h6" component="h6" align="right">Promo code: {this.state.validCoupon ? <b>{this.state.validCoupon}</b> : '----'}</Typography>
                                                                                            </Col>

                                                                                            <Typography variant="h5" component="h5" align="right" gutterBottom className="st-subscription">
                                                                                                Total due today: <b className={this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? 'discount-strike' : ''}>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>  <b>{this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? <NumberFormat value={this.state.discountTotal} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                                            </Typography>
                                                                                        </Paper>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="step_btn">

                                                                                <Button
                                                                                    onClick={this.handleBack.bind(this)}
                                                                                    color="secondary"
                                                                                    variant="contained"
                                                                                    className="go-back-btn">
                                                                                    Go Back
                                                                                </Button>
                                                                                <Button disabled={this.state.dblclkrest || this.state.dblclkrest === true ? true : false} className="next-btn">{isFullDiscount ? "Submit" : "Pay Now"}</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </AvForm>
                                                            </Container>
                                                        </div>
                                                        :
                                                        this.state.activeStep === 3 ?
                                                            <div className="last-step">
                                                                <Container>
                                                                    <Row>
                                                                        <Col md="12">
                                                                            <div className="sec-msg">
                                                                                <Paper className="p-4 elevation0" component="div" align="center" >
                                                                                    <Typography variant="h3" component="h3" className="pb-3 thank-you" >
                                                                                        Thank you for subscribing!
                                                                                    </Typography>
                                                                                    <Typography variant="h6" component="h6">
                                                                                        You are now signed up to an Optimize subscription
                                                                                    </Typography>
                                                                                    <Typography variant="body2" className="pb-3 fin-txt">
                                                                                        Click below to access your account and begin setting up your<br />
                                                                                        market(s). Once set up, you'll have access to StorTrack's<br />
                                                                                        powerful, fast and smart decision support system.
                                                                                    </Typography>
                                                                                    <div className="promoCode_btn pt-5">
                                                                                        <Button onClick={this.handleBack.bind(this)}>Go to my Optimize Account</Button>
                                                                                    </div>
                                                                                    <Divider />
                                                                                    <Typography component="p" className="pt-2 mb-0 fnt-14 opacity-7" gutterBottom>
                                                                                        Questions? Contact us at {CONFIG.Toll_Free} {CONFIG.RegionId === 3 ? "UK Direct tel:" + CONFIG.Sales_Support : ''} or
                                                                                    </Typography>
                                                                                    <Typography component="p" gutterBottom classname="fnt-14">
                                                                                        <a href={`mailto:` + CONFIG.Mail_Support} className="email-conform">{CONFIG.Mail_Support}</a>
                                                                                    </Typography>
                                                                                </Paper>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div>
                                                            :
                                                            ''}
                                        </div>)}
                            </DialogContent>
                        </Dialog>

                        {/* more than 100 market */}
                        <Dialog className='SingleMarket landing-stepper landing-popup' open={this.state.dialogbox2} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle align="center">
                                <div>
                                    <h3 className="multi-h3">Quote for StorTrack Optimize</h3>
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        className="closeModal"
                                        onClick={this.handleClosedb2.bind(this)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div className="multi-location enter-acc-details">
                                    <p>You have requested pricing for <span className="st-color">100</span> or more markets. To complete this request, please fill out and submit the form below or call us at <span className="st-black">{CONFIG.Toll_Free} {CONFIG.RegionId === 3 ? "UK Direct tel:" + CONFIG.Sales_Support : ''}</span> for assistance. We will contact you soon to discuss your specific needs.</p>
                                    <AvForm onSubmit={this.multpleLocationSubmit.bind(this)} ref={c => (this.myFormRef = c)}>
                                        <Label for="m_fName">Full Name</Label><AvField name="fName" id="m_fName" type="text" validate={{ required: { value: true, errorMessage: 'Full Name is required' }, maxLength: { value: 25, errorMessage: 'Your Full Name must be between 25 or below 25 characters' } }} />
                                        <Label for="m_lName">Last Name</Label><AvField name="lName" id="m_lName" type="text" validate={{ required: { value: true, errorMessage: 'Last Name is required' }, maxLength: { value: 25, errorMessage: 'Your Last Name must be between 25 or below 25 characters' } }} />
                                        <Label for="m_company">Company</Label><AvField name="company" id="m_company" type="text" validate={{ required: { value: true, errorMessage: 'Company is required' }, maxLength: { value: 100, errorMessage: 'Your Company Name must be between 100 or below 100 characters' } }} />
                                        <Label for="m_company">Phone Number</Label>
                                        <PhoneInput name="phone" id="m_phone" value={this.state.phone} onChange={this.onPhoneChangesML} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                        <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                                        <Label for="m_company">E-mail Address</Label><AvField name="email" id="m_company" type="email" validate={{ required: { value: true, errorMessage: 'E-mail Address is required' }, pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'E-mail Address is Invalid' } }} />
                                        <AvField name="noofstores" id="m_company" value={this.state.UserDetail.TempNoOfStores} type="hidden" />
                                        <FormGroup className="multiple-sub-quotes step_btn">
                                            {/* <Button className="edit_cancel" onClick={this.handleBack.bind(this)} variant="contained" className="reset-cancel left-cancel">Cancel</Button> */}
                                            <Link to="#" onClick={this.resetAllValues.bind(this)} className="go-back-btn reset-btns">Reset</Link>
                                            <Button className="next-btn">Submit </Button>
                                        </FormGroup>
                                    </AvForm>
                                </div>
                            </DialogContent>
                        </Dialog>

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={snackOpen}
                            className="snackbar bg-color-primary custom-snackbar"
                            maxsnack={3}
                            autoHideDuration={30000}
                            onClose={this.snackHandleclose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMSg}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.snackHandleclose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />

                    </Container>
                </section>
            </div>
        );
    }
}

export default withMobileDialog()(Home); 