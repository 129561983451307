import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import asyncLoading from 'react-async-loader';

class ReactStreetview extends React.Component {

	constructor() {
		super();
		this.streetView = null;
		this.state = {
			isNoData: false
		}
	}

	initialize(canvas) {
		if (this.props.googleMaps && this.streetView == null) {
		var lthis = this;

		var location = {
			lat: this.props.streetViewPanoramaOptions.position.lat,
			lng: this.props.streetViewPanoramaOptions.position.lng
		}
		var svService = new lthis.props.googleMaps.StreetViewService();
		var panoRequest = {
			location: location,
			preference: lthis.props.googleMaps.StreetViewPreference.NEAREST,
			radius: 50,
			source: lthis.props.googleMaps.StreetViewSource.OUTDOOR
		};

		var findPanorama = function (radius) {
			panoRequest.radius = radius;
			svService.getPanorama(panoRequest, function (panoData, status) {
				if (status === lthis.props.googleMaps.StreetViewStatus.OK) {
					lthis.streetView  = new lthis.props.googleMaps.StreetViewPanorama(
						canvas,
						{
							pano: panoData.location.pano,
						});
				} else {
					//Handle other statuses here
					if (radius > 200) {
						lthis.setState({ isNoData: true });
					} else {
						findPanorama(radius + 5);
					}
				}
			});
		};

		findPanorama(50);
		this.streetView=lthis.streetView;
	}
}

	componentDidMount() {
		this.initialize(ReactDOM.findDOMNode(this));
	}

	componentDidUpdate() {
		this.initialize(ReactDOM.findDOMNode(this));
	}
	componentWillUnmount() {
		if (this.streetView) {
			this.props.googleMaps.event.clearInstanceListeners(this.streetView);
		}
	}

	render() {
		return (
			<div
				style={{
					height: '450px',
				}}
			>
				<p>{this.state.isNoData ? "Street View is not available" : "Loading data..."}</p>
			</div>
		);
	}
}

ReactStreetview.propTypes = {
	apiKey: PropTypes.string.isRequired,
	streetViewPanoramaOptions: PropTypes.object.isRequired,
	onPositionChanged: PropTypes.func,
	onPovChanged: PropTypes.func
};

ReactStreetview.defaultProps = {
	streetViewPanoramaOptions: {
		position: { lat: 30.41881800, lng: -87.20564000 },
		pov: { heading: 0, pitch: 0 },
		zoom: 1
	}
};

function mapScriptsToProps(props) {
	const googleMapsApiKey = props.apiKey;
	return {
		googleMaps: {
			globalPath: 'google.maps',
			url: 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey + '&callback=initialize',
			jsonp: true
		}
	};
}

export default asyncLoading(mapScriptsToProps)(ReactStreetview);
