import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Modal as TrackModalPop,
  ModalFooter,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
//import  {MarketDashboardHeader}  from './../MarketDashboardHeader/index';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { HashLink as Link } from "react-router-hash-link";
import "./MarketDashboard.css";
import Card from "./Card";
import { CONFIG } from "../../utils/config";
import axios from "axios";
import { HelpTextPopup } from "./../HelpTextPopup/index";
// import exportFromJSON from 'export-from-json';
import moment from "moment";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// const exportType = 'xls';
import Workbook from "react-excel-workbook";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ExcelDownload from "../../Assets/Images/excel-download.png";
import CsvDownload from "../../Assets/Images/csv-download.png"

class MarketDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeid: props.match.params.storeid,
      cards: [],
      storedata: [],
      isLoading: false,
      storeInformation: [],
      countryCode: "",
      storeAddress: "",
      priceTrends: 2,
      priceTrendsAvg: "",
      currentDate: new Date(),
      resultArray: [],
      fileName: "",
      marketDownloadData: [],
      downloadInput: [],
      pdfLoading: true,
      snackOpen: false,
      snackMsg: "",
      snackClr: "",
      modal: false,
      myMarketData: [],
      excelProgress: false,
      dropdownOpen: false,
    };
    this.handler = this.handler.bind(this);
    this.refreshMenu = this.refreshMenu.bind(this);
    this.submitRabitQueue = this.submitRabitQueue.bind(this);
    this.downLoadReport = this.downLoadReport.bind(this);
    localStorage.setItem("StId", props.match.params.storeid);
  }
  //download file excel format
  // downloadExcel() {
  //   let token = localStorage.getItem("accessKey");
  //   if (token) {
  //     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //   }
  //   const marketIp = {
  //     UserStoreID: parseInt(this.state.storeid),
  //     RegionId: CONFIG.RegionId,
  //   };
  //   axios
  //     .post(CONFIG.API_URL + "market/downloadalldata", marketIp)
  //     .then((res) => {
  //       let marketDownloadData = res.data.unitdata;
  //       this.setState({ marketDownloadData }, () => {
  //         this.download();
  //       });
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 401) {
  //         localStorage.removeItem("accessKey");
  //         const { from } = this.props.location.state || {
  //           from: { pathname: "/" },
  //         };
  //         this.props.history.push(from);
  //       } else if (err.response.status === 403) {
  //         const { from } = this.props.location.state || {
  //           from: { pathname: "/UnauthorizedAccess" },
  //         };
  //         this.props.history.push(from);
  //       } else {
  //         alert(err.response.data);
  //       }
  //     });
  // }
  // download() {
  //   let date = this.state.currentDate;
  //   var day = date.getDate();
  //   var month = date.getMonth();
  //   var year = date.getFullYear();
  //   let fileName,
  //     vehicle,
  //     covered,
  //     advertisedOnlineRate,
  //     dtAdvertisedOnlineRateChanged,
  //     advertisedWalkInRate,
  //     promo,
  //     dtPromoChanged,
  //     alarm,
  //     climate,
  //     doorType,
  //     driveUp,
  //     elevator,
  //     storename,
  //     searchName,
  //     state,
  //     size,
  //     storeAddress,
  //     humidity,
  //     outdoorAccess,
  //     power,
  //     adPromoDate,
  //     adOnlineDate,
  //     adWalkinDate, proximity;
  //   let resultArray = [];
  //   let inputArray = [];
  //   let downloadInputMerge = [];
  //   let units = (CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? ' ' + 'km' : ' ' + 'mi';
  //   var currency = this.state.storeAddress.countryCurrency;
  //   let downloadInput = [];
  //   inputArray = this.state.marketDownloadData;
  //   inputArray.map((data) => {
  //     searchName = data.searchName ? data.searchName : "N/A";
  //     storename = data.competitorName ? data.competitorName : "N/A";
  //     storeAddress = data.address ? data.address : "N/A";
  //     state = data.state ? data.state : "N/A";
  //     size = data.size ? data.size.toString() : "N/A";
  //     climate = data.climateControlled === 1 ? "ClimateControlled, " : "";
  //     humidity = data.humidityControlled === 1 ? "HumidityControlled, " : "";
  //     outdoorAccess = data.outdoorAccess === 1 ? "OutdoorAccess, " : "";
  //     alarm = data.alarm === 1 ? "Alarm, " : "";
  //     power = data.power === 1 ? "Power, " : "";
  //     covered = data.covered === 1 ? "Covered, " : "";
  //     vehicle = data.vehicle === 1 ? "Vehicle, " : "";
  //     driveUp = data.driveUp === 1 ? "DriveUp, " : "";
  //     doorType =
  //       data.doorType > 0
  //         ? data.doorType === 1
  //           ? "Roll-Up Door, "
  //           : data.doorType === 2
  //             ? "Swing Door, "
  //             : data.doorType === 3
  //               ? "Double Door, "
  //               : data.doorType === 4
  //                 ? "Chain-Link Door, "
  //                 : data.doorType === 5
  //                   ? "Other Door, "
  //                   : ""
  //         : "";
  //     elevator = data.elevator === 1 ? "Elevator, " : "";
  //     //advertisedWalkInRate = data.advertisedWalkInRate ? currency + data.advertisedWalkInRate : 'N/A';
  //     advertisedWalkInRate =
  //       data.advertisedWalkInRate &&
  //         data.advertisedWalkInRate != 0 &&
  //         data.advertisedWalkInRate != null
  //         ? data.isMonthly === 1
  //           ? currency + data.advertisedWalkInRate + "*"
  //           : currency + data.advertisedWalkInRate
  //         : "N/A";
  //     adWalkinDate = data.dtAdvertisedWalkInRateChanged
  //       ? moment(data.dtAdvertisedWalkInRateChanged).format("DD-MMM-YYYY")
  //       : "N/A";
  //     //advertisedOnlineRate = data.advertisedOnlineRate ? currency + data.advertisedOnlineRate : 'N/A';
  //     advertisedOnlineRate =
  //       data.advertisedOnlineRate &&
  //         data.advertisedOnlineRate != 0 &&
  //         data.advertisedOnlineRate != null
  //         ? data.isMonthly === 1
  //           ? currency + data.advertisedOnlineRate + "*"
  //           : currency + data.advertisedOnlineRate
  //         : "N/A";
  //     dtAdvertisedOnlineRateChanged = data.dtAdvertisedOnlineRateChanged
  //       ? moment(data.dtAdvertisedOnlineRateChanged).format("DD-MMM-YYYY")
  //       : "N/A";
  //     promo = data.promo ? data.promo : "N/A";
  //     dtPromoChanged = data.dtPromoChanged
  //       ? moment(data.dtPromoChanged).format("DD-MMM-YYYY")
  //       : "N/A";
  //     let str =
  //       alarm +
  //       climate +
  //       doorType +
  //       driveUp +
  //       elevator +
  //       humidity +
  //       vehicle +
  //       outdoorAccess +
  //       power +
  //       covered;
  //     var features = str.replace(/,\s*$/, "");
  //     proximity = data.proximity && data.proximity !== 'empty' ? data.proximity.toFixed(2) + units : '';
  //     downloadInputMerge.push({
  //       SEARCHNAME: searchName,
  //       STORENAME: storename,
  //       ADDRESS: storeAddress,
  //       PROXIMITY: proximity,
  //       STATE: state,
  //       SIZE: size,
  //       FLOOR: data.floor ? data.floor : "N/A",
  //       FEATURES: features, //alarm + climate + doorType + driveUp + elevator + humidity + outdoorAccess + power,
  //       "ADVERTISED WALK-IN RATE": advertisedWalkInRate, //data.regPrice && data.regPrice != 0 ? (data.isMonthly === 1 ? currency + data.regPrice + '*' : currency + data.regPrice) : 'N/A',
  //       "WALK-IN RATE LAST CHANGED": adWalkinDate,
  //       "ADVERTISED ONLINE RATE": advertisedOnlineRate, //data.onlinePrice && data.onlinePrice != 0 ? (data.isMonthly === 1 ? currency + data.onlinePrice + '*' : currency + data.onlinePrice) : 'N/A',
  //       "ONLINE RATE LAST CHANGED": dtAdvertisedOnlineRateChanged, //adOnlineDate,
  //       "ADVERTISED PROMOTION": promo,
  //       "PROMOTION LAST CHANGED": dtPromoChanged,
  //     });
  //   });
  //   var merge = [
  //     {
  //       SEARCHNAME: "* This rate is calculated from an advertised monthly rate",
  //     },
  //   ];
  //   downloadInput =
  //     CONFIG.RegionId === 3
  //       ? downloadInputMerge.concat(merge)
  //       : downloadInputMerge;
  //   var stoName =
  //     this.state.storeAddress.storeName &&
  //       this.state.storeAddress.storeName !== "" &&
  //       this.state.storeAddress.storeName !== undefined &&
  //       this.state.storeAddress.storeName !== null
  //       ? this.state.storeAddress.storeName
  //       : "Your Store";
  //   fileName =
  //     stoName + "_" + moment(this.state.currentDate).format("DD-MMM-YYYY");
  //   //var result = exportFromJSON({ data, fileName, exportType, formateDate: 'yyyy/mm/dd' });
  //   this.setState({ downloadInput, fileName });
  // }

  //Ignored
  // downloadExcel(){
  //     let data = '';
  //     let date = this.state.currentDate;
  //     var day = date.getDate();
  //     var month = date.getMonth();
  //     var year = date.getFullYear();
  //     let fileName;
  //     let resultArray = [];
  //     let inputArray = [];
  //    var currency  = this.state.storeAddress.countryCurrency;
  //    inputArray = this.state.cards;
  //         inputArray.map(data =>{
  //           if(data != 'Add New')
  // {
  //   let priceTrend = data.priceTrend ? data.priceTrend.toString() : 'N/A';
  //   let name = data.name.toString();
  //       resultArray.push({
  //         "Name": name,
  //         "Offered by": data.storeCount > 1 ? data.storeCount + ' stores' : data.storeCount != 0 ? data.storeCount + ' store' : 'N/A',
  //         "Avg. Market Price": data.avgPrice != "N/A" && data.avgPrice != 0 ? currency + data.avgPrice : 'N/A',
  //         "Your Price": data.myStorePrice != 'N/A' && data.myStorePrice != 0 ? currency + data.myStorePrice : 'N/A',
  //         "Price Trends": priceTrend,
  //         "Show Price Trend for": this.state.priceTrends === 1 ? 'Last 5 days' : this.state.priceTrends === 2 ? 'Last 30 days' : this.state.priceTrends === 3 ? 'Last 90 days' : this.state.priceTrends === 4 ? 'Last 6 Months' : 'Last 12 Months'
  //     })
  //     }
  //     })
  //     data = resultArray;
  //     fileName = this.state.storeAddress.storeName? this.state.storeAddress.storeName+'_'+ moment(this.state.currentDate).format("DD-MMM-YYYY") : '';
  //     //var result = exportFromJSON({ data, fileName, exportType });
  //     this.setState({resultArray, fileName});
  //   }

  setToggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  refreshMenu() {
    localStorage.setItem("refreshMenu", "show-menu");
    var isElement = document.getElementById("r2");
    if (isElement == null) {
      document.getElementById("r1").click();
    } else {
      document.getElementById("r1").click();
      document.getElementById("r2").click();
    }
  }
async  componentDidMount(priceTrendss) {
    this.setState({
      isLoading: true,
      marketDownloadData: [],
    });
    const userstoreid = this.state.storeid;
    let priceTrends1 = "";
    if (priceTrendss) {
      priceTrends1 = priceTrendss;
    } else {
      priceTrends1 = this.state.priceTrends;
    }
    let token = localStorage.getItem("accessKey");
    // console.log(token);

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    if (userstoreid) {
      let data = {
        UserStoreId: userstoreid,
        SortId: priceTrends1,
        RegionId: CONFIG.RegionId,
      };
      // axios.post(CONFIG.API_URL + 'market/dashboard/' + userstoreid + '/' + priceTrends1)
     const response = await axios.post(CONFIG.API_URL + "market/dashboard/", data)
     if(response.status === 200){
      this.state.countryCode = response.data.board.storeInformation.countryCode;
      this.state.storeInformation =
      response.data.board.storeInformation.countryCurrency;
      this.state.cards = response.data.board.units;
      const storedata = response.data.board.storeInformation;
      this.state.cards.unshift("Add New");

      // this.setState({ isLoading: false, storedata }, () => {
      //   this.downloadExcel();
      // });
      this.setState({ isLoading: false, storedata });
      /*
       }
       else
       {
        alert("No store units available");
        const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
        this.props.history.push(from);
       }*/
     }else{
      this.setState({ isLoading: false });
      if (response.status === 401) {
        localStorage.removeItem("accessKey");
        const { from } = this.props.location.state || {
          from: { pathname: "/" },
        };
        this.props.history.push(from);
      } else if (response.status === 403) {
        const { from } = this.props.location.state || {
          from: { pathname: "/UnauthorizedAccess" },
        };
        this.props.history.push(from);
      } else {
        alert(response.data);
      }
     }
    
    } else {
      alert("User store id is empty");
    }


    const data1 = {
      UserStoreID: parseInt(this.state.storeid),
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(CONFIG.API_URL + "dashboard/dashboardlocation/", data1)
      .then((res) => {
        let storeAddress = res.data.data.storeDashboard;
        // To identify the Status report of Excel report is progress
        let excelProgress = storeAddress.reportStatus == 1 ? true : false;
        // End of To identify the Status report of Excel report is progress

        // this.setState({ storeAddress, excelProgress }, () => {
        //   this.downloadExcel();
        // });
        this.setState({ storeAddress, excelProgress });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });


    // To Call ExcelReport status Api
    this.CallgetStatusReportApi = setInterval(() => this.statusReport(), 30000);
  }



  //To call the Function
  componentWillUnmount() {
    clearInterval(this.CallgetStatusReportApi);
  }

  // To Get Status of ExcelReport
  statusReport() {
    if (this.state.excelProgress) {
      let token = localStorage.getItem("accessKey");
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }
      const data = {
        UserStoreID: parseInt(this.state.storeid),
        RegionId: CONFIG.RegionId,
      };
      axios
        .post(CONFIG.API_URL + "dashboard/dashboardlocation/", data)
        .then((res) => {
          let storeAddress = res.data.data.storeDashboard;
          // To identify the Status report of Excel report is progress
          let excelProgress = storeAddress.reportStatus == 1 ? true : false;
          // End of To identify the Status report of Excel report is progress

          this.setState({ storeAddress, excelProgress });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.response.status === 401) {
            localStorage.removeItem("accessKey");
            const { from } = this.props.location.state || {
              from: { pathname: "/" },
            };
            this.props.history.push(from);
          } else {
            alert(err.response.data);
          }
        });
    }
  }

  showPriceTrends = (event) => {
    let priceTrends = event.target.value;

    this.setState(
      { priceTrendsAvg: priceTrends, priceTrends: priceTrends },
      () => {
        this.componentDidMount(priceTrends);
      }
    );
  };

  moveCard = (dragIndex, hoverIndex) => {
    const dragCard = this.state.cards[dragIndex];
    this.state.cards.splice(dragIndex, 1);
    this.state.cards.splice(hoverIndex, 0, dragCard);

    this.setState({ cards: this.state.cards });
    const userstoreid = this.state.storeid;
    let removeCard = this.state.cards;
    removeCard.shift();
    this.state.cards.unshift("Add New");
    let reorderid = removeCard;
    let arr = [];

    reorderid.forEach(function (e) {
      arr.push(e.searchid);
    });

    let searchorder = arr.toString();
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreId: userstoreid,
      OrderList: searchorder,
      RegionId: CONFIG.RegionId,
    };
    // axios.post(CONFIG.API_URL + 'Market/unitorder/' + userstoreid + '/' + searchorder)
    axios
      .post(CONFIG.API_URL + "Market/unitorder/", data)
      .then((res) => {
        //  console.log(res)
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          // alert(err.response.data);
        }
      });
  };
  handler() {
    this.componentDidMount();
  }
  downLoadPDF() {
    this.setState({
      // isLoading: true,
      pdfLoading: false,
    });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = this.state.priceTrendsAvg ? this.state.priceTrendsAvg : 2;
    let usId = this.props.match.params.storeid;
    let accessKey = localStorage.getItem("SecretKey");
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "MarketDashboard" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.storeAddress.storeName &&
      this.state.storeAddress.storeName !== null &&
      this.state.storeAddress.storeName !== "" &&
      this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "MarketDashboard" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMsg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 1;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });               
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);       
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );          
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);             
        this.setState({isLoading: false });  
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  // TO Download Activity Report PDF
  downLoadActivityReportPDF() {
    this.setState({
      // isLoading: true,
      pdfLoading: false,
    });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let usId = this.props.match.params.storeid;
    let accessKey = localStorage.getItem("SecretKey");
    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "ActivityReport" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.storeAddress.storeName &&
      this.state.storeAddress.storeName !== null &&
      this.state.storeAddress.storeName !== "" &&
      this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "ActivityReport" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMsg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    const Input = {
      RegionID: CONFIG.RegionId,
      URL: CONFIG.OPTZ_URL + "render-activity-pdf/" + accessKey + "/" + usId,
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
       
        const blob = new Blob([response.data], { type: 'application/octet-stream' });               
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);       
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );          
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);             
        this.setState({isLoading: false });   
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  marketDashboard() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  // Call RabitQueue
  submitRabitQueue(storeId) {
    this.setState({ isLoading: true, downLoadReportModal: false });
    axios
      .post(CONFIG.API_URL + "market/reportqueue/" + storeId)
      .then((response) => {
        if (response.data === "success") {
          this.setState(
            {
              snackOpen: true,
              snackMsg:
                "Your Competitor Report is being generated. This might take a couple minutes. When the report is ready it will download automatically when you click the report icon again.",
            },
            () => {
              this.componentDidMount();
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          this.setState({
            snackOpen: true,
            snackMsg:
              "Report generation is in progress, please try after sometime.",
          });
        }
      });
  }

  //Excel report Download
  downLoadReport(rep_id, filename) {
    this.setState({ isLoading: true, downLoadReportModal: false });
    axios({
      url:
        CONFIG.API_URL +
        "market/downloadreport/" +
        rep_id +
        "/" +
        CONFIG.RegionId,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename.replace("<br />", "") + ".xlsx";        
        link.click();   
        this.setState({ isLoading: false });
        
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", filename.replace("<br />", "") + ".xlsx");
        // document.body.appendChild(link);
        // link.click();
        // this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          this.setState({
            snackOpen: true,
            snackMsg:
              "Report generation is in progress, please try after sometime.",
          });
        }
      });
  }

  handleDownload = (stoName) => {
    var fileName =
      stoName + "_" + moment(this.state.currentDate).format("DD-MMM-YYYY");
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMsg:
        "Your download is being processed. The " +
        fileName +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let Input = {
      UserStoreId: this.state.storeid,
      RegionId: CONFIG.RegionId,
      Currency: this.state.storeAddress.countryCurrency,
    };
    axios({
      url: CONFIG.API_URL + "market/downloadsearchdata",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName.replace("<br />", "") + ".xlsx";        
        link.click();   
        this.setState({ isLoading: false });        
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          this.setState({
            snackOpen: true,
            snackMsg:
              "Report generation is in progress, please try after sometime.",
          });
        }
      });
  };

  compReportDownload = (stoName) => {
    var fileName = stoName;
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMsg:
        "Your download is being processed. The " +
        fileName +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    axios({
      url: CONFIG.API_URL + "market/reportqueue/" + this.state.storeid,
      method: "post",
      responseType: "blob",
    })
      .then((response) => {
    
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName.replace("<br />", "") + ".xlsx";        
        link.click();   
        this.setState({ isLoading: false });         
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          this.setState({
            snackOpen: true,
            snackMsg:
              "Report generation is in progress, please try after sometime.",
          });
        }
      });
  };

  compReportDownloadCsv = (stoName) => {
    var fileName = stoName;
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMsg:
        "Your download is being processed. The " +
        fileName +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });

    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    axios({
      url: CONFIG.API_URL + "market/rawdataqueue/" + this.state.storeid,
      method: "post",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName.replace("<br />", "") + ".csv";        
        link.click();   
        this.setState({ isLoading: false });         
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          this.setState({
            snackOpen: true,
            snackMsg:
              "Report generation is in progress, please try after sometime.",
          });
        }
      });
  };

  render() {
    const {
      cards,
      storeInformation,
      pdfLoading,
      isLoading,
      countryCode,
      storeid,
      storedata,
      storeAddress,
      priceTrendsAvg,
      snackOpen,
      snackMsg,
      myMarketData,
    } = this.state;
    const defaultProps = {
      helpTitle: "Market Overview",
      helpContent:
        "<p>By default, this dashboard shows 12 of the most common unit types. Each 'box' gives you a quick snapshot of the rates for this Market. Below is a breakdown of what's in each snapshot:</p><h6>Offered by x stores</h6><p>This is the number of stores advertising this unit type in this Market</p>" +
        `${
          CONFIG.RegionId === 3
            ? "<h6>Average Market Weekly Price</h6>"
            : "<h6>Average Market Price</h6>"
        }` +
        "<p>This is the market average rate for this unit type. The average rates shown here are calculated using the " +
        `${
          CONFIG.RegionId === 3
            ? "Advertised Online Weekly rates"
            : "Advertised Online rates"
        }` +
        "</p><h6>Trend</h6><p>The upward/downward arrow shows the direction of the trend for this unit type's rates over the period selected (5 days, 30 days, 90 days)</p>" +
        `${
          CONFIG.RegionId === 3
            ? "<h6>Your Weekly Price</h6>"
            : "<h6>Your Price</h6>"
        }` +
        "<p>If you have set up your store as your Market, your store's rate(s) will appear here for the unit type. If we have not found a rate for your store, it will be shown as 'N/A'</p><h6>View Details</h6><p>To view the detailed pricing insights for the unit type, click on View Details</p><h6>+Add New</h6><p>You are not limited to the 12 unit types you see here. Click +Add New to configure your own unit size and features</p><h6>Edit</h6><p>Click the pencil icon to make any changes to the features for that unit type</p><h6>Delete</h6><p>Click the x to delete this unit type from the dashboard</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/4mN1tA5gjtk' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    // console.log(storeAddress, 'checkstoreaddres')
    return (
      <div className="my-markets-dashboard">
        <div id="r2"></div>
        <Breadcrumbs
          separator="›"
          aria-label="Breadcrumb"
          className="breadcrumb-float-left breadcrumb-top"
        >
         
          {localStorage.getItem("isMVP_User") === "false" ? 
            <Link
              to="/my-market/"
              onClick={() => this.refreshMenu()}
              color="inherit"
            >
              My Market
            </Link>
           : 
           <a href={CONFIG.MVP_URL+`${localStorage.getItem('SecretKey')}`} >Explorer Dashboard</a>
          //  <a href={CONFIG.MVP_URL+`${localStorage.getItem('SecretKey')}/${localStorage.getItem('mvpUserStoreId')}/${localStorage.getItem('OptStoreId')}`} >Explorer Dashboard</a>
          }

         
          <Typography component="h6" className="link-tag">
            Market Dashboard
          </Typography>
        </Breadcrumbs>
        {/* <button onClick={() => this.handleDownload(storeAddress.storeName.replace("<br />", ""))} className="mb-2 apply-btn download-pdf  btn btn-secondary ml-0 mr-0 all-data-excel" style={{border: "1px solid #F15D47",marginLeft: "10px",}}>
                   <FontAwesomeIcon icon={faFileExcel} /> Download All Data
                </button> */}
        {/* <Button
          disabled={!isLoading && cards.length > 1 ? false : true}
          className={
            cards.length > 1
              ? "mb-2 apply-btn download-pdf "
              : "mb-2 download-pdf-disabled"
          }
          onClick={this.downLoadPDF.bind(this)}
        >
          <FontAwesomeIcon icon={faFilePdf} />
          PDF Download
        </Button> */}
        <div className="clearfix"> </div>
        <div className="market-Dashboard-Heading">
          <Row className="market-Heading">
            <Col xs={12} md={3}>
              <h3 className="pl-0">
                Market Dashboard <HelpTextPopup parm={defaultProps} />
              </h3>
            </Col>
            {isLoading === false || pdfLoading === false ? (
              <Col xs={12} md={5} className="address-list">
                <h5>
                  {storeAddress.storeName &&
                  storeAddress.storeName !== null &&
                  storeAddress.storeName !== ""
                    ? storeAddress.storeName
                    : isLoading === false
                    ? "Your Store"
                    : ""}
                </h5>
                <p>
                  {storeAddress.storeAddress1 &&
                  storeAddress.storeAddress1 !== null &&
                  storeAddress.storeAddress1 !== "" &&
                  storeAddress.storeAddress1 !== undefined
                    ? storeAddress.storeAddress1 + ","
                    : isLoading === false
                    ? ""
                    : ""}{" "}
                  {storeAddress.storeAddress2
                    ? storeAddress.storeAddress2 + ","
                    : ""}{" "}
                  {storeAddress.storeCity
                    ? storeAddress.storeCity + ", "
                    : storeAddress.searchType === 1 &&
                      (storeAddress.storeCity === "" ||
                        storeAddress.storeCity === null) &&
                      (storeAddress.storeZipCode === "" ||
                        storeAddress.storeZipCode === null)
                    ? storeAddress.latitude +
                      ", " +
                      storeAddress.longitude +
                      ", "
                    : isLoading === false
                    ? ""
                    : ""}
                  {storeAddress.storeState
                    ? storeAddress.storeState + ""
                    : isLoading === false
                    ? ""
                    : ""}{" "}
                  {storeAddress.storeZipCode
                    ? storeAddress.storeZipCode + ""
                    : isLoading === false
                    ? ""
                    : ""}
                </p>
                <label>Coverage: </label>
                <span>
                  {storeAddress.storeZoneCoverage
                    ? storeAddress.storeZoneCoverage
                    : isLoading === false
                    ? "N/A"
                    : ""}
                </span>
              </Col>
            ) : (
              ""
            )}

            <Col md={4} className="padding-0">
              <div className="price-wrapper">
                <div className="show-price">
                  <label className="show">Show Price Trend for</label>
                </div>
                <div className="show-price pl-2">
                  <AvForm>
                    <AvField
                      value={this.state.priceTrends}
                      type="select"
                      onChange={this.showPriceTrends.bind(this)}
                      name="showPrice"
                    >
                      <option value="1"> 5 days </option>
                      <option value="2"> 30 days </option>
                      <option value="3"> 90 days </option>
                      {/* <option value="4"> 6 Months </option>
                      <option value="5"> 12 Months </option> */}
                    </AvField>
                  </AvForm>
                </div>
                <div>
                  <Dropdown
                    className="float-right download-reports-dropdown"
                    direction="down"
                    size="sm"
                    isOpen={this.state.dropdownOpen}
                    toggle={this.setToggle}
                  >
                    <DropdownToggle caret>Download Reports</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() =>
                          this.handleDownload(
                            storeAddress.storeName.replace("<br />", "")
                          )
                        }
                      >
                        <img src={ExcelDownload} alt="" /> Dashboard Data
                      </DropdownItem>
                      <DropdownItem
                        disabled={!isLoading && cards.length > 1 ? false : true}
                        onClick={this.downLoadPDF.bind(this)}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          className="remove-widgets"
                          data-html2canvas-ignore
                        >
                          {" "}
                          <IconButton aria-label="export">
                            <PictureAsPdfIcon />
                          </IconButton>
                        </span>
                        Dashboard View
                      </DropdownItem>
                      {
                        // storeAddress.competitorStoreID !== null &&
                        //   storeAddress.competitorStoreID !== 0 &&
                        storeAddress.compsCount !== null &&
                        storeAddress.compsCount > 0 ? (
                          <>
                          <DropdownItem
                            onClick={() =>
                              this.compReportDownload(
                                storeAddress.storeName.replace("<br />", "")
                              )
                            }
                          >
                            <img src={ExcelDownload} alt="" /> Competitor Report - Excel
                          </DropdownItem>
                          <DropdownItem
                          onClick={() =>
                            this.compReportDownloadCsv(
                              storeAddress.storeName.replace("<br />", "")
                            )
                          }
                        >
                          <img src={CsvDownload} alt="" style={{marginRight: '3px'}}/> Competitor Report - CSV
                        </DropdownItem>
                        </>
                        ) : (
                          ""
                        )
                      }
                      <DropdownItem
                        onClick={this.downLoadActivityReportPDF.bind(this)}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          className="remove-widgets"
                          data-html2canvas-ignore
                        >
                          {" "}
                          <IconButton aria-label="export">
                            <PictureAsPdfIcon />
                          </IconButton>
                        </span>{" "}
                        Market Activity Report
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div>
                {/* {this.state.storeAddress.compsCount >0 && this.state.marketDownloadData.length > 0 ?  */}

                {/* <Workbook
                  filename={this.state.fileName + ".xlsx"}
                  element={
                    this.state.storeAddress.compsCount > 0 &&
                      this.state.marketDownloadData.length > 0 ? (
                        <button
                          className="download-excel"
                          style={{
                            border: "1px solid #F15D47",
                            marginLeft: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} />
                          Download All Data
                        </button>
                      ) : (
                        <button
                          disabled="true"
                          className="no-download-excel"
                          style={{
                            border: "1px solid #F15D47",
                            marginLeft: "10px",
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} />
                          Download All Data
                        </button>
                      )
                  }
                > */}
                {/* <Workbook.Sheet
                    data={this.state.downloadInput}
                    name="sheet1"
                  >
                    <Workbook.Column label="SEARCH NAME" value="SEARCHNAME" />
                    <Workbook.Column
                      label="COMPETITOR NAME"
                      value="STORENAME"
                    />
                    <Workbook.Column label="ADDRESS" value="ADDRESS" /> */}
                {/* <Workbook.Column label="STATE" value="STATE" /> Already commented */}
                {/* <Workbook.Column label="PROXIMITY" value="PROXIMITY" />
                    <Workbook.Column label="SIZE" value="SIZE" />
                    <Workbook.Column label="FLOOR" value="FLOOR" />
                    <Workbook.Column label="FEATURES" value="FEATURES" />
                    <Workbook.Column
                      label="ADVERTISED ONLINE RATE"
                      value="ADVERTISED ONLINE RATE"
                    />
                    <Workbook.Column
                      label="LAST CHANGED"
                      value="ONLINE RATE LAST CHANGED"
                    />
                    <Workbook.Column
                      label="ADVERTISED WALK-IN RATE"
                      value="ADVERTISED WALK-IN RATE"
                    />
                    <Workbook.Column
                      label="LAST CHANGED"
                      value="WALK-IN RATE LAST CHANGED"
                    />
                    <Workbook.Column
                      label="ADVERTISED PROMOTION"
                      value="ADVERTISED PROMOTION"
                    />
                    <Workbook.Column
                      label="LAST CHANGED"
                      value="PROMOTION LAST CHANGED"
                    />
                  </Workbook.Sheet>
                </Workbook> */}

                {/* <Button onClick={()=> this.downloadExcel()} className="download-excel" style={{border: '1px solid #F15D47', marginRight: '35px'}}>
          <FontAwesomeIcon icon={faFileExcel} />Download Excel
          </Button> */}
                {/* {
                  // Checking Competitor Available (category.competitorStoreID) and Competitor belongs to "US"(category.displayReport)
                  storeAddress.competitorStoreID !== null &&
                    storeAddress.competitorStoreID !== 0 &&
                    storeAddress.compsCount !== null &&
                    storeAddress.compsCount > 0 ? (
                      storeAddress.zoneCoverage === null ? ( // Request to download CompReport if category.zoneCoverage is available
                        <button
                          className="download-excel download-comp-report-btn"
                          title="Request For Report"
                          onClick={this.marketDashboard.bind(this)}
                        >
                          <span className="chk-aval-xcel excel-icon-right"></span>
                          Download Comp Report</button>
                      ) : storeAddress.reportStatus === null ||
                        storeAddress.reportStatus === 0 ? ( // Request to download CompReport
                            <button
                              className="download-excel download-comp-report-btn"
                              title="Request For Report"
                              onClick={() =>
                                this.submitRabitQueue(this.state.storeid)}
                            >
                              <span className="chk-aval-xcel excel-icon-right"></span>
                              Download Comp Report</button>
                          ) : storeAddress.reportStatus === 1 ? ( // Compreport in progress
                            <button
                              className="download-excel download-comp-report-btn"
                              title="Report generation is in progress."
                            >
                              <span className="loader-xcel excel-icon-right"></span>
                              Download Comp Report</button>
                          ) : storeAddress.reportStatus === 3 ? ( // Able to download Compreport
                            <button
                              className="download-excel download-comp-report-btn"
                              title="Download Here"
                              onClick={() =>
                                this.downLoadReport(
                                  storeAddress.reportID,
                                  storeAddress.storeName.replace("<br />", "")
                                )
                              }
                            >
                              <span className="available-xcel excel-icon-right"></span>
                              Download Comp Report</button>
                          ) : storeAddress.reportStatus === 4 ? ( // Unable to Download Compreport
                            <button
                              className="download-excel download-comp-report-btn cur-not-allow"
                              title="Unable to generate report for this market."
                            >
                              <span className="unavailable-xcel excel-icon-right"></span>
                              Download Comp Report</button>
                          ) : storeAddress.reportStatus === 5 ? ( // Un Available price
                            <button
                              className="download-excel download-comp-report-btn"
                              title="Pricing data is not available for your store and comp report cannot be generated for this market."
                            >
                              <span className="unavailable-xcel excel-icon-right"></span>
                              Download Comp Report</button>
                          ) : (
                                    ""
                                  )
                    ) : (
                      ""
                    )
                } */}
                {/* <button onClick={this.downLoadActivityReportPDF.bind(this)} className="download-excel" style={{border: "1px solid #F15D47",marginRight: "10px",}}>
                   <FontAwesomeIcon icon={faFilePdf} /> Download Activity Report
                </button>  */}
              </div>
            </Col>
          </Row>
        </div>
        {/* <MarketDashboardHeader  parentMethod={this.handler}  />  */}

        <div className="mrket-Dashboard">
          {isLoading ? (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
          <Row className="custom-margin">
            <div className="market-Card">
              <Row className="custom-fix-row">
                {cards.map((card, i) => (
                  <div className="col-lg-3 col-md-4">
                    <Card
                      key={card.searchid}
                      index={i}
                      id={card.searchid ? card.searchid : "N/A"}
                      storeid={storeid}
                      name={card.name ? card.name : "N/A"}
                      storeCount={card.storeCount ? card.storeCount : "No"}
                      currency={storeInformation}
                      countryCode={countryCode}
                      avgPrice={card.avgPrice ? card.avgPrice : "N/A"}
                      priceTrend={card.priceTrend}
                      myStorePrice={
                        card.myStorePrice ? card.myStorePrice : "N/A"
                      }
                      moveCard={this.moveCard}
                      parentMethod={this.handler}
                      StoreName={storedata.storeName}
                      StoreAddress={storedata.storeAddress1}
                      StoreCity={storedata.storeCity}
                      StoreState={storedata.storeState}
                      StoreZipcode={storedata.storeZipCode}
                      StoreRadius={storedata.storeZoneCoverage}
                      noOfStores={storedata.noOfStores}
                      StoreSearchId={card.searchid}
                      StoreId={this.props.match.params.storeid}
                      priceTrends1={priceTrendsAvg ? priceTrendsAvg : "2"}
                    />
                  </div>
                ))}
              </Row>
            </div>
          </Row>
        </div>
        <div>
          <TrackModalPop isOpen={this.state.modal}>
            <ModalBody>
              Before you can view your competitors pricing and promotions, you
              must define the geographical coverage zone for this market.{" "}
            </ModalBody>
            <ModalFooter className="close-main-btn">
              <Button
                color="secondary"
                onClick={this.marketDashboard.bind(this)}
              >
                Close
              </Button>
            </ModalFooter>
          </TrackModalPop>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose.bind(this)}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose.bind(this)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackMsg}</span>}
        />
      </div>
    );
  }
}
export default DragDropContext(HTML5Backend)(MarketDashboard);
