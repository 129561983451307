import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import axios from 'axios';
import {HelpTextPopup} from './../HelpTextPopup/index';
import { CONFIG } from '../../utils/config';
import classnames from 'classnames';
import { CurrentPricing } from './../CurrentPricing';
import { MarketInventoryAnalysis } from './../MarketInventoryAnalysis';
import { DetailedStoresView } from './../DetailedStoresView';
import redimage from './../../Assets/Images/red.png';
import { MapViews, MapViewsGmap } from '../MapViews';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { EffectiveRates } from '../EffectiveRates';


const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };


  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    
    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket,
);

export default class ViewDetails extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      locationInfoData: [],
      analysisSummary: [],
      trackModalData: '',
      storeModal: false,
      pageCount: 0,
      storeCount: 0,
      isLoading: false,
      pageNavigate:'',
      centerPoint:[]
    };
    this.trackModal = this.trackModal.bind(this);
    this.trackModalClose = this.trackModalClose.bind(this);
    this.refreshMenu = this.refreshMenu.bind(this);
  }

  refreshMenu() {
    localStorage.setItem('refreshMenu', "show-menu");
    var isElement = document.getElementById('r2');
    if (isElement == null) {
      document.getElementById('r1').click();
    }
    else {
      document.getElementById('r1').click();
      document.getElementById('r2').click();
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }
  loadDashBoard() {
    let token = localStorage.getItem('accessKey');
    const data = {
      "UserStoreID": parseInt(this.props.match.params.storeid),
      "SearchID": parseInt(this.props.match.params.searchid),
      "RegionID":CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'unit/dashboardSearchlocation', data)
      .then(response => {        
        // this.state.isLoading=true;
        const locationInfoData = response.data.data.unitDashboard;
        const centerPoint = response.data.data.CenterPoints;
        localStorage.setItem('locSearchName', locationInfoData.searchName);
        this.setState({
          locationInfoData: locationInfoData, storeCount : locationInfoData.compsCount
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  loadSummary() {
    let token = localStorage.getItem('accessKey');
    const data = {
      "UserStoreID": this.props.match.params.storeid,
      "SearchId": this.props.match.params.searchid,
      "RegionId" : CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/currentpricinganalysissummary', data)
      .then(response => {
        var isElement = document.getElementById('r2');
        if (isElement == null) {
            document.getElementById('r1').click();
        }
        else {
            document.getElementById('r1').click();
            document.getElementById('r2').click();
        }
        // this.state.isLoading=true;
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });

      
  }

  componentDidMount() {
    
    let activeTab = this.props.match.params.tab === "2" ? '2': '1';
    let pageNavigate = this.props.match.params.tab;
    this.setState({ 
      isLoading: true, activeTab, pageNavigate
     })
    // let storeCount = this.props.match.params.storecount;
    // this.setState({ storeCount })
    this.loadDashBoard();
    this.loadSummary();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.searchid !== this.props.match.params.searchid) {
      this.setState({ 
        isLoading: true
       })
      this.props.match.params.storeid = nextProps.match.params.storeid;
      this.props.match.params.searchid = nextProps.match.params.searchid;
      // let storeCount = nextProps.match.params.storecount;
      // this.setState({ storeCount })
      this.loadDashBoard();
      this.loadSummary();

    //  window.location.reload();
    }
  }
  trackModal() {
    let UserStoreID = this.props.match.params.storeid;
    let SearchId = this.props.match.params.searchid;
    const data={
      "UserStoreId":UserStoreID,
      "SearchId":SearchId,
      "RegionId":CONFIG.RegionId
    }
    axios.post(CONFIG.API_URL + 'unit/competitorslist' ,data)
      .then(response => {
        
        // this.state.isLoading=true;
        const trackModalData = response.data.competitor.unitCompData;

        this.setState({ trackModalData, storeModal: true, })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }
  trackModalClose() {
    this.setState({
      storeModal: false,
      pageCount: 0
    })
  }
  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };
  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };
  
  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    const { isLoading, locationInfoData, analysisSummary, activeTab, trackModalData, pageCount, storeCount, centerPoint } = this.state;
    const defaultProps = {
      helpTitle: 'Current Pricing Analysis',
      helpContent: "<p>This is a detailed view of the rates for this Unit Type</p><h6>Offered by x stores</h6><p>The number of stores in this Market advertising this unit type</p> " + `${CONFIG.RegionId === 3 ? "<h6> Current Lowest Weekly Price </h6> ":"<h6> Current Lowest Price </h6>"}` + "<p>This is the lowest price currently advertised for this unit type in this Market and the store advertising it. The lowest price shown here is the lowest of the "+ `${CONFIG.RegionId === 3 ? "Advertised Online Weekly Rate" : "Advertised Online Rate"}`+"</p>"+`${CONFIG.RegionId === 3 ? "<h6>Current Average Weekly Price</h6>":"<h6>Current Average Price</h6>"}`+"<p>This is the average price for this unit type in this Market. The average rate is the average of all "+`${CONFIG.RegionId === 3 ? "Advertised Online Weekly Rates":"Advertised Online Rates"}`+"</p>"+`${CONFIG.RegionId === 3 ? "<h6>Current Highest Weekly Price</h6>":"<h6>Current Highest Price</h6>"}`+"<p>This is the highest price currently advertised for this unit type in this Market and the store advertising it. The highest price shown here is the highest "+`${CONFIG.RegionId === 3 ? "Advertised Online Weekly Rate":"Advertised Online Rate"}`+"</p><p><i>*Your store prices are included in the calculations.</i></p>"
      }
    //  let outputInventory = <MarketInventoryAnalysis storeId={parseInt(this.props.match.params.storeid)}></MarketInventoryAnalysis>;
    let cutCur = locationInfoData.countryCurrency ? locationInfoData.countryCurrency : 'N/A';
    let BreadcrumbTab = this.state.activeTab === '1' ? 'Current Pricing Analysis' : this.state.activeTab === '2' ? 'Inventory & Availability Analysis' : this.state.activeTab === '3' ? 'Detailed View of Stores' : this.state.activeTab === '4' ? 'Map View' : this.state.activeTab === '5' ? 'Effective Rate Analysis' : 'Current tab';
    let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if(clp){
      var splt_clp = clp.toString().split('.');
      let splt_val_clp = splt_clp[1] ? splt_clp[1].length === 1 ? '.'+splt_clp[1]+'0' : splt_clp[1].length === 2 ? '.'+splt_clp[1] : '' : '';
      clp = cutCur+ splt_clp[0]+splt_val_clp;
    }
    if(cap){
      var splt_cap = cap.toString().split('.');
      let splt_val_cap = splt_cap[1] ? splt_cap[1].length === 1 ? '.'+splt_cap[1]+"0" : splt_cap[1].length === 2 ? '.'+splt_cap[1] : '' : '';
      cap = cutCur+ splt_cap[0]+splt_val_cap;
    }
    if(chp){
      var splt_chp = chp.toString().split('.');
      let splt_val_chp = splt_chp[1] ? splt_chp[1].length === 1 ? '.'+splt_chp[1]+'0' : splt_chp[1].length === 2 ? '.'+splt_chp[1] : '' : '';
      chp = cutCur+ splt_chp[0]+splt_val_chp;
    }
    return (
      <div className="wrapper my-account-box view-details">
        <div id="r2"></div>
        {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
        <Breadcrumbs aria-label="Breadcrumb" className="breadcrumb-float-left breadcrumb-top">
        

          {localStorage.getItem('isMVP_User') === "false" ? <Link to="/my-market/" onClick={() => this.refreshMenu()} color="inherit">My Market</Link> : ""}
         
          <Link to={{ pathname: `/market-dashboard/${this.props.match.params.storeid}`}} color="inherit" >Go to Market Dashboard</Link>
         
          {this.state.pageNavigate === '3' || this.state.pageNavigate === '2' ?<Link to={{ pathname: `/market-level-data/alert/${this.props.match.params.storeid}` }} color="inherit" className="link-tag">{'Market Alerts'}</Link>:''}
          <Typography component="h6" className="link-tag">({locationInfoData ? locationInfoData.searchName : 'Market Detail'}) {BreadcrumbTab}</Typography>
        </Breadcrumbs>
       
        <div className="clearfix"> </div>
        <Row className="overview-header mt-2">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row className="ht-100">
                <Col sm="6" className="padding-right-8">
                  <h2>{locationInfoData ? locationInfoData.searchName : 'N/A'}  <HelpTextPopup parm={defaultProps} /></h2>
                  {isLoading ? '' : parseInt(storeCount) !== 0 ?
                    <div className="align-stores-btm"><p>Currently offered by <Link onClick={() => this.trackModal()} to="#">{storeCount ? parseInt(storeCount) > 1 ? storeCount + ' stores' : storeCount + ' store' : 'N/A'} </Link></p></div>
                    : <div className="align-stores-btm"><p>Currently offered by no store</p></div>}
                </Col>
                {isLoading===false ?
                <Col sm="6" className="pl-0">
                  <h3>{locationInfoData && locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' && locationInfoData.storeName !== undefined ? locationInfoData.storeName : 'Your Store'}</h3>
                  <div className={locationInfoData && locationInfoData.storeZoneCoverage ?  locationInfoData.storeZoneCoverage.length > 24 ? "pd-btm-35" : "pd-btm-20" : "pd-btm-20"}><p className="m-0">{locationInfoData && locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 +',' : ''} {locationInfoData.storeAddress2 ? locationInfoData.storeAddress2 + ', ' : ''} {locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity+', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ' : ''}{locationInfoData.storeState ? locationInfoData.storeState : ''} {locationInfoData ? locationInfoData.storeZipCode : 'N/A'}</p></div>
                  <div className="align-stores-btm"><p className="m-0 pt-1">Coverage: {locationInfoData ? locationInfoData.storeZoneCoverage : 'N/A'}</p></div>
                </Col>:''}
              </Row>
            </Col>
          </Col>
          <Col md="7" className="padding-left-0 view-top-right custom-md-6">
            <Col md="12" className="border-box shadow-sm rounded">
              <Row className="height-full">
                <Col xs="6" sm="6" md="4" className="sep-box">
                  <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Lowest Weekly Price" : "Current Lowest Price"}</h4>
                  <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.lowestPriceStoreName : isLoading===false ? 'N/A':''}</p></div>
                  <div className="align-stores-btm"><h5>{clp ? clp : isLoading===false ? 'N/A':''} </h5></div>
                </Col>
                <Col xs="6" sm="6" md="4" className="sep-box">
                  <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Average Weekly Price" : "Current Average Price"}</h4>
                  <div className="pd-btm-20"><p></p></div>
                  <div className="align-stores-btm"><h5>{cap ? cap : isLoading===false ? 'N/A':''} </h5></div>
                </Col>
                <Col xs="6" sm="6" md="4" className="sep-box">
                  <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Highest Weekly Price" : "Current Highest Price"}</h4>
                  <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.highestPriceStoreName : isLoading===false ? 'N/A':''}</p></div>
                  <div className="align-stores-btm"><h5>{chp ? chp : isLoading===false ? 'N/A':''} </h5></div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <div className="mt-3 marketing-act">
          <Col md="12" className="p-v-15 border-box shadow-sm rounded">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Current Pricing Analysis
                </NavLink>
              </NavItem>
             {CONFIG.RegionId === 3 ? '' :<> <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  Effective Rate Analysis
                </NavLink>
              </NavItem></>}
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Inventory & Availability Analysis
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  Detailed View of Stores
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  Map View
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col md="12">
                    {activeTab === '1' ? <CurrentPricing storeDetails = {locationInfoData ? locationInfoData : ''} currency={locationInfoData ? locationInfoData.countryCurrency : ''} searchId={parseInt(this.props.match.params.searchid)} storeId={parseInt(this.props.match.params.storeid)}></CurrentPricing> : ''}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col md="12">
                    {activeTab === '5' ? <EffectiveRates storeDetails = {locationInfoData ? locationInfoData : ''} currency={locationInfoData ? locationInfoData.countryCurrency : ''} searchId={parseInt(this.props.match.params.searchid)} storeId={parseInt(this.props.match.params.storeid)}></EffectiveRates> : ''}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">

                    {activeTab === '2' ? <MarketInventoryAnalysis storeDetails = {locationInfoData ? locationInfoData : ''} storeId={parseInt(this.props.match.params.storeid)} searchId={this.props.match.params.searchid}></MarketInventoryAnalysis> : ''}
                    {/* <MarketInventoryAnalysis storeId={parseInt(this.props.match.params.storeid)}></MarketInventoryAnalysis> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12" className="detailed-tab">

                    {activeTab === '3' ? <DetailedStoresView searchId={this.props.match.params.searchid} storeId={parseInt(this.props.match.params.storeid)} unitTypesName= { locationInfoData && locationInfoData.searchName ? locationInfoData.searchName : '' }curr={locationInfoData.countryCurrency}></DetailedStoresView> : ''}
                    {/* <DetailedStoresView /> */}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4" className="mapview-tab">
                <Row>
                  <Col sm="12">
                    {activeTab === '4' ? 
                      CONFIG.IsMapbox ? <MapViews storeDetails = {locationInfoData ? locationInfoData : ''} abc={this.props.match.params.storeid} bca={this.props.match.params.searchid} curr={locationInfoData && locationInfoData.countryCurrency ? locationInfoData.countryCurrency : ''} location ={locationInfoData} centerPoint ={centerPoint} />
                      : <MapViewsGmap storeDetails = {locationInfoData ? locationInfoData : ''} abc={this.props.match.params.storeid} bca={this.props.match.params.searchid} curr={locationInfoData && locationInfoData.countryCurrency ? locationInfoData.countryCurrency : ''} location ={locationInfoData} centerPoint ={centerPoint} /> : ''}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </div>
        <div>
          <Modal isOpen={this.state.storeModal} 
          // toggle={() => this.trackModal()} 
          className="store-mainpopup"
          backdrop="static">
            <ModalHeader toggle={this.trackModalClose}>{locationInfoData ? locationInfoData.storeName : 'N/A'}</ModalHeader>
            <ModalBody>
              <Table className="table custom-table table-bordered store-count-popup">
                <TableHead>
                  <TableRow>
                    <TableCell>COMPETITOR NAME</TableCell>
                    <TableCell >ADDRESS</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {trackModalData ? trackModalData.slice(pageCount * 10, pageCount * 10 + 10).map((trackData) =>
                    <TableRow key={trackData.storeid}>
                      <TableCell>{trackData.storename}</TableCell>
                      <TableCell>{trackData.address}</TableCell>

                    </TableRow>
                  ) :
                    <TableRow></TableRow>
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1]}
                      colSpan={3}
                      count={trackModalData ? trackModalData.length : 0}
                      rowsPerPage={10}
                      page={pageCount}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={this.handleChangePageTrack}
                      onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                      ActionsComponent={MyMarketWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </ModalBody>

          </Modal>
        </div>
      </div>
    );
  }

}