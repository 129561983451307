import React, { Component } from "react";
import "./CurrentPricingPdf.css";
import { Row, Col, Button, Nav, NavItem, NavLink } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Typography from "@material-ui/core/Typography";
import { Table as ReactTable } from "reactstrap";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HelpTextPopup } from "../../HelpTextPopup/index";
import * as d3 from "d3";
import axios from "axios";
import { CONFIG } from "../../../utils/config";
import addtomap from "../../../Assets/Images/plus.png";
import removefrommap from "../../../Assets/Images/trash.png";
import tableplus from "../../../Assets/Images/table-plus.png";
import tableminus from "../../../Assets/Images/table-minus.png";
import downarrows from "../../../Assets/Images/down-arrow.png";
import Tooltip from "@material-ui/core/Tooltip";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import Logo from "../../../Assets/Images/logo.jpg";
import Table from "@material-ui/core/Table";
import { TableBody } from "@material-ui/core";
import Chart from "react-apexcharts";
import duplicate from '../../../Assets/Images/duplicate.png'

export default class CurrentPricingPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {

      advertisedRatesHistoryOptions: {
        chart: {
          id: 'average-price-history-chart',
          type: "area",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        xaxis: {

          tickPlacement: 'on',
          tickAmount: 8,
          labels: {
            show: false,
            datetimeUTC: false,

            style: {
              colors: '#8C9AA6',
              fontSize: '12px'
            },
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },

        yaxis: {
          show: false,
          style: {
            colors: '#8C9AA6',
            fontSize: '12px'
          },
        },

        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: '11px',
          fontWeight: 500,
          showForSingleSeries: true,
          showForNullSeries: false,
          showForZeroSeries: false,
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
          tooltip: {
          enabled: false
        },
      },

      advertisedRatesHistorySeries: [],

      options: {
        chart: {
          id: 'CurrentPricing_PDF',
          toolbar: {
            show: false,
            tools: {
              download: false,
            }
          },
          animations: {
            enabled: false,
          },
        },
        yaxis: {
          show: false
        },
        xaxis: {
          tooltip: { enabled: false },
          categories: [],
          labels: {
            show: false,
            style: {
              colors: '#8C9AA6',
              fontSize: '12px'
            },
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
          
        },
        tooltip: {
          enabled: false,
          followCursor: false,
          onDatasetHover: {
            highlightDataSeries: false,
          },
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [],

      activeTab: props.isEffectiveRate !== undefined && props.isEffectiveRate === 1 ? 4 : 1,
      drpDownSelValue: props.durFilter !== undefined && props.durFilter ? props.durFilter : 12,
      isLoading1: false,
      order: this.props.order,
      orderBy: this.props.orderby,
      snackOpen: false,
      snackMSg: "",
      unitDurations: [],
      hisactivemenu: this.props.hisactivemenu.toString(),
      hisactivedur: this.props.hisactivedur.toString(),
      gridactivedur: this.props.gridactivedur.toString(),
      uniquestore: "",
      grapghCatcheck: this.props.grapghCatcheck,
      graphOpen: this.props.grapghCatcheck,
      // SinglelahMarkets: {},
      CoreMarketView: [],
      CoreMarketViewtab3: [],
      CoreMarketViewtab2: [],
      ChartTableData: [],
      ChartTableDatatab3: [],
      ChartTableDatatab2: [],
      GridTableValue: {},
      CurrentHistory: [],
      AllMarketdata: [],
      AllCurrentHistory: [],
      MarketViewpricingOptions: [],
      MarketViewpricingOptionstab3: [],
      MarketViewpricingOptionstab2: [],
      isLoading: false,
      AvailDataCheck: 0,
      AvailDataChecktab3: 0,
      AvailDataChecktab2: 0,
      stId: this.props.userstoreid,
      seId: this.props.searchId,
      defaultWidth: 0,
      priceLength: 0,
      pricecolLength: 0,
      priceLengthtab3: 0,
      pricecolLengthtab3: 0,
      priceLengthtab2: 0,
      pricecolLengthtab2: 0,
      Tabloading: false,
      promo_open: false,
      promo_open_id: 0,
      accessKey: "",
      locationInfoData: [],
      uniquestores: [],
      storeIdCount: [],
      countrycurrency: "",
      analysisSummary: [],
      uniCheck: "",
      promoCountReached: false,
      needComparePromos: [],
      annexureFilters: [],
      storeAdvertising: [],
      filterData : [],
    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
    this.onSelecCatGrapgh = this.onSelecCatGrapgh.bind(this);
    this.DurationsdropDown = this.DurationsdropDown.bind(this);
    this.AlignGraphTabData = this.AlignGraphTabData.bind(this);
    this.AlignGraphTabDatatab3 = this.AlignGraphTabDatatab3.bind(this);
    this.AlignGraphTabDatatab2 = this.AlignGraphTabDatatab2.bind(this);
    this.AlignHistoryGraphData = this.AlignHistoryGraphData.bind(this);
    //this.GetSummaryMarketData = this.GetSummaryMarketData.bind(this);
    this.GetGridtableData = this.GetGridtableData.bind(this);
    this.GetGraphTableData = this.GetGraphTableData.bind(this);
    this.GetGraphTableDatatab3 = this.GetGraphTableDatatab3.bind(this);
    this.GetGraphTableDatatab2 = this.GetGraphTableDatatab2.bind(this);
    this.tabtoggle = this.tabtoggle.bind(this);
    this.onDurAndPriChange = this.onDurAndPriChange.bind(this);
    localStorage.setItem("StId", this.props.userstoreid);
  }

  //Duration and Price Type Change
  onDurAndPriChange(e) {
    const { name, value } = e.target;
    let fields = this.state;
    fields[name] = value;
    if (name === "gridactivedur") {
      this.setState({ fields, isLoading1: false }, () =>
        this.GetGridtableData()
      );
    } else if (name === "hisactivedur") {
      this.setState({ fields, isLoading: false });
      // this.setState({ fields, isLoading: false }, () =>
      // //  this.GetcurrentpricinganalysisHistory()
      // );
    } else {
      this.setState({ fields, isLoading1: false }, () =>
        this.AlignHistoryGraphData()
      );
    }
  }

  //Tabs
  tabtoggle() {
    this.GetGraphTableDatatab3();
    this.GetGraphTableDatatab2();
  }

  //Table Sorting
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    let sort = isDesc ? "asc" : "desc";
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }

  //To open Particular Category's Graph
  onSelecCatGrapgh(openActGraph) {
    let activeRow =
      this.state.grapghCatcheck === openActGraph ? "" : openActGraph;
    activeRow === ""
      ? this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow })
      : this.setState(
        { grapghCatcheck: activeRow, graphOpen: activeRow },
        () => {
          this.TableViewGraphs();
        }
      );
  }

  //Grid Actions
  barhandleChange() {
    //loader
    this.setState({ isLoading: false });
    if (this.state.GridTableValue.dynamdata) {
      for (var i = 0; i < this.state.GridTableValue.dynamdata.length; i++) {
        if (
          this.state.storeIdCount.includes(
            this.state.GridTableValue.dynamdata[i].storeID
          )
        ) {
          this.state.GridTableValue.dynamdata[i].isSelected = true; //this.state.MarkScoring[i].isSelected === true ? false : true;
        } else if (this.state.storeIdCount.length > 0) {
          this.state.GridTableValue.dynamdata[i].isSelected = false;
        }
      }

      let GridTableValue = this.state.GridTableValue;
      this.setState({ GridTableValue });
      // this.setState({ GridTableValue }, () => {
      //   this.GetGraphTableData();
      //  // this.GetcurrentpricinganalysisHistory();
      // });
    }
  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  //Formatted in as per competitor
  formData(data) {
    let uniquestore = [...new Set(data.map((item) => item.storeID))];
    let CurrentActivity = this.state.grapghCatcheck;
    let Colorbarcode = [
      "#f69e4f",
      "#85411b",
      "#5b95f6",
      "#f96a5d",
      "#3ea7a1",
      "#83ce83",
      "#fac05f",
      "#959595",
    ];
    var FData = [];

    for (var t = 0; t < uniquestore.length; t++) {
      var tFData = data.filter(
        (a) =>
          a.storeID == uniquestore[t] && a.priceOptionName === CurrentActivity
      );
      var mData = {};
      if (tFData.length > 0) {
        let splitpriceVal = tFData[0].price.split("-");
        let priceVal = splitpriceVal[1]
          ? parseInt(splitpriceVal[0])
          : splitpriceVal[0] !== "0"
            ? parseInt(splitpriceVal[0])
            : 0;
        let p1 =
          CurrentActivity === "Price Range"
            ? splitpriceVal[1]
              ? parseInt(splitpriceVal[1])
              : parseInt(splitpriceVal[0])
            : 0;

        mData = {
          StoreID: uniquestore[t], //this.state.storeIdCount[t],
          StoreName: tFData[0].storeName,
          price: parseInt(priceVal),
          priceOptionName: tFData[0].priceOptionName,
          price1: p1,
          labelPrice: splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0],
          labelPrice2:
            splitpriceVal[1] !== "0" && splitpriceVal[1]
              ? splitpriceVal[1]
              : splitpriceVal[0] === "0"
                ? "N/A"
                : splitpriceVal[0],
          Gcolor: Colorbarcode[t],
          IsSelected: tFData[0].isSelected,
        };
      }

      FData.push(mData);
    }
    return FData;
  }
  //Graph for Table
  TableViewGraphs() {
    let { CoreMarketView, grapghCatcheck,ChartTableData } = this.state;
    let dataPremium = [];
    let dataPremium_labelPrice2 = [];
    let dataPremiumName = [];
    let colorsCode = ['rgba(0, 0, 0, 0.25)'];
    let currency = this.state.countrycurrency
    ? this.state.countrycurrency
    : "";
    let filterMarkets = CoreMarketView.filter(fl => fl.storeID > 0);
    let uniquestore = [...new Set(filterMarkets.map((item) => item.storeID))];
    for (var t = 0; t < uniquestore.length; t++) {
      let tFData = filterMarkets.filter((a) => a.storeID == uniquestore[t] && a.priceOptionName === grapghCatcheck);
      let noValues = tFData.every(item => item.price === null || item.price === '' || item.price === undefined);
      if (!noValues) {
        let splitpriceVal = tFData[0].price.split("-");
        let labelPrice = splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0];
        let labelPrice2 = splitpriceVal[1] !== "0" && splitpriceVal[1] ? splitpriceVal[1] : splitpriceVal[0] === "0" ? "N/A" : splitpriceVal[0];
        let storeName = tFData[0].storeName ? tFData[0].storeName : null;
        dataPremium.push(isNaN(labelPrice) ? null : labelPrice);
        dataPremium_labelPrice2.push(isNaN(labelPrice2) ? null : labelPrice2);
        dataPremiumName.push(storeName);
      }
    }
    let allViewGrid = [{ name: "", data: dataPremium }, { name: "", data: dataPremium_labelPrice2 }];

    if (dataPremium.length > 0) {
      this.setState({
        options: {
          chart: {
            id: `CurrentPricing_PDF${Math.random()}`,
            toolbar: {
              show: false
            },
            animations: {
              enabled: false,
            },
          },
          tooltip:{enabled : false},
          stroke: {
            width: 2,
            curve: 'straight'
          },
          colors: colorsCode,
          legend: { show: false },
          grid : {
            show : false,
            padding: {
              right: 50,
              left: 50
          },  
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value === null ? "N/A" : currency + value.toFixed(2);
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder:{
              show: false,
            },
            axisTicks: {
              show: false,
            }
          },
          xaxis: {
            categories: dataPremiumName,
            labels: {
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value.toUpperCase();
                return (ChartTableData.TableHead.length === 2 || ChartTableData.TableHead.length === 3 ) && val.length > 15 ? val.substring(0, 15) + "..." : val ;
              },
              style: {
                fontSize: '10px',
              },
              hideOverlappingLabels: false,
              trim: ChartTableData.TableHead.length === 2 || ChartTableData.TableHead.length === 3 ? false : true,
              show: true,
              rotate: 0,
            },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          }
          },
          markers: {

            discrete: [{
              seriesIndex: 1,
              dataPointIndex: 0,
              fillColor: "#F69E4F",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 1,
              fillColor: "#1B2C42",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 2,
              fillColor: "#5C95F6",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 3,
              fillColor: "#F3695D",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 4,
              fillColor: "#3FA7A1",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 1,
              dataPointIndex: 5,
              fillColor: "#83CE83",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 1,
              dataPointIndex: 6,
              fillColor: "#FAC05F",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 1,
              dataPointIndex: 7,
              fillColor: "#959595",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 0,
              fillColor: "#F69E4F",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 1,
              fillColor: "#1B2C42",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 2,
              fillColor: "#5C95F6",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 3,
              fillColor: "#F3695D",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 4,
              fillColor: "#3FA7A1",
              strokeColor: "#FFF",
              size: 6
            }, {
              seriesIndex: 0,
              dataPointIndex: 5,
              fillColor: "#83CE83",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 0,
              dataPointIndex: 6,
              fillColor: "#FAC05F",
              strokeColor: "#FFF",
              size: 6
            },
            {
              seriesIndex: 0,
              dataPointIndex: 7,
              fillColor: "#959595",
              strokeColor: "#FFF",
              size: 6
            },
            ]
          },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: allViewGrid
      });
    } else {
      this.setState({
        options: {
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            labels: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
        },
        series: []
      })
    }
  }
  
  //Formatted in as per competitor
  HisformData(data, uid) {
    var tFData = data.filter((a) => a.storeID === uid);
    var tData = [];

    let CurrentActivity = this.state.hisactivemenu === "2" ? "Price Range" : "";
    for (var i = 0; i < tFData.length; i++) {
      // for price range calculation
      let calPrice, price2;
      if (CurrentActivity === "Price Range") {
        let splitpriceVal = tFData[i].price.split("-");
        calPrice = parseInt(splitpriceVal[0]);
        price2 = splitpriceVal[1]
          ? parseInt(splitpriceVal[1])
          : parseInt(splitpriceVal[0]);
      } else {
        calPrice = tFData[i].price;
        price2 = 0;
      }
      // for display label date
      let caldate;
      caldate = moment(tFData[i].datePrice).toDate();
      var CData = {
        date: moment(tFData[i].datePrice).toDate(),
        StoreName: tFData[i].storeName,
        price: parseInt(calPrice),
        price2: parseInt(price2),
        storeID: tFData[i].storeID,
        labeldate: caldate,
        labelPrice: tFData[i].price === "0" ? "N/A" : tFData[i].price,
        orderNo: tFData[i].orderNo,
        priceOptionName: tFData[i].priceOptionName,
      };
      tData.push(CData);
    }
    return tData;
  }

  apexChartNoData = ({ textMessage, colorCode }) => {
    this.setState({
      advertisedRatesHistorySeries: [],
      filterData:[],
      advertisedRatesHistoryOptions: {
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        noData: {
          text: textMessage,
          style: { color: colorCode }
        }
      }
    });
  }

  dropDownData(tFData) {
    var { hisactivemenu } = this.state;
    let dataSeries1 = [];
    let dataSeries2 = [];
    let CurrentActivity = hisactivemenu === "2" ? "Price Range" : "";
    let priceRange1, priceRange2, datePrice;
    let noValues = tFData.every(item => item.price === null || item.price === '' || item.price === undefined);
    if (!noValues) {
      if (CurrentActivity === "Price Range") {
        dataSeries1 = tFData.map(val => {
          let splitpriceVal = val.price.split("-");
          priceRange1 = parseInt(splitpriceVal[0]);
          priceRange2 = splitpriceVal[1] ? parseInt(splitpriceVal[1]) : parseInt(splitpriceVal[0]);
          datePrice = moment(val.datePrice, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          return { '0': { x: datePrice, y: priceRange1 === 0 ? null : priceRange1, fullName: val.price }, '1': { x: datePrice, y: priceRange2 === 0 ? null : priceRange2, fullName: val.price } };
        });
      } else {
        dataSeries2 = tFData.map(val => {
          priceRange1 = val.price;
          priceRange2 = 0;
          datePrice = moment(val.datePrice, 'YYYY-MM-DD').format('DD-MMM-YYYY');
          return { x: datePrice, y: priceRange1 === '0' ? null : priceRange1, fullName: val.price };
          // return { '0' : { x: datePrice, y: priceRange1 == 0 ? null : priceRange1, fullName : val.price }, '1' : { x: datePrice, y: priceRange2 == 0 ? null : priceRange2, fullName : val.price }};
        })
      }
      return [dataSeries1, dataSeries2];
    }
  }

  //Graph for History
  HistoryGraphView() {
    var { CurrentHistory, hisactivemenu, hisactivedur , filterData} = this.state;
    let actualData = [];
    let markerSize = [];
    let filter_actualData = [];
    let currency = this.state.countrycurrency
    ? this.state.countrycurrency
    : "";
    this.setState({filterData:[]});
    let colors = ['#f69e4f', '#1b2c42', '#85411b', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
    if (CurrentHistory.length > 0) {
      let unique = [...new Set(CurrentHistory.map((item) => item.storeID))];
      let CurrentActivity = hisactivemenu === "2" ? "Price Range" : "";
      for (var i = 0; i < unique.length; i++) {
        var tFData = CurrentHistory.filter((a) => a.storeID === unique[i]);
        var tFMarketName = CurrentHistory.find((a) => a.storeID === unique[i]);
        let dataSeries = this.dropDownData(tFData);
       
        if (dataSeries[0].length > 0) {
          actualData.push({ name: tFMarketName["storeName"], data: dataSeries[0].map(val => { return val[0] }), color: colors[i] }, { name: tFMarketName["storeName"] + " data", data: dataSeries[0].map(val => { return val[1] }), color: colors[i] });
          filter_actualData.push({name: tFMarketName["storeName"], data: dataSeries[0].map(val => { return val[0] }), color: colors[i]} ,  { name: tFMarketName["storeName"] , data: dataSeries[0].map(val => { return val[1] }), color: colors[i]});
          markerSize.push(10, 0);
        } else {
          actualData.push({ name: tFMarketName["storeName"], data: dataSeries[1], color: colors[i] });
          filter_actualData.push({name: tFMarketName["storeName"], data: dataSeries[1], color: colors[i]});
          markerSize.push(10);
        }
      }

       //Duplicate Data
    let newData = [];
    filter_actualData.map(dataItem => dataItem.data.forEach(i => {
      if(i.y !== null ){
      filter_actualData.map(userItem => userItem.data.forEach(j => {
        if(j.y !== null){
          if((dataItem.name !== userItem.name) && (dataItem.color !== userItem.color)){
            if( ( (i.y === j.y) && (i.x === j.x) ) || ( (i.x === j.x) && (i.fullName === j.fullName))){
             newData.push({name:dataItem.name ,color:dataItem.color});
           let filterData = newData.reduce((elements, obj, index) => {
              let existingData = elements.find(element =>
                element.name === obj.name && element.color === obj.color
              );
              if (!existingData) {
                elements.push(obj);
              }
              
              return elements;
            }, []);
            this.setState({filterData:filterData});
            }
          }
          }
          }))
        }
        }));

      this.setState({
        advertisedRatesHistorySeries: actualData,
        advertisedRatesHistoryOptions: {
          chart: {
          
            toolbar: {
              show: false
            }
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value === null ? "N/A" : currency + value.toFixed(2);
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder:{
              show: false,
            },
            axisTicks: {
              show: false,
            }
          },
          xaxis: {
            tooltip: {
              enabled: false
            },
            labels: {
              show: true,
              rotate: 0,
              formatter: function(value, timestamp, opts) {
                  let val = value === undefined || typeof (value) !== "string" ? '' : hisactivedur < 4 ? moment(new Date(value)).format('DD-MMM-YYYY'):moment(new Date(value)).format('MMM-YYYY');   
                  return val;
                },

              style: {
                fontSize: '10px',
              },
            },
            tickPlacement: 'on',
            tickAmount: hisactivedur == 1 ? 4 : hisactivedur == 2 ? 4 : hisactivedur == 3 ? 12 : hisactivedur == 4 ? 5 : 9,

            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            showForNullSeries: false,
            showForZeroSeries: true,
            showForSingleSeries: true,
            formatter: (seriesName, opts) => {
              if (seriesName.endsWith('data')) return '';
              else return seriesName;
            },
            markers: {
              width: markerSize,
              height: markerSize,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 20,
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          noData: {
            align: 'top',
            text: 'Please enable any one of the below legend to view the graph.',
            verticalAlign: 'top',
            style: {
              color: "#5b95f6",
            }
          },
        },
      })

    }
    else {
      this.apexChartNoData({
        textMessage: "No Data Found",
        colorCode: "#F60000"
      });
      this.setState({ isLoading1: false });
    }
  }


  async UNSAFE_componentWillMount() {
    const data = {
      SecretKey: this.props.accessKey,
      RegionId: CONFIG.RegionId,
    };

    const response = await axios.post(
      CONFIG.API_URL + "account/secretlogin",
      data
    );
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.initialLoad();
      this.currentPricingAnalysis();
    } else {
      alert(response.status.Message);
    }
  }
  currentPricingAnalysis() {
    this.setState(
      {
        isLoading: false,
        hisactivedur: this.state.hisactivedur,
        hisactivemenu: this.state.hisactivemenu,
      },
      () => {
        this.commonapiCall();
      }
    );
  }

  commonapiCall() {
    this.loadDashBoard();
    this.DurationsdropDown();
    this.GetGraphTableData();
    this.GetcurrentpricinganalysisHistory();
    this.tabtoggle();
    this.GetGridtableData();
    this.loadSummary();
  }


  loadSummary() {
    let token = this.state.accessKey;
    const data = {
      UserStoreID: this.state.stId,
      SearchID: this.state.seId,
      RegionID: CONFIG.RegionId,
    };
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "dashboard/currentpricinganalysissummary", data)
      .then((response) => {
        // this.state.isLoading=true;
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      UserStoreID: this.state.stId,
      SearchID: this.state.seId,
      RegionID: CONFIG.RegionId,
    };
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "unit/dashboardSearchlocation", data)
      .then((response) => {
        // this.state.isLoading=true;
        const locationInfoData = response.data.data.unitDashboard;
        let countrycurrency = response.data.data.unitDashboard.countryCurrency;
        this.setState({
          locationInfoData: locationInfoData,
          countrycurrency,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.searchId !== this.state.seId) {
      this.setState(
        {
          hisactivedur: "2",
          seId: nextProps.searchId,
          stId: nextProps.storeId,
        },
        () => {
          this.currentPricingAnalysis();
        }
      );
    }
  }

  //Duration For DropDown
  DurationsdropDown() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .get(CONFIG.API_URL + "unit/unitdurations")
      .then((response) => {
        let unitDurations = response.data.data.lookupData;
        this.setState({
          unitDurations,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Grid Table data
  GetGridtableData() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: 1,//this.state.activeTab,
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
            ? this.state.uniquestores
            : 0,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName = this.state.activeTab === 4 ? "effectiverateallmarketview" : "currentpricinganalysisallmarketviews";
    axios
      .post(
        CONFIG.API_URL + "dashboard/" + hostName,
        inputData
      )
      .then((res) => {
        let GridTableValue = this.state.GridTableValue;
        let AllMarketdata = res.data.current.currentPricingAnalysisAllMarket;
        let storeAdvertising = AllMarketdata.filter(x => x.isNotCurrentlyAdvertised === true)[0];
        //To get Only Your Store on All Market View
        GridTableValue["statdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore === 1;
        });
        let amidx = 1;
        var sortByMyStoreData = GridTableValue["statdata"];
        for (var i in sortByMyStoreData) {
          if (sortByMyStoreData[i].promoCount > 60) {
            sortByMyStoreData[i].annexure = amidx;
            amidx = amidx + 1;
          }
        }
        GridTableValue["statdata"] = sortByMyStoreData;
        //To get Stores Expect Your Store on All Market View
        GridTableValue["dynamdata"] = AllMarketdata.filter(function (data) {
          return data.isMyStore !== 1;
        });

        var sortByStoreData = "";

        if (this.props.orderby == "storeName") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort((a, b) =>
              a.storeName.localeCompare(b.storeName)
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort((a, b) =>
              b.storeName.localeCompare(a.storeName)
            );
          }
        } else if (this.props.orderby == "currentPremium") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.currentPremium - b.currentPremium
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.currentPremium - a.currentPremium
            );
          }
        } else if (this.props.orderby == "currentValue") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.currentValue - b.currentValue
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.currentValue - a.currentValue
            );
          }
        } else if (this.props.orderby == "premium") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.premium - b.premium
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.premium - a.premium
            );
          }
        } else if (this.props.orderby == "value") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.value - b.value
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.value - a.value
            );
          }
        } else if (this.props.orderby == "value") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.value - b.value
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.value - a.value
            );
          }
        } else if (this.props.orderby == "currentWalkInPremium") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.currentWalkInPremium - b.currentWalkInPremium
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.currentWalkInPremium - a.currentWalkInPremium
            );
          }
        } else if (this.props.orderby == "currentWalkInValue") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => a.currentWalkInValue - b.currentWalkInValue
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort(
              (a, b) => b.currentWalkInValue - a.currentWalkInValue
            );
          }
        } else if (this.props.orderby == "promos") {
          if (this.props.order === "asc") {
            var sortByStoreData = GridTableValue["dynamdata"].sort((a, b) =>
              a.promos.localeCompare(b.promos)
            );
          } else {
            var sortByStoreData = GridTableValue["dynamdata"].sort((a, b) =>
              b.promos.localeCompare(a.promos)
            );
          }
        }

        for (var i in sortByStoreData) {
          if (sortByStoreData[i].promoCount > 60) {
            sortByStoreData[i].annexure = amidx;
            amidx = amidx + 1;
          }
        }

        GridTableValue["dynamdata"] = sortByStoreData;
        this.setState(
          {
            GridTableValue,
            AllMarketdata,
            needComparePromos: AllMarketdata,
            isLoading1: false,
            storeAdvertising,
          },
          () => {
            this.barhandleChange();
            this.annexureSplit();
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  annexureSplit() {
    // for annexure split
    let fixedSize = 1200;
    let finalResultSet = [];
    let tempresult = [];
    let annexSum = 0;
    let sliceCount = 0;
    let fullData = this.state.needComparePromos;
    let annexureArrObj = fullData.filter((annex) => annex.annexure !== 0);
    annexureArrObj.sort(function (a, b) {
      var textA = a.annexure;
      var textB = b.annexure;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    // let annexureArrObj = this.state.needComparePromos.filter((annex) => annex.annexure !== 0);
    for (let annex = 0; annex < annexureArrObj.length; annex++) {
      let annexure = annexureArrObj[annex];
      annexSum += annexure.promoCount;
      if (annexSum >= 7000) {
        annex = annex - 1;
        annexSum = 0;
        finalResultSet.push(tempresult);
        tempresult = [];
      } else {
        tempresult.push(annexure);
      }
    }
    if (tempresult.length > 0) finalResultSet.push(tempresult);
    this.setState({ annexureFilters: finalResultSet });
  }

  //History Graph
  GetcurrentpricinganalysisHistory() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    this.setState({
      AllCurrentHistory: [],
      isLoading: false,
    });

    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
            ? this.state.uniquestores
            : 0,
      Duration: parseInt(this.state.hisactivedur),
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.hisactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName = this.state.activeTab === 4 ? "effectiveratehistories" : "currentpricinganalysisHistories";

    axios
      .post(CONFIG.API_URL + "dashboard/" + hostName, inputData)
      .then((response) => {
        let AllCurrentHistory = response.data.current;
        this.setState({ AllCurrentHistory }, () => {
          this.AlignHistoryGraphData();
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  // Data Format HistoryGraph View
  AlignHistoryGraphData() {
    const { AllCurrentHistory, hisactivemenu } = this.state;

    //Based Dropdown above the History graph
    let CurrentActivity =
      hisactivemenu === "1"
        ? "Premium"
        : hisactivemenu === "2"
          ? "Price Range"
          : "Value";
    // Get Current priceOptionName
    let filterByMenu = AllCurrentHistory.filter(
      (a) => a.priceOptionName === CurrentActivity
    );

    //Spliting mystore and Non-mystore data to order
    let getMystore = filterByMenu.filter((a) => a.isMyMarket === 1);
    let getwithoutMystore = filterByMenu.filter((a) => a.isMyMarket === 0);

    let CurrentHistory = getMystore.concat(getwithoutMystore);
        this.setState(
          { CurrentHistory, isLoading: false, isLoading1: false },
          () => {
            this.HistoryGraphView();
          }
        );
  }

  //Graph Table data
  GetGraphTableData() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: this.state.activeTab,
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
            ? this.state.uniquestores
            : 0,
      RegionId: CONFIG.RegionId,
    }; 
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      // Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    let hostName = this.state.activeTab === 4 ? "effectiveratemarketview" : "currentpricinganalysiscoremarketview";
    axios
      .post(
        CONFIG.API_URL + "dashboard/" + hostName,
        inputData
      )
      .then((res) => {
        let CoreMarketView = res.data.current.currentPricingAnalysisMarket;
        let MarketViewpricingOptions = res.data.current.currentPricingAnalysis;
        this.setState(
          {
            CoreMarketView,
            MarketViewpricingOptions,
            isLoading1: false,
            Tabloading: false,
          },
          () => {
            this.AlignGraphTabData();
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  //Graph Table data
  GetGraphTableDatatab3() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: "3",
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
            ? this.state.uniquestores
            : 0,
      RegionId: CONFIG.RegionId,
    };
    let effectiveRateIp = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      EffectiveTimePeriod: this.state.drpDownSelValue,
      Competitors: this.state.uniquestore,
      Duration: parseInt(this.state.gridactivedur),
      RegionId: CONFIG.RegionId,
    };
    let inputData = this.state.activeTab === 4 ? effectiveRateIp : data;
    //let hostName = this.state.activeTab === 4 ? "effectiveratemarketview" : "currentpricinganalysiscoremarketview" ;
    axios
      .post(
        CONFIG.API_URL + "dashboard/currentpricinganalysiscoremarketview",
        data
      )
      .then((res) => {
        let CoreMarketViewtab3 = res.data.current.currentPricingAnalysisMarket;
        let MarketViewpricingOptionstab3 =
          res.data.current.currentPricingAnalysis;
        this.setState(
          {
            CoreMarketViewtab3,
            MarketViewpricingOptionstab3,
            isLoading1: false,
            Tabloading: false,
          },
          () => {
            this.AlignGraphTabDatatab3();
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  GetGraphTableDatatab2() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: 2,
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
            ? this.state.uniquestores
            : 0,
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(
        CONFIG.API_URL + "dashboard/currentpricinganalysiscoremarketview",
        data
      )
      .then((res) => {
        let CoreMarketViewtab2 = res.data.current.currentPricingAnalysisMarket;
        let MarketViewpricingOptionstab2 =
          res.data.current.currentPricingAnalysis;
        this.setState(
          {
            CoreMarketViewtab2,
            MarketViewpricingOptionstab2,
            isLoading1: false,
            Tabloading: false,
          },
          () => {
            this.AlignGraphTabDatatab2();
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }


  // Data Format table View
  AlignGraphTabData() {
    const { CoreMarketView } = this.state;

    let ChartTableData = this.state.ChartTableData;

    //Checking for data availability
    let AvailDataCheck = [];

    AvailDataCheck = CoreMarketView.filter(function (data) {
      return data.storeID > 0 && data.price != 0;
    });

    //For table Row Height controll
    let priceLength = this.state.priceLength;
    let pricecolLength = this.state.pricecolLength;

    /* Spliting the data in the order of activityType */
    ChartTableData["lahMarkets"] = [];
    if (AvailDataCheck.length > 0) {
      if (this.state.activeTab === 3) {
        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0;
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Promotions",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0;
          }),
        });
        /* End of Barchart table's body data */
      } else {
        //For table Row Height controll
        // priceLength = d3.max(
        //   AvailDataCheck.filter(function (data) {
        //     return data.storeID > 0 && data.priceOptionName === "Price Range";
        //   }),
        //   (d) => d.price.length
        // );
        //}), d => (d.price + (d.alternatePriceTag ? '(' + d.alternatePriceTag + ')' : '')).length);

        pricecolLength = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Price Range";
        }).length;
        // End of For table Row Height controll

        //For Premium data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Premium";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Premium"
            );
          }),
        });

        //For Price Range data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Price Range";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return (
              data.isMyStore === true && data.priceOptionName === "Price Range"
            );
          }),
        });

        //For Value data
        ChartTableData["lahMarkets"].push({
          tr1: CoreMarketView.filter(function (data) {
            return data.storeID < 1 && data.priceOptionName === "Value";
          }),
          tr2: CoreMarketView.filter(function (data) {
            return data.isMyStore === true && data.priceOptionName === "Value";
          }),
        });

        /* end of Spliting the data in the order of activityType */

        /* Barchart table's Head data */
        ChartTableData["TableHead"] = CoreMarketView.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Premium";
        });
        /* end of Barchart table's Head data */

        /* Barchart table's body data */
        ChartTableData["TableBody"] = [];

        //For Premium data
        ChartTableData["TableBody"].push({
          td1: "Premium",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Premium";
          }),
        });

        //For Price Range data
        ChartTableData["TableBody"].push({
          td1: "Price Range",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Price Range";
          }),
        });

        //For Value data
        ChartTableData["TableBody"].push({
          td1: "Value",
          td2: CoreMarketView.filter(function (data) {
            return data.storeID > 0 && data.priceOptionName === "Value";
          }),
        });
      }
    }
    if (
      this.state.graphOpen === "" ||
      this.state.activeTab === 3 ||
      AvailDataCheck.length === 0
    ) {
      this.setState({
        ChartTableData,
        AvailDataCheck: AvailDataCheck.length,
        pricecolLength,
        priceLength,
      });
    } else {
      this.setState(
        {
          ChartTableData,
          AvailDataCheck: AvailDataCheck.length,
          pricecolLength,
          priceLength,
        },
        () => {
          this.TableViewGraphs();
        }
      );
    }

    /* end of Barchart table's body data */
  }
  // Data Format table View
  AlignGraphTabDatatab2() {
    const { CoreMarketViewtab2 } = this.state;

    let ChartTableDatatab2 = this.state.ChartTableDatatab2;

    //Checking for data availability
    let AvailDataChecktab2 = [];

    AvailDataChecktab2 = CoreMarketViewtab2.filter(function (data) {
      return data.storeID > 0 && data.price != 0;
    });

    //For table Row Height controll
    let priceLengthtab2 = this.state.priceLengthtab2;
    let pricecolLengthtab2 = this.state.pricecolLengthtab2;

    /* Spliting the data in the order of activityType */
    ChartTableDatatab2["lahMarkets"] = [];
    if (AvailDataChecktab2.length > 0) {
      //For table Row Height controll
      // priceLengthtab2 = d3.max(
      //   AvailDataChecktab2.filter(function (data) {
      //     return data.storeID > 0 && data.priceOptionName === "Price Range";
      //   }),
      //   (d) => d.price.length
      // );
      //}), d => (d.price + (d.alternatePriceTag ? '(' + d.alternatePriceTag + ')' : '')).length);

      pricecolLengthtab2 = CoreMarketViewtab2.filter(function (data) {
        return data.storeID > 0 && data.priceOptionName === "Price Range";
      }).length;
      // End of For table Row Height controll

      //For Premium data
      ChartTableDatatab2["lahMarkets"].push({
        tr1: CoreMarketViewtab2.filter(function (data) {
          return data.storeID < 1 && data.priceOptionName === "Premium";
        }),
        tr2: CoreMarketViewtab2.filter(function (data) {
          return data.isMyStore === true && data.priceOptionName === "Premium";
        }),
      });

      //For Price Range data
      ChartTableDatatab2["lahMarkets"].push({
        tr1: CoreMarketViewtab2.filter(function (data) {
          return data.storeID < 1 && data.priceOptionName === "Price Range";
        }),
        tr2: CoreMarketViewtab2.filter(function (data) {
          return (
            data.isMyStore === true && data.priceOptionName === "Price Range"
          );
        }),
      });

      //For Value data
      ChartTableDatatab2["lahMarkets"].push({
        tr1: CoreMarketViewtab2.filter(function (data) {
          return data.storeID < 1 && data.priceOptionName === "Value";
        }),
        tr2: CoreMarketViewtab2.filter(function (data) {
          return data.isMyStore === true && data.priceOptionName === "Value";
        }),
      });

      /* end of Spliting the data in the order of activityType */

      /* Barchart table's Head data */
      ChartTableDatatab2["TableHead"] = CoreMarketViewtab2.filter(function (
        data
      ) {
        return data.storeID > 0 && data.priceOptionName === "Premium";
      });
      /* end of Barchart table's Head data */

      /* Barchart table's body data */
      ChartTableDatatab2["TableBody"] = [];

      //For Premium data
      ChartTableDatatab2["TableBody"].push({
        td1: "Premium",
        td2: CoreMarketViewtab2.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Premium";
        }),
      });

      //For Price Range data
      ChartTableDatatab2["TableBody"].push({
        td1: "Price Range",
        td2: CoreMarketViewtab2.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Price Range";
        }),
      });

      //For Value data
      ChartTableDatatab2["TableBody"].push({
        td1: "Value",
        td2: CoreMarketViewtab2.filter(function (data) {
          return data.storeID > 0 && data.priceOptionName === "Value";
        }),
      });
    }
    this.setState(
      {
        ChartTableDatatab2,
        AvailDataChecktab2: AvailDataChecktab2.length,
        pricecolLengthtab2,
        priceLengthtab2,
      },
      () => {
        this.TableViewGraphs();
      }
    );
    // }

    /* end of Barchart table's body data */
  }
  // Data Format table View
  AlignGraphTabDatatab3() {
    const { CoreMarketViewtab3 } = this.state;

    let ChartTableDatatab3 = this.state.ChartTableDatatab3;

    //Checking for data availability
    let AvailDataChecktab3 = [];

    AvailDataChecktab3 = CoreMarketViewtab3.filter(function (data) {
      return data.storeID > 0 && data.price != 0;
    });

    //For table Row Height controll
    let priceLengthtab3 = this.state.priceLengthtab3;
    let pricecolLengthtab3 = this.state.pricecolLengthtab3;

    /* Spliting the data in the order of activityType */
    ChartTableDatatab3["lahMarkets"] = [];
    if (AvailDataChecktab3.length > 0) {
      /* Barchart table's Head data */
      ChartTableDatatab3["TableHead"] = CoreMarketViewtab3.filter(function (
        data
      ) {
        return data.storeID > 0;
      });
      /* end of Barchart table's Head data */

      /* Barchart table's body data */
      ChartTableDatatab3["TableBody"] = [];

      //For Premium data
      ChartTableDatatab3["TableBody"].push({
        td1: "Promotions",
        td2: CoreMarketViewtab3.filter(function (data) {
          return data.storeID > 0;
        }),
      });
      /* End of Barchart table's body data */
    }
    this.setState({
      ChartTableDatatab3,
      AvailDataChecktab3: AvailDataChecktab3.length,
      pricecolLengthtab3,
      priceLengthtab3,
    });

    /* end of Barchart table's body data */
  }
  //Promotions Pop Up
  promohandleOpen(id) {
    this.setState({
      promo_open: true,
      promo_open_id: id,
    });
  }

  promohandleClose() {
    this.setState({
      promo_open: false,
      promo_open_id: 0,
    });
  }
  //End OF PRomotions Pop Up

  initialLoad() {
    let id1, id2, id3, id4, id5, id6, id7;
    let storeIdCount = [];
    let order = this.props.order;
    let orderBy = this.props.orderBy;
    if (this.props.id1 == "no") {
      this.state.uniCheck = "no";
      id1 = 0;
    } else {
      id1 = parseInt(this.props.id1 ? this.props.id1 : 0);
    }
    id2 = parseInt(this.props.id2 ? this.props.id2 : 0);
    id3 = parseInt(this.props.id3 ? this.props.id3 : 0);
    id4 = parseInt(this.props.id4 ? this.props.id4 : 0);
    id5 = parseInt(this.props.id5 ? this.props.id5 : 0);
    id6 = parseInt(this.props.id6 ? this.props.id6 : 0);
    id7 = parseInt(this.props.id7 ? this.props.id7 : 0);
    let storeCount = [id1, id2, id3, id4, id5, id6, id7];
    storeCount.map((data) => {
      if (data != 0) {
        storeIdCount.push(data);
      }
    });
    let uniquestores = storeIdCount.toString();
    this.setState({ uniquestores, storeIdCount, orderBy, order });
  }

  //price conversion
  priceConversion(tot) {
    if (tot !== "" && tot !== null) {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }

  render() {
    const {
      analysisSummary,
      locationInfoData,
      activeTab,
      promo_open,
      isLoading,
      pricecolLength,
      order,
      orderBy,
      grapghCatcheck,
      graphOpen,
      ChartTableDatatab3,
      AvailDataChecktab3,
      ChartTableDatatab2,
      AvailDataChecktab2,
      pricecolLengthtab3,
      priceLengthtab3,
      pricecolLengthtab2,
      priceLengthtab2,
      ChartTableData,
      /*SinglelahMarkets,*/ GridTableValue,
      unitDurations,
      MarketViewpricingOptions,
      MarketViewpricingOptionstab3,
      MarketViewpricingOptionstab2,
      snackOpen,
      snackMSg,
      AvailDataCheck,
      needComparePromos,
      annexureFilters,
      storeAdvertising,
      filterData
    } = this.state;
    let ComActivityType = ["Premium", "Price Range", "Value"];
    let Colors = [
      "mystorebarclr",
      "barclr1",
      "barclr2",
      "barclr3",
      "barclr4",
      "barclr5",
      "barclr6",
      "barclr7",
    ];
    let moneySymbol = this.state.countrycurrency
      ? this.state.countrycurrency
      : "";
    const GridTableValue1 =
      GridTableValue && GridTableValue.dynamdata
        ? GridTableValue.dynamdata.length
        : 0;
    const GridTableValueData = GridTableValue ? GridTableValue.dynamdata : [];
    var hlpcnt;
    if (activeTab === 1) {
      hlpcnt =
        "<h6>" +
        `${CONFIG.RegionId === 3
          ? "Current Advertised Weekly Rates"
          : "Current Advertised Rates"
        }` +
        "</h6><p>In this section, the graph enables you to compare rates like-for-like, against your top competitors*.  The rates shown on the graph are based on the Online Advertised Rate on the store's website.</p><p>The Number of Pricing Options shows you if the selected store* offers more than one price type for this unit type.</p><ul><li>The highest price advertised by a store is shown as the Premium rate.</li><li>The lowest price advertised by a store is shown as the Value rate.</li><li>Any other rates in between are shown as a range</li><li>If there is only one price for a unit, it is shown as both the Premium and Value rate</li></ul><p>Market Low, Market Average and Market High</p><p>These rates are based on the online rates advertised by the competitor stores* and the price type chosen (Premium or Value).</p><p>*See the All Market View section below to learn how you can change the competitors you can compare against on this graph.</p><p><i>*Your store prices are included in the calculations.</i></p>";
    } else if (activeTab === 3) {
      hlpcnt =
        "<h6>Promotions</h6><p>These are unit-specific promotions advertised by the store for the above unit type.</p>";
    } else {
      hlpcnt =
        "<h6>" +
        `${CONFIG.RegionId === 3
          ? "Advertised Walk-in Weekly Rates"
          : "Advertised Walk-in Rates"
        }` +
        "</h6><p>These are the walk-in (or 'Standard') rates offered by the store for this unit type.</p>";
    }
    const defaultProps = {
      helpTitle: "Core Market View",
      helpContent:
        hlpcnt +
        "<p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/EhEUYS4SMAA' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps1 = {
      helpTitle: "Historical Pricing Analysis",
      helpContent:
        "<p>View historical rates for this unit type over the last 5 days, 30 days, 90 days, 6 months or 1 year, choose the time range from the drop down menu. You can also view rates by pricing type - Premium, Value or both. See the All Market View section below to learn how you can change the competitors you can compare against on this graph. These rates are based on the Advertised Online Rate.</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p> <iframe width='472' height='248' src='https://www.youtube.com/embed/ZBHn_uCFJSI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps2 = {
      helpTitle: "All Market View",
      helpContent: this.state.activeTab === 4 ?
        "<ul><li>These are all the stores in this Market offering this unit type.</li><li>Select up to a total of 8 competitor stores listed below to compare against in the Current Effective Rate analysis section or Historical pricing view. You can remove any stores from the graph at any time.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>" :
        "<ul><li>These are all the stores in this Market offering this unit type.</li><li>Select up to a total of 8 competitor stores listed below to compare against in the Current Pricing analysis section or Historical pricing view. You can remove any stores from the graph at any time.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>",
    };

    let cutCur = locationInfoData.countryCurrency
      ? locationInfoData.countryCurrency
      : "N/A";
    let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if (clp) {
      var splt_clp = clp.toString().split(".");
      let splt_val_clp = splt_clp[1]
        ? splt_clp[1].length === 1
          ? "." + splt_clp[1] + "0"
          : splt_clp[1].length === 2
            ? "." + splt_clp[1]
            : ""
        : "";
      clp = cutCur + splt_clp[0] + splt_val_clp;
    }
    if (cap) {
      var splt_cap = cap.toString().split(".");
      let splt_val_cap = splt_cap[1]
        ? splt_cap[1].length === 1
          ? "." + splt_cap[1] + "0"
          : splt_cap[1].length === 2
            ? "." + splt_cap[1]
            : ""
        : "";
      cap = cutCur + splt_cap[0] + splt_val_cap;
    }
    if (chp) {
      var splt_chp = chp.toString().split(".");
      let splt_val_chp = splt_chp[1]
        ? splt_chp[1].length === 1
          ? "." + splt_chp[1] + "0"
          : splt_chp[1].length === 2
            ? "." + splt_chp[1]
            : ""
        : "";
      chp = cutCur + splt_chp[0] + splt_val_chp;
    }
    var checkIfAnnexureEmptyStat = [];
    checkIfAnnexureEmptyStat =
      GridTableValue &&
      GridTableValue.statdata &&
      GridTableValue.statdata.filter(function (data) {
        return data.annexure !== 0;
      });
    var checkIfAnnexureEmpty = [];
    checkIfAnnexureEmpty =
      GridTableValue &&
      GridTableValue.dynamdata &&
      GridTableValue.dynamdata.filter(function (data) {
        return data.annexure !== 0;
      });
    // var checkIfAnnexureEmpty = GridTableValue && GridTableValue.dynamdata && GridTableValue.dynamdata.filter(data => data.annexure !== 0);
    const tr = [...Array(Math.ceil(GridTableValue1 / 19))];
    const trproductRows = tr.map((row, idx) =>
      GridTableValueData.slice(idx * 19, idx * 19 + 19)
    );
    
    const nameCount = locationInfoData.storeName
      ? locationInfoData.storeName.length
      : 0;
    let checkPg = null;
    return (
      <div>
        <div className="currentprice currentprice-pdf">
          <div class="header">
            {" "}
            <img src={Logo} />
            <div className="float-right-name">
              <h5>
                {locationInfoData.storeName &&
                  locationInfoData.storeName !== null &&
                  locationInfoData.storeName !== ""
                  ? nameCount > CONFIG.storeNameMaxLimit
                    ? locationInfoData.storeName.substring(
                      0,
                      CONFIG.storeNameMaxLimit - 3
                    ) + "..."
                    : locationInfoData.storeName
                  : isLoading === false
                    ? "Your Store"
                    : ""}
              </h5>
            </div>
            <div className="float-right">
              <p className="m-0 address-font">
                {locationInfoData.storeAddress1 &&
                  locationInfoData.storeAddress1 !== null &&
                  locationInfoData.storeAddress1 !== ""
                  ? locationInfoData.storeAddress1 + ", "
                  : "" + locationInfoData && locationInfoData.storeCity
                    ? locationInfoData.storeCity + ", "
                    : locationInfoData.searchType === 1 &&
                      (locationInfoData.storeCity === "" ||
                        locationInfoData.storeCity === null) &&
                      (locationInfoData.storeZipCode === "" ||
                        locationInfoData.storeZipCode === null)
                      ? locationInfoData.latitude +
                      ", " +
                      locationInfoData.longitude +
                      ", "
                      : ""}
                {locationInfoData.storeState
                  ? locationInfoData.storeState + " "
                  : ""}{" "}
                {locationInfoData.storeZipCode}{" "}
                {locationInfoData.storeZoneCoverage
                  ? "| Coverage: " + locationInfoData.storeZoneCoverage
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="footer">
            <a
              href={CONFIG.Website_URL_Footer_Pdf}
              style={{ textDecoration: "none" }}
            >
              {CONFIG.Website_Text_Footer_Pdf}
            </a>{" "}
            | {CONFIG.Phone_Footer_Pdf} |{" "}
            <a
              href={"mailto:" + CONFIG.Mail_Footer_Pdf}
              style={{ textDecoration: "none" }}
            >
              {CONFIG.Mail_Footer_Pdf}
            </a>{" "}
            | <img src={Logo} alt="Optimize Logo" style={{ height: "16px" }} />
          </div>
          {isLoading ? (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
          {this.state.isLoading1 ? (
            <div className="loader-wrap">
              <div className="loading loading-center"></div>
            </div>
          ) : (
            ""
          )}
          {this.state.Tabloading ? (
            <div className="loader-wrap">
              <div className="loading loading-center"></div>
            </div>
          ) : (
            ""
          )}
          <div item="true" xs={12}>
            <div className="page">
              <div
                className="print-view"
                style={{ width: "100%", paddingLeft: "10px" }}
              >
                {this.state.activeTab === 4 ? <h4 className="mb-3">
                  {"(" + locationInfoData.searchName + ")"} Effective Rate
                  Analysis
                </h4> : <h4 className="mb-3">
                  {"(" + locationInfoData.searchName + ")"} Current Pricing
                  Analysis
                </h4>}
              </div>
              <Row className="height-full print-margin">
                <div className="sep-box">
                  <h4 className="text-left">
                    {CONFIG.RegionId === 3
                      ? "Current Lowest Weekly Price"
                      : "Current Lowest Price"}
                  </h4>
                  <div className="pd-btm-20">
                    <p>
                      {analysisSummary
                        ? analysisSummary.lowestPriceStoreName
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </p>
                  </div>
                  <div className="align-stores-btm">
                    <h5>{clp ? clp : isLoading === false ? "N/A" : ""} </h5>
                  </div>
                </div>
                <div className="sep-box current-avg-height">
                  <h4
                    className="text-left"
                    style={
                      !cap && isLoading === false
                        ? { height: "auto" }
                        : { position: "relative" }
                    }
                  >
                    {CONFIG.RegionId === 3
                      ? "Current Average Weekly Price"
                      : "Current Average Price"}
                  </h4>
                  {/* <div className="pd-btm-20"><p></p></div> */}
                  <div className="align-stores-btm">
                    <h5>{cap ? cap : isLoading === false ? "N/A" : ""} </h5>
                  </div>
                </div>
                <div className="sep-box right-none">
                  <h4 className="text-left">
                    {CONFIG.RegionId === 3
                      ? "Current Highest Weekly Price"
                      : "Current Highest Price"}
                  </h4>
                  <div className="pd-btm-20">
                    <p>
                      {analysisSummary
                        ? analysisSummary.highestPriceStoreName
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </p>
                  </div>
                  <div className="align-stores-btm">
                    <h5>{chp ? chp : isLoading === false ? "N/A" : ""} </h5>
                  </div>
                </div>
                <div className="clear"></div>
              </Row>
              <Row className="m-0">
                <Col md="12" id="cpanalysis">
                  <div className="pt-3 pb-2">
                    <h5 className="core mb-3">Core Market View </h5>
                    <h6>
                      {CONFIG.RegionId === 3
                        ? "Current Advertised Weekly Rates"
                        : "Current Advertised Rates"}
                    </h6>
                    <p>
                      In this section, the graph enables you to compare rates
                      like-for-like, against your top competitors*. The rates
                      shown on the graph are based on the Online Advertised Rate
                      on the store's website.
                      <br />
                      The Number of Pricing Options shows you if the selected
                      store* offers more than one price type for this unit type.
                      <br />
                      The highest price advertised by a store is shown as the
                      Premium rate.
                      <br />
                      The lowest price advertised by a store is shown as the
                      Value rate.
                      <br />
                      Any other rates in between are shown as a range
                      <br />
                      If there is only one price for a unit, it is shown as both
                      the Premium and Value rate
                      <br />
                      Market Low, Market Average and Market High
                      <br />
                      These rates are based on the online rates advertised by
                      the competitor stores* and the price type chosen (Premium
                      or Value).
                      <br />
                      *See the All Market View section below to learn how you
                      can change the competitors you can compare against on this
                      graph.
                      <br />
                      *Your store prices are included in the calculations.
                    </p>
                  </div>
                </Col>

                {AvailDataCheck > 0 ? (
                  <React.Fragment>
                    <Col
                      md="12"
                      lg={activeTab === 3 ? 12 : 9}
                      className={activeTab === 3 ? "padding-right-1" : ""}
                    >
                      {CONFIG.RegionId === 3 ? (
                        <p className="sqft-disclaimer mb-0 text-right">
                          * This rate is calculated from an advertised monthly
                          rate
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="ms_charts pl-0 pt-1 pb-3 padding-right-0 border-right-1 online-price-table">
                        <ReactTable className="msc activity-table" responsive>
                          <thead>
                            {ChartTableData.TableHead &&
                              ChartTableData.TableHead.length > 0 ? (
                              <tr>
                                <th id="getthwidth">Store Name</th>
                                {ChartTableData.TableHead.map(
                                  (th_data, index) => {
                                    let sAddress = th_data.address
                                      ? ", \n" + th_data.address
                                      : "";
                                    const text = (
                                      <div style={{ whiteSpace: "pre-line" }}>
                                        {th_data.storeName + sAddress}
                                      </div>
                                    );
                                    let dotline;
                                    let storName;
                                    if (ChartTableData.TableHead.length > 5) {
                                      dotline =
                                        th_data.storeName.length > 18
                                          ? ".."
                                          : "";
                                      storName =
                                        th_data.storeName.substring(0, 18) +
                                        dotline;
                                    } else if (
                                      ChartTableData.TableHead.length === 4 ||
                                      ChartTableData.TableHead.length === 5
                                    ) {
                                      dotline =
                                        th_data.storeName.length > 40
                                          ? ".."
                                          : "";
                                      storName =
                                        th_data.storeName.substring(0, 40) +
                                        dotline;
                                    } else {
                                      storName = th_data.storeName;
                                    }

                                    return (
                                      <Tooltip title={text} placement="top">
                                        <th
                                          className={
                                            Colors[index] + " thdatawidth"
                                          }
                                          key={th_data.storeID}
                                        >
                                          {storName}
                                        </th>
                                      </Tooltip>
                                    );
                                  }
                                )}
                              </tr>
                            ) : (
                              <tr>
                                <th>Store Name</th>
                              </tr>
                            )}
                          </thead>
                          <tbody className="walk-in-rates">
                            {MarketViewpricingOptions &&
                              MarketViewpricingOptions.length > 0 ? (
                              <tr className="disabledrow">
                                <td>
                                  <span className="markets-label">
                                    No.of Pricing Options
                                  </span>
                                </td>
                                {MarketViewpricingOptions.map((po_row) => {
                                  return (
                                    <td key={po_row.storeID}>
                                      {po_row.pricingOptions}
                                    </td>
                                  );
                                })}
                              </tr>
                            ) : (
                              <tr className="disabledrow disablegraphrow">
                                <td>
                                  <span className="markets-label">
                                    No.of Pricing Options
                                  </span>
                                </td>
                              </tr>
                            )}
                            {this.state.Tabloading ? (
                              ""
                            ) : ChartTableData.TableBody &&
                              ChartTableData.TableBody.length > 0 ? (
                              ChartTableData.TableBody.map((row1, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td align="right" className="ms_tablebtn">
                                        <span className="markets-label">
                                          {row1.td1}
                                        </span>
                                        {(activeTab === 1 || activeTab === 4) ? (
                                          grapghCatcheck === row1.td1 ? (
                                            <Button
                                              color="danger"
                                              className="add-to-graph"
                                              onClick={() =>
                                                this.onSelecCatGrapgh(row1.td1)
                                              }
                                              size="sm"
                                            >
                                              <img src={tableminus} alt="" />
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              className="remove-to-graph"
                                              onClick={() =>
                                                this.onSelecCatGrapgh(row1.td1)
                                              }
                                              size="sm"
                                            >
                                              <img src={tableplus} alt="" />
                                            </Button>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      {row1.td2.map((row) => {
                                        // let alterLabel = row.alternatePriceTag ? '('+row.alternatePriceTag+')':'' ;
                                        var tdVal =
                                          row.price !== null &&
                                            row.price !== "0"
                                            ? moneySymbol + row.price
                                            : "N/A";
                                        var monthlyValues =
                                          row.isMonthly &&
                                            row.isMonthly === 1 &&
                                            CONFIG.RegionId === 3
                                            ? "*"
                                            : "";
                                        if (activeTab === 3) {
                                          tdVal =
                                            row.promo &&
                                              row.promo !== null &&
                                              row.promo !== "0"
                                              ? row.promo
                                                .split("*~*")
                                                .join("<hr/>")
                                              : "No specials";
                                        }
                                        return (
                                          <React.Fragment key={row.storeID}>
                                            <td
                                              dangerouslySetInnerHTML={
                                                activeTab === 3
                                                  ? { __html: tdVal }
                                                  : {
                                                    __html:
                                                      tdVal + monthlyValues,
                                                  }
                                              }
                                            ></td>
                                            {/* <td dangerouslySetInnerHTML={{ __html:tdVal}}></td> */}
                                          </React.Fragment>
                                        );
                                      })}
                                    </tr>
                                    {/* <tr className={graphOpen === row1.td1 ? "legend-table" : 'disablegraphrow legend-table'}><td colSpan="9">(O) - Online Price<span className="pl-3">(R) - Regular Price</span></td></tr> */}
                                    <tr
                                      className={
                                        graphOpen === row1.td1 &&
                                          (activeTab === 1 || activeTab === 4)
                                          ? "set-gray-background"
                                          : "disablegraphrow"
                                      }
                                    >
                                      <td colSpan={row1.td2.length + 1}>

                                        <Chart options={this.state.options} series={this.state.series} className="label-align" type="line"  width={972}  height={250} />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <tr>
                                <td>
                                  {isLoading === false
                                    ? "There are no competitors available in this market."
                                    : ""}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </ReactTable>
                      </div>
                    </Col>
                    {activeTab !== 3 ? (
                      <Col md="12" lg="3">
                        <div className="ys_cont pt-3">
                          <ReactTable
                            className="mss market-right-table"
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="ma">Store Name</th>
                                <th className="ml">Market Low</th>
                                <th className="ma">Market Average</th>
                                <th className="mh">Market High</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.Tabloading ? (
                                ""
                              ) : ChartTableData.lahMarkets &&
                                ChartTableData.lahMarkets.length > 0 ? (
                                ChartTableData.lahMarkets.map(
                                  (tr1data, index) => {
                                    return (
                                      <React.Fragment>
                                        <tr>
                                          {tr1data.tr1.map((td, index) => {
                                            let HclassName =
                                              (activeTab === 1 || activeTab === 4)
                                                ? ""
                                                : "other_price";
                                            if (
                                              td.priceOptionName ===
                                              "Price Range"
                                            ) {
                                              // width lesser than 1367
                                              if (
                                                window.innerWidth < 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 11
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth < 1367 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }

                                              // width equal to 1367
                                              if (
                                                window.innerWidth === 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 12
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth === 1367 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }

                                              // width greater than 1367
                                              if (
                                                window.innerWidth > 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 15
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth > 1367 &&
                                                window.innerWidth < 1438 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }
                                            }
                                            return (
                                              <React.Fragment key={index}>
                                                {td.storeName ===
                                                  "MarketLow" ? (
                                                  <React.Fragment>
                                                    <td
                                                      className={
                                                        HclassName + " ma "
                                                      }
                                                    >
                                                      {td.priceOptionName
                                                        ? td.priceOptionName
                                                        : ""}
                                                    </td>
                                                    <td
                                                      className={
                                                        HclassName + " ml "
                                                      }
                                                    >
                                                      {td.priceOptionName ===
                                                        "Price Range"
                                                        ? " - "
                                                        : td.price
                                                          ? moneySymbol + td.price
                                                          : "N/A"}
                                                    </td>
                                                  </React.Fragment>
                                                ) : td.storeName ===
                                                  "MarketAvg" ? (
                                                  <td
                                                    className={
                                                      HclassName + " ma "
                                                    }
                                                  >
                                                    {td.priceOptionName ===
                                                      "Price Range"
                                                      ? " - "
                                                      : td.price
                                                        ? moneySymbol + td.price
                                                        : "N/A"}
                                                  </td>
                                                ) : td.storeName ===
                                                  "MarketHigh" ? (
                                                  <td
                                                    className={
                                                      HclassName + " mh "
                                                    }
                                                  >
                                                    {td.priceOptionName ===
                                                      "Price Range"
                                                      ? " - "
                                                      : td.price
                                                        ? moneySymbol + td.price
                                                        : "N/A"}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                              </React.Fragment>
                                            );
                                          })}
                                        </tr>

                                        {tr1data.tr2.length > 0 &&
                                          (activeTab === 1 || activeTab === 4) ? (
                                          tr1data.tr2.map((rowdata, index) => {
                                            const priceRangeFormatRegex = /^\d+(\.\d{1,2})?-\d+(\.\d{1,2})?$/;

                                            return (
                                              <tr
                                                key={index}
                                                className={
                                                  graphOpen ===
                                                    rowdata.priceOptionName
                                                    ? ""
                                                    : "disablegraphrow"
                                                }
                                              >
                                                <td colSpan="4">
                                                  <div
                                                    className="pb-1 pt-1 pl-0 pr-0 set-min-heights"
                                                    align="center"
                                                  >
                                                    <Typography
                                                      variant="caption"
                                                      className="your-score"
                                                    >
                                                      {CONFIG.RegionId === 3
                                                        ? "Your Weekly Price"
                                                        : "Your Price"}
                                                    </Typography>
                                                    <Typography
                                                      variant="h6"
                                                      className="score-value"
                                                    >
                                                      {rowdata?.price &&
                                                        rowdata.price !== "0"
                                                        ? priceRangeFormatRegex.test(rowdata?.price) ? moneySymbol + rowdata?.price : moneySymbol + this.priceConversion(rowdata?.price) 
                                                        : "N/A"}
                                                    </Typography>
                                                    {rowdata.myStorePriceRank ? (
                                                      <h5>
                                                        {
                                                          rowdata.myStorePriceRank
                                                        }
                                                      </h5>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr
                                            key={index}
                                            className={
                                              graphOpen ===
                                                ComActivityType[index] &&
                                                (activeTab === 1 || activeTab === 4) &&
                                                graphOpen !== "Price Range"
                                                ? ""
                                                : "disablegraphrow"
                                            }
                                          >
                                            <td colSpan="4">
                                              <div
                                                className="p-4 pt-6 pb-6"
                                                align="center"
                                              >
                                                <Typography
                                                  variant="caption"
                                                  className="your-score"
                                                >
                                                  {CONFIG.RegionId === 3
                                                    ? "Your Weekly Price"
                                                    : "Your Price"}
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  className="score-value"
                                                >
                                                  N/A
                                                </Typography>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" align="center">
                                    N/A
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </ReactTable>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  <Col>
                    <Typography variant="body1" className="no-data p-4">
                      {isLoading === false
                        ? "There are no competitors available in this market."
                        : ""}
                    </Typography>
                  </Col>
                )}

                {
                  AvailDataChecktab3 > 0 ? (
                    <React.Fragment>
                      <div className="pageclearfix"></div>
                      <div class="page">
                        <Row className="m-0">
                          <Col md="12" lg="12" className={grapghCatcheck !== "" ? "promotions-btm":"promotions-btm1"}>
                            <h5 className="mt-4">Promotions</h5>
                            <p>
                              These are unit-specific promotions advertised by
                              the store for the above unit type.
                            </p>
                            <div className="ms_charts pl-0 pb-3 pt-1 border-right-1 online-price-table">
                              <ReactTable
                                className="msc activity-table"
                                responsive
                              >
                                <thead>
                                  {ChartTableDatatab3.TableHead &&
                                    ChartTableDatatab3.TableHead.length > 0 ? (
                                    <tr>
                                      <th id="getthwidth">Store Name</th>
                                      {ChartTableDatatab3.TableHead.map(
                                        (th_data, index) => {
                                          let sAddress = th_data.address
                                            ? ", \n" + th_data.address
                                            : "";
                                          const text = (
                                            <div
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {th_data.storeName + sAddress}
                                            </div>
                                          );
                                          let dotline;
                                          let storName;
                                          if (
                                            ChartTableDatatab3.TableHead
                                              .length > 5
                                          ) {
                                            dotline =
                                              th_data.storeName.length > 18
                                                ? ".."
                                                : "";
                                            storName =
                                              th_data.storeName.substring(
                                                0,
                                                18
                                              ) + dotline;
                                          } else if (
                                            ChartTableDatatab3.TableHead
                                              .length === 4 ||
                                            ChartTableDatatab3.TableHead
                                              .length === 5
                                          ) {
                                            dotline =
                                              th_data.storeName.length > 40
                                                ? ".."
                                                : "";
                                            storName =
                                              th_data.storeName.substring(
                                                0,
                                                40
                                              ) + dotline;
                                          } else {
                                            storName = th_data.storeName;
                                          }

                                          return (
                                            <Tooltip
                                              title={text}
                                              placement="top"
                                            >
                                              <th
                                                className={
                                                  Colors[index] + " thdatawidth"
                                                }
                                                key={th_data.storeID}
                                              >
                                                {storName}
                                              </th>
                                            </Tooltip>
                                          );
                                        }
                                      )}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <th>Store Name</th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody className="walk-in-rates">
                                  {MarketViewpricingOptionstab3 &&
                                    MarketViewpricingOptionstab3.length > 0 ? (
                                    <tr className="disabledrow">
                                      <td>
                                        <span className="markets-label">
                                          No.of Pricing Options
                                        </span>
                                      </td>
                                      {MarketViewpricingOptionstab3.map(
                                        (po_row) => {
                                          return (
                                            <td key={po_row.storeID}>
                                              {po_row.pricingOptions}
                                            </td>
                                          );
                                        }
                                      )}
                                    </tr>
                                  ) : (
                                    <tr className="disabledrow disablegraphrow">
                                      <td>
                                        <span className="markets-label">
                                          No.of Pricing Options
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {this.state.Tabloading ? (
                                    ""
                                  ) : ChartTableDatatab3.TableBody &&
                                    ChartTableDatatab3.TableBody.length > 0 ? (
                                    ChartTableDatatab3.TableBody.map(
                                      (row1, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td
                                                align="right"
                                                className="ms_tablebtn"
                                              >
                                                <span className="markets-label">
                                                  {row1.td1}
                                                </span>
                                              </td>
                                              {row1.td2.map((row) => {
                                                var tdVal =
                                                  row.price !== null &&
                                                    row.price !== "0"
                                                    ? moneySymbol + row.price
                                                    : "N/A";
                                                // if(activeTab === 3 ){
                                                tdVal =
                                                  row.promo &&
                                                    row.promo !== null &&
                                                    row.promo !== "0"
                                                    ? row.promo
                                                      .split("*~*")
                                                      .join("<hr/>")
                                                    : "No specials";
                                                // }
                                                var allPromoData =
                                                  needComparePromos &&
                                                    needComparePromos.length > 0
                                                    ? needComparePromos
                                                    : [];
                                                var annexText = "Annexure - ";
                                                var results = allPromoData.filter(
                                                  (d) =>
                                                    d.storeID === row.storeID
                                                );
                                                var resultFinal =
                                                  results && results.length > 0
                                                    ? results[0].annexure
                                                    : 0;
                                                tdVal =
                                                  resultFinal && resultFinal > 0
                                                    ? annexText + resultFinal
                                                    : tdVal;
                                                return (
                                                  <React.Fragment
                                                    key={row.storeID}
                                                  >
                                                    <td
                                                      dangerouslySetInnerHTML={{
                                                        __html: tdVal,
                                                      }}
                                                    ></td>
                                                  </React.Fragment>
                                                );
                                              })}
                                            </tr>
                                            <tr
                                              className={
                                                graphOpen === row1.td1 &&
                                                  (activeTab === 1 || activeTab === 4)
                                                  ? "set-gray-background"
                                                  : "disablegraphrow"
                                              }
                                            >
                                              <td colSpan={row1.td2.length + 1}>
                                                <div className="cpsvg"></div>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td>
                                        {isLoading === false
                                          ? "There are no competitors available in this market."
                                          : ""}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </ReactTable>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </React.Fragment>
                  ) : (
                    <Col>
                      <Typography variant="body1" className="no-data p-4">
                        {isLoading === false
                          ? "There are no competitors available in this market."
                          : ""}
                      </Typography>
                    </Col>
                  )}
                {
                  AvailDataChecktab2 > 0 ? (
                    <React.Fragment>
                      <Col md="12" lg="12" className="page walk-in">
                        <h5 className="pt-2 mt-0 mb-3">
                          {CONFIG.RegionId === 3
                            ? "Advertised Walk-in Weekly Rates"
                            : "Advertised Walk-in Rates"}
                        </h5>
                        <p>
                          These are the walk-in (or 'Standard') rates offered by
                          the store for this unit type.
                        </p>
                        <div className="ms_charts border-right-1 online-price-table">
                          {CONFIG.RegionId === 3 ? (
                            <p className="sqft-disclaimer mb-0 text-right">
                              * This rate is calculated from an advertised
                              monthly rate
                            </p>
                          ) : (
                            ""
                          )}
                          <ReactTable className="msc activity-table" responsive>
                            <thead>
                              {ChartTableDatatab2.TableHead &&
                                ChartTableDatatab2.TableHead.length > 0 ? (
                                <tr>
                                  <th id="getthwidth">Store Name</th>
                                  {ChartTableDatatab2.TableHead.map(
                                    (th_data, index) => {
                                      let sAddress = th_data.address
                                        ? ", \n" + th_data.address
                                        : "";
                                      const text = (
                                        <div style={{ whiteSpace: "pre-line" }}>
                                          {th_data.storeName + sAddress}
                                        </div>
                                      );
                                      let dotline;
                                      let storName;
                                      if (
                                        ChartTableDatatab2.TableHead.length > 5
                                      ) {
                                        dotline =
                                          th_data.storeName.length > 18
                                            ? ".."
                                            : "";
                                        storName =
                                          th_data.storeName.substring(0, 18) +
                                          dotline;
                                      } else if (
                                        ChartTableDatatab2.TableHead.length ===
                                        4 ||
                                        ChartTableDatatab2.TableHead.length ===
                                        5
                                      ) {
                                        dotline =
                                          th_data.storeName.length > 40
                                            ? ".."
                                            : "";
                                        storName =
                                          th_data.storeName.substring(0, 40) +
                                          dotline;
                                      } else {
                                        storName = th_data.storeName;
                                      }

                                      return (
                                        <Tooltip title={text} placement="top">
                                          <th
                                            className={
                                              Colors[index] + " thdatawidth"
                                            }
                                            key={th_data.storeID}
                                          >
                                            {storName}
                                          </th>
                                        </Tooltip>
                                      );
                                    }
                                  )}
                                </tr>
                              ) : (
                                <tr>
                                  <th>Store Name</th>
                                </tr>
                              )}
                            </thead>
                            <tbody className="walk-in-rates">
                              {MarketViewpricingOptionstab2 &&
                                MarketViewpricingOptionstab2.length > 0 ? (
                                <tr className="disabledrow">
                                  <td>
                                    <span className="markets-label">
                                      No.of Pricing Options
                                    </span>
                                  </td>
                                  {MarketViewpricingOptionstab2.map(
                                    (po_row) => {
                                      return (
                                        <td key={po_row.storeID}>
                                          {po_row.pricingOptions}
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              ) : (
                                <tr className="disabledrow disablegraphrow">
                                  <td>
                                    <span className="markets-label">
                                      No.of Pricing Options
                                    </span>
                                  </td>
                                </tr>
                              )}
                              {this.state.Tabloading ? (
                                ""
                              ) : ChartTableDatatab2.TableBody &&
                                ChartTableDatatab2.TableBody.length > 0 ? (
                                ChartTableDatatab2.TableBody.map(
                                  (row1, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td
                                            align="right"
                                            className="ms_tablebtn"
                                          >
                                            <span className="markets-label">
                                              {row1.td1}
                                            </span>
                                          </td>
                                          {row1.td2.map((row) => {
                                            var tdVal =
                                              row.price !== null &&
                                                row.price !== "0"
                                                ? moneySymbol + row.price
                                                : "N/A";
                                            var monthlyValues =
                                              row.isMonthly &&
                                                row.isMonthly === 1 &&
                                                CONFIG.RegionId === 3
                                                ? "*"
                                                : "";
                                            return (
                                              <React.Fragment key={row.storeID}>
                                                <td
                                                  dangerouslySetInnerHTML={
                                                    CONFIG.RegionId === 3
                                                      ? {
                                                        __html:
                                                          tdVal +
                                                          monthlyValues,
                                                      }
                                                      : { __html: tdVal }
                                                  }
                                                ></td>
                                              </React.Fragment>
                                            );
                                          })}
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td>
                                    {isLoading === false
                                      ? "There are no competitors available in this market."
                                      : ""}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </ReactTable>
                        </div>
                      </Col>

                      <Col md="12" lg="3">
                        <div className="ys_cont pt-4">
                          <ReactTable
                            className="mss market-right-table"
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="ma">Store Name</th>
                                <th className="ml">Market Low</th>
                                <th className="ma">Market Average</th>
                                <th className="mh">Market High</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.Tabloading ? (
                                ""
                              ) : ChartTableDatatab2.lahMarkets &&
                                ChartTableDatatab2.lahMarkets.length > 0 ? (
                                ChartTableDatatab2.lahMarkets.map(
                                  (tr1data, index) => {
                                    return (
                                      <React.Fragment>
                                        <tr>
                                          {tr1data.tr1.map((td, index) => {
                                            let HclassName = "other_price";
                                            if (
                                              td.priceOptionName ===
                                              "Price Range"
                                            ) {
                                              // width lesser than 1367
                                              if (
                                                window.innerWidth < 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 11
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth < 1367 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }

                                              // width equal to 1367
                                              if (
                                                window.innerWidth === 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 12
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth === 1367 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }

                                              // width greater than 1367
                                              if (
                                                window.innerWidth > 1367 &&
                                                pricecolLength > 7 &&
                                                this.state.priceLength > 15
                                              ) {
                                                HclassName = "set-height";
                                              } else if (
                                                window.innerWidth > 1367 &&
                                                window.innerWidth < 1438 &&
                                                pricecolLength > 6 &&
                                                this.state.priceLength > 14
                                              ) {
                                                HclassName = "set-height";
                                              }
                                            }
                                            return (
                                              <React.Fragment key={index}>
                                                {td.storeName ===
                                                  "MarketLow" ? (
                                                  <React.Fragment>
                                                    <td
                                                      className={
                                                        HclassName + " ma "
                                                      }
                                                    >
                                                      {td.priceOptionName
                                                        ? td.priceOptionName
                                                        : ""}
                                                    </td>
                                                    <td
                                                      className={
                                                        HclassName + " ml "
                                                      }
                                                    >
                                                      {td.priceOptionName ===
                                                        "Price Range"
                                                        ? " - "
                                                        : td.price
                                                          ? moneySymbol + td.price
                                                          : "N/A"}
                                                    </td>
                                                  </React.Fragment>
                                                ) : td.storeName ===
                                                  "MarketAvg" ? (
                                                  <td
                                                    className={
                                                      HclassName + " ma "
                                                    }
                                                  >
                                                    {td.priceOptionName ===
                                                      "Price Range"
                                                      ? " - "
                                                      : td.price
                                                        ? moneySymbol + td.price
                                                        : "N/A"}
                                                  </td>
                                                ) : td.storeName ===
                                                  "MarketHigh" ? (
                                                  <td
                                                    className={
                                                      HclassName + " mh "
                                                    }
                                                  >
                                                    {td.priceOptionName ===
                                                      "Price Range"
                                                      ? " - "
                                                      : td.price
                                                        ? moneySymbol + td.price
                                                        : "N/A"}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                              </React.Fragment>
                                            );
                                          })}
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td colSpan="3" align="center">
                                    N/A
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </ReactTable>
                        </div>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <Col>
                      <Typography variant="body1" className="no-data p-4">
                        {isLoading === false
                          ? "There are no competitors available in this market."
                          : ""}
                      </Typography>
                    </Col>
                  )}
              </Row>
              <div className="pageclearfix"></div>
            </div>
            <div class="page">
              <Row className="m-0">
                <Col md="12" id="cpanalysis" className="history-graph">
                  <div className="ms_charthead pt-3">
                    <h6>{this.state.activeTab === 4 ? "Current Effective Rates History" : "Current Advertised Rates History"} </h6>
                    <p>
                      View historical rates for this unit type over the last 5
                      days, 30 days, 90 days, 6 months or 1 year, choose the
                      time range from the drop down menu. You can also view
                      rates by pricing type - Premium, Value or both. See the
                      All Market View section below to learn how you can change
                      the competitors you can compare against on this graph.
                      These rates are based on the Advertised Online Rate.
                      <br />
                      *Your store prices are included in the calculations.
                    </p>
                    {/* <HelpTextPopup parm={defaultProps1} /> */}
                    <AvForm>
                      <AvField
                        type="select"
                        className="mr-3 ml-3"
                        onChange={this.onDurAndPriChange}
                        value={this.state.hisactivemenu}
                        name="hisactivemenu"
                        id="hisactivemenu"
                      >
                        <option value={"1"}>Premium</option>
                        <option value={"2"}>Price Range</option>
                        <option value={"3"}>Value</option>
                      </AvField>
                      <AvField
                        type="select"
                        className="mr-3 ml-3"
                        onChange={this.onDurAndPriChange}
                        value={this.state.hisactivedur}
                        name="hisactivedur"
                        id="hisactivedur"
                      >
                        {unitDurations && unitDurations.length > 0 ? (
                          unitDurations.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.timePeriod}
                            </option>
                          ))
                        ) : (
                          <option value={"2"}>0 Days</option>
                        )}
                      </AvField>
                    </AvForm>

                    {
                      filterData.length > 0 ?   <div className="duplicate-data-show">
                            <img src={duplicate} />
                     <div>
                          {
                            filterData.map((item)=>{
                              return(
                                <>
                                <div className="legend-box-color" style={{background: item.color }}></div>
                                <span>{item.name}</span>
                                </>
                              )
                            })
                          }
                          
                
                          <b> has same value in the series.</b> 
                          </div>
                  </div> : ""

                    }
                    <div className="clear"></div>
                  </div>
                </Col>

                <div className="mainhisGraphdiv col-md-12">
                          <div className="hisGraph legend-space">
                          <Chart options={this.state.advertisedRatesHistoryOptions} series={this.state.advertisedRatesHistorySeries} width={980}  height={350} />
                          </div></div>
              </Row>
              <div className="pageclearfix"></div>
            </div>
            {/* <div className="print-view"> */}
            <div>
              {trproductRows
                ? trproductRows.map((rows, i) => (
                  <div className="page">
                    <Row className="m-0">
                      {i == 0 ? (
                        <Col md="12" id="cpgrid">
                          <div className="ms_charthead pt-3 market-view-head">
                            <h6 className="mb-1" style={{ display: "block" }}>
                              All Market View{" "}
                              <div
                                className={storeAdvertising ? "text-right store-calc-info-cont" : "text-right"}
                                style={{
                                  color: "#5a6e82",
                                  fontSize: "12px",
                                  float: "right",
                                  fontWeight: "500",

                                }}
                              >
                                <div className="mb-1">
                                  <i>
                                    *Your store prices are included in the
                                    calculations.
                                  </i>
                                </div>
                                {storeAdvertising ? <div>
                                  <i><sup style={{ color: 'red', fontSize: '12px', top: '0em' }}>*</sup>This store is not currently advertising a rate for this unit</i>
                                </div> : ''}
                              </div>
                            </h6>
                            <p
                              style={{
                                fontSize: "12px",
                                marginBottom: "0px",
                              }}
                            > {this.state.activeTab === 4 ? <>
                              These are all the stores in this Market offering
                              this unit type. Select up to a total of 8
                              competitor stores listed below to compare
                              against in the Current Effective rate analysis section
                              or Historical pricing view. You can remove any
                              stores from the graph at any time.</>
                              : <>These are all the stores in this Market offering
                                this unit type. Select up to a total of 8
                                competitor stores listed below to compare
                                against in the Current Pricing analysis section
                                or Historical pricing view. You can remove any
                                stores from the graph at any time.</>}
                            </p>

                            <AvForm>
                              <AvField
                                type="select"
                                className="mr-3 ml-3"
                                onChange={this.onDurAndPriChange}
                                value={this.state.gridactivedur}
                                name="gridactivedur"
                                id="gridactivedur"
                              >
                                {unitDurations && unitDurations.length > 0 ? (
                                  unitDurations.map((unit) => (
                                    <option key={unit.id} value={unit.id}>
                                      {unit.timePeriod}
                                    </option>
                                  ))
                                ) : (
                                  <option value={2}>0 Days</option>
                                )}
                              </AvField>
                            </AvForm>
                            <div className="clear"></div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12 col-md-12">
                        <div
                          style={{
                            display: "block",
                            overflow: "hidden",
                            textAlign: "right",
                          }}
                        >

                          <div className="selected-stores-legend">
                            <div className="symbol"></div>
                            <div className="name">
                              Selected Competitor Stores
                              {CONFIG.RegionId === 3 ? " | " : ""}
                            </div>
                          </div>
                          {CONFIG.RegionId === 3 ? (
                            <p
                              className="sqft-disclaimer mb-0 text-right"
                              style={{
                                marginTop: "12px",
                                fontSize: "13px",
                                display: "inline-block",
                              }}
                            >
                              {" "}
                              * This rate is calculated from an advertised
                              monthly rate
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="ms_table pd-3 display-grid">
                          <ReactTable
                            className="mst all-market-table activity-table"
                            responsive="true"
                          >
                            <EnhancedTableHead
                              activeTabs={activeTab}
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={this.handleRequestSort}
                              rowCount={
                                GridTableValue.dynamdata
                                  ? GridTableValue.dynamdata.length
                                  : 0
                              }
                            />
                            {GridTableValue.dynamdata ||
                              GridTableValue.statdata ? (
                              <tbody>
                                {i === 0 && GridTableValue.statdata ? (
                                  GridTableValue.statdata.map(
                                    (row, index) => {
                                      const labelId = index;
                                      let promocountMax =
                                        row.promoCount &&
                                          row.promoCount !== null &&
                                          row.promoCount > 60
                                          ? "Annexure -" + row.annexure
                                          : "";
                                      let ys_prom_tions =
                                        row.promos && row.promos !== null
                                          ? row.promos.replace(/~/g, " ")
                                          : "No specials";
                                      return (
                                        <tr
                                          key={row.storeID}
                                          className="disabled_color"
                                        >
                                          <td
                                            className="S_captalize"
                                            align="center"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                          >
                                            <span className="store-names">
                                              {row.storeName}
                                            </span>
                                            <span className="stores-address current-pr-store">
                                              {row.storeAddress}
                                            </span>
                                          </td>
                                          <td align="center">
                                            {row.currentPremium !== null &&
                                              row.currentPremium !== 0
                                              ? moneySymbol +
                                              row.currentPremium
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.currentPremium !== 0 && row.currentPremium !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td>
                                          <td align="center">
                                            {row.currentValue !== null &&
                                              row.currentValue !== 0
                                              ? moneySymbol + row.currentValue
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.currentValue !== 0 && row.currentValue !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td>
                                          <td align="center">
                                            {row.premium !== null &&
                                              row.premium !== 0
                                              ? moneySymbol + row.premium
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.premium !== 0 && row.premium !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td>
                                          <td align="center">
                                            {row.value !== null &&
                                              row.value !== 0
                                              ? moneySymbol + row.value
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.value !== 0 && row.value !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td>
                                          <td align="center">
                                            <div
                                              dangerouslySetInnerHTML={
                                                row.promoCount &&
                                                  row.promoCount > 60
                                                  ? { __html: promocountMax }
                                                  : { __html: ys_prom_tions }
                                              }
                                            />
                                          </td>
                                          {activeTab !== 4 ? <td align="center">
                                            {row.currentWalkInPremium !==
                                              null &&
                                              row.currentWalkInPremium !== 0
                                              ? moneySymbol +
                                              row.currentWalkInPremium
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.currentWalkInPremium !== 0 && row.currentWalkInPremium !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td> : ''}
                                          {activeTab !== 4 ? <td align="center">
                                            {row.currentWalkInValue !==
                                              null &&
                                              row.currentWalkInValue !== 0
                                              ? moneySymbol +
                                              row.currentWalkInValue
                                              : "N/A"}
                                            {row.isMonthly &&
                                              row.isMonthly === 1 &&
                                              CONFIG.RegionId === 3 ? (
                                              <sup>*</sup>
                                            ) : (
                                              ""
                                            )}
                                            {row.currentWalkInValue !== 0 && row.currentWalkInValue !== null &&
                                              row.isNotCurrentlyAdvertised === true ?
                                              <sup style={{ color: 'red' }}>*</sup> : ''}
                                          </td> : ''}
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr className="disabled_color na_row">
                                    <td>N/A</td>
                                  </tr>
                                )}
                                {GridTableValue.dynamdata ? (
                                  this.stableSort(
                                    rows,
                                    this.getSorting(order, orderBy)
                                  ).map((row1, index) => {
                                    const labelId = index;
                                    let promocountMax =
                                      row1.promoCount &&
                                        row1.promoCount !== null &&
                                        row1.promoCount > 60
                                        ? "Annexure -" + row1.annexure
                                        : "";
                                    let prom_tions =
                                      row1.promos && row1.promos !== null
                                        ? row1.promos.replace(/~/g, " ")
                                        : "No specials";
                                    return (
                                      <tr
                                        key={row1.storeID}
                                        className={
                                          row1.isSelected === true
                                            ? "selected-market"
                                            : ""
                                        }
                                      >
                                        <td
                                          className="S_captalize"
                                          align="center"
                                          id={labelId}
                                          scope="row"
                                          padding="none"
                                        >
                                          <span className="store-names">
                                            {row1.storeName}
                                          </span>
                                          <span className="stores-address current-pr-store">
                                            {row1.storeAddress}
                                          </span>
                                        </td>
                                        <td align="center">
                                          {row1.currentPremium !== null &&
                                            row1.currentPremium !== 0
                                            ? moneySymbol +
                                            row1.currentPremium
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.currentPremium !== 0 && row1.currentPremium !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td>
                                        <td align="center">
                                          {row1.currentValue !== null &&
                                            row1.currentValue !== 0
                                            ? moneySymbol + row1.currentValue
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.currentValue !== 0 && row1.currentValue !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td>
                                        <td align="center">
                                          {row1.premium !== null &&
                                            row1.premium !== 0
                                            ? moneySymbol + row1.premium
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.premium !== 0 && row1.premium !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td>
                                        <td align="center">
                                          {row1.value !== null &&
                                            row1.value !== 0
                                            ? moneySymbol + row1.value
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.value !== 0 && row1.value !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td>
                                        <td align="center">
                                          <div
                                            dangerouslySetInnerHTML={
                                              row1.promoCount &&
                                                row1.promoCount > 60
                                                ? { __html: promocountMax }
                                                : { __html: prom_tions }
                                            }
                                          />
                                        </td>
                                        {activeTab !== 4 ? <td align="center">
                                          {row1.currentWalkInPremium !==
                                            null &&
                                            row1.currentWalkInPremium !== 0
                                            ? moneySymbol +
                                            row1.currentWalkInPremium
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.currentWalkInPremium !== 0 && row1.currentWalkInPremium !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td> : ''}
                                        {activeTab !== 4 ? <td align="center">
                                          {row1.currentWalkInValue !== null &&
                                            row1.currentWalkInValue !== 0
                                            ? moneySymbol +
                                            row1.currentWalkInValue
                                            : "N/A"}
                                          {row1.isMonthly &&
                                            row1.isMonthly === 1 &&
                                            CONFIG.RegionId === 3 ? (
                                            <sup>*</sup>
                                          ) : (
                                            ""
                                          )}
                                          {row1.currentWalkInValue !== 0 && row1.currentWalkInValue !== null &&
                                            row1.isNotCurrentlyAdvertised === true ?
                                            <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </td> : ''}
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr className="na_row">
                                    <td>N/A</td>
                                  </tr>
                                )}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td className="no-data" colSpan="9">
                                    {isLoading === false
                                      ? "There are no competitors available in this market."
                                      : ""}
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </ReactTable>
                        </div>
                      </div>
                    </Row>
                    <div class="printclearfix"></div>
                  </div>
                ))
                : ""}
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackOpen}
              className="snackbar bg-color-primary custom-snackbar"
              maxsnack={3}
              autoHideDuration={30000}
              onClose={this.snackHandleclose.bind(this)}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{snackMSg}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.snackHandleclose.bind(this)}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </div>
        </div>
        <div
          className="annexure"
          style={
            checkIfAnnexureEmptyStat &&
              checkIfAnnexureEmptyStat.length === 0 &&
              checkIfAnnexureEmpty &&
              checkIfAnnexureEmpty.length === 0
              ? { display: "none" }
              : { display: "block" }
          }
        >
          {annexureFilters && annexureFilters.length !== 0
            ? annexureFilters.map((rows, i) => (
              <div className="page-annexure finalResultSet">
                {GridTableValue.dynamdata || GridTableValue.statdata ? (
                  <div>
                    {GridTableValue && rows.length !== 0
                      ? rows.map((row1, annexure) => {
                        const labelId = annexure;
                        let prom_tions =
                          row1.promos && row1.promos !== null
                            ? row1.promos.replace(/~/g, " | ")
                            : "No specials";
                        if (row1.promoCount > 60) {
                          let promoIndex = row1.annexure + ") : ";
                          return (
                            <div key={row1.storeID}>
                              <p className="bold-text mb-1">
                                Annexure({promoIndex}
                              </p>
                              <div
                                className="annexure-text"
                                dangerouslySetInnerHTML={{
                                  __html: prom_tions,
                                }}
                              />
                            </div>
                          );
                        }
                      })
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
            : ""}
        </div>
      </div>
    );
  }
}
//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, activeTabs } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headRows = [
    { id: "storeName", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "currentPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Premium",
    },
    {
      id: "currentValue",
      numeric: true,
      disablePadding: false,
      label: "Current Value",
    },
    {
      id: "premium",
      numeric: true,
      disablePadding: false,
      label: "Highest Premium Price Offered",
    },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Lowest Value Price Offered",
    },
    { id: "promos", numeric: true, disablePadding: false, label: "Promotion" },
    {
      id: "currentWalkInPremium",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Premium",
    },
    {
      id: "currentWalkInValue",
      numeric: true,
      disablePadding: false,
      label: "Current Walk-in Value",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => {
          if (activeTabs === 4 ? (row.id !== 'currentWalkInValue' && row.id !== 'currentWalkInPremium') : true) {
            return (
              <TableCell
                key={row.id}
                align="center"
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                  disabled={row.id !== "moreoptions" ? false : true}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  );
}
