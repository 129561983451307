import React from 'react';
import './CompReports.css'
import { Col, Row, Modal, ModalHeader, ModalBody, Button, FormText, Label } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import NumberFormat from 'react-number-format';
import EmailShare from 'react-email-share-link'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { CONFIG, MatchingCountryCode } from '../../utils/config';
import { COMMON } from '../../utils/common';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RefreshIcon from '@material-ui/icons/Refresh';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default class CompReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            snackOpen: false,
            snackMsg: '',
            snackClr: 'snack-error',
            isModalCheck: false,
            compData: [],
            isEnabled: this.props.isGreyOut,
            isActiveStatus: this.props.isActiveStatus,
            reciepients: [],
            isLoading: false,
            isFreqAlert: false,
            compImage: '',
            isModal: false,
            compId: null,
            selFrequency: 0,
            subChoice: "1",
            daysOut: 1,
            isDayAlert: false,
            compStatus: null,
            formValid: true,
            dayOfMonth: "",
            showDays: "1",
            frequencyMonth:[],
            dayOutMonth: [],
            selectedDaysOut: []
        };
        this.compReportSubmit = this.compReportSubmit.bind(this);
       // this.snackOpen = this.snackOpen.bind(this);
       // this.snackHandleclose = this.snackHandleclose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFreChange = this.handleFreChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
    }
    modalOpen = () => this.setState({ isModal: !this.state.isModal })
    modalClose = () => this.setState({ isModal: !this.state.isModal })
    compModalOpen = () => {
        this.setState(state => ({ modal: !state.modal }));
        this.loadCompData();
    }

    cancelCompReport = () => this.setState(state => ({ modal: !state.modal }))

    componentDidMount() {
        if (typeof window.dataLayer != "undefined" && this.props.isCompOpen) {
            window.dataLayer.push({
                "event": "show popup",
                'eventCategory': 'popup',
                'eventAction': 'show',
                'eventLabel': this.state.popupName,
            });
        }
    }

    //load comp report data
    loadCompData() {
        this.setState({ isLoading: true })
        const data = {
            UserStoreId: this.props.storeId,
            RegionId: CONFIG.RegionId
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'dashboard/userstorecompreport', data)
            .then(response => {
                const compData = response.data.data;
                let radioCheck = (compData.daysOutMonth.dayOfMonth == 0 || compData.daysOutMonth.dayOfMonth == '' || compData.daysOutMonth.dayOfMonth == null) ? false : true; 
                let selectedDaysOut = compData.daysOut.filter(days => days.value == 1);
                let showDays = selectedDaysOut.length > 0 ? selectedDaysOut[0].id : 0;
                let dayOutMonth = compData.daysOutMonth.daysOut.filter(days => days.value == 1);
                let frequencyMonth = compData.daysOutMonth.frequency.filter(days => days.value == 1);
                let reciepients = response.data.data.info != null && response.data.data.info.reciepients != null ? response.data.data.info.reciepients.split(',') : [];
                let compImage = response.data.data.info.compReportSample;
                this.setState({ compData, reciepients, compImage, isLoading: false, frequencyMonth, dayOutMonth ,selectedDaysOut, showDays, subChoice: radioCheck ? '2' : '1' });
            })
            .catch((err) => {
                this.setState({ isLoading: false })
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    handleChange(e, value) {
        let index;
        let compData = this.state.compData;
        if (e.target.checked) {
            index = compData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                // if(e.target.name == "frequency"){
                //     compData.daysOutMonth[e.target.name][index].value = 1;
                // }else{
                    compData[e.target.name][index].value = 1;
               // }
               
            }
        }
        else if (!e.target.checked) {
            index = compData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                // if(e.target.name == "frequency"){
                //     compData.daysOutMonth[e.target.name][index].value = 0;
                // }else{
                compData[e.target.name][index].value = 0;
           // }
            }
        }
        this.setState({ compData });

        // Data Validation
        let data = this.state.compData
        let freq = data.frequency.filter(element => element.value == 1)
        if (freq.length == 0) {
            this.setState({ isFreqAlert: true })
        } else {
            this.setState({ isFreqAlert: false })
        }
    }

    // function for activate and deactivate report
    updateStatus = (active, comp, id) => {
        let data = {
            UserStoreId: id,
            ActivityReport: active == null || active == 0 ? 0 : 1,
            CompReport: comp == null || comp == 4 ? 0 : 1
        }
        this.setState({ compId: data.CompReport });
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'market/updateactivitycompreport', data)
            .then(response => {
               // this.setState({ reciepients: []});
               this.state.reciepients = [];
                if (this.props.isFrom == "LEFT_NAV") {
                    this.props.parentMethod(this.props.storeId);
                } else {
                    this.props.compRefresh();
                }
                // if (this.state.compId == 1) {
                //     document.getElementById('btn-submit-form-active-comp').click();
                // } else {
                //     if (this.props.isFrom == "LEFT_NAV") {
                //         this.props.parentMethod(this.props.storeId);
                //     } else {
                //         this.props.compRefresh();
                //     }
                // }
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }


    compReportSubmit(event, errors, values) {
        let formValid = this.state.formValid;
        formValid = this.state.showDays == 3 && this.state.subChoice == 2 ? this.state.compData.daysOutMonth.dayOfMonth != "" && this.state.compData.daysOutMonth.dayOfMonth != null ? true : false : true;
        if (errors.length == 0 && formValid) {
            this.setState({ isLoading: true, compStatus: values.daysOut && values.daysOut != '' ? values.daysOut : 0 });
            const data = this.state.compData;
            //Data Validation
            let freq = this.state.showDays == 2 ? data.frequency.filter(element => element.value == 1) : [0];
            let reciepients = [values.Recipient1, values.Recipient2, values.Recipient3, values.Recipient4, values.Recipient5];
            reciepients = reciepients.filter(item => item);
            data["info"] = {
                "UserStoreId": this.props.storeId,
                "RegionId": CONFIG.RegionId,
                "Reciepients": reciepients.toString(),//[values.Recipient1,values.Recipient2,values.Recipient3,values.Recipient4,values.Recipient5].toString()
            }
            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            if (freq.length >= 1 &&  formValid) {
                axios.post(CONFIG.API_URL + 'dashboard/updatecompreport', data)
                    .then(response => {
                        this.setState({ modal: false, isLoading: false}, () => {
                            // if (this.props.isFrom == "LEFT_NAV") {
                            //     this.props.parentMethod(this.props.storeId);
                            // } else {
                            //     this.props.compRefresh();
                            // }
                            this.updateStatus(this.props.isActiveStatus, this.state.compStatus, this.props.storeId)
                        })
                        this.setState({ snackOpen: true, snackMsg: 'The changes have been saved Successfully', snackClr: 'snack-success' })
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false })
                        if (err.response.status === 401) {
                            localStorage.removeItem('accessKey');
                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                            this.props.history.push(from);
                        }
                        else {
                            alert(err.response.data);
                        }
                    })
            } else {
                this.setState({ isFreqAlert: true })
                document.getElementById("days").focus({ preventScroll: false });
            }
        }else{
            if(this.state.subChoice == 2 && this.state.dayOfMonth == ""){
            this.setState({formValid: false});
        }
        }
    }
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    // snackOpen() {
    //     this.setState({ snackOpen: true, snackMsg: 'Activate by checking on My Markets Page' });
    // }

   //selectedFrequency onChange event
   handleFreChange(e, value) {
    let index;
    let compData = this.state.compData;
    let isDayAlert = this.state.isDayAlert;
    //Check for No of days below 31
    if (e.target.name === "dayOfMonth") {
      e.target.value = e.target.value.replace(/[^\w\s]/gi, "");
      if (e.target.value.length > 2) {
        e.target.value = e.target.value.substring(
          0,
          Math.min(e.target.value.length, 2)
        );
      }
      if (parseInt(e.target.value) > 31 || e.target.value == "0") {
        e.target.value = "";
        isDayAlert = true;
      } else {
        isDayAlert = false;
      }
      // this.setState({isDayAlert});
      compData.daysOutMonth[e.target.name] = e.target.value;
    } else {
      if (e.target.name === "dayOutMonth") {
        index = compData.daysOutMonth["daysOut"].findIndex(
          (obj) => obj.id == parseInt(e.target.value)
        );
        if (index >= 0) {
          compData.daysOutMonth["daysOut"][index].value = 1;
          compData.daysOutMonth["daysOut"].forEach((val, i) => {
            if (val.id != parseInt(e.target.value))
              compData.daysOutMonth["daysOut"][i].value = 0;
          });
        }
      } else {
        index = compData.daysOutMonth[e.target.name].findIndex(
          (obj) => obj.id == parseInt(e.target.value)
        );
        if (index >= 0) {
          compData.daysOutMonth[e.target.name][index].value = 1;
          compData.daysOutMonth[e.target.name].forEach((val, i) => {
            if (val.id != parseInt(e.target.value))
              compData.daysOutMonth[e.target.name][i].value = 0;
          });
        }
      }
    }
    this.setState({formValid: true, isDayAlert});

}

    //Daysout selection handlechange
    selectChoice(choice) {
        let selChoice;
        let compData = this.state.compData;
        if (choice == 1) {
            selChoice = 1;
            compData.daysOutMonth.dayOfMonth = ""; 
        } else {
            selChoice = 2;
        }
        this.setState({ subChoice: selChoice });
    }

    handleDayChange(e, value) {
        let index;
        let compData = this.state.compData;
        if(e.target.value !=''){
            index = compData[e.target.name].findIndex((obj => obj.id == parseInt(e.target.value)));
            if (index >= 0) {
                    compData[e.target.name][index].value = 1;
                    compData[e.target.name].forEach((val, i) =>{
                        if(val.id != parseInt(e.target.value ))
                        compData[e.target.name][i].value = 0;
                    }); 
              }
        this.setState({showDays: e.target.value, isFreqAlert: false, isDayAlert: false});
            }
    }


    render() {
        const { modal, snackOpen, compData, reciepients, snackMsg, isFreqAlert, snackClr, compImage, selFrequency, subChoice, selDaysOut, isDayAlert, showDays, frequencyMonth, dayOutMonth, selectedDaysOut } = this.state;
        return (
            <div className="missing-Competitor">
                {/* <Link to="#" onClick={this.props.isGreyOut != 1 ? () => this.snackOpen() : this.compModalOpen.bind(this)} className="go-back-btn list-item-text man-act-repo-menu"><span>Manage Comp Reports</span></Link> */}
                {this.props.isFrom == "LEFT_NAV" ?
                    <Link to="#" onClick={this.compModalOpen.bind(this)} className="go-back-btn list-item-text man-act-repo-menu"><span>Manage Competitor Reports</span></Link>
                    : this.props.isGreyOut == 1 ? <p onClick={this.compModalOpen.bind(this)} className="setup-text sch"> Scheduled</p> : <p onClick={this.compModalOpen.bind(this)} className="setup-text not-sch"> Set up </p>}
                {/* <Link to="#" onClick={this.compModalOpen.bind(this)} className="go-back-btn list-item-text man-act-repo-menu"><span>Manage Comp Reports</span></Link> */}
                <Modal backdrop="static" isOpen={modal} toggle={this.cancelCompReport.bind(this)} id="missing-Competitor-modal" className={this.props.className} size="lg">
                    <ModalHeader Header toggle={this.cancelCompReport.bind(this)} className="pb-1">Manage Competitor Reports</ModalHeader >
                    <ModalBody className="misCompmodalBody">
                        <AvForm onSubmit={this.compReportSubmit.bind(this)} className="enter-acc-details">
                            <Row className="margin-right-0 mb-0 comp-report-activity-pop">
                                <Col lg={6} md={6} className="list-item-text p-t3-b5">
                                    <Row>
                                        <Col>
                                            {/* <h3 className="report-pop-title">When do you want to receive this report?<br /><span><i>(uncheck to exclude any day)</i></span></h3> */}
                                            {/* {isFreqAlert ? <p style={{ color: '#F15D47', opacity: '1' }}>Please select at least one day from the list below.</p> : ''} */}
                                            {/* <Row style={{margin: '0px', marginTop: '10px'}} className="weekly-list">
                                            {compData.frequency && compData.frequency.length > 0 ? compData.frequency.map(data => {
                                                return(
                                                    <Col xs={6} sm={4}>
                                                        <FormControlLabel 
                                                        control={<Checkbox                           
                                                                    checked={data.value==1}
                                                                    onChange={this.handleChange} 
                                                                    name="frequency"
                                                                    value={data.id}
                                                                    className="move-ch-top track-check-gl"
                                                                    id="frequency"
                                                                />} 
                                                        label={data.name} 
                                                        />
                                                    </Col>
                                                    )
                                                }) 
                                                : ''
                                            }
                                           </Row> */}
                                            <div className="weekly-list align-items-center">
                                            When do you want to receive this report?
                                            <Row style={{marginTop: '5px'}} className="weekly-refresh">
                                                <Col xs={6} sm={3} className="pr-0" style={{marginTop: '5px'}}>
                                                    <RefreshIcon /> Repeat
                                                </Col>
                                                <Col xs={6} sm={4} className="pl-0">
                                                <AvField type="select" name="daysOut" id="days"
                                                value={compData && selectedDaysOut.length > 0  ? selectedDaysOut[0].id : ''}  onChange={this.handleDayChange} >
                                                                                    {/* <option value='' >--Select DaysOut--</option> */}
                                                        {compData.daysOut && compData.daysOut.length > 0 ? compData.daysOut.map((daysOut) =>
                                                                                            <option key={daysOut.id} selected={selectedDaysOut.length > 0 && selectedDaysOut[0].id == daysOut.id ? true : false}  value={daysOut.id}>{daysOut.name}
                                                                                            </option>
                                                                                        ) : ''}
                                                    </AvField>
                                                </Col>
                                                </Row>
                                            </div>
                                            {/* <p className="please-note">Please note that Comp Reports are typically emailed between 8-9am your local time</p> */}
                                        </Col>
                                    </Row>
                                    {showDays == 2 ?
                                        <Row style={{ margin: '0px', marginTop: '10px' }} className="weekly-list">Please select which day(s) to receive this report
                                        {compData && compData.frequency && compData.frequency.length > 0 ? compData.frequency.map(data => {
                                            return (
                                                <Col xs={6} sm={4}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={data.value == 1}
                                                            onChange={this.handleChange}
                                                            name="frequency"
                                                            value={data.id}
                                                            id="frequency"
                                                            className="move-ch-top track-check-gl"
                                                        />}
                                                        label={data.name}
                                                    /></Col>)
                                        }) : ''}
                                        
                                                {isFreqAlert ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px', marginTop: '0px' }}>Please select at least one day from the list below.</p> : ''}
                                               
                                        </Row> : showDays == 3 ?
                                        <Col className="p-0 mt-2">
                                            <Row>
                                                <Col md={12}>
                                                Please select which day to receive this report
                                                </Col>
                                                <Col xs={12}>
                                                    <Row className="align-items-center">
                                                        <Col xs={1} className="pr-0">
                                                            <FormControlLabel  className="radio_cls" control={<Radio onChange={() => this.selectChoice('1')} checked={subChoice == '1' ? 'checked' : ''} />} />
                                                        </Col>
                                                        <Col xs={5} className="">
                                                        <AvField type="select" name="dayOutMonth" disabled={subChoice == 2} onChange={this.handleFreChange}
                                                        value={compData && dayOutMonth.length > 0  ? dayOutMonth[0].id : ''}>
                                                                    {compData && compData.daysOutMonth.daysOut && compData.daysOutMonth.daysOut.length > 0 ? compData.daysOutMonth.daysOut.map((state) =>
                                                                    <option key={state.id} selected={dayOutMonth.length > 0 && dayOutMonth[0].id == state.id ? true : false} value={state.id}>{state.name}</option>
                                                                ) : ''}
                                                            </AvField>
                                                        </Col>
                                                        <Col xs={5} className="pl-0">
                                                        <AvField type="select" name="frequency" disabled={subChoice == 2} onChange={this.handleFreChange}
                                                        value={compData && frequencyMonth.length > 0  ? frequencyMonth[0].id : ''} >
                                                                {compData && compData.daysOutMonth.frequency && compData.daysOutMonth.frequency.length > 0 ? compData.daysOutMonth.frequency.map((state) =>
                                                                    <option key={state.id} selected={frequencyMonth.length > 0 && frequencyMonth[0].id == state.id ? true : false} value={state.id}>{state.name}</option>
                                                                ) : ''}
                                                            </AvField>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col xs={1}>
                                                    <FormControlLabel
                                                        className="radio_cls"
                                                        control={<Radio
                                                            checked={subChoice == '2' ? 'checked' : ''}
                                                            onChange={() => this.selectChoice('2')} />}
                                                    />
                                                </Col>
                                                <Col xs={10}>
                                                    <Row className="align-items-center">
                                                        <Col xs={1} className="pr-0">Day</Col>
                                                        <Col xs={2} className="pr-0">
                                                        <input  type="number" value={compData && compData.daysOutMonth.dayOfMonth != null && compData.daysOutMonth.dayOfMonth != 0 ? compData.daysOutMonth.dayOfMonth : ''}  maxLength="2" disabled={subChoice == 1 ? true : false} name="dayOfMonth"  onChange={this.handleFreChange} className="count-type" />
                                                        </Col>
                                                        <Col className="pl-2">of every Month</Col>
                                                    </Row>
                                                    
                                                </Col>
                                                <Col xs={12}>{this.state.formValid ? isDayAlert ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px' }}>Please Enter Valid Day Count.</p> : '' : this.state.subChoice == 2 ? <p style={{ color: '#F15D47', opacity: '1', marginBottom: '0px' }}>Day is required</p> : ''}</Col>
                                            </Row>
                                        </Col> : ''} <p className="please-note" style={{marginTop: '10px'}}>Please note that Competitor Reports are typically emailed between 8-9am your local time</p>
                                    <div className="active-comp-report-mail-box">
                                        <h3 className="report-pop-title">Recipients <span className="pl-3"><i> Add up to 5 recipients </i></span></h3>
                                        <AvField name="Recipient1" placeholder="Required" label="Send to:" type="text"
                                            value={reciepients && reciepients[0] != "" ? reciepients[0] : ''}
                                            validate={{
                                                required: { value: true, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient2" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[1] != "" ? reciepients[1] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient3" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[2] != "" ? reciepients[2] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient4" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[3] != "" ? reciepients[3] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <AvField name="Recipient5" placeholder="Optional" label="Send to:" type="text"
                                            value={reciepients && reciepients[4] != "" ? reciepients[4] : ''}
                                            validate={{
                                                required: { value: false, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, errorMessage: 'Email Address is Invalid' }
                                                // pattern: { value: /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                            }}
                                        />
                                        <div className="form-submits">
                                            <Col className="changes-btn step_btn text-right p-0">
                                                <Button id="btn" onClick={this.cancelCompReport.bind(this)} className="submit-btn btn-design go-back-btn">Cancel</Button>
                                                {/* <Button className="submit-btn next-btn btn-design mr-2 activate-deactivate-btn" onClick={() => { this.updateStatus(this.props.isActiveStatus, this.props.isGreyOut, this.props.storeId) }}>{this.props.isGreyOut != 1 ? 'Schedule' : 'Deactivate'}</Button> */}
                                                <Button id="btn" className="submit-btn next-btn btn-design mr-0">Save</Button>
                                                {/* <Button id="btn-submit-form-active-comp" className="submit-btn next-btn btn-design mr-0" style={{ display: 'none' }} ></Button> */}
                                            </Col>
                                        </div>
                                    </div>

                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="active-comp-report-info-box">
                                        <h6 className="miss-sub">Competitor Reports direct to your inbox</h6>
                                        <p>Competitor Reports give you a full market competition analysis in an easy-to-ready spreadsheet, delivered into your inbox daily</p>
                                        <p>You can set the frequency so you receive an updated Competitor report on the days you wish.</p>
                                        <p>If you have suggestions, questions or comments, let us know..</p>
                                        <Row>
                                            <Col xs={8} className="pr-0">
                                                <div className="phone">
                                                    <span>Call: </span>
                                                    <a href={"tel:" + CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free}> <NumberFormat value={CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free} displayType={'text'} format="#-###-###-####" /></a>
                                                </div>
                                                <div className="email">
                                                    <span>Email: </span>
                                                    <EmailShare email={CONFIG.Mail_Support} subject="" body="">
                                                        {link => (
                                                            <a href={link} data-rel="external">{CONFIG.Mail_Support}</a>
                                                        )}
                                                    </EmailShare>
                                                </div>
                                            </Col>
                                            <Col xs={4} className="pl-0 pr-0">
                                                <Button size="sm" style={{ fontSize: '10px' }} className="submit-btn next-btn btn-design activate-deactivate-btn"
                                                    // href={
                                                    //     CONFIG.RegionId == COMMON.StorTrackRegion.US && (this.props.selectedCountry == "US" || this.props.selectedCountry == "CA") ?
                                                    //         require("./comp-report/sample-comp-report-us.xlsx")
                                                    //         : CONFIG.RegionId == COMMON.StorTrackRegion.US && (this.props.selectedCountry != "US" || this.props.selectedCountry != "CA") ?
                                                    //             require("./comp-report/sample-comp-report-eur.xlsx")
                                                    //             : CONFIG.RegionId == COMMON.StorTrackRegion.UK ?
                                                    //                 require("./comp-report/sample-comp-report-uk.xlsx")
                                                    //                 : CONFIG.RegionId == COMMON.StorTrackRegion.NZ ?
                                                    //                     require("./comp-report/sample-comp-report-nz.xlsx")
                                                    //                     : CONFIG.RegionId == COMMON.StorTrackRegion.AUS ?
                                                    //                         require("./comp-report/sample-comp-report-aus.xlsx")
                                                    //                         : "javascript:void(0)"
                                                    // }
                                                    // href={
                                                    //     pdfName && pdfName != undefined ?
                                                    //     require(`./comp-report/${pdfName}`) :
                                                    //     require("./comp-report/sample-comp-report-us.xlsx")
                                                    // }
                                                    onClick={this.modalOpen.bind(this)}
                                                >
                                                    view sample
                                                </Button>
                                                <Modal size="lg" isOpen={this.state.isModal} toggle={this.modalClose.bind(this)} className="sample-report-excel">
                                                    <Button color="danger" className="custom-colse-modal-btn" onClick={this.modalClose.bind(this)}>&times;</Button>
                                                    <ModalBody>
                                                        {/* <div id="light45" style="top:10%; height:auto; width:61%; overflow:auto; display:block;">
                                                        </div> */}
                                                        <img
                                                            src={
                                                                compImage && compImage != undefined ? CONFIG.OPTZ_URL + compImage : '/sample-comp-report-us.PNG'
                                                            }
                                                            className="modal-comp-img"
                                                            alt=""
                                                        />
                                                    </ModalBody>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackOpen}
                    id={snackClr}
                    className="snackbar bg-color-primary"
                    maxsnack={5}
                    autoHideDuration={3000}
                    onClose={this.snackHandleclose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}