import React from 'react';
import './QuoteMarket.css';
import { HashLink as Link } from 'react-router-hash-link';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Label, FormText, Button } from 'reactstrap';
import FormGroup from '@material-ui/core/FormGroup';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import Snackbar from '@material-ui/core/Snackbar';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'

export default class QuoteMarket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Open: false,
      phoneErr: '',
      emailErr: '',
      isLoading1: false,
      phone: '',
      apiErr: '',
      phoneMaxLength:CONFIG.PhoneMaxLength
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.ModalClickOpen = this.ModalClickOpen.bind(this);

  }
  ModalClickOpen() {
    this.handleClickOpen();
  }
  phoneKeyPressed = (event) => {
    this.setState({ keyCode: event.keyCode });
}
snackHandleclose = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  this.setState({ snackOpen: false });
};
onPhoneChangeML = (event) => {
  var string = event.target.value;
  var replacedString = event.target.value; //event.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
  var numericRegex = /^[ 0-9*()+-]*$/;///^([0-9]*)$/;
  var valid = numericRegex.exec(replacedString);
  var keyCode = this.state.keyCode;
  let phoneErr = '';// 'Phone is invalid';
  let phoneFieldBorder = '0';// '1';

  if (valid) {
      string = replacedString;
      // var regex = CONFIG.PhoneRegex;
      // var match = regex.exec(string);
      // if (match) {
      //     match.shift();
      //     match[0] = "(" + match[0] + ")";
      //     string = match.join("-");
          phoneErr = '';
          phoneFieldBorder = '0';
     // }
  } else {
      if (keyCode !== 8 && keyCode !== 46) {
          string = this.state.phone;
      }
  }

  if (string === "") {
      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
  }
  this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
}
onPhoneChangesML = (event) => {
  var string = event;
  let phoneErr = '';// 'Phone is invalid';
  let phoneFieldBorder = '0';// '1';
  if (string === "" || string === undefined) {
      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
  }
  this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
}
  multpleLocationSubmit(event, errors, values) {
    let formValid = true;


    //Phone number validation starts
    let phoneErr = '';
    let phoneFieldBorder = '0';
    if (this.state.phone === "") {

      phoneErr = 'Phone is required';
      phoneFieldBorder = '1';
      formValid = false;
    } else if (this.state.phone.length < CONFIG.PhoneMinLength) {
      phoneErr = 'Phone is invalid';
      phoneFieldBorder = '1';
      formValid = false;
    }
    let noofstores = this.props.parm;

    this.setState({ phoneErr, phoneFieldBorder, noofstores, apiErr: '' });

    //Phone number validation end


    if (errors.length > 0) {
      formValid = false;
    }
    if (formValid) {
      if (errors.length > 0) {
        console.log(errors);
      }
      else {
        let data = {
          "fName": values.fName.trim(),
          "lName": values.lName.trim(),
          "company": values.company.trim(),
          "phone": this.state.phone.replace(/[^\d]/g, ''),
          "email": values.email.trim(),
          "noofstores": this.props.parm,
          "RegionID":CONFIG.RegionId
        }
        this.setState({ isLoading1: true });
        axios.post(CONFIG.API_URL + 'subscription/getquote', data)
          .then(res => {

            this.myFormRef && this.myFormRef.reset();
            this.setState({ phoneErr: '', phoneFieldBorder: '', phone: '', apiErr:'' });
            let msg = "Thank you for submitting your request. We will contact you shortly regarding your 100+ subscription request.";
            this.setState({Open:false, isLoading1: false});
            this.setState({ snackOpen: true, snackMSg: msg });
            this.props.parentMethod()
            
           
          })
          .catch(err => {
          
            let msg = err.response.data.Message;
            this.setState({ isLoading1: false, apiErr: msg });
            // this.props.parentMethod()
          });
      }
    }

  }
  resetAllValues() {
    this.myFormRef && this.myFormRef.reset();
    this.setState({ phoneErr: '', phoneFieldBorder: '', phone: '', apiErr: '' });
  }
  handleClickOpen() {
    this.setState({
      Open: true
    })
  }

  handleClose() {
    this.setState({
      Open: false, phoneErr: '', phoneFieldBorder: '', phone: '', apiErr: ''
    })
    this.props.parentMethod()
  }



  render() {
    const { Open, isLoading1, snackMSg, snackOpen } = this.state;

    return (
      <div>
        {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
      <Dialog
        open={Open}
        className="SingleMarket landing-stepper landing-popup"
        // onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle align="center">
          <div>
            <h3 className="multi-h3">Quote for StorTrack Optimize</h3>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="closeModal"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="multi-location enter-acc-details">
            <p>You have requested pricing for <span className="st-color">100</span> or more markets. To complete this request, please fill out and submit the form below or call us at <span className="st-black">{CONFIG.Toll_Free} {CONFIG.RegionId === 3 ? "UK Direct tel:" +CONFIG.Sales_Support : ''}</span> for assistance. We will contact you soon to discuss your specific needs.</p>

            <AvForm onSubmit={this.multpleLocationSubmit.bind(this)} ref={c => (this.myFormRef = c)}>

              <Label for="m_fName">Full Name</Label><AvField name="fName" id="m_fName" type="text" validate={{ required: { value: true, errorMessage: 'Full Name is required' }, maxLength: { value: 25, errorMessage: 'Your Full Name must be between 25 or below 25 characters' } }} />
              <Label for="m_lName">Last Name</Label><AvField name="lName" id="m_lName" type="text" validate={{ required: { value: true, errorMessage: 'Last Name is required' }, maxLength: { value: 25, errorMessage: 'Your Last Name must be between 25 or below 25 characters' } }} />
              <Label for="m_company">Company</Label><AvField name="company" id="m_company" type="text" validate={{ required: { value: true, errorMessage: 'Company is required' }, maxLength: { value: 100, errorMessage: 'Your Company Name must be between 100 or below 100 characters' } }} />
              <Label for="m_company">Phone Number</Label> 
              <PhoneInput  name="phone" id="m_phone" value={this.state.phone}  onChange={this.onPhoneChangesML} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
              <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
              <Label for="m_company">E-mail Address</Label><AvField name="email" id="m_company" type="email" validate={{ required: { value: true, errorMessage: 'E-mail Address is required' }, pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'E-mail Address is Invalid' } }} />
              {/* <AvField name="noofstores" id="m_company" 
              value={this.props.parm}
               type="hidden" /> */}
               <FormText className='errorMsg' color="red">{this.state.apiErr}</FormText>
              <FormGroup className="multiple-sub-quotes step_btn">
                {/* <Button className="edit_cancel" onClick={this.handleBack.bind(this)} variant="contained" className="reset-cancel left-cancel">Cancel</Button> */}
                <Link to="#" onClick={this.resetAllValues.bind(this)} className="go-back-btn reset-btns">Reset</Link>
                <Button type="submit" className="next-btn btn btn-secondary">Submit </Button>
              </FormGroup>
            </AvForm>

          </div>
        </DialogContent>

      </Dialog>
      <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={snackOpen}
                            className="snackbar bg-color-primary custom-snackbar"
                            maxsnack={3}
                            autoHideDuration={30000}
                            onClose={this.snackHandleclose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMSg}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.snackHandleclose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />
      </div>
    );
  }
}
