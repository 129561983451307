import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Button } from "reactstrap";
//import './MapViews.css';
//import { Gmaps, Marker, InfoWindow } from 'react-gmaps';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { MarketProfile } from "../MarketProfile";
import { CONFIG } from "../../utils/config";
import orangepin from "./../../Assets/Images/orange-pin.png";
import blackpin from "./../../Assets/Images/black-pin.png";
import bluepin from "./../../Assets/Images/blue-pin.png";
import orangehome from "./../../Assets/Images/orange-home.png";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";
import moment from "moment";
//const params = { v: '3.exp', key: 'AIzaSyDaYQZ0es5K-kLgDuZuTqRHKiTDENEMxxQ' };

export default class MapViewsGmap extends React.Component {
    static defaultProps = {
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=" +
            CONFIG.GoogleMapsApiKey +
            "",
    };
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            mapData: [],
            showingInfoWindow: false,
            storeId: "",
            searchId: "",
            test: "",
            storeName: "",
            storeid: 0,
            isLoading: false,
            snackOpen: false,
            snackMSg: "",
            snackClr: "",
            zoomValue: 6,
        };
        this.getParams = this.getParams.bind(this);
        this.mapRef = React.createRef();
    }
    CMap = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap
                ref={this.mapRef}
                defaultZoom={this.state.zoomValue}
                defaultCenter={{
                    lat: parseFloat(
                        this.props.centerPoint &&
                            this.props.centerPoint.length > 0 &&
                            this.props.centerPoint[0].latitude != null
                            ? this.props.centerPoint[0].latitude
                            : this.props.location.latitude
                    ),
                    lng: parseFloat(
                        this.props.centerPoint &&
                            this.props.centerPoint.length > 0 &&
                            this.props.centerPoint[0].longitude != null
                            ? this.props.centerPoint[0].longitude
                            : this.props.location.longitude
                    ),
                }}
                defaultOptions={{
                    zoomControl: true,
                    fullscreenControl: false,
                    streetViewControl: false,
                    disableDefaultUI: false,
                    mapTypeControl: true,
                }}
            >
                {props.children}
            </GoogleMap>
        ))
    );

    getParams() {
        let strName = this.state.storeName;
        let strID = this.state.storeid;
        if (strName && strID) {
            this.child.current.toggleModal(strName, strID);
        }
    }

    onMarkerClick = (name, address, id, lat, lng, size) => {
        let sam = [],
            uniqsize = [];
        this.state.mapData.map((test) => {
            if (test.storeId === id) {
                sam.push(test.size);
            }
        });
        const uniqueNames = Array.from(new Set(sam));
        console.log(uniqueNames, "uniqsize");
        var units = uniqueNames.join(", ");
        var add = address;
        var last = add.lastIndexOf(",");
        var s1 = add.substring(0, last);
        var s2 = add.substring(last + 1);
        var storedata = this.state.FData;
        var tFData = storedata.filter((a) => a.StoreID == id);
        var StandardOnlinePrice = tFData[0].StandardOnlinePrice;
        var StandardRegPrice = tFData[0].StandardRegPrice;
        //setTimeout(() => {
        this.setState({
            storeName: name,
            lat: lat,
            lng: lng,
            address: address ? s1 : "",
            address1: address ? s2 : "",
            regPrice:
                StandardRegPrice != this.props.curr + 0 ? StandardRegPrice : "N/A",
            onlinePrice:
                StandardOnlinePrice != this.props.curr + 0
                    ? StandardOnlinePrice
                    : "N/A",
            size: size ? units : "N/A",
            storeid: id,
            showingInfoWindow: true,
        });
        // },500)
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.bca != this.props.bca) {
            this.state.mapData = [];
            this.setState(
                {
                    isLoading: true,
                    showingInfoWindow: false,
                    bca: nextProps.bca,
                    abc: nextProps.abc,
                },
                () => {
                    this.componentDidMount();
                }
            );
        }
    }

    setMapFitBounds = (mapData) => {
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < mapData.length; i++) {
            let loc = new window.google.maps.LatLng(mapData[i].latitude, mapData[i].longitude);
            bounds.extend(loc);
        }
        let homeLoc = new window.google.maps.LatLng(this.props.location.latitude, this.props.location.longitude);
        bounds.extend(homeLoc);
        this.mapRef.current.fitBounds(bounds);
    }

    mapView() {
        // const data=
        // {
        //   "UserStoreID":129229,
        //   "SearchID":965256
        // }
        localStorage.setItem("StId", this.props.abc);
        const data = {
            UserStoreID: this.props.abc,
            SearchID: this.props.bca,
            RegionID: CONFIG.RegionId,
            IsMapBox: CONFIG.IsMapbox
        };
        let token = localStorage.getItem("accessKey");
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }

        axios
            .post(CONFIG.API_URL + "unit/unitlevelmapview", data)
            .then((res) => {
                let mapData = res.data.data.unitLevelDatas;
                if (mapData.length) this.setMapFitBounds(mapData);
                let uniquestore = [...new Set(mapData.map((item) => item.storeId))];
                var FData = [];
                for (var t = 0; t < uniquestore.length; t++) {
                    var tFData = mapData.filter((a) => a.storeId == uniquestore[t]);
                    var mData = {
                        StoreID: uniquestore[t],
                        StoreAddress: tFData[0].address,
                        PremiumRegPrice: Math.max.apply(
                            Math,
                            tFData.map(function (o) {
                                return o.regPrice;
                            })
                        ),
                        // StandardRegPrice:(Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
                        StandardRegPrice:
                            Math.max.apply(
                                Math,
                                tFData.map(function (o) {
                                    return o.regPrice;
                                })
                            ) ===
                                Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.regPrice;
                                    })
                                )
                                ? this.props.curr +
                                Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.regPrice;
                                    })
                                )
                                : this.props.curr +
                                (Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.regPrice;
                                    })
                                ) +
                                    " - " +
                                    Math.max.apply(
                                        Math,
                                        tFData.map(function (o) {
                                            return o.regPrice;
                                        })
                                    )),
                        //old
                        // StandardRegPrice : Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) ? this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) : this.props.curr + (Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+ this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
                        ValueRegPRice: Math.min.apply(
                            Math,
                            tFData.map(function (o) {
                                return o.regPrice;
                            })
                        ),
                        PremiumOnlinePrice: Math.max.apply(
                            Math,
                            tFData.map(function (o) {
                                return o.onlinePrice;
                            })
                        ),
                        // StandardOnlinePrice:(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
                        StandardOnlinePrice:
                            Math.max.apply(
                                Math,
                                tFData.map(function (o) {
                                    return o.onlinePrice;
                                })
                            ) ===
                                Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.onlinePrice;
                                    })
                                )
                                ? this.props.curr +
                                Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.onlinePrice;
                                    })
                                )
                                : this.props.curr +
                                (Math.min.apply(
                                    Math,
                                    tFData.map(function (o) {
                                        return o.onlinePrice;
                                    })
                                ) +
                                    " - " +
                                    Math.max.apply(
                                        Math,
                                        tFData.map(function (o) {
                                            return o.onlinePrice;
                                        })
                                    )),
                        //old
                        // StandardOnlinePrice: Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) ? this.props.curr+ Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) : this.props.curr +(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
                        ValueOnlinePRice: Math.min.apply(
                            Math,
                            tFData.map(function (o) {
                                return o.onlinePrice;
                            })
                        ),
                    };
                    FData.push(mData);
                }
                //let lat= mapData != '' ? mapData[0].latitude:0;
                //let lng= mapData != '' ? mapData[0].longitude:0;
                this.setState({ mapData, FData, isLoading: false });
            })

            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem("accessKey");
                    const { from } = this.props.location.state || {
                        from: { pathname: "/" },
                    };
                    this.props.history.push(from);
                } else {
                    alert(err.response.data);
                }
            });
    }

    onMarkerClose = () => {
        this.setState({ showingInfoWindow: false });
    };

    componentDidMount() {
        let zoomValue;
        if (this.props.location.searchType === 3) {
            zoomValue = 10;
        } else if (this.props.location.searchType === 2) {
            let zCnt =
                this.props.location.storeZoneCoverage != "" &&
                    this.props.location.storeZoneCoverage != null
                    ? this.props.location.storeZoneCoverage.split(",").length - 1
                    : 1;
            zoomValue = zCnt > 1 ? 9 : zCnt > 0 ? 10 : 12;
        } else if (this.props.location.searchType === 1) {
            let storeZoneCoverage = this.props.location.storeZoneCoverage.split(" ");
            zoomValue =
                storeZoneCoverage[0] === "1" ||
                    storeZoneCoverage[0] === "2" ||
                    storeZoneCoverage[0] === "3"
                    ? 13
                    : storeZoneCoverage[0] === "4" ||
                        storeZoneCoverage[0] === "5" ||
                        storeZoneCoverage[0] === "6"
                        ? 12
                        : storeZoneCoverage[0] === "6" || storeZoneCoverage[0] === "7"
                            ? 11
                            : storeZoneCoverage[0] === "8" ||
                                storeZoneCoverage[0] === "9" ||
                                storeZoneCoverage[0] === "10"
                                ? 10
                                : 6;
        }
        this.setState({ isLoading: true, zoomValue });
        this.mapView();
    }
    downLoadPDF() {
        // this.setState({ isLoading: true });
        var currentDate = moment(new Date()).format("DD-MMM-YYYY");
        let usId = parseInt(this.props.abc);
        let pta = parseInt(this.props.bca);
        let lat = this.props.location.latitude;
        let lng = this.props.location.longitude;
        let accessKey = localStorage.getItem("SecretKey");
        var timestamp =
            new Date().getDate() +
            "" +
            new Date().getMonth() +
            "" +
            new Date().getFullYear() +
            "" +
            new Date().getTime();
        //construct filename for pdf report.
        var fileName = usId + "MapView" + "_" + timestamp + ".pdf";
        var stoName =
            this.props.storeDetails &&
                this.props.storeDetails.storeName &&
                this.props.storeDetails.storeName !== null &&
                this.props.storeDetails.storeName !== "" &&
                this.props.storeDetails.storeName !== undefined
                ? this.props.storeDetails.storeName
                : "Your Store";
        var fileNamePdf = stoName + "_" + "MapView" + "_" + currentDate + ".pdf";
        this.setState({
            snackClr: "snack-info",
            snackOpen: true,
            snackMSg:
                "Your download is being processed. The " +
                fileNamePdf +
                " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
        });
        let ComponentName = 11;
        const Input = {
            RegionID: CONFIG.RegionId,
            URL:
                CONFIG.OPTZ_URL +
                "render-pdf/" +
                accessKey +
                "/" +
                ComponentName +
                "/0/" +
                usId +
                "/" +
                pta +
                "/" +
                lat +
                "/" +
                lng +
                "/",
            FileName: fileName,
        };
        // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
        axios({
            url: CONFIG.API_URL + "dashboard/generatepdfbypage",
            method: "post",
            responseType: "blob",
            data: Input,
        })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNamePdf;
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(link.href);
                    link.remove();
                }, 3000);
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }
    snackHandleclose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ snackOpen: false });
    };
    render() {
        const {
            mapData,
            locationInfoData,
            FData,
            isLoading,
            snackOpen,
            snackMSg,
        } = this.state;
        const defaultProps = {
            helpTitle: "Map View",
            helpContent:
                "<p>The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store. </p><p><i>*Your store prices are included in the calculations.</i></p>",
        };
        return (
            <div className="wrapper my-account-box">
                {isLoading ? (
                    <div className="loader-wrap">
                        <div className="loading"></div>
                    </div>
                ) : (
                    ""
                )}
                <MarketProfile ref={this.child} />
                {/* {isLoading ? <div className="loading"></div> : ''} */}
                <div className="mt-3 is-it-store">
                    <Col
                        md="12"
                        className="p-v-15 border-box shadow-sm rounded padding-bottom-0"
                    >
                        <div className="float-right-help">
                            <Button
                                disabled={!isLoading ? false : true}
                                className="mb-1 apply-btn download-pdf "
                                onClick={this.downLoadPDF.bind(this)}
                                style={{ float: "none" }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} />
                                PDF Download
                            </Button>
                            <HelpTextPopup parm={defaultProps} />
                        </div>
                        <div className="clear"></div>
                        <Row className="mt-2">
                            <Col md="12">
                                <Fragment>
                                    <this.CMap
                                        googleMapURL={this.props.googleMapURL}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `600px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    >
                                        <Marker
                                            position={{
                                                lat: parseFloat(this.props.location.latitude),
                                                lng: parseFloat(this.props.location.longitude),
                                            }}
                                            icon={orangehome}
                                        />
                                        {mapData
                                            ? mapData.map((marker) => {
                                                return (
                                                    <Marker
                                                        position={{
                                                            lat: parseFloat(marker.latitude),
                                                            lng: parseFloat(marker.longitude),
                                                        }}
                                                        icon={
                                                            marker.isUserStore == 1
                                                                ? orangepin
                                                                : marker.isUserStore == 0 &&
                                                                    marker.storeStatus == 1
                                                                    ? bluepin
                                                                    : blackpin
                                                        }
                                                        onMouseOver={() =>
                                                            this.onMarkerClick(
                                                                marker.storeName,
                                                                marker.address,
                                                                marker.storeId,
                                                                marker.latitude,
                                                                marker.longitude,
                                                                marker.size
                                                            )
                                                        }
                                                    >
                                                        {this.state.showingInfoWindow &&
                                                            this.state.storeid === marker.storeId ? (
                                                            <InfoWindow
                                                                onCloseClick={() => this.onMarkerClose()}
                                                                visible={this.state.showingInfoWindow}
                                                            >
                                                                <div className="gmap-tooltip color-white">
                                                                    <table class="map-table">
                                                                        <tr>
                                                                            <td class="address-map-top">
                                                                                <b>
                                                                                    <a onClick={this.getParams}>
                                                                                        {this.state.storeName}
                                                                                    </a>
                                                                                </b>
                                                                                <p>
                                                                                    {this.state.address},<br />
                                                                                    {this.state.address1}
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table>
                                                                        <tr>
                                                                            <td class="label-list">
                                                                                Walk-in Rate:
                                                                            </td>
                                                                            <td>{this.state.regPrice}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="label-list">Online Rate:</td>
                                                                            <td>{this.state.onlinePrice}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="label-list">Unit Type:</td>
                                                                            <td class="state-size">
                                                                                {this.state.size}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </InfoWindow>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Marker>
                                                );
                                            })
                                            : ""}
                                    </this.CMap>
                                </Fragment>
                            </Col>
                        </Row>
                        <Col md="12" className="margin-2 map-btm-legend">
                            <Row>
                                <div className="legend-block">
                                    <div className="maps-legend-orange"></div>Your Store
                                </div>
                                <div className="legend-block">
                                    <div className="maps-legend-blue"></div>Competitor
                                </div>
                                <div className="legend-block">
                                    <div className="maps-legend-black"></div>Rate Data Unavailable
                                </div>
                            </Row>
                        </Col>
                    </Col>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackOpen}
                    className={
                        this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
                    }
                    maxsnack={3}
                    autoHideDuration={30000}
                    onClose={this.snackHandleclose.bind(this)}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                    message={<span id="message-id">{snackMSg}</span>}
                />
            </div>
        );
    }
}