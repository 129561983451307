import React from "react";
import "./PricingVolatility.css";
import { CONFIG, MatchingCountryCode } from "../../utils/config";
import axios from "axios";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import duplicate from "../../Assets/Images/duplicate.png"
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table as TableBoots,
} from "reactstrap";
import shows from "./../../Assets/Images/show.png";
import hiddens from "./../../Assets/Images/unshow.png";
import downarrows from "./../../Assets/Images/down-arrow.png";
import { CompetitorStore } from "../CompetitorStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { map } from "d3";
import resetPng from '../../Assets/Images/reset.png';

export default class PricingVolatility extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      options: {
        chart: {
          id: 'apexchart-price-volatility',
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 3,
        },
        markers: {
          size: 4,
        },
        grid: { show: false },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
        },
        axisTicks: {
          show: false
        }
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [],

      snackOpen: false,
      snackMSg: "",
      marketVolatility: "",
      marketStorePrice: "",
      isLoading: false,
      pdfLoading: false,
      unitType: "",
      priceOption: "",
      dashboardBasicInfo: "",
      volatilityGrpahData: "",
      isDefaultUnit: "",
      isDefaultPrice: "All",
      showGrpahData: "",
      isSelectIdPush: [],
      isShowGraph: [],
      isYourStore: [],
      isRemoveGrapg: [],
      isSelectCount: [],
      locationInfoData: [],
      isdefaultSelected: 1,
      isNoGraphData: false,
      isCompsName: [],
      order: "asc",
      orderBy: "storeName",
      isNoData: false,
      typePDF: "",
      sIdPDF: "",
      snackClr: "",
      duplicate_filterData: false,
      filterData:[],
      new_filterData:[]
    };
    this.getParams = this.getParams.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
  }

  getParams(storeAddress) {
    let storeId = parseInt(this.props.match.params.userstoreid);
    this.child.current.toggleStoreModal(storeAddress, storeId);
  }

  //Table Sorting
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    let sort = isDesc ? "asc" : "desc";
    this.setState({ order: sort, orderBy: property });
  }

  //Table Sorting order
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  //Table Sorting the selected Column
  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Table Sorting Order Request
  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }

  loadDashBoard() {
    let token = localStorage.getItem("accessKey");
    const data = {
      UserStoreID: parseInt(this.props.match.params.userstoreid),
      RegionId: CONFIG.RegionId,
    };
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "dashboard/dashboardlocation", data)
      .then((response) => {
        const locationInfoData = response.data.data.storeDashboard;
        var countrycode = response.data.data.storeDashboard.countryCode;
        var data1 = {
          CountryCode: countrycode,
          RegionId: CONFIG.RegionId,
        };
        axios
          .post(CONFIG.API_URL + "dashboard/lookupunit", data1)
          .then((response) => {
            let unitType = response.data.prices;
            this.state.isDefaultUnit = unitType[0].unitType;
            this.setState({
              unitType,
            });
          });
        this.setState({
          locationInfoData: locationInfoData,
        });
        const inputParam = {
          UserStoreID: parseInt(this.props.match.params.userstoreid),
          RegionId: CONFIG.RegionId,
          Competitors: "",
        };
        this.PricingVolatility(inputParam);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  componentDidMount() {

    this.setState({
      isLoading: true,
    });

    this.loadDashBoard();

    axios.post(CONFIG.API_URL + "dashboard/priceoptions").then((response) => {
      let priceOption = response.data.price;
      this.setState({
        priceOption,
      });
    });
  }
  PricingVolatility(inputParam) {
    let isShowGraph = [];
    let isRemoveGrapg = [];
    let isCompsName = [];
    let isYourStore = [];
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "dashboard/priceoverviewvolatility", inputParam)
      .then((response) => {
        const dashboardBasicInfo = response.data.data.dashboardBasicInfo;
        const volatilityGrpahData = response.data.data.volatilityGrpahData;
        const marketVolatility = response.data.data.unitVolatilityData;
        // Below I have using the filter function to finding the null values of all unit types. If any case all the units return the null values we have define the one boolean value to the state for showing the "No data found" in the pricing volatality grid.
        var checkNull = marketVolatility.filter(
          (filterData) => filterData.volatility !== null
        );
        if (checkNull.length === 0) {
          this.setState({ isNoData: true });
        }
        // End of the filter function code.
        const marketStorePrice = response.data.data.allVolatilityData;
        const isUnitType = marketVolatility.filter((e) => e.volatility === 0);

        const isUnitTypeNotNull = marketVolatility.filter((e) => e.volatility);
        const isYourStCount = marketStorePrice.filter((e) => e.isMyStore);
        // const isUnitTypeNull = marketVolatility.filter((e => e.volatility===null));
        let marketStorePriceSort = [];
        marketStorePrice
          .filter((e) => e.isMyStore === true)
          .forEach(function (e) {
            isYourStore.push(e);
          });
        marketStorePrice
          .filter((e) => e.isMyStore === false)
          .forEach(function (e) {
            marketStorePriceSort.push(e);
          });
        marketStorePrice
          .filter((e) => e.isSelected === 1 && e.isMyStore != 1)
          .forEach(function (e) {
            isCompsName.push(e.storeName);
          });
        marketStorePrice
          .filter((e) => e.isSelected === 1)
          .forEach(function (e) {
            isShowGraph.push(e.storeId);
          });
        marketStorePrice
          .filter((e) => e.isSelected === 0)
          .forEach(function (e) {
            isRemoveGrapg.push(e.storeId);
          });
        if (isShowGraph === undefined) {
          isShowGraph = [];
        }
        if (isRemoveGrapg === undefined) {
          isRemoveGrapg = [];
        }
        if (isYourStore === undefined) {
          isYourStore = [];
        }
        this.setState({
          marketVolatility,
          isYourStCount,
          isUnitType,
          isYourStore,
          isUnitTypeNotNull,
          volatilityGrpahData,
          isCompsName,
          marketStorePrice: marketStorePriceSort,
          dashboardBasicInfo,
          isShowGraph,
          isRemoveGrapg,
          isLoading: false,
        });

        if (this.state.volatilityGrpahData.length > 0) {
          this.setState({
            isNoGraphData: false,
          });
          this.initialLoad(
            volatilityGrpahData.filter(
              (a) =>
                a.unitType == this.state.isDefaultUnit &&
                a.priceType == this.state.isDefaultPrice
            )
          );
        } else {
          this.setState({
            isNoGraphData: true,
          });
          this.apexChartNoData({
            textMessage: "No Data Found",
            colorCode: "#F60000"
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  apexChartNoData = ({ textMessage, colorCode }) => {
    this.setState({
      series: [],
      options: {
        xaxis: {
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        noData: {
          text: textMessage,
          style: { color: colorCode }
        }
      }
    });
  }

  initialLoad(res) {

    const { isCompsName } = this.state;
    var data = res;
    var przvoldality = res.filter((a) => a.volatility !== null);
    let isCompString = typeof (isCompsName) !== 'object' ? isCompsName : isCompsName.toString();
    this.setState({new_filterData : [], series:[]});
      var tempdata = [];
      let marketSeries = [];
      let colorsData = [];
      let widthArrayData = [];
      let dashArrayData = [];
      let marketNameList = [];
      let colorsCodeList = [];
      let markerSize = [];
      let unique = [...new Set(data.map((item) => item.storeId))];
      let MarketName = ["Your Store", "Market Average", isCompString];
      let MarketName2 = ["Market Average", isCompString];
      let colorsCode = ['#F15D47', '#1B2C42', '#41A3F6'];
      let colorsCode2 = ['#1B2C42', '#41A3F6'];
      for (var i = 0; i < unique.length; i++) {
        var datum = {
          name: unique[i],
          count: i,
          isMyMarket: this.popMyStore(data, unique[i]),
          values: this.popData(data, unique[i]),
        };
        tempdata.push(datum);
      }
      const toTop = (key, value) => (a, b) => (b[key] === value) - (a[key] === value);
      tempdata.sort(toTop('isMyMarket', 1));
      tempdata.every(val => {
        marketNameList = val.isMyMarket === 1 ? MarketName : MarketName2;
        colorsCodeList = val.isMyMarket === 1 ? colorsCode : colorsCode2;
      })

      
      let newFilter_tempdata=[];
      for (var k = 0; k < tempdata.length; k++) {
        let noValues = tempdata[k].values.every(item => item.totalChangeCount === '' || item.totalChangeCount === undefined);
        if (!noValues) {
          let dataSeries = tempdata[k].values.map(val => { return { x: val.monthYear, y: val.totalChangeCount, originData: val.totalChangeCount }; });
          let dataSeries1 = tempdata[k].values.map(val => { return { x: val.monthYear, y: val.totalChangeCount !== null && val.totalChangeCount !== "0.00" ? val.totalChangeCount : 0, originData: val.totalChangeCount !== null && val.totalChangeCount !== "0.00" ? val.totalChangeCount : 0 }; });
         newFilter_tempdata.push({name :marketNameList[k] , data: dataSeries1, color:colorsCodeList[k]})
          let containsNull = dataSeries.every(val => val.y !== null);
          if (tempdata[k].isMyMarket === 1) {
            if (!containsNull) {
              let noZeroData1 = dataSeries.map(yData => { return { x: yData.x, y: yData.y === null ? 0 : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: (marketNameList[k] + " data"), data: noZeroData1, color: colorsCodeList[k] });
              let noZeroData = dataSeries.map(yData => { return { x: yData.x, y: yData.y === 0 ? null : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: marketNameList[k], data: noZeroData, color: colorsCodeList[k] });
              markerSize.push(0, 10);
              widthArrayData.push(1, 3);
              dashArrayData.push(6, 0);
            } else {
              marketSeries.push({ name: marketNameList[k], data: dataSeries, color: colorsCodeList[k] });
              markerSize.push(10);
              widthArrayData.push(3);
              dashArrayData.push(0);
            }
          } else {
            if (!containsNull) {
              let noZeroData1 = dataSeries.map(yData => { return { x: yData.x, y: yData.y === null ? 0 : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: (marketNameList[k] + " data"), data: noZeroData1, color: colorsCodeList[k] });
              let noZeroData = dataSeries.map(yData => { return { x: yData.x, y: yData.y === 0 ? null : yData.y, originData: yData.originData }; });
              marketSeries.push({ name: marketNameList[k], data: noZeroData, color: colorsCodeList[k] });
              markerSize.push(0, 10);
              widthArrayData.push(1, 3);
              dashArrayData.push(6, 0);
            } else {
              marketSeries.push({ name: marketNameList[k], data: dataSeries, color: colorsCodeList[k] });
              markerSize.push(10);
              widthArrayData.push(3);
              dashArrayData.push(0);
            }
          }
             
        }
      }
    //Duplicate Data
     let newData = [];
     if(przvoldality.length > 0){
     newFilter_tempdata.map(dataItem => dataItem.data.forEach(i => {
      if(i.y !== "" && i.y !== undefined){
        newFilter_tempdata.map(userItem => userItem.data.forEach(j => {
          if(j.y !== "" && j.y !== undefined){
            if((dataItem.name !== userItem.name) && (dataItem.color !== userItem.color)){
              if((i.y === j.y) && (i.x === j.x)){
                newData.push({name:dataItem.name ,color:dataItem.color});
               let new_filterData = newData.reduce((elements , obj , index)=>{  
                  let existingData = elements.find(element => 
                    element.name === obj.name  && element.color === obj.color
                  );
                  if(!existingData){
                    elements.push(obj);
                  }
                  return elements;
                }, []);
                this.setState({new_filterData : new_filterData});   
              }
            }
          }
        }))
      }
     })) 
    }else{
      this.setState({new_filterData :[]});
    }
   
     
      if (marketSeries.length > 0 && przvoldality.length > 0) {
        this.setState({
          options: {
            chart: {
              id: `apexchart-price-volatility${Math.random()}`,
              zoom: {
                enabled: true,
                type: "x",
                autoScaleYaxis: true,
                },
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin:false,
                    zoomout:false,
                    pan:false,
                    reset: false,
                  },
                },
              events: {
                legendClick: function (chartContext, seriesIndex, config) {
                  let marketName = config.config.series[seriesIndex].name + ' data';
                  if (config.config.series[seriesIndex - 1] !== undefined && config.config.series[seriesIndex - 1].name === marketName) chartContext.toggleSeries(marketName);
                },
                beforeZoom: function(chartContext, { xaxis,yaxis }) {
                  if(yaxis !== undefined || xaxis.min > 0) return chartContext.t.reset =`<img src=${resetPng} alt="Reset Zoom" width="20">`;
                },
             zoomed: function(chartContext, { xaxis, yaxis }) {
           if(yaxis === undefined || xaxis.min <= 0 || xaxis === null ) return chartContext.toolbar.t.reset = false;
              },
              mouseMove: function(event, chartContext, config) {
                var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
                let Value = tooltip !== null && tooltip.style.top ? parseInt(tooltip.style.top) : 0;
                var series = config.config.series.filter(item => item.data.length !== 0 && !item.name.endsWith('data'));
                  if (series.length == 1 && Value > 70) {
                    chartContext.el.classList.add('new_tooltip_name1')
                  }else if(series.length == 2 && Value > 37){
                    chartContext.el.classList.add('new_tooltip_name2')
                  }else if(series.length == 3 && Value > 3){
                    chartContext.el.classList.add('new_tooltip_name3')
                  }else{
                    chartContext.el.classList.remove('new_tooltip_name1')
                    chartContext.el.classList.remove('new_tooltip_name2')
                    chartContext.el.classList.remove('new_tooltip_name3')
                  }
            
            }
              }
            },
            grid: {
              show: false,
              row: {
                colors: ['#FFFFFF']
              },
              padding: {
                left: 20,
                right: 40 // Also you may want to increase this (based on the length of your labels)
              },
          
            },
            tooltip: {
              x: {
                show: true,
                formatter: function (value, { w }) {
                  if (typeof (w) === "object") {
                    const date = new Date("1/" + w.globals.categoryLabels[value - 1]);
                    let mon = moment(date, "MM-DD-YYYY").format('MMMM YYYY');
                    return `Volatality Percentage on ${mon}`;
                  }
                },
              },
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  if (!(w.config.series[seriesIndex].name).endsWith('data')) return typeof (w.config.series[seriesIndex].data[dataPointIndex]) === "object" ? w.config.series[seriesIndex].data[dataPointIndex].originData !== null ? Number(w.config.series[seriesIndex].data[dataPointIndex].originData).toFixed(2) + "%" : "N/A" : '';
                },
              },
              enabled: true,
              showForNullSeries: false,
              onDatasetHover: {
                highlightDataSeries: false,
              },
              style: {
                fontSize: '11px',
                fontFamily: "Roboto, Helvetica, Arial , sans-serif"
              },
            },
            stroke: {
              width: widthArrayData,
              dashArray: dashArrayData,
            },
            markers: {
              size: 4,
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  let val = value === null || value === undefined ? "N/A" : value.toFixed(0) + "%";
                  return val;
                },
                style: {
                  colors: '#8C9AA6',
                  fontSize: '10px'
                },
              },
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              position: 'bottom',
              horizontalAlign: 'center',
              fontSize: '11px',
              fontWeight: 500,
              showForNullSeries: true,
              showForZeroSeries: true,
              itemMargin: {
                horizontal: 10,
                vertical: 20,
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
              formatter: function (seriesName, opts) {
                if (!seriesName.endsWith('data')) return seriesName;
                return '';
              },
              markers: {
                width: markerSize,
                height: markerSize,
              }
            },
            xaxis: {
              title: {
                text: "--- No Data Available",
                offsetX: 400,
                offsetY: 20,
                style: {
                  fontSize: '11px',
                  fontWeight: 400,
                },
              },
              tooltip: { enabled: false },
              labels: {
                formatter: function (value) {
                  let val = value === undefined || typeof (value) !== "string" ? '' : value;
                  return val;
                },
                show: true,
                style: {
                  colors: '#8C9AA6',
                  fontSize: '10px'
                },
              },
              axisBorder: {
                show: true
            },
            axisTicks: {
              show: true
            }
            },
            noData: {
              text: 'Please enable any one of the below legend to view the graph.',
              style: {
                color: "#5b95f6",
              }
            },
          },
          series: marketSeries,
          isNoGraphData: false
        })
      } else {
        this.setState({isNoGraphData: true})
        this.apexChartNoData({
          textMessage: "No Data Found",
          colorCode: "#F60000"
        });
      }
  }

  popData(data, uid) {
    var tFData = data.filter((a) => a.storeId == uid);
    var tData = [];

    for (var i = 0; i < tFData.length; i++) {
      var CData = {
        date: new Date(tFData[i].year, tFData[i].month - 1, 1),
        volatility: tFData[i].volatility,
        month: tFData[i].month,
        isMyMarket: tFData[i].isMyMarket,
        totalChangeCount: tFData[i].totalChangeCount,
        totalCount: tFData[i].totalCount,
        monthYear: tFData[i].monthYear,
      };
      tData.push(CData);
    }

    return tData;
  }

  popMyStore(data, uid) {
    var tFData = data.filter((a) => a.storeId == uid && a.isMyMarket == 1);
    return tFData.length > 0 ? 1 : 0;
  }

  showGraph(sId, type, name) {
    this.state.sIdPDF = sId;
    this.state.typePDF = type;
    this.setState({
      isLoading: true,
    });

    const isShowGraph = this.state.isShowGraph;
    const isCount = this.state.isYourStCount;
    var isVal = 2;
    if (isCount.length === 0) {
      isVal = 1;
    } else {
      isVal = 2;
    }
    if (isShowGraph.length <= isVal) {
      var isSelectStoreId = [];
      var id;
      // var isShowData = this.state.marketStorePrice;
      //var isShowG = isShowData.filter(a => a.isSelected == "1");
      // for (const item of isShowG) {
      //   var id = item.storeId;
      // }
      // const isShowGraph = this.state.isShowGraph
      const isRemoveGrapg = this.state.isRemoveGrapg;
      let index;
      if (isShowGraph.length <= isVal) {
        if (type === 1) {
          this.state.isCompsName = name;
          id = sId;
          id = 0;
          isSelectStoreId.push(id);
          index = isShowGraph.indexOf(+sId);
          isShowGraph.splice(index, 1);
          isRemoveGrapg.push(+sId);
          const inputParam = {
            UserStoreID: parseInt(this.props.match.params.userstoreid),
            Competitors: isSelectStoreId.toString(),
            RegionId: CONFIG.RegionId,
          };

          this.onlyShowGraph(inputParam);
        }
      }
      if (isShowGraph.length < isVal) {
        this.state.isCompsName = [];
        if (type === 0) {
          this.state.isCompsName = name;
          if (id == undefined) {
            id = 0;
          }
          isSelectStoreId.push(id);
          isSelectStoreId = isSelectStoreId.concat(sId);
          index = isRemoveGrapg.indexOf(+sId);
          isRemoveGrapg.splice(index, 1);
          isShowGraph.push(+sId);
          const inputParam = {
            UserStoreID: parseInt(this.props.match.params.userstoreid),
            Competitors: isSelectStoreId.toString(),
            RegionId: CONFIG.RegionId,
          };
          this.onlyShowGraph(inputParam);
        }
      } else {
        this.setState({
          isLoading: false,
        });
        this.state.isSelectCount.pop();
        this.setState({
          snackOpen: true,
          snackMSg:
            "Please remove any of the selected markets to add new ones.",
        });
      }
      this.setState({
        isRemoveGrapg,
        isShowGraph,
      });
    }
  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  onlyShowGraph(inputParam) {
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(
        CONFIG.API_URL + "dashboard/priceoverviewvolatilitygraph",
        inputParam
      )
      .then((response) => {
        const showGrpahData = response.data.data;
        this.state.volatilityGrpahData = response.data.data;
        this.setState({
          showGrpahData,
          isLoading: false,
        });
        if (this.state.showGrpahData.length > 0) {
          this.initialLoad(
            showGrpahData.filter(
              (a) =>
                a.unitType == this.state.isDefaultUnit &&
                a.priceType == this.state.isDefaultPrice
            )
          );
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  onUnitChange(e) {
    this.state.isDefaultUnit = e.target.value;
    var res = "";
    res = this.state.volatilityGrpahData.filter(
      (a) =>
        a.unitType == this.state.isDefaultUnit &&
        a.priceType == this.state.isDefaultPrice
    );
    this.setState({ series: [] }, () => {
      this.apexChartNoData({
        textMessage: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
        colorCode: "#5b95f6"
      });
      this.initialLoad(res);
    });
    // this.initialLoad(res);
  }
  onPriceChange(e) {
    this.state.isDefaultPrice = e.target.value;
    var res = "";
    res = this.state.volatilityGrpahData.filter(
      (a) =>
        a.unitType == this.state.isDefaultUnit &&
        a.priceType == this.state.isDefaultPrice
    );
    this.setState({ series: [] }, () => {
      this.apexChartNoData({
        textMessage: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
        colorCode: "#5b95f6"
      });
      this.initialLoad(res);
    });
    // this.initialLoad(res);
  }
  downLoadPDF() {
    this.setState({
      // isLoading: true,
      pdfLoading: false,
    });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let accessKey = localStorage.getItem("SecretKey");
    let ComponentName = 3;
    let usId = parseInt(this.props.match.params.userstoreid);
    let pta = "1";
    let searchId = "1";
    let unitType = this.state.unitType.filter(
      (d) => d.unitType == this.state.isDefaultUnit
    );
    unitType = unitType[0].id;
    let priceType = this.state.isDefaultPrice;
    let orderBy = this.state.orderBy;
    let order = this.state.order;
    let showRemoveId = this.state.sIdPDF;
    let showRemoveType = this.state.typePDF;

    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "PricingVoltality" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.locationInfoData &&
        this.state.locationInfoData.storeName &&
        this.state.locationInfoData.storeName !== null &&
        this.state.locationInfoData.storeName !== "" &&
        this.state.locationInfoData.storeName !== undefined
        ? this.state.locationInfoData.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "PricingVoltality" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/" +
        showRemoveId +
        "/" +
        showRemoveType +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    const {
      marketVolatility,
      isYourStore,
      isUnitType,
      order,
      orderBy,
      isUnitTypeNotNull,
      marketStorePrice,
      isCompsName,
      isNoGraphData,
      locationInfoData,
      isRemoveGrapg,
      isShowGraph,
      dashboardBasicInfo,
      isLoading,
      pdfLoading,
      unitType,
      priceOption,
      isNoData,
      duplicate_filterData,
      filterData,
      new_filterData
    } = this.state;
    const defaultProps = {
      helpTitle: "Price Volatility",
      helpContent:
        "<h6>How aggressive is this store/competitor?</h6><ul><li>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing.</li><li> A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility.</li><li> Here you are shown the pricing volatility for this Market, Your Store (if available), the REITs in this Market, Multi-store operators and Single-store operators over the last 12 months.</li><li>Pricing Volatility is calculated by taking the number of times a pricing change occurred over the last 12 months for each store type.</li><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p></ul><iframe width='472' height='248' src='https://www.youtube.com/embed/EQQ7dSl3xNE' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps1 = {
      helpTitle: "Last 12 Months",
      helpContent:
        "<h6>How aggressive is this store/competitor?</h6><p>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing. A store which frequently raises and/or lowers their rates, will typically have a higher pricing volatility. Here you are shown the average monthly pricing volatility for Your Store (if available), against the Market average and any of the stores/competitors you select for comparison*, for the unit type and price type selected from the drop down menu.</p><p>Pricing Volatility is calculated by taking the number of times a pricing change occurred each month over the last 12 months.</p><p>*See Pricing Volatility by Store & Price Option to select a store you want to view on this graph</p><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    const defaultProps2 = {
      helpTitle: "Market Pricing Volatility Over Last 12 Months",
      helpContent:
        "<p>This shows the pricing volatility over the last 12 months for each of the 11 common unit types (across the entire Market). This helps you understand which unit types in the Market are priced more aggressively.</p><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    const defaultProps3 = {
      helpTitle: "Pricing Volatility by Store & Price Option",
      helpContent:
        "<p>This shows you all the stores in this Market along with their overall pricing volatility across all the unit types they advertise for the last 12 months. For comparison, we also show you the pricing volatility of the Premium unit rates vs. their Value unit rates.</p><p>Click on the link &#34;Show on graph&#34; to view this store's pricing volatility graph above over the last 12 months.</p><p><i>*Your store prices are included in the calculations.</i></p>",
    };

    return (
      <div>
        <Breadcrumbs
          separator="›"
          aria-label="Breadcrumb"
          className="breadcrumb-float-left breadcrumb-top"
        >


          {localStorage.getItem("isMVP_User") === "false" ? (
            <Link to={{ pathname: `/my-market/` }}>
              My Markets
            </Link>
          ) :
            ""
          }

          <Link to={{ pathname: `/market-dashboard/${this.props.match.params.userstoreid}` }} color="inherit" >Go to Market Dashboard</Link>
          <Typography component="h6" variant="headline" className="link-tag">
            Price Volatility
          </Typography>
        </Breadcrumbs>
        <Button
          disabled={!isLoading && !isNoGraphData ? false : true}
          className={
            !isNoGraphData
              ? "mb-2 apply-btn download-pdf "
              : "mb-2 download-pdf-disabled"
          }
          onClick={this.downLoadPDF.bind(this)}
        >
          <FontAwesomeIcon icon={faFilePdf} />
          PDF Download
        </Button>
        <div className="clearfix"> </div>
        <Row className="overview-header">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row className="ht-100">
                <Col sm="5" className="padding-right-8">
                  <h2 className="top-heading">
                    Price Volatility <HelpTextPopup parm={defaultProps} />
                  </h2>
                  {isLoading ? (
                    ""
                  ) : locationInfoData.compsCount === 0 ||
                    locationInfoData.compsCount === null ? (
                    <div className="align-stores-btm">
                      <p>Analyzing N/A</p>
                    </div>
                  ) : (
                    <div className="align-stores-btm">
                      <p>
                        Analyzing{" "}
                        <Link
                          onClick={() => this.getParams(locationInfoData)}
                          to="#"
                        >
                          {locationInfoData
                            ? parseInt(locationInfoData.compsCount) > 1
                              ? locationInfoData.compsCount + " stores"
                              : locationInfoData.compsCount + " store"
                            : "N/A"}
                        </Link>
                      </p>
                    </div>
                  )}
                  <CompetitorStore ref={this.child} />
                </Col>
                {isLoading === false || pdfLoading === false ? (
                  <Col sm="7" className="address-list pl-0">
                    <h3>
                      {locationInfoData &&
                        locationInfoData.storeName &&
                        locationInfoData.storeName !== null &&
                        locationInfoData.storeName !== ""
                        ? locationInfoData.storeName
                        : "Your Store"}
                    </h3>
                    <div
                      className={
                        locationInfoData && locationInfoData.storeZoneCoverage
                          ? locationInfoData.storeZoneCoverage.length > 24
                            ? "pd-btm-35"
                            : "pd-btm-20"
                          : "pd-btm-20"
                      }
                    >
                      <p className="m-0">
                        {locationInfoData &&
                          locationInfoData.storeAddress1 &&
                          locationInfoData.storeAddress1 !== null &&
                          locationInfoData.storeAddress1 !== ""
                          ? locationInfoData.storeAddress1 + ","
                          : ""}{" "}
                        {locationInfoData.storeAddress2
                          ? locationInfoData.storeAddress2 + ", "
                          : ""}{" "}
                        {locationInfoData && locationInfoData.storeCity
                          ? locationInfoData.storeCity + ", "
                          : locationInfoData.searchType === 1 &&
                            (locationInfoData.storeCity === "" ||
                              locationInfoData.storeCity === null) &&
                            (locationInfoData.storeZipCode === "" ||
                              locationInfoData.storeZipCode === null)
                            ? locationInfoData.latitude +
                            ", " +
                            locationInfoData.longitude +
                            ", "
                            : ""}
                        {locationInfoData.storeState
                          ? locationInfoData.storeState
                          : ""}{" "}
                        {locationInfoData
                          ? locationInfoData.storeZipCode
                          : "N/A"}
                      </p>
                    </div>
                    <div className="align-stores-btm">
                      <p className="m-0 pt-1">
                        Coverage:{" "}
                        {locationInfoData
                          ? locationInfoData.storeZoneCoverage
                          : "N/A"}
                      </p>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Col>
          <Col md="7" className="padding-left-0">
            <Col
              md="12"
              className="price-top-right border-box shadow-sm rounded"
            >
              <Row className="height-full">
                <Col>
                  {" "}
                  <div className="market-label">Market</div>
                  <div className="market-value">
                    {dashboardBasicInfo.market != null
                      ? dashboardBasicInfo.market + "%"
                      : isLoading === false
                        ? "N/A"
                        : ""}
                  </div>
                  {dashboardBasicInfo.market != null &&
                    dashboardBasicInfo.market ? (
                    <div className="btm-label">Last 12 months</div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Your Store</div>
                    <div className="other-values">
                      {dashboardBasicInfo.myStore != null
                        ? dashboardBasicInfo.myStore + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
                {locationInfoData.countryCode == "US" ? (
                  <Col className="common-col height-full">
                    <div className="middle-div">
                      <div className="other-labels">REIT's</div>
                      <div className="other-values">
                        {dashboardBasicInfo.reits != null
                          ? dashboardBasicInfo.reits + "%"
                          : isLoading === false
                            ? "N/A"
                            : ""}
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Multi-Ops</div>
                    <div className="other-values">
                      {dashboardBasicInfo.multiOps != null
                        ? dashboardBasicInfo.multiOps + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">Single-Ops</div>
                    <div className="other-values">
                      {dashboardBasicInfo.singleOps != null
                        ? dashboardBasicInfo.singleOps + "%"
                        : isLoading === false
                          ? "N/A"
                          : ""}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        {/* <div><Link to={"/market-level-data/pricing-volatility-pdf/"+this.props.match.params.userstoreid} color="inherit" className="link-tag">Download Pdf</Link></div> */}
        <div className="dashboard-layout last-months">
          <div className="App">
            <h4>
              Last 12 months <HelpTextPopup parm={defaultProps1} />
            </h4>

            <AvForm className="select-unit-type">
              <AvField
                className="custom-select-opt"
                type="select"
                name="unit"
                label=""
                helpMessage=""
                validate={{}}
                onChange={(e) => this.onUnitChange(e)}
              >
                {/* <option value='' >Unit Type</option> */}
                {unitType
                  ? unitType.map((unit) => (
                    <option
                      key={unit.id}
                      selected={this.state.isdefaultSelected}
                      value={unit.unitType}
                    >
                      {unit.unitType}
                    </option>
                  ))
                  : ""}
              </AvField>
              <AvField
                type="select"
                className="custom-select-opt"
                name="price"
                label=""
                helpMessage=""
                validate={{}}
                onChange={(e) => this.onPriceChange(e)}
              >
                {/* <option value='' >Price Option</option> */}
                {priceOption
                  ? priceOption.map((price) => (
                    <option
                      key={price.id}
                      selected={this.state.isdefaultSelected}
                      value={price.name}
                    >
                      {price.name}
                    </option>
                  ))
                  : ""}
              </AvField>
            </AvForm>
         
            
            
          
            <div className="clear"></div>
            {
              new_filterData.length > 0 ? <div className="duplicate-data-show">
              <img src={duplicate} />
              <div>
                          {
                            new_filterData.map((item)=>{
                              return(
                                <>
                                <div className="legend-box-color" style={{background: item.color }}></div>
                                <span>{item.name}</span>
                                </>
                              )
                            })
                          }
                          <b> has same value in the series. Please hover your mouse to see detailed information.</b> 
                  </div> </div> : ""
            } 

            <div className={isNoGraphData ? "fix-map price-map" : "price-map"}>
              <div id="Price-Volatility"></div>
            <div className="space_xaxis">
        {isNoGraphData ?
         <Chart options={this.state.options} series={this.state.series} type="line" style={{ width: "100%" }} height={100} />
         : <Chart options={this.state.options} series={this.state.series} type="line" style={{ width: "100%" }} height={250} />}


         </div>
            </div>
          </div>
          <div className="request-free-trial">
            {isLoading ? (
              <div className="loader-wrap">
                <div className="loading"></div>
              </div>
            ) : (
              ""
            )}
            <Col>
              <h5 className="bill-head">
                Current Market Volatility(last 12 months){" "}
                <HelpTextPopup parm={defaultProps2} />{" "}
              </h5>
              <div className="clear"></div>
              <div className="market-volatility">
                <div className="Table hole-table">
                  {marketVolatility.length > 0 ? (
                    <Table className="table table-bordered custom-table">
                      <TableHead className="TH cur-mar-vol-tab-head">
                        <TableRow className="table-header">
                          {marketVolatility.length > 0
                            ? marketVolatility.map((data) => {
                              return <TableCell>{data.unitType}</TableCell>;
                            })
                            : ""}
                        </TableRow>
                      </TableHead>
                      <TableBody className="cur-mar-vol-tab-body">
                        <TableRow className="table-body">
                          {(isUnitType.length === 0 || isUnitType.length > 0) &&
                            isNoData === false ? (
                            marketVolatility.map((data) => {
                              return (
                                <TableCell>
                                  {data.volatility === null
                                    ? "N/A"
                                    : data.volatility + "%"}
                                </TableCell>
                              );
                            })
                          ) : isNoData === true ? (
                            ""
                          ) : (
                            <td
                              colspan={marketVolatility.length}
                              className="no-data"
                            >
                              {isLoading === false ? "No Data Found" : ""}
                            </td>
                          )}
                          {/* Below I write code for showing "No data FOund" if all the units have return the null values */}
                          {isNoData === true ? (
                            <TableCell
                              className="no-data"
                              colSpan={marketVolatility.length}
                            >
                              {isLoading === false ? "No Data Found" : ""}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {/* Above I write code for showing "No data FOund" if all the units have return the null values */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <p className="no-data">
                      {isLoading === false ? "No Data Found" : ""}
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </div>
          <div className="request-free-trial price-option">
            {isLoading ? (
              <div className="loader-wrap">
                <div className="loading"></div>
              </div>
            ) : (
              ""
            )}
            <Col>
              <h5 className="bill-head">
                Current Market Volatility by Store & Price Option(last 12
                months) <HelpTextPopup parm={defaultProps3} />
              </h5>
              <div className="clear"></div>
              <div className="mobile-view-market-volatility">
                <div className="Table table-max-height">
                  <Table
                    className="table pr-vol table-bordered custom-table set-width-current-market pv_table"
                    scrollY
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={marketStorePrice.length}
                    />
                    <TableBody>
                      {isYourStore.length > 0
                        ? isYourStore.map((data) => {
                          return (
                            <TableRow className="table-body disabled_color">
                              {/* <TableCell className="store-name">{data.storeName} <div className="store-address">{data.address}, {data.city}, {data.state}, {data.zipCode}</div></TableCell> */}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store store-name">
                                  {data.storeName === null
                                    ? "N/A"
                                    : data.storeName}{" "}
                                  <div className="store-address">
                                    {data.address != null ? data.address : ""}{" "}
                                    {data.city != null
                                      ? ", " + data.city
                                      : ""}{" "}
                                    {CONFIG.RegionId ===
                                      MatchingCountryCode.UK_RID
                                      ? ""
                                      : data.state != null
                                        ? ", " + data.state
                                        : ""}{" "}
                                    {data.zipCode != null ? data.zipCode : ""}
                                  </div>
                                </TableCell>
                              ) : (
                                <TableCell className="store-name">
                                  {data.storeName === null
                                    ? "N/A"
                                    : data.storeName}{" "}
                                  <div className="store-address">
                                    {data.address != null ? data.address : ""}{" "}
                                    {data.city != null
                                      ? ", " + data.city
                                      : ""}{" "}
                                    {data.state != null &&
                                      CONFIG.RegionId !==
                                      MatchingCountryCode.UK_RID
                                      ? ", " + data.state
                                      : ""}{" "}
                                    {data.zipCode != null ? data.zipCode : ""}
                                  </div>
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.allPrice === null
                                    ? "N/A"
                                    : data.allPrice + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.allPrice === null
                                    ? "N/A"
                                    : data.allPrice + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.premium === null
                                    ? "N/A"
                                    : data.premium + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.premium === null
                                    ? "N/A"
                                    : data.premium + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.value === null
                                    ? "N/A"
                                    : data.value + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.value === null
                                    ? "N/A"
                                    : data.value + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.storeId ===
                                    isShowGraph.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          1,
                                          data.storeName
                                        )
                                      }
                                      className="hides-graph"
                                    >
                                      <img
                                        src={hiddens}
                                        className="remove-graph"
                                        alt="Show Graph"
                                      />
                                      Remove from graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {data.storeId ===
                                    isRemoveGrapg.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          0,
                                          data.storeName
                                        )
                                      }
                                      className="shows-graph"
                                    >
                                      <img
                                        src={shows}
                                        className="show-graph"
                                        alt="Remove Graph"
                                      />
                                      Show on graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.storeId ===
                                    isShowGraph.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          1,
                                          data.storeName
                                        )
                                      }
                                      className="hides-graph"
                                    >
                                      <img
                                        src={hiddens}
                                        className="remove-graph"
                                        alt="Show Graph"
                                      />
                                      Remove from graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {data.storeId ===
                                    isRemoveGrapg.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          0,
                                          data.storeName
                                        )
                                      }
                                      className="shows-graph"
                                    >
                                      <img
                                        src={shows}
                                        className="show-graph"
                                        alt="Remove Graph"
                                      />
                                      Show on graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                        : ""}
                      {marketStorePrice.length > 0 ? (
                        this.stableSort(
                          marketStorePrice,
                          this.getSorting(order, orderBy)
                        ).map((data) => {
                          return (
                            <TableRow className="table-body">
                              {/* <TableCell className="store-name">{data.storeName} <div className="store-address">{data.address}, {data.city}, {data.state}, {data.zipCode}</div></TableCell> */}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store store-name">
                                  {data.storeName === null
                                    ? "N/A"
                                    : data.storeName}{" "}
                                  <div className="store-address">
                                    {data.address != null ? data.address : ""}{" "}
                                    {data.city != null ? ", " + data.city : ""}{" "}
                                    {CONFIG.RegionId ===
                                      MatchingCountryCode.UK_RID
                                      ? ""
                                      : data.state != null
                                        ? ", " + data.state
                                        : ""}{" "}
                                    {data.zipCode != null ? data.zipCode : ""}
                                  </div>
                                </TableCell>
                              ) : (
                                <TableCell className="store-name">
                                  {data.storeName === null
                                    ? "N/A"
                                    : data.storeName}{" "}
                                  <div className="store-address">
                                    {data.address != null ? data.address : ""}{" "}
                                    {data.city != null ? ", " + data.city : ""}{" "}
                                    {data.state != null &&
                                      CONFIG.RegionId !==
                                      MatchingCountryCode.UK_RID
                                      ? ", " + data.state
                                      : ""}{" "}
                                    {data.zipCode != null ? data.zipCode : ""}
                                  </div>
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.allPrice === null
                                    ? "N/A"
                                    : data.allPrice + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.allPrice === null
                                    ? "N/A"
                                    : data.allPrice + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.premium === null
                                    ? "N/A"
                                    : data.premium + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.premium === null
                                    ? "N/A"
                                    : data.premium + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.value === null
                                    ? "N/A"
                                    : data.value + "%"}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.value === null
                                    ? "N/A"
                                    : data.value + "%"}
                                </TableCell>
                              )}
                              {data.storeName === "Your Store" ? (
                                <TableCell className="your-store">
                                  {data.storeId ===
                                    isShowGraph.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          1,
                                          data.storeName
                                        )
                                      }
                                      className="hides-graph"
                                    >
                                      <img
                                        src={hiddens}
                                        className="remove-graph"
                                        alt="Show Graph"
                                      />
                                      Remove from graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {data.storeId ===
                                    isRemoveGrapg.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          0,
                                          data.storeName
                                        )
                                      }
                                      className="shows-graph"
                                    >
                                      <img
                                        src={shows}
                                        className="show-graph"
                                        alt="Remove Graph"
                                      />
                                      Show on graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell className="">
                                  {data.storeId ===
                                    isShowGraph.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          1,
                                          data.storeName
                                        )
                                      }
                                      className="hides-graph"
                                    >
                                      <img
                                        src={hiddens}
                                        className="remove-graph"
                                        alt="Show Graph"
                                      />
                                      Remove from graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {data.storeId ===
                                    isRemoveGrapg.find(
                                      (e) => e === data.storeId
                                    ) &&
                                    data.isMyStore != 1 &&
                                    data.allPrice != null ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(
                                          data.storeId,
                                          0,
                                          data.storeName
                                        )
                                      }
                                      className="shows-graph"
                                    >
                                      <img
                                        src={shows}
                                        className="show-graph"
                                        alt="Remove Graph"
                                      />
                                      Show on graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      ) : marketStorePrice.length <= 0 &&
                        isYourStore.length <= 0 ? (
                        <tr>
                          <td colspan="5" className="no-data">
                            {isLoading === false ? "No Data Found" : ""}
                          </td>
                        </tr>
                      ) : marketStorePrice.length <= 0 ? (
                        <tr>
                          <td colspan="5" className="no-data">
                            {isLoading === false
                              ? "No Competitor Data Found"
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Col>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.snackOpen}
            className={
              this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
            }
            maxsnack={3}
            autoHideDuration={30000}
            onClose={this.snackHandleclose.bind(this)}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.snackMSg}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.snackHandleclose.bind(this)}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
      </div>
    );
  }
}

//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headRows = [
    {
      id: "storeName",
      numeric: false,
      disablePadding: true,
      label: "STORE NAME",
    },
    {
      id: "allPrice",
      numeric: true,
      disablePadding: false,
      label: "ALL PRICE VOLATILITY",
    },
    {
      id: "premium",
      numeric: true,
      disablePadding: false,
      label: "PREMIUM PRICE VOLATILITY",
    },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "VALUE PRICE VOLATILITY",
    },
    {
      id: "moreoptions",
      numeric: true,
      disablePadding: false,
      label: "MORE OPTIONS",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="center"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
              disabled={row.id !== "moreoptions" ? false : true}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
