import React, { Component } from 'react';
import './AllUnitPrizePdf.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as d3 from "d3";
import { Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { CONFIG } from '../../../utils/config';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { StoreStreetView } from '../../StoreStreetView';
import Logo from '../../../Assets/Images/logo.jpg';
import Chart from "react-apexcharts";
export default class AllUnitPrizePdf extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.storeImageStreetView = React.createRef();

    this.state = {
      options: {
        chart: {
          type: 'rangeBar',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
        },
        fill: {
          type: "solid",
          opacity: 0.6
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
        },
        axisTicks: {
          show: false
        },
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [{}],

      pricing: '',
      comparisons: '',
      storeAddress: '',
      market: '',
      myStore: '',
      compitator: '',
      gmyStore: '',
      gcompitator: '',
      compitatorAll: '',
      dropdownOpen: false,
      isShowGraph: [],
      isRemoveGraph: [],
      isShowGraph1: [],
      isRemoveGraph1: [],
      isSelectStoreId: [],
      isSelectPoint: [],
      isLoading: false,
      yGroupMax: '',
      myStoreID: '',
      alwaysMyStoreID: '',
      snackOpen: false,
      snackMSg: '',
      dropDownValue: this.props.orderby,
      anchorEl: null,
      markets1: '',
      accessKey: this.props.accessKey,
      showRemoveId: this.props.showRemoveId,
      showRemoveType: this.props.showRemoveType,
    }
    this.toggle = this.toggle.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.getParams = this.getParams.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
  }

  handlePopoverOpen(e, str) {
    this.setState({
      anchorEl: e.currentTarget,
      mStr: str
    });
  }

  mouseOut(str) {
    if (this.state.mStr == str) {
      this.setState({ anchorEl: null });
    }
  }

  getParams(storeAddress) {
    let storeId = parseInt(this.props.userstoreid);
    this.child.current.toggleStoreModal(storeAddress, storeId);
  }

  addDefaultSrc(ev) {
    if (ev.target.src != null) {
      var imageName = require('../../../Assets/Images/no-image.jpg');
      ev.target.src = imageName;
      ev.target.className = "noimages Unit-Store-Img cus-h-w-set";
    }
  }

  getStoreStreeView(lat, lon, name) {
    this.storeImageStreetView.current.toggleStoreModal(lat, lon, name);
  }
  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.AllUnitPrizePdf();
    } else {
      alert(response.status.Message);
    }
  }
  AllUnitPrizePdf() {
    this.setState({
      isLoading: true
    })
    let isShowGraph = [];
    let isRemoveGraph = [];
    let isShowGraph1 = [];
    let isRemoveGraph1 = [];

    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    const data = {
      "UserStoreID": parseInt(this.props.userstoreid),
      "RegionId": CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'dashboard/pricingcomparision', data)
      .then(res => {
        let pricing = res.data.data.pricings;
        let comparisons = res.data.data.comparisons;

        this.setState({ pricing });
        this.setState({ comparisons });

        var market = comparisons.filter(function (m) {
          return m.storeID == 0;
        });
        var markets1 = market.filter(function (m1) {
          return m1.storeID == 0 && m1.priceFrom > 0 && m1.priceTo > 0;
        });
        this.setState({ market });

        var myStore = comparisons.filter(function (ms) {
          return ms.storeID > 0 && ms.isMyStore == 1;
        });
        var compitator = comparisons.filter(function (c) {
          return c.storeID > 0 && c.isMyStore != 1;
        });

        var compitatorAll = comparisons.filter(function (c) {
          return c.storeID > 0 && c.isMyStore != 1;
        });
        let alwaysMyStoreID = myStore.length > 0 ? myStore[0].storeID : 0;
        let myStoreID = myStore.length > 0 ? myStore[0].storeID : 0;
        myStore.filter((e) => e.isSelected === false).forEach(function (e) { isShowGraph1.push(e.storeID) });
        myStore.filter((e) => e.isSelected === true).forEach(function (e) { isRemoveGraph1.push(e.storeID) });
        compitatorAll.filter((e) => e.isSelected === false).forEach(function (e) { isShowGraph1.push(e.storeID) });
        compitatorAll.filter((e) => e.isSelected === true).forEach(function (e) { isRemoveGraph1.push(e.storeID) });
        var gmyStore = myStore.filter(function (ms) {
          return ms.isSelected === true;
        });
        var gcompitator = compitator.filter(function (ms) {
          return ms.isSelected === true;
        });
        this.setState({ myStore, gmyStore: myStore, gcompitator, gcompitator: gcompitator, alwaysMyStoreID, myStoreID });

        this.setState({ compitatorAll });

        isShowGraph = [...new Set(isShowGraph1.map(item => item))];
        isRemoveGraph = [...new Set(isRemoveGraph1.map(item => item))];

        if (isShowGraph === undefined) {
          isShowGraph = [];
        }
        if (isRemoveGraph === undefined) {
          isRemoveGraph = [];
        }

        let dropDownValue = this.state.dropDownValue == 1 ? "Sort by" : this.state.dropDownValue == 2 ? "Store Name" : "Proximity";
        this.handleClickOpen('', dropDownValue)
        this.setState({ isShowGraph, isRemoveGraph, isShowGraph1, isRemoveGraph1, dropDownValue });
        // if (this.state.showRemoveId && this.state.showRemoveType) {
        //   this.showGraph(this.state.showRemoveId, this.state.showRemoveType, this.props.isRemovelen)
        // }
        // else {
      const data1 = {
        "UserStoreID": parseInt(this.props.userstoreid),
        "RegionId": CONFIG.RegionId
      }
      axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data1)
        .then(res => {
          let storeAddress = res.data.data.storeDashboard;
          this.setState({ storeAddress });
          if (market.length > 0 || gmyStore.length > 0 || gcompitator.length > 0) {
            if (gmyStore.length > 0) {
              this.multiSeriesGraph(gmyStore, gcompitator);
            }
            else {
              let compid = [...new Set(gcompitator.map(item => item.storeID))];
              if (compid.length > 1) {
                gmyStore = gcompitator.filter(function (m) {
                  return m.storeID === compid[0];
                });
                gcompitator = gcompitator.filter(function (m) {
                  return m.storeID === compid[1];
                });
              }
              this.multiSeriesGraph(gmyStore, gcompitator);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.response.status === 401) {
            localStorage.removeItem('accessKey');
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
          }
          else if (err.response.status === 403) {
            const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
            this.props.history.push(from);
          }
          else {
            alert(err.response.data);
          }
        });

        this.setState({ isLoading: false });
      })
      .catch(err => {
        if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        } else {
          this.setState({ isLoading: false });
          this.setState({ snackOpen: true, snackMSg: err });
        }

      });
  }

  showGraph(sId, type, isRemLen) {

    let isShowGraph = this.state.isShowGraph;
    let isRemoveGraph = this.state.isRemoveGraph;
    let index;
    let isRemoveGraphprev = '';
    //Remove Graph
    if (type == 1) {

      // if (sId == this.state.myStoreID) {
      //   this.setState({ myStoreID: 0 });
      // }
      if (isRemoveGraph.length <= 2) {
        if (isRemLen == 0) {


          isShowGraph = isShowGraph.concat(isRemoveGraph)
          isRemoveGraph = [];
        } else {
          isShowGraph = isShowGraph.concat(isRemoveGraph)
          index = isShowGraph.indexOf(+sId)
          isShowGraph.splice(index, 1)
          isRemoveGraph = [];
          isRemoveGraph = JSON.parse(+sId)
          // index = isRemoveGraph.indexOf(+sId)
          // isRemoveGraph.splice(index, 1)
          //isRemoveGraph.push(isRemoveGraph)
        }
        this.onlyRemoveGraph(sId, isRemLen);

      }
    }
    else {// show graph
      if (isRemLen == 1) {
        isShowGraph = isShowGraph.concat(isRemoveGraph)
        isRemoveGraph = [];
      } else {
        isRemoveGraph = []
        var prevIsremv = this.props.isRemoveGraph.split(",")
        isRemoveGraph = prevIsremv.filter(i => i != sId);
        isRemoveGraphprev = this.props.isRemoveGraph.split(",").filter(i => i != sId);
        isRemoveGraphprev = JSON.parse(isRemoveGraphprev)
        isShowGraph = isShowGraph.concat(this.state.isRemoveGraph)
        index = isShowGraph.indexOf(+isRemoveGraphprev)
        isShowGraph.splice(index, 1)



      }
      if (0 == this.state.myStoreID) {
        this.setState({ myStoreID: sId });
      }
      if (isRemoveGraph.length < 2) {
        index = isShowGraph.indexOf(+sId)
        isShowGraph.splice(index, 1)
        isRemoveGraph.push(+sId)
        this.onlyShowGraph(sId, isRemLen, isRemoveGraphprev);
      }
      else {
        let msg = 'You can only display a maximum of two stores on the chart. Please deselect a store before selecting another store to view.';
        this.setState({ snackOpen: true, snackMSg: msg });
      }
    }

    this.setState({ isShowGraph, isRemoveGraph });
  }

  onlyRemoveGraph(sId, isRemLen) {
    this.setState({ isLoading: true })
    if (isRemLen == 0) {
      this.setState({ gcompitator: [], gmyStore: [] });
      this.multiSeriesGraph([], []);
    }
    else {

      if (sId == this.state.myStoreID) {
        this.setState({ gmyStore: [] });
        this.multiSeriesGraph([], this.state.gcompitator);
        // this.setState({ gcompitator: [] });
        // this.multiSeriesGraph(this.state.gmyStore, []);
      }
      else {
        this.setState({ gcompitator: [] });
        this.multiSeriesGraph(this.state.gmyStore, []);
        // this.setState({ gmyStore: [] });
        // this.multiSeriesGraph([], this.state.gcompitator);
      }
    }
    this.setState({ isLoading: false })
  }

  onlyShowGraph(sId, isRemLen, isRemoveGraphprev) {
    this.setState({ isLoading: true })

    var showStore = this.state.comparisons.filter(function (m) {
      return m.storeID == sId;
    });

    if (isRemLen == 2) {
      var showPrevStore = this.state.comparisons.filter(function (m) {
        return m.storeID == isRemoveGraphprev;
      });
      if (this.props.storePlace == 'sc') {
        this.multiSeriesGraph(showStore, showPrevStore);
        this.setState({ gmyStore: showStore, gcompitator: showPrevStore });
      } else {
        this.multiSeriesGraph(showPrevStore, showStore);
        this.setState({ gcompitator: showStore, gmyStore: showPrevStore });
      }
    } else {
      if (this.props.storePlace == 'sc') {// show store in my store place
        this.multiSeriesGraph(showStore, []);
        this.setState({ gmyStore: showStore });
      }
      else {// Show store in competitor place
        this.setState({ gmyStore: [], gcompitator: showStore });
        this.multiSeriesGraph(showStore, this.state.gmyStore);

      }
    }
    this.setState({ isLoading: false })
  }

  multiSeriesGraph(store, compitator) {
    let market = this.state.market;
    let storeName = '';
    let compitatorname = compitator.length > 0 ? compitator[0].storeName : '';
    
    if (store.length === 0) {
      storeName = "";
    } else {
      if (store[0].storeID === this.state.alwaysMyStoreID) {
        storeName = " Your Store";
      } else {
        storeName = store[0].storeName;
      }
    }
    let data_compitator1 = [];
    let y_data_compitator1 = [];
    let y0_data_compitator1 = [];
    var currency = this.state.storeAddress;
    let check_Zero = compitator.concat(market,store).map(item => item.priceFrom && item.priceTo).filter(item => item !== 0 && item !== null && item !== '' && item !== undefined ? true : false );
    for (var i = 0; i < compitator.length; i++) {
      y_data_compitator1.push(compitator[i].priceFrom);
      y0_data_compitator1.push(compitator[i].priceTo);
      if (compitator[i].priceFrom === 0 && compitator[i].priceTo === 0) {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo] });
      } else if (compitator[i].priceFrom === compitator[i].priceTo) {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo] });
      } else {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo] });
      }
    }

    var y_min_compitator1 = Math.min.apply(Math, y_data_compitator1.filter(Number));
    var y0_min_compitator1 = Math.min.apply(Math, y0_data_compitator1.filter(Number));

    let leastVal_compitator1;
    if (y_min_compitator1 < y0_min_compitator1) {
      leastVal_compitator1 = y_min_compitator1
    } else {
      leastVal_compitator1 = y0_min_compitator1
    }

    let data_market1 = [];
    let y_data_market1 = [];
    let y0_data_market1 = [];

    for (var i = 0; i < market.length; i++) {
      y_data_market1.push(market[i].priceFrom)
      y0_data_market1.push(market[i].priceTo)
      if (market[i].priceFrom === 0 && market[i].priceTo === 0) {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo] });
      } else if (market[i].priceFrom === market[i].priceTo) {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo] });
      } else {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo] });
      }
    }

    var y_min_market1 = Math.min.apply(Math, y_data_market1.filter(Number));
    var y0_min_marke1 = Math.min.apply(Math, y0_data_market1.filter(Number));

    let leastVal_market1;
    if (y_min_market1 < y0_min_marke1) {
      leastVal_market1 = y_min_market1
    } else {
      leastVal_market1 = y0_min_marke1
    }

    let data_storeName = [];
    let y_data_storeName = [];
    let y0_data_storeName = [];

    for (var i = 0; i < store.length; i++) {
      y_data_storeName.push(store[i].priceFrom)
      y0_data_storeName.push(store[i].priceTo)
      if (store[i].priceFrom === 0 && store[i].priceTo === 0) {
        data_storeName.push({ x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo] });
      } else if (store[i].priceFrom === store[i].priceTo) {
        data_storeName.push({ name: storeName, x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo] });
      } else {
        data_storeName.push({ x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo] });
      }
    }

    var y_min_storeName = Math.min.apply(Math, y_data_storeName.filter(Number));
    var y0_min_storeName = Math.min.apply(Math, y0_data_storeName.filter(Number));

    let leastVal_storeName;
    if (y_min_storeName < y0_min_storeName) {
      leastVal_storeName = y_min_storeName
    } else {
      leastVal_storeName = y0_min_storeName
    }

    let yAxisMinValue = Math.min(leastVal_storeName, leastVal_market1, leastVal_compitator1);
    let allData = [{ name: compitatorname, data: data_compitator1 }, { name: "Market Average", data: data_market1 },  { name: storeName , data: data_storeName } ];

    if (check_Zero.length > 0) {

      this.setState({
        options: {
          chart:{
            animations: {
              enabled: false,
            },
          },
          legend: {
            position: 'bottom',
            showForSingleSeries: true,
            horizontalAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            showForNullSeries: false,
            showForZeroSeries: true,
            itemMargin: {
              horizontal: 10,
              vertical: 20,
            },
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: false
            },
            formatter: function (seriesName, opts) {
              if (seriesName) return seriesName.charAt(0).toUpperCase() + seriesName.slice(1);;
            }
          },
          yaxis: {
            min: yAxisMinValue,
            max: function(max){return max+5},
            labels: {
              formatter: function (value) {
                let val = value === null || value === undefined ? '' : currency.countryCurrency + value.toFixed(0);
                return val;
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder:{
              show: false,
            },
            axisTicks: {
              show: false,
            }
          },
          xaxis: {
            labels: {
              show: true,
              rotate: 0,
              hideOverlappingLabels: false,
              trim: true,
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value;
                return val;
              },
              style: {
                fontSize: '10px'
              }
            },
            axisBorder: {
              show: true
          },
          axisTicks: {
            show: true
          },
          },

        },
        series: allData
      })

    } else {
      this.setState({
        options: {
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            show: false,
            labels: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
          },
          axisTicks: {
            show: false
          },
          },
        },
        series: []
      })

    }


  }

  // Text Wrap For Graph X-axis label
  //   wrap(text, width) {
  //     text.each(function () {
  //         var text = d3.select(this),
  //             words = text.text().split(/\s+/).reverse(),
  //             word,
  //             line = [],
  //             lineNumber = 0,
  //             lineHeight = 1.1, // ems
  //             y = text.attr("y"),
  //             dy = parseFloat(text.attr("dy")),
  //             tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
  //         while (word = words.pop()) {
  //             line.push(word)
  //             tspan.text(line.join(" "))
  //             if (tspan.node().getComputedTextLength() > width) {
  //                 line.pop()
  //                 tspan.text(line.join(" "))
  //                 line = [word]
  //                 tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
  //             }
  //         }
  //     })
  // }

  formData(data) {

    let unique = [...new Set(data.map(item => item.unitType))];
    let uniquestore = [...new Set(data.map(item => item.storeID))];
    let storeAddress = this.state.storeAddress;

    var FData = [];
    for (var t = 0; t < uniquestore.length; t++) {
      var tFData = data.filter(a => a.storeID == uniquestore[t]);
      switch (unique.length) {
        case 12: {
          var mData = {
            StoreID: uniquestore[t],
            Address: tFData[0].address,
            StoreName: tFData[0].storeName,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1: tFData[0].priceTo === 0 && tFData[0].priceFrom === 0 ? 'N/A' : tFData[0].priceTo === tFData[0].priceFrom ? storeAddress.countryCurrency + tFData[0].priceTo : storeAddress.countryCurrency + tFData[0].priceFrom + " - " + tFData[0].priceTo,
            Unit2: tFData[1].priceTo === 0 && tFData[1].priceFrom === 0 ? 'N/A' : tFData[1].priceTo === tFData[1].priceFrom ? storeAddress.countryCurrency + tFData[1].priceTo : storeAddress.countryCurrency + tFData[1].priceFrom + " - " + tFData[1].priceTo,
            Unit3: tFData[2].priceTo === 0 && tFData[2].priceFrom === 0 ? 'N/A' : tFData[2].priceTo === tFData[2].priceFrom ? storeAddress.countryCurrency + tFData[2].priceTo : storeAddress.countryCurrency + tFData[2].priceFrom + " - " + tFData[2].priceTo,
            Unit4: tFData[3].priceTo === 0 && tFData[3].priceFrom === 0 ? 'N/A' : tFData[3].priceTo === tFData[3].priceFrom ? storeAddress.countryCurrency + tFData[3].priceTo : storeAddress.countryCurrency + tFData[3].priceFrom + " - " + tFData[3].priceTo,
            Unit5: tFData[4].priceTo === 0 && tFData[4].priceFrom === 0 ? 'N/A' : tFData[4].priceTo === tFData[4].priceFrom ? storeAddress.countryCurrency + tFData[4].priceTo : storeAddress.countryCurrency + tFData[4].priceFrom + " - " + tFData[4].priceTo,
            Unit6: tFData[5].priceTo === 0 && tFData[5].priceFrom === 0 ? 'N/A' : tFData[5].priceTo === tFData[5].priceFrom ? storeAddress.countryCurrency + tFData[5].priceTo : storeAddress.countryCurrency + tFData[5].priceFrom + " - " + tFData[5].priceTo,
            Unit7: tFData[6].priceTo === 0 && tFData[6].priceFrom === 0 ? 'N/A' : tFData[6].priceTo === tFData[6].priceFrom ? storeAddress.countryCurrency + tFData[6].priceTo : storeAddress.countryCurrency + tFData[6].priceFrom + " - " + tFData[6].priceTo,
            Unit8: tFData[7].priceTo === 0 && tFData[7].priceFrom === 0 ? 'N/A' : tFData[7].priceTo === tFData[7].priceFrom ? storeAddress.countryCurrency + tFData[7].priceTo : storeAddress.countryCurrency + tFData[7].priceFrom + " - " + tFData[7].priceTo,
            Unit9: tFData[8].priceTo === 0 && tFData[8].priceFrom === 0 ? 'N/A' : tFData[8].priceTo === tFData[8].priceFrom ? storeAddress.countryCurrency + tFData[8].priceTo : storeAddress.countryCurrency + tFData[8].priceFrom + " - " + tFData[8].priceTo,
            Unit10: tFData[9].priceTo === 0 && tFData[9].priceFrom === 0 ? 'N/A' : tFData[9].priceTo === tFData[9].priceFrom ? storeAddress.countryCurrency + tFData[9].priceTo : storeAddress.countryCurrency + tFData[9].priceFrom + " - " + tFData[9].priceTo,
            Unit11: tFData[10].priceTo === 0 && tFData[10].priceFrom === 0 ? 'N/A' : tFData[10].priceTo === tFData[10].priceFrom ? storeAddress.countryCurrency + tFData[10].priceTo : storeAddress.countryCurrency + tFData[10].priceFrom + " - " + tFData[10].priceTo,
            Unit12: tFData[11].priceTo === 0 && tFData[11].priceFrom === 0 ? 'N/A' : tFData[11].priceTo === tFData[11].priceFrom ? storeAddress.countryCurrency + tFData[11].priceTo : storeAddress.countryCurrency + tFData[11].priceFrom + " - " + tFData[11].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude
          }
          FData.push(mData);
          break;
        }
        case 10: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1: tFData[0].priceTo === 0 && tFData[0].priceFrom === 0 ? 'N/A' : tFData[0].priceTo === tFData[0].priceFrom ? storeAddress.countryCurrency + tFData[0].priceTo : storeAddress.countryCurrency + tFData[0].priceFrom + " - " + tFData[0].priceTo,
            Unit2: tFData[1].priceTo === 0 && tFData[1].priceFrom === 0 ? 'N/A' : tFData[1].priceTo === tFData[1].priceFrom ? storeAddress.countryCurrency + tFData[1].priceTo : storeAddress.countryCurrency + tFData[1].priceFrom + " - " + tFData[1].priceTo,
            Unit3: tFData[2].priceTo === 0 && tFData[2].priceFrom === 0 ? 'N/A' : tFData[2].priceTo === tFData[2].priceFrom ? storeAddress.countryCurrency + tFData[2].priceTo : storeAddress.countryCurrency + tFData[2].priceFrom + " - " + tFData[2].priceTo,
            Unit4: tFData[3].priceTo === 0 && tFData[3].priceFrom === 0 ? 'N/A' : tFData[3].priceTo === tFData[3].priceFrom ? storeAddress.countryCurrency + tFData[3].priceTo : storeAddress.countryCurrency + tFData[3].priceFrom + " - " + tFData[3].priceTo,
            Unit5: tFData[4].priceTo === 0 && tFData[4].priceFrom === 0 ? 'N/A' : tFData[4].priceTo === tFData[4].priceFrom ? storeAddress.countryCurrency + tFData[4].priceTo : storeAddress.countryCurrency + tFData[4].priceFrom + " - " + tFData[4].priceTo,
            Unit6: tFData[5].priceTo === 0 && tFData[5].priceFrom === 0 ? 'N/A' : tFData[5].priceTo === tFData[5].priceFrom ? storeAddress.countryCurrency + tFData[5].priceTo : storeAddress.countryCurrency + tFData[5].priceFrom + " - " + tFData[5].priceTo,
            Unit7: tFData[6].priceTo === 0 && tFData[6].priceFrom === 0 ? 'N/A' : tFData[6].priceTo === tFData[6].priceFrom ? storeAddress.countryCurrency + tFData[6].priceTo : storeAddress.countryCurrency + tFData[6].priceFrom + " - " + tFData[6].priceTo,
            Unit8: tFData[7].priceTo === 0 && tFData[7].priceFrom === 0 ? 'N/A' : tFData[7].priceTo === tFData[7].priceFrom ? storeAddress.countryCurrency + tFData[7].priceTo : storeAddress.countryCurrency + tFData[7].priceFrom + " - " + tFData[7].priceTo,
            Unit9: tFData[8].priceTo === 0 && tFData[8].priceFrom === 0 ? 'N/A' : tFData[8].priceTo === tFData[8].priceFrom ? storeAddress.countryCurrency + tFData[8].priceTo : storeAddress.countryCurrency + tFData[8].priceFrom + " - " + tFData[8].priceTo,
            Unit10: tFData[9].priceTo === 0 && tFData[9].priceFrom === 0 ? 'N/A' : tFData[9].priceTo === tFData[9].priceFrom ? storeAddress.countryCurrency + tFData[9].priceTo : storeAddress.countryCurrency + tFData[9].priceFrom + " - " + tFData[9].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude
          }
          FData.push(mData);
          break;
        }
        case 14: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1: tFData[0].priceTo === 0 && tFData[0].priceFrom === 0 ? 'N/A' : tFData[0].priceTo === tFData[0].priceFrom ? storeAddress.countryCurrency + tFData[0].priceTo : storeAddress.countryCurrency + tFData[0].priceFrom + " - " + tFData[0].priceTo,
            Unit2: tFData[1].priceTo === 0 && tFData[1].priceFrom === 0 ? 'N/A' : tFData[1].priceTo === tFData[1].priceFrom ? storeAddress.countryCurrency + tFData[1].priceTo : storeAddress.countryCurrency + tFData[1].priceFrom + " - " + tFData[1].priceTo,
            Unit3: tFData[2].priceTo === 0 && tFData[2].priceFrom === 0 ? 'N/A' : tFData[2].priceTo === tFData[2].priceFrom ? storeAddress.countryCurrency + tFData[2].priceTo : storeAddress.countryCurrency + tFData[2].priceFrom + " - " + tFData[2].priceTo,
            Unit4: tFData[3].priceTo === 0 && tFData[3].priceFrom === 0 ? 'N/A' : tFData[3].priceTo === tFData[3].priceFrom ? storeAddress.countryCurrency + tFData[3].priceTo : storeAddress.countryCurrency + tFData[3].priceFrom + " - " + tFData[3].priceTo,
            Unit5: tFData[4].priceTo === 0 && tFData[4].priceFrom === 0 ? 'N/A' : tFData[4].priceTo === tFData[4].priceFrom ? storeAddress.countryCurrency + tFData[4].priceTo : storeAddress.countryCurrency + tFData[4].priceFrom + " - " + tFData[4].priceTo,
            Unit6: tFData[5].priceTo === 0 && tFData[5].priceFrom === 0 ? 'N/A' : tFData[5].priceTo === tFData[5].priceFrom ? storeAddress.countryCurrency + tFData[5].priceTo : storeAddress.countryCurrency + tFData[5].priceFrom + " - " + tFData[5].priceTo,
            Unit7: tFData[6].priceTo === 0 && tFData[6].priceFrom === 0 ? 'N/A' : tFData[6].priceTo === tFData[6].priceFrom ? storeAddress.countryCurrency + tFData[6].priceTo : storeAddress.countryCurrency + tFData[6].priceFrom + " - " + tFData[6].priceTo,
            Unit8: tFData[7].priceTo === 0 && tFData[7].priceFrom === 0 ? 'N/A' : tFData[7].priceTo === tFData[7].priceFrom ? storeAddress.countryCurrency + tFData[7].priceTo : storeAddress.countryCurrency + tFData[7].priceFrom + " - " + tFData[7].priceTo,
            Unit9: tFData[8].priceTo === 0 && tFData[8].priceFrom === 0 ? 'N/A' : tFData[8].priceTo === tFData[8].priceFrom ? storeAddress.countryCurrency + tFData[8].priceTo : storeAddress.countryCurrency + tFData[8].priceFrom + " - " + tFData[8].priceTo,
            Unit10: tFData[9].priceTo === 0 && tFData[9].priceFrom === 0 ? 'N/A' : tFData[9].priceTo === tFData[9].priceFrom ? storeAddress.countryCurrency + tFData[9].priceTo : storeAddress.countryCurrency + tFData[9].priceFrom + " - " + tFData[9].priceTo,
            Unit11: tFData[10].priceTo === 0 && tFData[10].priceFrom === 0 ? 'N/A' : tFData[10].priceTo === tFData[10].priceFrom ? storeAddress.countryCurrency + tFData[10].priceTo : storeAddress.countryCurrency + tFData[10].priceFrom + " - " + tFData[10].priceTo,
            Unit12: tFData[11].priceTo === 0 && tFData[11].priceFrom === 0 ? 'N/A' : tFData[11].priceTo === tFData[11].priceFrom ? storeAddress.countryCurrency + tFData[11].priceTo : storeAddress.countryCurrency + tFData[11].priceFrom + " - " + tFData[11].priceTo,
            Unit13: tFData[12].priceTo === 0 && tFData[12].priceFrom === 0 ? 'N/A' : tFData[12].priceTo === tFData[12].priceFrom ? storeAddress.countryCurrency + tFData[12].priceTo : storeAddress.countryCurrency + tFData[12].priceFrom + " - " + tFData[12].priceTo,
            Unit14: tFData[13].priceTo === 0 && tFData[13].priceFrom === 0 ? 'N/A' : tFData[13].priceTo === tFData[13].priceFrom ? storeAddress.countryCurrency + tFData[13].priceTo : storeAddress.countryCurrency + tFData[13].priceFrom + " - " + tFData[13].priceTo,

            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude
          }
          FData.push(mData);
          break;
        }
        default: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1: tFData[0].priceTo === 0 && tFData[0].priceFrom === 0 ? 'N/A' : tFData[0].priceTo === tFData[0].priceFrom ? storeAddress.countryCurrency + tFData[0].priceTo : storeAddress.countryCurrency + tFData[0].priceFrom + " - " + tFData[0].priceTo,
            Unit2: tFData[1].priceTo === 0 && tFData[1].priceFrom === 0 ? 'N/A' : tFData[1].priceTo === tFData[1].priceFrom ? storeAddress.countryCurrency + tFData[1].priceTo : storeAddress.countryCurrency + tFData[1].priceFrom + " - " + tFData[1].priceTo,
            Unit3: tFData[2].priceTo === 0 && tFData[2].priceFrom === 0 ? 'N/A' : tFData[2].priceTo === tFData[2].priceFrom ? storeAddress.countryCurrency + tFData[2].priceTo : storeAddress.countryCurrency + tFData[2].priceFrom + " - " + tFData[2].priceTo,
            Unit4: tFData[3].priceTo === 0 && tFData[3].priceFrom === 0 ? 'N/A' : tFData[3].priceTo === tFData[3].priceFrom ? storeAddress.countryCurrency + tFData[3].priceTo : storeAddress.countryCurrency + tFData[3].priceFrom + " - " + tFData[3].priceTo,
            Unit5: tFData[4].priceTo === 0 && tFData[4].priceFrom === 0 ? 'N/A' : tFData[4].priceTo === tFData[4].priceFrom ? storeAddress.countryCurrency + tFData[4].priceTo : storeAddress.countryCurrency + tFData[4].priceFrom + " - " + tFData[4].priceTo,
            Unit6: tFData[5].priceTo === 0 && tFData[5].priceFrom === 0 ? 'N/A' : tFData[5].priceTo === tFData[5].priceFrom ? storeAddress.countryCurrency + tFData[5].priceTo : storeAddress.countryCurrency + tFData[5].priceFrom + " - " + tFData[5].priceTo,
            Unit7: tFData[6].priceTo === 0 && tFData[6].priceFrom === 0 ? 'N/A' : tFData[6].priceTo === tFData[6].priceFrom ? storeAddress.countryCurrency + tFData[6].priceTo : storeAddress.countryCurrency + tFData[6].priceFrom + " - " + tFData[6].priceTo,
            Unit8: tFData[7].priceTo === 0 && tFData[7].priceFrom === 0 ? 'N/A' : tFData[7].priceTo === tFData[7].priceFrom ? storeAddress.countryCurrency + tFData[7].priceTo : storeAddress.countryCurrency + tFData[7].priceFrom + " - " + tFData[7].priceTo,
            Unit9: tFData[8].priceTo === 0 && tFData[8].priceFrom === 0 ? 'N/A' : tFData[8].priceTo === tFData[8].priceFrom ? storeAddress.countryCurrency + tFData[8].priceTo : storeAddress.countryCurrency + tFData[8].priceFrom + " - " + tFData[8].priceTo,
            Unit10: tFData[9].priceTo === 0 && tFData[9].priceFrom === 0 ? 'N/A' : tFData[9].priceTo === tFData[9].priceFrom ? storeAddress.countryCurrency + tFData[9].priceTo : storeAddress.countryCurrency + tFData[9].priceFrom + " - " + tFData[9].priceTo,
            Unit11: tFData[10].priceTo === 0 && tFData[10].priceFrom === 0 ? 'N/A' : tFData[10].priceTo === tFData[10].priceFrom ? storeAddress.countryCurrency + tFData[10].priceTo : storeAddress.countryCurrency + tFData[10].priceFrom + " - " + tFData[10].priceTo,
            Unit12: tFData[11].priceTo === 0 && tFData[11].priceFrom === 0 ? 'N/A' : tFData[11].priceTo === tFData[11].priceFrom ? storeAddress.countryCurrency + tFData[11].priceTo : storeAddress.countryCurrency + tFData[11].priceFrom + " - " + tFData[11].priceTo,
            Unit13: tFData[12].priceTo === 0 && tFData[12].priceFrom === 0 ? 'N/A' : tFData[12].priceTo === tFData[12].priceFrom ? storeAddress.countryCurrency + tFData[12].priceTo : storeAddress.countryCurrency + tFData[12].priceFrom + " - " + tFData[12].priceTo,
            Unit14: tFData[13].priceTo === 0 && tFData[13].priceFrom === 0 ? 'N/A' : tFData[13].priceTo === tFData[13].priceFrom ? storeAddress.countryCurrency + tFData[13].priceTo : storeAddress.countryCurrency + tFData[13].priceFrom + " - " + tFData[13].priceTo,
            Unit15: tFData[14].priceTo === 0 && tFData[14].priceFrom === 0 ? 'N/A' : tFData[14].priceTo === tFData[14].priceFrom ? storeAddress.countryCurrency + tFData[14].priceTo : storeAddress.countryCurrency + tFData[14].priceFrom + " - " + tFData[14].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude
          }
          FData.push(mData);
        }
      }
    }
    return FData;
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleClickOpen(e, name) {
    let dropDownValue = "";
    if (name === "Proximity") {//descending order--to Address origin of Market
      dropDownValue = "Proximity";
      this.setState(compitatorAll => {
        this.state.compitatorAll.sort((a, b) => (a.proximity - b.proximity))
      });
    } else if (name === "Store Name") {//ascending order
      dropDownValue = "Store Name";
      this.setState(compitatorAll => {
        this.state.compitatorAll.sort((a, b) => a.storeName !== b.storeName ? a.storeName < b.storeName ? -1 : 1 : 0);
      });
    }
    this.setState({ dropDownValue });
  }

  //Snack Close
  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const { pricing, isLoading, storeAddress, isShowGraph, yGroupMax, isRemoveGraph, markets1, comparisons, compitatorAll, myStore, market, snackMSg, snackOpen } = this.state;
    const defaultProps = {
      helpTitle: "All Units Pricing",
      helpContent: "<p>On this page we provide a side-by-side analysis of the " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rates for the most common unit types.</p><h6>Broadest Range</h6><p>This is the unit type with the largest range of current " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rates (this includes both Regular/Walk-in and Online/Promotional rates)</p><h6>Narrowest Range</h6><p>This is the unit type with the lowest range of current " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rates (this includes both Regular/Walk-in and Online/Promotional rates)</p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    const defaultProps1 = {
      helpTitle: "All Units Pricing Ranges",
      helpContent: "<p>This graph is showing a side-by-side analysis of the " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rates for the most common unit types.</p><p>The Market Average (in black) show the range of current " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rates for this unit type. Hover over the range to view the specific rates.</p><p>If Your Store is advertising rates for this unit type, your rates will be shown here (in orange). Hover over the range to view the specific rates.</p><p>Compare the " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " rate range for any store/competitor in this Market*</p><p>*See 'All Stores' below to select stores/competitors to compare to on this graph</p><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    const defaultProps2 = {
      helpTitle: "All Stores",
      helpContent: "<ul><li>This shows all the stores in this Market and their current " + `${CONFIG.RegionId === 3 ? "weekly" : ''}` + " price ranges for each of the most common unit types.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>"
      // helpContent: "<ul><li>This shows all the stores in this Market and their current price ranges for each of the most common unit types.</li><li>Click on any to show/remove on the graph above for comparison.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>"
    }
    var storeName = "";
    { storeName = storeAddress ? storeAddress.storeName : "" }
    var count = 0;
    var initvalue = '';
    const rowsitem = [];
    var tData = [];
    var pData = [];
    var udt = 0;

    if (compitatorAll.length > 0) {
      tData = this.formData(compitatorAll);
      pData = compitatorAll.filter(a => (a.priceFrom > 0 || a.priceTo > 0));
      let unique = [...new Set(compitatorAll.map(item => item.unitType))];
      udt = unique.length;

    }
    const tr = [...Array(Math.ceil(tData.length / 10))];
    const trproductRows = tr.map((row, idx) => tData.slice(idx * 10, idx * 10 + 10));
    const nameCount = storeAddress.storeName ? storeAddress.storeName.length : 0;
    return (
      <div className="AllUnitPricing AllUnitPricing-pdf">
        <div className="header"> <img src={Logo} /><div className="float-right-name"> <h5>{storeAddress.storeName && storeAddress.storeName !== null && storeAddress.storeName !== '' && storeAddress.storeName !== undefined ? nameCount > CONFIG.storeNameMaxLimit ? storeAddress.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : storeAddress.storeName : isLoading === false ? 'Your Store' : ''}</h5></div>
          {/* <div className="float-right"><p className="m-0">{storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' && storeAddress.storeAddress1 !== undefined ?  storeAddress.storeAddress1 + ", " : '' + storeAddress && storeAddress.storeCity ? storeAddress.storeCity + ", " : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude+', ' +storeAddress.longitude+', ': ''}{storeAddress.storeState + ' ' + storeAddress.storeZipCode} {storeAddress.storeZoneCoverage ? "| Coverage: " + storeAddress.storeZoneCoverage : 'N/A'}</p></div></div>                                                         */}
          <div className="float-right"><p className="m-0 address-font">{storeAddress.storeAddress1 && storeAddress.storeAddress1 !== null && storeAddress.storeAddress1 !== '' && storeAddress.storeAddress1 !== undefined ? storeAddress.storeAddress1 + ", " : ''} {storeAddress && storeAddress.storeCity ? storeAddress.storeCity + ", " : storeAddress.searchType === 1 && (storeAddress.storeCity === '' || storeAddress.storeCity === null) && (storeAddress.storeZipCode === '' || storeAddress.storeZipCode === null) ? storeAddress.latitude + ', ' + storeAddress.longitude + ', ' : ''}{storeAddress.storeState && storeAddress.storeState !== '' && storeAddress.storeState !== null ? storeAddress.storeState + '' : isLoading === false ? ' ' : ''} {storeAddress.storeZipCode && storeAddress.storeZipCode !== '' && storeAddress.storeZipCode !== null ? storeAddress.storeZipCode + '' : isLoading === false ? '' : ''} | <span className="text-right"><label>Coverage: </label> <span>{storeAddress.storeZoneCoverage ? storeAddress.storeZoneCoverage : isLoading === false ? 'N/A' : ''}</span></span></p></div></div>
        <div className="footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>
        {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}


        <div className="clearfix"> </div>

        <div>
          <div className="help-text-pdf mb-3 text-left mt-5 pt-4" style={{ marginLeft: '15px' }}>
            <h5 className="mb-3" >All Units Pricing</h5>

            <p>On this page we provide a side-by-side analysis of the rates for the most common unit types.</p>
            <h6>Broadest Range</h6>
            <p>This is the unit type with the largest range of {CONFIG.RegionId === 3 ? "current weekly rates" : "current rates"} (this includes both Regular/Walk-in and Online/Promotional rates)</p>
            <h6>Narrowest Range</h6>
            <p>This is the unit type with the lowest range of {CONFIG.RegionId === 3 ? "current weekly rates" : "current rates"} (this includes both Regular/Walk-in and Online/Promotional rates)</p>
            <p><i>*Your store prices are included in the calculations.</i></p>
          </div>
          {/* {
            <ol>
            {  this.state.isSelectPoint.map(reptile => <li>{reptile}</li>)}
          </ol>
          } */}

          <div className="broadest-range">
            <div className="common-col height-full right-none border-top-left">
              <h4>Broadest Range</h4>
              {isLoading ? '' :
                <React.Fragment>
                  <div className="pd-btm-20"><h5>{pricing.broadestRangeUnit ? pricing.broadestRangeUnit : 'N/A'}</h5></div>
                  <div className="align-stores-btm"><div className="btm-label">{CONFIG.RegionId === 3 ? "Current Weekly Price Range" : "Current Price Range"}</div></div>
                </React.Fragment>
              }
            </div>
            <div className="common-col height-full border-left-right">
              {/* <h2>{isLoading ? '' : pricing.broadestPriceTo === null ? '' : pricing.broadestPriceTo === pricing.broadestPriceFrom ? storeAddress.countryCurrency + pricing.broadestPriceTo : storeAddress.countryCurrency + pricing.broadestPriceFrom + '-' + pricing.broadestPriceTo}</h2> */}
              <h2>{isLoading ? '' : pricing.broadestPriceTo === null ? '' : pricing.broadestPriceTo === pricing.broadestPriceFrom ? storeAddress.countryCurrency + pricing.broadestPriceTo : storeAddress.countryCurrency + pricing.broadestPriceFrom + '-' + pricing.broadestPriceTo}{pricing.broadestPriceTo === pricing.broadestPriceFrom ? <span style={{ color: '#ff6534', fontSize: '20px', position: 'relative', bottom: '5px' }}>**</span> : ''}</h2>
            </div>

            <div className="common-col height-full right-none">
              <h4>Narrowest Range</h4>
              {isLoading ? '' :
                <React.Fragment>
                  <div className="pd-btm-20"><h5>{pricing.narrowestRangeUnit ? pricing.narrowestRangeUnit : 'N/A'}</h5></div>
                  <div className="align-stores-btm"><div className="btm-label">{CONFIG.RegionId === 3 ? "Current Weekly Price Range" : "Current Price Range"}</div></div>
                </React.Fragment>
              }
            </div>
            <div className="common-col height-full left-none">
              {/* <h2>{isLoading ? '' : pricing.narrowestPriceTo === null ? '' : pricing.narrowestPriceTo === pricing.narrowestPriceFrom ? storeAddress.countryCurrency + pricing.narrowestPriceTo : storeAddress.countryCurrency + pricing.narrowestPriceFrom + '-' + pricing.narrowestPriceTo}</h2> */}
              <h2>{isLoading ? '' : pricing.narrowestPriceTo === null ? '' : pricing.narrowestPriceTo === pricing.narrowestPriceFrom ? storeAddress.countryCurrency + pricing.narrowestPriceTo : storeAddress.countryCurrency + pricing.narrowestPriceFrom + '-' + pricing.narrowestPriceTo} {pricing.narrowestPriceTo === pricing.narrowestPriceFrom ? <span style={{ color: '#ff6534', fontSize: '20px', position: 'relative', bottom: '5px' }}>**</span> : ''}</h2>
            </div>
            <div className="clear"></div>
          </div>
        </div>
        <p><i>
          {pricing.broadestPriceTo === pricing.broadestPriceFrom || pricing.narrowestPriceTo === pricing.narrowestPriceFrom ? <p className="btm-narrow-para"><span style={{ color: '#ff6534' }}>**</span>This market has only a single price option and there is no price range.</p> : ''}
        </i></p>
        <div className="dashboard-layout last-months">
          <div className="App">
            <div className="help-text-pdf mb-3 text-left" style={{ marginLeft: '0px' }}>
              <h4 className="mb-1" style={{ float: 'none' }}>All Units Pricing Ranges  </h4>
              {CONFIG.RegionId === 3 ?
                <div>
                  <p>This graph is showing a side-by-side analysis of the weekly rates for the most common unit types.</p>
                  <p>The Market Average (in black) shows the range of current weekly rates for this unit type. Hover over the range to view the specific rates.</p>
                  <p>If Your Store is advertising rates for this unit type, your rates will be shown here (in orange). Hover over the range to view the specific rates.</p>
                  <p>Compare the weekly rate range for any store/competitor in this Market*</p>
                  <p>*See 'All Stores' below to select stores/competitors to compare to on this graph</p>
                  <p><i>*Your store prices are included in the calculations.</i></p>
                  <p>This graph is showing a side-by-side analysis of the rates for the most common unit types.</p>
                </div>
                :
                <div>
                  <p>This graph is showing a side-by-side analysis of the rates for the most common unit types.</p>
                  {/* <p>The Market Average (in black) show the range of current rates for this unit type. Hover over the range to view the specific rates.</p> */}
                  <p>The Market Average (in black) show the range of current rates for this unit type.</p>
                  {/* <p>If Your Store is advertising rates for this unit type, your rates will be shown here (in orange). Hover over the range to view the specific rates.</p> */}
                  <p>If Your Store is advertising rates for this unit type, your rates will be shown here (in orange).</p>
                  <p>Compare the rate range for any store/competitor in this Market*</p>
                  <p>*See 'All Stores' below to select stores/competitors to compare to on this graph</p>
                  <p><i>*Your store prices are included in the calculations.</i></p> </div>}
            </div>
            <div className="clear"></div>


            <Paper style={{ height: '', width: '100%', marginTop: '30px' }} className="custom-paper">
              {markets1.length > 0 || myStore.length > 0 || pData.length > 0 ? <> <div id="chart1" class="Allunitbarchart"></div> <Chart options={this.state.options} series={this.state.series} type="rangeBar" width={960} height={320} /></> : <div className="no-data">{isLoading === false ? 'No Data Available' : ''}</div>}
            </Paper>
          </div>

          <Col style={{ marginTop: '150px', display: 'none' }}>
            <Paper className="all-units-table">
              <div>
                <div className="help-text-pdf mb-3 text-left" style={{ marginLeft: '15px' }}>
                  <h4 style={{ float: 'none' }}>All Stores </h4>
                  <ul>
                    <li>This shows all the stores in this Market and their current price ranges for each of the most common unit types.</li>
                    {/* <li>Click on any to show/remove on the graph above for comparison.</li> */}
                  </ul>
                  <p><i>*Your store prices are included in the calculations.</i></p>
                </div>
                {markets1.length > 0 || myStore.length > 0 || tData.length > 0 ?
                  <div className="all-pri-right">

                    <Dropdown className="d-inline-block sort-by-option" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                      <DropdownToggle caret>
                        {this.state.dropDownValue}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => this.handleClickOpen(e, "Store Name")}>Store Name</DropdownItem>
                        <DropdownItem onClick={(e) => this.handleClickOpen(e, "Proximity")}>Proximity</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  : ''}
                <div className="clear"></div>
              </div>
            </Paper>
          </Col>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackOpen}
          className="snackbar bg-color-primary custom-snackbar"
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMSg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <div className="print-view">
          {trproductRows ? trproductRows.map((rows, i) => (
            <div class="page first-child-page">
              <Col>
                <Paper className="all-units-table">
                  <div>
                    {i == 0 ?
                      <div className="help-text-pdf mb-3 text-left" style={{ marginLeft: '15px', paddingTop: '100px' }}>
                        <h4 style={{ float: 'none' }}>All Stores </h4>
                        <ul>
                          <li>This shows all the stores in this Market and their {CONFIG.RegionId === 3 ? "current weekly price ranges" : "current price ranges"} for each of the most common unit types.</li>
                          {/* <li>Click on any to show/remove on the graph above for comparison.</li> */}
                        </ul>
                        <p><i>*Your store prices are included in the calculations.</i></p>
                      </div>
                      : ''}

                    <div className="clear"></div>
                  </div>

                  <div className="overflow-scroll-table allUnitPrice-table shadow-none">
                    <Paper>
                      {markets1.length > 0 || myStore.length > 0 || tData.length > 0 ?
                        <Table className={"table custom-table table-bordered inventory-table"} size="small">
                          <TableHead>
                            <TableRow>

                              {market.length > 0 ? market.map(data => {
                                return (
                                  <TableCell className="m-0 width-col unit-type-heading">{data.unitType}</TableCell>
                                );
                              }) : ''}
                              {/* <TableCell className="m-0 more-option width-col">More Option</TableCell> */}
                            </TableRow >
                          </TableHead>
                          <TableBody>
                            <StoreStreetView ref={this.storeImageStreetView} />
                            {i == 0 ?
                              <React.Fragment>
                                <TableRow className="table-body font-weight-bol custom-td-width">
                                  <TableCell className="m-0 mar-range" colspan="14">{"Market Range "}</TableCell>
                                </TableRow>
                                <TableRow className="table-body font-weight-bol custom-td-width">
                                  {/* <TableCell className="m-0 mar-range">{"Market Range "}</TableCell> */}
                                  {market.length > 0 ? market.map(data => {
                                    return (
                                      <TableCell className="m-0 mar-range mar-range-bold">{data.priceTo === 0 && data.priceFrom === 0 ? 'N/A' : data.priceTo === data.priceFrom ? storeAddress.countryCurrency + data.priceFrom : storeAddress.countryCurrency + data.priceFrom + '-' + data.priceTo}</TableCell>
                                    );
                                  }) : ''}

                                </TableRow></React.Fragment>
                              : ''}

                            {i == 0 && myStore.length > 0 ?
                              <React.Fragment>
                                <TableRow className="table-body font-weight-bol custom-td-width">
                                  <TableCell className="m-0 mar-range" colspan="14">{"Your Store "}</TableCell>
                                </TableRow>
                                <TableRow className="table-body font-weight-bol custom-td-width">
                                  {/* <TableCell className="m-0 mar-range">{"Your Store "}</TableCell> */}
                                  {myStore.length > 0 ? myStore.map(data => {
                                    return (
                                      <TableCell className="m-0 mar-range-bold">{data.priceTo === 0 && data.priceFrom === 0 ? 'N/A' : data.priceTo === data.priceFrom ? storeAddress.countryCurrency + data.priceFrom : storeAddress.countryCurrency + data.priceFrom + '-' + data.priceTo}</TableCell>
                                    );
                                  }) : ''}

                                </TableRow>
                              </React.Fragment>
                              : ''}
                            {tData.length > 0 ? rows.map(data => {
                              switch (udt) {
                                case 12: {
                                  return (
                                    <React.Fragment>
                                      <TableRow className="table-body all-unit-customize-table">
                                        <TableCell className="m-0 no-need-style cus-padding" colspan="14">
                                          <div className="pdf-img-add-container">
                                            {data.StoreImage != '' ?
                                              <div className="pdf-print-img-left">
                                                <img className="Unit-Store-Img cus-h-w-set" src={data.StoreImage} alt="No image" onError={this.addDefaultSrc} />
                                              </div> : ''}
                                            <div className="pdf-print-add-right">
                                              <div className="store-name-bd">{data.StoreName}</div>
                                              <div className="store-miles">{data.Proximity + ' ' + "miles"}</div>
                                              <div className="store-address-bd">{data.Address}</div>
                                            </div>
                                          </div>
                                          <div className="clear"></div>

                                        </TableCell></TableRow>
                                      <TableRow className="all-units-results">
                                        <TableCell className="m-0">{data.Unit1}</TableCell>
                                        <TableCell className="m-0">{data.Unit2}</TableCell>
                                        <TableCell className="m-0">{data.Unit3}</TableCell>
                                        <TableCell className="m-0">{data.Unit4}</TableCell>
                                        <TableCell className="m-0">{data.Unit5}</TableCell>
                                        <TableCell className="m-0">{data.Unit6}</TableCell>
                                        <TableCell className="m-0">{data.Unit7}</TableCell>
                                        <TableCell className="m-0">{data.Unit8}</TableCell>
                                        <TableCell className="m-0">{data.Unit9}</TableCell>
                                        <TableCell className="m-0">{data.Unit10}</TableCell>
                                        <TableCell className="m-0">{data.Unit11}</TableCell>
                                        <TableCell className="m-0">{data.Unit12}</TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                  break;
                                }
                                case 10: {
                                  return (
                                    <React.Fragment>
                                      <TableRow className="table-body all-unit-customize-table">
                                        <TableCell className="m-0 no-need-style cus-padding" colspan="14">
                                          <div className="pdf-img-add-container">
                                            {data.StoreImage != '' ?
                                              <div className="pdf-print-img-left">
                                                <img className="Unit-Store-Img cus-h-w-set" src={data.StoreImage} alt="No image" onError={this.addDefaultSrc} />
                                              </div> : ''}
                                            <div className="pdf-print-add-right">
                                              <div className="store-name-bd">{data.StoreName}</div>
                                              <div className="store-miles">{data.Proximity + ' ' + "miles"}</div>
                                              <div className="store-address-bd">{data.Address}</div>
                                            </div>
                                          </div>
                                          <div className="clear"></div>

                                        </TableCell></TableRow>
                                      <TableRow className="all-units-results">
                                        <TableCell className="m-0">{data.Unit1}</TableCell>
                                        <TableCell className="m-0">{data.Unit2}</TableCell>
                                        <TableCell className="m-0">{data.Unit3}</TableCell>
                                        <TableCell className="m-0">{data.Unit4}</TableCell>
                                        <TableCell className="m-0">{data.Unit5}</TableCell>
                                        <TableCell className="m-0">{data.Unit6}</TableCell>
                                        <TableCell className="m-0">{data.Unit7}</TableCell>
                                        <TableCell className="m-0">{data.Unit8}</TableCell>
                                        <TableCell className="m-0">{data.Unit9}</TableCell>
                                        <TableCell className="m-0">{data.Unit10}</TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                  break;
                                }
                                case 14: {
                                  return (
                                    <React.Fragment>
                                      <TableRow className="table-body all-unit-customize-table" >
                                        <TableCell className="m-0 no-need-style cus-padding" colspan="14" >
                                          <div className="pdf-img-add-container">
                                            {data.StoreImage != '' ?
                                              <div className="pdf-print-img-left">
                                                <img className="Unit-Store-Img cus-h-w-set" src={data.StoreImage} alt="No image" onError={this.addDefaultSrc} />
                                              </div> : ''}
                                            <div className="pdf-print-add-right">
                                              <div className="store-name-bd">{data.StoreName}</div>
                                              <div className="store-miles">{data.Proximity + ' ' + "miles"}</div>
                                              <div className="store-address-bd">{data.Address}</div>
                                            </div>
                                          </div>
                                          <div className="clear"></div>

                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="all-units-results">
                                        <TableCell className="m-0">{data.Unit1}</TableCell>
                                        <TableCell className="m-0">{data.Unit2}</TableCell>
                                        <TableCell className="m-0">{data.Unit3}</TableCell>
                                        <TableCell className="m-0">{data.Unit4}</TableCell>
                                        <TableCell className="m-0">{data.Unit5}</TableCell>
                                        <TableCell className="m-0">{data.Unit6}</TableCell>
                                        <TableCell className="m-0">{data.Unit7}</TableCell>
                                        <TableCell className="m-0">{data.Unit8}</TableCell>
                                        <TableCell className="m-0">{data.Unit9}</TableCell>
                                        <TableCell className="m-0">{data.Unit10}</TableCell>
                                        <TableCell className="m-0">{data.Unit11}</TableCell>
                                        <TableCell className="m-0">{data.Unit12}</TableCell>
                                        <TableCell className="m-0">{data.Unit13}</TableCell>
                                        <TableCell className="m-0">{data.Unit14}</TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                  break;
                                }
                                default: {
                                  return (
                                    <React.Fragment>
                                      <TableRow className="table-body all-unit-customize-table">
                                        <TableCell className="m-0 store-name-bd no-need-style cus-padding" colspan="14" >
                                          <div className="pdf-img-add-container">
                                            {data.StoreImage != '' ?
                                              <div className="pdf-print-img-left">
                                                <img className="Unit-Store-Img cus-h-w-set" src={data.StoreImage} onError={this.addDefaultSrc} />
                                              </div> : ''}
                                            <div className="pdf-print-add-right">
                                              <div className="data-stores">{data.StoreName}</div>
                                              <div className="store-miles">{data.Proximity + ' ' + "miles"}</div>
                                              <div className="store-address">{data.Address}</div>
                                            </div>
                                          </div>
                                          <div className="clear"></div>

                                        </TableCell></TableRow>
                                      <TableRow className="all-units-results">
                                        <TableCell className="m-0">{data.Unit1}</TableCell>
                                        <TableCell className="m-0">{data.Unit2}</TableCell>
                                        <TableCell className="m-0">{data.Unit3}</TableCell>
                                        <TableCell className="m-0">{data.Unit4}</TableCell>
                                        <TableCell className="m-0">{data.Unit5}</TableCell>
                                        <TableCell className="m-0">{data.Unit6}</TableCell>
                                        <TableCell className="m-0">{data.Unit7}</TableCell>
                                        <TableCell className="m-0">{data.Unit8}</TableCell>
                                        <TableCell className="m-0">{data.Unit9}</TableCell>
                                        <TableCell className="m-0">{data.Unit10}</TableCell>
                                        <TableCell className="m-0">{data.Unit11}</TableCell>
                                        <TableCell className="m-0">{data.Unit12}</TableCell>
                                        <TableCell className="m-0">{data.Unit13}</TableCell>
                                        <TableCell className="m-0">{data.Unit14}</TableCell>
                                        <TableCell className="m-0">{data.Unit15}</TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );

                                  break;
                                }
                              }
                            }) : ''}
                          </TableBody>
                        </Table>
                        : <div className="no-data all-unit-no-data">{isLoading === false ? 'No Data Available' : ''}</div>}
                    </Paper>
                  </div>

                </Paper>
              </Col>
              <div class="printclearfix"></div>
            </div>
          ))
            : ''}
        </div>
      </div>
    );
  }
}