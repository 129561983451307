import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CONFIG } from "./../utils/config";
import Alert_Icon from "./favicon_new.ico";

export class IdleTimeOutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 60,
    };
    this.showNotification = this.showNotification.bind(this);
  }

  showNotification() {
    if (this.props.showModal == true) {
      var notification;
      var options = {
        body: "You will be logged out of the system due to inactivity. Do you want to stay logged in?",
        icon: CONFIG.OPTZ_URL + "logo_st.png?auto=compress&cs=tinysrgb&dpr=1&w=500",
        dir: "ltr",
      };
      notification = new Notification("Inactivity Warning", options);
    }
  }
  closeNotification() {
    var notification;
    notification.close();
  }

  componentDidMount() {
    document.getElementById("favicon").href = Alert_Icon;
    this.myInterval = setInterval(() => {
      const { count } = this.state;
      if (count > 0) {
        this.setState(({ count }) => ({ count: count - 1 }));
      }
      if(count == 60){
        this.showNotification();
      }
      if (count === 0) {
        clearInterval(this.myInterval);
        this.props.handleLogout();
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  render() {
    const { showModal, handleClose, handleLogout, isFrom } = this.props;
    return (
      <div>
        <Modal
          backdrop={false}
          isOpen={showModal}
          className="inactivity-warning-popup"
        >
          <ModalHeader>
            {isFrom == 1 ? "Logout Warning" : "Inactivity Warning"}{" "}
          </ModalHeader>
          <ModalBody>
            {isFrom == 1
              ? "You will be logged out of the system in " +
                this.state.count +
                " Seconds. Do you want to stay logged in?"
              : "You will be logged out of the system due to inactivity in " +
                this.state.count +
                " Seconds. Do you want to stay logged in?"}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleLogout}
              className="logout-session"
            >
              Log Out
            </Button>
            <Button
              color="secondary"
              onClick={handleClose}
              className="stay-session"
            >
              Stay on Page
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
