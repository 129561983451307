import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table as TableBoots } from 'reactstrap';
import storTLogo from '../../Assets/Images/logo.jpg';
import axios from 'axios';
import moment from 'moment';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import './BillingHistory.css';
import { CONFIG } from '../../utils/config';
import Paper from '@material-ui/core/Paper';
export default class BillingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingHistory: '',
            modalPrintInvoice: false,
            invoiceDesc: '',
            invoiceDate: '',
            invoiceTotal: '',
            invoiceName: '',
            invoiceAddress: '',
            invoiceCity: '',
            invoiceState: '',
            invoiceZipCode: '',
            isLoading: false
        };
        this.togglePrintInvoice = this.togglePrintInvoice.bind(this);
    }

    togglePrintInvoice(desc, date, total, name, address, city, stateInv, zipcode) {
        if (desc !== undefined && date !== undefined && total !== undefined && name !== undefined && address !== undefined && city !== undefined && stateInv !== undefined && zipcode !== undefined) {
            this.setState(prevState => ({
                modalPrintInvoice: !prevState.modalPrintInvoice,
                invoiceDesc: desc,
                invoiceDate: date,
                invoiceTotal: total,
                invoiceName: name,
                invoiceAddress: address,
                invoiceCity: city,
                invoiceState: stateInv,
                invoiceZipCode: zipcode
            }));
        }
        else {
            this.setState(prevState => ({
                modalPrintInvoice: !prevState.modalPrintInvoice
            }));
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'Account/billinghistory/' + CONFIG.RegionId)
            .then(response => {
                // this.state.isLoading=true;
                const billingHistory = response.data.billingdetail.billing;
                this.setState({
                    billingHistory, isLoading: false
                })
            }).catch((err) => {
                this.setState({
                    isLoading: false
                })
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });


    }
    render() {
        const { billingHistory, isLoading } = this.state;
        return (

            <div className="request-free-trial dashboard-layout">
                {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
                <Col>
                    <h5 className="bill-head"> Billing History</h5>

                    <div className="Table billing-table" >
                        <Paper className="bx-sh-0">
                            <Table className="table table-bordered custom-table">
                                <TableHead className="TH">
                                    <TableRow className="table-header">
                                        <TableCell>Billing Date</TableCell>
                                        <TableCell>Subscription Type</TableCell>
                                        <TableCell >Description</TableCell>
                                        <TableCell >Subscription Cost</TableCell>
                                        <TableCell >Print Invoice</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billingHistory.length > 0 ? billingHistory.map(data => {
                                        return (
                                            <TableRow className="table-body">
                                                <TableCell>{moment(data.billingDate).format('DD-MMM-YYYY')}</TableCell>
                                                <TableCell>{data.subscriptionType}</TableCell>
                                                <TableCell>{data.description}</TableCell>
                                                <TableCell>{data.subscriptionCost}</TableCell>
                                                {/* Below the code use only for development purpose */}
                                                <TableCell className="d-none">{data.name}</TableCell>
                                                <TableCell className="d-none">{data.billingAddress}</TableCell>
                                                <TableCell className="d-none">{data.billingCity}</TableCell>
                                                <TableCell className="d-none">{data.billingState}</TableCell>
                                                <TableCell className="d-none">{data.billingZipCode}</TableCell>
                                                <TableCell padding="checkbox">
                                                    <a className="pri-inv" key={data.orderID} onClick={() => this.togglePrintInvoice(data.description, data.billingDate, data.subscriptionCost, data.name, data.billingAddress, data.billingCity, data.billingState, data.billingZipCode)}>Print Invoice</a>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : 
                                        <td colspan="5" className="text-center">{isLoading===false ? 'No records found':''}</td>
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>
                </Col>
                <div>
                    <PrintProvider>
                        <NoPrint>
                            <Modal isOpen={this.state.modalPrintInvoice} toggle={this.togglePrintInvoice} size="lg">
                                <Print single name="foo">
                                    <ModalHeader className="bill-invoice-head" toggle={this.togglePrintInvoice}>
                                        <img src={storTLogo} alt="Logo StorTrack" />
                                    </ModalHeader>
                                    <ModalBody>
                                        <NoPrint><button className="close custom-close" onClick={this.togglePrintInvoice}>&times;</button></NoPrint>
                                        <Row className="print-invoice-header">
                                            <Col>
                                                <p className="m-0">26400 Lahser Rd. Suite 210</p>
                                                <p className="m-0">Southfield, MI 48033 </p>
                                                <p className="m-0">T: {CONFIG.Toll_Free} {CONFIG.RegionId === 3 ? "UK Direct tel:" +CONFIG.Sales_Support : ''}</p>
                                            </Col>
                                            <Col className="text-right">
                                                <h3 className="text-uppercase mb-0">Invoice</h3>
                                                <p className="date-show">{moment(this.state.invoiceDate).format('DD-MMM-YYYY')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-2 table-responsive">
                                                <TableBoots bordered className="print-invoice">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <h3>Bill to: </h3>
                                                                <p className="name-address mb-0">{this.state.invoiceName}</p>
                                                                <p className="name-address mb-0">
                                                                    {this.state.invoiceAddress},
                                                                    </p>
                                                                <p className="name-address mb-0">{this.state.invoiceCity}{this.state.invoiceState ? ', '+this.state.invoiceState:''} {this.state.invoiceZipCode}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h3>Description: </h3>
                                                                <p className="name-address">{this.state.invoiceDesc}</p>
                                                                <h3>Type of Payment: </h3>
                                                                <p className="name-address mb-0">Automatic renewal</p>
                                                            </td>
                                                            <td className="align-bottom">
                                                                <h3>Total:  </h3>
                                                                <p className="name-address mb-0">{this.state.invoiceTotal}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </TableBoots>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Print>
                                <ModalFooter>
                                    <Button color="primary" className="mb-0" onClick={() => window.print()}>Print Invoice</Button>
                                </ModalFooter>
                            </Modal>
                        </NoPrint>
                    </PrintProvider>
                </div>
            </div>
        );
    }
}