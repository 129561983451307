import React from 'react';
import './MarketDashboardHeader.css'
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import { Col, Row, Input } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';

export default class MarketDashboardHeader extends React.Component {
    constructor(props) {
        super(props);
       
    }
   
    componentDidMount() {
       
    }

    render() {
        return (
            <div className ="market-Dashboard-Heading">
                <Row className ="market-Heading">
                    <Col xs={12} md={3}>
                       <h3>Market Dashboard</h3>
                    </Col>
                    <Col xs={12} md={9} className="address-list">
                      <h5>{this.props.StoreName}</h5>
                      <p>{this.props.StoreAddress}, {this.props.StoreCity}, {this.props.StoreState} {this.props.StoreZipcode}</p>
                      <label>Coverage: </label><span>{this.props.StoreRadius}</span>
                    </Col>
                    {/* <Col xs={12} md={4} className="show-price">
                        <label className="show">Show Price Trend for</label>
                        <div className="select-checkbox">
                        <label>Show Online rates</label>
                        <Checkbox className="check-box-list"/>
                        <label>Regular rates</label>
                        <Checkbox className="check-box-list"/>
                        </div>
                    </Col> */}
                
                </Row> 
            </div>
        );
    }
}
