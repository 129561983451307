import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MyMarket, MapViews, Home,Login,Authendication, AllUnitPrize,InventoryAvailability,MarketSaturation, MyAccount, SubscriptionDetails,MarketDashboardHeader, Requesttrial, BillingHistory, MissingCompetitor, MarketDashboard, CompOverview, CompOverviewGmap, MarketScore,PricingVolatility, ViewDetails, MarketInventoryAnalysis, ErrorPage, Alert, PdfDownload, AveragePrice, MvpLogin, ActiveReports, CompReports, ActivityReportPdf} from '../Views';
import { DefaultLayoutRoute, MarketLayoutRoute, LoginLayoutRoute,PdfLayoutRoute } from './RouterLayout';
import { developmentListing } from '../Views/DevelopmentListing';
import { CONFIG } from "../utils/config";
// import { ActivityReportPdf } from '../Views/PdfDownload/ActivityReportPdf';


class RouteComp extends Component {
    render() {
        let token = localStorage.getItem('accessKey');
        return (
            <BrowserRouter>
                <Switch>
                    {/* Default router - Home Page */}
                    {/* <HomeLayoutRoute exact path="/" component={Home} /> */}

                    {/* Home Page */}
                    <DefaultLayoutRoute exact path="/" component={Home} />
                    <LoginLayoutRoute path="/login" component={Login} />
                    <DefaultLayoutRoute path="/average-price" component={AveragePrice} />
                    <LoginLayoutRoute exact path="/logins/:authurization/" component={Authendication}/>
                    {/* Session Out Page */}
                    <DefaultLayoutRoute path="/page_expired" component={ErrorPage} />
                    <MarketLayoutRoute path="/UnauthorizedAccess" component={ErrorPage} />
                   
                    <DefaultLayoutRoute  path="/requesttrial/" component={Requesttrial} />
                    {/* My Market Page */}
                    <MarketLayoutRoute path="/my-market/" component={MyMarket} /> 
                    <MarketLayoutRoute path="/market-saturation/" component={MarketSaturation} />
                    <MarketLayoutRoute path="/my-account/" component={MyAccount} />
                    <MarketLayoutRoute path="/billing-history/" component={BillingHistory} />
                    <MarketLayoutRoute path="/subscription-details/" component={SubscriptionDetails }/>
                    <DefaultLayoutRoute  path="/missing" component={MissingCompetitor} />
                    <MarketLayoutRoute path="/market-dashboard/:storeid/view-details/:searchid/:tab?" component={ViewDetails} />
                    <MarketLayoutRoute path="/market-dashboard/:storeid/" component={MarketDashboard} />
                    <DefaultLayoutRoute  path="/market-dashboard-header/" component={MarketDashboardHeader} />
                    <DefaultLayoutRoute  path="/active-reports" component={ActiveReports} />
                    <DefaultLayoutRoute  path="/comp-reports" component={CompReports} />
                    {/* <MarketLayoutRoute path="/pricing-volatility/" component={PricingVolatility} /> */}
                    <MarketLayoutRoute path="/market-level-data/inventory-analytics/:userstoreid/" component={InventoryAvailability} />
                    <MarketLayoutRoute path="/market-level-data/all-units-pricing/:userstoreid/" component={AllUnitPrize} />
                    <MarketLayoutRoute path="/market-level-data/pricing-volatility/:userstoreid/" component={PricingVolatility} />
                    {/* <Route path="/market-level-data/pricing-volatility-pdf/:userstoreid/" component={PricingVolatilityPdf} /> */}
                    <MarketLayoutRoute path="/market-level-data/competitor-overview/:userstoreid/" component={CONFIG.IsMapbox ? CompOverview : CompOverviewGmap} />
                    
                    <MarketLayoutRoute path="/market-level-data/alert/:userstoreid/" component={Alert} />
                    <MarketLayoutRoute path="/development-listing" component={developmentListing} />
                    {/*Marketing Scoring*/}
                    <MarketLayoutRoute path="/marketing-score/:storeid" component={MarketScore} />
                    {/* <Route path="/marketing-score-Pdf/:storeid/:order/:orderBy/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?/:id7?" component={MarketScorePdf} /> */}
                    {/* Market dashboard view details */}
                    {/* <MarketLayoutRoute path="/view-details/" component={ViewDetails} /> */}
                  
                    <MarketLayoutRoute path="/market-level-data/map-view/:userstoreid/" component={MapViews} />
                    <PdfLayoutRoute path="/render-activity-pdf/:accessKey?/:usId?" component={ActivityReportPdf} />
                    <PdfLayoutRoute path="/render-pdf/:accessKey?/:ComponentName?/:durTime?/:usId?/:pta?/:searchId?/:unitType?/:priceType?/:orderBy?/:order?/:isShowGraph?/:isRemoveGraph?/:isCompsName?/:Competitors?/:id4?/:id5?/:id6?/:id7?" component={PdfDownload} />
                    {/* <PdfLayoutRoute path="/render-pdf/:accessKey?/:ComponentName?/:usId?/:pta?/:searchId?/:unitType?/:priceType?/:orderBy?/:order?/:isShowGraph?/:isRemoveGraph?/:isCompsName?/:Competitors?/" component={PdfDownload} /> */}
                    <MarketLayoutRoute path="/market-level-data/marketing-inventory-analysis/:userstoreid/" component={MarketInventoryAnalysis} />
                   {/* <DefaultLayoutRoute path ="/alert" component ={Alert}/> */}
                   {/* <MarketLayoutRoute path ="/alert" component ={Alert}/> */}
                   {/* MVP login */}
                   <LoginLayoutRoute path="/tpuserlogin/:userstoreid/:secureid" component={MvpLogin} />
                    {/* 404 Error */}
                    { token ? 
                        <MarketLayoutRoute path="*" component={ErrorPage} status={404} />
                    :
                        <DefaultLayoutRoute path="*" component={ErrorPage} status={404} />
                    }
                </Switch>
            </BrowserRouter>
        );
    }
}
export default RouteComp;