import React, { Component } from "react";
import "./AllUnitPrize.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from "@material-ui/core/Paper";
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";
import shows from "./../../Assets/Images/show.png";
import hiddens from "./../../Assets/Images/unshow.png";
import { CONFIG } from "../../utils/config";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CompetitorStore } from "../CompetitorStore";
import Popover from "@material-ui/core/Popover";
import { StoreStreetView } from "../StoreStreetView";
import moment from "moment";
import Chart from "react-apexcharts"

export default class AllUnitPrize extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.storeImageStreetView = React.createRef();

    this.state = {
      options: {
        chart: {
          type: 'rangeBar',
          toolbar: {
            tools: {
              download: false,
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,  
        },
        fill: {
          type: "solid",
          opacity: 0.6
        },
        stroke: {
          width: 2
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false
        },
        axisTicks: {
          show: false
        }
        },
        noData: {
          text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
          verticalAlign: 'top',
          style: {
            color: "#5b95f6",
            fontSize: '13px',
            fontFamily: "Helvetica"
          }
        },
      },
      series: [
        {
          name: "Your Store",
          data: []
        },
        {
          name: "Market Average",
          data: []
        }, {
          name: '',
          data: []
        }
      ],
      pricing: "",
      comparisons: "",
      storeAddress: "",
      market: "",
      myStore: "",
      compitator: "",
      gmyStore: "",
      gcompitator: "",
      compitatorAll: "",
      dropdownOpen: false,
      isShowGraph: [],
      isRemoveGraph: [],
      isShowGraph1: [],
      isRemoveGraph1: [],
      isSelectStoreId: [],
      isLoading: false,
      yGroupMax: "",
      myStoreID: "",
      alwaysMyStoreID: "",
      snackOpen: false,
      snackMSg: "",
      dropDownValue: "Sort by",
      anchorEl: null,
      markets1: "",
      sIdPDF: "",
      typePDF: "",
      storePlace: "",
      snackClr: "",
      isNoGraphData: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.getParams = this.getParams.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
  }

  handlePopoverOpen(e, str) {
    this.setState({
      anchorEl: e.currentTarget,
      mStr: str,
    });
  }

  mouseOut(str) {
    if (this.state.mStr == str) {
      this.setState({ anchorEl: null });
    }
  }

  getParams(storeAddress) {
    let storeId = parseInt(this.props.match.params.userstoreid);
    this.child.current.toggleStoreModal(storeAddress, storeId);
  }

  addDefaultSrc(ev) {
    if (ev.target.src != null) {
      var imageName = require("./../../Assets/Images/no-image.jpg");
      ev.target.src = imageName;
      ev.target.className = "noimages Unit-Store-Img cus-h-w-set";
    }
  }

  getStoreStreeView(lat, lon, name) {
    this.storeImageStreetView.current.toggleStoreModal(lat, lon, name);
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let isShowGraph = [];
    let isRemoveGraph = [];
    let isShowGraph1 = [];
    let isRemoveGraph1 = [];
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const data = {
      UserStoreID: parseInt(this.props.match.params.userstoreid),
      RegionId: CONFIG.RegionId,
    };

    axios
      .post(CONFIG.API_URL + "dashboard/pricingcomparision", data)
      .then((res) => {
        let pricing = res.data.data.pricings;
        let comparisons = res.data.data.comparisons;
        this.setState({ pricing });
        this.setState({ comparisons });

        var market = comparisons.filter(function (m) {
          return m.storeID == 0;
        });

        var markets1 = market.filter(function (m1) {
          return m1.storeID == 0 && m1.priceFrom > 0 && m1.priceTo > 0;
        });

        this.setState({ market });

        var myStore = comparisons.filter(function (ms) {
          return ms.storeID > 0 && ms.isMyStore == 1;
        });
        var compitator = comparisons.filter(function (c) {
          return c.storeID > 0 && c.isMyStore != 1;
        });

        var compitatorAll = comparisons.filter(function (c) {
          return c.storeID > 0 && c.isMyStore != 1;
        });
        let alwaysMyStoreID = myStore.length > 0 ? myStore[0].storeID : 0;
        let myStoreID = myStore.length > 0 ? myStore[0].storeID : 0;
        myStore
          .filter((e) => e.isSelected === false)
          .forEach(function (e) {
            isShowGraph1.push(e.storeID);
          });
        myStore
          .filter((e) => e.isSelected === true)
          .forEach(function (e) {
            isRemoveGraph1.push(e.storeID);
          });
        compitatorAll
          .filter((e) => e.isSelected === false)
          .forEach(function (e) {
            isShowGraph1.push(e.storeID);
          });
        compitatorAll
          .filter((e) => e.isSelected === true)
          .forEach(function (e) {
            isRemoveGraph1.push(e.storeID);
          });
        var gmyStore = myStore.filter(function (ms) {
          return ms.isSelected === true;
        });
        var gcompitator = compitator.filter(function (ms) {
          return ms.isSelected === true;
        });
        this.setState({
          myStore,
          gmyStore: myStore,
          gcompitator,
          gcompitator: gcompitator,
          alwaysMyStoreID,
          myStoreID,
        });

        this.setState({ compitatorAll });

        isShowGraph = [...new Set(isShowGraph1.map((item) => item))];
        isRemoveGraph = [...new Set(isRemoveGraph1.map((item) => item))];

        if (isShowGraph === undefined) {
          isShowGraph = [];
        }
        if (isRemoveGraph === undefined) {
          isRemoveGraph = [];
        }


        this.setState({
          isShowGraph,
          isRemoveGraph,
          isShowGraph1,
          isRemoveGraph1,
        });
        const data1 = {
          UserStoreID: parseInt(this.props.match.params.userstoreid),
          RegionId: CONFIG.RegionId,
        };
        axios
          .post(CONFIG.API_URL + "dashboard/dashboardlocation/", data1)
          .then((res) => {
            let storeAddress = res.data.data.storeDashboard;
            this.setState({ storeAddress });
            if (
              market.length > 0 ||
              gmyStore.length > 0 ||
              gcompitator.length > 0
            ) {
              if (gmyStore.length > 0) {
                this.multiSeriesGraph(gmyStore, gcompitator);
              } else {
                let compid = [...new Set(gcompitator.map((item) => item.storeID))];
                if (compid.length > 1) {
                  gmyStore = gcompitator.filter(function (m) {
                    return m.storeID === compid[0];
                  });
                  gcompitator = gcompitator.filter(function (m) {
                    return m.storeID === compid[1];
                  });
                }
                this.multiSeriesGraph(gmyStore, gcompitator);
              }
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            if (err.response.status === 401) {
              localStorage.removeItem("accessKey");
              const { from } = this.props.location.state || {
                from: { pathname: "/" },
              };
              this.props.history.push(from);
            } else if (err.response.status === 403) {
              const { from } = this.props.location.state || {
                from: { pathname: "/UnauthorizedAccess" },
              };
              this.props.history.push(from);
            } else {
              alert(err.response.data);
            }
          });
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          this.setState({ isLoading: false });
          this.setState({ snackOpen: true, snackMSg: err });
        }
      });
  }


  showGraph(sId, type) {
    this.state.sIdPDF = sId;
    this.state.typePDF = type;
    const isShowGraph = this.state.isShowGraph;
    const isRemoveGraph = this.state.isRemoveGraph;
    let index;
    //Remove Graph
    if (type === 1) {
      if (sId === this.state.myStoreID) {
        this.setState({ myStoreID: 0 });
      }
      if (isRemoveGraph.length <= 2) {
        index = isRemoveGraph.indexOf(+sId);
        isRemoveGraph.splice(index, 1);
        isShowGraph.push(+sId);

        this.onlyRemoveGraph(sId);
      }
    } else {
      // show graph
      if (0 === this.state.myStoreID) {
        this.setState({ myStoreID: sId });
      }
      if (isRemoveGraph.length < 2) {
        index = isShowGraph.indexOf(+sId);
        isShowGraph.splice(index, 1);
        isRemoveGraph.push(+sId);
        this.onlyShowGraph(sId);
      } else {
        let msg =
          "You can only display a maximum of two stores on the chart. Please deselect a store before selecting another store to view.";
        this.setState({ snackOpen: true, snackMSg: msg });
      }
    }

    this.setState({ isShowGraph, isRemoveGraph });

    const rdata = {
      UserStoreID: parseInt(this.props.match.params.userstoreid),
      RegionId: CONFIG.RegionId,
      Competitors: isRemoveGraph.join(","),
    };
    axios
      .post(CONFIG.API_URL + "dashboard/updatepricingcomparision", rdata)
      .then((res) => { })
      .catch((err) => {
        if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          this.setState({ isLoading: false });
          this.setState({ snackOpen: true, snackMSg: err });
        }
      });
  }

  onlyRemoveGraph(sId) {
    this.setState({ isLoading: true });
    var shwID = this.state.isRemoveGraph.filter(function (m) {
      return m != sId;
    });
    let idx2Show = 0;
    idx2Show = shwID.length > 0 ? shwID[0] : 0;
    var Comp = [];
    if (shwID > 0) {
      Comp = this.state.comparisons.filter(function (m) {
        return m.storeID === idx2Show;
      });
    }
    if (sId === this.state.myStoreID) {
      this.setState({ gmyStore: [], storePlace: "store" });
      this.multiSeriesGraph([], Comp);
    } else {
      this.setState({ gcompitator: [], storePlace: "comp" });
      var mStore = Comp.filter(function (m) {
        return m.isMyStore === true;
      });
      if (mStore.length > 0) this.multiSeriesGraph(mStore, []);
      else this.multiSeriesGraph([], Comp);
    }

    this.setState({ isLoading: false });
  }

  onlyShowGraph(sId) {
    this.setState({ isLoading: true });
    var shwID = this.state.isRemoveGraph.filter(function (m) {
      return m != sId;
    });

    let idx2Show = 0;
    idx2Show = shwID.length > 0 ? shwID[0] : 0;
    var Comp = [];
    if (shwID > 0) {
      Comp = this.state.comparisons.filter(function (m) {
        return m.storeID === idx2Show;
      });
    }

    var showStore = this.state.comparisons.filter(function (m) {
      return m.storeID == sId;
    });

    var mStore = showStore.filter(function (m) {
      return m.isMyStore === true;
    });

    if (mStore.length > 0) {
      showStore = [];
    } else {
      mStore = Comp.filter(function (m) {
        return m.isMyStore === true;
      });
      if (mStore.length > 0) {
        Comp = [];
      }
    }


    if (this.state.myStoreID === sId) {
      // show store in my store place
      if (showStore.length > 0) this.multiSeriesGraph(mStore, showStore);
      else this.multiSeriesGraph(mStore, Comp);
      this.setState({ gmyStore: mStore, storePlace: "sc" });
    } else {
      // Show store in competitor place
      this.setState({ gcompitator: showStore, storePlace: "cs" });
      if (mStore.length > 0)this.multiSeriesGraph(mStore, []);
      if (mStore.length > 0 && showStore.length > 0)
        this.multiSeriesGraph(mStore, showStore);
      else if (mStore.length > 0 && Comp.length > 0)
        this.multiSeriesGraph(mStore, Comp);
      else {
        if (showStore.length > 0 && Comp.length > 0)
          this.multiSeriesGraph(showStore, Comp);
        else if (showStore.length > 0) this.multiSeriesGraph([], showStore);
        else if (Comp.length > 0) this.multiSeriesGraph([], Comp);
      }
      //this.multiSeriesGraph(this.state.gmyStore, showStore);
    }

    this.setState({ isLoading: false });
  }

  multiSeriesGraph(store, compitator) {
    let market = this.state.market;
    let storeName = "";
    let compitatorname = compitator.length > 0 ? compitator[0].storeName : "";
    if (store.length === 0) {
      storeName = "";
    } else {
      if (store[0].storeID === this.state.alwaysMyStoreID) {
        storeName = " Your Store";
      } else {
        storeName = store[0].storeName;
      }
    }
    let data_compitator1 = [];
    let y_data_compitator1 = [];
    let y0_data_compitator1 = [];
    let countryCurrency = this.state.storeAddress;
    let check_Zero = compitator.concat(market,store).map(item => item.priceFrom && item.priceTo).filter(item => item !== 0 && item !== null && item !== '' && item !== undefined ? true : false );
    for (var i = 0; i < compitator.length; i++) {
      y_data_compitator1.push(compitator[i].priceFrom);
      y0_data_compitator1.push(compitator[i].priceTo);
      if (compitator[i].priceFrom === 0 && compitator[i].priceTo === 0) {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo], address: compitator[i].address });
      } else if (compitator[i].priceFrom === compitator[i].priceTo) {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo], address: compitator[i].address });
      } else {
        data_compitator1.push({ x: compitator[i].unitType, y: [compitator[i].priceFrom, compitator[i].priceTo], address: compitator[i].address });
      }
    }

    var y_min_compitator1 = Math.min.apply(Math, y_data_compitator1.filter(Number));
    var y0_min_compitator1 = Math.min.apply(Math, y0_data_compitator1.filter(Number));

    let leastVal_compitator1;
    if (y_min_compitator1 < y0_min_compitator1) {
      leastVal_compitator1 = y_min_compitator1
    } else {
      leastVal_compitator1 = y0_min_compitator1
    }
    let data_market1 = [];
    let y_data_market1 = [];
    let y0_data_market1 = [];

    for (var i = 0; i < market.length; i++) {
      y_data_market1.push(market[i].priceFrom)
      y0_data_market1.push(market[i].priceTo)
      if (market[i].priceFrom === 0 && market[i].priceTo === 0) {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo], address: market[i].address });
      } else if (market[i].priceFrom === market[i].priceTo) {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo], address: market[i].address });
      } else {
        data_market1.push({ x: market[i].unitType, y: [market[i].priceFrom, market[i].priceTo], address: market[i].address });
      }
    }
    var y_min_market1 = Math.min.apply(Math, y_data_market1.filter(Number));
    var y0_min_marke1 = Math.min.apply(Math, y0_data_market1.filter(Number));

    let leastVal_market1;
    if (y_min_market1 < y0_min_marke1) {
      leastVal_market1 = y_min_market1
    } else {
      leastVal_market1 = y0_min_marke1
    }
    let data_storeName = [];
    let y_data_storeName = [];
    let y0_data_storeName = [];

    for (var i = 0; i < store.length; i++) {
      y_data_storeName.push(store[i].priceFrom)
      y0_data_storeName.push(store[i].priceTo)
      if (store[i].priceFrom === 0 && store[i].priceTo === 0) {
        data_storeName.push({ x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo], address: store[i].address });
      } else if (store[i].priceFrom === store[i].priceTo) {
        data_storeName.push({ name: storeName, x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo], address: store[i].address });
      } else {
        data_storeName.push({ x: store[i].unitType, y: [store[i].priceFrom, store[i].priceTo], address: store[i].address });
      }
    }

    var y_min_storeName = Math.min.apply(Math, y_data_storeName.filter(Number));
    var y0_min_storeName = Math.min.apply(Math, y0_data_storeName.filter(Number));

    let leastVal_storeName;
    if (y_min_storeName < y0_min_storeName) {
      leastVal_storeName = y_min_storeName
    } else {
      leastVal_storeName = y0_min_storeName
    }
    let yAxisMinValue = Math.min(leastVal_storeName, leastVal_market1, leastVal_compitator1);
    let allData = [{ name: compitatorname, data: data_compitator1 }, { name: "Market Average", data: data_market1 },  { name: storeName , data: data_storeName }];
  
    if (check_Zero.length > 0) {
      this.setState({
        isNoGraphData: false,
      });
      this.setState({
        options: {
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin:false,
                zoomout:false,
                pan:false,
                reset: true,
              },
            },
          },
          tooltip: {
            enabled: true,
            showForNullSeries: false,
            custom: undefined,
            y: {
              formatter: function (val, opts) {
                if (typeof (opts) === "object") {
                  let add = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].address !== null ? opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].address : "";
                  let dataVal = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[0] !== opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[1] ? `${countryCurrency.countryCurrency}${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[0]} - ${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[1]}` : `${countryCurrency.countryCurrency}${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y[0]}`
                  let aUP = opts.w.config.series[opts.seriesIndex].name == "Market Average" ? `${opts.w.config.series[opts.seriesIndex].name} <br/> Price Range: ${dataVal}`:`${opts.w.config.series[opts.seriesIndex].name}<br/> ${add} <br/>Price Range: ${dataVal}`
                  return aUP;
                }
              },
              title: {
                formatter: function (val) {
                  if (val) return "";
                }
              }
            },
            marker: {
              show: true,
            },
            onDatasetHover: {
              highlightDataSeries: false,
            },
            style: {
              fontSize: '11px',
              fontFamily: "Roboto, Helvetica, Arial , sans-serif"
            },
          },
          legend: {
            position: 'bottom',
            showForSingleSeries: true,
            horizontalAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            showForNullSeries: false,
            showForZeroSeries: true,
            itemMargin: {
              horizontal: 10,
              vertical: 20,
            },
            onItemClick: {
              toggleDataSeries: true
            },
            onItemHover: {
              highlightDataSeries: true
            },
            formatter: function (seriesName, opts) {
              if (seriesName) return seriesName.charAt(0).toUpperCase() + seriesName.slice(1);;
            }
          },
          yaxis: {
            min: yAxisMinValue,
            max: function(max){return max+5},
            labels: {
              formatter: function (value) {
                let val = value === null || value === undefined ? '' : countryCurrency.countryCurrency + value.toFixed(0);
                return val;
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
          },
          xaxis: {
            show: true,
            labels: {
              show: true,
              rotate: 0,
              hideOverlappingLabels: false,
              trim: true,
              formatter: function (value) {
                let val = value === undefined || typeof (value) !== "string" ? '' : value;
                return val;
              },
              style: {
                fontSize: '10px'
              }
            },
            axisBorder: {
              show: true
          },
          axisTicks: {
            show: true
          }
          },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: allData
      })
    } else {
      this.setState({
        isNoGraphData: true,
      });
     
    }
  }

  formData(data) {
    let unique = [...new Set(data.map((item) => item.unitType))];
    let uniquestore = [...new Set(data.map((item) => item.storeID))];
    let storeAddress = this.state.storeAddress;

    var FData = [];
    for (var t = 0; t < uniquestore.length; t++) {
      var tFData = data.filter((a) => a.storeID == uniquestore[t]);
      switch (unique.length) {
        case 12: {
          var mData = {
            StoreID: uniquestore[t],
            Address: tFData[0].address,
            StoreName: tFData[0].storeName,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1:
              tFData[0].priceTo === 0 && tFData[0].priceFrom === 0
                ? "N/A"
                : tFData[0].priceTo === tFData[0].priceFrom
                  ? storeAddress.countryCurrency + tFData[0].priceTo
                  : storeAddress.countryCurrency +
                  tFData[0].priceFrom +
                  " - " +
                  tFData[0].priceTo,
            Unit2:
              tFData[1].priceTo === 0 && tFData[1].priceFrom === 0
                ? "N/A"
                : tFData[1].priceTo === tFData[1].priceFrom
                  ? storeAddress.countryCurrency + tFData[1].priceTo
                  : storeAddress.countryCurrency +
                  tFData[1].priceFrom +
                  " - " +
                  tFData[1].priceTo,
            Unit3:
              tFData[2].priceTo === 0 && tFData[2].priceFrom === 0
                ? "N/A"
                : tFData[2].priceTo === tFData[2].priceFrom
                  ? storeAddress.countryCurrency + tFData[2].priceTo
                  : storeAddress.countryCurrency +
                  tFData[2].priceFrom +
                  " - " +
                  tFData[2].priceTo,
            Unit4:
              tFData[3].priceTo === 0 && tFData[3].priceFrom === 0
                ? "N/A"
                : tFData[3].priceTo === tFData[3].priceFrom
                  ? storeAddress.countryCurrency + tFData[3].priceTo
                  : storeAddress.countryCurrency +
                  tFData[3].priceFrom +
                  " - " +
                  tFData[3].priceTo,
            Unit5:
              tFData[4].priceTo === 0 && tFData[4].priceFrom === 0
                ? "N/A"
                : tFData[4].priceTo === tFData[4].priceFrom
                  ? storeAddress.countryCurrency + tFData[4].priceTo
                  : storeAddress.countryCurrency +
                  tFData[4].priceFrom +
                  " - " +
                  tFData[4].priceTo,
            Unit6:
              tFData[5].priceTo === 0 && tFData[5].priceFrom === 0
                ? "N/A"
                : tFData[5].priceTo === tFData[5].priceFrom
                  ? storeAddress.countryCurrency + tFData[5].priceTo
                  : storeAddress.countryCurrency +
                  tFData[5].priceFrom +
                  " - " +
                  tFData[5].priceTo,
            Unit7:
              tFData[6].priceTo === 0 && tFData[6].priceFrom === 0
                ? "N/A"
                : tFData[6].priceTo === tFData[6].priceFrom
                  ? storeAddress.countryCurrency + tFData[6].priceTo
                  : storeAddress.countryCurrency +
                  tFData[6].priceFrom +
                  " - " +
                  tFData[6].priceTo,
            Unit8:
              tFData[7].priceTo === 0 && tFData[7].priceFrom === 0
                ? "N/A"
                : tFData[7].priceTo === tFData[7].priceFrom
                  ? storeAddress.countryCurrency + tFData[7].priceTo
                  : storeAddress.countryCurrency +
                  tFData[7].priceFrom +
                  " - " +
                  tFData[7].priceTo,
            Unit9:
              tFData[8].priceTo === 0 && tFData[8].priceFrom === 0
                ? "N/A"
                : tFData[8].priceTo === tFData[8].priceFrom
                  ? storeAddress.countryCurrency + tFData[8].priceTo
                  : storeAddress.countryCurrency +
                  tFData[8].priceFrom +
                  " - " +
                  tFData[8].priceTo,
            Unit10:
              tFData[9].priceTo === 0 && tFData[9].priceFrom === 0
                ? "N/A"
                : tFData[9].priceTo === tFData[9].priceFrom
                  ? storeAddress.countryCurrency + tFData[9].priceTo
                  : storeAddress.countryCurrency +
                  tFData[9].priceFrom +
                  " - " +
                  tFData[9].priceTo,
            Unit11:
              tFData[10].priceTo === 0 && tFData[10].priceFrom === 0
                ? "N/A"
                : tFData[10].priceTo === tFData[10].priceFrom
                  ? storeAddress.countryCurrency + tFData[10].priceTo
                  : storeAddress.countryCurrency +
                  tFData[10].priceFrom +
                  " - " +
                  tFData[10].priceTo,
            Unit12:
              tFData[11].priceTo === 0 && tFData[11].priceFrom === 0
                ? "N/A"
                : tFData[11].priceTo === tFData[11].priceFrom
                  ? storeAddress.countryCurrency + tFData[11].priceTo
                  : storeAddress.countryCurrency +
                  tFData[11].priceFrom +
                  " - " +
                  tFData[11].priceTo,

            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude,
          };
          FData.push(mData);
          break;
        }
        case 10: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1:
              tFData[0].priceTo === 0 && tFData[0].priceFrom === 0
                ? "N/A"
                : tFData[0].priceTo === tFData[0].priceFrom
                  ? storeAddress.countryCurrency + tFData[0].priceTo
                  : storeAddress.countryCurrency +
                  tFData[0].priceFrom +
                  " - " +
                  tFData[0].priceTo,
            Unit2:
              tFData[1].priceTo === 0 && tFData[1].priceFrom === 0
                ? "N/A"
                : tFData[1].priceTo === tFData[1].priceFrom
                  ? storeAddress.countryCurrency + tFData[1].priceTo
                  : storeAddress.countryCurrency +
                  tFData[1].priceFrom +
                  " - " +
                  tFData[1].priceTo,
            Unit3:
              tFData[2].priceTo === 0 && tFData[2].priceFrom === 0
                ? "N/A"
                : tFData[2].priceTo === tFData[2].priceFrom
                  ? storeAddress.countryCurrency + tFData[2].priceTo
                  : storeAddress.countryCurrency +
                  tFData[2].priceFrom +
                  " - " +
                  tFData[2].priceTo,
            Unit4:
              tFData[3].priceTo === 0 && tFData[3].priceFrom === 0
                ? "N/A"
                : tFData[3].priceTo === tFData[3].priceFrom
                  ? storeAddress.countryCurrency + tFData[3].priceTo
                  : storeAddress.countryCurrency +
                  tFData[3].priceFrom +
                  " - " +
                  tFData[3].priceTo,
            Unit5:
              tFData[4].priceTo === 0 && tFData[4].priceFrom === 0
                ? "N/A"
                : tFData[4].priceTo === tFData[4].priceFrom
                  ? storeAddress.countryCurrency + tFData[4].priceTo
                  : storeAddress.countryCurrency +
                  tFData[4].priceFrom +
                  " - " +
                  tFData[4].priceTo,
            Unit6:
              tFData[5].priceTo === 0 && tFData[5].priceFrom === 0
                ? "N/A"
                : tFData[5].priceTo === tFData[5].priceFrom
                  ? storeAddress.countryCurrency + tFData[5].priceTo
                  : storeAddress.countryCurrency +
                  tFData[5].priceFrom +
                  " - " +
                  tFData[5].priceTo,
            Unit7:
              tFData[6].priceTo === 0 && tFData[6].priceFrom === 0
                ? "N/A"
                : tFData[6].priceTo === tFData[6].priceFrom
                  ? storeAddress.countryCurrency + tFData[6].priceTo
                  : storeAddress.countryCurrency +
                  tFData[6].priceFrom +
                  " - " +
                  tFData[6].priceTo,
            Unit8:
              tFData[7].priceTo === 0 && tFData[7].priceFrom === 0
                ? "N/A"
                : tFData[7].priceTo === tFData[7].priceFrom
                  ? storeAddress.countryCurrency + tFData[7].priceTo
                  : storeAddress.countryCurrency +
                  tFData[7].priceFrom +
                  " - " +
                  tFData[7].priceTo,
            Unit9:
              tFData[8].priceTo === 0 && tFData[8].priceFrom === 0
                ? "N/A"
                : tFData[8].priceTo === tFData[8].priceFrom
                  ? storeAddress.countryCurrency + tFData[8].priceTo
                  : storeAddress.countryCurrency +
                  tFData[8].priceFrom +
                  " - " +
                  tFData[8].priceTo,
            Unit10:
              tFData[9].priceTo === 0 && tFData[9].priceFrom === 0
                ? "N/A"
                : tFData[9].priceTo === tFData[9].priceFrom
                  ? storeAddress.countryCurrency + tFData[9].priceTo
                  : storeAddress.countryCurrency +
                  tFData[9].priceFrom +
                  " - " +
                  tFData[9].priceTo,
            // Unit11: tFData[10].priceTo === 0 && tFData[10].priceFrom === 0 ? 'N/A' : tFData[10].priceTo === tFData[10].priceFrom ? storeAddress.countryCurrency + tFData[10].priceTo : storeAddress.countryCurrency + tFData[10].priceFrom + " - " + tFData[10].priceTo,
            // Unit12: tFData[11].priceTo === 0 && tFData[11].priceFrom === 0 ? 'N/A' : tFData[11].priceTo === tFData[11].priceFrom ? storeAddress.countryCurrency + tFData[11].priceTo : storeAddress.countryCurrency + tFData[11].priceFrom + " - " + tFData[11].priceTo,
            // Unit13: tFData[12].priceTo === 0 && tFData[12].priceFrom === 0 ? 'N/A' : tFData[12].priceTo === tFData[12].priceFrom ? storeAddress.countryCurrency + tFData[12].priceTo : storeAddress.countryCurrency + tFData[12].priceFrom + " - " + tFData[12].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude,
          };
          FData.push(mData);
          break;
        }
        case 14: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1:
              tFData[0].priceTo === 0 && tFData[0].priceFrom === 0
                ? "N/A"
                : tFData[0].priceTo === tFData[0].priceFrom
                  ? storeAddress.countryCurrency + tFData[0].priceTo
                  : storeAddress.countryCurrency +
                  tFData[0].priceFrom +
                  " - " +
                  tFData[0].priceTo,
            Unit2:
              tFData[1].priceTo === 0 && tFData[1].priceFrom === 0
                ? "N/A"
                : tFData[1].priceTo === tFData[1].priceFrom
                  ? storeAddress.countryCurrency + tFData[1].priceTo
                  : storeAddress.countryCurrency +
                  tFData[1].priceFrom +
                  " - " +
                  tFData[1].priceTo,
            Unit3:
              tFData[2].priceTo === 0 && tFData[2].priceFrom === 0
                ? "N/A"
                : tFData[2].priceTo === tFData[2].priceFrom
                  ? storeAddress.countryCurrency + tFData[2].priceTo
                  : storeAddress.countryCurrency +
                  tFData[2].priceFrom +
                  " - " +
                  tFData[2].priceTo,
            Unit4:
              tFData[3].priceTo === 0 && tFData[3].priceFrom === 0
                ? "N/A"
                : tFData[3].priceTo === tFData[3].priceFrom
                  ? storeAddress.countryCurrency + tFData[3].priceTo
                  : storeAddress.countryCurrency +
                  tFData[3].priceFrom +
                  " - " +
                  tFData[3].priceTo,
            Unit5:
              tFData[4].priceTo === 0 && tFData[4].priceFrom === 0
                ? "N/A"
                : tFData[4].priceTo === tFData[4].priceFrom
                  ? storeAddress.countryCurrency + tFData[4].priceTo
                  : storeAddress.countryCurrency +
                  tFData[4].priceFrom +
                  " - " +
                  tFData[4].priceTo,
            Unit6:
              tFData[5].priceTo === 0 && tFData[5].priceFrom === 0
                ? "N/A"
                : tFData[5].priceTo === tFData[5].priceFrom
                  ? storeAddress.countryCurrency + tFData[5].priceTo
                  : storeAddress.countryCurrency +
                  tFData[5].priceFrom +
                  " - " +
                  tFData[5].priceTo,
            Unit7:
              tFData[6].priceTo === 0 && tFData[6].priceFrom === 0
                ? "N/A"
                : tFData[6].priceTo === tFData[6].priceFrom
                  ? storeAddress.countryCurrency + tFData[6].priceTo
                  : storeAddress.countryCurrency +
                  tFData[6].priceFrom +
                  " - " +
                  tFData[6].priceTo,
            Unit8:
              tFData[7].priceTo === 0 && tFData[7].priceFrom === 0
                ? "N/A"
                : tFData[7].priceTo === tFData[7].priceFrom
                  ? storeAddress.countryCurrency + tFData[7].priceTo
                  : storeAddress.countryCurrency +
                  tFData[7].priceFrom +
                  " - " +
                  tFData[7].priceTo,
            Unit9:
              tFData[8].priceTo === 0 && tFData[8].priceFrom === 0
                ? "N/A"
                : tFData[8].priceTo === tFData[8].priceFrom
                  ? storeAddress.countryCurrency + tFData[8].priceTo
                  : storeAddress.countryCurrency +
                  tFData[8].priceFrom +
                  " - " +
                  tFData[8].priceTo,
            Unit10:
              tFData[9].priceTo === 0 && tFData[9].priceFrom === 0
                ? "N/A"
                : tFData[9].priceTo === tFData[9].priceFrom
                  ? storeAddress.countryCurrency + tFData[9].priceTo
                  : storeAddress.countryCurrency +
                  tFData[9].priceFrom +
                  " - " +
                  tFData[9].priceTo,
            Unit11:
              tFData[10].priceTo === 0 && tFData[10].priceFrom === 0
                ? "N/A"
                : tFData[10].priceTo === tFData[10].priceFrom
                  ? storeAddress.countryCurrency + tFData[10].priceTo
                  : storeAddress.countryCurrency +
                  tFData[10].priceFrom +
                  " - " +
                  tFData[10].priceTo,
            Unit12:
              tFData[11].priceTo === 0 && tFData[11].priceFrom === 0
                ? "N/A"
                : tFData[11].priceTo === tFData[11].priceFrom
                  ? storeAddress.countryCurrency + tFData[11].priceTo
                  : storeAddress.countryCurrency +
                  tFData[11].priceFrom +
                  " - " +
                  tFData[11].priceTo,
            Unit13:
              tFData[12].priceTo === 0 && tFData[12].priceFrom === 0
                ? "N/A"
                : tFData[12].priceTo === tFData[12].priceFrom
                  ? storeAddress.countryCurrency + tFData[12].priceTo
                  : storeAddress.countryCurrency +
                  tFData[12].priceFrom +
                  " - " +
                  tFData[12].priceTo,
            Unit14:
              tFData[13].priceTo === 0 && tFData[13].priceFrom === 0
                ? "N/A"
                : tFData[13].priceTo === tFData[13].priceFrom
                  ? storeAddress.countryCurrency + tFData[13].priceTo
                  : storeAddress.countryCurrency +
                  tFData[13].priceFrom +
                  " - " +
                  tFData[13].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude,
          };
          FData.push(mData);
          break;
        }
        default: {
          var mData = {
            StoreID: uniquestore[t],
            StoreName: tFData[0].storeName,
            Address: tFData[0].address,
            Proximity: tFData[0].proximity.toFixed(2),
            StoreImage: tFData[0].storeImage,
            Unit1:
              tFData[0].priceTo === 0 && tFData[0].priceFrom === 0
                ? "N/A"
                : tFData[0].priceTo === tFData[0].priceFrom
                  ? storeAddress.countryCurrency + tFData[0].priceTo
                  : storeAddress.countryCurrency +
                  tFData[0].priceFrom +
                  " - " +
                  tFData[0].priceTo,
            Unit2:
              tFData[1].priceTo === 0 && tFData[1].priceFrom === 0
                ? "N/A"
                : tFData[1].priceTo === tFData[1].priceFrom
                  ? storeAddress.countryCurrency + tFData[1].priceTo
                  : storeAddress.countryCurrency +
                  tFData[1].priceFrom +
                  " - " +
                  tFData[1].priceTo,
            Unit3:
              tFData[2].priceTo === 0 && tFData[2].priceFrom === 0
                ? "N/A"
                : tFData[2].priceTo === tFData[2].priceFrom
                  ? storeAddress.countryCurrency + tFData[2].priceTo
                  : storeAddress.countryCurrency +
                  tFData[2].priceFrom +
                  " - " +
                  tFData[2].priceTo,
            Unit4:
              tFData[3].priceTo === 0 && tFData[3].priceFrom === 0
                ? "N/A"
                : tFData[3].priceTo === tFData[3].priceFrom
                  ? storeAddress.countryCurrency + tFData[3].priceTo
                  : storeAddress.countryCurrency +
                  tFData[3].priceFrom +
                  " - " +
                  tFData[3].priceTo,
            Unit5:
              tFData[4].priceTo === 0 && tFData[4].priceFrom === 0
                ? "N/A"
                : tFData[4].priceTo === tFData[4].priceFrom
                  ? storeAddress.countryCurrency + tFData[4].priceTo
                  : storeAddress.countryCurrency +
                  tFData[4].priceFrom +
                  " - " +
                  tFData[4].priceTo,
            Unit6:
              tFData[5].priceTo === 0 && tFData[5].priceFrom === 0
                ? "N/A"
                : tFData[5].priceTo === tFData[5].priceFrom
                  ? storeAddress.countryCurrency + tFData[5].priceTo
                  : storeAddress.countryCurrency +
                  tFData[5].priceFrom +
                  " - " +
                  tFData[5].priceTo,
            Unit7:
              tFData[6].priceTo === 0 && tFData[6].priceFrom === 0
                ? "N/A"
                : tFData[6].priceTo === tFData[6].priceFrom
                  ? storeAddress.countryCurrency + tFData[6].priceTo
                  : storeAddress.countryCurrency +
                  tFData[6].priceFrom +
                  " - " +
                  tFData[6].priceTo,
            Unit8:
              tFData[7].priceTo === 0 && tFData[7].priceFrom === 0
                ? "N/A"
                : tFData[7].priceTo === tFData[7].priceFrom
                  ? storeAddress.countryCurrency + tFData[7].priceTo
                  : storeAddress.countryCurrency +
                  tFData[7].priceFrom +
                  " - " +
                  tFData[7].priceTo,
            Unit9:
              tFData[8].priceTo === 0 && tFData[8].priceFrom === 0
                ? "N/A"
                : tFData[8].priceTo === tFData[8].priceFrom
                  ? storeAddress.countryCurrency + tFData[8].priceTo
                  : storeAddress.countryCurrency +
                  tFData[8].priceFrom +
                  " - " +
                  tFData[8].priceTo,
            Unit10:
              tFData[9].priceTo === 0 && tFData[9].priceFrom === 0
                ? "N/A"
                : tFData[9].priceTo === tFData[9].priceFrom
                  ? storeAddress.countryCurrency + tFData[9].priceTo
                  : storeAddress.countryCurrency +
                  tFData[9].priceFrom +
                  " - " +
                  tFData[9].priceTo,
            Unit11:
              tFData[10].priceTo === 0 && tFData[10].priceFrom === 0
                ? "N/A"
                : tFData[10].priceTo === tFData[10].priceFrom
                  ? storeAddress.countryCurrency + tFData[10].priceTo
                  : storeAddress.countryCurrency +
                  tFData[10].priceFrom +
                  " - " +
                  tFData[10].priceTo,
            Unit12:
              tFData[11].priceTo === 0 && tFData[11].priceFrom === 0
                ? "N/A"
                : tFData[11].priceTo === tFData[11].priceFrom
                  ? storeAddress.countryCurrency + tFData[11].priceTo
                  : storeAddress.countryCurrency +
                  tFData[11].priceFrom +
                  " - " +
                  tFData[11].priceTo,
            Unit13:
              tFData[12].priceTo === 0 && tFData[12].priceFrom === 0
                ? "N/A"
                : tFData[12].priceTo === tFData[12].priceFrom
                  ? storeAddress.countryCurrency + tFData[12].priceTo
                  : storeAddress.countryCurrency +
                  tFData[12].priceFrom +
                  " - " +
                  tFData[12].priceTo,
            Unit14:
              tFData[13].priceTo === 0 && tFData[13].priceFrom === 0
                ? "N/A"
                : tFData[13].priceTo === tFData[13].priceFrom
                  ? storeAddress.countryCurrency + tFData[13].priceTo
                  : storeAddress.countryCurrency +
                  tFData[13].priceFrom +
                  " - " +
                  tFData[13].priceTo,
            Unit15:
              tFData[14].priceTo === 0 && tFData[14].priceFrom === 0
                ? "N/A"
                : tFData[14].priceTo === tFData[14].priceFrom
                  ? storeAddress.countryCurrency + tFData[14].priceTo
                  : storeAddress.countryCurrency +
                  tFData[14].priceFrom +
                  " - " +
                  tFData[14].priceTo,
            IsSelected: tFData[0].isSelected,
            Latitude: tFData[0].latitude,
            Longitude: tFData[0].longitude,
          };
          FData.push(mData);
        }
      }
    }
    return FData;
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleClickOpen(e, name) {
    let dropDownValue = "";
    if (name === "Proximity") {
      //descending order--to Address origin of Market
      dropDownValue = "Proximity";
      this.setState((compitatorAll) => {
        this.state.compitatorAll.sort((a, b) => a.proximity - b.proximity);
      });
    } else if (name === "Store Name") {
      //ascending order
      dropDownValue = "Store Name";
      this.setState((compitatorAll) => {
        this.state.compitatorAll.sort((a, b) =>
          a.storeName !== b.storeName ? (a.storeName < b.storeName ? -1 : 1) : 0
        );
      });
    }
    this.setState({ dropDownValue });
  }

  //Snack Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let pta = this.state.isRemoveGraph.length;
    let usId = parseInt(this.props.match.params.userstoreid);
    let accessKey = localStorage.getItem("SecretKey");

    let orderBy =
      this.state.dropDownValue == "Sort by"
        ? 1
        : this.state.dropDownValue == "Store Name"
          ? 2
          : 3;
    let order = "asc";
    let searchId = this.state.storePlace ? this.state.storePlace : 1;
    let unitType = "1";
    let priceType =
      this.state.isRemoveGraph.length > 1 ? this.state.isRemoveGraph : 1;
    let showRemoveId = this.state.sIdPDF;
    let showRemoveType = this.state.typePDF;

    //let accessKey = '3f2b471776bc41cf863720bfc8cf9cb9';
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "AllUnitPrice" + "_" + timestamp + ".pdf";
    var stoName =
      this.state.storeAddress.storeName &&
        this.state.storeAddress.storeName !== null &&
        this.state.storeAddress.storeName !== "" &&
        this.state.storeAddress.storeName !== undefined
        ? this.state.storeAddress.storeName
        : "Your Store";
    var fileNamePdf =
      stoName + "_" + "AllUnitPrice" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 6;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        searchId +
        "/" +
        unitType +
        "/" +
        priceType +
        "/" +
        orderBy +
        "/" +
        order +
        "/" +
        showRemoveId +
        "/" +
        showRemoveType +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  render() {
    const open = Boolean(this.state.anchorEl);
    const {
      pricing,
      isLoading,
      storeAddress,
      isShowGraph,
      yGroupMax,
      isRemoveGraph,
      markets1,
      comparisons,
      compitatorAll,
      myStore,
      market,
      snackMSg,
      snackOpen,
      isNoGraphData
    } = this.state;
    const defaultProps = {
      helpTitle: "All Units Pricing",
      helpContent:
        "<p>On this page we provide a side-by-side analysis of the " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rates for the most common unit types.</p><h6>Broadest Range</h6><p>This is the unit type with the largest range of current " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rates (this includes both Regular/Walk-in and Online/Promotional rates)</p><h6>Narrowest Range</h6><p>This is the unit type with the lowest range of current " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rates (this includes both Regular/Walk-in and Online/Promotional rates)</p><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    const defaultProps1 = {
      helpTitle: "All Units Pricing Ranges",
      helpContent:
        "<p>This graph is showing a side-by-side analysis of the " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rates for the most common unit types.</p><p>The Market Average (in black) shows the range of current " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rates for this unit type. Hover over the range to view the specific rates.</p><p>If Your Store is advertising rates for this unit type, your rates will be shown here (in orange). Hover over the range to view the specific rates.</p><p>Compare the " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " rate range for any store/competitor in this Market*</p><p>*See 'All Stores' below to select stores/competitors to compare to on this graph</p><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    const defaultProps2 = {
      helpTitle: "All Stores",
      helpContent:
        "<ul><li>This shows all the stores in this Market and their current " +
        `${CONFIG.RegionId === 3 ? "weekly" : ""}` +
        " price ranges for each of the most common unit types.</li><li>Click on any to show/remove on the graph above for comparison.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    var storeName = "";
    {
      storeName = storeAddress ? storeAddress.storeName : "";
    }
    var count = 0;
    var initvalue = "";
    const rowsitem = [];
    var tData = [];
    var pData = [];
    var udt = 0;

    if (compitatorAll.length > 0) {
      tData = this.formData(compitatorAll);
      pData = compitatorAll.filter((a) => a.priceFrom > 0 || a.priceTo > 0);
      let unique = [...new Set(compitatorAll.map((item) => item.unitType))];
      udt = unique.length;
    }

    return (
      <div className="AllUnitPricing">
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <Breadcrumbs
          separator="›"
          aria-label="Breadcrumb"
          className="breadcrumb-float-left breadcrumb-top"
        >

          {localStorage.getItem("isMVP_User") === "false" ? (
            <Link to="/my-market/" color="inherit">
              My Markets
            </Link>
          ) :
            ""}


          <Link to={{ pathname: `/market-dashboard/${this.props.match.params.userstoreid}` }} color="inherit" >Go to Market Dashboard</Link>


          <Typography component="h6" className="link-tag">
            All Units Pricing
          </Typography>
        </Breadcrumbs>
        <Button
          disabled={
            !isLoading && (tData.length > 0 || myStore.length > 0)
              ? false
              : true
          }
          className={
            tData.length > 0 || myStore.length > 0
              ? "apply-btn download-pdf "
              : "download-pdf-disabled"
          }
          onClick={this.downLoadPDF.bind(this)}
        >
          <FontAwesomeIcon icon={faFilePdf} />
          PDF Download
        </Button>

        <div className="clearfix"> </div>
        {/* {storeAddress && pricing ? */}
        <Row className="overview-header mt-2">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row className="ht-100">
                <Col sm="6" className="padding-right-8">
                  <h2>
                    All Units Pricing <HelpTextPopup parm={defaultProps} />
                  </h2>
                  <CompetitorStore ref={this.child} />
                  {isLoading ? (
                    ""
                  ) : storeAddress.compsCount === 0 ||
                    storeAddress.compsCount === null ? (
                    <div className="align-stores-btm">
                      <p>Analyzing N/A</p>
                    </div>
                  ) : (
                    <div className="align-stores-btm">
                      <p>
                        Analyzing{" "}
                        <Link
                          onClick={() => this.getParams(storeAddress)}
                          to="#"
                        >
                          {storeAddress.compsCount
                            ? parseInt(storeAddress.compsCount) > 1
                              ? storeAddress.compsCount + " stores"
                              : storeAddress.compsCount + " store"
                            : "N/A"}
                        </Link>
                      </p>
                    </div>
                  )}
                </Col>
                {isLoading ? (
                  ""
                ) : (
                  <Col sm="6" className="pl-0">
                    <h3>
                      {storeAddress.storeName &&
                        storeAddress.storeName !== null &&
                        storeAddress.storeName !== "" &&
                        storeAddress.storeName !== undefined
                        ? storeAddress.storeName
                        : "Your Store"}
                    </h3>
                    <div
                      className={
                        storeAddress && storeAddress.storeZoneCoverage
                          ? storeAddress.storeZoneCoverage.length > 24
                            ? "pd-btm-35"
                            : "pd-btm-20"
                          : "pd-btm-20"
                      }
                    >
                      <p className="m-0">
                        {storeAddress.storeAddress1 &&
                          storeAddress.storeAddress1 !== "" &&
                          storeAddress.storeAddress1 !== null &&
                          storeAddress.storeAddress1 !== undefined
                          ? storeAddress.storeAddress1 + ", "
                          : ""}{" "}
                        {storeAddress.storeAddress2
                          ? storeAddress.storeAddress2 + ", "
                          : ""}{" "}
                        {storeAddress && storeAddress.storeCity
                          ? storeAddress.storeCity + ", "
                          : storeAddress.searchType === 1 &&
                            (storeAddress.storeCity === null ||
                              storeAddress.storeCity === "") &&
                            (storeAddress.storeZipCode === null ||
                              storeAddress.storeZipCode === "")
                            ? storeAddress.latitude +
                            ", " +
                            storeAddress.longitude +
                            ", "
                            : ""}
                        {storeAddress.storeState ? storeAddress.storeState : ""}{" "}
                        {storeAddress.storeZipCode}
                      </p>
                    </div>
                    <div className="align-stores-btm">
                      <p className="m-0 pt-1">
                        {"Coverage: " + storeAddress.storeZoneCoverage}
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Col>
          <Col md="7" className="padding-left-0 all-units-top-right">
            <Col md="12" className="border-box shadow-sm rounded">
              <Row className="height-full">
                <Col md="6" className="sep-box border-right">
                  <Row>
                    <Col xs="6" sm="6" md="6">
                      <h4>Broadest Range</h4>
                      {isLoading ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <div className="pd-btm-20">
                            <h5>
                              {pricing.broadestRangeUnit
                                ? pricing.broadestRangeUnit
                                : "N/A"}
                            </h5>
                          </div>
                          <div className="align-stores-btm">
                            <div className="btm-label">
                              {CONFIG.RegionId === 3
                                ? "Current Weekly Price Range"
                                : "Current Price Range"}
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Col>
                    <Col xs="6" sm="6" md="6">
                      <h2>
                        {isLoading ? (
                          ""
                        ) : pricing.broadestPriceTo === null ? (
                          ""
                        ) : pricing.broadestPriceTo ===
                          pricing.broadestPriceFrom ? (
                          <div
                            class="help-compo"
                            title="This market has only a single price option and there is no price range."
                          >
                            {" "}
                            {storeAddress.countryCurrency +
                              pricing.broadestPriceTo}
                          </div>
                        ) : (
                          storeAddress.countryCurrency +
                          pricing.broadestPriceFrom +
                          "-" +
                          pricing.broadestPriceTo
                        )}
                      </h2>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" className="sep-box">
                  <Row>
                    <Col xs="6" sm="6" md="6">
                      <h4>Narrowest Range</h4>
                      {isLoading ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <div className="pd-btm-20">
                            <h5>
                              {pricing.narrowestRangeUnit
                                ? pricing.narrowestRangeUnit
                                : "N/A"}
                            </h5>
                          </div>
                          <div className="align-stores-btm">
                            <div className="btm-label">
                              {CONFIG.RegionId === 3
                                ? "Current Weekly Price Range"
                                : "Current Price Range"}
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Col>
                    <Col xs="6" sm="6" md="6">
                      <h2>
                        {isLoading ? (
                          ""
                        ) : pricing.narrowestPriceTo === null ? (
                          ""
                        ) : pricing.narrowestPriceTo ===
                          pricing.narrowestPriceFrom ? (
                          <div
                            class="help-compo"
                            title="This market has only a single price option and there is no price range."
                          >
                            {" "}
                            {storeAddress.countryCurrency +
                              pricing.narrowestPriceTo}{" "}
                          </div>
                        ) : (
                          storeAddress.countryCurrency +
                          pricing.narrowestPriceFrom +
                          "-" +
                          pricing.narrowestPriceTo
                        )}{" "}
                      </h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        {/* : ''} */}
        <div className="dashboard-layout last-months">
          <div className="App">
            <h4 className="mb-1">
              All Units Pricing Ranges <HelpTextPopup parm={defaultProps1} />
            </h4>
            <div className="clear">
            </div>
            <Paper
              style={{ height: "", width: "100%", marginTop: 5 }}
              className="custom-paper"
            >
              {/* {markets1.length > 0 || myStore.length > 0 || pData.length > 0 ? ( */}
              {isNoGraphData ? ( <div className="no-data">No Data Available</div> ) :
              <Chart options={this.state.options} series={this.state.series} type="rangeBar" style={{ overflow: 'hidden' }} height={"350px"}/>}
           
            </Paper>
       
          </div>
          <Col>
            <Paper className="all-units-table">
              <div>
                <h4>
                  All Stores <HelpTextPopup parm={defaultProps2} />
                </h4>

                {markets1.length > 0 ||
                  myStore.length > 0 ||
                  tData.length > 0 ? (
                  <div className="all-pri-right">
                    <Dropdown
                      className="d-inline-block sort-by-option"
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                    >
                      <DropdownToggle caret>
                        {this.state.dropDownValue}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => this.handleClickOpen(e, "Store Name")}
                        >
                          Store Name
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => this.handleClickOpen(e, "Proximity")}
                        >
                          Proximity
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )}
                <div className="clear"></div>
              </div>

              <div className="overflow-scroll-table allUnitPrice-table">
                <Paper>
                  {markets1.length > 0 ||
                    myStore.length > 0 ||
                    tData.length > 0 ? (
                    <Table
                      className={
                        "table custom-table table-bordered inventory-table"
                      }
                      size="small"
                    >
                      <TableHead>
                        <TableRow className="unit-th-head">
                          <TableCell className="m-0 first-col getthwidth"></TableCell>
                          {market.length > 0
                            ? market.map((data) => {
                              return (
                                <TableCell className="m-0 width-col unit-type-heading">
                                  {data.unitType}
                                </TableCell>
                              );
                            })
                            : ""}
                          <TableCell className="m-0 more-option width-col">
                            More Option
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StoreStreetView ref={this.storeImageStreetView} />
                        <TableRow className="table-body font-weight-bol custom-td-width">
                          <TableCell className="m-0 mar-range">
                            {"Market Range "}
                          </TableCell>
                          {market.length > 0
                            ? market.map((data) => {
                              return (
                                <TableCell className="m-0 mar-range mar-range-bold">
                                  {data.priceTo === 0 && data.priceFrom === 0 ? "N/A" : data.priceTo === data.priceFrom
                                    ? storeAddress.countryCurrency +
                                    data.priceFrom
                                    : storeAddress.countryCurrency +
                                    data.priceFrom +
                                    "-" +
                                    data.priceTo}
                                </TableCell>
                              );
                            })
                            : ""}
                          <TableCell></TableCell>
                        </TableRow>
                        {myStore.length > 0 ? (
                          <TableRow className="table-body font-weight-bol custom-td-width">
                            <TableCell className="m-0 mar-range">
                              {"Your Store "}
                            </TableCell>
                            {myStore.length > 0
                              ? myStore.map((data) => {
                                return (
                                  <TableCell className="m-0 mar-range-bold">
                                    {data.priceTo === 0 &&
                                      data.priceFrom === 0
                                      ? "N/A"
                                      : data.priceTo === data.priceFrom
                                        ? storeAddress.countryCurrency +
                                        data.priceFrom
                                        : storeAddress.countryCurrency +
                                        data.priceFrom +
                                        "-" +
                                        data.priceTo}
                                  </TableCell>
                                );
                              })
                              : ""}
                            <div>
                              {myStore.length > 0 ? (
                                <TableCell className="m-0">
                                  {myStore[0].storeID ===
                                    isShowGraph.find(
                                      (e) => e === myStore[0].storeID
                                    ) ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(myStore[0].storeID, 0, 0)
                                      }
                                      className="red-clr hides-graph font-weight-normal"
                                    >
                                      <img
                                        src={shows}
                                        className="remove-graph"
                                        alt="Show Graph"
                                      />
                                      Show on graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {myStore[0].storeID ===
                                    isRemoveGraph.find(
                                      (e) => e === myStore[0].storeID
                                    ) ? (
                                    <a
                                      onClick={() =>
                                        this.showGraph(myStore[0].storeID, 1, 1)
                                      }
                                      className="blue-clr shows-graph font-weight-normal"
                                    >
                                      <img
                                        src={hiddens}
                                        className="show-graph"
                                        alt="Remove Graph"
                                      />
                                      Remove from graph
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {tData.length > 0
                          ? tData.map((data) => {
                            switch (udt) {
                              case 12: {
                                return (
                                  <TableRow className="table-body all-unit-customize-table">
                                    <TableCell className="m-0 no-need-style">
                                      <div className="row">
                                        {data.StoreImage != "" ? (
                                          <div className="col-lg-3">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                this.getStoreStreeView(
                                                  data.Latitude,
                                                  data.Longitude,
                                                  data.StoreName
                                                )
                                              }
                                              title="click here to view street view"
                                            >
                                              <img
                                                className="pointer-cur Unit-Store-Img cus-h-w-set"
                                                src={data.StoreImage}
                                                alt="No image"
                                                onError={this.addDefaultSrc}
                                              />
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-lg-9">
                                          <div className="store-name-bd">
                                            {data.StoreName}
                                          </div>
                                          <div className="store-miles">
                                            {data.Proximity + " "}
                                            {CONFIG.RegionId == 2 ||
                                              CONFIG.RegionId == 4
                                              ? "kilometre"
                                              : "mile"}
                                            {data.Proximity <= 1 ? "" : "s"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clear"></div>
                                      <div className="col-xs-12 store-address-bd">
                                        {data.Address}
                                      </div>
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit1}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit2}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit3}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit4}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit5}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit6}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit7}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit8}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit9}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit10}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit11}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit12}
                                    </TableCell>
                                    <TableCell className="">
                                      {data.StoreID ===
                                        isShowGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 0)
                                          }
                                          className="red-clr hides-graph"
                                        >
                                          <img
                                            src={shows}
                                            className="remove-graph"
                                            alt="Show Graph"
                                          />
                                          Show on Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {data.StoreID ===
                                        isRemoveGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 1)
                                          }
                                          className="blue-clr shows-graph"
                                        >
                                          <img
                                            src={hiddens}
                                            className="show-graph"
                                            alt="Remove Graph"
                                          />
                                          Remove From Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                                break;
                              }
                              case 10: {
                                return (
                                  <TableRow className="table-body all-unit-customize-table">
                                    <TableCell className="m-0 no-need-style">
                                      <div className="row">
                                        {data.StoreImage != "" ? (
                                          <div className="col-lg-3">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                this.getStoreStreeView(
                                                  data.Latitude,
                                                  data.Longitude,
                                                  data.StoreName
                                                )
                                              }
                                              title="click here to view street view"
                                            >
                                              <img
                                                className="pointer-cur Unit-Store-Img cus-h-w-set"
                                                src={data.StoreImage}
                                                alt="No image"
                                                onError={this.addDefaultSrc}
                                              />
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-lg-9">
                                          <div className="store-name-bd">
                                            {data.StoreName}
                                          </div>
                                          <div className="store-miles">
                                            {data.Proximity + " "}
                                            {CONFIG.RegionId == 2 ||
                                              CONFIG.RegionId == 4
                                              ? "kilometre"
                                              : "mile"}
                                            {data.Proximity <= 1 ? "" : "s"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clear"></div>
                                      <div className="col-xs-12 store-address-bd">
                                        {data.Address}
                                      </div>
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit1}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit2}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit3}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit4}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit5}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit6}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit7}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit8}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit9}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit10}
                                    </TableCell>
                                    {/* <TableCell className="m-0">{data.Unit11}</TableCell>
                                  <TableCell className="m-0">{data.Unit12}</TableCell>
                                  <TableCell className="m-0">{data.Unit13}</TableCell> */}
                                    <TableCell className="">
                                      {data.StoreID ===
                                        isShowGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 0)
                                          }
                                          className="red-clr hides-graph"
                                        >
                                          <img
                                            src={shows}
                                            className="remove-graph"
                                            alt="Show Graph"
                                          />
                                          Show on Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {data.StoreID ===
                                        isRemoveGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 1)
                                          }
                                          className="blue-clr shows-graph"
                                        >
                                          <img
                                            src={hiddens}
                                            className="show-graph"
                                            alt="Remove Graph"
                                          />
                                          Remove From Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                                break;
                              }
                              case 14: {
                                return (
                                  <TableRow className="table-body all-unit-customize-table">
                                    <TableCell className="m-0 no-need-style">
                                      <div className="row">
                                        {data.StoreImage != "" ? (
                                          <div className="col-lg-3">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                this.getStoreStreeView(
                                                  data.Latitude,
                                                  data.Longitude,
                                                  data.StoreName
                                                )
                                              }
                                              title="click here to view street view"
                                            >
                                              <img
                                                className="pointer-cur Unit-Store-Img cus-h-w-set"
                                                src={data.StoreImage}
                                                alt="No image"
                                                onError={this.addDefaultSrc}
                                              />
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-lg-9">
                                          <div className="store-name-bd">
                                            {data.StoreName}
                                          </div>
                                          <div className="store-miles">
                                            {data.Proximity + " "}
                                            {CONFIG.RegionId == 2 ||
                                              CONFIG.RegionId == 4
                                              ? "kilometre"
                                              : "mile"}
                                            {data.Proximity <= 1 ? "" : "s"}{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clear"></div>
                                      <div className="col-xs-12 store-address-bd">
                                        {data.Address}
                                      </div>
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit1}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit2}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit3}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit4}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit5}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit6}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit7}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit8}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit9}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit10}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit11}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit12}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit13}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit14}
                                    </TableCell>
                                    <TableCell className="">
                                      {data.StoreID ===
                                        isShowGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 0)
                                          }
                                          className="red-clr hides-graph"
                                        >
                                          <img
                                            src={shows}
                                            className="remove-graph"
                                            alt="Show Graph"
                                          />
                                          Show on Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {data.StoreID ===
                                        isRemoveGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 1)
                                          }
                                          className="blue-clr shows-graph"
                                        >
                                          <img
                                            src={hiddens}
                                            className="show-graph"
                                            alt="Remove Graph"
                                          />
                                          Remove From Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                                break;
                              }
                              default: {
                                return (
                                  <TableRow className="table-body all-unit-customize-table">
                                    <TableCell className="m-0 store-name-bd no-need-style">
                                      <div className="row">
                                        {data.StoreImage != "" ? (
                                          <div className="col-lg-3">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                this.getStoreStreeView(
                                                  data.Latitude,
                                                  data.Longitude,
                                                  data.StoreName
                                                )
                                              }
                                              title="click here to view street view test"
                                            >
                                              <img
                                                className="pointer-cur Unit-Store-Img cus-h-w-set"
                                                src={data.StoreImage}
                                                onError={this.addDefaultSrc}
                                              />
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-lg-9">
                                          <div className="store-name-bd">
                                            {data.StoreName}
                                          </div>
                                          <div className="store-miles">
                                            {data.Proximity + " "}
                                            {CONFIG.RegionId == 2 ||
                                              CONFIG.RegionId == 4
                                              ? "kilometre"
                                              : "mile"}
                                            {data.Proximity <= 1 ? "" : "s"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clear"></div>
                                      <div className="col-xs-12 store-address-bd">
                                        {data.Address}
                                      </div>
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit1}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit2}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit3}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit4}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit5}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit6}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit7}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit8}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit9}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit10}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit11}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit12}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit13}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit14}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.Unit15}
                                    </TableCell>
                                    <TableCell className="m-0">
                                      {data.StoreID ===
                                        isShowGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 0)
                                          }
                                          className="red-clr hides-graph"
                                        >
                                          <img
                                            src={shows}
                                            className="remove-graph"
                                            alt="Show Graph"
                                          />
                                          Show on Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {data.StoreID ===
                                        isRemoveGraph.find(
                                          (e) => e === data.StoreID
                                        ) ? (
                                        <a
                                          onClick={() =>
                                            this.showGraph(data.StoreID, 1)
                                          }
                                          className="blue-clr shows-graph"
                                        >
                                          <img
                                            src={hiddens}
                                            className="show-graph"
                                            alt="Remove Graph"
                                          />
                                          Remove From Graph
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                                break;
                              }
                            }
                          })
                          : ""}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="no-data all-unit-no-data">
                      {isLoading === false ? "No Data Available" : ""}
                    </div>
                  )}
                </Paper>
              </div>
            </Paper>
          </Col>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackMSg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}
