import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {HelpTextPopup} from '../../HelpTextPopup/index';
import axios from 'axios';
import { CONFIG } from '../../../utils/config';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Col,Row } from 'reactstrap';
import moment from 'moment';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alarm from '../../../Assets/Images/unit-type-images/Alarm.png';
import ClimateControlled from '../../../Assets/Images/unit-type-images/ClimateControlled.png';
import StorageUnit from '../../../Assets/Images/unit-type-images/StorageUnit.png';
import apartment from '../../../Assets/Images/unit-type-images/apartment.png';
import artstudio from '../../../Assets/Images/unit-type-images/artstudio.png';
import box from '../../../Assets/Images/unit-type-images/box.png';
import celltower from '../../../Assets/Images/unit-type-images/celltower.png';
import container from '../../../Assets/Images/unit-type-images/container.png';
import filestorage from '../../../Assets/Images/unit-type-images/filestorage.png';
import office from '../../../Assets/Images/unit-type-images/office.png';
import mailbox from '../../../Assets/Images/unit-type-images/mailbox.png';
import parking from '../../../Assets/Images/unit-type-images/parking.png';
import unit from '../../../Assets/Images/unit-type-images/unit.png';
import warehouse from '../../../Assets/Images/unit-type-images/warehouse.png';
import winestorage from '../../../Assets/Images/unit-type-images/winestorage.png';
import workshop from '../../../Assets/Images/unit-type-images/workshop.png';
import DoorType from '../../../Assets/Images/unit-type-images/DoorType.png';
import DriveUp from '../../../Assets/Images/unit-type-images/DriveUp.png';
import Elevator from '../../../Assets/Images/unit-type-images/Elevator.png';
import HumidityControlled from '../../../Assets/Images/unit-type-images/HumidityControlled.png';
import OutdoorAccess from '../../../Assets/Images/unit-type-images/OutdoorAccess.png';
import power from '../../../Assets/Images/unit-type-images/power.png';
import locker from '../../../Assets/Images/unit-type-images/locker.png';
import { MarketProfile } from '../../MarketProfile';
import { Link } from 'react-router-dom';
import { faMapPin, faStar } from '@fortawesome/free-solid-svg-icons';
import './DetailedStoresViewPdf.css';
import Logo from '../../../Assets/Images/logo.jpg';
const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class DetailedStoresViewPdf extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };


  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const DetailedStoresViewWrapped = withStyles(actionsStyles, { withTheme: true })(
  DetailedStoresViewPdf,
);


let headRows =[];
if(CONFIG.RegionId===3)
{
 headRows = [
  { id: 'storeName', numeric: false, disablePadding: false, label: 'Store Name / Address' },
  { id: 'proximity', numeric: true, disablePadding: false, label: 'Proximity' },
  { id: 'size', numeric: true, disablePadding: false, label: 'Unit Size' },
  { id: 'floor', numeric: false, disablePadding: false, label: 'Floor' },
  { id: 'features', numeric: true, disablePadding: false, label: 'Features' },
  { id: 'onlinePrice', numeric: true, disablePadding: false, label: 'Advertised Online Rate' },
  //  { id: 'onlinePriceVat', numeric: true, disablePadding: false, label: 'VAT' },
  { id: 'dtOnlinePriceChanged', numeric: true, disablePadding: false, label: 'Last Changed' },
  { id: 'regPrice', numeric: true, disablePadding: false, label: 'Advertised Walk-in Rate' },
  // { id: 'regularPriceVat', numeric: true, disablePadding: false, label: 'VAT' },
  { id: 'dtPriceChanged', numeric: true, disablePadding: false, label: 'Last Changed' },
  { id: 'promo', numeric: false, disablePadding: false, label: 'Advertised Promotion' },
  { id: 'dtPromoChanged', numeric: true, disablePadding: false, label: 'Last Changed' }
];
}
else{
  headRows = [
    { id: 'storeName', numeric: false, disablePadding: false, label: 'Store Name / Address' },
    { id: 'proximity', numeric: true, disablePadding: false, label: 'Proximity' },
    { id: 'size', numeric: true, disablePadding: false, label: 'Unit Size' },
    { id: 'floor', numeric: false, disablePadding: false, label: 'Floor' },
    { id: 'features', numeric: true, disablePadding: false, label: 'Features' },
    { id: 'onlinePrice', numeric: true, disablePadding: false, label: 'Advertised Online Rate' },
    { id: 'dtOnlinePriceChanged', numeric: true, disablePadding: false, label: 'Last Changed' },
    { id: 'regPrice', numeric: true, disablePadding: false, label: 'Advertised Walk-in Rate' },
    { id: 'dtPriceChanged', numeric: true, disablePadding: false, label: 'Last Changed' },
    { id: 'promo', numeric: false, disablePadding: false, label: 'Advertised Promotion' },
    { id: 'dtPromoChanged', numeric: true, disablePadding: false, label: 'Last Changed' }
  ];
}
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, order1, orderBy1, order2, orderBy2, numSelected, rowCount, onRequestSort, onProximitysort, onStoreSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const creatProximitysort = property => event => {

    onProximitysort(event, property);
  };
  const creatstoreSort = property => event => {
    onStoreSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell> */}
        {headRows.map(row => (
          <TableCell
            key={row.id}

            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : orderBy1 === row.id ? order1 : orderBy2 === row.id ? order2 : false}
          >
            <TableSortLabel
              active={orderBy === row.id || orderBy1 === row.id || orderBy2 === row.id}
              direction={order || order1 || order2}
              disabled={row.id === 'features' ? true : false}
              onClick={row.id === 'storeName' ? creatstoreSort(row.id) : row.id === 'proximity' ? creatProximitysort(row.id) : createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onProximitysort: PropTypes.func.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  order1: PropTypes.string.isRequired,
  orderBy1: PropTypes.string.isRequired,
  order2: PropTypes.string.isRequired,
  orderBy2: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,

};
class CustomDetailedStoresViewPdf extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pageCount: 0,
      rowsPerPage: 10,
      detailStoreData: [],
      detailStoreDatafilter: [],
      order: this.props.order,
      orderBy: this.props.orderBy,
      order1: this.props.order1,
      order2: this.props.order2,
      orderBy1: this.props.orderBy1,
      orderBy2: 'asc',
      orderBy2: this.props.orderBy2,
      isLoading: false,
      isPopUp: false,
     accessKey:'',
     locationInfoData: [],
     analysisSummary: [],

    };
    this.stableSort = this.stableSort.bind(this);
    this.getSorting = this.getSorting.bind(this);
    this.desc = this.desc.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleProximitysort = this.handleProximitysort.bind(this);
    this.handleStoreSort = this.handleStoreSort.bind(this);
    this.getParams = this.getParams.bind(this);
    this.child = React.createRef();
  }
  getParams(name, sid) {
    this.state.isPopUp=true;
    if (name && sid) {
      this.child.current.toggleModal(name, sid);
    }
  }
  async UNSAFE_componentWillMount() {
    
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }
   
    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.detailedStoreView();
      this.loadDashBoard();
      this.loadSummary();
    } else {
      alert(response.status.Message);
    }
  }
  loadSummary() {
    let token =   this.state.accessKey;
    const data = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID":CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/currentpricinganalysissummary', data)
      .then(response => {
       
        // this.state.isLoading=true;
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });

      
  }

  loadDashBoard() {
    let token =   this.state.accessKey;
    const data = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID":CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'unit/dashboardSearchlocation', data)
      .then(response => {
        // this.state.isLoading=true;
        const locationInfoData = response.data.data.unitDashboard;
       
        this.setState({
          locationInfoData: locationInfoData
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isPopUp === false) {
      if (nextProps.searchId != this.props.searchId) {
        this.setState({
          isLoading: true,
          searchId: nextProps.searchId,
          storeId: nextProps.storeId
        },
          () => {
            this.detailedStoreView()
          });
      }
    }
    else{
      this.setState({
      isPopUp: false,
    });
     
    }


  }
  desc(a, b, orderBy) {

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {

    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  handleRequestSort(event, property) {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ isLoading: true });
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {        
        let detailStoreData = res.data.data;
        var sortData = detailStoreData.sort((a, b) => (b.storeId - a.storeId));

        let uniquestore = [...new Set(sortData.map(item => item.storeId))];
        var FData = [];
        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              rowcount: 1,
              competitorName: tempData[s].competitorName,
              address: tempData[s].address,
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: tempData[s].proximity,
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: tempData[s].storeName,
              vehicle: tempData[s].vehicle,
              regularPriceVat:tempData[s].regularPriceVat,
              onlinePriceVat:tempData[s].onlinePriceVat,
              isMonthly:tempData[s].isMonthly,
            }

            FData.push(inlineData);
          }
        }
        this.setState({
          detailStoreData: FData, isLoading: false,
          order: isDesc ? 'asc' : 'desc', orderBy: property, order1: '', orderBy1: '', order2: '', orderBy2: ''
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });


  }
  handleProximitysort(event, property) {

    this.setState({ isLoading: true });
    const { order1, orderBy1 } = this.state;
    const isDesc = orderBy1 === property && order1 === 'desc';
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {
        //let detailStoreData = [];
        let tempdetailStoreData = res.data.data;
        // var sortData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        if (order1 === 'desc') {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => (a.proximity - b.proximity));

        }
        else {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        }
        // let term = order;
        //     let option = orderBy;
        // var sortByStoreData = tempdetailStoreData.sort((a, b) => {
        //   return (option == 'asc' ? (a[term] >= b[term] ? -1 : 1) :
        //       (a[term] <= b[term] ? -1 : 1))
        //   });

        let uniquestore = [...new Set(sortByStoreData.map(item => item.storeId))];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : '',
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : 'empty',
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : '',
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat:tempData[s].regularPriceVat,
              onlinePriceVat:tempData[s].onlinePriceVat,
              isMonthly:tempData[s].isMonthly,
              
            }

            detailStoreDatafilter.push(inlineData);
          }
        }
        this.setState({
          detailStoreDatafilter, detailStoreData: [], tempdetailStoreData, isLoading: false,
          order1: isDesc ? 'asc' : 'desc', orderBy1: property, order: '', orderBy: '', order2: '', orderBy2: '',
          detailStoreDatafilterLength: uniquestore.length
        })

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }
  handleStoreSort(event, property) {
    this.setState({ isLoading: true });
    const { order2, orderBy2 } = this.state;
    const isDesc = orderBy2 === property && order2 === 'desc';
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {
        let tempdetailStoreData = res.data.data;
        if (order2 === 'desc') {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => a.storeName.localeCompare(b.storeName));
        }
        else {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => b.storeName.localeCompare(a.storeName));
        }
        let uniquestore = [...new Set(sortByStoreData.map(item => item.storeId))];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : '',
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : 'empty',
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : '',
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat:tempData[s].regularPriceVat,
              onlinePriceVat:tempData[s].onlinePriceVat,
              isMonthly:tempData[s].isMonthly,

            }

            detailStoreDatafilter.push(inlineData);
          }
        }
        this.setState({
          detailStoreDatafilter, detailStoreData: [], tempdetailStoreData, isLoading: false,
          order2: isDesc ? 'asc' : 'desc', orderBy2: property,
          order: '', orderBy: '', order1: '', orderBy1: '',
          detailStoreDatafilterLength: uniquestore.length
        })

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });


  }

  detailedStoreView() {
    if(this.state.orderBy1 === "proximity"){
      let token =  this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
   
    this.setState({ isLoading: true });
   
    const { order1, orderBy1 } = this.state;
    const property = 'proximity';
    const isDesc = orderBy1 === property && order1 === 'asc';
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {
        //let detailStoreData = [];
        let tempdetailStoreData = res.data.data;
        // var sortData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        if (order1 === 'asc' ) {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => (a.proximity - b.proximity));

        }
        else {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => (b.proximity - a.proximity));
        }
          
        
        // let term = order;
        //     let option = orderBy;
        // var sortByStoreData = tempdetailStoreData.sort((a, b) => {
        //   return (option == 'asc' ? (a[term] >= b[term] ? -1 : 1) :
        //       (a[term] <= b[term] ? -1 : 1))
        //   });

        let uniquestore = [...new Set(sortByStoreData.map(item => item.storeId))];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : '',
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : 'empty',
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : '',
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
             regularPriceVat:tempData[s].regularPriceVat,
             onlinePriceVat:tempData[s].onlinePriceVat,
             isMonthly:tempData[s].isMonthly,
             promoCount: tempData[s].promoCount,
             annexure: tempData[s].annexure
            }
            detailStoreDatafilter.push(inlineData);
          }
        }
        
        let amidx = 1;
        for (var i in detailStoreDatafilter) {
          if (detailStoreDatafilter[i].promoCount > 50 ) {
            detailStoreDatafilter[i].annexure = amidx;
            amidx=amidx+1;
          }
        }

        this.setState({
          detailStoreDatafilter, detailStoreData: [], tempdetailStoreData, isLoading: false,
          order1: isDesc ? 'asc' : 'desc', orderBy1: property, order: '', orderBy: '', order2: '', orderBy2: '',
          detailStoreDatafilterLength: uniquestore.length
        }, ()=> {this.annexureSplit();})

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    }else if(this.state.orderBy2 === "storeName"){
      let token =  this.state.accessKey;
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }

    this.setState({ isLoading: true });
    const { order2, orderBy2 } = this.state;
    const property = 'storeName';
    const isDesc = orderBy2 === property && order2 === 'desc';
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {
        let tempdetailStoreData = res.data.data;
        if (order2 === 'asc') {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => a.storeName.localeCompare(b.storeName));
        }
        else {
          var sortByStoreData = tempdetailStoreData.sort((a, b) => b.storeName.localeCompare(a.storeName));
        }
        let uniquestore = [...new Set(sortByStoreData.map(item => item.storeId))];
        var detailStoreDatafilter = [];
        var detailStoreDatafilterLength = 0;

        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = sortByStoreData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              competitorName: tempData[s].competitorName,
              address: s === 0 ? tempData[s].address : '',
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: s === 0 ? tempData[s].proximity : 'empty',
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: s === 0 ? tempData[s].storeName : '',
              vehicle: tempData[s].vehicle,
              rowcount: s === 0 ? tempData.length : 0,
              regularPriceVat:tempData[s].regularPriceVat,
              onlinePriceVat:tempData[s].onlinePriceVat,
              isMonthly:tempData[s].isMonthly,
              promoCount: tempData[s].promoCount,
              annexure: tempData[s].annexure

            }

            detailStoreDatafilter.push(inlineData);
          }
        }
        
        let amidx = 1;
        for (var i in detailStoreDatafilter) {
          if (detailStoreDatafilter[i].promoCount > 50 ) {
            detailStoreDatafilter[i].annexure = amidx;
            amidx=amidx+1;
          }
        }
        
        this.setState({
          detailStoreDatafilter, detailStoreData: [], tempdetailStoreData, isLoading: false,
          order2: isDesc ? 'asc' : 'desc', orderBy2: property,
          order: '', orderBy: '', order1: '', orderBy1: '',
          detailStoreDatafilterLength: uniquestore.length
        }, ()=> {this.annexureSplit(); })

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    }else{
      let token =  this.state.accessKey;
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }

    this.setState({ isLoading: true });
    const { order, orderBy } = this.state;
    const property = this.state.orderBy;
    const isDesc = orderBy === property && order === 'asc';
    
    const detaildata = {
      "UserStoreID": this.props.userstoreid,
      "SearchID": this.props.searchId,
      "RegionID" :CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/unitlevelgridview', detaildata)
      .then(res => {        
        let detailStoreData = res.data.data;
       // var sortData = detailStoreData.sort((a, b) => (b.storeId - a.storeId));
      
        let uniquestore = [...new Set(detailStoreData.map(item => item.storeId))];
        var FData = [];
        for (var t = 0; t < uniquestore.length; t++) {
          var tempData = detailStoreData.filter(a => a.storeId == uniquestore[t]);
          for (var s = 0; s < tempData.length; s++) {
            var inlineData = {
              rowcount: 1,
              competitorName: tempData[s].competitorName,
              address: tempData[s].address,
              alarm: tempData[s].alarm,
              climateControlled: tempData[s].climateControlled,
              covered: tempData[s].covered,
              doorType: tempData[s].doorType,
              driveUp: tempData[s].driveUp,
              dtOnlinePriceChanged: tempData[s].dtOnlinePriceChanged,
              dtPriceChanged: tempData[s].dtPriceChanged,
              dtPromoChanged: tempData[s].dtPromoChanged,
              elevator: tempData[s].elevator,
              floor: tempData[s].floor,
              humidityControlled: tempData[s].humidityControlled,
              isUserStore: tempData[s].isUserStore,
              lastCheckedDate: tempData[s].lastCheckedDate,
              latitude: tempData[s].latitude,
              longitude: tempData[s].longitude,
              onlinePrice: tempData[s].onlinePrice,
              onlinePriceLastChanged: tempData[s].onlinePriceLastChanged,
              outdoorAccess: tempData[s].outdoorAccess,
              power: tempData[s].power,
              promo: tempData[s].promo,
              promoLastChanged: tempData[s].promoLastChanged,
              proximity: tempData[s].proximity,
              rank: tempData[s].rank,
              regPrice: tempData[s].regPrice,
              regularPrice: tempData[s].regularPrice,
              regularPriceLastChanged: tempData[s].regularPriceLastChanged,
              size: tempData[s].size,
              spaceId: tempData[s].spaceId,
              spaceType: tempData[s].spaceType,
              spacetype: tempData[s].spacetype,
              state: tempData[s].state,
              storeId: tempData[s].storeId,
              storeName: tempData[s].storeName,
              vehicle: tempData[s].vehicle,
              regularPriceVat:tempData[s].regularPriceVat,
              onlinePriceVat:tempData[s].onlinePriceVat,
              isMonthly:tempData[s].isMonthly,
              promoCount: tempData[s].promoCount,
              annexure: tempData[s].annexure
            }

            FData.push(inlineData);
          }
        }
        var sortData ='';
        let orderBy2 ='';
        if (orderBy == "onlinePrice") {
         if (order === 'asc') {
             var sortData = FData.sort((a, b) => (a.onlinePrice - b.onlinePrice));
         }
         else {
             var sortData = FData.sort((a, b) => (b.onlinePrice - a.onlinePrice));
         }
     } 
      else if(orderBy =="size"){
      
       if (order === 'asc') {
         
         var sortData = FData.sort((a, b) => a.size.localeCompare(b.size));
     }
     else {
         var sortData = FData.sort((a, b) =>  b.size.localeCompare(a.size));
     }
      }
      else if(orderBy =="floor"){
      
       if (order === 'asc') {
         
         var sortData = FData.sort((a, b) => a.floor.localeCompare(b.floor));
     }
     else {
         var sortData = FData.sort((a, b) =>  b.floor.localeCompare(a.floor));
     }
      }
      else if(orderBy =="dtOnlinePriceChanged"){
      
        if (order === 'asc') {
          
          var sortData = FData.sort((a, b) => a.dtOnlinePriceChanged.localeCompare(b.dtOnlinePriceChanged));
      }
      else {
          var sortData = FData.sort((a, b) =>  b.dtOnlinePriceChanged.localeCompare(a.dtOnlinePriceChanged));
      }
       }
       else if(orderBy =="regPrice"){
      
        if (order === 'asc') {
          var sortData = FData.sort((a, b) => (a.regPrice - b.regPrice));
      }
      else {
          var sortData = FData.sort((a, b) => (b.regPrice - a.regPrice));
      }
       }
       else if(orderBy =="dtPriceChanged"){
      
        if (order === 'asc') {
          
          var sortData = FData.sort((a, b) => a.dtPriceChanged.localeCompare(b.dtPriceChanged));
      }
      else {
          var sortData = FData.sort((a, b) =>  b.dtPriceChanged.localeCompare(a.dtPriceChanged));
      }
       }
       else if(orderBy =="promo"){
      
        if (order === 'asc') {
          
          var sortData = FData.sort((a, b) => a.promo.localeCompare(b.promo));
      }
      else {
          var sortData = FData.sort((a, b) =>  b.promo.localeCompare(a.promo));
      }
       }
       else if(orderBy =="dtPromoChanged"){
      
        if (order === 'asc') {
          
          var sortData = FData.sort((a, b) => a.dtPromoChanged.localeCompare(b.dtPromoChanged));
      }
      else {
          var sortData = FData.sort((a, b) =>  b.dtPromoChanged.localeCompare(a.dtPromoChanged));
      }
       }
       
       let amidx = 1;
       for (var i in sortData) {
         if (sortData[i].promoCount > 50 ) {
          sortData[i].annexure = amidx;
           amidx=amidx+1;
         }
       }
       this.setState({ detailStoreData: sortData, isLoading: false, order: isDesc ? 'asc' : 'desc', orderBy: property, order1: '', orderBy1: '', order2: '', orderBy2: ''}, () => {
        this.annexureSplit();
      });


      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
    }
  }
  annexureSplit(){
    // for annexure split
    let fixedSize = 1200;
    let finalResultSet = [];
    let tempresult = [];
    let annexSum = 0;
    let sliceCount = 0;
    let fullData = this.state.detailStoreData && this.state.detailStoreData.length > 0 ? this.state.detailStoreData : this.state.detailStoreDatafilter && this.state.detailStoreDatafilter.length > 0 ? this.state.detailStoreDatafilter : [];
    let annexureArrObj = fullData.filter((annex) => annex.annexure !== 0);
    annexureArrObj.sort(function (a, b) {
        var textA = a.annexure;
        var textB = b.annexure;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0 ;
    });
    // let annexureArrObj = this.state.needComparePromos.filter((annex) => annex.annexure !== 0);   
    for (let annex = 0; annex < annexureArrObj.length; annex++){		    
    let annexure = annexureArrObj[annex];			
    annexSum += annexure.promoCount;
        if(annexSum >= 2200){
            annex = annex -1;
            annexSum = 0;
    finalResultSet.push(tempresult);
    tempresult = [];
        }else{
            tempresult.push(annexure);
        }
    }
    if(tempresult.length>0)
      finalResultSet.push(tempresult);
      this.setState({ annexureFilters : finalResultSet});
      console.log(finalResultSet, 'check-annexure');
      // console.log(finalResultSet);
}


  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    const {analysisSummary, detailStoreData,locationInfoData, isLoading, detailStoreDatafilter, detailStoreDatafilterLength, rowsPerPage, page, pageCount, order, orderBy, order1, orderBy1, order2, orderBy2, annexureFilters } = this.state;
    //'<a href="#" className="download-xcel">HideCompetitor</a>'
    const defaultProps = {
      helpTitle: "Detailed View of Stores",
      helpContent: "<p>This is a spreadsheet style view of the stores advertising this unit currently. It shows the store, the proximity to your address, the unit size, the floor in which the unit is located (Ground or Upper floor) and any features advertised for this unit type by the store. Additionally, the rates (both Advertised Online Rate and Advertised Walk-in Rate), unit-specific promotions and the last time these were changed on the store's website.</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/DOjmEH0-SOI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
      }
      const GridTableValue = detailStoreData.length > 0 ? detailStoreData.length : detailStoreDatafilter.length > 0 ? detailStoreDatafilter.length : 0;
      const GridTableValueData = detailStoreData.length > 0 ? detailStoreData : detailStoreDatafilter.length > 0 ? detailStoreDatafilter : [];
      const tr = [...Array( Math.ceil(  GridTableValue / 21) )];
      const trproductRows = tr.map( (row, idx) =>  GridTableValueData.slice(idx * 21, idx * 21 + 21) );
      let cutCur = locationInfoData.countryCurrency ? locationInfoData.countryCurrency : 'N/A';
     let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if(clp){
      var splt_clp = clp.toString().split('.');
      let splt_val_clp = splt_clp[1] ? splt_clp[1].length === 1 ? '.'+splt_clp[1]+'0' : splt_clp[1].length === 2 ? '.'+splt_clp[1] : '' : '';
      clp = cutCur+ splt_clp[0]+splt_val_clp;
    }
    if(cap){
      var splt_cap = cap.toString().split('.');
      let splt_val_cap = splt_cap[1] ? splt_cap[1].length === 1 ? '.'+splt_cap[1]+"0" : splt_cap[1].length === 2 ? '.'+splt_cap[1] : '' : '';
      cap = cutCur+ splt_cap[0]+splt_val_cap;
    }
    if(chp){
      var splt_chp = chp.toString().split('.');
      let splt_val_chp = splt_chp[1] ? splt_chp[1].length === 1 ? '.'+splt_chp[1]+'0' : splt_chp[1].length === 2 ? '.'+splt_chp[1] : '' : '';
      chp = cutCur+ splt_chp[0]+splt_val_chp;
    }
    const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
    return (
      <div>
      <div className = "Detailed-Storeview Detailed-Storeview-pdf">
        <MarketProfile ref={this.child} />
        <div class="header"> <img src={Logo} /><div className="float-right-name"> 
        <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0,CONFIG.storeNameMaxLimit-3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div>
        <div className="float-right">
        <p className="m-0 address-font">
        {/* {locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", ": '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ': ''}{locationInfoData.storeState + ' ' + locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: "+ locationInfoData.storeZoneCoverage : 'N/A'} */}
        {locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", ": '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ", " : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ': ''}{locationInfoData.storeState ? locationInfoData.storeState+' ':''}{locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: "+ locationInfoData.storeZoneCoverage : 'N/A'}
        </p>
        </div> 
        </div>    
        <div className="footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:'+CONFIG.Mail_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>
        <div className="clear"></div>
        {/* <span className="setup-stores">You have setup {detailStoreData && detailStoreData.length > 0 ? detailStoreData.length : '0'}.</span> */}
        {trproductRows ? trproductRows.map((rows, i) => ( 
          <div className="detailed-view-table">
            { i === 0 ? <div className="mb-3"><h5 style={{marginBottom: '15px', marginLeft: '15px', marginRight: '15px'}}>{'(' + locationInfoData.searchName + ')'} Detailed view of Stores </h5>
            <Row className="height-full mb-3 mt-2">
                <div className="sep-box">
                  <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Lowest Weekly Price" : "Current Lowest Price"}</h4>
                  <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.lowestPriceStoreName : isLoading===false ? 'N/A':''}</p></div>
                  <div className="align-stores-btm"><h5>{clp ? clp : isLoading===false ? 'N/A':''} </h5></div>
                </div>
                <div className="sep-box current-avg-height">
                  <h4 className="text-left" style={!cap && isLoading === false ? {height: 'auto'} : {position: 'relative'}}>{CONFIG.RegionId === 3 ? "Current Average Weekly Price" : "Current Average Price"}</h4>                  
                  <div className="align-stores-btm"><h5>{cap ? cap : isLoading===false ? 'N/A':''} </h5></div>
                </div>
                <div className="sep-box right-none">
                  <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Highest Weekly Price" : "Current Highest Price" }</h4>
                  <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.highestPriceStoreName : isLoading===false ? 'N/A':''}</p></div>
                  <div className="align-stores-btm"><h5>{chp ? chp : isLoading===false ? 'N/A':''} </h5></div>
                </div>
                <div className="clear"></div>
              </Row>
           
            <p style={{marginLeft: '15px', marginRight: '15px'}}>This is a spreadsheet style view of the stores advertising this unit currently. It shows the store, the proximity to your address, the unit size, the floor in which the unit is located (Ground or Upper floor) and any features advertised for this unit type by the store. Additionally, the rates (both Advertised Online Rate and Advertised Walk-in Rate), unit-specific promotions and the last time these were changed on the store's website.
            </p><p style={{marginLeft: '15px', marginRight: '15px'}}>*Your store prices are included in the calculations.</p></div>: ''}
            {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
            {CONFIG.RegionId === 3 && i === 0 ?
              <p className = "sqft-disclaimer mb-1 text-right">* This rate is calculated from an advertised monthly rate</p>
              :''}
            <Paper style={{marginLeft: '15px', marginRight: '15px'}}>
              <Table className="table table-bordered custom-table" id={i === 0 ? '' : 'container-box-detail'}>
                <EnhancedTableHead
                  // numSelected={selected.length}
                  order={order || order1 || order2}
                  orderBy={orderBy || orderBy1 || orderBy2}
                  // onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  onProximitysort={this.handleProximitysort}
                  onStoreSort={this.handleStoreSort}
                  rowCount={detailStoreData.length}
                />
                {/* <TableHead>
                                  <TableRow className="table-header">
                                      <TableCell>storeName</TableCell>
                                      
                                  </TableRow>
                              </TableHead> */}
                <TableBody>
                  {
                    detailStoreData && detailStoreData.length > 0 ?
                      this.stableSort(rows, this.getSorting(order, orderBy)).map((category, index) =>
                        <TableRow key={category.userStoreID} title={'Last Checked: ' + moment(category.lastCheckedDate).format('DD-MMM-YYYY')} className="table-body">
                          <TableCell>
                            
                            {/* <Link onClick={() => this.getParams(category.storeName, category.storeId)} className="storename">{category.storeName}</Link> */}
                            <span className="storename">{category.storeName}</span><br />
                            <span className="store-names-view">{category.address}</span>
                          </TableCell>
                          <TableCell >
                            {category.proximity.toFixed(2)} {(CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? ' '+'km':' '+'mi'}
                          </TableCell>
                          <TableCell>{category.size}
                            {category.spaceType.toLowerCase().replace(' ', '') === "storageunit" ? <img src={StorageUnit} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "apartment" ? <img src={apartment} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "artstudio" ? <img src={artstudio} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "box" ? <img src={box} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "celltower" ? <img src={celltower} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "container" ? <img src={container} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "filestorage" ? <img src={filestorage} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "office" ? <img src={office} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "mailbox" ? <img src={mailbox} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "parking" ? <img src={parking} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "unit" ? <img src={unit} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "warehouse" ? <img src={warehouse} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "winestorage" ? <img src={winestorage} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "workshop" ? <img src={workshop} class="space-type" title={category.spaceType} /> : ''}
                            {category.spaceType.toLowerCase().replace(' ', '') === "locker" ? <img src={locker} class="space-type" title={category.spaceType} /> : ''}
                          </TableCell>
                          <TableCell >{category.floor}</TableCell>
                          <TableCell className="feature-images">

                            {category.alarm === 1 ? <img src={Alarm} title="Alarm" /> : ''}
                            {category.climateControlled === 1 ? <img src={ClimateControlled} title="Climate" /> : ''}
                            {category.doorType > 0 ? <img src={DoorType} title={category.doorType === 1 ? 'Roll-Up Door' : category.doorType === 2 ? 'Swing Door' : category.doorType === 3 ? 'Double Door' : category.doorType === 4 ? 'Chain Link Door' : category.doorType === 5 ? 'Other Door' : ''} /> : ''}
                            {category.driveUp === 1 ? <img src={DriveUp} title="Drive Up" /> : ''}
                            {category.elevator === 1 ? <img src={Elevator} title="Elevator" /> : ''}
                            {category.humidityControlled === 1 ? <img src={HumidityControlled} title="Humidity" /> : ''}
                            {category.outdoorAccess === 1 ? <img src={OutdoorAccess} title="Outdoor Access" /> : ''}
                            {category.power === 1 ? <img src={power} title="Power" /> : ''}</TableCell>
                          <TableCell>{category.onlinePrice >= 1 && locationInfoData.countryCurrency ? <span>{locationInfoData.countryCurrency + category.onlinePrice}</span> : 'N/A'}{(category.isMonthly && category.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</TableCell>
                  {/* {CONFIG.RegionId ===3?<TableCell>{category.onlinePriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.onlinePriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                          <TableCell>{moment(category.dtOnlinePriceChanged).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell>{category.regPrice >= 1 && locationInfoData.countryCurrency ? <span>{locationInfoData.countryCurrency + category.regPrice}</span> : 'N/A'}{(category.isMonthly && category.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</TableCell>
                          {/* {CONFIG.RegionId ===3?<TableCell>{category.regularPriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.regularPriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                          <TableCell>{moment(category.dtPriceChanged).format('DD-MMM-YYYY')}</TableCell>
                          <TableCell>{category.promo && category.promoCount > 50 ? 'Annexure -'+category.annexure : category.promo}</TableCell>
                          <TableCell>{moment(category.dtPromoChanged).format('DD-MMM-YYYY')}</TableCell>



                        </TableRow>
                      )
                      :
                      detailStoreDatafilter && detailStoreDatafilter.length > 0 ?
                        rows.map((category, index) =>
                          <TableRow key={category.userStoreID} title={'Last Checked: ' + moment(category.lastCheckedDate).format('DD-MMM-YYYY')} className={category.rowcount > 0 ? 'table-body gropup-data border-tr' : 'no-border'}>
                            {category.storeName !== '' ? <TableCell className="top-align-cell" >
                              {/* <Link onClick={() => this.getParams(category.storeName, category.storeId)} className="storename">{category.storeName}</Link> */}
                              <span className="storename">{category.storeName}</span><br />
                              <span className="store-names-view">{category.address}</span>
                            </TableCell> : <TableCell></TableCell>}
                            {category.proximity !== 'empty' ? <TableCell
                             
                              className={category.rowcount > 0 ? 'border-right-cell' : ''}>
                              {category.proximity.toFixed(2)}{(CONFIG.RegionId == 2) ||(CONFIG.RegionId ==  4) ? ' '+'km' :' '+'mi'}
                            </TableCell> : <TableCell></TableCell>}
                            <TableCell>{category.size}
                              {category.spaceType.toLowerCase().replace(' ', '') === "storageunit" ? <img src={StorageUnit} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "apartment" ? <img src={apartment} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "artstudio" ? <img src={artstudio} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "box" ? <img src={box} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "celltower" ? <img src={celltower} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "container" ? <img src={container} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "filestorage" ? <img src={filestorage} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "office" ? <img src={office} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "mailbox" ? <img src={mailbox} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "parking" ? <img src={parking} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "unit" ? <img src={unit} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "warehouse" ? <img src={warehouse} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "winestorage" ? <img src={winestorage} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "workshop" ? <img src={workshop} class="space-type" title={category.spaceType} /> : ''}
                              {category.spaceType.toLowerCase().replace(' ', '') === "locker" ? <img src={locker} class="space-type" title={category.spaceType} /> : ''}
                            </TableCell>
                            <TableCell>{category.floor}</TableCell>
                            <TableCell className="feature-images">

                              {category.alarm === 1 ? <img src={Alarm} title="Alarm" /> : ''}
                              {category.climateControlled === 1 ? <img src={ClimateControlled} title="Climate" /> : ''}
                              {category.doorType > 0 ? <img src={DoorType} title={category.doorType === 1 ? 'Roll-Up Door' : category.doorType === 2 ? 'Swing Door' : category.doorType === 3 ? 'Double Door' : category.doorType === 4 ? 'Chain Link Door' : category.doorType === 5 ? 'Other Door' : ''} /> : ''}
                              {category.driveUp === 1 ? <img src={DriveUp} title="Drive Up" /> : ''}
                              {category.elevator === 1 ? <img src={Elevator} title="Elevator" /> : ''}
                              {category.humidityControlled === 1 ? <img src={HumidityControlled} title="Humidity" /> : ''}
                              {category.outdoorAccess === 1 ? <img src={OutdoorAccess} title="Outdoor Access" /> : ''}
                              {category.power === 1 ? <img src={power} title="Power" /> : ''}</TableCell>
                            <TableCell>{category.onlinePrice >= 1 && locationInfoData.countryCurrency ? <span>{locationInfoData.countryCurrency + category.onlinePrice}</span> : 'N/A'}{(category.isMonthly && category.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</TableCell>
                            {/* {CONFIG.RegionId ===3?<TableCell>{category.onlinePriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.onlinePriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                            <TableCell>{moment(category.dtOnlinePriceChanged).format('DD-MMM-YYYY')}</TableCell>
                            <TableCell>{category.regPrice >= 1 && locationInfoData.countryCurrency ? <span>{locationInfoData.countryCurrency + category.regPrice}</span> : 'N/A'}{(category.isMonthly && category.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</TableCell>
                            {/* {CONFIG.RegionId ===3?<TableCell>{category.regularPriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:category.regularPriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</TableCell>:''} */}
                            <TableCell>{moment(category.dtPriceChanged).format('DD-MMM-YYYY')}</TableCell>
                            <TableCell>{category.promo && category.promoCount > 50 ? 'Annexure -'+category.annexure : category.promo}</TableCell>
                            <TableCell>{moment(category.dtPromoChanged).format('DD-MMM-YYYY')}</TableCell>


                          </TableRow>
                        )
                        :
                        <TableRow>
                          <TableCell colSpan="10" className="no-data">{isLoading===false ? 'No data Available.':''}</TableCell>
                        </TableRow>
                  }

                </TableBody>
                {/* {
                detailStoreData && detailStoreData.length > 0 ?
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[2]}
                  colSpan={10}
                  count={detailStoreData ? detailStoreData.length : detailStoreDatafilter ? detailStoreDatafilterLength : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={DetailedStoresViewWrapped}
                />
              </TableRow>
            </TableFooter>
            :''} */}
              </Table>
            </Paper>
            {/* {CONFIG.RegionId ===3?
            <div className="new-legend legend-box">
                    
                        <b>Legend:</b>
                          <FontAwesomeIcon icon={faCheck} className="green-clr" /> Price includes VAT
                          <FontAwesomeIcon icon={faTimes} className="red-clr" /> Price excludes VAT 
                          <FontAwesomeIcon  className="red-clr" /> <span>--</span>Unknown if price includes VAT
                          
                      </div>
                      :""
            } */}
          </div>
        ))
        : '' } 
      </div>
      <div className="annexure" style={annexureFilters && annexureFilters.length > 0 ? {display: 'block'} : {display: 'none'}}>
                    {annexureFilters && annexureFilters.length !== 0 ? annexureFilters.map((rows, i) => (
                      <div className="page-annexure finalResultSet">
                          <div>
                              {annexureFilters && rows.length != 0 ? rows.map((row1, annexure) => {
                                      return (
                                          <div key={row1.storeID} >
                                              <p className="bold-text mb-1">Annexure({row1.annexure}) :</p>
                                              <div className="annexure-text">{row1.promo}</div>
                                          </div>
                                      )
                              })
                                  : ''
                              }
                          </div>
                      </div>
                    ))
                    : ''}
                </div>
      </div>
    );
  }
}

CustomDetailedStoresViewPdf.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default CustomDetailedStoresViewPdf;