import React from 'react';
import "./ActivityReportPdf.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { CONFIG } from '../../utils/config';
import Logo from '../../Assets/Images/opt-logo.png';
import Logo1 from '../../Assets/Images/stortrack-logo.png';
import Linkedin from '../../Assets/Images/email-linkedin.png';
import Facebook from '../../Assets/Images/email-fb.png';
import Twitter from '../../Assets/Images/email-twitter.png';
// import Down from '../../Assets/Images/red-arrow.png';
// import Up from '../../Assets/Images/green-arrow.png';
import axios from 'axios';

class ActivityReportPdf extends React.Component {
    constructor(props) {
        super(props);
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const today = new Date();
        this.state = {
            userStoreId: this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0,
            date: month[today.getMonth()] + " " + today.getDate() + ", " + today.getFullYear(),
            activityReports: [],
            inventoryAvailablities: [],
            rateTrends: [],
            basicInfo: {},
            accessKey: this.props.match.params.accessKey ? this.props.match.params.accessKey : '',
            token: ''
        }
    }
    async componentDidMount() {
        // const data = {
        //     UserName: 'santhosh@aggregateintelligence.com',
        //     Password: '365media'
        //     // "SecretKey": this.props.accessKey,
        //     // "RegionId": CONFIG.RegionId
        // }

        // const response = await axios.post(CONFIG.API_URL + 'account/securelogin', data);
        // if (response.status === 200) {
        //     this.state.accessKey = response.data.token;
        //     this.getData();
        // } else {
        //     alert(response.status.Message);
        // }

    }
    async UNSAFE_componentWillMount() {
        const data = {
            "SecretKey": this.state.accessKey,
            "RegionId": CONFIG.RegionId
        }

        const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
        if (response.status === 200) {
            this.state.token = response.data.token;
            this.getData();
        } else {
            alert(response.status.Message);
        }
    }

    getData() {
        let token = this.state.token;
        const data = {
            "UserStoreID": parseInt(this.state.userStoreId),
            "RegionId": CONFIG.RegionId
        }
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'dashboard/downloadactivityreportdata', data)
            .then(response => {
                this.setState({
                    basicInfo: response.data.info || {},
                    activityReports: response.data.data.activityReports || [],
                    inventoryAvailablities: response.data.data.inventoryAvailablities || [],
                    rateTrends: response.data.data.rateTrends || []
                })
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    render() {
        const { activityReports, inventoryAvailablities, rateTrends, basicInfo } = this.state;
        return (
            <div class="container-fluid p-0 activity-pdf-download">
                <div className="header">
                    <div style={{width: '70%', float: 'left'}}>
                        <h1 style={{ fontSize: '2.2rem' }}>Today's Market Activity Report</h1>
                        <p>Optimize your rates with quick and accurate market insights in your inbox!</p>
                    </div>
                    <div style={{width: '30%', float: 'right'}}>
                        <img style={{ float: 'right' }} src={Logo} width="300px" height="78px" />
                    </div>
                    <div className="clear"></div>
                    <hr className="bg-warning m-0" />
                </div>
                <div className="footer">
                    <div>
                        <img src={Logo1} width="230px" height="35px" style={{float: 'left'}}/>
                        <span style={{ float: 'right' }}>
                            <img className="mr-2" src={Linkedin} width="32px" height="32px" />
                            <img className="mr-2" src={Twitter} width="32px" height="32px" />
                            <img className="mr-2" src={Facebook} width="32px" height="32px" />
                        </span>
                        <div className="clear"></div>
                    </div>
                    <div className="text-center">
                        <a href="#" id="website">www.StorTrack.com</a> | <a id="contact"> 1-800-969-7424</a> | <a href="#" id="email">support@stortrack.com</a>
                    </div>
                </div>
                {activityReports && activityReports.length > 0 ? <React.Fragment>
                <div className="pt-2 body-pdf-content">
                    <div>
                        <h3 className="text-danger" style={{ fontSize: '1.6rem' }}>For {this.state.date}</h3>
                        <p>This is your daily Optimize Market Activity Report based on a {basicInfo.zoneCoverage != null ? basicInfo.zoneCoverage : 'N/A'} of:<br />{basicInfo.storeName != null ? basicInfo.storeName : 'N/A'} at<br />{basicInfo.address != null ? basicInfo.address : 'N/A'}</p>
                        <h4 className="text-danger">Today's Rate Activity</h4>
                        <p>These are the changes in your market's average rate between yesterday and today.</p>
                        <table className="table table-sm table-bordered table-striped todays-rates">
                            <tbody>
                                <tr className="bg-secondary text-white">
                                    <th scope="col">Unit Type</th>
                                    <th scope="col">Rate Changes</th>
                                    <th scope="col" className="text-center">Market Av Yesterday</th>
                                    <th scope="col" className="text-center">Market Av Today</th>
                                    {basicInfo.isMyStore && basicInfo.isMyStore == 1 ? <th scope="col">My Store Price</th> : ''}
                                    <th scope="col" className="text-center">Change</th>
                                    <th scope="col">Notables</th>
                                </tr>
                                {
                                    activityReports && activityReports.length > 0 ? activityReports.map((val, i) => {
                                        return (
                                            <tr key={i} className="">
                                                <td>{val.unitType != null && val.unitType != undefined ? val.unitType : <div className="text-center">-</div>}</td>
                                                <td>{val.rateActivityReport != null && val.rateActivityReport != undefined ? val.rateActivityReport : <div className="text-center">-</div>}</td>
                                                <td className="text-center">{val.marketAvYesterday != null && val.marketAvYesterday != undefined ? val.marketAvYesterday : <div className="text-center">-</div>}</td>
                                                <td className="text-center">{val.marketAvgToday != null && val.marketAvgToday != undefined ? val.marketAvgToday : <div className="text-center">-</div>}</td>
                                                {basicInfo.isMyStore && basicInfo.isMyStore == 1 ? <td className="text-center">{val.myStorePrice != null && val.myStorePrice != undefined ? val.myStorePrice : <div className="text-center">-</div>}</td> : ''}
                                                <td className="text-center" style={{ color: val.change == null || val.change == undefined || val.change == 0 ? "#000" : val.change < 0 ? "#F15D47" : "#48AF5F" }}>{val.change == null && val.change == undefined ? <div className="text-center">-</div> : val.change == 0 ? val.change + "%" : val.change < 0 ? <><FontAwesomeIcon icon={faArrowDown} style={{ color: "#F15D47" }} />{val.change + "%"}</> : <><FontAwesomeIcon icon={faArrowUp} style={{ color: "#48AF5F" }} />{val.change + "%"}</>}</td>
                                                <td>{val.notables != null && val.notables != undefined ? val.notables : <div className="text-center">-</div>}</td>
                                            </tr>
                                        );
                                    }
                                    ) : <tr><td colSpan={basicInfo.isMyStore && basicInfo.isMyStore == 1 ? "7" : "6"}>No Data Found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="page"></div>
                </React.Fragment>: ''}
                {inventoryAvailablities && inventoryAvailablities.length > 0 ? <React.Fragment>
                <div className="pt-2 body-pdf-content">
                    <h4 className="text-danger mt-3">Today's Inventory Activity</h4>
                    <p>This is showing how many of each unit type are advertised in this market today compared to yesterday.</p>
                    <table className="table table-sm table-bordered table-striped common-table-width">
                        <tbody>
                            <tr className="bg-secondary text-white">
                                <th scope="col">Unit Type</th>
                                <th scope="col">Inventory Change</th>
                                <th scope="col" className="text-center">Yesterday</th>
                                <th scope="col" className="text-center">Today</th>
                                <th scope="col">Notables</th>
                            </tr>
                            {
                                inventoryAvailablities && inventoryAvailablities.length > 0 ? inventoryAvailablities.map((val, i) =>
                                    <tr key={i}>
                                        <td>{val.unitType != null && val.unitType != undefined ? val.unitType : <div className="text-center">-</div>}</td>
                                        <td>{val.inventoryChange != null && val.inventoryChange != undefined ? val.inventoryChange : <div className="text-center">-</div>}</td>
                                        <td className="text-center">{val.yesterday != null && val.yesterday != undefined ? val.yesterday : <div className="text-center">-</div>}</td>
                                        <td className="text-center">{val.today != null && val.today != undefined ? val.today : <div className="text-center">-</div>}</td>
                                        <td>{val.notables != null && val.notables != undefined ? val.notables : <div className="text-center">-</div>}</td>
                                    </tr>
                                )
                                    : <tr><td colSpan="6">No Data Found</td></tr>
                            }
                        </tbody>
                    </table>
                    <p>If you have any questions please do not hesitate to contact us. To modify the settings of this Activity Report, simply log-in to your Optimize account, go the Market Dashboard and select Manage Activity Reports in the left navigation bar.</p>
                </div>
                <div className="page"></div>
                </React.Fragment> : ''}
                {rateTrends && rateTrends.length > 0 ? <React.Fragment>
                <div className="pt-2 body-pdf-content">
                    <h4 className="text-danger mt-3">Today's Rate Trend Analysis</h4>
                    <p>This is an analysis on how the rates for each unit have trended over the past year.</p>
                    <table className="table table-sm table-bordered table-striped common-table-width-half">
                        <tbody>
                            <tr className="bg-secondary text-white">
                                <th scope="col">Unit Type</th>
                                <th scope="col" className="text-center">5 day Trend</th>
                                <th scope="col" className="text-center">30 day Trend</th>
                                <th scope="col" className="text-center">90 day Trend</th>
                                <th scope="col" className="text-center">365 day Trend</th>
                            </tr>
                            {
                                rateTrends && rateTrends.length > 0 ? rateTrends.map((val, i) =>
                                    <tr key={i}>
                                        <td>{val.unitType != null && val.unitType != undefined ? val.unitType : <div className="text-center">-</div>}</td>
                                        <td className="text-center" style={{ color: val.day5 == null || val.day5 == undefined || val.day5 == 0 ? "#000" : val.day5 < 0 ? "#F15D47" : "#48AF5F" }}>{val.day5 == null && val.day5 == undefined ? <div className="text-center">-</div> : val.day5 == 0 ? val.day5 + "%" : val.day5 < 0 ? <><FontAwesomeIcon icon={faArrowDown} style={{ color: "#F15D47" }} />{val.day5 + "%"}</> : <><FontAwesomeIcon icon={faArrowUp} style={{ color: "#48AF5F" }} />{val.day5 + "%"}</>}</td>
                                        <td className="text-center" style={{ color: val.day30 == null || val.day30 == undefined || val.day30 == 0 ? "#000" : val.day30 < 0 ? "#F15D47" : "#48AF5F" }}>{val.day30 == null && val.day30 == undefined ? <div className="text-center">-</div> : val.day30 == 0 ? val.day30 + "%" : val.day30 < 0 ? <><FontAwesomeIcon icon={faArrowDown} style={{ color: "#F15D47" }} />{val.day30 + "%"}</> : <><FontAwesomeIcon icon={faArrowUp} style={{ color: "#48AF5F" }} />{val.day30 + "%"}</>}</td>
                                        <td className="text-center" style={{ color: val.day90 == null || val.day90 == undefined || val.day90 == 0 ? "#000" : val.day90 < 0 ? "#F15D47" : "#48AF5F" }}>{val.day90 == null && val.day90 == undefined ? <div className="text-center">-</div> : val.day90 == 0 ? val.day90 + "%" : val.day90 < 0 ? <><FontAwesomeIcon icon={faArrowDown} style={{ color: "#F15D47" }} />{val.day90 + "%"}</> : <><FontAwesomeIcon icon={faArrowUp} style={{ color: "#48AF5F" }} />{val.day90 + "%"}</>}</td>
                                        <td className="text-center" style={{ color: val.day5 == null || val.day5 == undefined || val.day365 == 0 ? "#000" : val.day365 < 0 ? "#F15D47" : "#48AF5F" }}>{val.day365 == null && val.day365 == undefined ? <div className="text-center">-</div> : val.day365 == 0 ? val.day365 + "%" : val.day365 < 0 ? <><FontAwesomeIcon icon={faArrowDown} style={{ color: "#F15D47" }} />{val.day365 + "%"}</> : <><FontAwesomeIcon icon={faArrowUp} style={{ color: "#48AF5F" }} />{val.day365 + "%"}</>}</td>
                                    </tr>
                                )
                                    : <tr><td colSpan="6">No Data Found</td></tr>
                            }
                        </tbody>
                    </table>
                    <p>If you have any questions please do not hesitate to contact us. To modify the settings of this Activity Report, simply log-in to your Optimize account, go the Market Dashboard and select Manage Activity Reports in the left navigation bar.</p>
                </div>
                </React.Fragment> : ''}
            </div>
        )
    }
}

export default ActivityReportPdf;