import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { CONFIG } from '../../utils/config';
import axios from 'axios';

const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket,
);

export default class CompetitorStore extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
      trackModalData: '',
      modal: false,
      storeAddress: ''
    }

    this.toggleStoreModal = this.toggleStoreModal.bind(this);
    this.trackModal = this.trackModal.bind(this);
  }

  toggleStoreModal(storeAddress, storeId) {
    this.setState(state => ({
      modal: !state.modal,
      storeAddress: storeAddress,
    }))

    axios.post(CONFIG.API_URL + 'market/competitors/' + storeId + '/' + CONFIG.RegionId)
      .then(response => {
        const trackModalData = response.data.competitor.competitorsList;
        var trackedFilterResult = trackModalData.filter((result) => result.isTracked === true);
        this.setState({ trackModalData: trackedFilterResult });
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  trackModal() {
    this.setState(state => ({
      modal: !state.modal,
      pageCount: 0
    }));
  }

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };

  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };

  render() {
    const { trackModalData, pageCount, storeAddress } = this.state;

    return (
      <div>
        <Modal isOpen={this.state.modal} 
        // toggle={() => this.trackModal()} 
        className="store-mainpopup"
        backdrop="static">
          <ModalHeader toggle={this.trackModal}>{storeAddress.storeName}</ModalHeader>
          <ModalBody>
            <Table className="table custom-table table-bordered store-count-popup">
              <TableHead>
                <TableRow>
                  <TableCell>COMPETITOR NAME</TableCell>
                  <TableCell >ADDRESS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trackModalData ? trackModalData.slice(pageCount * 10, pageCount * 10 + 10).map((trackData) =>
                  <TableRow key={trackData.rec_num}>
                    <TableCell>{trackData.storeName}</TableCell>
                    <TableCell>{trackData.storeAddress}</TableCell>
                  </TableRow>
                ) :
                  <TableRow></TableRow>
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[1]}
                    colSpan={3}
                    count={trackModalData ? trackModalData.length : 0}
                    rowsPerPage={10}
                    page={pageCount}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handleChangePageTrack}
                    onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                    ActionsComponent={MyMarketWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}