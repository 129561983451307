import React, { Fragment } from "react";
import { Row, Col, Button } from "reactstrap";
import './MapView.css';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { MarketProfile } from "../MarketProfile";
import { CONFIG } from "../../utils/config";
import homeIcon from "./../../Assets/Images/home-icon-circle.png";
import { HelpTextPopup } from "./../HelpTextPopup/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MapGL, { Layer, NavigationControl, Popup, Source, Marker, WebMercatorViewport, FlyToInterpolator } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": ["get", "colorCode"],
  },
};

export default class MapViews extends React.Component {
  static defaultProps = {
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" +
      CONFIG.GoogleMapsApiKey +
      "",
  };
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      mapData: [],
      FData: [],
      showingInfoWindow: false,
      storeId: "",
      searchId: "",
      test: "",
      storeName: "",
      storeid: 0,
      isLoading: false,
      snackOpen: false,
      snackMSg: "",
      snackClr: "",
      zoomValue: 6,
      //   viewport: {
      //     longitude: -97.86589,
      //     latitude:  37.70959,
      //     zoom: 3,
      // },
      viewport: {
        // width:"100%",
        // height:"100vh",
        longitude: parseFloat(props.location.longitude),
        latitude: parseFloat(props.location.latitude),
        zoom: 10,
      },
      popupInfoLatlng: {},
      satelliteView: 0
    };
    this.getParams = this.getParams.bind(this);
    this.mapRef = React.createRef();
  }

  getParams() {
    let strName = this.state.storeName;
    let strID = this.state.storeid;
    if (strName && strID) {
      this.child.current.toggleModal(strName, strID);
    }
  }

  onMarkerClick = (event) => {
    let sam = [],
      uniqsize = [];
    let latitude = Number(event.lngLat[1]);
    let longitude = Number(event.lngLat[0]);
    let data = event.features.filter(ft => ft.layer.type === "circle")[0];
    if (data && data.layer.type === "circle") {
      let price = "";
      let selectedMarker = this.state.mapData.filter(ft => ft.properties.storeId === data.properties.StoreID)[0].properties;
      let name = selectedMarker.storeName;
      let address = selectedMarker.address;
      let id = selectedMarker.storeId;
      let lat = selectedMarker.latitude;
      let lng = selectedMarker.longitude;
      let size = selectedMarker.size;
      this.state.mapData.map((test) => {
        if (test.properties.storeId === id) {
          sam.push(test.properties.size);
        }
      });
      const uniqueNames = Array.from(new Set(sam));
      var units = uniqueNames.join(", ");
      var add = address;
      var last = add.lastIndexOf(",");
      var s1 = add.substring(0, last);
      var s2 = add.substring(last + 1);
      var storedata = this.state.FData;
      var tFData = storedata.filter(ft => ft.properties.StoreID == id)[0].properties;
      var StandardOnlinePrice = tFData.StandardOnlinePrice;
      var StandardRegPrice = tFData.StandardRegPrice;
      this.setState({
        storeName: name,
        lat: lat,
        lng: lng,
        address: address ? s1 : "",
        address1: address ? s2 : "",
        regPrice:
          StandardRegPrice != this.props.curr + 0 ? StandardRegPrice : "N/A",
        onlinePrice:
          StandardOnlinePrice != this.props.curr + 0
            ? StandardOnlinePrice
            : "N/A",
        size: size ? units : "N/A",
        storeid: id,
        showingInfoWindow: true,
        popupInfoLatlng: { latitude, longitude }
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.bca != this.props.bca) {
      this.state.mapData = [];
      this.setState(
        {
          isLoading: true,
          showingInfoWindow: false,
          bca: nextProps.bca,
          abc: nextProps.abc,
        },
        () => {
          this.componentDidMount();
        }
      );
    }
  }

  mapView() {
    // const data=
    // {
    //   "UserStoreID":129229,
    //   "SearchID":965256
    // }
    localStorage.setItem("StId", this.props.abc);
    const data = {
      UserStoreID: this.props.abc,
      SearchID: this.props.bca,
      RegionID: CONFIG.RegionId,
      IsMapBox: CONFIG.IsMapbox,
    };
    let token = localStorage.getItem("accessKey");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    axios
      .post(CONFIG.API_URL + "unit/unitlevelmapview", data)
      .then((res) => {
        let mapData = res.data.data.unitLevelDatas;
        let uniquestore = [...new Set(mapData.map((item) => item.properties.storeId))];
        var FData = [];
        for (var t = 0; t < uniquestore.length; t++) {
          var tFData = mapData.filter((a) => a.properties.storeId == uniquestore[t]);
          var mData = {
            StoreID: uniquestore[t],
            StoreAddress: tFData[0].properties.address,
            colorCode: tFData[0].properties.colorCode,
            PremiumRegPrice: Math.max.apply(
              Math,
              tFData.map(function (o) {
                return o.properties.regPrice;
              })
            ),
            // StandardRegPrice:(Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
            StandardRegPrice:
              Math.max.apply(
                Math,
                tFData.map(function (o) {
                  return o.properties.regPrice;
                })
              ) ===
                Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.regPrice;
                  })
                )
                ? this.props.curr +
                Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.regPrice;
                  })
                )
                : this.props.curr +
                (Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.regPrice;
                  })
                ) +
                  " - " +
                  Math.max.apply(
                    Math,
                    tFData.map(function (o) {
                      return o.properties.regPrice;
                    })
                  )),
            //old
            // StandardRegPrice : Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) ? this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; })) : this.props.curr + (Math.max.apply(Math, tFData.map(function(o) { return o.regPrice; }))+'-'+ this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.regPrice; }))),
            ValueRegPRice: Math.min.apply(
              Math,
              tFData.map(function (o) {
                return o.properties.regPrice;
              })
            ),
            PremiumOnlinePrice: Math.max.apply(
              Math,
              tFData.map(function (o) {
                return o.properties.onlinePrice;
              })
            ),
            // StandardOnlinePrice:(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
            StandardOnlinePrice:
              Math.max.apply(
                Math,
                tFData.map(function (o) {
                  return o.properties.onlinePrice;
                })
              ) ===
                Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.onlinePrice;
                  })
                )
                ? this.props.curr +
                Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.onlinePrice;
                  })
                )
                : this.props.curr +
                (Math.min.apply(
                  Math,
                  tFData.map(function (o) {
                    return o.properties.onlinePrice;
                  })
                ) +
                  " - " +
                  Math.max.apply(
                    Math,
                    tFData.map(function (o) {
                      return o.properties.onlinePrice;
                    })
                  )),
            //old
            // StandardOnlinePrice: Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) === Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) ? this.props.curr+ Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; })) : this.props.curr +(Math.max.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))+'-'+this.props.curr + Math.min.apply(Math, tFData.map(function(o) { return o.onlinePrice; }))),
            ValueOnlinePRice: Math.min.apply(
              Math,
              tFData.map(function (o) {
                return o.properties.onlinePrice;
              })
            ),
          };
          let reStructuredData = { ...tFData[0], properties: mData }
          FData.push(reStructuredData);
        }
        this.setState({ mapData, FData, isLoading: false }, () => this.getBoundingValues(mapData));
      })

      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  getBoundingValues = (points) => {
    if (points.length) {
      const paddingLength = window.innerHeight / 3;
      const applyToArray = (func, array) => func.apply(Math, array);
      const pointsLong = points.map((point) => point.geometry.coordinates[0]);
      const pointsLat = points.map((point) => point.geometry.coordinates[1]);
      if (!pointsLong.includes(Number(this.props.location.longitude))) pointsLong.push(Number(this.props.location.longitude));
      if (!pointsLat.includes(Number(this.props.location.latitude))) pointsLat.push(Number(this.props.location.latitude));
      const cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ];
      const viewport = new WebMercatorViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      }).fitBounds(cornersLongLat, {
        padding: { top: paddingLength, bottom: paddingLength, left: paddingLength, right: paddingLength },
      });
      const { longitude, latitude, zoom } = viewport;
      this.setState(prevState => {
        return {
          viewport: {
            ...prevState.viewport,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionDuration: 100,
            transitionInterpolator: new FlyToInterpolator(),
          }
        }
      });
    }
  };

  onMarkerClose = () => {
    this.setState({ showingInfoWindow: false });
  };

  componentDidMount() {
    // let zoomValue;
    // if (this.props.location.searchType === 3) {
    //   zoomValue = 8;
    // } else if (this.props.location.searchType === 2) {
    //   let zCnt =
    //     this.props.location.storeZoneCoverage != "" &&
    //       this.props.location.storeZoneCoverage != null
    //       ? this.props.location.storeZoneCoverage.split(",").length - 1
    //       : 1;
    //   zoomValue = zCnt > 1 ? 9 : zCnt > 0 ? 10 : 11;
    // } else if (this.props.location.searchType === 1) {
    //   let storeZoneCoverage = this.props.location.storeZoneCoverage.split(" ");
    //   zoomValue =
    //     storeZoneCoverage[0] === "1" ||
    //       storeZoneCoverage[0] === "2" ||
    //       storeZoneCoverage[0] === "3"
    //       ? 13
    //       : storeZoneCoverage[0] === "4" ||
    //         storeZoneCoverage[0] === "5" ||
    //         storeZoneCoverage[0] === "6"
    //         ? 12
    //         : storeZoneCoverage[0] === "6" || storeZoneCoverage[0] === "7"
    //           ? 11
    //           : storeZoneCoverage[0] === "8" ||
    //             storeZoneCoverage[0] === "9" ||
    //             storeZoneCoverage[0] === "10"
    //             ? 10
    //             : 6;
    // }
    // this.setState(prevState => ({
    //   isLoading: true,
    //   viewport: {
    //     ...prevState.viewport,
    //     zoom: 10
    //   }
    // }));
    this.mapView();
  }
  downLoadPDF() {
    // this.setState({ isLoading: true });
    var currentDate = moment(new Date()).format("DD-MMM-YYYY");
    let usId = parseInt(this.props.abc);
    let pta = parseInt(this.props.bca);
    let lat = this.props.location.latitude;
    let lng = this.props.location.longitude;
    let accessKey = localStorage.getItem("SecretKey");
    var timestamp =
      new Date().getDate() +
      "" +
      new Date().getMonth() +
      "" +
      new Date().getFullYear() +
      "" +
      new Date().getTime();
    //construct filename for pdf report.
    var fileName = usId + "MapView" + "_" + timestamp + ".pdf";
    var stoName =
      this.props.storeDetails &&
        this.props.storeDetails.storeName &&
        this.props.storeDetails.storeName !== null &&
        this.props.storeDetails.storeName !== "" &&
        this.props.storeDetails.storeName !== undefined
        ? this.props.storeDetails.storeName
        : "Your Store";
    var fileNamePdf = stoName + "_" + "MapView" + "_" + currentDate + ".pdf";
    this.setState({
      snackClr: "snack-info",
      snackOpen: true,
      snackMSg:
        "Your download is being processed. The " +
        fileNamePdf +
        " file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.",
    });
    let ComponentName = 11;
    const Input = {
      RegionID: CONFIG.RegionId,
      URL:
        CONFIG.OPTZ_URL +
        "render-pdf/" +
        accessKey +
        "/" +
        ComponentName +
        "/0/" +
        usId +
        "/" +
        pta +
        "/" +
        lat +
        "/" +
        lng +
        "/",
      FileName: fileName,
    };
    // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
    axios({
      url: CONFIG.API_URL + "dashboard/generatepdfbypage",
      method: "post",
      responseType: "blob",
      data: Input,
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNamePdf;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(link.href);
          link.remove();
        }, 3000);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  handleViewportChange = (viewport) => {
    this.setState({ viewport });
  }

  onLoadHomeMarker = () => {
    setTimeout(() => {
      let mapbox = this.mapRef.current.getMap();
      mapbox.loadImage(homeIcon, (error, image) => {
        if (image) mapbox.addImage('homeMarker', image)
        else if (error) throw error;
      });
    }, 500);
  }

  changeMapView = (type) => {
    this.setState({ satelliteView: type }, () => this.onLoadHomeMarker());
  }
  render() {
    const {
      mapData,
      isLoading,
      snackOpen,
      snackMSg,
      viewport,
      popupInfoLatlng,
      satelliteView
    } = this.state;
    const defaultProps = {
      helpTitle: "Map View",
      helpContent:
        "<p>The stores advertising this unit are shown on a map. The stores identified in blue are the stores currently advertising rates online, those in black are stores in this Market which currently do not advertised online. Hover over any of the store pinpoints to view some details on that store. </p><p><i>*Your store prices are included in the calculations.</i></p>",
    };
    return (
      <div className="wrapper my-account-box">
        {isLoading ? (
          <div className="loader-wrap">
            <div className="loading"></div>
          </div>
        ) : (
          ""
        )}
        <MarketProfile ref={this.child} />
        {/* {isLoading ? <div className="loading"></div> : ''} */}
        <div className="mt-3 is-it-store">
          <Col
            md="12"
            className="p-v-15 border-box shadow-sm rounded padding-bottom-0"
          >
            <div className="float-right-help">
              <Button
                disabled={!isLoading ? false : true}
                className="mb-1 apply-btn download-pdf "
                onClick={this.downLoadPDF.bind(this)}
                style={{ float: "none" }}
              >
                <FontAwesomeIcon icon={faFilePdf} />
                PDF Download
              </Button>
              <HelpTextPopup parm={defaultProps} />
            </div>
            <div className="clear"></div>
            <Row className="mt-2">
              <Col md="12">
                <div className="map-view-height">
                  <div className='map-satellite-view' style={{ right: '30px' }}>
                    <button onClick={() => this.changeMapView(0)}>
                      <span className={satelliteView ? "map-satellite-opacity" : ""}>Map</span>
                    </button>
                    <button onClick={() => this.changeMapView(1)}>
                      <span className={satelliteView ? "" : "map-satellite-opacity"}>Satellite</span>
                    </button>
                  </div>
                  <MapGL
                    {...viewport}
                    ref={this.mapRef}
                    onViewportChange={this.handleViewportChange}
                    mapboxApiAccessToken={CONFIG.REACT_APP_MAPBOX_TOKEN}
                    mapStyle={satelliteView ? "mapbox://styles/mapbox/satellite-streets-v11" : "mapbox://styles/mapbox/streets-v11"}
                    attributionControl={false}
                    onHover={this.onMarkerClick}
                    onLoad={this.onLoadHomeMarker}
                  >
                    <NavigationControl className='zoom-plus-minus' showCompass={false} />
                    {/* <Marker longitude={parseFloat(this.props.location.longitude)} latitude={parseFloat(this.props.location.latitude)} offsetLeft={-10} offsetTop={-12}>
                      <img src={homeIcon} alt="icon" />
                    </Marker> */}
                    {/* {this.props.location.longitude && this.props.location.latitude && */}
                    <Source
                      type="geojson"
                      data={{
                        'type': 'FeatureCollection',
                        'features': [{
                          'type': 'Feature',
                          'geometry': {
                            'type': 'Point',
                            'coordinates': [parseFloat(this.props.location.longitude), parseFloat(this.props.location.latitude)]
                          }
                        }]
                      }}
                    >
                      <Layer
                        id="home-marker"
                        type="symbol"
                        layout={{
                          'icon-image': 'homeMarker',
                        }}
                      />
                    </Source>
                    <Source id="map-data" type='geojson' data={{ type: 'FeatureCollection', features: this.state.FData }}>
                      <Layer {...layerStyle} />
                    </Source>
                    {this.state.showingInfoWindow &&
                      (<Popup
                        onClose={() => this.onMarkerClose()}
                        {...popupInfoLatlng}
                        className="mapbox-custom-popup"
                      >
                        <div className="gmap-tooltip">
                          <table class="map-table">
                            <tr>
                              <td class="address-map-top">
                                <b>
                                  <a onClick={this.getParams}>
                                    {this.state.storeName}
                                  </a>
                                </b>
                                <p>
                                  {this.state.address},<br />
                                  {this.state.address1}
                                </p>
                              </td>
                            </tr>
                          </table>
                          <table className="map-table-two">
                            <tr>
                              <td class="label-list">
                                Walk-in Rate:
                              </td>
                              <td>{this.state.regPrice}</td>
                            </tr>
                            <tr>
                              <td class="label-list">Online Rate:</td>
                              <td>{this.state.onlinePrice}</td>
                            </tr>
                            <tr>
                              <td class="label-list">Unit Type:</td>
                              <td class="state-size">
                                {this.state.size}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </table>
                        </div>
                      </Popup>)}
                  </MapGL>
                </div>
              </Col>
            </Row>
            <Col md="12" className="margin-2 map-btm-legend">
              <Row>
                <div className="legend-block">
                  <div className="maps-legend-orange rounded-circle"></div>Your Store
                </div>
                <div className="legend-block">
                  <div className="maps-legend-blue rounded-circle"></div>Competitor
                </div>
                <div className="legend-block">
                  <div className="maps-legend-black rounded-circle"></div>Rate Data Unavailable
                </div>
              </Row>
            </Col>
          </Col>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          className={
            this.state.snackClr + " snackbar bg-color-primary custom-snackbar"
          }
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose.bind(this)}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose.bind(this)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackMSg}</span>}
        />
      </div>
    );
  }
}
