import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import CreditCardInput from 'react-credit-card-input';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Label, Row, Col, Form, Input, Button, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader, FormText } from 'reactstrap';
import './MyAccount.css';
import { QuoteMarket } from '../QuoteMarket';
import moment from 'moment';
export default class SubscriptionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      aModel: false,
      aModel1: false,
      cardName: '',
      cardNumber: null,
      expiry: null,
      cvv: null,
      focusAfterClose: true,
      isChecked: false,
      isPlusChecked: false,
      selected: [],
      optionsChecked: [],
      saveBtn: true,
      disablecheckbox: false,
      disvalue: null,
      upgradeOptions: [],
      deleteOptions: [],
      deleteOptions1: [],
      unUpgradeOptions: [],
      userPriceData: [],
      tempPlusNoOfStores: null,
      tempBasicNoOfStores: null,
      fixedTempBasicNoOfStores: null,
      UserType: 0,
      areyouSure: true,
      totalValue: 0,
      isLoading: false,
      updateSubscripConfirmation: false,
      countryCurrency: '',
      isLoading: false,
      snackOpen: false,
      snackMSg: '',
      errCard: '',
      couponCode: '',
      nextBillingDate: '',
      userStatus: null,
      userUserType: null,
      curSymb: CONFIG.Currency_Symbol,
      infoData: [],
      infoDataStore: []
      
    };
    this.child = React.createRef();
    this.accountModal = this.accountModal.bind(this);
    this.accountModalCLose = this.accountModalClose.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.subHandleChange = this.subHandleChange.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.snackHandleclose = this.snackHandleclose.bind(this);
    this.api_call = this.api_call.bind(this);
    this.formsub_modal_toggle = this.formsub_modal_toggle.bind(this);
    this.ValidateCard = this.ValidateCard.bind(this);
    this.saveSubscriptionWithoutCard = this.saveSubscriptionWithoutCard.bind(this);
    this.updateMenu = this.updateMenu.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.refreshHandler = this.refreshHandler.bind(this);
  }
  sigOut() {
    this.setState({
      aModel1: false
    })
    localStorage.removeItem('accessKey');
    localStorage.removeItem('tempNoStoreCount');
    localStorage.removeItem('isPaid');
    localStorage.removeItem('getNames');
    localStorage.removeItem('instructionMsg');
    localStorage.removeItem('StId');
    localStorage.removeItem('userType');
    localStorage.removeItem('userStatus');
    localStorage.removeItem('locSearchName');
    localStorage.removeItem('userManual');
    localStorage.removeItem('refreshMenu');
    localStorage.removeItem('IsUpdatePaid');
    localStorage.removeItem('displayMessage');
    // this.state.isNavigationBlocked = true;
    this.props.history.push({
      pathname: `/`
    });

  }
  cancelSubcription(event, errors, values) {
    
    this.setState({
      isLoading1: true
    })
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    const remarks = { "Remarks": values.Remarks, "RegionId": CONFIG.RegionId }
    axios.post(CONFIG.API_URL + 'account/cancelsubscription', remarks)
      .then(response => {
        if (response.status == 200) {
          if (response.data == 'success') {

            this.setState({
              isLoading1: false, aModel: false, aModel1: true
            })
          }
        }
      })
      .catch((err) => {
        this.setState({
          isLoading1: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });

  }
  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
    // const x = document.getElementById('Subid');
    // x.focus();
  };
  toggleConfirmation() {
    this.setState(prevState => ({
      updateSubscripConfirmation: !prevState.updateSubscripConfirmation,
      saveBtn: true
    }));
    this.updateMenu();
    const { from } = this.props.location.state || { from: { pathname: "/subscription-details/" } };
    this.props.history.push(from);

  }
  updateMenu() {

    localStorage.setItem('IsUpdatePaid', "1");
    var isElement = document.getElementById('r2');
    if (isElement == null) {
      document.getElementById('r1').click();
    }
    else {
      document.getElementById('r1').click();
      document.getElementById('r2').click();
    }
  }
  areyouSure() {
    if (this.state.userStatus !== 1 || this.state.userUserType === 2 || localStorage.getItem('isPaid') === '0') {
      this.setState({
        areyouSure: false
      })
    }
    else if (this.state.userStatus === 1 && this.state.userUserType !== 2 && this.state.fixedTempBasicNoOfStores < parseInt(this.state.tempBasicNoOfStores)) {
      this.setState({
        areyouSure: false
      })
    }
    else if (this.state.userStatus === 1 && this.state.userUserType !== 2 && this.state.fixedTempBasicNoOfStores >= parseInt(this.state.tempBasicNoOfStores) && localStorage.getItem('isPaid') !== '0') {
      this.setState({
        isLoading1: true
      })

      const subscriptionData = this.state.storeData.info.userInfo;
      // var getPaymonthyear = this.state.expiry.toString().split('/');
      const data = {
        "UserType": this.state.userUserType,
        "TempNoOfStores": subscriptionData.tempNoOfStores,
        "TempBasicNoOfStores": this.state.tempBasicNoOfStores,
        // "TempPlusNoOfStores": this.state.tempPlusNoOfStores,
        // "UpgradeStoreIDs": this.state.upgradeOptions.toString(),
        "DeleteStoreIDs": this.state.deleteOptions1.toString(),
        "RegionId": CONFIG.RegionId,
        PaymentDetail: {
          "CardType": "",
          "CardNumber": "",
          "ExpiryMonth": "",
          "ExpiryYear": "",
          "CardName": "",
          "SecurityCode": "",
          "IsDefault": true
        }
      }

      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.put(CONFIG.API_URL + 'account/downgradesubscription', data)
        .then(res => {
          if (res.status == 200) {
            const getTokvalues = res.data.data;
            this.setState({ getTokvalues });
            if (res.data.message) {
              localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
            } else {
              axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
              localStorage.setItem('accessKey', getTokvalues.token);
              localStorage.setItem('getNames', getTokvalues.firstName);
              localStorage.setItem('isPaid', getTokvalues.isPaid);
              localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
              localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
              localStorage.setItem('userType', getTokvalues.userType);
              localStorage.setItem('userStatus', getTokvalues.status);
              // localStorage.setItem('curSymb', this.state.curSymb);
              localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
              localStorage.setItem('userManual', getTokvalues.isManual);
              localStorage.setItem('displayMessage', getTokvalues.displayMessage);
            }
            window.scrollTo(0, 0);
            this.componentDidMount();
            this.setState({
              isLoading1: false, updateSubscripConfirmation: true,
              open: false
            });
          }
          // const infoData = res.data;
          // this.state.editaccountmodal = false;
          // window.scrollTo(0, 0);
          // this.componentDidMount();
          // this.setState({
          //   isLoading1: false, updateSubscripConfirmation: true,
          //   open: false
          // });
          // window.location.reload();
        })
        .catch(err => {
          if (err.response.status == 400) {
            if (err.response.data.Message == "Card not found" || err.response.data.Message == "Card Expired")
              this.setState({
                areyouSure: false, isLoading1: false, errCard: "Please update your card details"
              })
          }
          else if (err.response.data.Message !== 'Payment Failed') {
            this.setState({ errCard: err.response.data.Message, isLoading1: false });
          }
          else {
            this.setState({ snackOpen: true, snackMSg: 'Payment Failed', errCard: '' });
          }
          this.setState({ isLoading1: false });
        });

    }
    else {
      this.saveSubscriptionWithoutCard();
    }

  }
  saveSubscriptionCard() {


  }
  saveSubscriptionWithoutCard() {
    this.setState({
      isLoading1: true
    })
    const subscriptionData = this.state.storeData.info.userInfo;
    const data = {
      "UserType": this.state.userUserType,
      "TempNoOfStores": subscriptionData.tempNoOfStores,
      "TempBasicNoOfStores": this.state.tempBasicNoOfStores,
      // "TempPlusNoOfStores": this.state.tempPlusNoOfStores,
      // "UpgradeStoreIDs": this.state.upgradeOptions.toString(),
      "DeleteStoreIDs": this.state.deleteOptions1.toString(),
      "RegionId": CONFIG.RegionId,
      PaymentDetail: {
        "CardType": "",
        "CardNumber": "",
        "ExpiryMonth": "",
        "ExpiryYear": "",
        "CardName": "",
        "SecurityCode": "",
        "IsDefault": true
      }
    }
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.put(CONFIG.API_URL + 'account/updatesubscription', data)
      .then(res => {
        // const infoData = res.data;
        // this.state.editaccountmodal = false;
        window.scrollTo(0, 0);
        this.componentDidMount();
        this.setState({
          isLoading1: false, updateSubscripConfirmation: true,
          open: false
        });
        // window.location.reload();
      })
      .catch(err => {
        if (err.response.data.Message !== 'Payment Failed') {
          this.setState({ errCard: err.response.data.Message, isLoading1: false });
        }
        else {
          this.setState({ snackOpen: true, snackMSg: 'Payment Failed', errCard: '' });
        }
        this.setState({ isLoading1: false });
      });
  }
  upgradehandleClick(event, userStoreID, checkOption) {


    // let userStoreID = userStoreID
    // const isPlusCheckeddata = this.state.infoData.isPlus;
    const subscriptionData = this.state.subscriptionData
    //const subscriptionData = this.state.subscriptionData;

    const upgradeOptions = this.state.upgradeOptions
    const unUpgradeOptions = this.state.unUpgradeOptions;
    const deleteOptions = this.state.deleteOptions
    const deleteOptions1 = this.state.deleteOptions1
    let index
    let indexd
    let tempPlusNoOfStores = parseInt(subscriptionData.tempPlusNoOfStores);
    let tempBasicNoOfStores = parseInt(subscriptionData.tempBasicNoOfStores);

    if (subscriptionData.tempBasicNoOfStores > 99) {

      // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });
      this.handleClickOpen();

    }
    // else if(subscriptionData.tempBasicNoOfStores === NaN) {
    //   this.setState({ snackOpen: true, snackMSg: 'Please enter Market count.' });
    // }
    else if (subscriptionData.tempBasicNoOfStores < 0) {
      this.setState({ snackOpen: true, snackMSg: 'Cannot proceed with ' + this.state.countryCurrency + '0 cost. Please enter location count.' });

    }
    else if (subscriptionData.tempBasicNoOfStores === 1) {
      if (event.target.checked && checkOption === 'delete') {

        this.state.disablecheckbox = true;
        deleteOptions.push(+userStoreID)
        deleteOptions1.push(+userStoreID)



        index = deleteOptions.indexOf(+userStoreID)
        let index1 = upgradeOptions.indexOf(+userStoreID)
        if (index1 >= 0) {
          upgradeOptions.splice(index1, 1)
          tempPlusNoOfStores = tempPlusNoOfStores - 1;
        }
        else {
          if (this.state.infoData.length === deleteOptions.length) {
            tempBasicNoOfStores = 0
          }
          else if (tempBasicNoOfStores < this.state.infoData.length) {
            tempBasicNoOfStores = this.state.infoData.length - deleteOptions.length;
          }
          else {
            tempBasicNoOfStores = tempBasicNoOfStores - 1;
          }
        }

        // this.setState({ tempBasicNoOfStores: 1 });

      }
      else if (!event.target.checked && checkOption === 'delete') {

        index = deleteOptions.indexOf(+userStoreID)
        indexd = deleteOptions1.indexOf(+userStoreID)
        deleteOptions.splice(index, 1)
        if (userStoreID > 0) {
          deleteOptions1.splice(indexd, 1)
        }
        let index1 = upgradeOptions.indexOf(+userStoreID)
        if (index1 === undefined || index1 >= 0) {
          upgradeOptions.push(+userStoreID)
          tempPlusNoOfStores = tempPlusNoOfStores + 1;
        }
        else {
          if (this.state.infoData.length === deleteOptions.length) {
            tempBasicNoOfStores = 0
          }
          else if (tempBasicNoOfStores < this.state.infoData.length) {
            tempBasicNoOfStores = this.state.infoData.length - deleteOptions.length;
          }
          else {
            tempBasicNoOfStores = tempBasicNoOfStores + 1;
          }
        }
      }

    }
    else if (subscriptionData.tempBasicNoOfStores === 99) {
      if (event.target.checked && checkOption === 'delete') {
        this.state.disablecheckbox = true;
        deleteOptions.push(+userStoreID)
        if (userStoreID > 0) {
          deleteOptions1.push(+userStoreID)
        }


        index = deleteOptions.indexOf(+userStoreID)
        let index1 = upgradeOptions.indexOf(+userStoreID)
        if (index1 >= 0) {
          upgradeOptions.splice(index1, 1)
          tempPlusNoOfStores = tempPlusNoOfStores - 1;
        }
        else {
          tempBasicNoOfStores = tempBasicNoOfStores - 1;
        }

      }
      else if (!event.target.checked && checkOption === 'delete') {
        // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });
        this.handleClickOpen();

      }

    }
    else if (subscriptionData.tempBasicNoOfStores > 1 || subscriptionData.tempBasicNoOfStores < 99) {

      // if (event.target.checked && checkOption === 'upgrade') {
      //   this.state.disablecheckbox = true;
      //   upgradeOptions.push(+userStoreID)
      //   tempPlusNoOfStores = tempPlusNoOfStores + 1;
      //   tempBasicNoOfStores = tempBasicNoOfStores - 1;
      // }
      // else if (!event.target.checked && checkOption === 'upgrade') {
      //   index = upgradeOptions.indexOf(+userStoreID)
      //   upgradeOptions.splice(index, 1)
      //   unUpgradeOptions.push(+userStoreID)
      //   tempPlusNoOfStores = tempPlusNoOfStores - 1;
      //   tempBasicNoOfStores = tempBasicNoOfStores + 1;
      // }
      if (event.target.checked && checkOption === 'delete') {
        this.state.disablecheckbox = true;
        deleteOptions.push(+userStoreID)
        if (userStoreID > 0) {
          deleteOptions1.push(+userStoreID)
        }


        index = deleteOptions.indexOf(+userStoreID)
        let index1 = upgradeOptions.indexOf(+userStoreID)
        if (index1 >= 0) {
          upgradeOptions.splice(index1, 1)
          tempPlusNoOfStores = tempPlusNoOfStores - 1;
        }
        else {
          if (this.state.infoData.length === deleteOptions.length) {
            tempBasicNoOfStores = 0
          }
          else if (tempBasicNoOfStores < this.state.infoData.length) {
            tempBasicNoOfStores = this.state.infoData.length - deleteOptions.length;
          }
          else {
            tempBasicNoOfStores = tempBasicNoOfStores - 1;
          }
        }
      }
      else if (!event.target.checked && checkOption === 'delete') {

        index = deleteOptions.indexOf(+userStoreID)
        indexd = deleteOptions1.indexOf(+userStoreID)
        deleteOptions.splice(index, 1)
        if (userStoreID > 0) {

          deleteOptions1.splice(indexd, 1)

        }
        let index1 = upgradeOptions.indexOf(+userStoreID)
        if (index1 === undefined || index1 >= 0) {
          upgradeOptions.push(+userStoreID)
          tempPlusNoOfStores = tempPlusNoOfStores + 1;
        }
        else {
          if (this.state.infoData.length === deleteOptions.length) {
            tempBasicNoOfStores = 0
          }
          else if (tempBasicNoOfStores < this.state.infoData.length) {
            tempBasicNoOfStores = this.state.infoData.length - deleteOptions.length;
          }
          else {
            tempBasicNoOfStores = tempBasicNoOfStores + 1;
          }

        }
      }

    }
    subscriptionData.tempPlusNoOfStores = tempPlusNoOfStores;
    subscriptionData.tempBasicNoOfStores = tempBasicNoOfStores;
    this.updateprice();
    this.setState({ upgradeOptions, unUpgradeOptions, tempPlusNoOfStores, tempBasicNoOfStores, subscriptionData, deleteOptions, deleteOptions1, isPlusChecked: !this.state.isPlusChecked });
    let saveBtn = this.state.saveBtn
    if (this.state.fixedTempBasicNoOfStores === tempBasicNoOfStores && this.state.userUserType !== 2 && this.state.userStatus === 1) {
      saveBtn = true
    } else {
      saveBtn = false
    }
    this.setState({
      saveBtn
    })
  }
  updateprice() {
    const subscriptionData = this.state.subscriptionData
    let nos = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;
    let plusCount = subscriptionData.tempPlusNoOfStores ? parseInt(subscriptionData.tempPlusNoOfStores) : 0;
    let basecount = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;
    let base_total = 0;
    let totalValue = this.state.totalValue;
    let marketPlusRate = 0;
    let Market_price = 0;
    if (nos === 0) {
      Market_price = 0;
    }
    else if (nos === 1) {
      Market_price = this.state.p_singleMarket.filter(function (data) {
        return nos === data.noOfStores && data.userType === 1

      });
      marketPlusRate = Market_price[0].regularPrice + 20;
      let basecountPrice = Market_price[0].regularPrice * basecount;
      let plusCountPrice = marketPlusRate * plusCount;
      base_total = plusCountPrice + basecountPrice;
      base_total = base_total - ((base_total / 100) * Market_price[0].discountPercentage);
    }
    else {
      Market_price = this.state.p_multiMarket.filter(function (data) {
        return nos <= data.noOfStores && data.userType === 1

      });
      marketPlusRate = Market_price[0].regularPrice + 20;
      let basecountPrice = Market_price[0].regularPrice * basecount;
      let plusCountPrice = marketPlusRate * plusCount;
      base_total = plusCountPrice + basecountPrice;
      base_total = base_total - ((base_total / 100) * Market_price[0].discountPercentage);
    }
    this.setState({ Market_price, totalValue: base_total, marketPlusRate })
  }

  saveSubscription(event, errors, values) {

    let formvalid = false;
    formvalid = this.ValidateCard();

    if (formvalid && values.cardName !== '') {
      this.setState({ errCard: '' });
      this.setState({
        isLoading1: true
      })
      const subscriptionData = this.state.storeData.info.userInfo;
      var getPaymonthyear = this.state.expiry.toString().split('/');
      const data = {
        "UserType": this.state.userUserType,
        "TempNoOfStores": subscriptionData.tempNoOfStores,
        "TempBasicNoOfStores": this.state.tempBasicNoOfStores,
        // "TempPlusNoOfStores": this.state.tempPlusNoOfStores,
        // "UpgradeStoreIDs": this.state.upgradeOptions.toString(),
        "DeleteStoreIDs": this.state.deleteOptions1.toString(),
        "RegionId": CONFIG.RegionId,
        PaymentDetail: {
          "CardType": "visa",
          "CardNumber": this.state.cardNumber,
          "ExpiryMonth": getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : null,
          "ExpiryYear": getPaymonthyear.length > 0 ? parseInt('20'.concat(getPaymonthyear[1].trim())) : null,
          "CardName": this.state.cardName,
          "SecurityCode": this.state.cvv,
          "IsDefault": true
        }


      }
      this.setState({
        curSymb: CONFIG.Currency_Symbol
      })
      // axios.get(CONFIG.API_URL + 'home/region')
      //   .then(response => {
      //     let curSymb = response.data.region.currencySymbol;
      //     this.setState({
      //       curSymb
      //     })
      //   });

      let token = localStorage.getItem('accessKey');
      if (token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
      }
      axios.put(CONFIG.API_URL + 'account/updatesubscription', data)
        .then(res => {
          const getTokvalues = res.data.data;
          this.setState({ getTokvalues });
          if (res.data.message) {
            localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
          } else {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
            localStorage.setItem('accessKey', getTokvalues.token);
            localStorage.setItem('getNames', getTokvalues.firstName);
            localStorage.setItem('isPaid', getTokvalues.isPaid);
            localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
            localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
            localStorage.setItem('userType', getTokvalues.userType);
            localStorage.setItem('userStatus', getTokvalues.status);
            // localStorage.setItem('curSymb', this.state.curSymb);
            localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
            localStorage.setItem('userManual', getTokvalues.isManual);
            localStorage.setItem('displayMessage', getTokvalues.displayMessage);
          }
          // const infoData = res.data;
          // this.state.editaccountmodal = false;
          window.scrollTo(0, 0);
          this.componentDidMount();
          this.setState({
            isLoading1: false, updateSubscripConfirmation: true,
            open: false
          });
          // window.location.reload();
        })
        .catch(err => {
          if (err.response.data.Message !== 'Payment Failed') {
            this.setState({ errCard: err.response.data.Message, isLoading1: false });
          }
          else {
            this.setState({ snackOpen: true, snackMSg: 'Payment Failed', errCard: '' });
          }
          this.setState({ isLoading1: false });
        });
    }
  }


  ValidateCard() {
    let formvalid = false;
    if (!this.state.cardNumber || !this.state.cvv || !this.state.expiry) {
      this.setState({ errCard: "Please enter missing card details" })
    }
    else if (this.state.expiry) {
      //Expiry date check for Card
      var getPaymonthyear = this.state.expiry.toString().split('/');
      let ExpiryMonth = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
      let ExpiryYear = getPaymonthyear.length > 1 ? getPaymonthyear[1].trim() : '';
      var CurrentMonth = new Date().getMonth() + 1;
      var CurrentYear = new Date().getFullYear().toString().substr(-2);
      if (CurrentYear > ExpiryYear) {
        this.setState({ errCard: 'Please enter valid expiry year' });
      }
      else {
        if (CurrentYear === ExpiryYear) {
          if (CurrentMonth > ExpiryMonth || ExpiryMonth > 12) {
            this.setState({ errCard: 'Please enter valid expiry month' });
          } else {
            if (this.state.cvv) {
              if (this.state.cvv.length === 3 || this.state.cvv.length === 4) {
                this.setState({ errCard: '' });
                formvalid = true;
              } else {
                this.setState({ errCard: 'Please enter valid CVV' });
              }
            }
          }
        }
        else {
          if (ExpiryMonth > 12) {
            this.setState({ errCard: 'Please enter valid expiry month' });
          } else {
            if (this.state.cvv) {
              if (this.state.cvv.length === 3 || this.state.cvv.length === 4) {
                this.setState({ errCard: '' });
                formvalid = true;
              } else {
                this.setState({ errCard: 'Please enter valid CVV' });
              }
            }
          }
        }
      }

    }
    return formvalid;
  }


  handleChange(e) {
    if (e.target.name != "cardName") {
      this.setState({ [e.target.name]: e.target.value, errCard: '' }, () => { this.ValidateCard() });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }
  subHandleChange(e) {
    let deleteOptions1 = this.state.deleteOptions1;
    deleteOptions1 = []
    let deleteOptions = this.state.deleteOptions;
    deleteOptions = []
    this.setState({
      deleteOptions1, deleteOptions
    })

    if (parseInt(e.target.value) > 99) {
      // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });
      let infoData = this.state.infoData;
      let infoDataStore = this.state.infoDataStore;
      let infoDatalen = e.target.value.length === 3 ? 99 : parseInt(e.target.value);
      this.setState({
        infoDatalen
      })
      var infoDatafilter = [];
      if (this.state.infoDataStoreLength <= infoDatalen) {
        for (var s = 0; s < infoDatalen; s++) {
          var inlineData = {
            address: s <= infoData.length - 1 ? infoData[s].address : 'Not yet entered',
            isDefaultStore: s <= infoData.length - 1 ? infoData[s].isDefaultStore : 'Not yet entered',
            isPlus: s <= infoData.length - 1 ? infoData[s].isPlus : 'Not yet entered',
            storeName: s <= infoData.length - 1 ? infoData[s].storeName : 'Not yet entered',
            storeNo: s <= infoData.length - 1 ? infoData[s].storeNo : - + s,
            userStoreID: s <= infoData.length - 1 ? infoData[s].userStoreID : - + s,
            zoneCoverage: s <= infoData.length - 1 ? infoData[s].zoneCoverage : 'Not yet entered',
            zoneCoverageLink: s <= infoData.length - 1 ? infoData[s].zoneCoverageLink : 'Not yet entered'


          }
          infoDatafilter.push(inlineData);
        }
        this.setState({ infoData: infoDatafilter });
      }

      let tempBasicNoOfStores = this.state.tempBasicNoOfStores;
      tempBasicNoOfStores = e.target.value === "" ? 0 : parseInt(e.target.value);
      const subscriptionData = this.state.subscriptionData;
      subscriptionData[e.target.name] = e.target.value === "" ? 0 : parseInt(e.target.value);
      if (e.target.value.length > 3) {
        e.target.value = e.target.value.substring(0, Math.min(e.target.value.length, 3));


      }
      this.setState({
        tempBasicNoOfStores,
        [e.target.name]: e.target.value, subscriptionData
      })
      this.handleClickOpen();
    }
    else if (parseInt(e.target.value) === 0) {
      this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Above 0.' });

    }
    else {
      let infoData = this.state.infoData;
      let infoDataStore = this.state.infoDataStore;
      let infoDatalen = e.target.value === "" ? 0 : parseInt(e.target.value)
      this.setState({
        infoDatalen
      })
      var infoDatafilter = [];
      if (this.state.infoDataStoreLength <= infoDatalen) {
        for (var s = 0; s < infoDatalen; s++) {
          var inlineData = {
            address: s <= infoData.length - 1 ? infoData[s].address : 'Not yet entered',
            isDefaultStore: s <= infoData.length - 1 ? infoData[s].isDefaultStore : 'Not yet entered',
            isPlus: s <= infoData.length - 1 ? infoData[s].isPlus : 'Not yet entered',
            storeName: s <= infoData.length - 1 ? infoData[s].storeName : 'Not yet entered',
            storeNo: s <= infoData.length - 1 ? infoData[s].storeNo : - + s,
            userStoreID: s <= infoData.length - 1 ? infoData[s].userStoreID : - + s,
            zoneCoverage: s <= infoData.length - 1 ? infoData[s].zoneCoverage : 'Not yet entered',
            zoneCoverageLink: s <= infoData.length - 1 ? infoData[s].zoneCoverageLink : 'Not yet entered'


          }
          infoDatafilter.push(inlineData);
        }
        this.setState({ infoData: infoDatafilter });
      }
      else {
        this.setState({
          infoData: infoDataStore
        })
      }
      const subscriptionData = this.state.subscriptionData;
      subscriptionData[e.target.name] = e.target.value === "" ? 0 : parseInt(e.target.value);
      let nos = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;

      if (nos > 99) {
        // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });
        let tempBasicNoOfStores = this.state.tempBasicNoOfStores;
        tempBasicNoOfStores = e.target.value === "" ? 0 : parseInt(e.target.value);
        this.setState({
          tempBasicNoOfStores
        })
        this.handleClickOpen();

      }
      else if (nos < 0) {
        this.setState({ snackOpen: true, snackMSg: 'Cannot proceed with ' + this.state.countryCurrency + '0 cost. Please enter location count.' });

      }
      // else if (nos === 0) {
      //   this.setState({ snackOpen: true, snackMSg: 'Minimum Market Count is 1' });

      // }
      // else if (nos === 100) {
      //   this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });

      // }

      else if (nos > 1 || nos < 99) {

        let tempBasicNoOfStores = this.state.tempBasicNoOfStores;
        tempBasicNoOfStores = e.target.value === "" ? 0 : parseInt(e.target.value);
        this.setState({
          tempBasicNoOfStores
        })
        let saveBtn = this.state.saveBtn
        if (this.state.fixedTempBasicNoOfStores === tempBasicNoOfStores && this.state.userUserType !== 2 && this.state.userStatus === 1) {
          saveBtn = true
        } else {
          saveBtn = false
        }

        this.setState({
          saveBtn
        })


        this.setState({ [e.target.name]: e.target.value, subscriptionData });
        nos = (subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0) + (subscriptionData.tempPlusNoOfStores ? parseInt(subscriptionData.tempPlusNoOfStores) : 0);
        let plusCount = subscriptionData.tempPlusNoOfStores ? parseInt(subscriptionData.tempPlusNoOfStores) : 0;
        let basecount = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;
        let base_total = 0;
        let totalValue = this.state.totalValue;
        let marketPlusRate = 0;
        let Market_price = 0;
        if (nos === 0) {
          Market_price = 0;
        }
        else if (nos === 1) {
          Market_price = this.state.p_singleMarket.filter(function (data) {
            return nos === data.noOfStores && data.userType === 1

          });
          marketPlusRate = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount + Market_price[0].plus;
          let basecountPrice = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount;
          let plusCountPrice = marketPlusRate * plusCount;
          let discount = Market_price[0].discountPercentage;
          base_total = plusCountPrice + basecountPrice;
          base_total = base_total - ((base_total / 100) * discount);
        }
        else {
          Market_price = this.state.p_multiMarket.filter(function (data) {
            return nos <= data.noOfStores && data.userType === 1

          });
          marketPlusRate = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount + Market_price[0].plus;
          let basecountPrice = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount;
          let plusCountPrice = marketPlusRate * plusCount;
          let discount = Market_price[0].discountPercentage;
          base_total = plusCountPrice + basecountPrice;
          base_total = base_total - ((base_total / 100) * discount);

        }




        this.setState({ Market_price, totalValue: base_total, marketPlusRate });
      }

    }



  }

  checkboxCheck(event) {
    let totalValue = 0
    if (this.state.isChecked) {
      totalValue = 0
    }
    else {
      totalValue = 1000
    }


    this.setState({ isChecked: !this.state.isChecked, totalValue })
  }
  accountModal() {

    this.setState(({ aModel }) => ({ aModel: !aModel }));
  }
  accountModalClose() {

    this.setState(({ aModel1 }) => ({ aModel1: !aModel1 }));
  }
  toggle() {

    const subscriptionData = this.state.subscriptionData;
    let nos = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;
    // if (this.state.fixedTempBasicNoOfStores === nos && this.state.deleteOptions1.length === 0) {
    //   this.setState({ snackOpen: true, snackMSg: 'Your Market same' });
    // }
    if (nos > 99) {
      // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });
      this.handleClickOpen();

    }
    else if (nos < (this.state.unUpgradeOptions.length - this.state.deleteOptions1.length)) {
      this.setState({ snackOpen: true, snackMSg: 'Please select the market(s) from the list which you wish to delete from your account.' });

    }
    else if (nos < 1) {
      this.setState({ snackOpen: true, snackMSg: 'Cannot proceed with ' + this.state.countryCurrency + '0 cost. Please enter location count.' });

    }
    else if (nos > 1 || nos < 99) {
      this.setState(({ open }) => ({ open: !open, errCard: '', areyouSure: true, cardName: '', cardNumber: '', expiry: '', cvv: '' }));
    }
    // if(parseInt(this.state.tempBasicNoOfStores) === this.state.unUpgradeOptions.length && parseInt(this.state.tempPlusNoOfStores) === this.state.upgradeOptions.length) {

    // }
    // else {
    //   alert('Please check your stores')
    // }

    // this.setState(({ open }) => ({ open: !open }));

  }
  formsub_modal_toggle(e) {
    e.preventDefault();
    const subscriptionData = this.state.subscriptionData;
    let nos = subscriptionData.tempBasicNoOfStores ? parseInt(subscriptionData.tempBasicNoOfStores) : 0;
    if (this.state.fixedTempBasicNoOfStores === nos && this.state.deleteOptions1.length === 0) {
      this.setState({ snackOpen: true, snackMSg: 'No Changes are done. Please update your subscription.' });
    }
    else if (nos > 99) {
      this.handleClickOpen();
      // this.setState({ snackOpen: true, snackMSg: 'Your Market Count Should be Below 100.' });

    }
    else if (nos === 0) {
      this.setState({ snackOpen: true, snackMSg: 'Cannot proceed with ' + this.state.countryCurrency + '0 cost. Please enter location count..' });

    }
    else if (nos < (this.state.unUpgradeOptions.length - this.state.deleteOptions1.length)) {
      this.setState({ snackOpen: true, snackMSg: 'Please select the market(s) from the list which you wish to delete from your account.' });

    }
    else if (nos < 1) {
      this.setState({ snackOpen: true, snackMSg: 'Cannot proceed with ' + this.state.countryCurrency + '0 cost. Please enter location count.' });

    }
    else if (nos > 1 || nos < 99) {
      this.setState(({ open }) => ({ open: !open, errCard: '', areyouSure: true, cardName: '', cardNumber: '', expiry: '', cvv: '' }));
    }
  }

  handleSelectChange({ target: { value } }) {
    this.setState({ focusAfterClose: JSON.parse(value) });
  }

  componentDidMount() {
    let countryCurrency = CONFIG.Currency_Symbol;
    this.setState({
      isLoading: true, countryCurrency
    })
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    // axios.post(CONFIG.API_URL + 'account/userpricedetails')
    axios.post(CONFIG.API_URL + 'account/userpricedetails/' + CONFIG.RegionId)
      .then(response => {
        // let userPriceData = response.data.userPrice.userPriceList;

        let priceData = response.data.userPrice.userPriceList;
        // console.log(priceData);

        let p_singleMarket = priceData.filter(function (data) {
          return data.userType === 1 && data.noOfStores === 1
        });

        let p_multiMarket = priceData.filter(function (data) {
          return data.userType === 1 && data.noOfStores !== 1
        });

        let p_unLimited = priceData.filter(function (data) {
          return data.userType === 4
        });

        this.setState({
          p_singleMarket, p_multiMarket, p_unLimited
        }, () => { this.api_call() });

      })
      .catch((err) => {
        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  api_call() {
    // axios.post(CONFIG.API_URL + 'Account/getuserinfo')
    axios.post(CONFIG.API_URL + 'Account/getuserinfo/' + CONFIG.RegionId)
      .then(response => {
        let storeData = response.data;
        let storeDataEdit = response.data.info.userInfo;
        let subscriptionData = response.data.info.userInfo;
        const subdata = response.data.info.subscription;
        const infoData = response.data.info.storeDetails;
        const infoDataStoreLength = infoData.length;
        const infoDataStore = response.data.info.storeDetails;
        let infoEditData = response.data.info.storeDetails;
        const tempPlusNoOfStores = subscriptionData.tempPlusNoOfStores;
        const tempBasicNoOfStores = subscriptionData.tempBasicNoOfStores;
        const fixedTempBasicNoOfStores = subscriptionData.tempBasicNoOfStores;
        let UserType = response.data.info.userInfo.userType;
        let couponCode = response.data.info.userInfo.couponCode;
        let nextBillingDate = response.data.info.subscription != null ? response.data.info.subscription.nextBillingDate : '';
        //let isChecked = UserType === 4 ? true : false;
        let upgradeOptions = [];
        let unUpgradeOptions = [];
        let totalValue = subdata && subdata.totalPrice != '' ? subdata.totalPrice : '';
        let deleteOptions = [];
        let userStatus = response.data.info.userInfo.status;
        let userUserType = response.data.info.userInfo.userType;
        let saveBtn = this.state.saveBtn;
        if (userUserType === 2 || userStatus !== 1 || localStorage.getItem('isPaid') === '0') {
          saveBtn = false
        }
        // if (isPaid === 0) {
        //   saveBtn = false
        // }


        var infoDatafilter = [];
        for (var s = 0; s < tempBasicNoOfStores; s++) {
          var inlineData = {
            address: s <= infoData.length - 1 ? infoData[s].address : 'Not yet entered',
            isDefaultStore: s <= infoData.length - 1 ? infoData[s].isDefaultStore : 'Not yet entered',
            isPlus: s <= infoData.length - 1 ? infoData[s].isPlus : 'Not yet entered',
            storeName: s <= infoData.length - 1 ? infoData[s].storeName : 'Not yet entered',
            storeNo: s <= infoData.length - 1 ? infoData[s].storeNo : - + s,
            userStoreID: s <= infoData.length - 1 ? infoData[s].userStoreID : - + s,
            zoneCoverage: s <= infoData.length - 1 ? infoData[s].zoneCoverage : 'Not yet entered',
            zoneCoverageLink: s <= infoData.length - 1 ? infoData[s].zoneCoverageLink : 'Not yet entered'


          }
          infoDatafilter.push(inlineData);
        }
        this.setState({
          infoData: infoDatafilter, infoDatafilter
        })

        infoData.filter((e) => e.isPlus === true).forEach(function (e) { upgradeOptions.push(e.userStoreID) });
        infoData.filter((e) => e.isPlus === false).forEach(function (e) { unUpgradeOptions.push(e.userStoreID) });
        if (unUpgradeOptions === undefined) {
          unUpgradeOptions = [];
        }
        if (upgradeOptions === undefined) {
          upgradeOptions = [];
        }
        // console.log(infoDatafilter);
        let base_total = 0;
        let marketPlusRate = 0;
        let Market_price = 0;
        let plusCount = subscriptionData ? parseInt(tempPlusNoOfStores) : 0;
        let basecount = subscriptionData ? parseInt(tempBasicNoOfStores) : 0;

        if (this.state.p_singleMarket || this.state.p_multiMarket || this.state.p_unLimited) {
          if (tempBasicNoOfStores === 0) {
            Market_price = 0;
          }
          else if (tempBasicNoOfStores === 1) {
            Market_price = this.state.p_singleMarket.filter(function (data) {
              return tempBasicNoOfStores === data.noOfStores && data.userType === 1

            });
            marketPlusRate = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount + Market_price[0].plus;
            let basecountPrice = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount;
            let plusCountPrice = marketPlusRate * plusCount;
            let discount = Market_price[0].discountPercentage;
            base_total = plusCountPrice + basecountPrice;
            base_total = base_total - ((base_total / 100) * discount);
          }
          else {
            Market_price = this.state.p_multiMarket.filter(function (data) {
              return tempBasicNoOfStores <= data.noOfStores && data.userType === 1

            });
            marketPlusRate = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount + Market_price[0].plus;
            let basecountPrice = Market_price[0].adminAllottedPrice > 0 ? Market_price[0].adminAllottedPrice * basecount : Market_price[0].isFixedPrice ? Market_price[0].price * basecount : Market_price[0].regularPrice * basecount;
            let plusCountPrice = marketPlusRate * plusCount;
            let discount = Market_price[0].discountPercentage;
            base_total = plusCountPrice + basecountPrice;
            base_total = base_total - ((base_total / 100) * discount);

          }
        }






        this.setState({
          Market_price, totalValue: base_total, marketPlusRate, userStatus, userUserType, saveBtn,
          isLoading: false,

          fixedTempBasicNoOfStores,
          infoDataStoreLength,
          infoDataStore,
          couponCode,
          nextBillingDate,
          // totalValue,
          infoEditData, deleteOptions, upgradeOptions, unUpgradeOptions, storeData, storeDataEdit, tempPlusNoOfStores, tempBasicNoOfStores, UserType, subscriptionData
        })
        // console.log(totalValue);
        if (this.state.storeData.info.storeDetails.length > 0) {
          const data1 = {
            "UserStoreID": this.state.storeData.info.storeDetails[0].userStoreID,
            "RegionId": CONFIG.RegionId
          }

          // axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data1)
          //   .then(res => {
          //     let countryCurrency = res.data.data.countryCurrency;
          //     this.setState({ countryCurrency });

          //   })
          //   .catch((err) => {
          //     this.setState({ isLoading: false });
          //     if (err.response.status === 401) {
          //       localStorage.removeItem('accessKey');
          //       const { from } = this.props.location.state || { from: { pathname: "/" } };
          //       this.props.history.push(from);
          //     }
          //     else {
          //       alert(err.response.data);
          //     }
          //   });
        }
      })

      .catch((err) => {

        this.setState({
          isLoading: false
        })

        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }
  handleClickOpen() {
    this.child.current.ModalClickOpen();
  }
  refreshHandler() {
    this.componentDidMount();
  }
  reset() {
    this.setState({
      isLoading: true
    })

    //window.location.reload();
    this.componentDidMount();
    this.setState({
      isLoading: false
    })
    window.scrollTo(0, 0);
    const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
    this.props.history.push(from);
  }



  render() {
    document.title = 'StorTrack: My Account';
    const hidden = this.state.checked ? '' : 'hidden';
    const { isLoading, isLoading1, saveBtn, couponCode, userStatus, userUserType, nextBillingDate, snackMSg, snackOpen, disablecheckbox, upgradeOptions, unUpgradeOptions, totalValue, subscriptionData, deleteOptions, userPriceData, tempPlusNoOfStores, tempBasicNoOfStores, UserType } = this.state;

    const { infoData, cardNumber,
      expiry, storeData, countryCurrency,
      cvv } = this.state;

    return (

      <div className="my-account-box">
        <div id="r2"></div>
        <QuoteMarket parm={this.state.tempBasicNoOfStores} parentMethod={this.refreshHandler} ref={this.child} />
        {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
        {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}

        <Row>
          <Col>
            <Typography component="h2" variant="title" gutterBottom className="breadcrumbs-top">
              My Account
           </Typography>
          </Col>
        </Row>
        <div className="subscription-details">

          <Col>
            <h5 className="top-account1">
              My Account</h5>
            <h5 className="top-account">Subscription Details</h5>

            <div className="Sub">
              <Row>
                <Col md={4} sm={6} className="subscri-list">
                  <Typography variant="subheading" className="subscription-label">
                    Your current subscription</Typography>
                  <p className="bot-5">{storeData && storeData.info.userInfo.subscriptionType ? storeData.info.userInfo.subscriptionType : isLoading === false ? 'N/A' : ''}</p>
                  {/* <p>Stortrack Online, Multi-Market </p> */}
                  {storeData && storeData.info.userInfo.subscriptionType != '7-days Free Trial' ?
                    <p className="monthly-totals">
                      Monthly Total:<label>{countryCurrency}{this.state.totalValue ? this.state.totalValue.toFixed(2) : '00.00'}
                        {/* {storeData && storeData.Subscription ? storeData.Subscription.TotalPrice : 'N/A'} */}
                      </label>
                      {couponCode ? <div className="couponCode-blk">Coupon Applied : <span className="couponCode">{couponCode}</span></div> : ''}
                    </p> : ''}
                </Col>
                <Col md={6} sm={6} className="custom-form-control">
                  <div>
                    <form onSubmit={this.formsub_modal_toggle}>
                      <Input type="number" maxLength="3" name="tempBasicNoOfStores" id="Subid" value={this.state.tempBasicNoOfStores} disabled={this.state.isChecked} onChange={this.subHandleChange} />
                      market{this.state.tempBasicNoOfStores > 1 ? '(s)' : ''} at <label>{isLoading === true ? '':countryCurrency}{this.state.Market_price ? this.state.Market_price[0].regularPrice.toFixed(2) : isLoading === true ? '' : '00.00'}</label>/month {this.state.tempBasicNoOfStores > 1 ? "ea." : ""}

                    </form>
                  </div>
                </Col>
              </Row>
              <h3 className="font-size-14">
                Currently subscribed markets are shown below</h3>

              <div className="Table currently-subscribed" >
                <Table className="table table-bordered custom-table">
                  <TableHead className="">
                    <TableRow className="table-header">
                      <TableCell></TableCell>
                      <TableCell>Market Name</TableCell>
                      <TableCell >Address</TableCell>
                      {/* Here comment the code for as per new requirement */}
                      {/* <TableCell >Upgrade</TableCell> */}
                      <TableCell >Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* infoData.info.storeDetails.storeName address */}
                    {infoData && infoData.length > 0 ? infoData.map((n, index) => {
                      return (
                        <TableRow className="table-body">
                          <TableCell>
                            {index + 1
                              // n.storeNo.split(' ').slice(1).map((str) =>
                              // str)
                            }
                          </TableCell>
                          <TableCell>{n.storeName}</TableCell>
                          <TableCell>{n.address}</TableCell>
                          {/* <TableCell padding="checkbox" className="remove-padding">
                              <Checkbox key={n.userStoreID} id={n.userStoreID}
                                disabled={disablecheckbox && n.userStoreID === deleteOptions.find((e) => e === n.userStoreID) || this.state.isChecked}
                                checked={n.userStoreID === upgradeOptions.find((e) => e === n.userStoreID)} name="updateCheck" onClick={event => this.upgradehandleClick(event, n.userStoreID, 'upgrade')} />
                            </TableCell> */}
                          <TableCell align='center' padding="checkbox" className="remove-padding">
                            {/* {n.userStoreID} */}
                            {/* {n.userStoreID === 0 ? '' : */}
                            <Checkbox key={n.userStoreID} className="track-check-gl" name="deleteCheck" style={{ fontSize: 12 }}
                              //checked={false}
                              checked={n.userStoreID === deleteOptions.find((e) => e === n.userStoreID)}
                              //onClick={event => this.deletehandleClick(event, n.userStoreID)}
                              onClick={event => this.upgradehandleClick(event, n.userStoreID, 'delete')}
                            />
                            {/* } */}
                          </TableCell>
                        </TableRow>
                      );
                    }) :
                      <TableRow>
                        <TableCell colSpan="4" className="txt-cen no-data">
                          {isLoading === false ? 'No Market Found' : ''}

                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            </div>

            <div className="subscription-btm-btns">
              <div className="row">
                {/* {infoData && infoData.length >= 1 ? */}
                <div className="col-md-4">
                  {this.state.userStatus == 1 ?
                    <Link to="#" className="cancel-subs" onClick={this.accountModal} >
                      Cancel Subscription
                </Link>
                    : ''}
                </div>
                {/* : ''} */}

                <div className="Subscription-btn col-md-8">
                  {/* {this.state.fixedTempBasicNoOfStores + '-' + this.state.tempBasicNoOfStores + '-' + this.state.deleteOptions1.length} */}
                  {this.state.saveBtn == true && this.state.deleteOptions1.length === 0 ? '' :
                    <Button className="btn-save" onClick={this.toggle}>
                      Save
          </Button>
                  }
                  {/* {this.state.userUserType + '-' + this.state.userStatus + this.state.saveBtn} */}
                  {/* {
                    this.state.userUserType  === 2 || this.state.userStatus !== 1 ? 
                    <Button className="btn-save" onClick={this.toggle}>
                    Save
        </Button> :
''  
                  } */}
                  {/* {infoData && infoData.length >= 1 ? */}
                  <Button className="reset-pwd" onClick={this.reset.bind(this)}>
                    Cancel
          </Button>
                  {/* : ''} */}
                </div>

              </div>
              <div className="clear"></div>
            </div>
          </Col>
        </div>
        <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.aModel} backdrop="static" className="exists-popup">
          <AvForm onSubmit={this.cancelSubcription.bind(this)}>
            <p className="exist-para">We are sorry to see you leave StorTrack Optimize.</p>
            <p className="exi-para">We appreciate your feedback. Please let us know in a few words your reason(s) for cancelling your subscription, and any feedback that can make StorTrack Optimize better.</p>
            <div className="exists-confirms">
              {/* <input type="text" name ="Remarks" placeholder="remarks" values=""/> */}
              <AvField name="Remarks" label="Remarks" type="textarea"
                value=''
              // validate={{
              //   required: { value: true, errorMessage: 'Remarks is required' }
              // }}
              />
              <div className="login_inline">
                <Link to="#" onClick={this.accountModal} className="edit_cancel btn btn-secondary">Cancel</Link>
                <Button type="submit" className="success-btn">Confirm </Button>
                <div className="clear"></div>
              </div>
              {/* <Link to="/" onClick={this.sigOut.bind(this)} className="confirm-btn" >
                  Confirm
              </Link> */}
            </div>
          </AvForm>
        </Modal>
        <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.aModel1} backdrop="static" className="exists-popup">
          <p className="exist-para1">Your subscription has been cancelled. You will receive an email confirmation soon.</p>
          <h4 className="ext-h4">Thank you for using StorTrack Optimize.</h4>
          <p className="exi-para">We are constantly improving StorTrack Optimize - Please check back with us again. We welcome you back at any time. If you have any questions regarding the cancellation of your subscription please contact us at {CONFIG.Toll_Free} {CONFIG.RegionId === 3 ? "UK Direct tel:" + CONFIG.Sales_Support : ''}.</p>
          <div className="exists-confirms">

            <div className="login_inline iner">
              {/* <Link to="#" onClick={this.accountModalClose} className="edit_cancel btn btn-secondary">Cancel</Link> */}
              <Button onClick={this.sigOut.bind(this)} className="success-btn">Ok </Button>
              <div className="clear"></div>
            </div>

          </div>
        </Modal>
        <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.open} backdrop="static" className="custom-popup card-popup">

          {this.state.areyouSure ?
            <div>
              <ModalHeader>Confirm the following updates to your subscription</ModalHeader>
              <div className="padding-txt pt-0">
                <div className="online-plus"> StorTrack Optimize, Multi-Market
                    <p><span className="right-st-count">{this.state.tempBasicNoOfStores}</span> market(s) at <label>{countryCurrency}{this.state.Market_price ? this.state.Market_price[0].regularPrice.toFixed(2) : 49.00} </label>/month {this.state.tempBasicNoOfStores > 1 ? "ea." : ""}</p>
                </div>
                <p className="monthly-totals pt-1 pb-1">
                  Your New Monthly Total: <span className="b-st-price">{countryCurrency}{this.state.totalValue ? this.state.totalValue.toFixed(2) : 0}</span>
                  {/* {storeData && storeData.Subscription ? storeData.Subscription.TotalPrice : 'N/A'} */}
                </p>

                {/* {nextBillingDate ? <p className="due-txt">Your card will not be charged now. We will charge your credit card the new rate when your monthly subscription is due on <span>{moment(nextBillingDate).format('DD-MMM-YYYY').replace("--", "-")}</span>.</p> : ''} */}
                {nextBillingDate ? <p className="due-txt">Your card will not be charged now. We will charge your credit card the new rate when your monthly subscription is due on <span>{nextBillingDate}</span>.</p> : ''}

                <FormText className='errorMsg' color="red">{this.state.errCard}</FormText>
              </div>
              <div className="subscription-details no-border">
                <div className="Subscription-btn">
                  <Button className="success-btn" onClick={this.areyouSure.bind(this)} >
                    Confirm
              </Button>
                  <Button className="edit_cancel" onClick={this.toggle} >Cancel</Button>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            :
            <div>
              <ModalHeader>Payment Details</ModalHeader>
              <AvForm onSubmit={this.saveSubscription.bind(this)}>
                <Col md="12" className="set-btm-mr">
                  <Label>Name on Card</Label>
                  <AvField name="cardName" id="Name" validate={{
                    required: { value: true, errorMessage: 'Please enter the card name' }
                  }} onChange={this.handleChange} value={this.state.cardName} />
                </Col>
                <Col md="12" className="card-fields">
                  <Label>Card Credentials</Label>
                  <CreditCardInput
                    cardNumberInputProps={{ value: this.state.cardNumber, name: "cardNumber", placeholder: 'Card Number', onChange: this.handleChange }}
                    cardExpiryInputProps={{ value: this.state.expiry, name: "expiry", placeholder: 'MM/YY', onChange: this.handleChange }}
                    cardCVCInputProps={{ value: this.state.cvv, name: "cvv", placeholder: 'CVV', onChange: this.handleChange }}
                    fieldClassName={this.state.errCard !== '' ? "input is-invalid" : "input"}
                  />
                  <FormText className='errorMsg' color="red">{this.state.errCard}</FormText>
                </Col>

                <div className="subscription-details no-border">
                  <div className="Subscription-btn">

                    <Button type="submit" className="success-btn">Confirm</Button>
                    <Button className="edit_cancel" onClick={this.toggle}>Cancel</Button>
                  </div>
                  <div className="clear"></div>
                </div>
              </AvForm>
            </div>
          }

        </Modal>

        <div>
          <Modal isOpen={this.state.updateSubscripConfirmation} className={this.props.className + ' con-pop-up'}>
            <ModalHeader toggle={this.toggleConfirmation}></ModalHeader>
            <ModalBody className="text-center">
              Your subscription has been updated successfully.
            </ModalBody>
            <ModalFooter>
              <Link to="/my-market/" onClick={() => this.updateMenu()} className="btn-save btn btn-secondary">Go to My Markets</Link>
            </ModalFooter>
          </Modal>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackOpen}
          className="snackbar bg-color-primary custom-snackbar"
          maxsnack={3}
          autoHideDuration={30000}
          onClose={this.snackHandleclose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackMSg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.snackHandleclose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div >
    );
  }
}
