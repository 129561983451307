import React, { Component } from "react";
import "./EffectiveRatesPdf.css";
import { Row, Col, Button, Nav, NavItem, NavLink } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Typography from "@material-ui/core/Typography";
import { Table as ReactTable } from "reactstrap";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { HelpTextPopup } from "../../HelpTextPopup/index";
import * as d3 from "d3";
import axios from "axios";
import { CONFIG } from "../../../utils/config";
import addtomap from "../../../Assets/Images/plus.png";
import removefrommap from "../../../Assets/Images/trash.png";
import tableplus from "../../../Assets/Images/table-plus.png";
import tableminus from "../../../Assets/Images/table-minus.png";
import downarrows from "../../../Assets/Images/down-arrow.png";
import Tooltip from "@material-ui/core/Tooltip";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import Logo from "../../../Assets/Images/logo.jpg";
import Table from "@material-ui/core/Table";
import { TableBody } from "@material-ui/core";

export default class EffectiveRatesPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      isLoading1: false,
      order: this.props.order,
      orderBy: this.props.orderby,
      snackOpen: false,
      snackMSg: "",
      unitDurations: [],
      hisactivemenu: this.props.hisactivemenu.toString(),
      hisactivedur: this.props.hisactivedur.toString(),
      gridactivedur: this.props.gridactivedur.toString(),
      uniquestore: "",
      grapghCatcheck: this.props.grapghCatcheck,
      graphOpen: this.props.grapghCatcheck,
      // SinglelahMarkets: {},
      CoreMarketView: [],
      CoreMarketViewtab3: [],
      CoreMarketViewtab2: [],
      ChartTableData: [],
      ChartTableDatatab3: [],
      ChartTableDatatab2: [],
      GridTableValue: {},
      CurrentHistory: [],
      AllMarketdata: [],
      AllCurrentHistory: [],
      MarketViewpricingOptions: [],
      MarketViewpricingOptionstab3: [],
      MarketViewpricingOptionstab2: [],
      isLoading: false,
      AvailDataCheck: 0,
      AvailDataChecktab3: 0,
      AvailDataChecktab2: 0,
      stId: this.props.userstoreid,
      seId: this.props.searchId,
      defaultWidth: 0,
      priceLength: 0,
      pricecolLength: 0,
      priceLengthtab3: 0,
      pricecolLengthtab3: 0,
      priceLengthtab2: 0,
      pricecolLengthtab2: 0,
      Tabloading: false,
      promo_open: false,
      promo_open_id: 0,
      accessKey: "",
      locationInfoData: [],
      uniquestores: [],
      storeIdCount: [],
      countrycurrency: "",
      analysisSummary: [],
      uniCheck: "",
      promoCountReached: false,
      needComparePromos: [],
      annexureFilters: [],
      effectiveRateData: null,
      drpDownSelValue: this.props.durFilter,
    };
    this.DurationsdropDown = this.DurationsdropDown.bind(this);
    this.GetGraphTableDatatab4 = this.GetGraphTableDatatab4.bind(this);
    this.tabtoggle = this.tabtoggle.bind(this);
    this.onDurAndPriChange = this.onDurAndPriChange.bind(this);
    localStorage.setItem("StId", this.props.userstoreid);
  }

  //Duration and Price Type Change
  onDurAndPriChange(e) {
    const { name, value } = e.target;
    let fields = this.state;
    fields[name] = value;
    if (name === "gridactivedur") {
      this.setState({ fields, isLoading1: false }, () =>
        this.GetGridtableData()
      );
    } else if (name === "hisactivedur") {
      this.setState({ fields, isLoading: false }, () =>
        this.GetcurrentpricinganalysisHistory()
      );
    } else {
      this.setState({ fields, isLoading1: false }, () =>
        this.AlignHistoryGraphData()
      );
    }
  }

  //Tabs
  tabtoggle() {
    this.GetGraphTableDatatab4();
  }

  //SnackBar Close
  snackHandleclose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  async UNSAFE_componentWillMount() {
    const data = {
      SecretKey: this.props.accessKey,
      RegionId: CONFIG.RegionId,
    };

    const response = await axios.post(
      CONFIG.API_URL + "account/secretlogin",
      data
    );
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.initialLoad();
      this.currentPricingAnalysis();
    } else {
      alert(response.status.Message);
    }
  }
  currentPricingAnalysis() {
    this.setState(
      {
        isLoading: false,
        hisactivedur: this.state.hisactivedur,
        hisactivemenu: this.state.hisactivemenu,
      },
      () => {
        this.commonapiCall();
      }
    );
  }

  commonapiCall() {
    this.loadDashBoard();
    this.DurationsdropDown();
    this.GetGraphTableDatatab4();
    // this.GetGraphTableData();
    // this.GetcurrentpricinganalysisHistory();
    // this.tabtoggle();
    // //this.GetSummaryMarketData();
    // this.GetGridtableData();
    // this.GetcurrentpricinganalysisHistory();
    // this.loadSummary();
  }
  loadSummary() {
    let token = this.state.accessKey;
    const data = {
      UserStoreID: this.state.stId,
      SearchID: this.state.seId,
      RegionID: CONFIG.RegionId,
    };
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "dashboard/currentpricinganalysissummary", data)
      .then((response) => {
        // this.state.isLoading=true;
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      UserStoreID: this.state.stId,
      SearchID: this.state.seId,
      RegionID: CONFIG.RegionId,
    };
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .post(CONFIG.API_URL + "unit/dashboardSearchlocation", data)
      .then((response) => {
        // this.state.isLoading=true;
        const locationInfoData = response.data.data.unitDashboard;
        let countrycurrency = response.data.data.unitDashboard.countryCurrency;
        this.setState({
          locationInfoData: locationInfoData,
          countrycurrency,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else if (err.response.status === 403) {
          const { from } = this.props.location.state || {
            from: { pathname: "/UnauthorizedAccess" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.searchId !== this.state.seId) {
      this.setState(
        {
          hisactivedur: "2",
          seId: nextProps.searchId,
          stId: nextProps.storeId,
        },
        () => {
          this.currentPricingAnalysis();
        }
      );
    }
  }

  //Duration For DropDown
  DurationsdropDown() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    axios
      .get(CONFIG.API_URL + "unit/unitdurations")
      .then((response) => {
        let unitDurations = response.data.data.lookupData;
        this.setState({
          unitDurations,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  //effective rate price
  GetGraphTableDatatab4() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    let data = {
      UserStoreID: this.state.stId,
      SearchId: this.state.seId,
      PriceType: 4,
      Duration: parseInt(this.state.gridactivedur),
      Competitors:
        this.state.uniCheck == "no"
          ? this.state.uniquestore
          : this.state.uniquestores
          ? this.state.uniquestores
          : 0,
      RegionId: CONFIG.RegionId,
    };
    axios
      .post(
        CONFIG.API_URL + "dashboard/currentpricinganalysiscoremarketview",
        data
      )
      .then((res) => {
        let CoreMarketViewtab4 = res.data.current.currentPricingAnalysisMarket;
        let effectiveRateData = [];
        CoreMarketViewtab4.map((x) => {
          const found = effectiveRateData.some(el => el.storeID === x.storeID);
       
          return(
            effectiveRateData.push({storeID: x.storeID,
              address: found ? '' : x.address,
              storeName: found ? '' : x.storeName,
              priceOptionName: x.priceOptionName,
              price: x.price,
              isMyStore: x.isMyStore,
              alternatePriceTag: x.alternatePriceTag,
              proximity: x.proximity,
              myStorePriceRank: x.myStorePriceRank,
              promo: x.promo,
              isMonthly: x.isMonthly,
              floorType: x.floorType,
              advertisedPrice: x.advertisedPrice,
              promotion: x.promotion,
              m1: x.m1,
             m2: x.m2,
              m3: x.m3,
              m4: x.m4,
              m5: x.m5,
              m6: x.m6,
              m7: x.m7,
              m8: x.m8,
              m9: x.m9,
              m10: x.m10,
              m11: x.m11,
              m12: x.m12,})
          )
        })
        this.setState(
          {
            effectiveRateData: effectiveRateData, //CoreMarketViewtab2, MarketViewpricingOptionstab2,
            isLoading1: false,
            Tabloading: false,
          },
          () => {
            //  this.AlignGraphTabDatatab2();
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("accessKey");
          const { from } = this.props.location.state || {
            from: { pathname: "/" },
          };
          this.props.history.push(from);
        } else {
          alert(err.response.data);
        }
      });
  }

  //Promotions Pop Up
  promohandleOpen(id) {
    this.setState({
      promo_open: true,
      promo_open_id: id,
    });
  }

  promohandleClose() {
    this.setState({
      promo_open: false,
      promo_open_id: 0,
    });
  }
  //End OF PRomotions Pop Up

  initialLoad() {
    let id1, id2, id3, id4, id5, id6, id7;
    let storeIdCount = [];
    let order = this.props.order;
    let orderBy = this.props.orderBy;
    if (this.props.id1 == "no") {
      this.state.uniCheck = "no";
      id1 = 0;
    } else {
      id1 = parseInt(this.props.id1 ? this.props.id1 : 0);
    }
    id2 = parseInt(this.props.id2 ? this.props.id2 : 0);
    id3 = parseInt(this.props.id3 ? this.props.id3 : 0);
    id4 = parseInt(this.props.id4 ? this.props.id4 : 0);
    id5 = parseInt(this.props.id5 ? this.props.id5 : 0);
    id6 = parseInt(this.props.id6 ? this.props.id6 : 0);
    id7 = parseInt(this.props.id7 ? this.props.id7 : 0);
    let storeCount = [id1, id2, id3, id4, id5, id6, id7];
    storeCount.map((data) => {
      if (data != 0) {
        storeIdCount.push(data);
      }
    });
    let uniquestores = storeIdCount.toString();
    this.setState({ uniquestores, storeIdCount, orderBy, order });
  }

  //price conversion
  priceConversion(tot) {
    if (tot !== "" && tot !== null) {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") == -1) {
        tot = tot;
      } else {
        if (checkDigit == "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }

  render() {
    const {
      analysisSummary,
      locationInfoData,
      activeTab,
      promo_open,
      isLoading,
      pricecolLength,
      order,
      orderBy,
      grapghCatcheck,
      graphOpen,
      ChartTableDatatab3,
      AvailDataChecktab3,
      ChartTableDatatab2,
      AvailDataChecktab2,
      pricecolLengthtab3,
      priceLengthtab3,
      pricecolLengthtab2,
      priceLengthtab2,
      ChartTableData,
      /*SinglelahMarkets,*/ GridTableValue,
      unitDurations,
      MarketViewpricingOptions,
      MarketViewpricingOptionstab3,
      MarketViewpricingOptionstab2,
      snackOpen,
      snackMSg,
      AvailDataCheck,
      needComparePromos,
      annexureFilters,
      effectiveRateData,
      drpDownSelValue,
    } = this.state;
    let moneySymbol = this.state.countrycurrency
      ? this.state.countrycurrency
      : "";
    const GridTableValue1 =
      GridTableValue && GridTableValue.dynamdata
        ? GridTableValue.dynamdata.length
        : 0;
    const GridTableValueData = GridTableValue ? GridTableValue.dynamdata : [];
    var hlpcnt;
    if (activeTab === 1) {
      hlpcnt =
        "<h6>" +
        `${
          CONFIG.RegionId === 3
            ? "Current Advertised Weekly Rates"
            : "Current Advertised Rates"
        }` +
        "</h6><p>In this section, the graph enables you to compare rates like-for-like, against your top competitors*.  The rates shown on the graph are based on the Online Advertised Rate on the store's website.</p><p>The Number of Pricing Options shows you if the selected store* offers more than one price type for this unit type.</p><ul><li>The highest price advertised by a store is shown as the Premium rate.</li><li>The lowest price advertised by a store is shown as the Value rate.</li><li>Any other rates in between are shown as a range</li><li>If there is only one price for a unit, it is shown as both the Premium and Value rate</li></ul><p>Market Low, Market Average and Market High</p><p>These rates are based on the online rates advertised by the competitor stores* and the price type chosen (Premium or Value).</p><p>*See the All Market View section below to learn how you can change the competitors you can compare against on this graph.</p><p><i>*Your store prices are included in the calculations.</i></p>";
    } else if (activeTab === 3) {
      hlpcnt =
        "<h6>Promotions</h6><p>These are unit-specific promotions advertised by the store for the above unit type.</p>";
    } else {
      hlpcnt =
        "<h6>" +
        `${
          CONFIG.RegionId === 3
            ? "Advertised Walk-in Weekly Rates"
            : "Advertised Walk-in Rates"
        }` +
        "</h6><p>These are the walk-in (or 'Standard') rates offered by the store for this unit type.</p>";
    }
    const defaultProps = {
      helpTitle: "Core Market View",
      helpContent:
        hlpcnt +
        "<p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/EhEUYS4SMAA' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps1 = {
      helpTitle: "Historical Pricing Analysis",
      helpContent:
        "<p>View historical rates for this unit type over the last 5 days, 30 days, 90 days, 6 months or 1 year, choose the time range from the drop down menu. You can also view rates by pricing type - Premium, Value or both. See the All Market View section below to learn how you can change the competitors you can compare against on this graph. These rates are based on the Advertised Online Rate.</p><p><i>*Your store prices are included in the calculations.</i></p><p>Refer the below help video for more information.</p> <iframe width='472' height='248' src='https://www.youtube.com/embed/ZBHn_uCFJSI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    };
    const defaultProps2 = {
      helpTitle: "All Market View",
      helpContent:
        "<ul><li>These are all the stores in this Market offering this unit type.</li><li>Select up to a total of 8 competitor stores listed below to compare against in the Current Pricing analysis section or Historical pricing view. You can remove any stores from the graph at any time.</li></ul><p><i>*Your store prices are included in the calculations.</i></p>",
    };

    let cutCur = locationInfoData.countryCurrency
      ? locationInfoData.countryCurrency
      : "N/A";
    let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if (clp) {
      var splt_clp = clp.toString().split(".");
      let splt_val_clp = splt_clp[1]
        ? splt_clp[1].length === 1
          ? "." + splt_clp[1] + "0"
          : splt_clp[1].length === 2
          ? "." + splt_clp[1]
          : ""
        : "";
      clp = cutCur + splt_clp[0] + splt_val_clp;
    }
    if (cap) {
      var splt_cap = cap.toString().split(".");
      let splt_val_cap = splt_cap[1]
        ? splt_cap[1].length === 1
          ? "." + splt_cap[1] + "0"
          : splt_cap[1].length === 2
          ? "." + splt_cap[1]
          : ""
        : "";
      cap = cutCur + splt_cap[0] + splt_val_cap;
    }
    if (chp) {
      var splt_chp = chp.toString().split(".");
      let splt_val_chp = splt_chp[1]
        ? splt_chp[1].length === 1
          ? "." + splt_chp[1] + "0"
          : splt_chp[1].length === 2
          ? "." + splt_chp[1]
          : ""
        : "";
      chp = cutCur + splt_chp[0] + splt_val_chp;
    }
    var checkIfAnnexureEmptyStat = [];
    checkIfAnnexureEmptyStat =
      GridTableValue &&
      GridTableValue.statdata &&
      GridTableValue.statdata.filter(function (data) {
        return data.annexure !== 0;
      });
    var checkIfAnnexureEmpty = [];
    checkIfAnnexureEmpty =
      GridTableValue &&
      GridTableValue.dynamdata &&
      GridTableValue.dynamdata.filter(function (data) {
        return data.annexure !== 0;
      });
    // var checkIfAnnexureEmpty = GridTableValue && GridTableValue.dynamdata && GridTableValue.dynamdata.filter(data => data.annexure !== 0);
    let effRateTableValue =
      effectiveRateData && effectiveRateData.length > 0
        ? effectiveRateData.length
        : 0;
    const tr = [...Array(Math.ceil(GridTableValue1 / 21))];
    let rowsDiv =
      drpDownSelValue === 6
        ? 27
        : drpDownSelValue === 9
        ? 20
        : drpDownSelValue === 3
        ? 30
        : 16;
    const effRateRow = [...Array(Math.ceil(effRateTableValue / rowsDiv))];
    const trproductRows1 = effRateRow.map((row, idx1) =>
      effectiveRateData.slice(idx1 * rowsDiv, idx1 * rowsDiv + rowsDiv)
    );
    // console.log(this.state.gridactivedur, 'gridactivedur');
    const nameCount = locationInfoData.storeName
      ? locationInfoData.storeName.length
      : 0;
    let checkPg = null;
    return (
      <div>
        <div className="currentprice currentprice-pdf">
          <div class="header">
            {" "}
            <img src={Logo} />
            <div className="float-right-name">
              <h5>
                {locationInfoData.storeName &&
                locationInfoData.storeName !== null &&
                locationInfoData.storeName !== ""
                  ? nameCount > CONFIG.storeNameMaxLimit
                    ? locationInfoData.storeName.substring(
                        0,
                        CONFIG.storeNameMaxLimit - 3
                      ) + "..."
                    : locationInfoData.storeName
                  : isLoading === false
                  ? "Your Store"
                  : ""}
              </h5>
            </div>
            <div className="float-right">
              <p className="m-0 address-font">
                {locationInfoData.storeAddress1 &&
                locationInfoData.storeAddress1 !== null &&
                locationInfoData.storeAddress1 !== ""
                  ? locationInfoData.storeAddress1 + ", "
                  : "" + locationInfoData && locationInfoData.storeCity
                  ? locationInfoData.storeCity + ", "
                  : locationInfoData.searchType === 1 &&
                    (locationInfoData.storeCity === "" ||
                      locationInfoData.storeCity === null) &&
                    (locationInfoData.storeZipCode === "" ||
                      locationInfoData.storeZipCode === null)
                  ? locationInfoData.latitude +
                    ", " +
                    locationInfoData.longitude +
                    ", "
                  : ""}
                {locationInfoData.storeState
                  ? locationInfoData.storeState + " "
                  : ""}{" "}
                {locationInfoData.storeZipCode}{" "}
                {locationInfoData.storeZoneCoverage
                  ? "| Coverage: " + locationInfoData.storeZoneCoverage
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="footer">
            <a
              href={CONFIG.Website_URL_Footer_Pdf}
              style={{ textDecoration: "none" }}
            >
              {CONFIG.Website_Text_Footer_Pdf}
            </a>{" "}
            | {CONFIG.Phone_Footer_Pdf} |{" "}
            <a
              href={"mailto:" + CONFIG.Mail_Footer_Pdf}
              style={{ textDecoration: "none" }}
            >
              {CONFIG.Mail_Footer_Pdf}
            </a>{" "}
            | <img src={Logo} alt="Optimize Logo" style={{ height: "16px" }} />
          </div>
          {isLoading ? (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          ) : (
            ""
          )}
          {this.state.isLoading1 ? (
            <div className="loader-wrap">
              <div className="loading loading-center"></div>
            </div>
          ) : (
            ""
          )}
          {this.state.Tabloading ? (
            <div className="loader-wrap">
              <div className="loading loading-center"></div>
            </div>
          ) : (
            ""
          )}
          <div item="true" xs={12}>
            {/* <div className="page"> */}
            {/* <div
                className="print-view"
                style={{ width: "100%", paddingLeft: "10px" }}
              >
                <h4 className="mb-3">
                  {"(" + locationInfoData.searchName + ")"} Effective Rate Analysis
                  Analysis
                </h4>
              </div> */}

            {/* effective rate start */}
            {CONFIG.RegionId === 3 ? (
              ""
            ) : trproductRows1 ? (
              trproductRows1.map((rows, i) => {
                return (
                  <>
                    <div className="page">
                      <Row className="m-0">
                        <div className="col-lg-12 col-md-12">
                          <div className="ms_table pd-3 display-grid">
                            {i === 0 ? (
                              <h4 className="mb-3">
                                Effective Rate Analysis
                                <p>
                                  The Effective Rate is the price the consumer
                                  pays after factoring in the promotion. The
                                  calculation is performed over a specific
                                  tenancy length, which can be selected on the
                                  right.
                                </p>
                              </h4>
                            ) : (
                              ""
                            )}
                            {/* {i === 0 ? ( */}
                            <h6 className="mb-1" style={{ display: "block" }}>
                              {"(" + locationInfoData.searchName + ")"}{" "}
                              Effective Rates Analysis
                            </h6>

                            {/* ) : (
                              ""
                            )} */}
                            <Table
                              className="mst all-market-table activity-table"
                              responsive="true"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell colspan="5"></TableCell>
                                  <TableCell style={{ "text-align": "center" }}
                                    colspan={
                                      drpDownSelValue === 0 ||
                                      drpDownSelValue === 12
                                        ? 12
                                        : drpDownSelValue
                                    }
                                  >
                                    Months
                                  </TableCell>
                                  <TableCell colspan="1"></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Store Name</TableCell>
                                  <TableCell>Price Option Name</TableCell>
                                  <TableCell>Floor</TableCell>
                                  <TableCell>Advertised Price</TableCell>
                                  <TableCell>Promotion</TableCell>
                                  <TableCell>1st </TableCell>
                                  <TableCell>2nd </TableCell>
                                  <TableCell>3rd </TableCell>
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 3 ? (
                                    <TableCell>4th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 3 ? (
                                    <TableCell>5th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 3 ? (
                                    <TableCell>6th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 6 ? (
                                    <TableCell>7th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 6 ? (
                                    <TableCell>8th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 6 ? (
                                    <TableCell>9th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 9 ? (
                                    <TableCell>10th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 9 ? (
                                    <TableCell>11th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {drpDownSelValue === 0 ||
                                  drpDownSelValue > 9 ? (
                                    <TableCell>12th </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell style={{ "text-align": "center" }}>
                                    Effective Rate
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows && rows.length > 0 ? (
                                  rows.map((effData, index) => {
                                    if (trproductRows1.length === i + 1) {
                                      checkPg = rows.length;
                                    }
                                    let m1 =
                                      parseFloat(effData.m1).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m1)
                                        : 0;
                                    let m2 =
                                      parseFloat(effData.m2).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m2)
                                        : 0;
                                    let m3 =
                                      parseFloat(effData.m3).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m3)
                                        : 0;
                                    let m4 =
                                      parseFloat(effData.m4).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m4)
                                        : 0;
                                    let m5 =
                                      parseFloat(effData.m5).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m5)
                                        : 0;
                                    let m6 =
                                      parseFloat(effData.m6).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m6)
                                        : 0;
                                    let m7 =
                                      parseFloat(effData.m7).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m7)
                                        : 0;
                                    let m8 =
                                      parseFloat(effData.m8).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m8)
                                        : 0;
                                    let m9 =
                                      parseFloat(effData.m9).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m9)
                                        : 0;
                                    let m10 =
                                      parseFloat(effData.m10).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m10)
                                        : 0;
                                    let m11 =
                                      parseFloat(effData.m11).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m11)
                                        : 0;
                                    let m12 =
                                      parseFloat(effData.m12).toFixed(2) !==
                                      "0.00"
                                        ? parseFloat(effData.m12)
                                        : 0;
                                    let avgM3 = (m1 + m2 + m3) / 3;
                                    let avgM6 =
                                      (m1 + m2 + m3 + m4 + m5 + m6) / 6;
                                    let avgM9 =
                                      (m1 +
                                        m2 +
                                        m3 +
                                        m4 +
                                        m5 +
                                        m6 +
                                        m7 +
                                        m8 +
                                        m9) /
                                      9;
                                    let avgM12 =
                                      (m1 +
                                        m2 +
                                        m3 +
                                        m4 +
                                        m5 +
                                        m6 +
                                        m7 +
                                        m8 +
                                        m9 +
                                        m10 +
                                        m11 +
                                        m12) /
                                      12;
                                    let priceCal =
                                      drpDownSelValue === 3
                                        ? avgM3
                                        : drpDownSelValue === 6
                                        ? avgM6
                                        : drpDownSelValue === 9
                                        ? avgM9
                                        : avgM12;
                                    let advPrice =
                                      parseFloat(
                                        effData.advertisedPrice
                                      ).toFixed(2) === "0.00"
                                        ? effData.advertisedPrice
                                        : this.priceConversion(
                                            effData.advertisedPrice
                                          );
                                    let averagePrice =
                                      parseFloat(priceCal).toFixed(2) === "0.00"
                                        ? priceCal
                                        : this.priceConversion(priceCal);
                                    const labelId = index;
                                    let promotions =
                                      effData.promotion &&
                                      effData.promotion !== null
                                        ? effData.promotion.replace(
                                            /~/g,
                                            "<hr/>"
                                          )
                                        : "No specials";
                                    return (
                                      <TableRow key={effData.storeID}>
                                        {effData.storeName !== '' ? <TableCell 
                                        //  className="S_captalize"
                                          className={effData.isMyStore ? "S_captalize disabled_color" : 'S_captalize'}
                                          align="center"
                                          id={labelId}
                                          scope="row"
                                          padding="none"
                                          rowspan={2}
                                        >
                                          <span className="store-names">
                                            {effData.storeName}
                                          </span>
                                          <span className="stores-address current-pr-store">
                                            {effData.address}
                                          </span>
                                        </TableCell>: ''}
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {effData.priceOptionName}
                                        </TableCell>
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {effData.floorType}
                                        </TableCell>
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {advPrice !== "" && advPrice !== null
                                            ? parseFloat(advPrice).toFixed(
                                                2
                                              ) === "0.00"
                                              ? "free"
                                              : moneySymbol +
                                                advPrice
                                            : ""}
                                        </TableCell>
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: promotions,
                                            }}
                                          />
                                        </TableCell>
                                       <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {effData.m1 !== "" &&
                                          effData.m1 !== null
                                            ? parseFloat(effData.m1).toFixed(
                                                2
                                              ) === "0.00"
                                              ? "free"
                                              : moneySymbol + effData.m1
                                            : ""}
                                        </TableCell>
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {effData.m2 !== "" &&
                                          effData.m2 !== null
                                            ? parseFloat(effData.m2).toFixed(
                                                2
                                              ) === "0.00"
                                              ? "free"
                                              : moneySymbol + effData.m2
                                            : ""}
                                        </TableCell>
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {effData.m3 !== "" &&
                                          effData.m3 !== null
                                            ? parseFloat(effData.m3).toFixed(
                                                2
                                              ) === "0.00"
                                              ? "free"
                                              : moneySymbol + effData.m3
                                            : ""}
                                        </TableCell>
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 3 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m4 !== "" &&
                                            effData.m4 !== null
                                              ? parseFloat(effData.m4).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m4
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 3 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m5 !== "" &&
                                            effData.m5 !== null
                                              ? parseFloat(effData.m5).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m5
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 3 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m6 !== "" &&
                                            effData.m6 !== null
                                              ? parseFloat(effData.m6).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m6
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 6 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m7 !== "" &&
                                            effData.m7 !== null
                                              ? parseFloat(effData.m7).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m7
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 6 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m8 !== "" &&
                                            effData.m8 !== null
                                              ? parseFloat(effData.m8).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m8
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 6 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m9 !== "" &&
                                            effData.m9 !== null
                                              ? parseFloat(effData.m9).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m9
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 9 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m10 !== "" &&
                                            effData.m10 !== null
                                              ? parseFloat(effData.m10).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m10
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 9 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m11 !== "" &&
                                            effData.m11 !== null
                                              ? parseFloat(effData.m11).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m11
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {drpDownSelValue === 0 ||
                                        drpDownSelValue > 9 ? (
                                          <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                            {effData.m12 !== "" &&
                                            effData.m12 !== null
                                              ? parseFloat(effData.m12).toFixed(
                                                  2
                                                ) === "0.00"
                                                ? "free"
                                                : moneySymbol + effData.m12
                                              : ""}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className={effData.isMyStore ? "disabled_color" : ''}>
                                          {averagePrice !== "" &&
                                          averagePrice !== null
                                            ? parseFloat(averagePrice).toFixed(
                                                2
                                              ) === "0.00"
                                              ? "free"
                                              : moneySymbol +
                                                averagePrice
                                            : ""}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      colSpan="6"
                                      className="text-center no-data"
                                    >
                                      {effectiveRateData === null
                                        ? ""
                                        : "No Data Found"}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>
                        </div>
                      </Row>{" "}
                      <div class="printclearfix"></div>
                    </div>
                  </>
                );
              })
            ) : (
              <tbody>
                <tr colSpan="6" className="text-center no-data">
                  {effectiveRateData === null ? "" : "No Data Found"}
                </tr>
              </tbody>
            )}

            {/* effective rate end */}

            {/* </div> */}

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackOpen}
              className="snackbar bg-color-primary custom-snackbar"
              maxsnack={3}
              autoHideDuration={30000}
              onClose={this.snackHandleclose.bind(this)}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{snackMSg}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.snackHandleclose.bind(this)}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
