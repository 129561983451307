import React from 'react';
import './MarketInventoryAnalysisPdf.css';
import Chart from "react-apexcharts";
import { CONFIG } from '../../../utils/config';
import axios from 'axios';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import { MarketProfile } from '../../MarketProfile';
import { Row, Table as TableBoots } from 'reactstrap';
import greenimage from '../../../Assets/Images/green.png';
import redimages from '../../../Assets/Images/red.png';
import Logo from '../../../Assets/Images/logo.jpg';
export default class MarketInventoryAnalysisPdf extends React.Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {

      options: {
        chart: {
          id: 'apexchart-example',
          toolbar: {
            show: false
          }
        },
        grid: { show: false },
        xaxis: {
          labels: {
            show: false,

          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: { show: false },
      },
      series: [{
        name: 'Store offered this Unit Type',
        data: []
      }],
      unitInventoryAvailablity: '',
      isLoading: false,
      unitDurations: '',
      unitLevelInventory: '',
      isDefaultUnit: this.props.isDefaultUnit.toString(),
      storeId: '',
      defaultSelected: this.props.isDefaultUnit.toString(),
      isModel: false,
      date: '',
      searchId: '',
      isAddedCount: '',
      isRemovedCount: '',
      isNoGraphData: false,
      accessKey: '',
      locationInfoData: [],
      analysisSummary: [],
    };

    // this.props;
    this.state.storeId = this.props.userstoreid;
    this.state.searchId = this.props.searchId;
    this.openPopup = this.openPopup.bind(this);
    this.getParams = this.getParams.bind(this);

  }

  getParams(name, sid) {
    this.child.current.toggleModal(name, sid);
  }

  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.marketInventoryAnalysis();
      this.loadDashBoard()
      this.loadSummary()
    } else {
      alert(response.status.Message);
    }
  }
  loadSummary() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": this.state.storeId,
      "SearchID": this.state.searchId,
      "RegionID": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/currentpricinganalysissummary', data)
      .then(response => {

        // this.state.isLoading=true;
        const analysisSummary = response.data.current;
        this.setState({
          analysisSummary: analysisSummary,
          isLoading: false,
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });


  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": this.state.storeId,
      "SearchID": this.state.searchId,
      "RegionID": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'unit/dashboardSearchlocation', data)
      .then(response => {
        // this.state.isLoading=true;
        const locationInfoData = response.data.data.unitDashboard;

        this.setState({
          locationInfoData: locationInfoData
        })
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  marketInventoryAnalysis() {
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    this.setState({
      isLoading: true, defaultSelected: this.state.isDefaultUnit, isDefaultUnit: this.state.isDefaultUnit
    })
    axios.get(CONFIG.API_URL + 'unit/unitdurations')
      .then(response => {

        let unitDurations = response.data.data.lookupData;

        this.setState({
          unitDurations
        })
      });
    localStorage.setItem('StId', this.state.storeId);
    const inputParam = {
      UserStoreID: this.state.storeId,
      SearchID: this.state.searchId,
      Duration: this.state.isDefaultUnit,
      RegionID: CONFIG.RegionId
    }

    this.commonInitLoad(inputParam);


  }

  componentWillReceiveProps(nextProps) {
    // this.myFormRef && this.myFormRef.reset();

    if (nextProps.searchId != this.props.searchId) {

      this.setState({
        isLoading: true, defaultSelected: '',
        searchId: nextProps.searchId,
        storeId: nextProps.storeId
      },
        () => {
          this.marketInventoryAnalysis()
        });
      // window.location.reload();
      //  const { from } = { from: { pathname: "/market-dashboard/${nextProps.storeId}/view-details/${nextProps.searchId}/${nextProps.storeCount}" } };
      // this.props.history.push(from);
    }

  }


  commonInitLoad(inputParam) {
    this.setState({
      isLoading: true
    })

    axios.post(CONFIG.API_URL + 'unit/unitdatahistoryinventory', inputParam)
      .then(response => {

        const unitLevelInventory = response.data.data.unitLevelInventory;
        const unitInventoryAvailablity = response.data.data.unitInventoryAvailablity;
        const isAddedCount = unitInventoryAvailablity.filter(e => e.isAdded === 1);
        const isRemovedCount = unitInventoryAvailablity.filter(e => e.isAdded === 0);
        // unitInventoryAvailablity.filter((e) => e.isAdded === 1).forEach(function (e) { isAddedCount.push(e.isAdded) });
        // unitInventoryAvailablity.filter((e) => e.isAdded === 0).forEach(function (e) { isRemovedCount.push(e.isAdded) });
        const date = response.data.data.datePrice.dateDetail;

        this.setState({
          unitInventoryAvailablity, unitLevelInventory, date, isAddedCount, isRemovedCount, isLoading: false
        })
        if (this.state.unitLevelInventory.length > 0) {
          this.setState({
            isNoGraphData: false
          })
          this.initialLoad(unitLevelInventory);
        }
        else {
          this.setState({
            isNoGraphData: true
          })
        }

      }).catch((err) => {

        this.setState({
          isLoading: false
        })
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  initialLoad(res) {
    var data = res;
    let dataSeries = data.map(val => { return { x: val.datePrice, y: isNaN(val.availableCount) ? null : val.availableCount }; });
    let uniqueDataSet = [...new Set(data.map(val => { return  isNaN(val.availableCount) ? null : val.availableCount  }))];
    let marketSeries = [({ name: 'Store offered this Unit Type', data: dataSeries })];
     let yearRange = this.state.isDefaultUnit;

    if (marketSeries.length > 0) {
      this.setState({
        isNoGraphData: false,
        options: {
          stroke: {
            width: 2,
          },
          colors: ["#1D1E1F"],
          yaxis: {
            title: {
              text: "Number of Stores offering this Unit Type"
            },
            min:0,
            tickAmount: uniqueDataSet.length === 1 || uniqueDataSet.length === 2 ? 1 : 2,
            labels: {
              formatter: function (value) {
                return value === null ? "N/A" : value.toFixed(0);
              },
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              }
            },
            style: {
              color: undefined,
              fontSize: '10px',
              fontFamily: 'Arial',
              fontWeight: 500,
              cssClass: 'apexcharts-yaxis-title',
            },
            axisBorder:{
              show: false,
            },
            axisTicks: {
              show: false,
            }
          },
          tooltip: {
            enabled: false,
          },
          xaxis: {
            type: "datetime",
            tooltip: { enabled: false },
            labels: {
              formatter: function(value, timestamp, opts) {
                       
                let val = value === undefined ? '' : yearRange < 4 ? moment(new Date(value)).format('DD-MMM-YYYY') : moment(new Date(value)).format('MMM-YYYY');
                return val;
              },
              showDuplicates: false,
              show: true,
              rotate: 0,
              style: {
                colors: '#8C9AA6',
                fontSize: '10px'
              },
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
           tickAmount: yearRange == 1 ? 4 : yearRange == 2 ? 4 : yearRange == 3 ? 12 : yearRange == 4 ? 5 : 9, 
        },
          noData: {
            text: 'Please enable any one of the below legend to view the graph.',
            style: {
              color: "#5b95f6",
            }
          },
        },
        series: marketSeries
      });
      var tempdata = [];
      var datum = {
        name: "Graph",
        values: this.popData(data),
      };
      tempdata.push(datum);
    } else {
      this.setState({
        isNoGraphData: true,
        options: {
          noData: {
            text: "No Records Found.",
            align: 'center',
            verticalAlign: 'top',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#F60000",
              fontSize: '13px',
              fontFamily: "Helvetica"
            }
          },
          yaxis: { show: false },
          xaxis: {
            labels: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
        },
        series: []
      })
    }
  }


  popData(data) {
    var tFData = data;
    var tData = [];
    for (var i = 0; i < tFData.length; i++) {
      var CData = {
        date: new Date(new Date().getFullYear(), new Date().getMonth(), tFData[i].orderNo),
        totalStores: tFData[i].totalStores,
        availableCount: tFData[i].availableCount,
        datePrice: new Date(tFData[i].datePrice),
        orderNo: tFData[i].orderNo
      }
      tData.push(CData);
    }
    return tData;
  }


  unitInventory(e) {

    this.state.isDefaultUnit = e.target.value;
    const inputParam = {
      UserStoreID: this.state.storeId,
      SearchID: this.state.searchId,
      Duration: this.state.isDefaultUnit,
    }

    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    this.commonInitLoad(inputParam);
    // this.initialLoad(res);
  }
  openPopup() {

    this.setState(prevState => ({
      isModel: !prevState.isModel
    }));
  }

  render() {
    const { isLoading, analysisSummary, locationInfoData, unitDurations, date, isRemovedCount, isNoGraphData, isAddedCount, unitInventoryAvailablity } = this.state;
    // console.log('invent');
    // console.log(unitInventoryAvailablity);
    let cutCur = locationInfoData.countryCurrency ? locationInfoData.countryCurrency : 'N/A';
    let clp = analysisSummary.lowestPrice;
    let cap = analysisSummary.averagePrice;
    let chp = analysisSummary.highestPrice;
    if (clp) {
      var splt_clp = clp.toString().split('.');
      let splt_val_clp = splt_clp[1] ? splt_clp[1].length === 1 ? '.' + splt_clp[1] + '0' : splt_clp[1].length === 2 ? '.' + splt_clp[1] : '' : '';
      clp = cutCur + splt_clp[0] + splt_val_clp;
    }
    if (cap) {
      var splt_cap = cap.toString().split('.');
      let splt_val_cap = splt_cap[1] ? splt_cap[1].length === 1 ? '.' + splt_cap[1] + "0" : splt_cap[1].length === 2 ? '.' + splt_cap[1] : '' : '';
      cap = cutCur + splt_cap[0] + splt_val_cap;
    }
    if (chp) {
      var splt_chp = chp.toString().split('.');
      let splt_val_chp = splt_chp[1] ? splt_chp[1].length === 1 ? '.' + splt_chp[1] + '0' : splt_chp[1].length === 2 ? '.' + splt_chp[1] : '' : '';
      chp = cutCur + splt_chp[0] + splt_val_chp;
    }
    const defaultProps = {
      helpTitle: "Historical Inventory Analytics",
      helpContent: "<p>In Optimize, we define inventory as the number of stores in this Market which advertise a particular unit type. On this graph you will see the number of stores over time (5 days, 30 days, 90 days, 6 months or 1 year) which have advertised this unit type.</p><p>Below the graph, under Added in Last 24 Hours, you will see the stores in this Market which have either added this unit type to their website or removed it from their website in the last 24 hours.</p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/hl1BepLwnnI' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    }
    const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
    return (
      <div className="MarketInventory-AnalysisPdf">
        <div className="header"> <img src={Logo} /><div className="float-right-name">
          {/* <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0,CONFIG.storeNameMaxLimit-3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity+', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude+', ' +locationInfoData.longitude+', ' : ''}{locationInfoData.storeState + ' ' + locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ?"| Coverage: " +locationInfoData.storeZoneCoverage : 'N/A'}</p> */}
          <h5>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5></div> <div className="float-right"><p className="m-0 address-font">{locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ", " : '' + locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''}{locationInfoData.storeState ? locationInfoData.storeState + ' ' : ''}{locationInfoData.storeZipCode} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p>
          </div>
        </div>
        <div className="footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>
        <div className="App history-tab">
          <div className="page">
            <div className="minus-top-set"><h4 className="fl-fix" style={{ float: 'none' }}>{'(' + locationInfoData.searchName + ')'} Inventory & Availability Analysis</h4></div>
            <Row className="height-full">
              <div className="sep-box">
                <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Lowest Weekly Price" : "Current Lowest Price"}</h4>
                <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.lowestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
                <div className="align-stores-btm"><h5>{clp ? clp : isLoading === false ? 'N/A' : ''} </h5></div>
              </div>
              <div className="sep-box current-avg-height">
                <h4 className="text-left" style={!cap && isLoading === false ? { height: 'auto' } : { position: 'relative' }}>{CONFIG.RegionId === 3 ? "Current Average Weekly Price" : "Current Average Price"} </h4>
                <div className="align-stores-btm"><h5>{cap ? cap : isLoading === false ? 'N/A' : ''} </h5></div>
              </div>
              <div className="sep-box right-none">
                <h4 className="text-left">{CONFIG.RegionId === 3 ? "Current Highest Weekly Price" : "Current Highest Price"} </h4>
                <div className="pd-btm-20"><p>{analysisSummary ? analysisSummary.highestPriceStoreName : isLoading === false ? 'N/A' : ''}</p></div>
                <div className="align-stores-btm"><h5>{chp ? chp : isLoading === false ? 'N/A' : ''} </h5></div>
              </div>
              <div className="clear"></div>
            </Row>

            <h4 className="mt-4" style={{ marginLeft: '15px', marginRight: '15px' }}>History </h4>
            <p style={{ marginLeft: '15px', marginRight: '15px' }}>In Optimize, we define inventory as the number of stores in this Market which advertise a particular unit type. On this graph you will see the number of stores over time (5 days, 30 days, 90 days, 6 months or 1 year) which have advertised this unit type.</p>
            <p style={{ marginLeft: '15px', marginRight: '15px' }}>Below the graph, under Added in Last 24 Hours, you will see the stores in this Market which have either added this unit type to their website or removed it from their website in the last 24 hours.</p>
            {/* <HelpTextPopup parm={defaultProps} /> */}
            <AvForm id="frmDurDdl" ref={c => (this.myFormRef = c)} className="days-count">
              <AvField type="select" name="price" value={this.state.defaultSelected} onChange={e => this.unitInventory(e)}>
                {unitDurations ? unitDurations.map((unit) =>
                  <option key={unit.id} selected={this.state.defaultSelected} value={unit.id}>{unit.timePeriod}</option>
                ) : ''}
              </AvField>

            </AvForm>

          <div className="clear"></div>
          <div id="Inventory-Analysis">
          {isNoGraphData ? <div className="no-data mb-3">No data available for this graph!</div> :   <Chart options={this.state.options} series={this.state.series} type="line" width={960} height={320} /> }
          </div>
         
          <div className="pageclearfix"></div>
          </div>
          <div className="page">
            <div className="both-list">
              <Row style={{ marginLeft: '15px', marginRight: '15px' }}>
                <div className="added-on col-lg-6 col-md-12 padding-right-7">
                  {/* <h5>ADDED ON DATE: {moment(date).format('L')}</h5> */}
                  <h5>Added in last 24 hours</h5>
                  <div className="body-add-on">
                    <Row>
                      {isAddedCount.length > 0 ? isAddedCount.map((unit) =>
                        unit.isAdded == 1 ?
                          <div className="col-lg-6" style={{ float: 'left', width: '50%' }}>
                            <div className="address-listings">
                              <MarketProfile ref={this.child} />
                              <a onClick={() => this.getParams(unit.storeName, unit.storeID)}><img src={greenimage} alt="" />{unit.storeName}</a>
                              <div className="address-name">{unit.address}</div>
                            </div>
                          </div>
                          : ''
                      ) : <div className="col-lg-12 no-records pt-2 pb-2 text-center">{isLoading === false ? 'No Stores have Added this Unit Type' : ''}</div>}
                      <div className="printclearfix"></div>
                    </Row>
                  </div>
                </div>
                <div className="printclearfix"></div>
                <div className="added-on col-lg-6 col-md-12 low-range">
                  {/* <h5>WITHDRAWN ON DATE: {moment(date).format('L')}</h5> */}
                  <h5>Removed in last 24 hours</h5>
                  <div className="body-add-on">
                    <Row>
                      {isRemovedCount.length > 0 ? isRemovedCount.map((unit) =>
                        unit.isAdded == 0 ?
                          <div className="col-lg-6" style={{ float: 'left', width: '50%' }}>
                            <div className="address-listings red-list">
                              <MarketProfile ref={this.child} />
                              <a onClick={() => this.getParams(unit.storeName, unit.storeID)}><img src={redimages} alt="" />
                                {unit.storeName}

                              </a>
                              <div className="address-name">{unit.address}</div>
                            </div>
                          </div>
                          : ''
                      ) : <div className="col-lg-12 no-records pt-2 pb-2 text-center">{isLoading === false ? 'No Stores have Removed this Unit Type' : ''}</div>}
                    </Row>
                  </div>
                </div>
              </Row>
            </div>
            <div className="pageclearfix"></div>
          </div>
        </div>
        <div className="">
          {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}

        </div>

      </div>


    );
  }



}