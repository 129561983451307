import React from 'react';
import './MissingCompetitor.css'
import { Col, Row, Modal, ModalHeader, ModalBody, Button, FormText, Label } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import NumberFormat from 'react-number-format';
import EmailShare from 'react-email-share-link'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { CONFIG ,MatchingCountryCode} from '../../utils/config';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default class MissingCompetitor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modal1: false,
            phoneFieldBorder: '0',
            phoneErr: '',
            phone: '',
            snackMSg: '',
            snackOpen: false,
            validError: '',
            phoneMaxLength: CONFIG.PhoneMaxLength,
            StateCountryCount: 1,
            
        };
        this.missingCompetitorSubmit = this.missingCompetitorSubmit.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
        this.postalCodeValidation = this.postalCodeValidation.bind(this);

    }

    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    toggleModal = () => {
        // axios.get(CONFIG.API_URL + 'home/lookupdata')
        axios.get(CONFIG.API_URL + 'home/lookupdata/' + CONFIG.RegionId +'/'+ 0)
            .then(response => {

                let stateData = response.data.lookups.statesList;
                //To know how many Countries in statesList data
                let StateCountryCount = stateData.filter(data => {
                    return data.stateID === '';
                }).length;
                this.setState({
                    stateData, StateCountryCount
                })
                //  console.log(stateData,"hiiii");
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                })

            });
        this.setState(state => ({ modal: !state.modal }))
    }

    toggleModal1 = () => {
        // axios.get(CONFIG.API_URL + 'home/lookupdata')
        axios.get(CONFIG.API_URL + 'home/lookupdata/' + CONFIG.RegionId+'/'+ 0)
            .then(response => {

                let stateData = response.data.lookups.statesList;
                //To know how many Countries in statesList data
                let StateCountryCount = stateData.filter(data => {
                    return data.stateID === '';
                }).length;
                this.setState({
                    stateData, StateCountryCount
                })
                //  console.log(stateData,"hiiii");
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                })

            });
        this.setState(state => ({ modal1: !state.modal1 }))
    }

    cancelMissingCompetitor = () => this.setState(state => ({ modal: !state.modal, phoneErr: '', phoneFieldBorder: '', phone: '', validError: '' }))

    cancelMissingCompetitor1 = () => this.setState(state => ({ modal1: !state.modal1, phoneErr: '', phoneFieldBorder: '', phone: '', validError: '' }))

    componentDidMount() {
        if (typeof window.dataLayer != "undefined" && this.props.isShowModal) {
            window.dataLayer.push({
                "event": "show popup",
                'eventCategory': 'popup',
                'eventAction': 'show',
                'eventLabel': this.state.popupName,
            });
        }
    }

    onPhoneChange = (event) => {
        let validError = '';
        this.setState({ validError });
        var string = event.target.value;
        var replacedString = event.target.value;
       // var replacedString = event.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
        var numericRegex = /^[ 0-9*()+-]*$/;// /^([0-9]*)$/;
        var valid = numericRegex.exec(replacedString);
        var keyCode = this.state.keyCode;
        let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';

        if (valid) {
            string = replacedString;
            // var regex = CONFIG.PhoneRegex;
            // var match = regex.exec(string);
            // if (match) {
            //     match.shift();
            //     match[0] = "(" + match[0] + ")";
            //     string = match.join("-");
                phoneErr = '';
                phoneFieldBorder = '0';
            // }
        } else {
            if (keyCode !== 8 && keyCode !== 46) {
                string = this.state.phone;
            }
        }

        if (string === "") {
            phoneErr = '';
            phoneFieldBorder = '0';
        }
        this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
    }

    onPhoneChanges = (event) => {
        let validError = '';
        this.setState({ validError });
        var string = event;
       let phoneErr = '';// 'Phone is invalid';
        let phoneFieldBorder = '0';// '1';


        if (string === "" || string === undefined) {
            phoneErr = '';
            phoneFieldBorder = '0';
        }
        this.setState({ phone: string, phoneErr, phoneFieldBorder, keyCode: '' });
    }

    phoneKeyPressed = (event) => {
        this.setState({ keyCode: event.keyCode });
    }

    missingCompetitorSubmit(event, errors, values) {
        let validError = '';
        this.setState({ validError });
        values.Phone = this.state.phone;
        values['RegionId'] = CONFIG.RegionId;
        //Phone number validation starts
        let phoneErr = '';
        let status = values.status;
        let phoneFieldBorder = '0';

        this.setState({ phoneErr, phoneFieldBorder });
        //Phone number validation end
        if (values.WebsiteUrl !== "" || values.MarketName !== "" || values.Address !== "") {
            var regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            var zipcodeValid = /(^[a-zA-Z0-9]+$)/;  
           
            if (this.state.phone !== "" && this.state.phone.length < CONFIG.PhoneMinLength) {

                // phoneErr = 'Phone is invalid';
                //    phoneFieldBorder = '1';
                //     formValid = false;
                //alert('Phone is invalid');
                let validError = 'Phone is invalid';
                this.setState({ validError });
            } else if (values.WebsiteUrl !== "" && !regexp.test(values.WebsiteUrl)) {
                let validError = 'Website URL is invalid';
                this.setState({ validError });
            } else if (values.Zipcode  !== "" && !zipcodeValid.test(values.Zipcode)){

            }
            else {
                this.setState({ isLoading: true });
                let token = localStorage.getItem('accessKey');
                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }
                axios.post(CONFIG.API_URL + 'Market/missingcompetitor', values)
                    .then(res => {
                        if (res.status === 200) {
                            this.myFormRef && this.myFormRef.reset();
                            let msg = "Sucessfully submitted your request.";
                            if (status === 1) {
                                this.setState(state => ({ modal1: !state.modal1, phone: '' }))
                            } else {
                                this.setState(state => ({ modal: !state.modal, phone: '' }))
                            }
                            this.setState({ snackOpen: true, snackMSg: msg  });
                            // this.setState({ modal: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            isLoading: false
                        })
                        if (err.response.status === 401) {
                            localStorage.removeItem('accessKey');
                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                            this.props.history.push(from);
                        }
                        else {
                            alert(err.response.data);
                        }
                    });
            }
        }
        else {
            // this.setState({ snackOpen: true, snackMSg: "Please enter Location Name or Address or Website URL." });
            // alert("Please enter Location Name or Address or Website URL.");
            let validError = 'Please enter Market Name or Address or Website URL.';
            this.setState({ validError });
        }
    }
    postalCodeValidation = (e) => {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
            if (!(code == 32) && // space
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            e.preventDefault();
        }
       
    }
    render() {
        const { stateData, snackMSg, snackOpen, StateCountryCount } = this.state;
        return (
            <div className="missing-Competitor">
                <Link to="#" onClick={this.toggleModal1.bind(this)} className="go-back-btn list-item-text"><span>Notify us of Missing Stores</span></Link>
                <Link to="#" onClick={this.toggleModal.bind(this)} className="go-back-btn list-item-text add-mar"><span>Are there any stores missing?</span></Link>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackOpen}
                    className="snackbar bg-color-primary custom-snackbar"
                    maxsnack={3}
                    autoHideDuration={30000}
                    onClose={this.snackHandleclose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMSg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
                <Dialog open={this.state.modal} onClose={this.cancelMissingCompetitor.bind(this)} id="missing-Competitor-modal" className={this.props.className} size="lg">
                    <DialogTitle Header className="pb-1">Missing Competitor
                    <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="closeModal only-close-move"
                            onClick={this.cancelMissingCompetitor.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="misCompmodalBody">
                        <h4>Identify Missing Stores</h4>
                        <p>No problem, just list the name, address and website, if possible and we'll work on getting them into our database as quickly as possible.</p>
                        <Row className="margin-right-0">
                            <Col lg={6} md={6}>
                                <span className='errorMsg errorMsgSpace' color="red">{this.state.validError ? this.state.validError : this.state.phoneErr}</span>
                                <AvForm onSubmit={this.missingCompetitorSubmit.bind(this)} ref={c => (this.myFormRef = c)} className="enter-acc-details">
                                    {/* <AvField name="RegionId" value={CONFIG.RegionId} type="hidden"  /> */}
                                    <AvField name="MarketName" label="Market Name" type="text" />
                                    <AvField name="Address" label={CONFIG.Address1} type="text" />
                                    <AvField name="Address2" label={CONFIG.Address2} type="text" />
                                    <AvField name="City" label={CONFIG.City} type="text" />
                                    <AvField name="status" value={0} type="hidden" />
                                    <Row>
                                       {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '':
                                        <Col xs="12" sm="6" className="pr-0">
                                            <AvField type="select" name="state" className="bs" label={CONFIG.State} >
                                                <option value='' >--Select State--</option>
                                                {stateData ? stateData.map((state) =>
                                                    state.stateID === '' && StateCountryCount <= 1 ? '' : 
                                                    <option key={state.stateID} className={state.stateID === '' ? "optHead" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                ) : ''}
                                            </AvField>
                                        </Col>
                                        }
                                        <Col xs="12" sm="6" className={CONFIG.RegionId === MatchingCountryCode.UK_RID ? "postal-code-uk left-padding-a-m" : "left-padding-a-m"}>
                                            <AvField name="Zipcode" validate={{
                                                                        required: { value: false },
                                                                        pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.PostalCodevalidation },
                                                                        maxLength: { value: 10, errorMessage: CONFIG.PostalCode+ ' must below 10 characters' }
                                                                    }} 
                                            onKeyPress={CONFIG.RegionId === 3 ? this.postalCodeValidation :''} label={CONFIG.PostalCode} type="text" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} >
                                            <Label className={this.state.phoneFieldBorder === '1' ? 'text-danger' : ''}>Phone</Label> <PhoneInput name="phone" id="phone" value={this.state.phone} onChange={this.onPhoneChanges} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                            {/* <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText> */}
                                        </Col>
                                    </Row>
                                    <AvField name="WebsiteUrl" label="Website URL" /*pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"*/ type="text" />
                                    <Row className="form-submits">
                                        <Col className="changes-btn step_btn">
                                            <Button id="btn" className="submit-btn next-btn btn-design mr-2">Notify Us</Button>
                                            <Button id="btn" onClick={this.cancelMissingCompetitor.bind(this)} className="submit-btn btn-design go-back-btn">Go Back</Button>

                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                            <Col lg={6} md={6} className="missing-data">
                                <div className="missing-Static-Data">
                                    <h6>StorTrack Research Team is on it!</h6>
                                    <p>Not sure how we missed this one, but we will find it and add it as soon as we can! If we have any trouble, we will be in contact with you. Check back shortly to see this store added!</p>
                                    <p>If you have any questions or comments let us know.</p>
                                    <div className="phone">
                                        <span className="icon-answer"></span>
                                        <a href={"tel:"+ CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free}> <NumberFormat value={CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free} displayType={'text'} format="#-###-###-####" /></a>

                                    </div>
                                    <div className="email">
                                        <span className="icon-email"></span>
                                        <EmailShare email={CONFIG.Mail_Support} subject="" body="">
                                            {link => (
                                                <a href={link} data-rel="external">{CONFIG.Mail_Support}</a>
                                            )}
                                        </EmailShare>
                                        {/* <Link to="mailto:viprocess@validationinstitute.com">viprocess@validationinstitute.com</Link> */}
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </DialogContent>
                </Dialog>


                <Modal isOpen={this.state.modal1} toggle={this.cancelMissingCompetitor1.bind(this)} id="missing-Competitor-modal" className={this.props.className} size="lg">
                    <ModalHeader Header toggle={this.cancelMissingCompetitor1.bind(this)} className="pb-1">Missing Competitor</ModalHeader >
                    <ModalBody className="misCompmodalBody">
                        <h4>Identify Missing Stores</h4>
                        <p>No problem, just list the name, address and website, if possible and we'll work on getting them into our database as quickly as possible.</p>
                        <Row className="margin-right-0">
                            <Col lg={6} md={6}>
                                <span className='errorMsg errorMsgSpace' color="red">{this.state.validError ? this.state.validError : this.state.phoneErr}</span>
                                <AvForm onSubmit={this.missingCompetitorSubmit.bind(this)} ref={c => (this.myFormRef = c)} className="enter-acc-details">
                                    {/* <AvField name="RegionId" value={CONFIG.RegionId} type="hidden"  /> */}
                                    <AvField name="MarketName" label="Market Name" type="text" />
                                    <AvField name="Address" label={CONFIG.Address1} type="text" />
                                    <AvField name="Address2" label={CONFIG.Address2} type="text" />
                                    <AvField name="City" label={CONFIG.City} type="text" />
                                    <AvField name="status" value={1} type="hidden" />

                                    <Row>
                                   {CONFIG.RegionId === MatchingCountryCode.UK_RID ? '':
                                        <Col xs="12" sm="6" className="pr-0">
                                            <AvField type="select" name="state" className="bs" label={CONFIG.State} >
                                                <option value='' >--Select State--</option>
                                                {stateData ? stateData.map((state) =>
                                                    state.stateID === '' && StateCountryCount <= 1 ? '' :
                                                    <option key={state.stateID} className={state.stateID === '' ? "optHead" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                ) : ''}
                                            </AvField>
                                        </Col>
                                      }
                                        <Col xs="12" sm="6" className={CONFIG.RegionId === MatchingCountryCode.UK_RID ? "postal-code-uk left-padding-a-m" : "left-padding-a-m"}>
                                            <AvField name="Zipcode" label={CONFIG.PostalCode}  validate={{
                                                                        required: { value: false  },
                                                                        pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.PostalCodevalidation },
                                                                        maxLength: { value: 10, errorMessage: CONFIG.PostalCode+ ' must below 10 characters' }
                                                                    }} onKeyPress={CONFIG.RegionId === 3 ? this.postalCodeValidation :''}  type="text" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Label className={this.state.phoneFieldBorder === '1' ? 'text-danger' : ''}>Phone</Label> <PhoneInput name="phone" id="phone" value={this.state.phone}  onChange={this.onPhoneChanges} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                            {/* <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText> */}
                                        </Col>
                                    </Row>
                                    <AvField name="WebsiteUrl" label="Website URL" /*pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"*/ type="text" />
                                    <Row className="form-submits">
                                        <Col className="changes-btn step_btn">
                                            <Button id="btn" className="submit-btn next-btn btn-design mr-2">Notify Us</Button>
                                            <Button id="btn" onClick={this.cancelMissingCompetitor1.bind(this)} className="submit-btn btn-design go-back-btn">Go Back</Button>

                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                            <Col lg={6} md={6} className="missing-data">
                                <div className="missing-Static-Data">
                                    <h6 className="miss-sub">StorTrack Research Team is on it!</h6>
                                    <p>Not sure how we missed this one, but we will find it and add it as soon as we can! If we have any trouble, we will be in contact with you. Check back shortly to see this store added!</p>
                                    <p>If you have any questions or comments let us know.</p>
                                    <div className="phone">
                                        <span className="icon-answer"></span>
                                        <a href={"tel:"+ CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free}> <NumberFormat value={CONFIG.RegionId === 3 ? CONFIG.Sales_Support : CONFIG.Toll_Free} displayType={'text'} format="#-###-###-####" /></a>

                                    </div>
                                    <div className="email">
                                        <span className="icon-email"></span>
                                        <EmailShare email={CONFIG.Mail_Support} subject="" body="">
                                            {link => (
                                                <a href={link} data-rel="external">{CONFIG.Mail_Support}</a>
                                            )}
                                        </EmailShare>
                                        {/* <Link to="mailto:viprocess@validationinstitute.com">viprocess@validationinstitute.com</Link> */}
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>



            </div>
        );
    }
}