import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DefaultLayout, MarketLayout, LoginLayout,PdfLayout } from './../Layouts';
import { COMMON } from './../utils/common';

const authGuard = {
    authenticate() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            return true;
        } else {
            return false
        }
    },

};
// Below the code for verify it is a MVP user
const userType= {
    verifyUser() {
        let userVerify = parseInt(localStorage.getItem('MVPUserType'));
        let mvpTypes = COMMON.MVPUSER;
        let validate = mvpTypes.filter(data => data === userVerify ? true : false)
        if(validate.length === 0){
            return false;
        }else{
            return true;
        }
        // let user = parseInt(localStorage.getItem('MVPUserType'));
        // if(user === COMMON.MVPUSER.TYPE1 || user === COMMON.MVPUSER.TYPE2 || user === COMMON.MVPUSER.TYPE3){
        //     return true;
        // } else {
        //     return false;
        // }
    }
}

export const DefaultLayoutRoute = ({ component: Component, ...rest }) => {
    localStorage.setItem('isLayoutFrom', 'DEFAULT_LAYOUT');
    return (
        <Route {...rest} render={matchProps => (
            <DefaultLayout>
                <Component {...matchProps} />
            </DefaultLayout>
        )} />
    )
};

export const PdfLayoutRoute = ({ component: Component, ...rest }) => {
    localStorage.setItem('isLayoutFrom', 'PDF_LAYOUT');
    return (
        <Route {...rest} render={matchProps => (
            <PdfLayout>
                <Component {...matchProps} />
            </PdfLayout>
        )} />
    )
};
export const MarketLayoutRoute = ({ component: Component, ...rest }) => {
    localStorage.setItem('isLayoutFrom', 'ADMIN_LAYOUT');
    return (
        <Route {...rest} render={matchProps => (
            (authGuard.authenticate() && !userType.verifyUser()) || (authGuard.authenticate() && userType.verifyUser() && (rest.path === "/market-dashboard/:storeid/" || rest.path === "/UnauthorizedAccess" || rest.path === "/market-dashboard/:storeid/view-details/:searchid/:tab?" || rest.path === "/market-level-data/competitor-overview/:userstoreid/" || rest.path ==="/market-level-data/pricing-volatility/:userstoreid/" || rest.path === "/market-level-data/inventory-analytics/:userstoreid/" || rest.path === "/marketing-score/:storeid" || rest.path === "/market-level-data/all-units-pricing/:userstoreid/" || rest.path === "/market-level-data/alert/:userstoreid/" )) ?
                <MarketLayout>
                    <Component {...matchProps} />
                </MarketLayout>
            : userType.verifyUser() && ( rest.path !== "/market-dashboard/:storeid/" || rest.path !== "/market-dashboard/:storeid/view-details/:searchid/:tab?" || rest.path !== "/market-level-data/competitor-overview/:userstoreid/" || rest.path !=="market-level-data/pricing-volatility/:userstoreid/" || rest.path !== "/market-level-data/inventory-analytics/:userstoreid/" || rest.path !== "/marketing-score/:storeid" || rest.path !== "/market-level-data/all-units-pricing/:userstoreid/" || rest.path !== "/market-level-data/alert/:userstoreid/") ? 
            <Redirect to={{pathname: "/UnauthorizedAccess"}}/>
            : <Redirect to={{pathname: "/page_expired"}}/>
        )} />
    )
};

// export const MarketLayoutRoute = ({ component: Component, ...rest }) => {
//     return (
//         <Route {...rest} render={matchProps => (
//             authGuard.authenticate() ? (
//                 <MarketLayout>
//                     <Component {...matchProps} />
//                 </MarketLayout>
//             ) :
//                 <Redirect
//                     to={{
//                         pathname: "/page_expired",
//                     }}
//                 />
//         )} />
//     )
// };

export const LoginLayoutRoute = ({ component: Component, ...rest }) => {
    localStorage.setItem('isLayoutFrom', 'LOGIN_LAYOUT');
    return (
        <Route {...rest} render={matchProps => (
            <LoginLayout>
                <Component {...matchProps} />
            </LoginLayout>
        )} />
    )
};