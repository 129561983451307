import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { HashLink as Link } from 'react-router-hash-link';
import { CONFIG } from '../../utils/config';
import { SeUserType } from '../../utils/common';
import axios from 'axios';
import './MvpLogin.css';
import { withRouter } from 'react-router-dom';

export default class MvpLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // authToken: this.props.match.params.newToken
            SecretKey: this.props.match.params.secureid,
            AccessUserStoreId: this.props.match.params.userstoreid
        };
    }
    componentDidMount() { 
        // var str = this.props.location.pathname;
        // if(str !== undefined && str !== ""){ 
        //     var tmp = str.split("/access/");
        //     let tok = tmp[1];
        //     this.setState({authToken: tok}, () => {this.getUserDetail()});
        // }
        window.scrollTo(0, 0);
        this.getUserDetail();
      }
    getUserDetail() {
        const values = {
            "SecretKey": this.state.SecretKey,
            "AccessUserStoreId": parseInt(this.state.AccessUserStoreId),
            // headers
        }
        axios.post(CONFIG.API_URL + 'account/secretlogin', values)
            .then(res => {
                if (res.status === 200) {
                    const getTokvalues = res.data;
                    // this.myFormRef && this.myFormRef.reset();
                    //console.log(getTokvalues);
                    this.setState({ getTokvalues });
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
                    localStorage.setItem('SecretKey', getTokvalues.secureID);
                    localStorage.setItem('accessKey', getTokvalues.token);
                    localStorage.setItem('getNames', getTokvalues.firstName);
                    localStorage.setItem('isPaid', getTokvalues.isPaid);
                    localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
                    localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
                    localStorage.setItem('userType', getTokvalues.userType);
                    localStorage.setItem('userStatus', getTokvalues.status);
                    localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
                    localStorage.setItem('userManual', getTokvalues.isManual);
                    localStorage.setItem('userCdate', getTokvalues.createdDate);
                    localStorage.setItem('displayMessage', getTokvalues.displayMessage);
                    // Below the storage for MVP return
                    localStorage.setItem('mvpUserStoreId', getTokvalues.mvpUserStoreId);
                    localStorage.setItem('OptStoreId', this.state.AccessUserStoreId);
                    localStorage.setItem('MVPUserType', getTokvalues.userType);
                    const { from } = this.props.location.state || { from: { pathname: `/market-dashboard/${this.state.AccessUserStoreId}` } };
                    this.props.history.push(from);
                }
            })
            .catch((e) => {
                // error in e.message
                // this.setState({ isLoading: false });
                // if (e.response.data.StatusCode === 400) {
                //     const sighinerror = e.response.data.Message;
                //     alert(sighinerror);
                // }
            });
    }
    render() {
        // const { } = this.state;
        return (
            <div className="loader-wrap"><div className="loading loading-center"></div></div>
            // <div className="body-form text-center" style={{marginTop: '150px'}}>
            //     {/* please wait we will redirect you shortly... */}
            // </div>
        );
    }
}