import React from 'react';
import { CONFIG } from '../../utils/config';
import axios from 'axios';
import stlogo from './../../Assets/Images/logo.jpg';
import './Authendication.css'

export default class Authendication extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, curSymb: ''
        };

    }
    componentWillMount() {
        this.setState({ isLoading: true });
    }



    componentDidMount() {
        localStorage.clear();
        this.setState({
            curSymb: CONFIG.Currency_Symbol
        })
        // axios.get(CONFIG.API_URL + 'home/region/' + CONFIG.RegionId)
        //     .then(response => {
        //         let curSymb = response.data.region.currencySymbol;
        //         this.setState({
        //             curSymb
        //         })
        //     });

        const data = {
            "SecretKey": this.props.match.params.authurization,
            "RegionId" : CONFIG.RegionId
        }
        localStorage.setItem('SecretKey', this.props.match.params.authurization);
        this.setState({ isLoading: true });
        axios.post(CONFIG.API_URL + 'account/secretlogin', data)

            .then(res => {
               

                if (res.status === 200) {
                    const getTokvalues = res.data;

                    //console.log(getTokvalues);
                    this.setState({ getTokvalues });
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
                    localStorage.setItem('accessKey', getTokvalues.token);
                    localStorage.setItem('getNames', getTokvalues.firstName);
                    localStorage.setItem('isPaid', getTokvalues.isPaid);
                    localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
                    localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
                    localStorage.setItem('userType', getTokvalues.userType);
                    localStorage.setItem('userStatus', getTokvalues.status);
                    localStorage.setItem('curSymb', CONFIG.Currency_Symbol);
                    localStorage.setItem('userManual', getTokvalues.isManual);
                    localStorage.setItem('userCdate', getTokvalues.createdDate);
                    localStorage.setItem('displayMessage', getTokvalues.displayMessage);

                    if (getTokvalues.isPaid === 0) {

                        const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
                        this.props.history.push(from);

                    }
                    else {
                        const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
                        this.props.history.push(from);

                    }

                }

            })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response.data.StatusCode === 400) {
                    this.myFormRef && this.myFormRef.reset();
                    const sighinerror = err.response.data.Message;
                    this.setState({ sighinerror });
                }

            });

    }
    render() {
        const { isLoading } = this.state;
        return (

            <div className="">
                <div className="secure-login">
                    <a href={CONFIG.STK_URL + "index.html"} > <img src={stlogo} className="logo main-page-logo" alt="StorTrack" /></a>
                </div>

                <div className="secure-logding">Please wait while we sign you in..</div>
            </div>

        );
    }


}

