import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CONFIG } from '../../utils/config';
import ReactStreetview from './ReactStreetview.js';

class StoreStreetView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      latitude: '',
      longitude: '',
      storeName:'',
      googleMapsApiKey: CONFIG.GoogleMapsApiKey
    }

    this.toggleStoreModal = this.toggleStoreModal.bind(this);
    this.trackModal = this.trackModal.bind(this);
  }

  toggleStoreModal(lat, lon,name) {
    this.setState(state => ({
      modal: !state.modal,
      latitude: lat,
      longitude: lon,
      storeName:name
    }))
  }

  trackModal() {
    this.setState(state => ({
      modal: !state.modal,
    }));
  }

  render() {
    const { latitude, longitude, googleMapsApiKey ,storeName} = this.state;
    const streetViewPanoramaOptions = {
      position: { lat: +latitude, lng: +longitude },
      pov: { heading: 100, pitch: 0 },
      zoom: 1
    }
    return (
      <div>
        <Modal isOpen={this.state.modal} 
        // toggle={() => this.trackModal()} 
        className="store-mainpopup"
        backdrop="static">
        <ModalHeader toggle={this.trackModal}>{storeName} <br/><i className="op-min">This is the street view from Google Maps.</i></ModalHeader>
          <ModalBody>
            <ReactStreetview
              apiKey={googleMapsApiKey}
              streetViewPanoramaOptions={streetViewPanoramaOptions}
              onPositionChanged={position => this.setState({ position: position })}
              onPovChanged={pov => this.setState({ pov: pov })}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default StoreStreetView;