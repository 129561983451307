import React, { Component } from 'react';
import './MarketScore.css';
import Chart from "react-apexcharts";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { HelpTextPopup } from './../HelpTextPopup/index';
import { Table as ReactTable } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CloseIcon from '@material-ui/icons/Close';
import * as d3 from "d3";
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import addtomap from './../../Assets/Images/plus.png';
import removefrommap from './../../Assets/Images/trash.png';
import tableplus from './../../Assets/Images/table-plus.png';
import tableminus from './../../Assets/Images/table-minus.png';
import Tooltip from '@material-ui/core/Tooltip';
import { CompetitorStore } from '../CompetitorStore';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

class MyMarket extends React.Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };


    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.tablepaggination}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
    MyMarket,
);

export default class MarketScore extends Component {
    constructor(props) {
        super(props);
        this.compstorechild = React.createRef();

        this.state = {
            options: {
                chart: {
                    id: 'apexchart-example',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                xaxis: {
                    categories: [],
                    show: false
                },
                yaxis: {
                    show: false,

                },
                noData: {
                    text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
                    verticalAlign: 'top',
                    style: {
                        color: "#5b95f6",
                        fontSize: '13px',
                        fontFamily: "Helvetica"
                    }
                },
            },
            series: [{
                name: '',
                data: []
            }],
            isLoading1: true,
            order: 'asc',
            orderBy: 'storeName',
            snackOpen: false,
            snackMSg: '',
            grapghCatcheck: 'Total',
            graphOpen: 'Total',
            MarkScoringData: [],
            MarkScoring: [],
            MarkStoreInfo: {},
            Markets: [],
            MarketsDesc: {},
            ChartTableData: {},
            GraphCompId: {},
            locationInfoData: {},
            pageCount: 0,
            trackModalData: '',
            storeModal: false,
            defaultWidth: 0,
            dialogOpen: false,
            MarketSCdet: {},
            Scorecard: [],
            uniquestores: [],
            snackClr: ''
        };
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.stableSort = this.stableSort.bind(this);
        this.getSorting = this.getSorting.bind(this);
        this.desc = this.desc.bind(this);
        this.onSelecCatGrapgh = this.onSelecCatGrapgh.bind(this);
        this.scoringData = this.scoringData.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.getParams = this.getParams.bind(this);
        this.dialogContentOpen = this.dialogContentOpen.bind(this);
    }

    getParams(storeAddress) {
        let storeId = parseInt(this.props.match.params.storeid);
        this.compstorechild.current.toggleStoreModal(storeAddress, storeId);
    }

    //Table Sorting 
    handleRequestSort(event, property) {
        const { order, orderBy } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        let sort = isDesc ? 'asc' : 'desc';
        this.setState({ order: sort, orderBy: property });
    }

    //Table Sorting order
    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    //Table Sorting the selected Column
    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    //Table Sorting Order Request
    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    //To open Particular Category's Graph 
    onSelecCatGrapgh(openActGraph) {
        let activeRow = this.state.grapghCatcheck === openActGraph ? '' : openActGraph;
        activeRow === '' ?
            this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow })
            :
            this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow }, () => {
                this.drawChart();
            });
    }
    downLoadPDF() {
        // this.setState({ isLoading1: true });
        var currentDate = moment(new Date()).format("DD-MMM-YYYY");
        let graphOpen = this.state.graphOpen === "Total" ? '1' : this.state.graphOpen === "Website" ? '2' : this.state.graphOpen === "Search" ? '3' : this.state.graphOpen === "Paid Search" ? '4' : this.state.graphOpen === "Social Media" ? '5' : this.state.graphOpen === "Contactability" ? '6' : '';
        let pta = this.state.order;
        let searchId = this.state.orderBy;
        let unitType = this.state.uniquestores ? this.state.uniquestores : '';
        let usId = parseInt(this.props.match.params.storeid);
        let accessKey = localStorage.getItem('SecretKey');
        var timestamp = new Date().getDate() + "" + new Date().getMonth() + "" + new Date().getFullYear() + "" + new Date().getTime();
        //construct filename for pdf report.
        var fileName = usId + "MarketScoreCard" + "_" + timestamp + ".pdf";
        var stoName = this.state.locationInfoData && this.state.locationInfoData.storeName && this.state.locationInfoData.storeName !== null && this.state.locationInfoData.storeName !== '' && this.state.locationInfoData.storeName !== undefined ? this.state.locationInfoData.storeName : 'Your Store';
        var fileNamePdf = stoName + '_' + "MarketScoreCard" + '_' + currentDate + ".pdf";
        this.setState({ snackClr: 'snack-info', snackOpen: true, snackMSg: 'Your download is being processed. The ' + fileNamePdf + ' file will download automatically to your browser when ready and Please do not close or reload the pages until download completes. We recommend you check your Downloads folder if you don’t see it in your browser.' });
        let ComponentName = 5;
        const Input = {
            "RegionID": CONFIG.RegionId,
            "URL": CONFIG.OPTZ_URL + "render-pdf/" + accessKey + '/' + ComponentName + '/0/' + usId + '/' + pta + "/" + searchId + "/" + graphOpen + "/" + unitType,
            "FileName": fileName,
        }
        // axios.post(CONFIG.API_URL + 'dashboard/generatepdfbypage', Input)
        axios(
            {
                url: CONFIG.API_URL + 'dashboard/generatepdfbypage',
                method: 'post',
                responseType: 'blob',
                data: Input
            })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNamePdf;
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(link.href);
                    link.remove();
                }, 3000);
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading1: false });
            })
    }

    componentDidMount() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        // For Current Store Info
        const data = {
            "UserStoreID": parseInt(this.props.match.params.storeid),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation', data)
            .then(response => {
                const locationInfoData = response.data.data.storeDashboard;
                this.setState({ locationInfoData });
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

        //For Graph and Table Content
        const Storedata = {
            "UserStoreID": parseInt(this.props.match.params.storeid),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'Dashboard/marketscoring', Storedata)
            .then(res => {
                let MarkScoring = res.data.data.scorings;
                let MarkStoreInfo = res.data.data.storeInformation;
                let Markets = res.data.data.markets;
                let MarketsDesc = res.data.data.marketDescriptions;
                this.setState({ MarkScoring, MarkStoreInfo, Markets, MarketsDesc });
                this.scoringData();
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    barhandleChange(CompId) {
        //loader 
        this.setState({ isLoading1: true });

        //getting the ids of to show or hide the graph data
        let GraphshowData = this.state.MarkScoring.filter(function (data) {
            return data.isSelected === true && data.storeId !== CompId;
        });

        if (GraphshowData.length > 7) {
            this.setState({ isLoading1: false, snackOpen: true, snackMSg: 'You can view up to 8 markets only, please remove any of the selected markets to add new ones.' });
        }
        else {

            //to change the selected value which clicked on table
            let uniquestore = [...new Set(this.state.MarkScoring.filter(function (data) {
                return data.isSelected === true ? data.storeId !== CompId : data.storeId === CompId
            }).map(item => item.storeId))].join(', ');
            //forloop
            let uniquestores = [...new Set(this.state.MarkScoring.filter(function (data) {
                return data.isSelected === true ? data.storeId !== CompId : data.storeId === CompId
            }).map(item => item.storeId))].join('/');

            //If User Remove all Markets, then uniquestore should be 0
            uniquestore = uniquestore === '' ? 0 : uniquestore;
            uniquestores = uniquestores === '' ? 0 : uniquestores;
            this.setState({ uniquestores });

            for (var i = 0; i < this.state.MarkScoring.length; i++) {
                if (this.state.MarkScoring[i].storeId === CompId) {
                    this.state.MarkScoring[i].isSelected = this.state.MarkScoring[i].isSelected === true ? false : true;
                }
            }
            let MarkScoring = this.state.MarkScoring;

            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }
            const data = {
                "UserStoreID": parseInt(this.props.match.params.storeid),
                "Competitors": uniquestore,
                "RegionId": CONFIG.RegionId
            };
            axios.post(CONFIG.API_URL + 'Dashboard/marketscoringgraph', data)
                .then(res => {
                    this.setState({ Markets: [], MarkScoringData: [], ChartTableData: {} });
                    let Markets = res.data.data.markets;
                    this.setState({ Markets, MarkScoring });
                    this.scoringData();
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        localStorage.removeItem('accessKey');
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }
                    else {
                        alert(err.response.data);
                    }
                });
        }
    }

    //To Assign the data for particular bar and tables
    scoringData() {
        const { MarkScoring, MarkStoreInfo, Markets, MarketsDesc } = this.state;
        /* Spliting the data in the order of StoreID In Scoring Tabl */
        let MarkScoringData = this.state.MarkScoringData;
        MarkScoringData.push({
            'statdata': MarkScoring.filter(function (data) {
                return data.storeId < 1 || data.isMyMarket === 1;
            }), 'dynamdata': MarkScoring.filter(function (data) {
                return data.storeId > 0 && data.isMyMarket !== 1;
            })
        });
        /* End of Spliting the data in the order of StoreID In Scoring Table */

        //Checking for data availability
        let AvailDataCheck = [];
        AvailDataCheck = Markets.filter(function (data) {
            return data.storeId > 0;
        });


        let ChartTableData = this.state.ChartTableData;
        if (Markets.length > 0 && AvailDataCheck.length > 0) {

            /* Spliting the data in the order of activityType */
            ChartTableData['lahMarkets'] = [];

            //For Total data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Total';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Total';
                })
            });

            //For Website data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Website';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Website';
                })
            });

            //For Website data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Search';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Search';
                })
            });

            //For Paid Search data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Paid Search';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Paid Search';
                })
            });

            //For Social Media data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Social Media';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Social Media';
                })
            });

            //For Contactability data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Contactability';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Contactability';
                })
            });

            /* end of Spliting the data in the order of activityType */

            /* Barchart table's Head data */
            ChartTableData['TableHead'] = Markets.filter(function (data) {
                return data.storeId > 0 && data.activityType === 'Total' || data.storeId < -2 && data.activityType === 'Total';
            });
            /* end of Barchart table's Head data */

            /* Barchart table's body data */
            ChartTableData['TableBody'] = [];

            //For Total data
            ChartTableData['TableBody'].push({
                'td1': 'Total', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Total' || data.storeId < -2 && data.activityType === 'Total';
                })
            });

            //For Website data
            ChartTableData['TableBody'].push({
                'td1': 'Website', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Website' || data.storeId < -2 && data.activityType === 'Website';
                })
            });

            //For Search data
            ChartTableData['TableBody'].push({
                'td1': 'Search', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Search' || data.storeId < -2 && data.activityType === 'Search';
                })
            });

            //For Paid Search data
            ChartTableData['TableBody'].push({
                'td1': 'Paid Search', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Paid Search' || data.storeId < -2 && data.activityType === 'Paid Search';
                })
            });

            //For Social Media data
            ChartTableData['TableBody'].push({
                'td1': 'Social Media', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Social Media' || data.storeId < -2 && data.activityType === 'Social Media';
                })
            });

            //For Contactability data
            ChartTableData['TableBody'].push({
                'td1': 'Contactability', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Contactability' || data.storeId < -2 && data.activityType === 'Contactability';
                })
            });

            /* end of Barchart table's body data */
        }
        this.setState({ MarkScoringData, MarkStoreInfo, MarketsDesc, Markets, ChartTableData, isLoading1: false });
        this.drawChart();
    }

    //Formatted in as per competitor
    // formData(data) {
    //     let uniquestore = [...new Set(data.map(item => item.storeId))];
    //     let CurrentActivity = this.state.grapghCatcheck;
    //     let Colorbarcode = ['#f69e4f', '#1b2c42', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
    //     var FData = [];

    //     for (var t = 0; t < uniquestore.length; t++) {
    //         var tFData = data.filter(a => a.storeId == uniquestore[t] && a.activityType === CurrentActivity);
    //         var mData = {};
    //         if (tFData) {
    //             mData = {
    //                 StoreID: uniquestore[t],
    //                 StoreName: tFData[0].storeName,
    //                 Score: tFData[0].score,
    //                 act: tFData[0].activityType,
    //                 Gcolor: Colorbarcode[t],
    //                 IsSelected: tFData[0].isSelected
    //             }
    //         }
    //         FData.push(mData);
    //     }
    //     return FData;
    // }

    //Bar Chart
    drawChart() {
        let { Markets, grapghCatcheck } = this.state;
        let actualData = [];
        let colorsData = [];
        let storeName = [];
        let maxScore = 0;
        let colorsCode = ['#f69e4f', '#1b2c42', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
        let filterMarkets = Markets.filter(fl => fl.storeId > 0 || fl.storeId < -2);
        let uniquestore = [...new Set(filterMarkets.map(item => item.storeId))];
        for (var t = 0; t < uniquestore.length; t++) {
            var tFData = filterMarkets.filter(a => a.storeId === uniquestore[t] && a.activityType === grapghCatcheck);
            let noValues = tFData.every(item => item.score === null || item.score === '' || item.score === undefined);
            if (!noValues) {
                let dataSeries = tFData[0].score;
                let storageName = tFData[0].storeName;
                maxScore = tFData[0].totalScore;
                actualData.push(dataSeries);
                storeName.push(storageName);
                colorsData.push(colorsCode[t]);
            }
        }

        const options = {
            colors: colorsData,
            chart: {
               
                id: `apexchart-example${Math.random()}`,  //when the state updates randam id has to change to render the changes
                    toolbar: {
                        show: false
                    },
            },
            tooltip: {
                enabled: true,
                showForNullSeries: false,
                x: {
                    formatter: function (value) {
                        let val = value === undefined || typeof (value) !== "string" ? '' : value;
                        return val;
                    }
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                style: {
                    fontSize: '11px',
                    fontFamily: "Roboto, Helvetica, Arial , sans-serif"
                },
            },
            yaxis: {
                
                min: 0,
                max: maxScore,
                tickAmount: 5,
                labels: {
                    formatter: function (value) {
                    return  value === 0 ? ""  : `${value.toFixed(0)}/${maxScore ? maxScore : 0}`;
                    },
                    style: {
                        colors: '#140000',
                        fontSize: '10px'
                    },
                },
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 5,
                    dataLabels: {
                        position: 'top',
                    },
                    columnWidth: '25%',
                    barHeight: '50%',
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '10px',
                    colors: ["#304758"]
                },
                formatter: function (val, opts) {
                    return val === 0 ? '' : val;
                },
            },
            grid: { show: false },
            xaxis: {
                categories: storeName,
                labels: {
                    show: true,
                    rotate: 0,
                    hideOverlappingLabels: false,
                    trim: true,
                    formatter: function (value) {
                        let val = value === undefined || typeof (value) !== "string" ? '' : value.toUpperCase();
                        return val;
                    },
                    style: {
                        colors: '#8C9AA6',
                        fontSize: '10px'
                    },
                }
            },
            legend: {
                show: false,
            }
        }

        const series = [{
            name: '',
            data: actualData
        }]

        if (actualData.length > 0) {
            this.setState({ options, series })
        } else {
            this.setState({
                options: {
                    chart: {
                        id: 'apexchart-example'
                    },
                    noData: {
                        text: "No Data Available.",
                        align: 'center',
                        verticalAlign: 'top',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: "#F60000",
                            fontSize: '20px',
                            fontFamily: "Helvetica"
                        }
                    },
                    yaxis: { show: false },
                    grid: { show: false },
                    xaxis: {
                        labels: {
                            show: false
                        },
                    }
                },
                series: []
            })
        }
    }

    // Text Wrap For Graph X-axis label
    /* Score Card Dialog box functions */
    dialogContentOpen(id, YS_data) {
        this.setState({
            isLoading1: true
        })
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        const data = {
            "StoreId": parseInt(id),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'dashboard/marketscorecard', data)
            .then(res => {
                //let Scorecard = res.data.scoreCard.marketScoreCardDetails;
                let ScorecardDet = res.data.scoreCard.marketScoreCardDetails;
                let MarketSCdet = this.state.MarketSCdet;

                if (ScorecardDet.length > 0) {
                    //to get Market Details
                    MarketSCdet.id = [...new Set(ScorecardDet.map(item => item.storeId))];
                    MarketSCdet.StoreName = YS_data === 'YourStore' ? ['Your Store'] : [...new Set(ScorecardDet.map(item => item.storeName))];
                    MarketSCdet.StoreAddress = [...new Set(ScorecardDet.map(item => item.address))];
                    MarketSCdet.CheckDate = [...new Set(ScorecardDet.map(item => item.dtCollected))];
                    MarketSCdet.M_TotScore = [...new Set(ScorecardDet.map(item => item.totalScore))];


                    //For Market Score Card For Map Details                
                    let uniquemscore = [...new Set(ScorecardDet.map(item => item.marketingActivity))];
                    var Scorecard = [];
                    for (var t = 0; t < uniquemscore.length; t++) {
                        var tempData = ScorecardDet.filter(a => a.marketingActivity == uniquemscore[t]);
                        for (var s = 0; s < tempData.length; s++) {
                            var inlineData = {
                                storeId: tempData[s].storeId,
                                storeName: tempData[s].storeName,
                                address: tempData[s].address,
                                marketingActivity: s === 0 ? tempData[s].marketingActivity : 'empty',
                                name: tempData[s].name,
                                score: tempData[s].score,
                                activityScore: tempData[s].activityScore,
                                activityScoreTotal: tempData[s].activityScoreTotal,
                                totalScore: tempData[s].totalScore,
                                dtCollected: tempData[s].dtCollected,
                                id: tempData[s].id,
                                rowcount: s === 0 ? tempData.length : 0,
                                color: t % 2 ? 'odd-m-score' : 'even-m-score'
                            }
                            Scorecard.push(inlineData);
                        }
                    }
                    this.setState({ dialogOpen: true, Scorecard, MarketSCdet, isLoading1: false });
                }
                else {
                    this.setState({ MarketSCdet, isLoading1: false, snackOpen: true, snackMSg: 'Score Card is not available in this market.' });
                }
            })
            .catch(err => {
                this.setState({ isLoading1: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    dialogClose() {
        this.setState({ dialogOpen: false, MarketSCdet: {}, Scorecard: [] });
    }
    /* End of Score Card Dialog box functions */

    handleChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount });
    };

    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    render() {
        const { order, orderBy, grapghCatcheck, graphOpen, MarkScoringData, MarkStoreInfo, ChartTableData, MarketsDesc, snackOpen, snackMSg,
            locationInfoData, isLoading1, trackModalData, pageCount, Markets, MarketSCdet, Scorecard } = this.state;
        let ComActivityType = ['Total', 'Website', 'Search', 'Paid Search', 'Social Media', 'Contactability'];
        let Colors = ['mystorebarclr', 'barclr1', 'barclr2', 'barclr3', 'barclr4', 'barclr5', 'barclr6', 'barclr7'];
        const defaultProps = {
            helpTitle: <Typography>Marketing Scorecard - an explanation</Typography>,
            helpContent: "<p>The StorTrack Marketing Scorecard is a devised scoring mechanism for judging the activity and effectiveness of a Store's local marketing, so as to enable us to create a methodology for comparison and competitive insight.</p><p>The Scorecard is created by monitoring 5 different areas of a Store's marketing activities: it's website, it's organic presence on search engines, it's paid placement in search engines, the social media presence of the Store and finally how easy the Store makes it for potential new tenants to contact it. In each of these five categories, StorTrack has created a weighted scoring process that then rolls up to a category score. The category scores are what you see on the Marketing Scorecard page and the method by which we compare Stores side-by-side.</p><p>The Scoring Process is detailed in the attached PDF. Please click the link to download the full Scoring methodology. <a href=" + CONFIG.STK_URL + "/StorTrack_Optmize_MarketScoringProcess.pdf target='_blank'>Click here</a></p><p>For any further information on StorTrack's Marketing Scorecard please contact Support directly.</p><p><i>*Your store scoring is included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/U41xa7ELS-o' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        }
        return (
            <div className="marketscore">
                {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                <Breadcrumbs separator="›" aria-label="Breadcrumb" className="breadcrumb-top breadcrumb-float-left">

                    {localStorage.getItem('isMVP_User') === "false" ? <Link to="/my-market/" color="inherit">My Markets</Link> : ""}

                    <Link to={{ pathname: `/market-dashboard/${this.props.match.params.storeid}` }} color="inherit" >Go to Market Dashboard</Link>

                    <Typography component="h6" variant="headline" className="link-tag">Marketing Scorecard </Typography>
                </Breadcrumbs>
                <Button disabled={!isLoading1 ? false : true} className="mb-2 apply-btn download-pdf" onClick={this.downLoadPDF.bind(this)}>
                    <FontAwesomeIcon icon={faFilePdf} />
                    PDF Download
                </Button>
                <div className="clear"></div>
                <div className="content-1">
                    <Row className="overview-header">
                        <Col md={5} className="mr-2 custom-md-5">
                            <Col md={12} className="p-v-15 border-box shadow-sm rounded">
                                <Row className="ht-100">
                                    <Col md={6} className="padding-right-8">
                                        <h2 className="beta-marketing">Marketing Scorecard <HelpTextPopup parm={defaultProps} /></h2>
                                        <CompetitorStore ref={this.compstorechild} />
                                        {isLoading1 && (locationInfoData.compsCount === 0 || locationInfoData.compsCount === null) ? '' : locationInfoData.compsCount === 0 || locationInfoData.compsCount === null ? <div className="align-stores-btm"><p>Analyzing N/A</p></div> :
                                            <div className="align-stores-btm"><p>Analyzing <Link to="#" onClick={() => this.getParams(locationInfoData)}>{locationInfoData !== null && locationInfoData.compsCount ? parseInt(locationInfoData.compsCount) > 1 ? locationInfoData.compsCount + ' stores' : locationInfoData.compsCount + ' store' : 'N/A'} </Link></p></div>
                                        }
                                    </Col>
                                    {isLoading1 && locationInfoData.length === 0 ? '' :
                                        <Col md={6} className="pl-0">
                                            <h3 className="S_captalize">{locationInfoData !== null && locationInfoData.storeName ? locationInfoData.storeName : 'Your Store'}</h3>
                                            <div className={locationInfoData && locationInfoData.storeZoneCoverage ? locationInfoData.storeZoneCoverage.length > 24 ? "pd-btm-35" : "pd-btm-20" : "pd-btm-20"}><p className="m-0">{locationInfoData !== null && locationInfoData.storeAddress1 ? locationInfoData.storeAddress1.trim() + ', ' : ''}{locationInfoData !== null && locationInfoData.storeAddress2 ? locationInfoData.storeAddress2 + ', ' : ''}{locationInfoData !== null && locationInfoData.storeCity ? locationInfoData.storeCity + ', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === null || locationInfoData.storeCity === '') && (locationInfoData.storeZipCode === null || locationInfoData.storeZipCode === '') ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''}{locationInfoData !== null && locationInfoData.storeState ? locationInfoData.storeState : ''} {locationInfoData !== null && locationInfoData.storeZipCode ? locationInfoData.storeZipCode : ''}</p></div>
                                            <div className="align-stores-btm"><p className="m-0 pt-1">Coverage: {locationInfoData !== null && locationInfoData.storeZoneCoverage ? locationInfoData.storeZoneCoverage : '0 mile radius'}</p></div>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Col>
                        <Col md={7} className="padding-left-0 marketing-right">
                            <Col md={12} className="price-top-right border-box shadow-sm rounded">
                                <Row className="height-full">
                                    <Col>
                                        <div className="market-label">Current Weakest Marketing  Area</div>
                                        {MarkStoreInfo.weakestMarketingArea ? <div className="market-weak"> {MarkStoreInfo.weakestMarketingArea} </div> : ''}
                                        <div className="align-stores-btm"><div className="market-value">{MarkStoreInfo.weakestMarketingAreaScore >= 0 && MarkStoreInfo.weakestMarketingAreaScore !== null ? MarkStoreInfo.weakestMarketingAreaScore + '/' + MarkStoreInfo.weakestMarketingAreaTotalScroe : isLoading1 === false ? 'N/A' : ''} {MarkStoreInfo.strongestMarketingAreaScore ? <span className="btm-label">(Average)</span> : ''}</div></div>
                                    </Col>
                                    <Col className="common-col">
                                        <div className="market-label">Current Strongest Marketing Area</div>
                                        {MarkStoreInfo.strongestMarketingArea ? <div className="market-weak"> {MarkStoreInfo.strongestMarketingArea}</div> : ''}
                                        <div className="align-stores-btm"><div className="market-value">{MarkStoreInfo.strongestMarketingAreaScore >= 0 && MarkStoreInfo.strongestMarketingAreaScore !== null ? MarkStoreInfo.strongestMarketingAreaScore + '/' + MarkStoreInfo.strongestMarketingAreaTotalScroe : isLoading1 === false ? 'N/A' : ''} {MarkStoreInfo.strongestMarketingAreaScore ? <span className="btm-label">(Average)</span> : ''}</div></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </div>
                <div>

                    {/* <Link to={"/marketing-score-Pdf/"+this.props.match.params.storeid+'/'+this.state.order+'/'+this.state.orderBy+'/'+this.state.uniquestores} color="inherit" className="link-tag">Download Pdf</Link> */}
                </div>
                <Paper item="true" xs={12} className="dashboard-layout last-months market-activity">
                    <Row>
                        <Col md="12" id="marketanalysis">
                            <div className="ms_charthead pl-3 pt-3">
                                <h4>Marketing Activity Analysis    <a href="#marketgrid" className="add_edit_link">Go to All Market View to Add/Remove Stores </a></h4>
                            </div>
                        </Col>
                        {Markets && Markets.length > 0 ?
                            <React.Fragment>
                                <Col md="12" lg="9" className="padding-right-0" >
                                    <div className="ms_charts p-3 padding-right-0 market-score-ms-chart pb-0" >
                                        <ReactTable className="msc activity-table marketings-table" responsive >
                                            <thead>
                                                {ChartTableData.TableHead && ChartTableData.TableHead.length > 0 ?
                                                    <React.Fragment>
                                                        <tr>
                                                            <th rowSpan="2">Marketing Activity</th>
                                                            {ChartTableData.TableHead.map((th_data, index) => {
                                                                let sAddress = th_data.address ? ', \n' + th_data.address : '';
                                                                const text = <div style={{ whiteSpace: 'pre-line' }}>{th_data.storeName + sAddress}</div>
                                                                let dotline;
                                                                let storName;
                                                                if (ChartTableData.TableHead.length > 5) {
                                                                    dotline = th_data.storeName.length > 18 ? '..' : '';
                                                                    storName = th_data.storeName.substring(0, 18) + dotline;
                                                                }
                                                                else if (ChartTableData.TableHead.length === 4 || ChartTableData.TableHead.length === 5) {
                                                                    dotline = th_data.storeName.length > 40 ? '..' : '';
                                                                    storName = th_data.storeName.substring(0, 40) + dotline;
                                                                }
                                                                else {
                                                                    storName = th_data.storeName;
                                                                }
                                                                return (
                                                                    <Tooltip data-html="true" title={text} key={index} placement="top"><th className={Colors[index]} >{storName}</th></Tooltip>
                                                                );
                                                            })}
                                                        </tr>
                                                        <tr className="scorecardRow">
                                                            {ChartTableData.TableHead.map((th_sc_data, index) => {
                                                                var mystore_chk = th_sc_data.isMyStore === true ? 'YourStore' : '';
                                                                return (
                                                                    <th className='tg_scorecard' key={th_sc_data.storeId}><span className="sc_link_btn" onClick={() => this.dialogContentOpen(th_sc_data.storeId, mystore_chk)}>see scorecard</span></th>
                                                                );
                                                            })}
                                                        </tr>
                                                    </React.Fragment>
                                                    :
                                                    <tr>
                                                        <th>Marketing Activity</th>
                                                    </tr>
                                                }
                                            </thead>
                                            <tbody>
                                                {ChartTableData.TableBody && ChartTableData.TableBody.length > 0 ? ChartTableData.TableBody.map((row1, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td align="right" className="ms_tablebtn">
                                                                    <span className="markets-label">{row1.td1}</span>
                                                                    {grapghCatcheck === row1.td1 ?
                                                                        <Button color="danger" className="add-to-graph" onClick={() => this.onSelecCatGrapgh(row1.td1)} size="sm"><img src={tableminus} alt="" /></Button>
                                                                        :
                                                                        <Button color="primary" className="remove-to-graph" onClick={() => this.onSelecCatGrapgh(row1.td1)} size="sm"><img src={tableplus} alt="" /></Button>
                                                                    }
                                                                </td>
                                                                {row1.td2.map((row) => {
                                                                    return (
                                                                        <React.Fragment key={row.storeId}>
                                                                            <td>{row.score + '/' + row.totalScore}</td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                            <tr className={graphOpen === row1.td1 ? '' : 'disablegraphrow'} >
                                                                <td colSpan={row1.td2.length + 1} className="set-gray-background">
                                                                    <Chart options={this.state.options} series={this.state.series} type="bar" style={{ width: "100%" }} height={200} />
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td>N/A</td>
                                                        {/* <Chart options={this.state.options} series={this.state.series} type="bar" style={{ width: "100%" }} height={200} /> */}
                                                    </tr>
                                                }
                                            </tbody>
                                        </ReactTable>
                                    </div>
                                </Col>
                                <Col md="12" lg={3} className="padding-left-7">
                                    <div className="ys_cont p-2 pt-3">
                                        <ReactTable className="mss market-right-table only-market-right" responsive>
                                            <thead>
                                                <tr>
                                                    <th className="ml">Market Low</th>
                                                    <th className="ma">Market Average</th>
                                                    <th className="mh">Market High</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ChartTableData.lahMarkets && ChartTableData.lahMarkets.length > 0 ? ChartTableData.lahMarkets.map((tr1data, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr className={ComActivityType[index] === 'PriceRange' ? 'disablegraphrow' : ''} >
                                                                {tr1data.tr1.map((td, index) => {
                                                                    if (td.storeId !== -3) {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                {td.storeName === "Market Low" ?
                                                                                    <td className="ml">{td.score + '/' + td.totalScore}</td>
                                                                                    :
                                                                                    td.storeName === "Market Average" ?
                                                                                        <td className="ma">{td.score + '/' + td.totalScore}</td>
                                                                                        :
                                                                                        td.storeName === "Market High" ?
                                                                                            <td className="mh">{td.score + '/' + td.totalScore}</td>
                                                                                            :
                                                                                            <td>N/A</td>
                                                                                }
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                })}
                                                            </tr>

                                                            {tr1data.tr2.length > 0 ? tr1data.tr2.map((rowdata, index) => {
                                                                let percentage = Number(((rowdata.score / rowdata.totalScore) * 100).toFixed(2));
                                                                return (
                                                                    <tr key={index} className={graphOpen === rowdata.activityType ? '' : 'disablegraphrow'} >
                                                                        <td colSpan="3">
                                                                            <div className="pb-4 pt-4 pl-0 pr-0 pt-custom-2" align="center">
                                                                                <Typography variant="caption" className="your-score">Your Score</Typography>
                                                                                <Typography variant="h6" className="score-value">{rowdata.score >= 0 ? rowdata.score + '/' + rowdata.totalScore : 'N/A'}</Typography>
                                                                                {rowdata.score >= 0 ? <Typography variant="body1" className="your-score">{percentage}%</Typography> : ''}
                                                                                {rowdata.myStoreRank ? <h5>{rowdata.myStoreRank}</h5> : ''}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                                :
                                                                <tr key={index} className={graphOpen === ComActivityType[index] ? '' : 'disablegraphrow'} >
                                                                    <td colSpan="3">
                                                                        <div className="pb-4 pt-4 pl-0 pr-0 pt-custom-2" align="center">
                                                                            <Typography variant="caption" className="your-score">Your Score</Typography>
                                                                            <Typography variant="h6" className="score-value">N/A</Typography>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan="3" align="center">N/A</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </ReactTable>
                                    </div>
                                </Col>
                            </React.Fragment>
                            :
                            <Col>
                                <Typography variant="body1" className="p-4 no-data">{isLoading1 === false ? 'There are no competitors available in this market.' : ''}</Typography>
                            </Col>

                        }
                    </Row>
                    {Markets && Markets.length > 0 ?
                        <Row>
                            <Col md={12} lg={9} className="padding-right-0 only-for-desktop">
                                <Row className="p-3 custom-row">
                                    <Col lg={4} md={12} className="passive-col">
                                        <Paper className="p-4 max-height">
                                            <Typography align="center" variant="subtitle2" className="top-head">This market is considered</Typography>
                                            <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.marketScoreValue ? MarketsDesc.marketScoreValue : ''}</Typography>
                                            <Typography align="center" variant="body2" className="passive-body">{MarketsDesc ? MarketsDesc.marketScore : ''}</Typography>
                                        </Paper>
                                    </Col>
                                    <Col lg={4} md={12} className="passive-col">
                                        <Paper className="p-4 max-height">
                                            <Typography align="center" variant="subtitle2" className="top-head">The most competitive Store is</Typography>
                                            <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.highestMarketName ? MarketsDesc.highestMarketName : ''}</Typography>
                                            <Typography align="center" variant="body2" className="passive-body">{MarketsDesc ? MarketsDesc.highestMarket : ''}</Typography>
                                        </Paper>
                                    </Col>
                                    <Col lg={4} md={12} className="passive-col">
                                        <Paper className="p-4 max-height">
                                            <Typography align="center" variant="subtitle2" className="top-head">The lowest Marketing Activity area is</Typography>
                                            <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.lowestMarketActivity ? MarketsDesc.lowestMarketActivity : ''}</Typography>
                                            <Typography align="center" variant="body2" className="passive-body">{MarketsDesc.lowestMarket ? MarketsDesc.lowestMarket : 'There are no competitors available in this market.'}</Typography>
                                        </Paper>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        ''}
                    <Row>
                        <Col>
                            <div className="ms_table p-3" id="marketgrid">
                                <h4 className="markviewhead"  >All Market View <a href="#marketanalysis" className="add_edit_link">Go to Core Market View </a></h4>
                                <div className="overflow-scroll-table br-0">
                                    <Paper className="bx-sh-0">
                                        <Table className="mst all-market-table market-score-table" responsive="true">
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                                rowCount={MarkScoringData.length}
                                            />

                                            {MarkScoringData ?
                                                MarkScoringData.map((msd, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <TableBody>
                                                                {msd.statdata.map((row1, index) => {
                                                                    const labelId = index;
                                                                    return (
                                                                        <TableRow key={row1.storeId} className="disabled_color">
                                                                            <TableCell id={labelId} scope="row" padding="none">
                                                                                {row1.storeName}
                                                                            </TableCell>
                                                                            <TableCell>{row1.score !== null ? row1.score + '/' + row1.totalScore + '(' + row1.totalPercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row1.websiteScore !== null ? row1.websiteScore + '/' + row1.websiteScoreTotal + '(' + row1.websiteScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row1.searchScore !== null ? row1.searchScore + '/' + row1.searchScoreTotal + '(' + row1.searchScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row1.paidScore !== null ? row1.paidScore + '/' + row1.paidScoreTotal + '(' + row1.paidScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row1.socialMediaScore !== null ? row1.socialMediaScore + '/' + row1.socialMediaScoreTotal + '(' + row1.socialMediaScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row1.contactabilityScore !== null ? row1.contactabilityScore + '/' + row1.contactabilityScoreTotal + '(' + row1.contactabilityScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell className="ms_card normal-weight">{row1.isMyMarket === 1 ? <span className="sc_link_btn" onClick={() => this.dialogContentOpen(row1.storeId, 'YourStore')}>See Full Scorecard</span> : ''}</TableCell>
                                                                            <TableCell className="ms_action"></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                            <TableBody>
                                                                {this.stableSort(msd.dynamdata, this.getSorting(order, orderBy)).map((row, index) => {
                                                                    const labelId = index;
                                                                    return (
                                                                        <TableRow key={row.storeId} className={row.storeId > 0 ? '' : "disabled_color"}>
                                                                            <TableCell id={labelId} scope="row" padding="none"  >
                                                                                <span className="store-names">{row.storeName}</span>
                                                                                <span className="stores-address">{row.address}</span>
                                                                            </TableCell>
                                                                            <TableCell >{row.score !== null ? row.score + '/' + row.totalScore + '(' + row.totalPercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row.websiteScore !== null ? row.websiteScore + '/' + row.websiteScoreTotal + '(' + row.websiteScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row.searchScore !== null ? row.searchScore + '/' + row.searchScoreTotal + '(' + row.searchScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row.paidScore !== null ? row.paidScore + '/' + row.paidScoreTotal + '(' + row.paidScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row.socialMediaScore !== null ? row.socialMediaScore + '/' + row.socialMediaScoreTotal + '(' + row.socialMediaScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell>{row.contactabilityScore !== null ? row.contactabilityScore + '/' + row.contactabilityScoreTotal + '(' + row.contactabilityScorePercent + '%)' : 'N/A'}</TableCell>
                                                                            <TableCell className="ms_card"><span className="sc_link_btn" onClick={() => this.dialogContentOpen(row.storeId, '')}>See Full Scorecard</span></TableCell>
                                                                            <TableCell className="ms_action">
                                                                                {row.storeId > 0 ?
                                                                                    <div>
                                                                                        {row.score !== null ? row.isSelected === true ?
                                                                                            <Button color="danger" padding="none" className="add-to-graph" onClick={this.barhandleChange.bind(this, row.storeId)}><Tooltip title="Remove from graph" placement="top"><img src={removefrommap} alt="collapse" /></Tooltip></Button>
                                                                                            :
                                                                                            <Button color="info" className="remove-to-graph" onClick={this.barhandleChange.bind(this, row.storeId)}><Tooltip title="Show on graph" placement="top"><img src={addtomap} alt="expand" /></Tooltip></Button>
                                                                                            : ''}
                                                                                    </div>
                                                                                    : ''}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </React.Fragment>
                                                    );
                                                })
                                                :
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="no-data" colSpan="8">{isLoading1 === false ? 'There are no competitors available in this market.' : ''}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            }
                                        </Table>
                                    </Paper>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackOpen}
                        className={this.state.snackClr + ' snackbar bg-color-primary custom-snackbar'}
                        maxsnack={3}
                        autoHideDuration={30000}
                        onClose={this.snackHandleclose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.snackHandleclose.bind(this)}
                            >
                                <CloseIcon />
                            </IconButton>
                        ]}
                        message={<span id="message-id">{snackMSg}</span>}

                    />
                </Paper>
                <Dialog className='sc_dialog scorecard-popup' open={this.state.dialogOpen} scroll={'body'} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="scroll-dialog-title">
                        Marketing Activity Scorecard
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="ms_closeModal"
                            onClick={this.dialogClose.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Row className="market-top">
                            <Col md="8">
                                <h3 className="market-profile-heading">
                                    <Row>
                                        <div className="col-lg-4 label-address">Store Details:</div>
                                        {isLoading1 === false ?
                                            <div className="col-lg-8 pop-store-add">
                                                <span><b>{MarketSCdet.StoreName ? MarketSCdet.StoreName.length > 0 ? MarketSCdet.StoreName[0] + ',' : 'N/A' : 'N/A'}</b></span><br /><span class="s_address">{MarketSCdet.StoreAddress ? MarketSCdet.StoreAddress.length > 0 ? MarketSCdet.StoreAddress[0] : '' : ''}</span>
                                            </div> : ''}
                                    </Row>
                                </h3>
                            </Col>
                            <Col md="4">
                                <h4 className="text-right market-profile-heading">Last Checked:   {isLoading1 === false ? <span className="heading-span">{MarketSCdet.CheckDate ? MarketSCdet.CheckDate.length > 0 ? moment(MarketSCdet.CheckDate[0]).format('DD-MMM-YYYY') : 'N/A' : 'N/A'}</span> : ''}</h4>
                                <h4 className="text-right market-profile-heading total-score">Total Score:   {isLoading1 === false ? <span className="heading-span">{MarketSCdet.M_TotScore ? MarketSCdet.M_TotScore.length > 0 ? MarketSCdet.M_TotScore + '/100' : 'N/A' : 'N/A'}</span> : ''}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="market-height">
                                    <Table className="mst all-market-table market-score-table marketings-table" responsive="true">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>MARKETING ACTIVITY</TableCell>
                                                <TableCell>CATEGORY</TableCell>
                                                <TableCell>SCORE</TableCell>
                                                <TableCell>TOTAL</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Scorecard.length > 0 ? Scorecard.map((data, index) => {
                                                return (
                                                    <TableRow className={data.color}>
                                                        {data.marketingActivity !== 'empty' ?
                                                            <TableCell rowSpan={data.rowcount > 0 ? data.rowcount : 0} className={data.rowcount > 0 ? 'border-right-cell font-16-td' : 'font-16-td'}>{data.marketingActivity}</TableCell>
                                                            : ''}
                                                        <TableCell className="border-right-cell">{data.name}</TableCell>
                                                        <TableCell className="pnk-cen">{data.score}</TableCell>
                                                        {data.marketingActivity !== 'empty' ?
                                                            <TableCell rowSpan={data.rowcount > 0 ? data.rowcount : 0} className={data.rowcount > 0 ? 'border-left-cell font-20-td' : 'font-20-td'}>{data.activityScore + '/' + data.activityScoreTotal}</TableCell>
                                                            : ''}
                                                    </TableRow>
                                                );
                                            })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan="4" className="no-data"> {isLoading1 === false ? 'No data Available' : ''}</TableCell>
                                                </TableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headRows = [
        { id: 'storeName', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'score', numeric: true, disablePadding: false, label: 'Total Score' },
        { id: 'websiteScore', numeric: true, disablePadding: false, label: 'Website' },
        { id: 'searchScore', numeric: true, disablePadding: false, label: 'Search' },
        { id: 'paidScore', numeric: true, disablePadding: false, label: 'Paid Search' },
        { id: 'socialMediaScore', numeric: true, disablePadding: false, label: 'Social Media' },
        { id: 'contactabilityScore', numeric: true, disablePadding: false, label: 'Contact' },
        { id: 'scorecard', numeric: true, disablePadding: false, label: 'Scorecard' },
        { id: 'moreoptions', numeric: true, disablePadding: false, label: 'More Options' }
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align='center'
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                            disabled={row.id !== 'moreoptions' && row.id !== 'scorecard' ? false : true}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
} 