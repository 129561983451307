import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget, } from 'react-dnd';
import flow from 'lodash/flow';
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Row, Modal, ModalHeader, ModalBody, Button, Tooltip  } from 'reactstrap';
import { AvForm, AvField, AvCheckboxGroup } from 'availity-reactstrap-validation';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowDown from '../../../Assets/Images/red.png';
import Arrowup from '../../../Assets/Images/green.png';
import addnew from '../../../Assets/Images/addnew.png';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import ReactHtmlParser from 'react-html-parser';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import editicon from '../../../Assets/Images/edit.png';

import { CONFIG } from '../../../utils/config';
import axios from 'axios';
const actionsStyles = theme => ({
  tablepaggination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing * 2.5,
  },
});

class MyMarket extends React.Component {

  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };


  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.tablepaggination}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
  MyMarket,
);

const cardSource = {
  beginDrag(props) {
    return {
      id: props.searchid,
      index: props.index,
    }
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = (findDOMNode(
      component,
    )).getBoundingClientRect();

    // Get vertical middle
    // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    //const hoverClientY = (clientOffset).y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //   return;
    // }

    //should not drag first div.
    if (dragIndex === 0 || hoverIndex === 0) { return; }

    // Dragging upwards
    // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //   return;
    // }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
}

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modal: false,
      addmodal: false,
      deleteModal: false,
      deleteId: '',
      spacetype: 'Unit',
      ClimateControlled: '-1',
      Alarm: '-1',
      DoorType: '-1',
      DriveUp: '-1',
      Elevator: '-1',
      Power: '-1',
      OutdoorAccess: '-1',
      HumidityControlled: '-1',
      ad_Size: 'predefined',
      userStore: [],
      userStoreList: [],
      countryCode: '',
      parkingminlength: '',
      parkingmaxlength: '',
      lengthWidthMatches: '',
      Car: '',
      RV: '',
      Large: '',
      unitDetail: [],
      editisSpecifiedSizes: '',
      editSpaceChange: '',
      editParkUnits: '',
      editParkingisSpecifiedSizes: '',
      editparkingmaxlength: '',
      editparkingminlength: '',
      editCar: '',
      editRV: '',
      editLarge: '',
      emptyParking: '',
      emptyWidth: '',
      editlengthWidthMatches: 0,
      extractMatchchange: 0,
      widthLengthchange: 0,
      editextacttMatches: 0,
      editwlMatchPredefined: 0,
      editSearchId: '',
      editName: '',
      trackModalData: '',
      storeModal: false,
      pageCountMDT: 0,
      pageCount: 0,
      resData: '',
      snackOpen: false,
      snackMSg: '',
      hideSpecifiedtext: '',
      WidthPlusMinus: '',
      LengthPlusMinus: '',
      HeightPlusMinus: '',
      tooltipOpen:false,
     
      // storeid:props.location.storeid
    };
    this.trackModal = this.trackModal.bind(this);
    this.trackModalClose = this.trackModalClose.bind(this);
    this.snackHandleclose = this.snackHandleclose.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }

  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    avgPrice: PropTypes.any.isRequired,
    priceTrend: PropTypes.any.isRequired,
    myStorePrice: PropTypes.any.isRequired,
    moveCard: PropTypes.func.isRequired,
    currency: PropTypes.any.isRequired,
    countryCode: PropTypes.any.isRequired,
    storeid: PropTypes.any.isRequired,
    StoreName: PropTypes.any.isRequired,
    StoreAddress: PropTypes.any.isRequired,
    StoreCity: PropTypes.any.isRequired,
    StoreState: PropTypes.any.isRequired,
    StoreZipcode: PropTypes.any.isRequired,
    StoreRadius: PropTypes.any.isRequired,
    noOfStores: PropTypes.any.isRequired,
    storeCount: PropTypes.any.isRequired,
    priceTrends1: PropTypes.any.isRequired,

  }
  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };
  resetEditUnit(id) {

    //this.myFormupdate && this.myFormupdate.reset();
    let unitDetail = this.state.unitDetail;
    unitDetail = [];
    this.setState({
      unitDetail
    })
    this.toggleModal(id);

  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  toggleModal(id) {
   
    this.setState({
      isLoading: true
    })
    this.setState(state => ({ modal: true }))
    const editParam = id.split("/");
    let Sid = editParam[0];
    let countryCode = editParam[1];
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    this.state.countryCode = countryCode;
    this.setState({ countryCode: this.state.countryCode });
    this.userStoreUnit();
    axios.post(CONFIG.API_URL + 'market/unitdetail/' + Sid + '/' + CONFIG.RegionId)
      .then(res => {
        let resData = res.data.competitor;
        let unitDetail = res.data.competitor;
        let editSearchId = unitDetail.searchID;
        let editlengthWidthMatches = unitDetail.wlMatchSpecific === true ? 1 : 0;
        let editextacttMatches = unitDetail.exactMatch === true ? 1 : 0;
        let editwlMatchPredefined = unitDetail.wlMatchPredefined === true ? 1 : 0;
        let editName = unitDetail.name;
        let editisSpecifiedSizes = '';
        let editSpaceChange = '';
        let editParkUnits = '';
        let editParkingisSpecifiedSizes = '';
        let editparkingminlength = '';
        let editparkingmaxlength = '';
        let hideSpecifiedtext = '';
        //console.log(this.state.editlengthWidthMatches);
        if (unitDetail.isSpecifiedSizes === true) {
          editisSpecifiedSizes = 'editSpecific';
          hideSpecifiedtext = 2;
        }
        else if (unitDetail.sqft > 0 || unitDetail.sqftTo > 0) {
          editisSpecifiedSizes = 'editSquareFootage';
          hideSpecifiedtext = 3;
        }
        else {
          editisSpecifiedSizes = 'editPredefined';
          hideSpecifiedtext = 1;
        }
        if (unitDetail.spaceType === 'Unit') {
          editSpaceChange = "Unit";


        }
        else {
          editSpaceChange = "Parking";


          if (unitDetail.rv === 1) {
            editParkUnits = "2";

          } else if (unitDetail.car === 1) {
            editParkUnits = "1";

          } else if (unitDetail.otherVehicle === 1) {
            editParkUnits = "3";

          } else {
            editParkUnits = "-1";

          }
          if (unitDetail.car === 1 || unitDetail.otherVehicle === 1 || unitDetail.rv === 1 || unitDetail.isSpecifiedSizes===false) {
            editParkingisSpecifiedSizes = 'editParkingPredefined';
            editparkingminlength = '';
            editparkingmaxlength = '';
          }
          else {
            editParkingisSpecifiedSizes = 'editParkingSpecific';
            editparkingminlength = unitDetail.parkingminlength;
            editparkingmaxlength = unitDetail.parkingmaxlength;
          }
        }
        this.setState({ unitDetail, resData, editSearchId, editName, editlengthWidthMatches, editextacttMatches, editwlMatchPredefined, editisSpecifiedSizes, editSpaceChange, editParkUnits, editParkingisSpecifiedSizes, editparkingminlength, editparkingmaxlength, hideSpecifiedtext });

      })
      .catch((err) => {
        // console.log(err)
        this.setState({ isLoading: false });
        if (err.res.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.res.data);
        }
      });
  }
  /*Add Unit Type*/
  addtoggleModal(countryCode) {

    //countryCode  = countryCode;
    this.setState({ isLoading: true })
    this.state.countryCode = countryCode;
    this.setState({ countryCode: this.state.countryCode });
    this.userStoreUnit();
    this.setState({ isLoading: false });
    this.setState(state => ({ addmodal: !state.addmodal }))
    this.state.ad_Size = 'predefined';
    let spacetype = "Unit";
    let fields = "";
    let LengthPlusMinus = '';
    let HeightPlusMinus = '';
    let WidthPlusMinus = '';
    this.setState({ ad_Size: this.state.ad_Size, spacetype, fields, LengthPlusMinus, HeightPlusMinus, WidthPlusMinus });

  }
  userStoreUnit() {
    let countryCode = this.state.countryCode;
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'market/lookupdataunit/' + countryCode+'/'+CONFIG.RegionId)
      .then(res => {
        this.setState({ isLoading: false });
        let userStore = res.data.userstore;
        let userStoreList = res.data.userstore;
        this.setState({ userStore })
        this.setState({ userStoreList })
        //console.log(res);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.res.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });

  }

  toggleClose = () => this.setState(state => ({ addmodal: false }))

  
  editMarketSubmit(event, errors, values) {

    if (errors.length > 0) {

      console.log(errors);
    }
    else {
      var cc = false;
      if(values.editcountryCode === "US" || values.editcountryCode === "CA" || CONFIG.RegionId === 3){
        cc = true;
      }
      if (values.editSpaceType === "Unit" && this.state.editisSpecifiedSizes === "editSpecific" && values.editWidth === "" && cc === false) {
        this.setState({ snackOpen: true, snackMSg: "Please enter the specific size." });
      }
      else if (values.editSpaceType === "Unit" && this.state.editisSpecifiedSizes === "editSpecific" && values.editWidth === "" && cc === true) {
        this.setState({ snackOpen: true, snackMSg: "Please enter the specific size." });
      }
     else  if (values.editSpaceType === "Unit" && this.state.editisSpecifiedSizes !== "editSpecific" && this.state.editisSpecifiedSizes !== "editPredefined" && values.editSqFtFrom ==="" && values.editSqFtTo ==="" && cc === true) {
        this.setState({ snackOpen: true, snackMSg: "Please enter desired Sq.Ft range." });
      }
      else if (values.editSpaceType === "Unit" && this.state.editisSpecifiedSizes !== "editSpecific" && this.state.editisSpecifiedSizes !== "editPredefined" && parseFloat(values.editSqFtFrom) > parseFloat(values.editSqFtTo) && cc === true) {
        this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter Sq.Ft range." });
      }
      else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtTo === "" && values.editSqFtFrom === "" && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter desired m² range" });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtTo == "0" && values.editSqFtFrom == "0" && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft value." });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtFrom == ""  && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'From' Sq.Ft value." });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtTo == ""  && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft value." });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtTo == "0" && values.editSqFtFrom == "0" && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² value." });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtFrom === ""  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'From' m² value." });

        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && values.editSqFtTo === ""  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² value." });

        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && parseFloat(values.editSqFtFrom) < 0   && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter valid 'From' m² value." });
        }
        else if (this.state.editisSpecifiedSizes === "editSquareFootage" && values.editSpaceType === "Unit" && parseFloat(values.editSqFtFrom) > parseFloat(values.editSqFtTo)  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter m² range." });
        }
        else if ( values.editSpaceType === "Parking"   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && values.editparkingmaxlength ==='' && values.editparkingminlength ==='' && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter desired m² range" });
        }
        else if ( values.editSpaceType === "Parking"   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && values.editparkingmaxlength ===''  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² range" });
        }
        else if ( values.editSpaceType === "Parking"   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && values.editparkingminlength ===''  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'From' m² range" });
        }
        else if ( values.editSpaceType === "Parking"   && values.editparkingmaxlength ==='' && values.editparkingminlength ===''  && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter desired Sq.Ft range." });
        }
        else if ( values.editSpaceType === "Parking"   && values.editparkingminlength ===''   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'From' Sq.Ft range." });
        }
        else if ( values.editSpaceType === "Parking"   && values.editparkingmaxlength ===''   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft range." });
        }
        else if ( values.editSpaceType === "Parking"    && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && values.editparkingmaxlength =='0' && values.editparkingminlength =='0'  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² range" });
        }
        // else if ( values.editSpaceType === "Parking"   && values.editparkingmaxlength < 0 && values.editparkingminlength < 0  && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
        //   this.setState({ snackOpen: true, snackMSg: "Please enter Valid Sq.Ft range." });
        // }
        else if ( values.editSpaceType === "Parking"   && values.editparkingmaxlength =='0' && values.editparkingminlength =='0'  && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft range." });
        }
        else if ( values.editSpaceType === "Parking"    && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && parseFloat(values.editparkingminlength) < 0 && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "Please enter valid 'From' m² range" });
        }
        else if ( values.editSpaceType === "Parking"    && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && parseFloat(values.editparkingminlength) > parseFloat(values.editparkingmaxlength)  && cc === false) {
          this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter m² range." });
        }
        else if ( values.editSpaceType === "Parking"   && parseFloat(values.editparkingminlength) < 0  && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg:  "Please enter valid 'From' Sq.Ft range." });
        }
        else if ( values.editSpaceType === "Parking"   && parseFloat(values.editparkingminlength) > parseFloat(values.editparkingmaxlength)   && this.state.editParkingisSpecifiedSizes === "editParkingSpecific" && cc === true) {
          this.setState({ snackOpen: true, snackMSg:  "'From' value cannot be greater than or equal to 'To' value! Please re-enter Sq.Ft range." });
        }
      else {
        // console.log(values);
        //let editad_SizeType = this.state.ad_Size;
        //let editunitSize = values.sizes;
        let editWidth = '';
        let editLength = '';
        let editHeight = '';
        let editIsSpecifiedSizes = '';
        let editCar = '';
        let editRV = '';
        let editOtherVehicle = '';
        let editWidthPlusMinus = '';
        let editLengthPlusMinus = '';
        let editHeightPlusMinus = '';
        let editSqFtFrom = '';
        let editSqFtTo = '';
        let editClimateControlled = '';
        let editHumidityControlled = '';
        let editOutdoorAccess = '';
        let editAlarm = '';
        let editPower = '';
        let editDriveUp = '';
        let editElevator = '';
        let editCovered = '';
        let editDoorType = '';
        let editLengthWidthMatch = '';
        let editextractMatchchanges = '';
        let editwidthLengthchanges = '';
        let editparkingminlength = '';
        let editparkingmaxlength = '';
        let editMeasurement = '';

        if (values.editcountryCode === "US" || values.editcountryCode === "CA" || CONFIG.RegionId === 3 || values.editcountryCode === "AU" || values.editcountryCode === "NZ") {

          if (this.state.editSpaceChange === 'Unit') {
            editCar = -1;
            editRV = -1;
            editOtherVehicle = -1;
            editClimateControlled = values.editClimateControlled ? values.editClimateControlled : this.state.unitDetail.climateControlled;
            editHumidityControlled = values.editHumidityControlled ? values.editHumidityControlled : this.state.unitDetail.humidityControlled;
            editOutdoorAccess = values.editOutdoorAccess ? values.editOutdoorAccess : this.state.unitDetail.outdoorAccess;
            editAlarm = values.editAlarm ? values.editAlarm : this.state.unitDetail.alarm;
            editPower = values.editPower ? values.editPower : this.state.unitDetail.power;
            editDriveUp = values.editDriveUp ? values.editDriveUp : this.state.unitDetail.driveUp;
            editElevator = values.editElevator ? values.editElevator : this.state.unitDetail.elevator;
            editCovered = -1;
            editDoorType = values.editDoorType ? values.editDoorType : this.state.unitDetail.doorType;
            editparkingminlength = -1;
            editparkingmaxlength = -1;
           // editMeasurement = "Square Feet"
            editMeasurement = values.editcountryCode === "AU"  || values.editcountryCode === "NZ" ? "Meters" : "Square Feet";
            if (this.state.editisSpecifiedSizes === "editPredefined") {
              let editsizes = values.editsizes ? values.editsizes : this.state.unitDetail.width + 'x' + this.state.unitDetail.length;
              //  console.log(editsizes)
              const reSize = editsizes.split('x');
              editWidth = reSize[0];
              editLength = reSize[1];
              editHeight = -1;
              editWidthPlusMinus = -1;
              editLengthPlusMinus = -1;
              editHeightPlusMinus = -1;
              editSqFtFrom = 0;
              editSqFtTo = 0;
              editIsSpecifiedSizes = 0;
              editLengthWidthMatch = 0;
              editextractMatchchanges = this.state.editextacttMatches === 1 ? 1 : 0;
              editwidthLengthchanges = this.state.editwlMatchPredefined === 1 ? 1 : 0;
              // console.log(editwidthLengthchanges)
            }
            else if (this.state.editisSpecifiedSizes === "editSpecific") {

              editWidth = values.editWidth ? values.editWidth : -1;
              editLength = values.editLength ? values.editLength : -1;
              editHeight = values.editHeight ? values.editHeight : -1;
              editWidthPlusMinus = values.editWidthPlusMinus ? values.editWidthPlusMinus : 0;
              editLengthPlusMinus = values.editLengthPlusMinus ? values.editLengthPlusMinus : 0;
              editHeightPlusMinus = values.editHeightPlusMinus ? values.editHeightPlusMinus : 0;
              editSqFtFrom = 0;
              editSqFtTo = 0;
              editIsSpecifiedSizes = 1;
              editLengthWidthMatch = this.state.editlengthWidthMatches ? this.state.editlengthWidthMatches : 0;
              editextractMatchchanges = 0;
              editwidthLengthchanges = 0;
            }
            else {
              editWidth = '';
              editLength = '';
              editHeight = -1;
              editWidthPlusMinus = -1;
              editLengthPlusMinus = -1;
              editHeightPlusMinus = -1;
              editSqFtFrom = values.editSqFtFrom ? values.editSqFtFrom : 0;
              editSqFtTo = values.editSqFtTo ? values.editSqFtTo : 0;
              editIsSpecifiedSizes = 0;
              editLengthWidthMatch = 0;
              editextractMatchchanges = 0;
              editwidthLengthchanges = 0;
            }

          }
          else {

            editWidth = '';
            editLength = '';
            editHeight = -1;
            editWidthPlusMinus = -1;
            editLengthPlusMinus = -1;
            editHeightPlusMinus = -1;
            editSqFtFrom = 0;
            editSqFtTo = 0;
            editClimateControlled = -1;
            editHumidityControlled = -1;
            editOutdoorAccess = -1;
            editAlarm = -1;
            editDriveUp = -1;
            editDoorType = -1;
            editPower = -1;
            editElevator = -1;
            editLengthWidthMatch = 0;
            editextractMatchchanges = 0;
            editwidthLengthchanges = 0;
           // editMeasurement = "Square Feet"
            editMeasurement = values.editcountryCode === "AU" || values.editcountryCode === "NZ" ? "Meters" : "Square Feet";

            if (this.state.editParkingisSpecifiedSizes === "editParkingPredefined") {

              editparkingminlength = -1;
              editparkingmaxlength = -1;
              editIsSpecifiedSizes = 0;
              editCovered = values.editCovered ? parseInt(values.editCovered) : -1;
              editCar = values.sizes === "1" ? 1 : -1;
              editRV = values.sizes === "2" ? 1 : -1;
              editOtherVehicle = values.sizes === "3" ? 1 : -1;
              editparkingminlength = values.editparkingminlength ? values.editparkingminlength : '-1';
              editparkingmaxlength = values.editparkingmaxlength ? values.editparkingmaxlength : '-1';
            }
            else {

              editIsSpecifiedSizes = 1;
              editCar = -1;
              editRV = -1;
              editOtherVehicle = -1;
              editparkingminlength = values.editparkingminlength ? values.editparkingminlength : '-1';
              editparkingmaxlength = values.editparkingmaxlength ? values.editparkingmaxlength : '-1';
              editCovered = values.editCovered ? parseInt(values.editCovered) : -1;

            }
          }
        }
        else {
          if (this.state.editSpaceChange === 'Unit') {
            editHeight = -1;
            editWidthPlusMinus = -1;
            editLengthPlusMinus = -1;
            editHeightPlusMinus = -1;
            editLengthWidthMatch = 0;
            editwidthLengthchanges = 0;
            editparkingminlength = '-1';
            editparkingmaxlength = '-1';
            editCovered = -1;
            editPower = parseInt(values.editPower) != -1 ? parseInt(values.editPower) : -1;
            editElevator = parseInt(values.editElevator) != -1 ? parseInt(values.editElevator) : -1;
            editClimateControlled = -1;
            editHumidityControlled = -1;
            editOutdoorAccess = -1;
            editAlarm = -1;
            editDriveUp = -1;
            editDoorType = -1;
            //editMeasurement = "Square Meter";
            editMeasurement = CONFIG.RegionId === 3 ? "Square Feet" : "Square Meter";
            editCar = -1;
            editRV = -1;
            editOtherVehicle = -1;
            if (this.state.editisSpecifiedSizes === "editPredefined") {
              let editsizes = values.editsizes ? values.editsizes : this.state.unitDetail.width + 'x' + this.state.unitDetail.length;
              const reSizes = editsizes.split('x');
              editWidth = reSizes[0];
              editLength = reSizes[1];
              editextractMatchchanges = this.state.editextacttMatches;
              editIsSpecifiedSizes = 0;
              editSqFtFrom = 0;
              editSqFtTo = 0;

            }
            else {

              editWidth = '';
              editLength = '';
              editextractMatchchanges = 0;
              editSqFtFrom = values.editSqFtFrom ? values.editSqFtFrom : 0;
              editSqFtTo = values.editSqFtTo ? values.editSqFtTo : 0;
              editIsSpecifiedSizes = 0;
            }
          }
          else {
            editWidth = '';
            editLength = '';
            editextractMatchchanges = 0;
            editHeight = -1;
            editWidthPlusMinus = -1;
            editLengthPlusMinus = -1;
            editHeightPlusMinus = -1;
            editLengthWidthMatch = 0;
            editwidthLengthchanges = 0;
            editCovered = values.editCovered ? parseInt(values.editCovered) : -1;
            editClimateControlled = -1;
            editHumidityControlled = -1;
            editOutdoorAccess = -1;
            editAlarm = -1;
            editDriveUp = -1;
            editDoorType = -1;
            editPower = -1;
            editElevator = -1;
           // editMeasurement = "Square Meter";
            editMeasurement = CONFIG.RegionId === 3 ? "Square Feet" : "Square Meter";
            editSqFtFrom = 0;
            editSqFtTo = 0;
            if (this.state.editParkingisSpecifiedSizes === "editParkingPredefined") {

              editWidth = '';
              editLength = '';
              editIsSpecifiedSizes = 0;
              editCar = values.sizes === "1" ? 1 : -1;
              editRV = values.sizes === "2" ? 1 : -1;
              editOtherVehicle = values.sizes === "3" ? 1 : -1;
              editparkingminlength = values.editparkingminlength ? values.editparkingminlength : '-1';
              editparkingmaxlength = values.editparkingmaxlength ? values.editparkingmaxlength : '-1';
            }
            else {
              editWidth = '';
              editLength = '';
              editparkingminlength = values.editparkingminlength ? values.editparkingminlength : '-1';
              editparkingmaxlength = values.editparkingmaxlength ? values.editparkingmaxlength : '-1';
              editIsSpecifiedSizes = 1;
              editCar = -1;
              editRV = -1;
              editOtherVehicle = -1;
            }
          }
        }
        const data1 = {
          "searchID": this.state.editSearchId,
          "Name": values.editName ? values.editName : this.state.unitDetail.name,
          "SpaceType": this.state.editSpaceChange,
          "Width": editWidth ? editWidth : '',
          "Length": editLength ? editLength : '',
          "Height": editHeight,
          "WidthPlusMinus": editWidthPlusMinus,
          "LengthPlusMinus": editLengthPlusMinus,
          "HeightPlusMinus": editHeightPlusMinus,
          "Alarm": editAlarm != -1 ? editAlarm : -1,
          "Power": parseInt(editPower) != -1 ? editPower : -1,
          "Covered": editCovered,
          "DriveUp": editDriveUp,
          "Elevator": parseInt(editElevator) != -1 ? editElevator : -1,
          "Car": parseInt(editCar),
          "RV": parseInt(editRV),
          "OtherVehicle": parseInt(editOtherVehicle),
          "DoorType": parseInt(editDoorType),
          "ExactMatch": parseInt(editextractMatchchanges),
          "WLMatchPredefined": parseInt(editwidthLengthchanges),
          "WLMatchSpecific": parseInt(editLengthWidthMatch),
          "SqFtFrom": editSqFtFrom,
          "sqFTTo": editSqFtTo,
          "IsSpecifiedSizes": parseInt(editIsSpecifiedSizes),
          "ClimateControlled": editClimateControlled,
          "HumidityControlled": editHumidityControlled,
          "OutdoorAccess": editOutdoorAccess,
          "parkingminlength": editparkingminlength,
          "parkingmaxlength": editparkingmaxlength,
          "measurement": editMeasurement,
          "RegionId": CONFIG.RegionId
        }



        let token = localStorage.getItem('accessKey');
        if (token) {
          axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.post(CONFIG.API_URL + 'market/updateunit/', data1)
          .then(res => {

            this.setState(state => ({ modal: false }));
            var isElement = document.getElementById('r2');
            if (isElement == null) {
              document.getElementById('r1').click();
            }
            else {
              document.getElementById('r1').click();
              document.getElementById('r2').click();
            }
            this.props.parentMethod();
            this.setState({ isLoading: false });
            this.setState({ snackOpen: true, snackMSg: "Unit type updated successfully" });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            if (err.response.data.StatusCode === 400) {
              this.setState({ snackOpen: true, snackMSg: "Unit Type Name already exists" });
            }
            else if (err.res.status === 401) {
              localStorage.removeItem('accessKey');
              const { from } = this.props.location.state || { from: { pathname: "/" } };
              this.props.history.push(from);
            }
            else {

              this.setState({ snackOpen: true, snackMSg: err.res.data });
            }
          });
      }

    }
  }

  addMarketSubmit(event, errors, values) {

    if (errors.length > 0) {

      console.log(errors);
    }
    else {
     
      var cc = false;
      if(values.countryCode === "US" || values.countryCode === "CA" || CONFIG.RegionId === 3){
        cc = true;
      }
      let ad_SizeTypec = this.state.ad_Size;
      if (cc === true && ( ad_SizeTypec === 'Specific' && values.Width === '' && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter specific size." });
      }
     else if (cc === false && ( ad_SizeTypec === 'Specific' && values.Width === '' && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter specific size." });
      }
      else if ( cc === false  && (ad_SizeTypec === 'Measurement' && !values.SqFtFrom && !values.SqFtTo && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter desired m² range." });
      }
      else if (cc === false && (ad_SizeTypec === 'Measurement' && !values.SqFtFrom && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'From' m² value." });
      }
       else if (cc === false && (ad_SizeTypec === 'Measurement' && !values.SqFtTo && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² value." });
      }
      else if (cc === false && ( ad_SizeTypec === 'Measurement' && values.SqFtFrom === '0'  && values.SqFtTo === '0' && values.SpaceType === 'Unit' )) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² value." });
      }
      else if (cc === false && (ad_SizeTypec === 'Measurement' && parseFloat(values.SqFtFrom) < 0 && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter valid 'From' m² value." });
      }
      else if (cc === false && (ad_SizeTypec === 'Measurement' && parseFloat(values.SqFtFrom) > parseFloat(values.SqFtTo) && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "From' value cannot be greater than or equal to 'To' value! Please re-enter m² range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Measurement' && values.SqFtFrom === ''  && values.SqFtTo === '' && values.SpaceType === 'Unit' )) {
        this.setState({ snackOpen: true, snackMSg: "Please enter desired Sq.Ft range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Measurement' && values.SqFtFrom === ''   && values.SpaceType === 'Unit' )) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'From' Sq.Ft value." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Measurement' && values.SqFtTo === ''   && values.SpaceType === 'Unit' )) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft value." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Measurement' && values.SqFtFrom === '0'  && values.SqFtTo === '0' && values.SpaceType === 'Unit' )) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft value." });
      }
      else if (cc === true && (ad_SizeTypec === 'Measurement' && parseFloat(values.SqFtFrom) < 0 && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter valid 'From' Sq.Ft value." });
      }
      else if (cc === true && (ad_SizeTypec === 'Measurement' && parseFloat(values.SqFtFrom) > parseFloat(values.SqFtTo) && values.SpaceType === 'Unit')) {
        this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter Sq.Ft range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Measurement' && values.parkingminlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Sq. Ft value must be greater than Zero or Enter a valid value in +/- besides it." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Specific' && values.parkingminlength === ''  && values.parkingmaxlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter desired Sq.Ft range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Specific' && values.parkingminlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'From' Sq.Ft range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Specific' &&  values.parkingmaxlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft range." });
      }
      else if (cc === false && ( ad_SizeTypec === 'Specific' && values.parkingminlength === ''  && values.parkingmaxlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter desired m² range." });
      } 
      else if (cc === false && ( ad_SizeTypec === 'Specific' && values.parkingminlength === ''   && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'From' m² range."  });
      } 
      else if (cc === false && ( ad_SizeTypec === 'Specific'   && values.parkingmaxlength === '' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² range."  });
      } 
      else if (cc === true && ( ad_SizeTypec === 'Specific' && values.parkingminlength === '0' && values.parkingmaxlength === '0'  && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' Sq.Ft range." });
      }
      else if (cc === false && ( ad_SizeTypec === 'Specific' && values.parkingminlength === '0' && values.parkingmaxlength === '0' && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter 'To' m² range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Specific' && parseFloat(values.parkingminlength) < 0   && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg:"Please enter valid 'From' Sq.Ft range." });
      }
      else if (cc === true && ( ad_SizeTypec === 'Specific' && parseFloat(values.parkingminlength) > parseFloat(values.parkingmaxlength)   && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter Sq.Ft range." });
      }
      else if (cc === false && ( ad_SizeTypec === 'Specific' && parseFloat(values.parkingminlength) < 0 && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "Please enter valid 'From' m² range." });
      }
      else if (cc === false && ( ad_SizeTypec === 'Specific' && parseFloat(values.parkingminlength) > parseFloat(values.parkingmaxlength)  && values.SpaceType === 'Parking')) {
        this.setState({ snackOpen: true, snackMSg: "'From' value cannot be greater than or equal to 'To' value! Please re-enter m² range." });
      }
    
      else {
        let nameExit = values.Name;
        let Sid = values.StoreId;
        localStorage.setItem('StId', values.StoreId);
        let token = localStorage.getItem('accessKey');
        if (token) {
          axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'market/unitnamevalidation/' + nameExit + '/' + Sid)
          .then(res => {
            if (res.data.message === "Success") {

              this.setState({ snackOpen: true, snackMSg: "Unit Type Name already exists" });
            }
            else {


              let spaceState = values.SpaceType;
              //let Name =values.Name
              let ad_SizeType = this.state.ad_Size;
              let unitSize = values.sizes;
              let Width = '';
              let Length = '';
              let Height = '';
              let IsSpecifiedSizes = '';
              let Car = '';
              let RV = '';
              let OtherVehicle = '';
              let WidthPlusMinus = '';
              let LengthPlusMinus = '';
              let HeightPlusMinus = '';
              let SqFtFrom = '';
              let SqFtTo = '';
              let ClimateControlled = '';
              let HumidityControlled = '';
              let OutdoorAccess = '';
              let Alarm = '';
              let Power = '';
              let DriveUp = '';
              let Elevator = '';
              let Covered = '';
              let DoorType = '';
              let LengthWidthMatch = '';
              let extractMatchchanges = '';
              let widthLengthchanges = '';
              let parkingminlength = '';
              let parkingmaxlength = '';
              let Measurement = '';
              if (values.countryCode === 'US' || values.countryCode === 'CA' || CONFIG.RegionId === 3 || values.countryCode === 'AU' || values.countryCode === 'NZ') {
                if (spaceState === 'Unit') {
                  Car = -1;
                  RV = -1;
                  OtherVehicle = -1;
                  ClimateControlled = values.ClimateControlled ? parseInt(values.ClimateControlled) : -1;
                  HumidityControlled = values.HumidityControlled ? parseInt(values.HumidityControlled) : -1;
                  OutdoorAccess = values.OutdoorAccess ? parseInt(values.OutdoorAccess) : -1;
                  Alarm = values.Alarm ? parseInt(values.Alarm) : -1;
                  Power = values.Power ? parseInt(values.Power) : -1;
                  DriveUp = values.DriveUp ? parseInt(values.DriveUp) : -1;
                  Elevator = values.Elevator ? parseInt(values.Elevator) : -1;
                  Covered = -1;
                  DoorType = values.DoorType ? parseInt(values.DoorType) : -1;
                  parkingminlength = -1;
                  parkingmaxlength = -1;
                 // Measurement = "Square Feet"
                  Measurement = values.countryCode === "AU" || values.countryCode === "NZ" ? "Meters" : "Square Feet";
                  if (ad_SizeType === 'predefined') {
                    const reSize = unitSize.split('x');
                    Width = reSize[0] ? reSize[0] : -1;
                    Length = reSize[1] ? reSize[1] : -1;
                    Height = -1;
                    WidthPlusMinus = -1;
                    LengthPlusMinus = -1;
                    HeightPlusMinus = -1;
                    SqFtFrom = 0;
                    SqFtTo = 0;
                    IsSpecifiedSizes = 0;
                    LengthWidthMatch = 0;
                    extractMatchchanges = this.state.extractMatchchange;
                    widthLengthchanges = this.state.widthLengthchange;

                  }
                  else if (ad_SizeType === 'Specific') {
                    Width = values.Width ? values.Width : -1;
                    Length = values.Length ? values.Length : -1;
                    Height = values.Height ? values.Height : -1;
                    WidthPlusMinus = values.WidthPlusMinus ? values.WidthPlusMinus : 0;
                    LengthPlusMinus = values.LengthPlusMinus ? values.LengthPlusMinus : 0;
                    HeightPlusMinus = values.HeightPlusMinus ? values.HeightPlusMinus : 0;
                    SqFtFrom = 0;
                    SqFtTo = 0;
                    IsSpecifiedSizes = 1;
                    LengthWidthMatch = this.state.lengthWidthMatches ? this.state.lengthWidthMatches : 0;
                    extractMatchchanges = 0;
                    widthLengthchanges = 0;
                  }
                  else {
                    Width = '';
                    Length = ''
                    Height = -1;
                    WidthPlusMinus = -1;
                    LengthPlusMinus = -1;
                    HeightPlusMinus = -1;
                    SqFtFrom = values.SqFtFrom ? values.SqFtFrom : 0;
                    SqFtTo = values.SqFtTo ? values.SqFtTo : 0;
                    IsSpecifiedSizes = 0;
                    LengthWidthMatch = 0;
                    extractMatchchanges = 0;
                    widthLengthchanges = 0;
                  }

                }
                else {
                  Width = '';
                  Length = '';
                  Height = -1;
                  WidthPlusMinus = -1;
                  LengthPlusMinus = -1;
                  HeightPlusMinus = -1;
                  SqFtFrom = 0;
                  SqFtTo = 0;
                  ClimateControlled = -1;
                  HumidityControlled = -1;
                  OutdoorAccess = -1;
                  Alarm = -1;
                  DriveUp = -1;
                  DoorType = -1;
                  Power = -1;
                  Elevator = -1;
                  LengthWidthMatch = 0;
                  extractMatchchanges = 0;
                  widthLengthchanges = 0;
                 // Measurement = "Square Feet"
                  Measurement = values.countryCode === "AU" || values.countryCode === "NZ" ? "Meters" : "Square Feet";

                  if (ad_SizeType === 'predefined') {

                    parkingminlength = -1;
                    parkingmaxlength = -1;
                    IsSpecifiedSizes = 0;
                    Covered = values.Covered ? parseInt(values.Covered) : -1;
                    Car = this.state.Car === 1 ? this.state.Car : -1;
                    RV = this.state.RV === 1 ? this.state.RV : -1;
                    OtherVehicle = this.state.Large ? this.state.Large : -1;
                    parkingminlength = values.parkingminlength ? values.parkingminlength : '-1';
                    parkingmaxlength = values.parkingmaxlength ? values.parkingmaxlength : '-1';
                  }
                  else {

                    IsSpecifiedSizes = 1;
                    Car = 0;
                    RV = 0;
                    OtherVehicle = 0;
                    parkingminlength = values.parkingminlength ? values.parkingminlength : '-1';
                    parkingmaxlength = values.parkingmaxlength ? values.parkingmaxlength : '-1';
                    Covered = values.Covered ? parseInt(values.Covered) : -1;

                  }
                }
              }
              else {

                if (spaceState === 'Unit') {
                  Height = -1;
                  WidthPlusMinus = -1;
                  LengthPlusMinus = -1;
                  HeightPlusMinus = -1;
                  LengthWidthMatch = 0;
                  widthLengthchanges = 0;
                  parkingminlength = '-1';
                  parkingmaxlength = '-1';
                  Covered = -1;
                  Power = values.Power ? parseInt(values.Power) : -1;
                  Elevator = values.Elevator ? parseInt(values.Elevator) : -1;
                  ClimateControlled = -1;
                  HumidityControlled = -1;
                  OutdoorAccess = -1;
                  Alarm = -1;
                  DriveUp = -1;
                  DoorType = -1;
                 // Measurement = "Square Meter";
                  Measurement = CONFIG.RegionId === 3 ? "Square Feet" : "Square Meter";
                  Car = -1;
                  RV = -1;
                  OtherVehicle = -1;
                  if (ad_SizeType === 'predefined') {
                    const reSizes = unitSize.split('x');
                    Width = reSizes[0];
                    Length = reSizes[1];
                    extractMatchchanges = this.state.extractMatchchange;
                    IsSpecifiedSizes = 0;
                    SqFtFrom = '';
                    SqFtTo = '';

                  }
                  else {

                    Width = '';
                    Length = '';
                    extractMatchchanges = 0;
                    SqFtFrom = values.SqFtFrom ? values.SqFtFrom : '';
                    SqFtTo = values.SqFtTo ? values.SqFtTo : '';
                    IsSpecifiedSizes = 0;
                  }
                }
                else {
                  Width = '';
                  Length = '';
                  extractMatchchanges = 0;
                  Height = -1;
                  WidthPlusMinus = -1;
                  LengthPlusMinus = -1;
                  HeightPlusMinus = -1;
                  LengthWidthMatch = 0;
                  widthLengthchanges = 0;
                  Covered = values.Covered ? parseInt(values.Covered) : -1;
                  ClimateControlled = -1;
                  HumidityControlled = -1;
                  OutdoorAccess = -1;
                  Alarm = -1;
                  DriveUp = -1;
                  DoorType = -1;
                  Power = -1;
                  Elevator = -1;
                  // Measurement = "Square Meter";
                  Measurement = CONFIG.RegionId === 3 ? "Square Feet" : "Square Meter";
                  SqFtFrom = '';
                  SqFtTo = '';
                  if (ad_SizeType === 'predefined') {

                    Width = '';
                    Length = '';
                    IsSpecifiedSizes = 0;
                    Car = this.state.Car === 1 ? this.state.Car : -1;
                    RV = this.state.RV === 1 ? this.state.RV : -1;
                    OtherVehicle = this.state.Large ? this.state.Large : -1;
                    parkingminlength = values.parkingminlength ? values.parkingminlength : '-1';
                    parkingmaxlength = values.parkingmaxlength ? values.parkingmaxlength : '-1';
                  }
                  else {
                    Width = '';
                    Length = '';
                    parkingminlength = values.parkingminlength ? values.parkingminlength : '-1';
                    parkingmaxlength = values.parkingmaxlength ? values.parkingmaxlength : '-1';
                    IsSpecifiedSizes = 1;
                    Car = -1;
                    RV = -1;
                    OtherVehicle = -1;
                  }
                }
              }
              const data = {
                "StoreId": parseInt(values.StoreId),
                "Name": values.Name,
                "SpaceType": spaceState,
                "Width": Width ? Width : 0,
                "Length": Length ? Length : 0,
                "Height": Height,
                "WidthPlusMinus": WidthPlusMinus,
                "LengthPlusMinus": LengthPlusMinus,
                "HeightPlusMinus": HeightPlusMinus,
                "ClimateControlled": parseInt(ClimateControlled),
                "HumidityControlled": parseInt(HumidityControlled),
                "OutdoorAccess": parseInt(OutdoorAccess),
                "Alarm": parseInt(Alarm),
                "Power": parseInt(Power),
                "DriveUp": parseInt(DriveUp),
                "Elevator": parseInt(Elevator),
                "Covered": parseInt(Covered),
                "Car": Car,
                "RV": RV,
                "OtherVehicle": OtherVehicle,
                "DoorType": parseInt(DoorType),
                "SqFtFrom": SqFtFrom ? SqFtFrom : 0,
                "SqFtTo": SqFtTo ? SqFtTo : 0,
                "IsSpecifiedSizes": IsSpecifiedSizes,
                "parkingminlength": parkingminlength,
                "parkingmaxlength": parkingmaxlength,
                "ExactMatch": extractMatchchanges,
                "WLMatchPredefined": widthLengthchanges,
                "WLMatchSpecific": LengthWidthMatch,
                "Measurement": Measurement,
                "SearchStatus": 1,
                "RegionId": CONFIG.RegionId
              }

              axios.post(CONFIG.API_URL + 'market/addunit/', data)
                .then(res => {


                  this.setState(state => ({ addmodal: !state.addmodal }));
                  var isElement = document.getElementById('r2');
                  if (isElement == null) {
                    document.getElementById('r1').click();
                  }
                  else {
                    document.getElementById('r1').click();
                    document.getElementById('r2').click();
                  }
                  this.props.parentMethod();
                  this.setState({ isLoading: false });
                  this.setState({ snackOpen: true, snackMSg: "Unit type added successfully" });
                  // console.log(res);
                })
                .catch((err) => {

                  this.setState({ isLoading: false });
                  if (err.res.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                  }
                  else {
                    this.setState({ snackOpen: true, snackMSg: err.res.data });
                  }
                });
            }
          })
          .catch((err) => {

            if (err.res.status === 401) {
              localStorage.removeItem('accessKey');
              const { from } = this.props.location.state || { from: { pathname: "/" } };
              this.props.history.push(from);
            }
            else {
              this.setState({ snackOpen: true, snackMSg: err.res.data });
            }
          });
      }
    }
    // this.setState({ errors, values });

  }

  deleteMarket(searchid) {
    this.state.deleteId = searchid;
    this.delConfirmToggle()
    // this.toggleYes()
  }
  delConfirmToggle() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
    }));
  }
  toggleYes() {
    let deleteId = this.state.deleteId;
    this.setState({ isLoading: true })
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'market/deleteunit/' + deleteId+ '/'+ CONFIG.RegionId)
      .then(res => {
        this.setState(state => ({ deleteModal: !state.deleteModal }));
        var isElement = document.getElementById('r2');
        if (isElement == null) {
          document.getElementById('r1').click();
        }
        else {
          document.getElementById('r1').click();
          document.getElementById('r2').click();
        }
        this.props.parentMethod();

        this.setState({ isLoading: false });
        this.setState({ snackOpen: true, snackMSg: "Unit Type Deleted Successfully" });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.res.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else {
          this.setState({ snackOpen: true, snackMSg: err.res.data });
        }
      });
  }
  editLengthWidthMatchchange = (event) => {

    let editlengthWidthMatches = this.state.editlengthWidthMatches;
    if (event.target.checked) {
      editlengthWidthMatches = 1
    }
    else {
      editlengthWidthMatches = 0
    }
    //alert(editlengthWidthMatches);
    this.setState({
      editlengthWidthMatches
    })

  }
  editextacttMatches = (event) => {

    let editextacttMatches = this.state.editextacttMatches;
    if (event.target.checked) {
      editextacttMatches = 1
    }
    else {
      editextacttMatches = 0
    }

    this.setState({
      editextacttMatches
    })

  }
  editwlMatchPredefined = (event) => {

    let editwlMatchPredefined = this.state.editwlMatchPredefined;
    if (event.target.checked) {
      editwlMatchPredefined = 1
    }
    else {
      editwlMatchPredefined = 0
    }

    this.setState({
      editwlMatchPredefined
    })

  }
  toggleNo() {
    this.setState(state => ({ deleteModal: !state.deleteModal }));
  }
  editSpaceTypeChange = (event) => { 
    let editTypeChange = event.target.value;
    let editParkingisSpecifiedSizes = this.state.editParkingisSpecifiedSizes;
    let editisSpecifiedSizes = this.state.editisSpecifiedSizes;
    let editSpaceChange = '';
    if (editTypeChange === 'Unit') {
      editSpaceChange = "Unit";
      if (this.state.unitDetail.spaceType === 'Unit') {
        editisSpecifiedSizes = this.state.editisSpecifiedSizes;
      }
      else{
        editisSpecifiedSizes = "editPredefined"; 
      }
      
    } else {
      editSpaceChange = "Parking";
      if (this.state.unitDetail.spaceType === 'Unit') {
     editParkingisSpecifiedSizes = "editParkingPredefined";
      }else{
        editParkingisSpecifiedSizes = this.state.editParkingisSpecifiedSizes;
      }
    }
    this.setState({ editSpaceChange,editParkingisSpecifiedSizes,editisSpecifiedSizes });
  }
  spaceTypeChange = (event) => {
   
    //let spacetype = this.state.spacetype;
    this.state.spacetype = event.target.value;
    //let ParkingAdd;
    this.setState({ spacetype: this.state.spacetype });
    this.state.userStore = [{ unit: "-1", unitID: "-- --" }, { unit: "Car", unitID: "Car" },
    { unit: "RV", unitID: "RV" }, { unit: "Large", unitID: "Large" }]
    if (this.state.spacetype == 'Parking') {
      this.setState({ userStore: this.state.userStore });
      // ParkingAdd = true;
      this.state.ad_Size = 'predefined';

    }
    else {
      // ParkingAdd = false;
      this.state.ad_Size = 'predefined';

      this.setState({ userStore: this.state.userStoreList });
      //this.userStoreUnit();
    }
    // this.setState({    parkingAdd  })

  };

  sizeChange = (event) => {
  
    this.state.ad_Size = event.target.value;
  
     this.setState({ ad_Size: this.state.ad_Size});
    if (this.state.ad_Size === 'predefined') {
      this.state.ad_Size = 'predefined';
      //this.refs.firstName.value = '';
      let emptyWidth = '';
      
      this.setState({ emptyWidth: '',LengthWidthMatchchange:0 });

    }
    else if (this.state.ad_Size === 'Specific') {
      this.state.ad_Size = 'Specific';
     
     
        this.setState({
        extractMatchchange: 0,
        widthLengthchange: 0,emptyParking:"-1" 
      })
      //this.state.emptyParking = '--';
      // this.setState({emptyParking: this.state.emptyParking });

    }
    else {
      this.state.ad_Size = 'Measurement';
      this.setState({
        extractMatchchange: 0,
        widthLengthchange: 0,LengthWidthMatchchange:0
      })

    }

  };
  sizeEditChange = (event) => { 
    let until = this.state.unitDetail.width;
    until = '';
    // this.state.editisSpecifiedSizes = event.target.value;
    let editisSpecifiedSizes = event.target.value;
let editextacttMatches = this.state.editextacttMatches;
let editwlMatchPredefined = this.state.editwlMatchPredefined;
let editlengthWidthMatches = this.state.editlengthWidthMatches;
    if (editisSpecifiedSizes === "editSpecific") {

      editisSpecifiedSizes = "editSpecific"; editextacttMatches =0;editwlMatchPredefined = 0;
      editlengthWidthMatches = this.state.unitDetail.wlMatchSpecific === true ? 1 : 0;
    } else if (editisSpecifiedSizes === "editPredefined") {
      editisSpecifiedSizes = "editPredefined";editlengthWidthMatches=0;
       editextacttMatches = this.state.unitDetail.exactMatch === true ? 1 : 0;
       editwlMatchPredefined = this.state.unitDetail.wlMatchPredefined === true ? 1 : 0;
    } else {
      editisSpecifiedSizes = "editSquareFootage";
      editextacttMatches =0;editwlMatchPredefined = 0;editlengthWidthMatches=0;
    }
    this.setState({ editisSpecifiedSizes, until,editextacttMatches,editwlMatchPredefined,editlengthWidthMatches });
    //alert(this.state.unitDetail.isSpecifiedSizes);
  }
  sizeEditParkingChange = (event) => {
    let editParkingisSpecifiedSizess = event.target.value;
    let editParkingisSpecifiedSizes = '';
    if (editParkingisSpecifiedSizess === "editParkingPredefined") {
      editParkingisSpecifiedSizes = "editParkingPredefined";
    }
    else {
      editParkingisSpecifiedSizes = "editParkingSpecific";

    }
    this.setState({ editParkingisSpecifiedSizes });
  }
  LengthWidthMatchchange = (event) => {

    if (!event.target.checked) {
      this.setState({LengthWidthMatchchange:0

      })
    }
    else{
      this.setState({LengthWidthMatchchange:1})
    }
    

  }
  parkingChange = (event) => {
    let parkingchange = event.target.value;
    if (parkingchange === 'Car') {
      // this.state.parkingmaxlength = '18';
      //  this.state.parkingminlength = '0';
      this.state.Car = 1;
      this.state.RV = '';
      this.state.Large = '';
    }
    else if (parkingchange === 'RV') {
      //  this.state.parkingmaxlength = '1000';
      //  this.state.parkingminlength = '18';
      this.state.RV = 1;
      this.state.Large = '';
      this.state.Car = '';
    }
    else if (parkingchange === 'Large') {
      // this.state.parkingmaxlength = '1000';
      //  this.state.parkingminlength = '30';
      this.state.RV = '';
      this.state.Large = 1;
      this.state.Car = '';
    }
    else {
      this.state.parkingmaxlength = '';
      this.state.parkingminlength = '';
    }
    // this.setState({ parkingmaxlength: this.state.parkingmaxlength });
    //  this.setState({ parkingminlength: this.state.parkingminlength });

  }
  editParkingsChange = (event) => {
    let parkingChangeMax = event.target.value;
    let editCar = '';
    let editRV = '';
    let editLarge = '';
    if (parkingChangeMax === '1') {
      // this.state.editparkingmaxlength = '18';
      // this.state.editparkingminlength = '0';
      editCar = 1;

    }
    else if (parkingChangeMax === '2') {
      // this.state.editparkingmaxlength = '1000';
      // this.state.editparkingminlength = '18';
      editRV = 1;
    }
    else if (parkingChangeMax === '3') {
      //  this.state.editparkingmaxlength = '1000';
      //  this.state.editparkingminlength = '30';
      editLarge = 1;
    }
    else {
      // this.state.editparkingmaxlength = '';
      // this.state.editparkingminlength = '';
    }
    this.setState({ editCar, editRV, editLarge });
    // this.setState({ editparkingminlength: this.state.editparkingminlength });
  }
  resetAllValues() {

    // this.setState({ checkItOut: false });
    //  ReactDOM.findDOMNode(this.myFormRef.form).value = "";
    this.myFormRef && this.myFormRef.reset();
  }
  closeAddUnit() {
    this.setState(state => ({ addmodal: !state.addmodal }))

  }
  closeEditUnit() {
    this.setState(state => ({ modal: false }))

  }

  resetEditUnits(id) {

    this.toggleModal(id);
    //     const editParam = id.split("/");
    //     let Sid = editParam[0];
    //     let countryCode = editParam[1];
    //     let token = localStorage.getItem('accessKey');
    //     if (token) {
    //       axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    //     }

    //     this.state.countryCode = countryCode;
    //     this.setState({ countryCode: this.state.countryCode });
    //     this.userStoreUnit();
    //     axios.post(CONFIG.API_URL + 'market/unitdetail/' + Sid)
    //       .then(res => {
    //         let resData = res.data.competitor;
    //         let unitDetail = res.data.competitor;
    //       //  let unitDetail = this.state.unitDetail;
    //       let editSearchId = unitDetail.searchID;
    //         let editlengthWidthMatches = unitDetail.wlMatchSpecific === true ? 1 : 0;
    //         let editextacttMatches = unitDetail.exactMatch === true ? 1 : 0;
    //         let editwlMatchPredefined = unitDetail.wlMatchPredefined === true ? 1 : 0;
    //         let editName =  unitDetail.name ;
    //        let editisSpecifiedSizes = '';
    //        let editParkUnits = '';
    //        let editParkingisSpecifiedSizes = '';
    //        let editparkingminlength = '';
    //        let editparkingmaxlength = '';
    //         if (unitDetail.isSpecifiedSizes === true) {
    //            editisSpecifiedSizes= 'editSpecific' ;
    //         }
    //         else if (unitDetail.sqft > 0) {
    //           editisSpecifiedSizes= 'editSquareFootage' ;

    //         }
    //         else {
    //           editisSpecifiedSizes= 'editPredefined' ;

    //         }
    //         let editSpaceChange = '';
    //         if (unitDetail.spaceType === 'Unit') {
    //           editSpaceChange = "Unit";


    //         }
    //         else {
    //            editSpaceChange = "Parking";
    //            if (unitDetail.rv === 1) {
    //             editParkUnits = "2";

    //           } else if (unitDetail.car === 1) {
    //             editParkUnits = "1";

    //           } else if (unitDetail.otherVehicle === 1) {
    //              editParkUnits = "3";

    //           } else {
    //              editParkUnits = "-1";

    //           }
    //           if (unitDetail.car === 1 || unitDetail.otherVehicle === 1 || unitDetail.rv === 1) {
    //              editParkingisSpecifiedSizes= 'editParkingPredefined' ;
    //              editparkingminlength='' ;
    //              editparkingmaxlength= '' ;
    //           }
    //           else {
    //              editParkingisSpecifiedSizes= 'editParkingSpecific';
    //              editparkingminlength= unitDetail.parkingminlength ;
    //              editparkingmaxlength= unitDetail.parkingmaxlength ;
    //           }
    //         }

    //            this.setState({ unitDetail,resData,editSearchId ,editName,editlengthWidthMatches,editextacttMatches,editwlMatchPredefined,editisSpecifiedSizes,editSpaceChange,editParkUnits,editParkingisSpecifiedSizes,editparkingminlength,editparkingmaxlength })

    //       })
    //       .catch((err) => {
    //         // console.log(err)

    //         if (err.res.status === 401) {
    //           localStorage.removeItem('accessKey');
    //           const { from } = this.props.location.state || { from: { pathname: "/" } };
    //           this.props.history.push(from);
    //         }
    //         else {
    //           alert(err.res.data);
    //         }
    //       });

  }
  extractMatchChange(event) {
    
    //let extractMatchchange = event.target.value;
    if (!event.target.checked) {
      this.setState({ extractMatchchange: 0 });
    }
    else {
      this.setState({ extractMatchchange: 1 });
    }
  }
  widthLengthChange(event) {
    //let widthLengthchange = event.target.value;
    if (!event.target.checked) {
      this.setState({ widthLengthchange: 0 });
    }
    else {
      this.setState({ widthLengthchange: 1 });
    }


  }
  trackModal(storeId, searchId) {
    const data={
      "UserStoreId":storeId,
      "SearchId":searchId,
      "RegionId":CONFIG.RegionId
    }

    axios.post(CONFIG.API_URL + 'unit/competitorslist',data )
      .then(response => {

        // this.state.isLoading=true;
        const trackModalData = response.data.competitor.unitCompData;

        this.setState({ trackModalData, storeModal: true, })
      }).catch((err) => {
        // if (err.response.status === 401) {
        //     localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        // }
        // else {
        //     alert(err.response.data);
        // }
      });
  }
  trackModalClose() {
    this.setState({
      storeModal: false,
      pageCount: 0
    })
  }
  snackHandleclose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackOpen: false });
  };

  handleChangePageTrack = (event, pageCount) => {
    this.setState({ pageCount });
  };
  handleChangeRowsPerPageTrack = event => {
    this.setState({ pageCount: 0, 10: event.target.value });
  };
  handleChange(e) {

    let fields = this.state;
    let num = e.target.value.replace(/[^\w\s]/gi, "");
    fields[e.target.name] = num;
    this.setState({ fields });
  }
  handleChange1(e) {

    let unitDetail = this.state.unitDetail;
    let num = e.target.value.replace(/[^\w\s]/gi, "");

    if (e.target.name === "editWidthPlusMinus") {
      unitDetail.widthplusminus = num;
    }
    else if (e.target.name === "editLengthPlusMinus") {
      unitDetail.lengthplusminus = num;
    }
    else {
      unitDetail.heightplusminus = num;
    }

    this.setState({ unitDetail });
  }
  render() {

    const {
      name, index, avgPrice, priceTrend, myStorePrice, id, currency, storeid, StoreName, StoreAddress, StoreCity, StoreState, StoreZipcode,
      isDragging, countryCode, priceTrends1,
      connectDragSource, storeCount,
      connectDropTarget,
    } = this.props;
    const { spacetype, hideSpecifiedtext, ad_Size, userStore, unitDetail, editisSpecifiedSizes, trackModalData, pageCount, snackMSg, snackOpen } = this.state;

    // const opacity = isDragging ? 0 : 1;

    const partsOfStr = myStorePrice.split('-');
    let myStorePrice1 = partsOfStr[0];
    let myStorePrice2 = '';
    if (partsOfStr[1] === undefined) {
      myStorePrice2 = '';
    } else {
      myStorePrice2 = partsOfStr[1].trim();
    }
    var regionID= CONFIG.RegionId;
    //   const style = spacetype == 'Parking'?{display : 'none'} : {};

    //console.log(userStore);
    //console.log(unitDetail, 'unitDetail')
    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(

          <div className="column" >
            <div id="r2"></div>
             
            <div className="drag-view">
                <div className="market-FirstLine">
                  <h5><span id={'DisabledAutoHideExample-'+id}>{name.length>15?name.slice(0,15)+'..':name.slice(0,15)}</span>
                    {/* <Link to="#" onClick={this.toggleModal.bind(this, id + '/' + countryCode)} className="edit-icon"><img src={editicon} title="Edit Search" alt="" /> </Link> */}
                    <img src={editicon} className="edit-icon" alt="" />
                  </h5>
                  <Tooltip placement="top" className="dashboard-tooltip" isOpen={this.state.tooltipOpen} autohide={false} target={'DisabledAutoHideExample-'+id} toggle={this.toggle.bind(this)}>
                  {name}
                </Tooltip>
                  {/* <Link to="#" onClick={this.deleteMarket.bind(this, id)} className="Market-Close-Button"><FontAwesomeIcon icon={faTimes} title="Delete Search" /></Link> */}
                  <FontAwesomeIcon icon={faTimes} className="Market-Close-Button" />
                  <div className="clear"></div>
                </div>
                {storeCount === 'No' ? <p> Offered by N/A</p> :
                  // <p>Offered by <Link onClick={() => this.trackModal(storeid, id)}>{storeCount > 1 ? storeCount + ' stores' : storeCount + ' store'}</Link></p>
                  <p>Offered by {storeCount > 1 ? storeCount + ' stores' : storeCount + ' store'}</p>
                }

                <div className="avg-market">
                  <p className="margin-btm-0 avg-para">{CONFIG.RegionId === 3 ? "Avg. Weekly Market Price" : "Avg. Market Price" }</p>
                  <div className="avg-Price">
                    <span className={CONFIG.RegionId === 4 ?"price-show nz-price":"price-show aus-price"}>{avgPrice === "N/A" ? "N/A" : currency + avgPrice}</span><span className="na-count">{priceTrend === null ? '' : parseFloat(priceTrend) >= 0 ? <img src={Arrowup} alt="arrow-up" className="img-circle" /> : <img src={ArrowDown} alt="arrow-down" className="img-circle" />}{priceTrend === null ? <span className="red">N/A</span> : priceTrend >= 0 ? <span className="green">{priceTrend.toFixed(2) + "%"}</span> : <span className="red">{priceTrend.toFixed(2) + "%"}</span>}<span className="days-count">{priceTrends1 === "1" ? 'Last 5 days' : priceTrends1 === "2" ? 'Last 30 days' : priceTrends1 === "3" ? 'Last 90 days' : priceTrends1 === "4" ? 'Last 6 Months' : 'Last 12 Months'}</span></span>
                    <div className="clear"></div>
                  </div>
                </div>
                <div className="your-price">
                  <p className="avg-para">{CONFIG.RegionId === 3 ? "Your Weekly Price" : "Your Price" } </p>
                  <div className={CONFIG.RegionId === 2 && myStorePrice1 !== "0" && myStorePrice1 && myStorePrice2 ? "prices aus-price"  : CONFIG.RegionId === 2 && myStorePrice1 === "0" ? "prices" : "prices"}>{myStorePrice1 === "0" ? "N/A" : currency + myStorePrice1}{myStorePrice2 === "" ? "" : " - " + myStorePrice2}</div>
                </div>
                <div className="clear"></div>
                {/* </Link> 
                <Link to={{ pathname: `/market-dashboard/${this.props.StoreId}/view-details/${this.props.StoreSearchId}/${storeCount === 'No' ? 0 : storeCount}`` }}>
                */}
                <div className="view-Details">
                  <center>
                    {/* <Link to={{ pathname: `/market-dashboard/${this.props.StoreId}/view-details/${this.props.StoreSearchId}` }}>View Details <FontAwesomeIcon icon={faArrowRight} /></Link> */}
                    <span className="view-detail-pdf-link">View Details <FontAwesomeIcon icon={faArrowRight} /></span>
                  </center>
                </div>
                {/* <div className="view-Details"><center>View Details <FontAwesomeIcon icon={faArrowRight} /></center></div> */}
                <Modal isOpen={this.state.modal} toggle={this.toggleModal.bind(this)} className="edit-market-dashboard" size="lg">
                  <ModalHeader
                  // toggle={this.closeEditUnit.bind(this)} 
                  >
                    {unitDetail.name === undefined ?"Update " + '':  "Update " + unitDetail.name}
                    <div className="btm-address"><span>{StoreName}</span> | <span>{StoreAddress + ', ' + StoreCity }{StoreState ? ', ' + StoreState : ''} {StoreZipcode}</span></div>
                    {/* <div className="btm-address"><span>{"Coverage: "}{StoreRadius}</span> </div> */}
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className="closeModal"
                      onClick={this.closeEditUnit.bind(this)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ModalHeader>
                  <ModalBody className="overflow-scroll">

                    <AvForm onSubmit={this.editMarketSubmit.bind(this)} ref={c => (this.myFormupdate = c)} className="basic-details">
                      <h4>Basic Details</h4>
                      <Row>
                        <Col md={6}>
                          <AvField name="editName" value={unitDetail.name} label="Unit Type Name" maxLength={55} type="text" />
                          <AvField name="editcountryCode" value={countryCode} type="hidden" />


                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <AvField value={this.state.editSpaceChange} type="select" onChange={this.editSpaceTypeChange.bind(this)} name="editSpaceType" label="Storage Type" >
                            <option value="Unit">Self Storage</option>
                            <option value="Parking">Parking</option>
                          </AvField>
                        </Col>
                      </Row>
                      <h4 className="margin-top-5">Size</h4>

                      {this.state.editSpaceChange !== "Parking" ?
                        <div>
                          <Row className="margin-btm-15">
                            <Col md={3}>

                              <Radio className="radio-padding move-ch-top" value="editPredefined" checked={this.state.editisSpecifiedSizes === "editPredefined"} onChange={this.sizeEditChange.bind(this)} name="editSpecifies" /> <label className="label-field">Predefined</label>
                            </Col>
                            <Col md={3} className="padding-top-10">
                              <AvField type="select" value={this.state.editisSpecifiedSizes === 'editPredefined' ? unitDetail.width + 'x' + unitDetail.length : ''} /*onChange={this.parkingEditChange.bind(this)}*/ disabled={editisSpecifiedSizes === 'editSpecific' || editisSpecifiedSizes === 'editSquareFootage'} name="editsizes" >
                                {userStore ? userStore.map(n => {
                                  return (
                                    <option value={n.unit}>
                                      {ReactHtmlParser(n.unitID)}
                                    </option>
                                  );
                                }) : ''}
                              </AvField>
                            </Col>
                            <Col md={6}>
                              <Row className="size-top-5">
                                <AvCheckboxGroup inline name="locationQualities" className="full-width-display">
                                  <Row>
                                    <div className="col-md-5"><Checkbox value="1" checked={this.state.editextacttMatches === 1 ? true : false} onClick={this.editextacttMatches.bind(this)} disabled={editisSpecifiedSizes === 'editSpecific' || editisSpecifiedSizes === 'editSquareFootage'} className="move-ch-top track-check-gl" /><label className="">Exact Match</label></div>
                                    {(countryCode === 'US') || (countryCode === 'CA') ?
                                      <div className="col-md-5 pl-0"><Checkbox value="1" checked={this.state.editwlMatchPredefined === 1 ? true : false} onClick={this.editwlMatchPredefined.bind(this)} disabled={editisSpecifiedSizes === 'editSpecific' || editisSpecifiedSizes === 'editSquareFootage'} className="move-ch-top track-check-gl" /><label className="">Width=Length</label></div>
                                      : ''}
                                  </Row>
                                </AvCheckboxGroup>
                              </Row>
                            </Col>
                          </Row>

                          {(countryCode === 'US') || (countryCode === 'CA') || (countryCode === 'AU')  || (countryCode === 'NZ')?
                            <Row className="margin-btm-10">
                              <Col md={3}>
                                <Radio className="radio-padding pt-0 move-ch-top" value={"editSpecific"} checked={this.state.editisSpecifiedSizes === "editSpecific"} onChange={this.sizeEditChange.bind(this)} name="editSpecifies" /> <label className="label-field top-move">Specific</label>
                              </Col>

                              <Col md={5} className="selected-fields">
                                <Row className="row-selected">
                                  <Col md={12}>
                                    <label>W</label><div className="start-field"><AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.width > 0 && hideSpecifiedtext === 2) ? unitDetail.width : ''} name="editWidth" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} /></div><span>(+/-<AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.widthplusminus && unitDetail.widthplusminus > 0 && unitDetail.isSpecifiedSizes !=0) ? unitDetail.widthplusminus : ''} name="editWidthPlusMinus" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} onChange={this.handleChange1} />)</span>
                                    <div className="clear"></div>
                                  </Col>
                                  <Col md={12}>
                                    <label>L</label><div className="start-field"><AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.length > 0 && hideSpecifiedtext === 2) ? unitDetail.length : ''} name="editLength" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} /></div><span>(+/-<AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.lengthplusminus && unitDetail.lengthplusminus > 0 && unitDetail.isSpecifiedSizes !=0) ? unitDetail.lengthplusminus : ''} name="editLengthPlusMinus" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} onChange={this.handleChange1} />)</span>
                                    <div className="clear"></div>
                                  </Col>
                                  <Col md={12}>
                                    <label>H</label><div className="start-field"><AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.height > 0) ? unitDetail.height : ''} name="editHeight" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} /></div><span>(+/-<AvField value={(unitDetail.spaceType === 'Unit' && editisSpecifiedSizes === 'editSpecific' && unitDetail.heightplusminus > 0) ? unitDetail.heightplusminus : ''} name="editHeightPlusMinus" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} onChange={this.handleChange1} />)</span>
                                    <div className="clear"></div>
                                  </Col>
                                </Row>
                              </Col>
                              {countryCode === 'AU' || countryCode === 'NZ'  ?''   :
                              <Col md={4} className="mr-tp-15">
                                <Checkbox value="1" checked={this.state.editlengthWidthMatches === 1 ? true : false} onClick={this.editLengthWidthMatchchange.bind(this)} name="LengthWidthMatch" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSquareFootage'} className="move-ch-top track-check-gl" /><label className="new-label" >Width=Length Match <em>(eg: 5 x 10 = 10 x 5)</em></label>
                              </Col>
                             }

                            </Row>
                            : ''}

                      {countryCode === 'AU' || countryCode === 'NZ'   ?''   :  
                          <Row className="square-ft" >
                            {(countryCode === 'US') || (countryCode === 'CA')|| (regionID === 3) ?
                              <Col md={3}>
                                <Radio className="radio-padding move-ch-top" value={"editSquareFootage"} checked={this.state.editisSpecifiedSizes === "editSquareFootage"} onChange={this.sizeEditChange.bind(this)} name="editSpecifies" /> <label className="label-field">Square Footage</label>
                              </Col>
                              : <Col md={3}>
                                <Radio className="radio-padding pt-0 move-ch-top" value={"editSquareFootage"} checked={this.state.editisSpecifiedSizes === "editSquareFootage"} onChange={this.sizeEditChange.bind(this)} name="editSpecifies" /> <label className="label-field top-move">Specific</label>
                              </Col>}
                            <Col md={2} className="margin-top-7">
                              <AvField value={unitDetail.sqftTo > 0 ? unitDetail.sqft.toString() : ''} name="editSqFtFrom" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSpecific'} />
                            </Col> <span className="to">to</span>
                            <Col md={2} className="margin-top-7">
                              <AvField value={unitDetail.sqftTo > 0 ? unitDetail.sqftTo : ''} name="editSqFtTo" type="number" disabled={editisSpecifiedSizes === 'editPredefined' || editisSpecifiedSizes === 'editSpecific'} />
                            </Col>
                            {countryCode === 'US' || countryCode === 'CA' && spacetype === 'Unit' ?  ''  : regionID === 3 && spacetype === 'Unit'? <label className="mr-tp-13">Please specify size in sq.ft</label>: <label className="mr-tp-13">Please specify size in m²</label>}
                          </Row>
                       }

                          <h4 className="margin-top-5">Unit Features</h4>
                          {(countryCode === 'US') || (countryCode === 'CA')|| (regionID === 3)|| (countryCode === 'AU') || (countryCode === 'NZ')?
                            <Row>
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.climateControlled} name="editClimateControlled" label="Climate Controlled" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </AvField></Col>
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.alarm} name="editAlarm" label="Alarm" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </AvField>
                              </Col>
                            </Row>
                            : ''}
                          <Row>
                            {(countryCode === 'US') || (countryCode === 'CA')|| (regionID === 3)|| (countryCode === 'AU') || (countryCode === 'NZ') ?
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.driveUp} name="editDriveUp" label="Drive Up" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </AvField>
                              </Col>
                              : ''}

                            <Col md={6}>
                              <AvField type="select" value={this.state.unitDetail.power === 0 ? '0' : this.state.unitDetail.power} name="editPower" label="Power" >
                                <option value='-1'>-- --</option>
                                <option value='1'>Yes</option>
                                <option value="0">No</option>
                              </AvField>
                            </Col>

                            {(countryCode === 'US') || (countryCode === 'CA')|| (regionID === 3)|| (countryCode === 'AU') || (countryCode === 'NZ') ?
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.outdoorAccess} name="editOutdoorAccess" label="Outdoor Access" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </AvField>
                              </Col>
                              : ''}
                            <Col md={6}>
                              <AvField type="select" value={this.state.unitDetail.elevator === 0 ? '0' : this.state.unitDetail.elevator} name="editElevator" label="Elevator" >
                                <option value='-1'>-- --</option>
                                <option value='1'>Yes</option>
                                <option value='0'>No</option>
                              </AvField>

                            </Col>
                          </Row>

                          {(countryCode === 'US') || (countryCode === 'CA')|| (regionID === 3) || (countryCode === 'AU') || (countryCode === 'NZ')?
                            <Row>
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.humidityControlled} name="editHumidityControlled" label="Humidity Controlled" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Yes</option>
                                  <option value='0'>No</option>
                                </AvField>
                              </Col>
                              <Col md={6}>
                                <AvField type="select" value={unitDetail.doorType} name="editDoorType" label="Door Type" >
                                  <option value='-1'>-- --</option>
                                  <option value='1'>Rollup</option>
                                  <option value='2'>Swing</option>
                                  <option value='3'>Double</option>
                                  <option value='4'>Chain Link</option>
                                  <option value='5'>Other</option>
                                </AvField>
                              </Col>
                            </Row>
                            : ''}
                        </div>
                        : ''}
                      {this.state.editSpaceChange !== "Unit" ?
                        <div>
                          <Row className="margin-btm-10">
                            <Col md={3}>

                              <Radio className="radio-padding move-ch-top" value="editParkingPredefined" checked={this.state.editParkingisSpecifiedSizes === "editParkingPredefined"} onChange={this.sizeEditParkingChange.bind(this)} name="editParkingSpecific" /> <label className="label-field">Predefined</label>
                            </Col>
                            <Col md={3} className="padding-top-10">
                              <AvField value={this.state.editParkUnits} type="select" onChange={this.editParkingsChange.bind(this)} disabled={this.state.editParkingisSpecifiedSizes === "editParkingSpecific"} name="sizes" >
                                <option value="-1">-- --</option>
                                <option value="1">Car</option>
                                <option value="2">RV</option>
                                <option value="3">Large</option>
                              </AvField>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <Radio className="radio-padding pt-0 move-ch-top" value="editParkingSpecific" checked={this.state.editParkingisSpecifiedSizes === "editParkingSpecific"} onChange={this.sizeEditParkingChange.bind(this)} name="editParkingSpecific" /> <label className="label-field top-move">Specific</label>
                            </Col>

                            <Col md={7} className="selected-fields dynamic-select">
                              <Row className="row-selected">
                                <Col md={12}>
                                  <div className="start-field"><AvField name="editparkingminlength" placeholder="Min.Len" value={this.state.editparkingmaxlength > 0 ? this.state.editparkingminlength.toString() : ''} type="number" disabled={this.state.editParkingisSpecifiedSizes === "editParkingPredefined"} /></div><span><AvField type="number" placeholder="Max.Len" name="editparkingmaxlength" value={this.state.editparkingmaxlength > 0 ? this.state.editparkingmaxlength : ''} disabled={this.state.editParkingisSpecifiedSizes === "editParkingPredefined"} /></span>
                                  <div className="clear"></div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <h4 className="margin-top-5">Unit Features</h4>
                          <Row>
                            <Col md={6}>
                              <AvField type="select" value={unitDetail.covered} name="editCovered" label="Covered" >
                                <option value='-1'>-- --</option>
                                <option value='1'>Yes</option>
                                <option value='0'>No</option>
                              </AvField></Col>
                          </Row>
                        </div>
                        : ''}
                      <div className="step_btn multiple-sub-quotes">
                        <Button id="btn" onClick={this.closeEditUnit.bind(this)} className="reset-cancel btn-design left-cancel">Cancel</Button>
                        <Button id="btn" onClick={this.resetEditUnit.bind(this, id + '/' + countryCode)} className="go-back-btn btn-design reset-btns">Reset</Button>
                        <Button id="btn" className="next-btn submit-btn btn-design">Save</Button>
                      </div>

                    </AvForm>



                  </ModalBody>

                </Modal>

                <Modal isOpen={this.state.deleteModal} toggle={this.delConfirmToggle.bind(this)} className={this.props.className}>
                  <ModalHeader >Are you sure you want to delete?</ModalHeader>
                  <ModalBody className="want-to-delete">
                    <Button color="primary" onClick={this.toggleYes.bind(this)} className="success-btn">Yes</Button>
                    <Button color="secondary" onClick={this.toggleNo.bind(this)} className="edit_cancel">No</Button>
                  </ModalBody>

                </Modal>
                <div>
                  <Modal isOpen={this.state.storeModal}
                  //  toggle={() => this.trackModal()} 
                   className="store-mainpopup"
                   backdrop="static">
                    <ModalHeader toggle={this.trackModalClose}>{StoreName}</ModalHeader>
                    <ModalBody>
                      <Table className="table custom-table table-bordered store-count-popup">
                        <TableHead>
                          <TableRow>
                            <TableCell>COMPETITOR NAME</TableCell>
                            <TableCell >ADDRESS</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trackModalData ? trackModalData.slice(pageCount * 10, pageCount * 10 + 10).map((trackData) =>
                            <TableRow key={trackData.storeid}>
                              <TableCell>{trackData.storename}</TableCell>
                              <TableCell>{trackData.address}</TableCell>

                            </TableRow>
                          ) :
                            <TableRow></TableRow>
                          }
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[1]}
                              colSpan={3}
                              count={trackModalData ? trackModalData.length : 0}
                              rowsPerPage={10}
                              page={pageCount}
                              SelectProps={{
                                native: true,
                              }}
                              onChangePage={this.handleChangePageTrack}
                              onChangeRowsPerPage={this.handleChangeRowsPerPageTrack}
                              ActionsComponent={MyMarketWrapped}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </ModalBody>

                  </Modal>
                </div>
              </div>

            <Snackbar
              open={snackOpen}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              className="snackbar bg-color-primary custom-snackbar"
              maxsnack={3}
              autoHideDuration={30000}
              onClose={this.snackHandleclose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{snackMSg}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.snackHandleclose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </div>),
      )
    );
  }
}

export default flow(
  DragSource(
    'card',
    cardSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  ),
  DropTarget('card', cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(Card);