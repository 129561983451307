import React from 'react';
import {MarketDashboardPdf} from './MarketDashboardPdf';
import {InventoryAvailabilityPdf} from './InventoryAvailabilityPdf';
import {AllUnitPrizePdf} from './AllUnitPrizePdf';
import {PricingVolatilityPdf} from './PricingVolatilityPdf';
import{CurrentPricingPdf} from './CurrentPricingPdf';
import{MarketInventoryAnalysisPdf} from './MarketInventoryAnalysisPdf';
import {DetailedStoresViewPdf} from './DetailedStoresViewpdf';
import {CompOverviewPdf,CompOverviewGmapPdf} from './CompetitorOverviewPdf';
import {MarketScorePdf} from './MarketScorePdf';
import {MapViewsPdf, MapViewsGmapPdf} from './MapViewPdf';
import EffectiveRatesPdf from './EffectiveRatesPdf/EffectiveRatesPdf';
import {CONFIG} from '../../utils/config';

class PdfDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userStoreId: this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0,
            page: this.props.match.params.ComponentName ? parseInt(this.props.match.params.ComponentName) : 0,
            priceTrend: this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 0,
            unit:this.props.match.params.unitType ? parseInt(this.props.match.params.unitType) : 0,
            price:this.props.match.params.priceType ? parseInt(this.props.match.params.priceType) : 0,
            orderby : this.props.match.params.orderBy ? this.props.match.params.orderBy : 'storeName',
            order : this.props.match.params.order ? this.props.match.params.order : 'asc',
            accessKey :this.props.match.params.accessKey ? this.props.match.params.accessKey : '',
            durationFil: this.props.match.params.durTime ? parseInt(this.props.match.params.durTime) : 0, 
        }
    }
    render() {
        return (
          <div>
              {this.props.match.params.ComponentName == 1?
          <MarketDashboardPdf 
          storeid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
          priceTrend = {this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 1}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}

          />
          : this.props.match.params.ComponentName == 2?
          CONFIG.IsMapbox ?
          <CompOverviewPdf 
            storeid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
            sortId = {this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 1}
            accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          />
          :
          <CompOverviewGmapPdf 
            storeid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
            sortId = {this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 1}
            accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          />
          : this.props.match.params.ComponentName == 3?
          <PricingVolatilityPdf 
           userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
           unit = { this.props.match.params.unitType ? this.props.match.params.unitType : 1 }
           price = { this.props.match.params.priceType ? this.props.match.params.priceType : 1 }
           orderby = {this.props.match.params.orderBy ? this.props.match.params.orderBy : 'storeName'}
           order = {this.props.match.params.order ? this.props.match.params.order : 'asc'}
           accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
           showRemoveId = {this.props.match.params.isShowGraph ? this.props.match.params.isShowGraph : ''}
           showRemoveType = {this.props.match.params.isRemoveGraph ? this.props.match.params.isRemoveGraph : ''}
          //  isCompsName = {this.props.match.params.isCompsName ? this.props.match.params.isCompsName : []}
          //  Competitors = {this.props.match.params.isCompsName ? this.props.match.params.isCompsName : []}
           isShowGraph = {[]}
           isRemoveGraph = {[]}
           isCompsName = {[]}
           Competitors = {[]}
           />
          : this.props.match.params.ComponentName == 4?
            <InventoryAvailabilityPdf 
          userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
          orderby = {this.props.match.params.orderBy ? this.props.match.params.orderBy : 'storeName'}
          order = {this.props.match.params.order ? this.props.match.params.order : 'asc'}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          /> 
          : this.props.match.params.ComponentName == 5?
          <MarketScorePdf
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
          orderBy = {this.props.match.params.searchId ? this.props.match.params.searchId : 'storeName'}
          order = {this.props.match.params.pta ? this.props.match.params.pta : 'asc'}
          graphOpen = {this.props.match.params.unitType ? this.props.match.params.unitType : ''}
          id1 = {parseInt(this.props.match.params.priceType ? this.props.match.params.priceType : 0)}
          id2 = {parseInt(this.props.match.params.orderBy ? this.props.match.params.orderBy : 0)}
          id3 = {parseInt(this.props.match.params.order ? this.props.match.params.order : 0)}
          id4 = {parseInt(this.props.match.params.isShowGraph ? this.props.match.params.isShowGraph : 0)}
          id5 = {parseInt(this.props.match.params.isRemoveGraph ? this.props.match.params.isRemoveGraph : 0)}
          id6 = {parseInt(this.props.match.params.isCompsName ? this.props.match.params.isCompsName : 0)}
          id7 = {parseInt(this.props.match.params.id4 ? this.props.match.params.id4 : 0)}
          id8 = {parseInt(this.props.match.params.Competitors ? this.props.match.params.Competitors : 0)}
          />
         : this.props.match.params.ComponentName == 6?
          <AllUnitPrizePdf   userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          orderby = {this.props.match.params.orderBy ? this.props.match.params.orderBy : 'Sort by'}
          showRemoveId = {this.props.match.params.isShowGraph ? this.props.match.params.isShowGraph : ''}
          showRemoveType = {this.props.match.params.isRemoveGraph ? this.props.match.params.isRemoveGraph : ''}
          isRemoveGraph = { this.props.match.params.priceType ? this.props.match.params.priceType : 1 }
          isRemovelen = {this.props.match.params.pta }
          storePlace = {this.props.match.params.searchId }


          />
         
          : (this.props.match.params.ComponentName == 8 || this.props.match.params.ComponentName == 13) ?
           <CurrentPricingPdf
           userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
           searchId={this.props.match.params.searchId ? parseInt(this.props.match.params.searchId) : 0}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          hisactivemenu = { this.props.match.params.unitType ? this.props.match.params.unitType : 1 }
          hisactivedur = { this.props.match.params.priceType ? this.props.match.params.priceType : 1 }
          gridactivedur = {this.props.match.params.pta ? this.props.match.params.pta : 1}
          orderby = {this.props.match.params.orderBy ? this.props.match.params.orderBy : 'storeName'}
          order = {this.props.match.params.order ? this.props.match.params.order : 'asc'}
          grapghCatcheck = {this.props.match.params.isShowGraph == 1 ? "Premium":this.props.match.params.isShowGraph == 2?"Price Range":this.props.match.params.isShowGraph == 3?"Value":''}
          id1 = {this.props.match.params.isRemoveGraph=="no" ?"no": parseInt(this.props.match.params.isRemoveGraph)? parseInt(this.props.match.params.isRemoveGraph) : 0}
          id2 = {parseInt(this.props.match.params.isCompsName ? this.props.match.params.isCompsName : 0)}
          id3 = {parseInt(this.props.match.params.Competitors ? this.props.match.params.Competitors : 0)}
          id4 = {parseInt(this.props.match.params.id4 ? this.props.match.params.id4 : 0)}
          id5 = {parseInt(this.props.match.params.id5 ? this.props.match.params.id5 : 0)}
          id6 = {parseInt(this.props.match.params.id6 ? this.props.match.params.id6 : 0)}
          id7 = {parseInt(this.props.match.params.id7 ? this.props.match.params.id7 : 0)}
          durFilter = {this.state.durationFil}
          isEffectiveRate = { this.props.match.params.ComponentName == 13 ? 1 : 0}
          /> 
          : this.props.match.params.ComponentName == 12?
           <EffectiveRatesPdf
           userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
           searchId={this.props.match.params.searchId ? parseInt(this.props.match.params.searchId) : 0}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          hisactivemenu = { this.props.match.params.unitType ? this.props.match.params.unitType : 1 }
          hisactivedur = { this.props.match.params.priceType ? this.props.match.params.priceType : 1 }
          gridactivedur = {this.props.match.params.pta ? this.props.match.params.pta : 1}
          orderby = {this.props.match.params.orderBy ? this.props.match.params.orderBy : 'storeName'}
          order = {this.props.match.params.order ? this.props.match.params.order : 'asc'}
          grapghCatcheck = {this.props.match.params.isShowGraph == 1 ? "Premium":this.props.match.params.isShowGraph == 2?"Price Range":this.props.match.params.isShowGraph == 3?"Value":''}
          id1 = {this.props.match.params.isRemoveGraph=="no" ?"no": parseInt(this.props.match.params.isRemoveGraph)? parseInt(this.props.match.params.isRemoveGraph) : 0}
          id2 = {parseInt(this.props.match.params.isCompsName ? this.props.match.params.isCompsName : 0)}
          id3 = {parseInt(this.props.match.params.Competitors ? this.props.match.params.Competitors : 0)}
          id4 = {parseInt(this.props.match.params.id4 ? this.props.match.params.id4 : 0)}
          id5 = {parseInt(this.props.match.params.id5 ? this.props.match.params.id5 : 0)}
          id6 = {parseInt(this.props.match.params.id6 ? this.props.match.params.id6 : 0)}
          id7 = {parseInt(this.props.match.params.id7 ? this.props.match.params.id7 : 0)}
          durFilter = {this.state.durationFil}
          />
          : this.props.match.params.ComponentName == 9?
         <MarketInventoryAnalysisPdf 
         userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
         searchId={this.props.match.params.searchId ? parseInt(this.props.match.params.searchId) : 0}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          isDefaultUnit = {this.props.match.params.pta }

          /> 
          : this.props.match.params.ComponentName == 10?
            <DetailedStoresViewPdf
          userstoreid = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
          searchId={this.props.match.params.searchId ? parseInt(this.props.match.params.searchId) : 0}
          accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
          order = { this.props.match.params.unitType  }
          orderBy = { this.props.match.params.priceType  }
          orderBy1 = {this.props.match.params.isShowGraph }
          orderBy2 = {this.props.match.params.isRemoveGraph }
          order1 = {this.props.match.params.orderBy }
          order2 = {this.props.match.params.order }
          /> 
          : this.props.match.params.ComponentName == 11?
          CONFIG.IsMapbox ?
          <MapViewsPdf 
            usId = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
            pta = {this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 1}
            accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
            lat = {this.props.match.params.searchId ? this.props.match.params.searchId : ''}
            lng = {this.props.match.params.unitType ? this.props.match.params.unitType : ''}
          />
          :
          <MapViewsGmapPdf
            usId = {this.props.match.params.usId ? parseInt(this.props.match.params.usId) : 0}
            pta = {this.props.match.params.pta ? parseInt(this.props.match.params.pta) : 1}
            accessKey = {this.props.match.params.accessKey ? this.props.match.params.accessKey : ''}
            lat = {this.props.match.params.searchId ? this.props.match.params.searchId : ''}
            lng = {this.props.match.params.unitType ? this.props.match.params.unitType : ''}
          />
          :''}
          </div>
        )
    }
}
export default PdfDownload;