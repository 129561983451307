import React from 'react';
import { Link, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import StarBorder from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMinusSquare, faPlusSquare, faCaretSquareLeft, faBell, faEnvelopeOpenText, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import Logo from './../../Assets/Images/logo.jpg';
import { AddMarketcmpopup, MissingCompetitor, ActiveReports, CompReports, AddMarketcmpopupGmap } from './../../Views';
import './MarketLayout.css';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import { COMMON } from '../../utils/common';
import { IdleTimeOutModal } from '../../Modal/IdleModal';
const drawerWidth = 290;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        zIndex: 99,
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing * 3,
    },
    marketlayout: {
        background: '#F9F9FA',
        padding: '5px 17px',
        minHeight: 'calc(100vh - 0px)',
    },
    removepadding: {
        padding: '0px',
    },
    myroot: {
        width: '100%',
        maxWidth: 290,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing * 4,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    card: {
        boxShadow: 'none',
    },
});

class MarketLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            leftMenuData: [],
            dashNavData: [],
            one: true,
            two: false,
            three: true,
            storeId: '',
            showModal: false,
            countlen: null,
            localUserStoreId: null,
            countryCode: '',
            userName: '',
            // activeClassName: null,
            localActiveClassSearchName: localStorage.getItem('locSearchName'),
            filterMarketData: [],
            // logginStatus: true
            // isNavigationBlocked: false,
            userTypeCheck: parseInt(localStorage.getItem('MVPUserType')),
            isMVPUser: null,
            reportCompActive:{},
          //  isModal: false
        };
        // this.events = [
        //     "load",
        //     "mousemove",
        //     "mousedown",
        //     "click",
        //     "scroll",
        //     "keypress"
        //   ];
        this.storeIdReq = this.storeIdReq.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.divRef = React.createRef();
        this.child = React.createRef();
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.refreshHandler1 = this.refreshHandler1.bind(this);
        this.activeClass = this.activeClass.bind(this);
        this.refreshMenu = this.refreshMenu.bind(this);
        // this.warn = this.warn.bind(this);
        // this.logout = this.logout.bind(this);
        // this.resetTimeout = this.resetTimeout.bind(this);

        // for (var i in this.events) {
        // window.addEventListener(this.events[i], this.resetTimeout);
        // }

        // this.setTimeout();
    }
    //below the code for session timeout
    // clearTimeout() {
    //     if (this.warnTimeout) clearTimeout(this.warnTimeout);

    //     if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    //   }

    //   setTimeout() {
    //     this.warnTimeout = setTimeout(this.warn, 8 * 1000);

    //     this.logoutTimeout = setTimeout(this.logout, 10 * 1000);
    //   }

    //   resetTimeout() {
    //     this.clearTimeout();
    //     this.setTimeout();
    //   }

    //   warn() {
    //     alert("You will be logged out automatically in 1 minute.");
    //   }

    //   logout() {
    //     //   localStorage.removeItem('accessKey');
    //     // const { from } = this.props.location.state || { from: { pathname: "/" } };
    //     // this.props.history.push(from);
    //     // Send a logout request to the API
    //     console.log("Sending a logout request to the API...");
    //     this.setState({ logginStatus: false });
    //     return <Redirect to='/' />
    //     // const { from } = this.props.children.props.location.pathname || { from: { pathname: "/" } };
    //     // this.props.children.props.location.pathname.push(from);

    //     // // this.destroy(); // Cleanup
    //   }

    //   destroy() {
    //     this.clearTimeout();

    //     for (var i in this.events) {
    //       window.removeEventListener(this.events[i], this.resetTimeout);
    //     }
    //   }

    onShowModal = () => {
        this.setState({ showModal: true })
    }

    activeClass() {
        this.setState({
            // activeClassName: id,
            localActiveClassSearchName: localStorage.getItem('locSearchName')
        });
    }

    handleClick = (index) => {
        if (index === 'one') {
            this.setState(state => ({ one: !state.one }));
        }
        if (index === 'two') {
            this.setState(state => ({ two: !state.two }));
        }
        if (index === 'three') {
            this.setState(state => ({ three: !state.three }));
        }
    };

    handleClickOpen(e, id) {
        this.child.current.ModalClickOpen(id);
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };


    async storeIdReq(id) {
        // localStorage.removeItem('StId');
        // localStorage.setItem('StId', id);
        // this.state.storeId = id;
        this.state.storeId = id ? id : this.state.storeId;
        const data = {
            UserStoreId: this.state.storeId,
            localUserStoreId: this.state.storeId,
            RegionId: CONFIG.RegionId
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        await axios.post(CONFIG.API_URL + 'account/dashboardnavigation', data)
       .then((res) => {
        const reportCompActive = res.data.report;
        const dashNavData = res.data.data;
        const cCode = res.data.data.length > 0 ? res.data.data[0].countryCode : "";
        this.setState({
            dashNavData,
            localUserStoreId: this.state.storeId,
            countryCode: cCode, reportCompActive
        });
        this.activeClass();
      })
      .catch((err) => {
        if (err.response.status === 401) {
            localStorage.removeItem('accessKey');
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
        }
        else {
            alert(err.response.data);
        }
      });  
    }
  async  componentDidMount() {
        let token = localStorage.getItem('accessKey');
        // let userName = localStorage.getItem('getNames');
        let isPaid = localStorage.getItem('isPaid');
        let tempNoStoreCount = localStorage.getItem('tempNoStoreCount');
        let localUserStoreId = localStorage.getItem('StId');
        let refreshMenu = localStorage.getItem('refreshMenu');
        let userName = localStorage.getItem('getNames');
       
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        // axios.post(CONFIG.API_URL + 'account/marketnavigation' + CONFIG.Region_Id)
        const response = await axios.post(CONFIG.API_URL + 'account/marketnavigation/' + CONFIG.RegionId)
        if(response.status === 200){

            const leftMenuData = response.data
            var filteredData = leftMenuData.filter(resultSet => resultSet.isCoverage === 1 );
            let countlen = leftMenuData.length;
            // let localActiveClassSearchName = localStorage.getItem('locSearchName');
            this.setState({
                leftMenuData, refreshMenu, userName, isPaid, tempNoStoreCount, countlen, localUserStoreId, filterMarketData: filteredData//, localActiveClassSearchName
            });

        }
        if (localUserStoreId != null) {
            this.storeIdReq(localUserStoreId);
        }
        this.isMvpUser();
    }
    
    
    isMvpUser(){
        let userVerify = parseInt(this.state.userTypeCheck);
        let mvpTypes = COMMON.MVPUSER;
        let validate = mvpTypes.filter(data => data === userVerify ? true : false)
        console.log(validate.length);
        if(validate.length === 0){
            this.setState({isMVPUser: false});
            localStorage.setItem('isMVP_User', 'false');
        }else{
            this.setState({isMVPUser: true});
            localStorage.setItem('isMVP_User', 'true');
        }
    }

    // to update storeid and call the storeIdReq method
    componentWillReceiveProps(nextProps){
        let localUserStoreId = localStorage.getItem('StId'); 
        if(nextProps.children.props.match.params.storeid && nextProps.children.props.match.path === '/market-dashboard/:storeid/' && nextProps.children.props.match.params.storeid !== localUserStoreId ) {
           this.storeIdReq(parseInt(nextProps.children.props.match.params.storeid));
        }
    }

    signOut() {
        localStorage.removeItem('accessKey');
        localStorage.removeItem('tempNoStoreCount');
        localStorage.removeItem('isPaid');
        localStorage.removeItem('getNames');
        localStorage.removeItem('instructionMsg');
        localStorage.removeItem('StId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userStatus');
        localStorage.removeItem('locSearchName');
        localStorage.removeItem('userManual');
        localStorage.removeItem('refreshMenu');
        localStorage.removeItem('IsUpdatePaid');
        localStorage.removeItem('displayMessage');
        // this.state.isNavigationBlocked = true;
       // this.props.history.replaceState({ pathname: `/logins/null` })

       // this.props.children.props.history.push({ pathname: `/` });
     //  window.top.location.href = CONFIG.STK_URL+"index.html";
     window.top.location.href ="/page_expired";
     
    }
    addClass() {
        this.divRef.current.classList.add('main-div')
    }
    removeClass() {
        this.divRef.current.classList.remove('main-div')
    }
    refreshHandler1() {
        this.componentDidMount();
    }
    refreshMenu() {
        localStorage.setItem('refreshMenu', "show-menu");
        this.componentDidMount();
    }

    handleClose = () =>  {
        this.setState({isModal: false})
    }
    handleLogout = () =>  {
        localStorage.clear();
        this.setState({isModal: false});
        window.top.location.href ="/page_expired";
        //window.top.location.href = CONFIG.STK_SIGNURL;
    }

    // IdealModel = () => {
    //     this.setState({isModal : !this.state.isModal})
    // }

    render() {
        const { leftMenuData, dashNavData, userName, refreshMenu, isPaid, StrId, tempNoStoreCount, countlen, localUserStoreId, localActiveClassSearchName, filterMarketData, userTypeCheck, isMVPUser, reportCompActive } = this.state;

        const { classes, theme } = this.props;
        const drawer = (
            <div className="left-sidebar">
                <div id="r1" onClick={this.refreshHandler1}></div>
                <List className="dashboard-logo">
                    {localStorage.getItem('isMVP_User') === 'true' ?
                    <a href="javascript:void(0)">
                        <img src={Logo} className="logo" alt="stortrack" />
                    </a> :
                    <Link to="/my-market/" onClick={() => this.refreshMenu()}>
                        <img src={Logo} className="logo" alt="stortrack" />
                    </Link>}
                </List>
                <List className="pb-0">
                    <ListItem>
                        <div className="welcome-header">
                            <div className="top-welcome">Welcome</div>
                            <span className="text-uppercase">{userName ? userName : 'User'}</span>
                        </div>
                    </ListItem>
                </List>
                <Divider />
                <div ref={this.divRef} className="nav-controller">
                    {/* {isMVPUser === true ?
                    <a href={CONFIG.MVP_URL+`${localStorage.getItem('SecretKey')}/${localStorage.getItem('mvpUserStoreId')}/${localStorage.getItem('OptStoreId')}`} >
                        <ListItem button className="remove-class mvp-link" >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faCaretSquareLeft} />
                            </ListItemIcon>
                            <ListItemText className="list-item-text" inset primary="Back to Explorer Dashboard" />
                        </ListItem>
                    </a>: ''} */}
                    {isMVPUser === false ?
                    <List className="remove-padding">
                        <Link to="/my-account/" onClick={() => this.refreshMenu()}>
                            <ListItem button className="set-padding">
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faUser} />
                                </ListItemIcon>
                                <ListItemText className="list-item-text" inset primary="My Account" />
                            </ListItem>
                        </Link>
                    </List> : ''}

                    {this.props.children.props.location.pathname == "/my-account/" || this.props.children.props.location.pathname == "/my-market/" || this.props.children.props.location.pathname == "/subscription-details/" || this.props.children.props.location.pathname == "/billing-history" || this.props.children.props.location.pathname == "/UnauthorizedAccess" && isMVPUser === false ?
                        <div className={refreshMenu == "show-menu" ? 'pri-mar-nav refresh-active' : 'pri-mar-nav'}>

                            {isPaid === '1' || localStorage.getItem('IsUpdatePaid') == "1" ?
                                <List component="nav" className={`${this.props.classes.myroot} ${this.props.classes.removepadding}`}>
                                    {/* <Divider /> */}
                                    {/* <ListItem button className="set-padding p-0 miss-comp">
                                        <ListItemIcon className="p-l-16 min-w-30 mar-right-12">
                                            <FontAwesomeIcon icon={faBell} />
                                        </ListItemIcon>
                                        <MissingCompetitor className="list-item-text p-t3-b5" isShowModal={this.state.showModal} />
                                    </ListItem> */}
                                    <Divider />
                                    <Link to="/my-market/" className="remove-padding">
                                        <ListItem
                                            button
                                            onClick={() => this.handleClick('one')}
                                            className="set-padding">
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faPoll} />
                                            </ListItemIcon>
                                            <ListItemText className="list-item-text" inset primary="My Markets" />
                                            {this.state.one ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}

                                        </ListItem>
                                    </Link>

                                    <Collapse in={this.state.one} timeout="auto" unmountOnExit className="left-sub-menu">
                                        <List component="div" disablePadding className="sub-menu-list-wrap">
                                            {filterMarketData.map((leftNaV) => {
                                                return (
                                                    <ListItem key={leftNaV.userStoreID} button className="p-0 add-class sublist-anchor" onClick={() => { this.addClass() }} >
                                                        {/* <ListItem key={leftNaV.userStoreID} button className="p-0 add-class sublist-anchor" >  */}
                                                        <Link to={{ pathname: `/market-dashboard/${leftNaV.userStoreID}` }} className={classes.nested} onClick={() => this.storeIdReq(leftNaV.userStoreID)} >

                                                            <ListItemText className="list-item-text" inset primary={leftNaV.storeName} />
                                                        </Link>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                    {tempNoStoreCount !== countlen && tempNoStoreCount > countlen ?
                                        <Link to="#" className="remove-padding">
                                            <ListItem button onClick={(e) => this.handleClickOpen(e, 0)} className="set-padding">
                                                <ListItemIcon>
                                                    <FontAwesomeIcon icon={faPlusSquare} />
                                                </ListItemIcon>
                                                <ListItemText className="list-item-text" inset primary="Add New Market" />
                                            </ListItem>
                                        </Link>
                                        : ''}
                                </List>
                                : ''}
                        </div>
                        :
                        <div className="sec-mar-nav">
                            {isPaid === '1' ?
                                <List component="nav" className="remove-padding">
                                    {isMVPUser === false ?
                                    <Divider /> : ''}
                                    {isMVPUser === false ?
                                    <Link to="/my-market/" >
                                        <ListItem button className="remove-class" onClick={() => { this.removeClass() }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCaretSquareLeft} />
                                            </ListItemIcon>
                                            <ListItemText className="list-item-text" inset primary="Back to My Markets" />
                                        </ListItem>
                                    </Link>: ''}
                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={() => this.handleClick('two')}
                                        className="market-icon set-padding-left">
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faArrowsAlt} />
                                        </ListItemIcon>
                                        <ListItemText className="list-item-text" inset primary="Unit Type Shortcuts" />
                                        {this.state.two ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                                    </ListItem>
                                    <Divider />
                                    <Collapse in={this.state.two} timeout="auto" unmountOnExit className="left-sub-menu set-max-unit-type">
                                        <List component="div" disablePadding className="sub-menu-list-wrap sublist-anchor">
                                            {dashNavData && dashNavData.map((dashNav) => {
                                                return (
                                                    <ListItem onClick={this.activeClass} key={dashNav.searchID} button className={this.state.localActiveClassSearchName == dashNav.searchName && this.props.children.props.match.path == "/market-dashboard/:storeid/view-details/:searchid" ? 'unit-type activeItem p-0' : '' + 'p-0'}>
                                                        <Link to={{ pathname: `/market-dashboard/${this.state.localUserStoreId}/view-details/${dashNav.searchID}` }} className={classes.nested} >

                                                            <ListItemText inset primary={dashNav.searchName} className="list-item-text" />
                                                        </Link>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                </List>
                                : ''}
                            <List className="remove-padding">
                                <ListItem
                                    button
                                    onClick={() => this.handleClick('three')}
                                    className="market-icon set-padding-left">
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faColumns} />
                                    </ListItemIcon>
                                    <ListItemText className="list-item-text" inset primary="Market Dashboard" />
                                    {this.state.three ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                                </ListItem>
                                <Collapse in={this.state.three} timeout="auto" unmountOnExit className="left-sub-menu">
                                    <List disablePadding className="sublist-anchor">
                                        <ListItem button className={this.props.children.props.match.path == "/market-level-data/competitor-overview/:userstoreid/" ? 'active-menu p-0 extra-btn' : 'p-0 extra-btn'}>
                                            <Link to={{ pathname: `/market-level-data/competitor-overview/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                {/* <ListItemIcon className="sublist-anchor-icon">
                                                     <StarBorder className="star-icon" />
                                                </ListItemIcon> */}
                                                <ListItemText className="list-item-text" inset primary="Competitor Overview" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className={this.props.children.props.match.path == "/market-level-data/pricing-volatility/:userstoreid/" ? 'active-menu p-0 extra-btn' : 'p-0 extra-btn'}>
                                            <Link to={{ pathname: `/market-level-data/pricing-volatility/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                {/* <ListItemIcon className="sublist-anchor-icon">
                                                    <StarBorder className="star-icon" />
                                                </ListItemIcon> */}
                                                <ListItemText className="list-item-text" inset primary="Price Volatility" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className={this.props.children.props.match.path == "/market-level-data/inventory-analytics/:userstoreid/" ? 'active-menu p-0 extra-btn' : 'p-0 extra-btn'}>
                                            <Link to={{ pathname: `/market-level-data/inventory-analytics/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                {/* <ListItemIcon className="sublist-anchor-icon">
                                                    <StarBorder className="star-icon" />
                                                </ListItemIcon> */}
                                                <ListItemText className="list-item-text" inset primary="Inventory Analytics" />
                                            </Link>
                                        </ListItem>
                                        {this.state.countryCode === "US" || this.state.countryCode === "CA" || CONFIG.RegionId === 3 ?
                                            <ListItem button className={this.props.children.props.match.path == "/marketing-score/:storeid" ? 'active-menu p-0 extra-btn marketing-beta' : 'p-0 extra-btn marketing-beta'}>
                                                <Link to={{ pathname: `/marketing-score/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                    {/* <ListItemIcon className="sublist-anchor-icon">
                                                        <StarBorder className="star-icon" />
                                                    </ListItemIcon> */}
                                                    {/*<ListItemText  className="list-item-text" inset primary="Marketing Scorecard" />*/}
                                                    <ListItemText inset
                                                        disableTypography
                                                        primary={<span>Marketing Scorecard</span>}
                                                    />
                                                </Link>
                                            </ListItem>
                                            : ''}
                                
                                        <ListItem button className={this.props.children.props.match.path == "/market-level-data/all-units-pricing/:userstoreid/" ? 'active-menu p-0 extra-btn' : 'p-0 extra-btn'}>
                                            <Link to={{ pathname: `/market-level-data/all-units-pricing/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                {/* <ListItemIcon className="sublist-anchor-icon">
                                                    <StarBorder className="star-icon" />
                                                </ListItemIcon> */}
                                                <ListItemText className="list-item-text" inset primary="All Units Pricing" />
                                            </Link>
                                        </ListItem>
                                        <ListItem button className={this.props.children.props.match.path == "/market-level-data/alert/:userstoreid/" ? 'active-menu p-0 extra-btn' : 'p-0 extra-btn'}>
                                            <Link to={{ pathname: `/market-level-data/alert/${this.state.localUserStoreId}` }} className={classes.nested}>
                                                {/* <ListItemIcon className="sublist-anchor-icon">
                                                    <StarBorder className="star-icon" />
                                                </ListItemIcon> */}
                                                <ListItemText className="list-item-text" inset primary="Market Alerts" />
                                            </Link>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </List>
                        </div>
                    }
                </div>
                <Divider />
                {this.props.children.props.location.pathname == "/my-account/" || this.props.children.props.location.pathname == "/my-market/" || this.props.children.props.location.pathname == "/subscription-details/" || this.props.children.props.location.pathname == "/billing-history" || this.props.children.props.location.pathname == "/UnauthorizedAccess" && isMVPUser === false ? '' :
                <div>
                <List className="remove-padding">
                <Link to="#" className={reportCompActive.isActivityReport != 1 ? 'grey-out' : ''}>
                        <ListItem button className="set-padding p-0 miss-comp">
                            <ListItemIcon className="p-l-16 min-w-30 mar-right-12">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} className="man-act-repo-menu" />
                            </ListItemIcon>
                            <ActiveReports className="list-item-text p-t3-b5" isCompStatus={reportCompActive.isCompReport} isGreyOut ={reportCompActive.isActivityReport} isActiveOpen={this.state.showModal} storeId ={this.state.storeId} parentMethod={this.storeIdReq} isFrom="LEFT_NAV" />
                        </ListItem>
                    </Link>
                    <Divider/>
                    {/* {reportCompActive.competitorStoreID != null && reportCompActive.competitorStoreID != 0 ? */}
                    <React.Fragment>
                    <Link to="#" className={reportCompActive.isCompReport != 1 ? 'grey-out' : ''}>
                        <ListItem button className="set-padding p-0 miss-comp">
                            <ListItemIcon className="p-l-16 min-w-30 mar-right-12">
                                <FontAwesomeIcon icon={faFileExcel} className="man-act-repo-menu" />
                            </ListItemIcon>
                            <CompReports className="list-item-text p-t3-b5" isActiveStatus={reportCompActive.isActivityReport} isGreyOut ={reportCompActive.isCompReport} isCompOpen={this.state.showModal} storeId ={this.state.storeId} parentMethod={this.storeIdReq} isFrom="LEFT_NAV" />
                        </ListItem>
                    </Link>
                    <Divider/>
                    </React.Fragment>
                    {/* : ''} */}
                                </List>
                    </div>
                    }
                <List className="remove-padding">
                {isPaid === '1' || localStorage.getItem('IsUpdatePaid') == "1" ?
                    <Link to="#">
                        <ListItem button className="set-padding p-0 miss-comp">
                            <ListItemIcon className="p-l-16 min-w-30 mar-right-12">
                                <FontAwesomeIcon icon={faBell} />
                            </ListItemIcon>
                            <MissingCompetitor className="list-item-text p-t3-b5" isShowModal={this.state.showModal} />
                        </ListItem>
                    </Link>
                    :""}
                    {isPaid === '1' || localStorage.getItem('IsUpdatePaid') == "1" ?<Divider />:""}
                    {isMVPUser === false ?
                        <Link to="/page_expired" onClick={this.signOut.bind(this)}>
                        {/* <Link onClick={this.IdealModel.bind(this)}> */}
                    <ListItem button className="set-padding">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </ListItemIcon>
                            <ListItemText className="list-item-text" inset primary="Logout" />
                        </ListItem>
                    </Link>: ''}
                </List>
            </div>
        );
        return (
            <div className={classes.root}>
                <Hidden smUp>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Hidden>
                <nav className={classes.drawer} id="left-nav-bar">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={`${this.props.classes.content} ${this.props.classes.marketlayout}`}>
                    <Hidden smUp><div className={classes.toolbar} /></Hidden>
                    {this.props.children}
                </main>
                {CONFIG.IsMapbox ?
                <AddMarketcmpopup parentMethod1={this.refreshHandler1} parm={this.props} ref={this.child} />
                :
                <AddMarketcmpopupGmap parentMethod1={this.refreshHandler1} parm={this.props} ref={this.child} />}
               { this.state.isModal ? <IdleTimeOutModal 
                    showModal={this.state.isModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                    isFrom={"1"}
                /> : ''}
            </div>
        );
    }
}

MarketLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(MarketLayout);