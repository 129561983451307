import React, { Component } from 'react';
import ReactDOM from "react-dom";
import './MarketSaturation.css';
import Paper from '@material-ui/core/Paper';
import * as d3 from "d3";
import { Col, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { CONFIG } from '../../utils/config';
import axios from 'axios';

export default class MarketSaturation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      storeAddress: '',
    }
    this.datavalue = [
      {
        "salesperson": "Bob",
        "sales": 60,

      },
      {
        "salesperson": "Robin",
        "sales": 12,
      }
    ];
  }
  componentDidMount() {
    this.setState({
      isLoading: true
    })
    //this.barChart(this.data);
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    const data1 = {
      "UserStoreID": 117241,
      "RegionId": CONFIG.RegionId
    }
    axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation/', data1)
      .then(res => {
        let storeAddress = res.data.data.storeDashboard;
        this.setState({ storeAddress });
        //console.log(storeAddress);

      })
      .catch((err) => {

        this.setState({ isLoading: false });
        if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        // if (err.response.status === 401) {
        //    localStorage.removeItem('accessKey');
        //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //     this.props.history.push(from);
        //   }
        //   else {
        //     alert(err.response.data);
        //   }
      });
  }

  render() {
    const { storeAddress } = this.state;
    return (

      <div>
        <Row className="overview-header mt-2">
          <Col md="5" className="mr-2 custom-md-5">
            <Col md="12" className="p-v-15 border-box shadow-sm rounded">
              <Row>
                <Col sm="6" className="padding-right-8">
                  <h2>Inventory Availability</h2>
                  <p>Analyzing <Link to="#">5 Stores</Link></p>
                </Col>
                <Col sm="6" className="pl-0">
                  <h3>Market Name</h3>
                  <p className="m-0">{storeAddress.storeAddress1 + "," + storeAddress.storeCity}{storeAddress.storeState + ',' + storeAddress.storeZipCode}</p>
                  <p className="m-0 pt-1">{"Coverage: " + storeAddress.storeZoneCoverage}</p>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col md="7" className="">
            <Col md="12" className="p-v-15 p-37-15 border-box shadow-sm rounded">
              <Row className="height-full">

              </Row>
            </Col>
          </Col>
        </Row>


      </div>
    );
  }
}