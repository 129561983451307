import React from 'react';
import './MarketProfile.css'
import { Col, Row, Modal, ModalHeader, ModalBody,ModalFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CONFIG } from '../../utils/config';
import moment from 'moment';
import { Table } from 'reactstrap';
import Alarm from './../../Assets/Images/unit-type-images/Alarm.png';
import ClimateControlled from './../../Assets/Images/unit-type-images/ClimateControlled.png';
import DoorType from './../../Assets/Images/unit-type-images/DoorType.png';
import DriveUp from './../../Assets/Images/unit-type-images/DriveUp.png';
import Elevator from './../../Assets/Images/unit-type-images/Elevator.png';
import HumidityControlled from './../../Assets/Images/unit-type-images/HumidityControlled.png';
import OutdoorAccess from './../../Assets/Images/unit-type-images/OutdoorAccess.png';
import power from './../../Assets/Images/unit-type-images/power.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


export default class MarketProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            marPriceProfileData: [],
            StrName: '',
            isLoading1: false,
            focusAfterClose: false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalClose = this.toggleModalClose.bind(this);
    }
    // toggleModal = () => this.setState(state => (
    //     { modal: !state.modal })
    // )
    compModal = (name, id) => {
        this.toggleModal(name, id);
    } 

    toggleModal(name, sid) {
        // console.log(name, sid, 'test');
        this.setState(state => ({
            modal: !state.modal,
            StrName: name,
            isLoading1: true
        }))
        const data = {
            StoreId: sid,
        }
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.post(CONFIG.API_URL + 'profile/marketpriceprofile', data)
        
            .then(response => {
                // this.state.isLoading1=true;
                const marPriceProfileData = response.data.data
                this.setState({
                    marPriceProfileData, isLoading1: false
                })
                console.log(marPriceProfileData, 'priceProfile')
            }).catch((err) => {
                this.setState({ isLoading1: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    cancelMissingCompetitor = () => this.setState(state => ({ modal: !state.modal }))

    componentDidMount() {
        if (typeof window.dataLayer != "undefined" && this.props.isShowModal) {
            window.dataLayer.push({
                "event": "show popup",
                'eventCategory': 'popup',
                'eventAction': 'show',
                'eventLabel': this.state.popupName,
            });
        }

    }
    toggleModalClose() {
        this.setState(state => ({
            modal: !state.modal,
            marPriceProfileData: []
        }))
    }

    render() {
        const { marPriceProfileData, isLoading1 } = this.state;
        return (
            <div className="missing-Competitor">
                <Modal backdrop="static" returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.state.modal} toggle={this.toggleModalClose} id="missing-Competitor-modal" className="market-profile" size="lg">
                    <ModalHeader toggle={this.toggleModalClose}>Historical Marketing and Pricing Information</ModalHeader>
                    <ModalBody>
                        <Row className="market-top">
                            <Col md="8">
                                <h3 className="market-profile-heading">
                                    <Row>
                                        <div className="col-lg-4 label-address">Market Details:</div>
                                        {isLoading1===false ?
                                        <div className="col-lg-8 pop-store-add">
                                            <span><b>{marPriceProfileData.unitMarketDetail ? marPriceProfileData.unitMarketDetail.storeName : 'N/A'}</b></span>,<br/><span>{marPriceProfileData.unitMarketDetail ? marPriceProfileData.unitMarketDetail.address : 'N/A'}</span>
                                        </div> : ''}
                                    </Row>
                                    </h3>
                            </Col>
                            <Col md="4">
                                <h4 className="text-right">Last Checked:   {isLoading1===false ?<span className="heading-span">{marPriceProfileData.unitMarketDetail && marPriceProfileData.unitMarketDetail.lastCheckedDate ? moment(marPriceProfileData.unitMarketDetail.lastCheckedDate).format('DD-MMM-YYYY') : 'N/A'}</span> : ''}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="comp-height">
                                <div className="market-height">
                                    <Table className="comp-view-grid table custom-table table-bordered mp_align">
                                        <thead>
                                            <tr>
                                                <th>space Type</th>
                                                <th>Size</th>
                                                <th>Floor</th>
                                                <th>Features</th>
                                                <th>{CONFIG.RegionId === 3 ? "Advertised Online Weekly Rate":"Advertised Online Rate"}</th>
                                                {/* {CONFIG.RegionId == 3?  <th style={{width: 'initial'}}>VAT</th>:''} */}
                                                 <th style={{width: '95px'}}>Last Changed</th>
                                                <th>{CONFIG.RegionId === 3 ? "Advertised Walk-in Weekly Rate" : "Advertised Walk-in Rate"}</th>
                                                {/* {CONFIG.RegionId == 3?  <th style={{width: 'initial'}}>VAT</th>:''} */}
                                                <th style={{width: '95px'}}>Last Changed</th>
                                                <th>Promotion</th>
                                                <th>Last Changed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                                            {marPriceProfileData.unitLevel && marPriceProfileData.unitLevel.length != 0 ? marPriceProfileData.unitLevel.map((markProfile) =>
                                                <tr key={markProfile.storeID}>
                                                    <td>{markProfile.spaceType !== null ? markProfile.spaceType : 'N/A'}</td>
                                                    <td>{markProfile.size !== null ? markProfile.size : ''}</td>
                                                    <td>{markProfile.floor !== null ? markProfile.floor : 'N/A'}</td>
                                                    <td>
                                                        {markProfile.alarm === 1 ? <img src={Alarm} title="Alarm" /> : ''}
                                                        {markProfile.climateControlled === 1 ? <img src={ClimateControlled} title="Climate Controlled" /> : ''}
                                                        {markProfile.doorType === 1 ? <img src={DoorType} title={markProfile.doorType === 1 ? 'Roll-Up Door' : markProfile.doorType === 2 ? 'Swing Door' : markProfile.doorType === 3 ? 'Double Door' : markProfile.doorType === 4 ? 'Chain Link Door' : markProfile.doorType === 5 ? 'Other Door' : ''} /> : ''}
                                                        {markProfile.driveUp === 1 ? <img src={DriveUp} title="Drive Up" /> : ''}
                                                        {markProfile.elevator === 1 ? <img src={Elevator} title="Elevator" /> : ''}
                                                        {markProfile.humidityControlled === 1 ? <img src={HumidityControlled} title="Humidity Controlled" /> : ''}
                                                        {markProfile.outdoorAccess === 1 ? <img src={OutdoorAccess} title="Outdoor Access" /> : ''}
                                                        {markProfile.power === 1 ? <img src={power} title="Power" /> : ''}
                                                    </td>
                                                    <td>{markProfile.onlinePrice === 0 || markProfile.onlinePrice == null ? 'N/A':marPriceProfileData.unitMarketDetail.currencySymbol+markProfile.onlinePrice}{(markProfile.isMonthly && markProfile.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</td>
                                                    {/* {CONFIG.RegionId ===3?<td>{markProfile.onlinePriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:markProfile.onlinePriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</td>:''} */}
                                                    <td>{moment(markProfile.onlinePriceLastChanged).format('DD-MMM-YYYY')}</td>
                                                    <td>{markProfile.regularPrice === 0 || markProfile.regularPrice == null ? 'N/A':marPriceProfileData.unitMarketDetail.currencySymbol+markProfile.regularPrice}{(markProfile.isMonthly && markProfile.isMonthly === 1) && (CONFIG.RegionId === 3) ? <sup>*</sup> :''}</td>
                                                    {/* {CONFIG.RegionId ===3?<td>{markProfile.regularPriceVat ==1?<FontAwesomeIcon icon={faCheck} className="green-clr" title={"Price includes VAT"} />:markProfile.regularPriceVat ==0?<FontAwesomeIcon icon={faTimes} className="red-clr" title={"Price excludes VAT"} />:'--'}</td>:''} */}
                                                    <td>{moment(markProfile.regularPriceLastChanged).format('DD-MMM-YYYY')}</td>
                                                    {/* <td>{markProfile.promo === 0 || markProfile.promo == null || markProfile.promo == ""  ? 'No specials':marPriceProfileData.unitMarketDetail.currencySymbol+markProfile.promo}</td> */}
                                                    <td>{markProfile.promo === 0 || markProfile.promo == null || markProfile.promo == ""  ? 'No specials':markProfile.promo}</td>
                                                    <td>{moment(markProfile.promoLastChanged).format('DD-MMM-YYYY')}</td>
                                                </tr>
                                            ) :
                                                <tr>
                                                    {isLoading1===false ? <td colSpan="12" className="text-center no-data">No records found</td> : '' }
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                {/* {CONFIG.RegionId == 3 ?
                                <div className="new-legend legend-box">
                                    <b>Legend:</b>
                                    <FontAwesomeIcon icon={faCheck} className="green-clr" /> Price includes VAT
                                    <FontAwesomeIcon icon={faTimes} className="red-clr" /> Price excludes VAT 
                                    <FontAwesomeIcon  className="red-clr" /> <span>--</span>Unknown if price includes VAT
                                </div>
                                : ''} */}
                            </Col>
                        </Row>
                    </ModalBody>
                    {CONFIG.RegionId === 3 ?
                    <ModalFooter className="market-price-uk-footer">
                    <p className = "sqft-disclaimer mb-1">* This rate is calculated from an advertised monthly rate</p>
                    </ModalFooter>
                    :''}
                </Modal>
            </div>
        );
    }
}