import React, { Component } from 'react';
import './MarketScorePdf.css';
import { Row, Col, Button } from 'reactstrap';
import Chart from "react-apexcharts";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Table as ReactTable } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { CONFIG } from '../../../utils/config';
import addtomap from '../../../Assets/Images/plus.png';
import removefrommap from '../../../Assets/Images/trash.png';
import tableplus from '../../../Assets/Images/table-plus.png';
import tableminus from '../../../Assets/Images/table-minus.png';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import Logo from '../../../Assets/Images/logo.jpg';

const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

class MyMarket extends React.Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };


    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.tablepaggination}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
    MyMarket,
);

export default class MarketScorePdf extends Component {
    constructor(props) {
        super(props);
        this.compstorechild = React.createRef();
        this.state = {

            options: {
                chart: {
                    id: 'apexchart-example',
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false,
                      },
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    show: false
                },
                noData: {
                    text: "Please wait for the data to be loaded. The data volume is high and this might take a minute for loading the data.",
                    verticalAlign: 'top',
                    style: {
                        color: "#5b95f6",
                        fontSize: '13px',
                        fontFamily: "Helvetica"
                    }
                },
            },
            series: [],
            isLoading1: true,
            order: 'asc',
            orderBy: 'storeName',
            snackOpen: false,
            snackMSg: '',
            grapghCatcheck: 'Total',
            graphOpen: 'Total',
            MarkScoringData: [],
            MarkScoring: [],
            MarkStoreInfo: {},
            Markets: [],
            MarketsDesc: {},
            ChartTableData: {},
            GraphCompId: {},
            locationInfoData: {},
            pageCount: 0,
            trackModalData: '',
            storeModal: false,
            defaultWidth: 0,
            dialogOpen: false,
            MarketSCdet: {},
            Scorecard: [],
            uniquestores: [],
            storeIdCount: [],
            accessKey: ''
        };
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.stableSort = this.stableSort.bind(this);
        this.getSorting = this.getSorting.bind(this);
        this.desc = this.desc.bind(this);
        this.onSelecCatGrapgh = this.onSelecCatGrapgh.bind(this);
        this.scoringData = this.scoringData.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.getParams = this.getParams.bind(this);
        this.dialogContentOpen = this.dialogContentOpen.bind(this);
    }

    getParams(storeAddress) {
        let storeId = parseInt(this.props.match.params.storeid);
        this.compstorechild.current.toggleStoreModal(storeAddress, storeId);
    }

    //Table Sorting 
    handleRequestSort(event, property) {
        const { order, orderBy } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        let sort = isDesc ? 'asc' : 'desc';
        this.setState({ order: sort, orderBy: property });
    }

    //Table Sorting order
    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    //Table Sorting the selected Column
    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    //Table Sorting Order Request
    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    //To open Particular Category's Graph 
    onSelecCatGrapgh(openActGraph) {
        let activeRow = this.state.grapghCatcheck === openActGraph ? '' : openActGraph;
        activeRow === '' ?
            this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow })
            :
            this.setState({ grapghCatcheck: activeRow, graphOpen: activeRow }, () => {
                this.drawChart();
            });
    }

    async UNSAFE_componentWillMount() {
        const data = {
            "SecretKey": this.props.accessKey,
            "RegionId": CONFIG.RegionId
        }

        const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
        if (response.status === 200) {
            this.state.accessKey = response.data.token;
            this.initialLoad();
        } else {
            alert(response.status.Message);
        }
    }

    initialLoad() {
        let openValue;
        openValue = this.props.graphOpen === '1' ? "Total" : this.props.graphOpen === '2' ? "Website" : this.props.graphOpen === '3' ? "Search" : this.props.graphOpen === '4' ? "Paid Search" : this.props.graphOpen === '5' ? "Social Media" : this.props.graphOpen === '6' ? "Contactability" : "Total";
        this.setState({ graphOpen: openValue, grapghCatcheck: openValue });
        let id1, id2, id3, id4, id5, id6, id7, id8;
        let storeIdCount = [];
        let order = this.props.order;
        let orderBy = this.props.orderBy;
        id1 = parseInt(this.props.id1 ? this.props.id1 : 0);
        id2 = parseInt(this.props.id2 ? this.props.id2 : 0);
        id3 = parseInt(this.props.id3 ? this.props.id3 : 0);
        id4 = parseInt(this.props.id4 ? this.props.id4 : 0);
        id5 = parseInt(this.props.id5 ? this.props.id5 : 0);
        id6 = parseInt(this.props.id6 ? this.props.id6 : 0);
        id7 = parseInt(this.props.id8 ? this.props.id8 : 0);
        id8 = parseInt(this.props.id7 ? this.props.id7 : 0);
        let storeCount = [id1, id2, id3, id4, id5, id6, id7, id8];
        storeCount.map(data => {
            if (data != 0) {
                storeIdCount.push(data);
            }
        })
        let uniquestores = storeIdCount.toString();
        this.setState({ uniquestores, storeIdCount, orderBy, order });
        let token = this.state.accessKey;//localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        // For Current Store Info
        const data = {
            "UserStoreID": parseInt(this.props.userstoreid),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation', data)
            .then(response => {
                const locationInfoData = response.data.data.storeDashboard;
                this.setState({ locationInfoData });
            }).catch((err) => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

        //For Graph and Table Content
        const Storedata = {
            "UserStoreID": parseInt(this.props.userstoreid),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'Dashboard/marketscoring', Storedata)
            .then(res => {
                let MarkScoring = res.data.data.scorings;
                let MarkStoreInfo = res.data.data.storeInformation;
                let Markets = res.data.data.markets;
                let MarketsDesc = res.data.data.marketDescriptions;
                this.setState({
                    MarkScoring, MarkStoreInfo, Markets, MarketsDesc
                });
                this.barhandleChange();
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 403) {
                    const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }
    componentDidMount() {
    }



    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    async barhandleChange() {

        //loader 
        this.setState({ isLoading1: true });

        for (var i = 0; i < this.state.MarkScoring.length; i++) {
            if (this.state.storeIdCount.includes(this.state.MarkScoring[i].storeId)) {
                this.state.MarkScoring[i].isSelected = true;//this.state.MarkScoring[i].isSelected === true ? false : true;

            } else if (this.state.storeIdCount.length > 0) {
                this.state.MarkScoring[i].isSelected = false;
            }
        }
        let MarkScoring = this.state.MarkScoring;

        let token = this.state.accessKey//localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        const data = {
            "UserStoreID": parseInt(this.props.userstoreid),
            "Competitors": this.state.uniquestores,//uniquestore,
            "RegionId": CONFIG.RegionId
        };
        await axios.post(CONFIG.API_URL + 'Dashboard/marketscoringgraph', data)
            .then(res => {
                this.setState({ Markets: [], MarkScoringData: [], ChartTableData: {} });
                let Markets = res.data.data.markets;
                this.setState({ Markets, MarkScoring });
                this.scoringData();
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

    }

    //To Assign the data for particular bar and tables
    scoringData() {
        const { MarkScoring, MarkStoreInfo, Markets, MarketsDesc } = this.state;
        /* Spliting the data in the order of StoreID In Scoring Tabl */
        let MarkScoringData = this.state.MarkScoringData;
        MarkScoringData.push({
            'statdata': MarkScoring.filter(function (data) {
                return data.storeId < 1 || data.isMyMarket === 1;
            }), 'dynamdata': MarkScoring.filter(function (data) {
                return data.storeId > 0 && data.isMyMarket !== 1;
            })
        });
        /* End of Spliting the data in the order of StoreID In Scoring Table */

        //Checking for data availability
        let AvailDataCheck = [];
        AvailDataCheck = Markets.filter(function (data) {
            return data.storeId > 0;
        });


        let ChartTableData = this.state.ChartTableData;
        if (Markets.length > 0 && AvailDataCheck.length > 0) {

            /* Spliting the data in the order of activityType */
            ChartTableData['lahMarkets'] = [];

            //For Total data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Total';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Total';
                })
            });

            //For Website data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Website';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Website';
                })
            });

            //For Website data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Search';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Search';
                })
            });

            //For Paid Search data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Paid Search';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Paid Search';
                })
            });

            //For Social Media data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Social Media';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Social Media';
                })
            });

            //For Contactability data
            ChartTableData['lahMarkets'].push({
                'tr1': Markets.filter(function (data) {
                    return data.storeId < 1 && data.activityType === 'Contactability';
                }), 'tr2': Markets.filter(function (data) {
                    return data.isMyStore === true && data.activityType === 'Contactability';
                })
            });

            /* end of Spliting the data in the order of activityType */

            /* Barchart table's Head data */
            ChartTableData['TableHead'] = Markets.filter(function (data) {
                return data.storeId > 0 && data.activityType === 'Total' || data.storeId < -2 && data.activityType === 'Total';
            });
            /* end of Barchart table's Head data */

            /* Barchart table's body data */
            ChartTableData['TableBody'] = [];

            //For Total data
            ChartTableData['TableBody'].push({
                'td1': 'Total', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Total' || data.storeId < -2 && data.activityType === 'Total';
                })
            });

            //For Website data
            ChartTableData['TableBody'].push({
                'td1': 'Website', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Website' || data.storeId < -2 && data.activityType === 'Website';
                })
            });

            //For Search data
            ChartTableData['TableBody'].push({
                'td1': 'Search', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Search' || data.storeId < -2 && data.activityType === 'Search';
                })
            });

            //For Paid Search data
            ChartTableData['TableBody'].push({
                'td1': 'Paid Search', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Paid Search' || data.storeId < -2 && data.activityType === 'Paid Search';
                })
            });

            //For Social Media data
            ChartTableData['TableBody'].push({
                'td1': 'Social Media', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Social Media' || data.storeId < -2 && data.activityType === 'Social Media';
                })
            });

            //For Contactability data
            ChartTableData['TableBody'].push({
                'td1': 'Contactability', 'td2': Markets.filter(function (data) {
                    return data.storeId > 0 && data.activityType === 'Contactability' || data.storeId < -2 && data.activityType === 'Contactability';
                })
            });

            /* end of Barchart table's body data */
        }
        //console.log(ChartTableData);
        this.setState({ MarkScoringData, MarkStoreInfo, MarketsDesc, Markets, ChartTableData, isLoading1: false });
        this.drawChart();
    }

    //Formatted in as per competitor
    formData(data) {
        let uniquestore = [...new Set(data.map(item => item.storeId))];
        let CurrentActivity = this.state.grapghCatcheck;
        let Colorbarcode = ['#f69e4f', '#1b2c42', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
        var FData = [];
        for (var t = 0; t < uniquestore.length; t++) {
            var tFData = data.filter(a => a.storeId == uniquestore[t] && a.activityType === CurrentActivity);
            var mData = {};
            if (tFData) {
                mData = {
                    StoreID: uniquestore[t],
                    StoreName: tFData[0].storeName,
                    Score: tFData[0].score,
                    act: tFData[0].activityType,
                    Gcolor: Colorbarcode[t],
                    IsSelected: tFData[0].isSelected
                }
            }

            FData.push(mData);
        }
        return FData;
    }

    //Bar Chart
    drawChart() {
        let { Markets, grapghCatcheck } = this.state;
        let actualData = [];
        let colorsData = [];
        let storeName = [];
        let maxScore = 0;
        let colorsCode = ['#f69e4f', '#1b2c42', '#5b95f6', '#f96a5d', '#3ea7a1', '#83ce83', '#fac05f', '#959595'];
        let filterMarkets = Markets.filter(fl => fl.storeId > 0 || fl.storeId < -2);
        let uniquestore = [...new Set(filterMarkets.map(item => item.storeId))];
        for (var t = 0; t < uniquestore.length; t++) {
            var tFData = filterMarkets.filter(a => a.storeId === uniquestore[t] && a.activityType === grapghCatcheck);
            let noValues = tFData.every(item => item.score === null || item.score === '' || item.score === undefined);
            if (!noValues) {
                let dataSeries = tFData[0].score;
                let storageName = tFData[0].storeName;
                // let storageName = tFData[0].storeName.length > 10 ? `${tFData[0].storeName.substring(0, 10)}...` : tFData[0].storeName;
                maxScore = tFData[0].totalScore;
                actualData.push(dataSeries);
                storeName.push(storageName);
                colorsData.push(colorsCode[t]);
            }
        }

        const options = {
            colors: colorsData,
            chart: {
                id: `apexchart-example${Math.random()}`,
                animations: {
                    enabled: false,
                  },  //when the state updates randam id has to change to render the changes
            },
            tooltip: { enabled: false },
            yaxis: {
                min: 0,
                max: maxScore,
                tickAmount: 5,
                labels: {
                    formatter: function (value) {
                        return value === 0 ? ""  : `${value.toFixed(0)}/${maxScore ? maxScore : 0}`;
                    },
                    style: {
                        colors: '#140000',
                        fontSize: '10px'
                    },
                },
                axisBorder:{
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 5,
                    dataLabels: {
                        position: 'top',
                    },
                    columnWidth: '25%',
                    barHeight: '50%',
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '10px',
                    colors: ["#304758"]
                },
                formatter: function (val, opts) {
                    return val === 0 ? '' : val;
                },
            },
            grid: { show: false },
            xaxis: {
                categories: storeName,
                labels: {
                    show: true,
                    rotate: 0,
                    hideOverlappingLabels: false,
                    trim: true,
                    formatter: function (value) {
                        let val = value === undefined || typeof (value) !== "string" ? '' : value.toUpperCase();
                        return val;
                    },
                    style: {
                        colors: '#8C9AA6',
                        fontSize: '10px'
                    },
                }
            },
            legend: {
                show: false,
            }
        }

        const series = [{
            name: '',
            data: actualData
        }]

        if (actualData.length > 0) {
            this.setState({ options, series })
        } else {
            this.setState({
                options: {
                    chart: {
                        id: 'apexchart-example'
                    },
                    noData: {
                        text: "No Data Available.",
                        align: 'center',
                        verticalAlign: 'top',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: "#F60000",
                            fontSize: '20px',
                            fontFamily: "Helvetica"
                        }
                    },
                    yaxis: { show: false },
                    grid: { show: false },
                    xaxis: {
                        labels: {
                            show: false
                        },
                    }
                },
                series: []
            })
        }
    }


    /* Score Card Dialog box functions */
    dialogContentOpen(id, YS_data) {
        this.setState({
            isLoading1: true
        })
        let token = this.state.accessKey;//localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        const data = {
            "StoreId": parseInt(id),
            "RegionId": CONFIG.RegionId
        };
        axios.post(CONFIG.API_URL + 'dashboard/marketscorecard', data)
            .then(res => {
                //let Scorecard = res.data.scoreCard.marketScoreCardDetails;
                let ScorecardDet = res.data.scoreCard.marketScoreCardDetails;
                let MarketSCdet = this.state.MarketSCdet;

                if (ScorecardDet.length > 0) {
                    //to get Market Details
                    MarketSCdet.id = [...new Set(ScorecardDet.map(item => item.storeId))];
                    MarketSCdet.StoreName = YS_data === 'YourStore' ? ['Your Store'] : [...new Set(ScorecardDet.map(item => item.storeName))];
                    MarketSCdet.StoreAddress = [...new Set(ScorecardDet.map(item => item.address))];
                    MarketSCdet.CheckDate = [...new Set(ScorecardDet.map(item => item.dtCollected))];
                    MarketSCdet.M_TotScore = [...new Set(ScorecardDet.map(item => item.totalScore))];


                    //For Market Score Card For Map Details                
                    let uniquemscore = [...new Set(ScorecardDet.map(item => item.marketingActivity))];
                    var Scorecard = [];
                    for (var t = 0; t < uniquemscore.length; t++) {
                        var tempData = ScorecardDet.filter(a => a.marketingActivity == uniquemscore[t]);
                        for (var s = 0; s < tempData.length; s++) {
                            var inlineData = {
                                storeId: tempData[s].storeId,
                                storeName: tempData[s].storeName,
                                address: tempData[s].address,
                                marketingActivity: s === 0 ? tempData[s].marketingActivity : 'empty',
                                name: tempData[s].name,
                                score: tempData[s].score,
                                activityScore: tempData[s].activityScore,
                                activityScoreTotal: tempData[s].activityScoreTotal,
                                totalScore: tempData[s].totalScore,
                                dtCollected: tempData[s].dtCollected,
                                id: tempData[s].id,
                                rowcount: s === 0 ? tempData.length : 0,
                                color: t % 2 ? 'odd-m-score' : 'even-m-score'
                            }
                            Scorecard.push(inlineData);
                        }
                    }
                    this.setState({ dialogOpen: true, Scorecard, MarketSCdet, isLoading1: false });
                }
                else {
                    this.setState({ MarketSCdet, isLoading1: false, snackOpen: true, snackMSg: 'Score Card is not available in this market.' });
                }
            })
            .catch(err => {
                this.setState({ isLoading1: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    dialogClose() {
        this.setState({ dialogOpen: false, MarketSCdet: {}, Scorecard: [] });
    }
    /* End of Score Card Dialog box functions */

    handleChangePageTrack = (event, pageCount) => {
        this.setState({ pageCount });
    };

    handleChangeRowsPerPageTrack = event => {
        this.setState({ pageCount: 0, 10: event.target.value });
    };

    render() {
        const { order, orderBy, grapghCatcheck, graphOpen, MarkScoringData, MarkStoreInfo, ChartTableData, MarketsDesc, snackOpen, snackMSg,
            locationInfoData, isLoading1, trackModalData, pageCount, Markets, MarketSCdet, Scorecard } = this.state;
        let ComActivityType = ['Total', 'Website', 'Search', 'Paid Search', 'Social Media', 'Contactability'];
        let Colors = ['mystorebarclr', 'barclr1', 'barclr2', 'barclr3', 'barclr4', 'barclr5', 'barclr6', 'barclr7'];
        const defaultProps = {
            helpTitle: <Typography>Marketing Scorecard - an explanation</Typography>,
            helpContent: "<p>The StorTrack Marketing Scorecard is a devised scoring mechanism for judging the activity and effectiveness of a Store's local marketing, so as to enable us to create a methodology for comparison and competitive insight.</p><p>The Scorecard is created by monitoring 5 different areas of a Store's marketing activities: it's website, it's organic presence on search engines, it's paid placement in search engines, the social media presence of the Store and finally how easy the Store makes it for potential new tenants to contact it. In each of these five categories, StorTrack has created a weighted scoring process that then rolls up to a category score. The category scores are what you see on the Marketing Scorecard page and the method by which we compare Stores side-by-side.</p><p>The Scoring Process is detailed in the attached PDF. Please click the link to download the full Scoring methodology. <a href=" + CONFIG.STK_URL + "/StorTrack_Optmize_MarketScoringProcess.pdf target='_blank'>Click here</a></p><p>For any further information on StorTrack's Marketing Scorecard please contact Support directly.</p><p><i>*Your store scoring is included in the calculations.</i></p><p>Refer the below help video for more information.</p><iframe width='472' height='248' src='https://www.youtube.com/embed/U41xa7ELS-o' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        }
        const GridTableValue1 = MarkScoringData.length > 0 ? MarkScoringData[0].dynamdata.length : 0;
        const GridTableValueData = MarkScoringData.length > 0 ? MarkScoringData[0].dynamdata : [];

        if (GridTableValue1 > 0) {
            const { order, orderBy } = this.state;
            if (orderBy !== "storeName") {
                var sortByStoreData = GridTableValueData;
                sortByStoreData.sort(function (a, b) {
                    // var textA = a.orderBy;
                    // var textB = b.orderBy;
                    var textA = orderBy === "score" ? a.score : orderBy === "websiteScore" ? a.websiteScore : orderBy === "searchScore" ? a.searchScore : orderBy === "paidScore" ? a.paidScore : orderBy === "socialMediaScore" ? a.socialMediaScore : orderBy === "contactabilityScore" ? a.contactabilityScore : '';
                    var textB = orderBy === "score" ? b.score : orderBy === "websiteScore" ? b.websiteScore : orderBy === "searchScore" ? b.searchScore : orderBy === "paidScore" ? b.paidScore : orderBy === "socialMediaScore" ? b.socialMediaScore : orderBy === "contactabilityScore" ? b.contactabilityScore : '';
                    if (order === 'asc') {
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    } else {
                        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                    }
                });
            } else if (orderBy === "storeName") {
                if (order === 'asc') {
                    var sortByStoreData = GridTableValueData.sort((a, b) => a.storeName.localeCompare(b.storeName));
                }
                else {
                    var sortByStoreData = GridTableValueData.sort((a, b) => b.storeName.localeCompare(a.storeName));
                }
            }
        }
        console.log(ChartTableData)
        const tr = [...Array(Math.ceil(GridTableValue1 / 17))];
        const trproductRows = tr.map((row, idx) => sortByStoreData.slice(idx * 17, idx * 17 + 17));
        const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
        const marketNameCount = MarketsDesc.highestMarketName ? MarketsDesc.highestMarketName.length : 0;
        return (
            <div className="marketscore marketscore-pdf">
                {isLoading1 ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                {/* <div class="header marketscore-header"> <img src={Logo} />                    
                    <div className="float-right only-address-right">
                    <h5 style={{marginBottom: '0px'}}>{locationInfoData ? locationInfoData.storeName : isLoading1 === false ? 'N/A' : ''}</h5>
                        <p className="m-0">{locationInfoData ? locationInfoData.storeAddress1 : 'N/A'}, {locationInfoData.storeAddress2 ? locationInfoData.storeAddress2 + ', ' : ''} {locationInfoData ? locationInfoData.storeCity : 'N/A'}{locationInfoData.storeState ? ', ' + locationInfoData.storeState : ''} {locationInfoData ? locationInfoData.storeZipCode : 'N/A'}</p>
                    </div>
                </div> */}
                <div className="header"> <img src={Logo} />
                    <div className="float-right-name">
                        <h5>{locationInfoData && locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading1 === false ? 'Your Store' : ''}</h5>
                    </div><div className="float-right">
                        <p className="m-0 address-font">{locationInfoData && locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== null && locationInfoData.storeAddress1 !== '' ? locationInfoData.storeAddress1 + ',' : ''} {locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''} {locationInfoData ? locationInfoData.storeState : 'N/A'} {locationInfoData ? locationInfoData.storeZipCode : 'N/A'} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p></div></div>
                {/* <div class="footer">
                <a href="https://optimize.stortrack.com/" style={{textDecoration: 'none'}}>optimize.stortrack.com</a> | 1-800-969-7424 | <a href="mailto:info@stortrack.com" style={{textDecoration: 'none'}}>info@stortrack.com</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
                 </div> */}

                <Paper item="true" xs={12} className="dashboard-layout last-months market-activity">
                    <div>
                        <div className="help-txt-pdf">
                            <h5 className="mb-3" >Marketing Scorecard</h5>
                            <p>The StorTrack Marketing Scorecard is a devised scoring mechanism for judging the activity and effectiveness of a Store's local marketing, so as to enable us to create a methodology for comparison and competitive insight.</p>
                            <p>The Scorecard is created by monitoring 5 different areas of a Store's marketing activities: it's website, it's organic presence on search engines, it's paid placement in search engines, the social media presence of the Store and finally how easy the Store makes it for potential new tenants to contact it. In each of these five categories, StorTrack has created a weighted scoring process that then rolls up to a category score. The category scores are what you see on the Marketing Scorecard page and the method by which we compare Stores side-by-side.</p>
                            {/* <p>The Scoring Process is detailed in the attached PDF. Please click the link to download the full Scoring methodology. <a href={ + CONFIG.STK_URL + "/StorTrack_Optmize_MarketScoringProcess.pdf"} target='_blank'>Click here</a></p>*/}
                            <p>For any further information on StorTrack's Marketing Scorecard please contact Support directly.</p>
                            <p><i>*Your store scoring is included in the calculations.</i></p>
                        </div>
                        <Row>

                            <Col md={12}>

                                <div className="set-border-over">
                                    <Col style={{ borderRight: '1px solid #DEE2E5' }} className="left-align-st">
                                        <div className="market-label">Current Weakest Marketing  Area</div>
                                        {MarkStoreInfo.weakestMarketingArea ? <div className="market-weak"> {MarkStoreInfo.weakestMarketingArea} </div> : ''}
                                        <div className="align-stores-btm"><div className="market-value">{MarkStoreInfo.weakestMarketingAreaScore >= 0 && MarkStoreInfo.weakestMarketingAreaScore !== null ? MarkStoreInfo.weakestMarketingAreaScore + '/' + MarkStoreInfo.weakestMarketingAreaTotalScroe : isLoading1 === false ? 'N/A' : ''} {MarkStoreInfo.strongestMarketingAreaScore ? <span className="btm-label">(Average)</span> : ''}</div></div>
                                    </Col>
                                    <Col className="left-align-st">
                                        <div className="market-label">Current Strongest Marketing Area</div>
                                        {MarkStoreInfo.strongestMarketingArea ? <div className="market-weak"> {MarkStoreInfo.strongestMarketingArea}</div> : ''}
                                        <div className="align-stores-btm"><div className="market-value">{MarkStoreInfo.strongestMarketingAreaScore >= 0 && MarkStoreInfo.strongestMarketingAreaScore !== null ? MarkStoreInfo.strongestMarketingAreaScore + '/' + MarkStoreInfo.strongestMarketingAreaTotalScroe : isLoading1 === false ? 'N/A' : ''} {MarkStoreInfo.strongestMarketingAreaScore ? <span className="btm-label">(Average)</span> : ''}</div></div>
                                    </Col>
                                    <div className="clear"></div>
                                </div>

                            </Col>

                        </Row>
                        <Row>
                            <Col md="12" id="marketanalysis">
                                <div className="ms_charthead pl-3 pt-3">
                                    <h4>Marketing Activity Analysis
                                        {/* <a href="#marketgrid" className="add_edit_link">Go to All Market View to Add/Remove Stores </a> */}
                                    </h4>
                                </div>
                            </Col>
                            {Markets && Markets.length > 0 ?
                                <React.Fragment>
                                    <Col md="12" className="" >
                                        <div className="ms_charts pl-3 pt-3 pr-3 market-score-ms-chart pb-1" >
                                            <ReactTable className="msc activity-table marketings-table" responsive >
                                                <thead>
                                                    {ChartTableData.TableHead && ChartTableData.TableHead.length > 0 ?
                                                        <React.Fragment>
                                                            <tr>
                                                                <th rowSpan="2">Marketing Activity</th>
                                                                {ChartTableData.TableHead.map((th_data, index) => {
                                                                    let sAddress = th_data.address ? ', \n' + th_data.address : '';
                                                                    const text = <div style={{ whiteSpace: 'pre-line' }}>{th_data.storeName + sAddress}</div>
                                                                    let dotline;
                                                                    let storName;
                                                                    if (ChartTableData.TableHead.length > 5) {
                                                                        dotline = th_data.storeName.length > 18 ? '..' : '';
                                                                        storName = th_data.storeName.substring(0, 18) + dotline;
                                                                    }
                                                                    else if (ChartTableData.TableHead.length === 4 || ChartTableData.TableHead.length === 5) {
                                                                        dotline = th_data.storeName.length > 40 ? '..' : '';
                                                                        storName = th_data.storeName.substring(0, 40) + dotline;
                                                                    }
                                                                    else {
                                                                        storName = th_data.storeName;
                                                                    }
                                                                    return (
                                                                        <Tooltip data-html="true" title={text} key={index} placement="top"><th className={Colors[index]} >{storName}</th></Tooltip>
                                                                    );
                                                                })}
                                                            </tr>
                                                            <tr className="scorecardRow">
                                                                {ChartTableData.TableHead.map((th_sc_data, index) => {
                                                                    var mystore_chk = th_sc_data.isMyStore === true ? 'YourStore' : '';
                                                                    return (
                                                                        <th className='tg_scorecard' key={th_sc_data.storeId}>
                                                                            {/* <span className="sc_link_btn" onClick={() => this.dialogContentOpen(th_sc_data.storeId, mystore_chk)}>see scorecard</span> */}
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </React.Fragment>
                                                        :
                                                        <tr>
                                                            <th>Marketing Activity</th>
                                                        </tr>
                                                    }
                                                </thead>
                                                <tbody>
                                                    {ChartTableData.TableBody && ChartTableData.TableBody.length > 0 ? ChartTableData.TableBody.map((row1, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td align="right" className="ms_tablebtn">
                                                                        <span className="markets-label">{row1.td1}</span>
                                                                        {grapghCatcheck === row1.td1 ?
                                                                            <Button color="danger" className="add-to-graph" onClick={() => this.onSelecCatGrapgh(row1.td1)} size="sm"><img src={tableminus} alt="" /></Button>
                                                                            :
                                                                            <Button color="primary" className="remove-to-graph" onClick={() => this.onSelecCatGrapgh(row1.td1)} size="sm"><img src={tableplus} alt="" /></Button>
                                                                        }
                                                                    </td>
                                                                    {row1.td2.map((row) => {
                                                                        return (
                                                                            <React.Fragment key={row.storeId}>
                                                                                <td>{row.score + '/' + row.totalScore}</td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </tr>
                                                                <tr className={graphOpen === row1.td1 ? '' : 'disablegraphrow'} >
                                                                    <td colSpan={row1.td2.length + 1} className="set-gray-background">

                                                                        <Chart options={this.state.options} series={this.state.series} type="bar" width={970} height={200} />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td>N/A</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </ReactTable>
                                        </div>
                                    </Col>
                                    <Col md="12" lg={12} className="">
                                        <div className="ys_cont pl-3 pr-3 pb-2 pt-0">
                                            {/* <h4>{graphOpen ?graphOpen :''}</h4> */}
                                            <ReactTable className="mss market-right-table only-market-right" responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="ma">Marketing Activity</th>
                                                        <th className="ml">Market Low</th>
                                                        <th className="ma">Market Average</th>
                                                        <th className="mh">Market High</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ChartTableData.lahMarkets && ChartTableData.lahMarkets.length > 0 ? ChartTableData.lahMarkets.map((tr1data, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr className={ComActivityType[index] === 'PriceRange' ? 'disablegraphrow' : ''} >
                                                                    {tr1data.tr1.map((td, index) => {
                                                                        if (td.storeId !== -3) {
                                                                            return (
                                                                                <React.Fragment key={index}>

                                                                                    {

                                                                                        td.storeName === "Market Low" ?
                                                                                            <React.Fragment>
                                                                                                <td className="ma">{td.activityType}</td>
                                                                                                <td className="ml">{td.score + '/' + td.totalScore}</td>
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            td.storeName === "Market Average" ?
                                                                                                <td className="ma">{td.score + '/' + td.totalScore}</td>
                                                                                                :
                                                                                                td.storeName === "Market High" ?
                                                                                                    <td className="mh">{td.score + '/' + td.totalScore}</td>
                                                                                                    :
                                                                                                    <td>N/A</td>
                                                                                    }
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    })}
                                                                </tr>

                                                                {tr1data.tr2.length > 0 ? tr1data.tr2.map((rowdata, index) => {
                                                                    let percentage = Number(((rowdata.score / rowdata.totalScore) * 100).toFixed(2));
                                                                    return (
                                                                        <tr key={index} className={graphOpen === rowdata.activityType ? '' : 'disablegraphrow'} >
                                                                            <td colSpan="4">
                                                                                <div className="pb-4 pt-4 pl-0 pr-0 pt-custom-2" align="center">
                                                                                    <Typography variant="caption" className="your-score">Your Score</Typography>
                                                                                    <Typography variant="h6" className="score-value">{rowdata.score >= 0 ? rowdata.score + '/' + rowdata.totalScore : 'N/A'}</Typography>
                                                                                    {rowdata.score >= 0 ? <Typography variant="body1" className="your-score">{percentage}%</Typography> : ''}
                                                                                    {rowdata.myStoreRank ? <h5>{rowdata.myStoreRank}</h5> : ''}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                    :
                                                                    <tr key={index} className={graphOpen === ComActivityType[index] ? '' : 'disablegraphrow'} >
                                                                        <td colSpan="4">
                                                                            <div className="pb-4 pt-4 pl-0 pr-0 pt-custom-2" align="center">
                                                                                <Typography variant="caption" className="your-score">Your Score</Typography>
                                                                                <Typography variant="h6" className="score-value">N/A</Typography>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </React.Fragment>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan="4" align="center">N/A</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </ReactTable>
                                        </div>
                                    </Col>
                                </React.Fragment>
                                :
                                <Col>
                                    <Typography variant="body1" className="p-4 no-data">{isLoading1 === false ? 'There are no competitors available in this market.' : ''}</Typography>
                                </Col>

                            }
                        </Row>
                        {/* Below the table adding for display the static data of national and state values when there are no competitors available in this market */}
                        {trproductRows.length === 0 && isLoading1 === false ?
                            <Row>
                                <Col>
                                    <div style={{ padding: '15px' }}>
                                        <div className="ms_table pt-3 pb-3 pl-0 pr-0" id="marketgrid">
                                            <h4 className="markviewhead">All Market View</h4>
                                            <div className="overflow-scroll-table br-0">
                                                <Paper className="bx-sh-0 dynam-length-zero">
                                                    <Table className="mst all-market-table market-score-table table-layout-auto" responsive="true">
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={this.handleRequestSort}
                                                            rowCount={MarkScoringData.length}
                                                        />

                                                        {MarkScoringData ?
                                                            MarkScoringData.map((msd, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <TableBody>
                                                                            {msd.statdata.map((row1, index) => {
                                                                                const labelId = index;
                                                                                return (
                                                                                    <TableRow key={row1.storeId} className="disabled_color">
                                                                                        <TableCell id={labelId} scope="row" padding="none">
                                                                                            {row1.storeName}
                                                                                        </TableCell>
                                                                                        <TableCell>{row1.score !== null ? row1.score + '/' + row1.totalScore + '(' + row1.totalPercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row1.websiteScore !== null ? row1.websiteScore + '/' + row1.websiteScoreTotal + '(' + row1.websiteScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row1.searchScore !== null ? row1.searchScore + '/' + row1.searchScoreTotal + '(' + row1.searchScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row1.paidScore !== null ? row1.paidScore + '/' + row1.paidScoreTotal + '(' + row1.paidScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row1.socialMediaScore !== null ? row1.socialMediaScore + '/' + row1.socialMediaScoreTotal + '(' + row1.socialMediaScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row1.contactabilityScore !== null ? row1.contactabilityScore + '/' + row1.contactabilityScoreTotal + '(' + row1.contactabilityScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        {/* <TableCell className="ms_card normal-weight">{row1.isMyMarket === 1 ? <span className="sc_link_btn" onClick={() => this.dialogContentOpen(row1.storeId, 'YourStore')}>See Full Scorecard</span> : ''}</TableCell> */}
                                                                                        <TableCell className="ms_action"></TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            })}
                                                                        </TableBody>
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                            :
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className="no-data" colSpan="8">{isLoading1 === false ? 'There are no competitors available in this market.' : ''}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        }
                                                    </Table>
                                                </Paper>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row> : ''}
                        {/* Above the table adding for display the static data of national and state values when there are no competitors available in this market */}
                        {Markets && Markets.length > 0 ?
                            <Row>
                                <Col md={12} className="only-for-desktop">
                                    <Row className="p-3 custom-row" style={{ margin: '0px auto' }}>
                                        <Col md={4} className="passive-col">
                                            <Paper className="p-2 max-height">
                                                <Typography align="center" variant="subtitle2" className="top-head">This market is considered</Typography>
                                                <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.marketScoreValue ? MarketsDesc.marketScoreValue : ''}</Typography>
                                                <Typography align="center" variant="body2" className="passive-body">{MarketsDesc ? MarketsDesc.marketScore : ''}</Typography>
                                            </Paper>
                                        </Col>
                                        <Col md={4} className="passive-col">
                                            <Paper className="p-2 max-height">
                                                <Typography align="center" variant="subtitle2" className="top-head">The most competitive Store is</Typography>
                                                {/* <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.highestMarketName ? MarketsDesc.highestMarketName : ''}</Typography> */}
                                                <Typography align="center" variant="h6" className="passive-head">{MarketsDesc ? marketNameCount > 34 ? MarketsDesc.highestMarketName.substring(0, 34) + '...' : MarketsDesc.highestMarketName : isLoading1 === false ? 'N/A' : ''}</Typography>
                                                <Typography align="center" variant="body2" className="passive-body">{MarketsDesc ? MarketsDesc.highestMarket : ''}</Typography>
                                            </Paper>
                                        </Col>
                                        <Col md={4} className="passive-col">
                                            <Paper className="p-2 max-height">
                                                <Typography align="center" variant="subtitle2" className="top-head">The lowest Marketing Activity area is</Typography>
                                                <Typography align="center" variant="h6" className="passive-head">{MarketsDesc.lowestMarketActivity ? MarketsDesc.lowestMarketActivity : ''}</Typography>
                                                <Typography align="center" variant="body2" className="passive-body">{MarketsDesc.lowestMarket ? MarketsDesc.lowestMarket : 'There are no competitors available in this market.'}</Typography>
                                            </Paper>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            :
                            ''}
                    </div>
                    <div className="clear"></div>
                    <div>
                        <Col>
                            {
                                trproductRows ? trproductRows.map((rows, i) => (
                                    <div class="page first-child-page">
                                        <div className="ms_table pt-4 pb-3 pl-0 pr-0" id="marketgrid">
                                            {i === 0 ? <h4 className="markviewhead"  >All Market View</h4> : ''}
                                            <div className="overflow-scroll-table br-0">
                                                <Paper className="bx-sh-0">
                                                    <Table className="mst all-market-table market-score-table table-layout-auto" responsive="true">
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={this.handleRequestSort}
                                                            rowCount={MarkScoringData.length}
                                                        />

                                                        {MarkScoringData ?
                                                            MarkScoringData.map((msd, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        {i === 0 ?
                                                                            <TableBody>
                                                                                {msd.statdata.map((row1, index) => {
                                                                                    const labelId = index;
                                                                                    return (
                                                                                        <TableRow key={row1.storeId} className="disabled_color">
                                                                                            <TableCell id={labelId} scope="row" padding="none">
                                                                                                {row1.storeName}
                                                                                            </TableCell>
                                                                                            <TableCell>{row1.score !== null ? row1.score + '/' + row1.totalScore + '(' + row1.totalPercent + '%)' : 'N/A'}</TableCell>
                                                                                            <TableCell>{row1.websiteScore !== null ? row1.websiteScore + '/' + row1.websiteScoreTotal + '(' + row1.websiteScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                            <TableCell>{row1.searchScore !== null ? row1.searchScore + '/' + row1.searchScoreTotal + '(' + row1.searchScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                            <TableCell>{row1.paidScore !== null ? row1.paidScore + '/' + row1.paidScoreTotal + '(' + row1.paidScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                            <TableCell>{row1.socialMediaScore !== null ? row1.socialMediaScore + '/' + row1.socialMediaScoreTotal + '(' + row1.socialMediaScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                            <TableCell>{row1.contactabilityScore !== null ? row1.contactabilityScore + '/' + row1.contactabilityScoreTotal + '(' + row1.contactabilityScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                            {/* <TableCell className="ms_card normal-weight">{row1.isMyMarket === 1 ? <span className="sc_link_btn" onClick={() => this.dialogContentOpen(row1.storeId, 'YourStore')}>See Full Scorecard</span> : ''}</TableCell> */}
                                                                                            <TableCell className="ms_action"></TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                            : ''}
                                                                        <TableBody>
                                                                            {this.stableSort(rows, this.getSorting(order, orderBy)).map((row, index) => {
                                                                                const labelId = index;
                                                                                return (
                                                                                    <TableRow key={row.storeId} className={row.storeId > 0 ? '' : "disabled_color"}>
                                                                                        <TableCell id={labelId} scope="row" padding="none"  >
                                                                                            <span className="store-names">{row.storeName}</span>
                                                                                            <span className="stores-address">{row.address}</span>
                                                                                        </TableCell>
                                                                                        <TableCell >{row.score !== null ? row.score + '/' + row.totalScore + '(' + row.totalPercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row.websiteScore !== null ? row.websiteScore + '/' + row.websiteScoreTotal + '(' + row.websiteScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row.searchScore !== null ? row.searchScore + '/' + row.searchScoreTotal + '(' + row.searchScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row.paidScore !== null ? row.paidScore + '/' + row.paidScoreTotal + '(' + row.paidScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row.socialMediaScore !== null ? row.socialMediaScore + '/' + row.socialMediaScoreTotal + '(' + row.socialMediaScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        <TableCell>{row.contactabilityScore !== null ? row.contactabilityScore + '/' + row.contactabilityScoreTotal + '(' + row.contactabilityScorePercent + '%)' : 'N/A'}</TableCell>
                                                                                        {/* <TableCell className="ms_card"><span className="sc_link_btn" onClick={() => this.dialogContentOpen(row.storeId, '')}>See Full Scorecard</span></TableCell> */}
                                                                                        <TableCell className="ms_action">
                                                                                            {row.storeId > 0 ?
                                                                                                <div>
                                                                                                    {row.score !== null ? row.isSelected === true ?
                                                                                                        <Button color="danger" padding="none" className="add-to-graph" onClick={this.barhandleChange.bind()}><Tooltip title="Remove from graph" placement="top"><img src={removefrommap} alt="collapse" /></Tooltip></Button>
                                                                                                        :
                                                                                                        <Button color="info" className="remove-to-graph" onClick={this.barhandleChange.bind()}><Tooltip title="Show on graph" placement="top"><img src={addtomap} alt="expand" /></Tooltip></Button>
                                                                                                        : ''}
                                                                                                </div>
                                                                                                : ''}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                        </TableBody>
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                            :
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className="no-data" colSpan="8">{isLoading1 === false ? 'There are no competitors available in this market.' : ''}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        }
                                                    </Table>
                                                </Paper>
                                            </div>
                                        </div>
                                        <div class="printclearfix"></div>
                                    </div>
                                ))
                                    : ''}
                        </Col>

                    </div>
                    <div className="footer marketscore-footer">
                        <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackOpen}
                        className="snackbar bg-color-primary custom-snackbar"
                        maxsnack={3}
                        autoHideDuration={30000}
                        onClose={this.snackHandleclose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.snackHandleclose.bind(this)}
                            >
                                <CloseIcon />
                            </IconButton>
                        ]}
                        message={<span id="message-id">{snackMSg}</span>}

                    />
                </Paper>
                <Dialog className='sc_dialog scorecard-popup' open={this.state.dialogOpen} scroll={'body'} maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="scroll-dialog-title">
                        Marketing Activity Scorecard
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className="ms_closeModal"
                            onClick={this.dialogClose.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Row className="market-top">
                            <Col md="8">
                                <h3 className="market-profile-heading">
                                    <Row>
                                        <div className="col-lg-4 label-address">Store Details:</div>
                                        {isLoading1 === false ?
                                            <div className="col-lg-8 pop-store-add">
                                                <span><b>{MarketSCdet.StoreName ? MarketSCdet.StoreName.length > 0 ? MarketSCdet.StoreName[0] + ',' : 'N/A' : 'N/A'}</b></span><br /><span class="s_address">{MarketSCdet.StoreAddress ? MarketSCdet.StoreAddress.length > 0 ? MarketSCdet.StoreAddress[0] : '' : ''}</span>
                                            </div> : ''}
                                    </Row>
                                </h3>
                            </Col>
                            <Col md="4">
                                <h4 className="text-right market-profile-heading">Last Checked:   {isLoading1 === false ? <span className="heading-span">{MarketSCdet.CheckDate ? MarketSCdet.CheckDate.length > 0 ? moment(MarketSCdet.CheckDate[0]).format('DD-MMM-YYYY') : 'N/A' : 'N/A'}</span> : ''}</h4>
                                <h4 className="text-right market-profile-heading total-score">Total Score:   {isLoading1 === false ? <span className="heading-span">{MarketSCdet.M_TotScore ? MarketSCdet.M_TotScore.length > 0 ? MarketSCdet.M_TotScore + '/100' : 'N/A' : 'N/A'}</span> : ''}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="market-height">
                                    <Table className="mst all-market-table market-score-table marketings-table" responsive="true">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>MARKETING ACTIVITY</TableCell>
                                                <TableCell>CATEGORY</TableCell>
                                                <TableCell>SCORE</TableCell>
                                                <TableCell>TOTAL</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Scorecard.length > 0 ? Scorecard.map((data, index) => {
                                                return (
                                                    <TableRow className={data.color}>
                                                        {data.marketingActivity !== 'empty' ?
                                                            <TableCell rowSpan={data.rowcount > 0 ? data.rowcount : 0} className={data.rowcount > 0 ? 'border-right-cell font-16-td' : 'font-16-td'}>{data.marketingActivity}</TableCell>
                                                            : ''}
                                                        <TableCell className="border-right-cell">{data.name}</TableCell>
                                                        <TableCell className="pnk-cen">{data.score}</TableCell>
                                                        {data.marketingActivity !== 'empty' ?
                                                            <TableCell rowSpan={data.rowcount > 0 ? data.rowcount : 0} className={data.rowcount > 0 ? 'border-left-cell font-20-td' : 'font-20-td'}>{data.activityScore + '/' + data.activityScoreTotal}</TableCell>
                                                            : ''}
                                                    </TableRow>
                                                );
                                            })
                                                :
                                                <TableRow>
                                                    <TableCell colSpan="4" className="no-data"> {isLoading1 === false ? 'No data Available' : ''}</TableCell>
                                                </TableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


//Listing and Sorting Table's Head
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headRows = [
        { id: 'storeName', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'score', numeric: true, disablePadding: false, label: 'Total Score' },
        { id: 'websiteScore', numeric: true, disablePadding: false, label: 'Website' },
        { id: 'searchScore', numeric: true, disablePadding: false, label: 'Search' },
        { id: 'paidScore', numeric: true, disablePadding: false, label: 'Paid Search' },
        { id: 'socialMediaScore', numeric: true, disablePadding: false, label: 'Social Media' },
        { id: 'contactabilityScore', numeric: true, disablePadding: false, label: 'Contact' },
        // { id: 'scorecard', numeric: true, disablePadding: false, label: 'Scorecard' },
        { id: 'moreoptions', numeric: true, disablePadding: false, label: 'More Options' }
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align='center'
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                            disabled={row.id !== 'moreoptions' && row.id !== 'scorecard' ? false : true}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
} 