import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../src/Assets/Css/icons.css';
import '../src/Assets/Css/shortcodes.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

/* index.js */

// import './polyfills'

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
