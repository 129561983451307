import React from 'react';
import ReactDOM from "react-dom";
import { Row, Col } from 'reactstrap';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Avatar from '@material-ui/core/Avatar';
import './CompOverviewPdf.css';
import axios from 'axios';
import moment from 'moment';
import { CONFIG } from './../../../utils/config';
import { MissingCompetitor } from '../../MissingCompetitor';
import { MarketProfile } from '../../MarketProfile';
import noImage from './../../../Assets/Images/no-image.jpg';
import { StoreStreetView } from '../../StoreStreetView';
import homeIcon from './../../../Assets/Images/home-icon-circle.png';
import Logo from './../../../Assets/Images/logo.jpg';
import MapGL, { Layer, Source, Marker, WebMercatorViewport, FlyToInterpolator } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const params = { v: '3.exp', key: CONFIG.GoogleMapsApiKey };

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": ["get", "colorCode"],
  },
};

export class InfoWindowEx extends React.Component {
  constructor(props) {
    super(props);
    this.onInfoWindowOpen = this.onInfoWindowOpen.bind(this);
    if (!this.containerElement) {
      this.containerElement = document.createElement(`div`);
    }
  }

  onInfoWindowOpen() {
    ReactDOM.render(
      React.Children.only(this.props.children),
      this.containerElement
    );
    this.infoWindowRef.current.infowindow.setContent(this.containerElement);
  }
}

export default class CompOverviewPdf extends React.Component {

  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.mapRef = React.createRef();
    this.child = React.createRef();
    this.compstorechild = React.createRef();
    this.storeImageStreetView = React.createRef();
    this.state = {
      compOverviewData: [],
      storeData: [],
      locationInfoData: [],
      isLoading: false,
      dropDownValue: "Sort by",
      sordOrder: '',
      accessKey: '',
      viewport: {
        // width: "100%",
        // height: "100vh",
        longitude: 0,
        latitude: 0,
        zoom: 10,
      },
      homeLatLng: { longitude: 0, latitude: 0 }
    };
    this.getParams = this.getParams.bind(this);
    this.getParams1 = this.getParams1.bind(this);
    this.addDefaultSrcMap = this.addDefaultSrcMap.bind(this);
  }

  getParams(name, sid) {
    this.child.current.toggleModal(name, sid);
  }

  getStoreStreeView(lat, lon, name) {
    this.storeImageStreetView.current.toggleStoreModal(lat, lon, name);
  }

  NumberFormat(number) {
    return new Intl.NumberFormat().format(number);
  }

  loadDashBoard() {
    let token = this.state.accessKey;
    const data = {
      "UserStoreID": parseInt(this.props.storeid),
      "RegionId": CONFIG.RegionId
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/dashboardlocation', data)
      .then(response => {
        // let zoomValue;
        const locationInfoData = response.data.data.storeDashboard;
        // const centerPoint = response.data.data.centerPointDetail;
        // if (locationInfoData.searchType === 3) {
        //   zoomValue = 10;
        // } else if (locationInfoData.searchType === 2) {
        //   let zCnt = locationInfoData.storeZoneCoverage != "" && locationInfoData.storeZoneCoverage != null ? (locationInfoData.storeZoneCoverage.split(",").length - 1) : 1;
        //   zoomValue = (zCnt > 1) ? 9 : (zCnt > 0) ? 10 : 12;
        // } else if (locationInfoData.searchType === 1) {
        //   let storeZoneCoverage = locationInfoData.storeZoneCoverage.split(' ');
        //   zoomValue = (storeZoneCoverage[0] === '1' || storeZoneCoverage[0] === '2' || storeZoneCoverage[0] === '3') ? 13 : (storeZoneCoverage[0] === '4' || storeZoneCoverage[0] === '5' || storeZoneCoverage[0] === '6') ? 12 : (storeZoneCoverage[0] === '6' || storeZoneCoverage[0] === '7') ? 11 : (storeZoneCoverage[0] === '8' || storeZoneCoverage[0] === '9' || storeZoneCoverage[0] === '10') ? 10 : 6;
        // }
        // let lgt = (centerPoint && (centerPoint.length > 0 && centerPoint[0].longitude != null) ? parseFloat(centerPoint[0].longitude) : parseFloat(locationInfoData.longitude));
        // let ltd = (centerPoint && (centerPoint.length > 0 && centerPoint[0].latitude != null) ? parseFloat(centerPoint[0].latitude) : parseFloat(locationInfoData.latitude));
        let lgt = parseFloat(locationInfoData.longitude);
        let ltd = parseFloat(locationInfoData.latitude);
        this.setState(prevState => ({
          locationInfoData: locationInfoData,
          viewport: {
            ...prevState.viewport,
            longitude: lgt,
            latitude: ltd,
            // zoom: zoomValue
          },
          homeLatLng: { longitude: lgt, latitude: ltd }
        }), () => this.compOverview());
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  getBoundingValues = (points) => {
    if (points.length) {
      const paddingLength = window.innerHeight / 3;
      const applyToArray = (func, array) => func.apply(Math, array);
      const pointsLong = points.map((point) => point.geometry.coordinates[0]);
      const pointsLat = points.map((point) => point.geometry.coordinates[1]);
      if (!pointsLong.includes(this.state.homeLatLng.longitude)) pointsLong.push(this.state.homeLatLng.longitude);
      if (!pointsLat.includes(this.state.homeLatLng.latitude)) pointsLat.push(this.state.homeLatLng.latitude);
      const cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ];
      const viewport = new WebMercatorViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      }).fitBounds(cornersLongLat, {
        padding: { top: paddingLength, bottom: paddingLength, left: paddingLength, right: paddingLength },
      });
      const { longitude, latitude, zoom } = viewport;
      this.setState(prevState => {
        return {
          viewport: {
            ...prevState.viewport,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionDuration: 100,
            transitionInterpolator: new FlyToInterpolator(),
          }
        }
      });
    }
  };

  compOverview() {
    const data = {
      "UserStoreID": parseInt(this.props.storeid),
      "RegionId": CONFIG.RegionId
    }
    let token = this.state.accessKey;
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.post(CONFIG.API_URL + 'dashboard/compsoverviewdata/map', data)
      .then(res => {
        const compOverviewData = res.data.data;
        const storeData = compOverviewData.storeData;
        this.setState({ compOverviewData: compOverviewData, storeData: storeData, isLoading: false }, () => this.getBoundingValues(storeData));
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 401) {
          localStorage.removeItem('accessKey');
          const { from } = this.props.location.state || { from: { pathname: "/" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 403) {
          const { from } = this.props.location.state || { from: { pathname: "/UnauthorizedAccess" } };
          this.props.history.push(from);
        }
        else if (err.response.status === 400) {
          alert(err.response.data.Message);
        }
        else {
          alert(err.response.data);
        }
      });
  }

  addDefaultSrc(ev) {
    if (ev.target.src != null) {
      var imageName = require('./../../../Assets/Images/no-image.jpg');
      ev.target.src = imageName;
      ev.target.className = "noimages Unit-Store-Img";
    }
  }

  addDefaultSrcMap(ev) {
    if (ev.target.src != null) {
      var imageName = require('./../../../Assets/Images/no-image.jpg');
      ev.target.src = imageName;
      this.setState({ imagePath: noImage });
    }
  }
  async UNSAFE_componentWillMount() {
    const data = {
      "SecretKey": this.props.accessKey,
      "RegionId": CONFIG.RegionId
    }

    const response = await axios.post(CONFIG.API_URL + 'account/secretlogin', data);
    if (response.status === 200) {
      this.state.accessKey = response.data.token;
      this.initialLoad();
    } else {
      alert(response.status.Message);
    }
  }
  initialLoad() {
    let sordOrder = this.props.sortId.toString();
    let sortingChange = sordOrder === "1" ? "Proximity" : sordOrder === "2" ? "Store Name" : sordOrder === "3" ? "Total Square Footage" : sordOrder === "4" ? "Year Built" : "Sort by";
    this.setState({ isLoading: true, dropDownValue: sortingChange });
    this.loadDashBoard();
  }

  getParams1(storeAddress) {
    let storeId = parseInt(this.props.match.params.userstoreid);
    this.compstorechild.current.toggleStoreModal(storeAddress, storeId);
  }

  handleViewportChange = (viewport) => {
    this.setState({ viewport });
  }

  render() {
    document.title = 'StorTrack: Self-storage market pricing data';
    const { locationInfoData, isLoading, strImageMap } = this.state;
    const { compOverviewData, storeData, viewport } = this.state;
    const len = storeData.length === 4 ? 2 : 9;
    const tr = [...Array(Math.ceil(storeData.length / len))];
    const trproductRows = tr.map((row, idx) => storeData.slice(idx * len, idx * len + len));
    const nameCount = locationInfoData.storeName ? locationInfoData.storeName.length : 0;
    return (
      <div className="wrapper my-account-box competitor-overview">
        {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ''}
        <div className="header compoverview-header">
          <img src={Logo} />
          <div className="float-right only-address-right">
            <h5 style={{ marginBottom: '0px' }}>{locationInfoData.storeName && locationInfoData.storeName !== null && locationInfoData.storeName !== '' ? nameCount > CONFIG.storeNameMaxLimit ? locationInfoData.storeName.substring(0, CONFIG.storeNameMaxLimit - 3) + '...' : locationInfoData.storeName : isLoading === false ? 'Your Store' : ''}</h5>
            <p className="m-0 address-font">{locationInfoData && locationInfoData.storeAddress1 && locationInfoData.storeAddress1 !== '' && locationInfoData.storeAddress1 !== null ? locationInfoData.storeAddress1 + ',' : ''} {locationInfoData.storeAddress2 ? locationInfoData.storeAddress2 + ', ' : ''} {locationInfoData && locationInfoData.storeCity ? locationInfoData.storeCity + ', ' : locationInfoData.searchType === 1 && (locationInfoData.storeCity === '' || locationInfoData.storeCity === null) && (locationInfoData.storeZipCode === '' || locationInfoData.storeZipCode === null) ? locationInfoData.latitude + ', ' + locationInfoData.longitude + ', ' : ''}{locationInfoData.storeState ? locationInfoData.storeState : ''} {locationInfoData && locationInfoData.storeZipCode !== null && locationInfoData.storeZipCode !== '' ? locationInfoData.storeZipCode : ''} {locationInfoData.storeZoneCoverage ? "| Coverage: " + locationInfoData.storeZoneCoverage : 'N/A'}</p>
          </div>
        </div>
        <div className="footer compoverview-footer">
          <a href={CONFIG.Website_URL_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:' + CONFIG.Mail_Footer_Pdf} style={{ textDecoration: 'none' }}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
        </div>
        <div className="clearfix"> </div>
        <div className="comp-pdf-body">
          <div className="help-text-pdf text-left">
            <h3 className="mb-3">Competitor Overview</h3>
            <p>Learn more about the stores in this Market. What are they advertising? Are they pricing aggressively? How big is their facility? etc.</p>
            <p><b>{(this.state.locationInfoData.countryCode === "DE" || this.state.locationInfoData.countryCode === "AT" || this.state.locationInfoData.countryCode === "CH") ? "Total Square Metre" : CONFIG.MeasurementLabel}</b> values displayed here are obtained from public sources, including city and county property records. When this information is unavailable, StorTrack uses mapping tools or internal models to estimate the size of the property - such properties have an asterisk (<span style={{ color: 'red' }}>*</span>) next to their {(this.state.locationInfoData.countryCode === "DE" || this.state.locationInfoData.countryCode === "AT" || this.state.locationInfoData.countryCode === "CH") ? "Total Square Metre" : CONFIG.MeasurementLabel} value.</p>
            <h6>Store Types</h6>
            <p>At the top of the page you will find a breakdown of the types of stores in this Market</p>
            <h6>Real Estate Investment Trust (REIT) Stores</h6><p>Any stores operated by the following companies:</p>
            <ul>
              <li>Public Storage</li>
              <li>Extra Space</li>
              <li>LifeStorage</li>
              <li>Cubesmart</li>
              <li>National Storage Affiliates</li>
              <li>Global Self Storage</li></ul>
            <h6>Multi Store Operators (Multi-Ops)</h6>
            <p>Companies operating more than 2 stores</p>
            <h6>Single Store Operators (Single-Ops)</h6>
            <p>Companies (typically independent operators) managing a single store</p>
            <h6>Competitor Overview</h6>
            <ul className="no-list-style">
              <li>This grid below shows each store in this Market. Each store 'snapshot' shows the store name, address and proximity to your store. Further, it includes the building's square footage (derived from public sources or in some instances from calculations made by StorTrack's data science team).</li>
              <li>You will also find ownership and management information and store age.</li>
            </ul>
            <h6>How aggressive is this store/competitor?</h6>
            <ul className="no-list-style"><li>Pricing volatility is a major indicator of how aggressive or passive a store or Market is with their pricing. A store which frequently raises or lowers their rates, will typically have a higher pricing volatility. Here you can view the pricing volatility for a store over the last 30 days.</li>
              <li>Pricing Volatility is calculated by taking the number of times a pricing change occurred over the last 30 days for that store.</li></ul><h6>Unit Availability</h6><ul className="no-list-style"><li>For each store we show 11 of the most common unit types. This is meant to give you a quick snapshot of the unit types each store in this Market is advertising.</li>
              <li>The green checkmark means this unit type is currently advertised by this store </li><li>The red x means this store has not advertised this unit type in the last 12 months</li><li>The grey icon means this store has advertised this unit type in the last 12 months, but it is not currently advertising it</li>
            </ul>
            {/* <h6>Street View</h6>
            <p>Hover over the store's image to view a Google Map snapshot of this facility</p>
            <h6>Map View of Stores</h6>
            <p>Click on the map pin point icon on the upper right corner to view a map of all the stores in your Market</p> */}
          </div>
          <div className="clear"></div>
          <Col md="12" className="p-0 mt-0">
            <Row className="height-full row-wid-flex">
              <Col className="common-col height-full">
                <div className="middle-div">
                  <div className="other-labels">No. of Stores</div>
                  <div className="other-values">
                    {compOverviewData.overViewInfo ? compOverviewData.overViewInfo.compsCount : isLoading === false ? 'N/A' : ''}
                  </div>
                </div>
              </Col>
              {locationInfoData.countryCode == "US" ?
                <Col className="common-col height-full">
                  <div className="middle-div">
                    <div className="other-labels">No. of REIT's</div>
                    <div className="other-values">
                      {compOverviewData.overViewInfo ? compOverviewData.overViewInfo.reits : isLoading === false ? 'N/A' : ''}
                    </div>
                  </div>
                </Col>
                : ''}
              <Col className="common-col height-full">
                <div className="middle-div">
                  <div className="other-labels">No. of Multi-Ops</div>
                  <div className="other-values">
                    {compOverviewData.overViewInfo ? compOverviewData.overViewInfo.multiOps : isLoading === false ? 'N/A' : ''}
                  </div>
                </div>
              </Col>
              <Col className="common-col height-full remove-right">
                <div className="middle-div">
                  <div className="other-labels">No. of Single-Ops</div>
                  <div className="other-values">
                    {compOverviewData.overViewInfo ? compOverviewData.overViewInfo.singleOps : isLoading === false ? 'N/A' : ''}
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row className="top-banner-list">
                <Col xs="6" sm="6" md={locationInfoData.countryCode == "US" ? "3" : "4"} className="align-middle-div comp-overview-left">
                  <h4>No. of Stores</h4>
                  <h5>{compOverviewData.overViewInfo ? compOverviewData.overViewInfo.compsCount : isLoading === false ? 'N/A' : ''}</h5>
                </Col>
                {locationInfoData.countryCode == "US" ?
                  <Col xs="6" sm="6" md="3" className="align-middle-div comp-overview-left">
                    <h4>No. of REIT's</h4>
                    <h5>{compOverviewData.overViewInfo ? compOverviewData.overViewInfo.reits : isLoading === false ? 'N/A' : ''}</h5>
                  </Col>
                  : ''}
                <Col xs="6" sm="6" md={locationInfoData.countryCode == "US" ? "3" : "4"} className="align-middle-div comp-overview-left">
                  <h4>No. of Multi-Ops</h4>
                  <h5>{compOverviewData.overViewInfo ? compOverviewData.overViewInfo.multiOps : isLoading === false ? 'N/A' : ''}</h5>
                </Col>
                <Col xs="6" sm="6" md={locationInfoData.countryCode == "US" ? "3" : "4"} className="align-middle-div comp-overview-left border-right-none">
                  <h4>No. of Single-Ops</h4>
                  <h5>{compOverviewData.overViewInfo ? compOverviewData.overViewInfo.singleOps : isLoading === false ? 'N/A' : ''}</h5>
                </Col>
                
              </Row> */}
          </Col>
        </div>

        <div style={{ display: 'none' }}><img className="Unit-Store-Img" src={strImageMap} alt="No Image" onError={this.addDefaultSrcMap} /></div>
        <div className="margin-left-right-space">
          {trproductRows ? trproductRows.map((rows, i) => (
            <div>
              <div className="page">

                <div className="comp-pdf-body"
                  id={i === 0 ? 'comp-pdf-body-first' : ''}
                >
                  <div className="break-after--">

                    <Col md="12" className="p-v-15 border-box shadow-sm rounded padding-bottom-0 mar-sepa new-col">
                      <Row>
                        <Col md="7" style={{ display: 'none' }}>
                          <div className="missed-competitor comp-over">
                            <MissingCompetitor className="list-item-text add-mar-comp" isShowModal={this.state.showModal} />
                          </div>
                        </Col>
                        <Col md="5" style={{ display: 'none' }} className="float-right text-right competitor-nav">

                        </Col>
                        <Col>
                          <p className="sqft-disclaimer mb-1">* The {(this.state.locationInfoData.countryCode === "DE" || this.state.locationInfoData.countryCode === "AT" || this.state.locationInfoData.countryCode === "CH") ? "Total Square Metre" : CONFIG.MeasurementLabel} values are obtained from publicly available sources. In instances where this information is unavailable, our team has used different methods to calculate the size of the property.</p>
                        </Col>
                      </Row>
                      <Row className="only-competitor">
                        <Table className="m-0 legend-box">
                          <tbody>
                            <tr>
                              <td><FontAwesomeIcon icon={faCheck} className="green-clr" /> Pricing available for this unit type</td>
                              <td><FontAwesomeIcon icon={faTimes} className="red-clr" /> Pricing not available for this unit type</td>
                              <td><FontAwesomeIcon icon={faEyeSlash} /> Pricing previously available, but not currently advertised</td>
                              <td><FontAwesomeIcon icon={faStar} className="red-clr" /> Your Store</td>
                            </tr>
                          </tbody>
                        </Table>
                        <Col md="12" className="table-responsive comp-height">
                          <Table className="comp-view-grid">
                            <tbody>
                              <StoreStreetView ref={this.storeImageStreetView} />
                              {/* {compOverviewData && storeData.length != 0 ? storeData.map((strdata) => */}
                              {compOverviewData && rows.length != 0 ? rows.map((strdata) =>
                                <tr className="order-info-ele">
                                  <td className="p-0">
                                    <Table bordered className="mb-0 border-top-bottom-0">
                                      <tbody>
                                        <tr key={strdata.properties.storeID} className="store-id">
                                          <td colSpan="4" className="border-0 first-td">
                                            <Row>
                                              <Col xs="2">
                                                {/* below the link comment for PDF purpose */}
                                                {/* <Link to="#" onClick={() => this.getStoreStreeView(strdata.latitude, strdata.longitude, strdata.storeName)} title="click here to view street view"></Link> */}
                                                <Avatar
                                                  style={{ borderRadius: 0 }} className="unit-border-img">
                                                  {strdata.properties.StoreImage != '' ? <img className="Unit-Store-Img re-style-unit-store-img" src={strdata.properties.storeImagePath} alt="No Image" onError={this.addDefaultSrc} /> : ''}
                                                </Avatar>
                                              </Col>
                                              <Col xs="10" className="pr-35 pl-1 store-pdf-name">
                                                <MarketProfile ref={this.child} />
                                                {/* below the line comment for PDF does not have href */}
                                                {/* <h2 className="m-0">{strdata.isUserStore == 1 ? <FontAwesomeIcon icon={faStar} title={"Your Store"} /> : ''} <Link onClick={() => this.getParams(strdata.storeName, strdata.storeID)}>{strdata.storeName}</Link> {strdata.proximity != 0 ? <span>{strdata.proximity} {(CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? 'kilometre' : 'mile'}{strdata.proximity <= 1 ? '' : 's'}</span> : ''}</h2> */}
                                                <h2 className="m-0 kilometers">{strdata.properties.isUserStore == 1 ? <FontAwesomeIcon icon={faStar} title={"Your Store"} /> : ''} <span className="store-name-pdf">{strdata.properties.storeName}</span> {strdata.properties.proximity != 0 ? <span>{strdata.properties.proximity} {(CONFIG.RegionId == 2) || (CONFIG.RegionId == 4) ? 'kilometre' : 'mile'}{strdata.properties.proximity <= 1 ? '' : 's'}</span> : ''}</h2>
                                                <p className="m-0 address-line">{strdata.properties.storeAddress}</p>
                                              </Col>
                                            </Row>
                                          </td>
                                          <td colSpan="3" className="border-0 sec-td">
                                            <div className="right-list"><Row><Col xs="7" className="pl-0"><label className="m-0">{(this.state.locationInfoData.countryCode === "DE" || this.state.locationInfoData.countryCode === "AT" || this.state.locationInfoData.countryCode === "CH") ? "Total Square Metre" : CONFIG.MeasurementLabel}</label></Col><Col xs="5" className="pl-0 pr-0"><span className="storage-value">{strdata.properties.squareFootage == "0" ? "N/A" : this.NumberFormat(strdata.properties.squareFootage) + " " + ((this.state.locationInfoData.countryCode === "DE" || this.state.locationInfoData.countryCode === "AT" || this.state.locationInfoData.countryCode === "CH") ? "sqm" : CONFIG.MeasurementShortName)}{(strdata.properties.sqFtFlag == 2 && strdata.properties.squareFootage !== "0") ? <sup style={{ color: 'red', fontSize: '13px' }}>*</sup> : ''}</span></Col></Row><div className="clear"></div></div>
                                            <div className="right-list"><Row><Col xs="7" className="pl-0"><label className="m-0">Opened</label></Col><Col xs="5" className="pl-0"><span className="storage-value">{strdata.properties.opened == "0" ? "N/A" : strdata.properties.opened}</span></Col></Row><div className="clear"></div></div>
                                          </td>
                                          <td colSpan="3" className="border-0 third-td">
                                            <div className="right-list"><Row><Col xs="3" className="pl-0"><label className="m-0">Owned by</label></Col><Col xs="9" className="pl-0"><span className="storage-value">{strdata.properties.ownedBy}</span></Col></Row><div className="clear"></div></div>
                                            <div className="right-list"><Row><Col xs="3" className="pl-0"><label className="m-0">Operated by</label></Col><Col xs="9" className="pl-0"><span className="storage-value">{strdata.properties.operatedBy}</span></Col></Row><div className="clear"></div></div>
                                          </td>
                                          <td colSpan="1" className="border-0 fourth-td">
                                            <div className="right-list"><label className="m-0">Price Volatility</label>{strdata.properties.priceChangesPercent != null ? <span className="storage-value ml-1">{strdata.properties.priceChangesPercent}% </span> : <span className="storage-value ml-1">N/A</span>}<div className="clear"></div></div>
                                            <h3 className="m-0 d-block last-days">Last 30 days</h3>
                                            <div className="right-list">
                                              <label className="m-0" style={{ width: '85px' }}>Last checked date</label>{strdata.properties.lastVerified && strdata.properties.lastVerified !== null ? <span className="storage-value ml-1">{moment(strdata.properties.lastVerified).format('DD-MMM-YYYY')}</span> : <span className="storage-value ml-1">N/A</span>}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Table bordered className="mb-custom table-layout-fixed">
                                      <tbody>
                                        <tr>
                                          {compOverviewData.unitData ? compOverviewData.unitData.map((untdata) =>
                                            strdata.properties.storeID === untdata.storeID ?
                                              <td className="pl-0 pr-0 padding-top-0 pb-1">
                                                <p className="pr-1 pl-1 pb-1 border-bottom">{untdata.unitType}</p>
                                                <p className="pr-1 pl-1 mb-0">{untdata.value === 1 ? <FontAwesomeIcon icon={faCheck} className="green-clr" title="Pricing available for this unit type" /> : untdata.value === -1 ? <FontAwesomeIcon icon={faEyeSlash} title="Pricing previously available, but not currently advertised" /> : <FontAwesomeIcon icon={faTimes} className="red-clr" title="Pricing not available for this unit type" />}</p>
                                              </td>
                                              : ''
                                          ) : ''}
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              ) :
                                <tr>
                                  <td className="text-center no-data">{isLoading === false ? 'There are no competitors available in this market' : ''}</td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                    {/* <div className="footer compoverview-footer">
                    optimize.stortrack.com | 1-800-969-7424 | info@stortrack.com
        </div> */}
                  </div>
                </div>
              </div>
              <div className="printclearfix"></div>
            </div>
          ))
            : ''}
          <div className="break-before--">
            {/* <div className="header compoverview-header">
              <img src={Logo} />
              <div className="float-right only-address-right">
                <h5 style={{ marginBottom: '0px' }}>{locationInfoData ? locationInfoData.storeName : isLoading === false ? 'N/A' : ''}</h5>
                <p className="m-0">{locationInfoData ? locationInfoData.storeAddress1 : 'N/A'}, {locationInfoData.storeAddress2 ? locationInfoData.storeAddress2 + ', ' : ''} {locationInfoData ? locationInfoData.storeCity : 'N/A'}{locationInfoData.storeState ? ', ' + locationInfoData.storeState : ''} {locationInfoData ? locationInfoData.storeZipCode : 'N/A'}</p>
              </div>
            </div> */}
            <Col className="comp-pdf-body pl-0 pt-0 pb-0 m-0" style={{ paddingRight: '15px' }}>
              <Row >
                <Col md="12">
                  <div className="map-view-height">
                    <MapGL
                      {...viewport}
                      ref={this.mapRef}
                      onViewportChange={this.handleViewportChange}
                      mapboxApiAccessToken={CONFIG.REACT_APP_MAPBOX_TOKEN}
                      mapStyle={"mapbox://styles/mapbox/streets-v11"}
                      attributionControl={false}
                      preserveDrawingBuffer={true}
                      onLoad={() => {
                        let mapbox = this.mapRef.current.getMap();
                        mapbox.loadImage(homeIcon, (error, image) => {
                          if (error) throw error;
                          mapbox.addImage('homeMarker', image);
                        });
                      }}
                    >
                      {/* {this.state.homeLatLng.latitude !== 0 && this.state.homeLatLng.longitude !== 0 &&
                        (<Marker {...this.state.homeLatLng} offsetLeft={-10} offsetTop={-12}>
                          <img src={homeIcon} alt="icon" />
                        </Marker>)} */}

                      {/* {this.state.homeLatLng.latitude !== 0 && this.state.homeLatLng.longitude !== 0 && */}
                      <Source
                        type="geojson"
                        data={{
                          'type': 'FeatureCollection',
                          'features': [{
                            'type': 'Feature',
                            'geometry': {
                              'type': 'Point',
                              'coordinates': [this.state.homeLatLng.longitude, this.state.homeLatLng.latitude]
                            }
                          }]
                        }}
                      >
                        <Layer
                          id="home-marker"
                          type="symbol"
                          layout={{
                            'icon-image': 'homeMarker',
                          }}
                        />
                      </Source>
                      <Source id="map-data" type='geojson' data={{ type: 'FeatureCollection', features: storeData }}>
                        <Layer {...layerStyle} />
                      </Source>

                    </MapGL>
                  </div>
                </Col>
                <Col md="12" className="margin-2 map-btm-legend">
                  <Row className="ml-0 mb-2">
                    <div className="legend-block"><div className="maps-legend-orange rounded-circle"></div>Your Store</div>
                    <div className="legend-block"><div className="maps-legend-blue rounded-circle"></div>Competitor</div>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* <div className="footer compoverview-footer">
              <a href={CONFIG.Website_URL_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Website_Text_Footer_Pdf}</a> | {CONFIG.Phone_Footer_Pdf} | <a href={'mailto:'+CONFIG.Mail_Footer_Pdf} style={{textDecoration: 'none'}}>{CONFIG.Mail_Footer_Pdf}</a> | <img src={Logo} alt="Optimize Logo" style={{ height: '16px' }} />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}