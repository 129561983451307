// For United States
export const CONFIG = {
    IsMapbox: true,
    API_URL: 'https://apioptimize.stortrack.com/api/',
    GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4',
    REACT_APP_MAPBOX_TOKEN: "pk.eyJ1IjoiY2xvdWRzdXBwb3J0IiwiYSI6ImNrcDd6MzllZzA1N2IzMW9oNTcwOHh0ajAifQ.C2VDAibO99HHvq7_YluMmw",
    STK_URL: 'https://www.stortrack.com/',
    OPTZ_URL: 'https://optimize.stortrack.com/',
    STK_SIGNURL: 'https://www.stortrack.com/Signin.html',
        USA_URL: 'https://www.stortrack.com/',
    AU_URL: 'https://au.stortrack.com/',
    NZ_URL:'https://nz.stortrack.com/',
    UK_URL: 'https://www.stortrack.co.uk/',
    RegionId: 1,
        Country_Code:'US',
    Currency_Symbol: '$',
    PhoneMaxLength: 20,
    PhoneMinLength: 4,
    PhoneRegex: /^([^\s]{3})([^\s]{3})([^\s]{4})$/g,   
    Mail_Support: 'support@stortrack.com',    
    STK_Homeurl: 'stortrack.com',    
    Mail_Info: 'info@stortrack.com',
    MapQuestKey_URL: 'https://www.mapquestapi.com/search/v2/radius?key=',
    MapQuestKey: 'YACBPORLjPNwG22g0A16czmgreWVIaGM',
    Toll_Free: '1-800-969-7424',
    Sales_Support:'1-800-969-7424',
        Address1: "Address Line 1",
    Address2: "Address Line 2",
    City: "City",
    State: "State/Province",
    PostalCode: "Zip/Postal Code",
    Country: "Country",
        MeasurementLabel: "Total Square Feet",
        MeasurementName: "Specific",
        MeasurementShortName: "sqft",
        PostalCodevalidation :"Postcode is invalid",
        Website_Text_Footer_Pdf: 'optimize.stortrack.com',
    Website_URL_Footer_Pdf: 'https://optimize.stortrack.com/',
    Phone_Footer_Pdf: '1-800-969-7424',
    Mail_Footer_Pdf: 'info@stortrack.com',
        storeNameMaxLimit: 90,        
        MVP_URL: 'https://explorer.stortrack.com/self-storage/logins/',        
    MARKETS_REPORTS_URL: 'https://reports.stortrack.com/market-reports/',  
    HISTORICAL_REPORTS_URL: 'https://reports.stortrack.com/historical-reports/',
    ResourcePage:'https://www.stortrack.com/news',
        TimeOut_Sec: 1800
}

export const MatchingCountryCode = {
  UK_RID : 3,
  NZ_RID : 4,
};